import moment from 'moment';
import React, { useState } from 'react';

//Components
import ProgressBar from '../../ProgressBar';
import PilihJadwalModal from '../PilihJadwalModal';

//Sub-components
import CourseImage from './subcomponent/CourseImage';
import ScheduleDate from './subcomponent/ScheduleDate';
import SchedulePickButton from './subcomponent/SchedulePickButton';
import TopData from './subcomponent/TopData';
import { WALink, ZoomLink } from './subcomponent/ZoomWAButton';

import { useDispatch } from 'react-redux';
import { parseCourseDuration, parseCourseDurationAndEbook } from '../../../Utils/Helpers';
import { parseTotalTimeMaterialAndEbook } from '../../../Utils/Utils';

import axios from 'axios';
import ReactModal from 'react-modal';
import { NotificationManager } from 'react-notifications';
import { API_URL, KPJ_CODE_ADMIN } from '../../../constant';

const MaterialDuration = ({ course, progressCheck }) => {
  const [openModalPrakerjaRedeem, setOpenModalPrakerjaRedeem] = useState(false);
  const togglePrakerjaRedeem = () => setOpenModalPrakerjaRedeem(!openModalPrakerjaRedeem);

  const [voucherKpj, setVoucherKpj] = useState('');
  const [loadingVoucherKpj, setLoadingVoucherKpj] = useState(false);

  const handleSubmit = async (e) => {
    const token = localStorage.getItem('access_token');
    e.preventDefault();
    setLoadingVoucherKpj(true);
    try {
      const res = await axios.post(
        `${API_URL}/kjflow/verifycoupon`,
        { payment_id: course?.payment?.id, kpj_code: voucherKpj, sequence: 1 },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (voucherKpj === KPJ_CODE_ADMIN) {
        window.location.reload();
        NotificationManager.success('Voucher redeemed');
      } else if (res?.data?.data?.data?.url) {
        window.open(res?.data?.data?.data?.url);
        NotificationManager.success('Voucher redeemed');
      }
    } catch (error) {
      NotificationManager.error(error?.response?.data?.message ?? 'Failed to redeem voucher');
    } finally {
      setLoadingVoucherKpj(false);
      setVoucherKpj('');
      togglePrakerjaRedeem();
    }
  };

  return (
    <>
      {/* mobile */}
      <div className="navbar-mob">
        <div className="mt-1 d-flex w-100 flex-column">
          <p className="course-card__materi-text" style={{ fontWeight: '300' }}>
            {`${course.lessons.length} Materi${
              course.ebooks && course.ebooks.length > 0
                ? ' + ' + course.ebooks.length + ' Ebook'
                : ''
            } | Total durasi ${
              course.ebooks && course.ebooks.length > 0
                ? parseCourseDurationAndEbook(course.total_material_duration, course.ebooks)
                : parseCourseDuration(course.total_material_duration)
            }`}
          </p>

          <ButtonRedeem
            course={course}
            progressCheck={progressCheck}
            togglePrakerjaRedeem={togglePrakerjaRedeem}
          />
        </div>
      </div>
      {/* mobile */}

      {/* desktop */}
      <div className="navbar-desc">
        <div className="mt-3 d-flex justify-content-between align-items-center w-100">
          <p style={{ fontSize: '16px' }}>
            {`${course.lessons.length} Materi${
              course.ebooks && course.ebooks.length > 0
                ? ' + ' + course.ebooks.length + ' Ebook'
                : ''
            } | Total durasi ${
              course.ebooks && course.ebooks.length > 0
                ? parseCourseDurationAndEbook(course.total_material_duration, course.ebooks)
                : parseCourseDuration(course.total_material_duration)
            }`}
          </p>

          <ButtonRedeem
            course={course}
            progressCheck={progressCheck}
            togglePrakerjaRedeem={togglePrakerjaRedeem}
          />
        </div>
      </div>
      {/* desktop */}

      {/* modal input kpj voucher */}
      <ReactModal
        closeTimeoutMS={100}
        isOpen={openModalPrakerjaRedeem}
        overlayClassName="login-popup-overlay"
        className="login-popup-content"
        onRequestClose={togglePrakerjaRedeem}
        ariaHideApp={false}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '16px',
            height: '100vh',
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: '8px',
              padding: '16px',
              maxWidth: '576px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <h4 style={{ fontWeight: '600', fontSize: '18px', marginBottom: '0' }}>
              Insert KPJ Redeem Code
            </h4>

            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Enter your code"
                className="search__input"
                style={{ margin: '0', maxWidth: '100%', marginBottom: '16px' }}
                value={voucherKpj}
                onChange={(e) => setVoucherKpj(e.target.value)}
              />

              <div style={{ display: 'flex', gap: '16px' }}>
                <button
                  type="button"
                  className="btn"
                  style={{ width: '100%', color: 'black' }}
                  onClick={togglePrakerjaRedeem}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loadingVoucherKpj}
                  className="btn btn-pink"
                  style={{ width: '100%' }}
                >
                  {loadingVoucherKpj ? 'Loading...' : 'Submit'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </ReactModal>
      {/* modal input kpj voucher */}
    </>
  );
};

const CourseList = ({ course }) => {
  const [modalOpen, SetModalOpen] = useState(false);
  const dispatch = useDispatch();

  const progressCheck = () => {
    if (course.progress_indicator == '100.00%' && course.is_qna_required && course.is_qna_enabled) {
      if (course.course_qna_attend) {
        if (course.quiz) {
          if (course.quiz.is_exam_given) {
            return 'Beri Rating';
          }
        }
        return 'Akses Exam';
      }
    } else if (
      course.progress_indicator == '100.00%' &&
      !(course.is_qna_required && course.is_qna_enabled)
    ) {
      if (course.quiz) {
        if (course.quiz.is_exam_given) {
          return 'Beri Rating';
        }
      }
      return 'Akses Exam';
    }

    return 'Akses Kelas';
  };

  return (
    <>
      <div className="course-card">
        <div className="row">
          <div className="col-xl-4 col-md-4 col-sm-3 col-5 course-card__mobile-container-img">
            <CourseImage course={course} />
          </div>
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-9 col-7 course-card__content course-card__mobile-container-content">
            <TopData course={course} />
            <MaterialDuration course={course} progressCheck={progressCheck} />
          </div>
        </div>
        <>
          <ProgressBar progress={course.progress_indicator} />
          {course.progress_indicator == '100.00%' &&
          course.is_qna_required &&
          course.is_qna_enabled ? (
            course.course_qna_attend ? (
              <p className="ml-2 mt-4 course-card__bold-text" style={{ fontWeight: '300' }}>
                Anda telah menyelesaikan live QnA, silahkan kerjakan Exam dan beri Rating untuk
                menyelesaikan kelas.
              </p>
            ) : course.course_qna_register ? (
              <div>
                <div className="course-card__qna__sched">
                  <p className="course-card__bold-text mr-3">Live QnA dilaksanakan Pada Tanggal:</p>
                  <ScheduleDate course={course} />
                  <div className="navbar-desc ml-auto ">
                    <SchedulePickButton isChangeSched course={course} SetModalOpen={SetModalOpen} />
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-2">
                  <div className="navbar-mob mr-4 ">
                    <SchedulePickButton
                      isChangeSched
                      isMobile
                      course={course}
                      SetModalOpen={SetModalOpen}
                    />
                  </div>
                  {course.course_qna_register.schedule.whatsapp_link && (
                    <WALink link={course.course_qna_register.schedule.whatsapp_link} />
                  )}
                  {course.course_qna_register.schedule.zoom_link && (
                    <ZoomLink link={course.course_qna_register.schedule.zoom_link} />
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-center flex-column mt-4 mb-2">
                  <p className="course-card__jadwal-hosted">
                    Pilih Jadwal Live QnA terlebih dahulu untuk menyelesaikan kelas
                  </p>
                  <div className="navbar-desc ml-auto ">
                    <SchedulePickButton course={course} SetModalOpen={SetModalOpen} />
                  </div>
                  <div className="navbar-mob ml-auto ">
                    <SchedulePickButton isMobile course={course} SetModalOpen={SetModalOpen} />
                  </div>
                </div>
              </>
            )
          ) : (
            ''
          )}
        </>
      </div>
      {modalOpen && (
        <PilihJadwalModal
          modalOpen={modalOpen}
          closeModal={(e) => SetModalOpen(false)}
          course={course}
        />
      )}
    </>
  );
};

export default CourseList;

function ButtonRedeem({ course, togglePrakerjaRedeem, progressCheck }) {
  return (
    <>
      {(course?.is_kpj_code_redeemed === 1 || !course?.kartu_prakerja) && (
        <div
          className="btn btn-pink btn-pilih-jadwal course-card__materi-button mt-1"
          onClick={() => (window.location.href = '/course/login/' + course.id + '/' + course.slug)}
        >
          {progressCheck()}
        </div>
      )}
      {course?.kartu_prakerja && course?.is_kpj_code_redeemed === 0 && (
        <div
          className="btn btn-pink btn-pilih-jadwal course-card__materi-button mt-1"
          onClick={togglePrakerjaRedeem}
          style={{ width: 'auto' }}
        >
          Insert KPJ Redeem Code
        </div>
      )}
    </>
  );
}
