import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import Slider from '../Slider'
import ReactGA from 'react-ga';
import Card from '../CourseCard'

const KelasPopuler = ({ landing }) => {
    const dispatch = useDispatch()
    const [featuredCourse, setFeaturedCourse] = useState(null)
    const [returnError, setReturnError] = useState([])
    const location = useLocation()

    useEffect(() => {
        const fetchFeaturedCourses = async () => {
            dispatch({ type: 'TOGGLE_LOADING_TRUE' })
            axios.get(`${window.$API_URL}api/course/featured_home/`, {},
                {
                    headers: { 'Content-Type': 'application/json', 'x-access-token': '' },
                },
            ).then((success) => {
                dispatch({ type: 'TOGGLE_LOADING_FALSE' })
                if (success.data.success) {
                    setFeaturedCourse(success.data.data)
                }
            })
                .catch((error) => {
                    dispatch({ type: 'TOGGLE_LOADING_FALSE' })
                    if (!error.response) {
                        NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
                        return false
                    }
                    if (error.response.status == '422') {
                        setReturnError([]);
                        let errorTemp = []
                        Object.keys(error.response.data.errors).forEach((key) => {
                            errorTemp.push(error.response.data.errors[key][0]);
                        });
                        setReturnError(errorTemp)
                        const errorList2 = returnError.map((char, i) => {
                            return (
                                <li key={i} style={{ listStyleType: 'none' }}>
                                    {char}
                                </li>
                            );
                        });
                        NotificationManager.error(errorList2, 'Validasi Error!');
                    } else {
                        NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
                    }
                });
        }
        fetchFeaturedCourses()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const isEmpty = () => {
        if(featuredCourse !== null){
            if(featuredCourse[0]){
                return true
            }
        }

        return false
    }

    const gaOnFocus = (el) => {
        if(location.pathname === '/') {
            ReactGA.event({
                category: 'Kelas Populer',
                action: `Click Details-Home`,
                label: el.name
            })
        } else if(location.pathname === '/courses') {
            ReactGA.event({
                category: 'Kelas Populer',
                action: `Click Details-Semua Kelas`,
                label: el.name
            }) 
        }
    }

    return (
        <>
            {isEmpty() ?
            <div className="kelas-populer" style={{ marginTop: landing ? '80px' : '0' }}>
                <div>
                    <h1 className="kelas-populer__title">Kelas Populer</h1>
                    <h1 className="kelas-populer__desc">Temukan Kelas Untuk Masa Depanmu</h1>
                    <div className="navbar-desc">
                        <div className="row">
                            {
                                featuredCourse.map(el => (
                                    <div className="col-md-4 col-lg-3 col-sm-12 mb-4 d-flex justify-content-center">
                                        <div onFocus={() => gaOnFocus(el)}>
                                            <Card data={el} notSlider={true}/>
                                        </div>
                                    </div>
                                ))
                            }     
                        </div>
                    </div>
                    <div className="navbar-mob">
                        {
                            <Slider courses_list={featuredCourse} sliderOnly noArrow/>
                        }
                    </div>
                </div>
            </div> : <div style={{height: landing ? '80px' : '0'}}></div>}
        </>
    )
}

export default KelasPopuler