import React, { useState } from 'react';

const ExamAnswers = ({ answers, setAnswer, answer }) => {
    return (
        <div className="exam-answers">
            {
                answers.map(elAnswer => (
                    <div className={`mb-3 d-flex ${elAnswer.answer === answer ? 'exam-answers__answer__selected' : 'exam-answers__answer'}`}
                        onMouseDown={() => {
                            setAnswer({ id: elAnswer.id, answer: [elAnswer.answer] })
                        }}
                    >
                        <div className={`mr-3  ${elAnswer.answer === answer ? 'exam-answers__option__selected' : 'exam-answers__option'}`}>
                            {elAnswer.option}
                        </div>
                        <div className="mr-auto ">
                            {elAnswer.text}
                        </div>
                        <div style={{ minWidth: '30px' }} className='ml-1'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                                style={{ display: `${elAnswer.option === answer ? 'block' : 'none'}` }}>
                                <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z" fill="#FF0F51" />
                            </svg>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default ExamAnswers;