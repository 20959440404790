import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import CourseListConsultation from './CourseList/CourseListConsultation';
import CourseList from './CourseList/index';
import CourseListDone from './CourseListDone';
import KelasNav from './KelasNav';
import SertifikatList from './SertifikatList';

const MyCourse = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const tabSelected = useSelector((state) => state.my_class_submenu);
  const studentCourse = useSelector((state) => state.student_course);
  const certificates = useSelector((state) => state.certificates);
  const [bg, setBg] = useState('list-background-course-empty');
  const [haveCompleted, setHaveCompleted] = useState(false);
  const [needInfo, setNeedInfo] = useState(false);
  const setTabSelected = (submenu) => {
    dispatch({
      type: 'SET_MY_CLASS_SUBMENU',
      payload: submenu,
    });
  };

  const blankBg = () => {
    if (certificates !== null && tabSelected === 'sertifikat') {
      if (!certificates[0]) {
        setBg('list-background-course');
        return 1;
      } else {
        setBg('list-background-course-empty');
        return 1;
      }
    }

    if (studentCourse !== null) {
      const courseUnfinished = studentCourse.filter((el) => {
        if (!el.is_finished) return true;
        else return false;
      });

      if (!courseUnfinished[0] && tabSelected === 'sedang') {
        setBg('list-background-course');
        return 1;
      }

      const courseFinished = studentCourse.filter((el) => {
        if (el.is_finished) return true;
        else return false;
      });

      if (!courseFinished[0] && tabSelected === 'selesai') {
        setBg('list-background-course');
        return 1;
      } else {
        setBg('list-background-course-empty');
        return 1;
      }
    } else {
      setBg('list-background-course');
      return 1;
    }
  };

  useEffect(() => {
    blankBg();
    if (studentCourse) {
      studentCourse.forEach((el) => {
        const courseThatNeedsInformation = [9, 22, 23, 27, 29, 24, 77, 14, 82];
        let found = false;
        courseThatNeedsInformation.forEach((elCourse) => {
          if (elCourse === +el.id) {
            found = true;
          }
        });
        if (found) setNeedInfo(true);

        if (el.is_finished) setHaveCompleted(true);
      });
    }
  }, [tabSelected, studentCourse, certificates]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isMobile && !tabSelected) {
      setTabSelected('sedang');
    }
    return () => {
      setTabSelected('');
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div>
        {haveCompleted ? <KormoJob /> : ''}
        {needInfo ? <InfoKelasSelfPace /> : ''}
        {!isMobile ? <KelasNav tabSelected={tabSelected} setTabSelected={setTabSelected} /> : ''}
        <div style={{ padding: '37px 0', minHeight: '600px' }} className={bg}>
          {tabSelected && isMobile ? (
            <div className="mb-2 d-flex align-items-center" onClick={() => setTabSelected('')}>
              <Arrow />
              <p>Kembali</p>
            </div>
          ) : (
            ''
          )}

          {tabSelected === 'sedang' &&
            studentCourse !== null &&
            studentCourse.map((el, i) => !el.is_finished && <CourseList key={i} course={el} />)}

          {tabSelected === 'selesai'
            ? studentCourse !== null
              ? studentCourse.map((el, i) =>
                  el.is_finished ? <CourseListDone key={i} course={el} /> : '',
                )
              : ''
            : ''}
          {tabSelected === 'diskusi'
            ? studentCourse !== null
              ? studentCourse.map((el, i) =>
                  el.is_future_consultation_available ? (
                    <CourseListConsultation key={i} course={el} />
                  ) : (
                    ''
                  ),
                )
              : ''
            : ''}
          {tabSelected === 'sertifikat'
            ? certificates !== null
              ? certificates.map((el, i) => <SertifikatList key={i} sertifikat={el} />)
              : ''
            : ''}
          {!tabSelected && isMobile ? (
            <KelasNav tabSelected={tabSelected} setTabSelected={setTabSelected} />
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

const InfoKelasSelfPace = () => {
  return (
    <>
      <div className="info-kelas-self pt-3">
        <h2>Info Kelas Self-Paced</h2>
        <p>
          Anda hanya akan dapat menyelesaikan kelas self-paced anda, setelah mengikuti webinar untuk
          sesi Q&A dan mengambil quiz untuk mengecek pemahaman dari kelas yang telah anda ikuti.
          Terima kasih!
        </p>
      </div>
    </>
  );
};

const KormoJob = () => {
  const [drawerOpenStatus, setDrawerOpenStatus] = useState(false);
  return (
    <>
      <div className={'content-drawer content-drawer-kormo-job'}>
        <a
          className={`d-flex justify-content-between align-items-center ${
            drawerOpenStatus ? 'kormo-job-header--opened' : 'kormo-job-header'
          }`}
          onClick={(e) => {
            e.preventDefault();
            setDrawerOpenStatus(!drawerOpenStatus);
          }}
          href="/"
          aria-controls="class-subcontent-small"
          aria-expanded={drawerOpenStatus}
        >
          <div>
            <div>
              <h1>Sedang Mencari Pekerjaan?</h1>
              <img src="/images/partners/kormo/icon.png" />
            </div>
            {drawerOpenStatus ? (
              <p>
                Untuk pencari kerja, Kormo Jobs memberi Anda akses ke lowongan pekerjaan yang
                relevan dan peluang wawancara, serta konten pembelajaran untuk membantu Anda
                mempersiapkan dan mengembangkan karier Anda.
              </p>
            ) : (
              <p>Klik disini untuk info selanjutnya!</p>
            )}
          </div>
        </a>
        <Collapse isOpened={drawerOpenStatus}>
          <div className="kormo-job">
            <div>
              <h1>Lowongan Pekerjaan</h1>
              <div>
                <div>
                  <div>
                    <img src="/images/partners/kormo/lowongan_1.png" />
                  </div>
                  <h2>Management Trainee</h2>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      window.open('http://tinyurl.com/kerjamt', '_blank');
                    }}
                    className="btn btn-pink"
                  >
                    Apply
                  </button>
                </div>
                <div>
                  <div>
                    <img src="/images/partners/kormo/lowongan_2.png" />
                  </div>
                  <h2>Marketing</h2>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      window.open('http://tinyurl.com/kerjamarketing', '_blank');
                    }}
                    className="btn btn-pink"
                  >
                    Apply
                  </button>
                </div>
                <div>
                  <div>
                    <img src="/images/partners/kormo/lowongan_3.png" />
                  </div>
                  <h2>Sales</h2>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      window.open('http://tinyurl.com/kerjasales', '_blank');
                    }}
                    className="btn btn-pink"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
            <div>
              <h1>Cara Melamar</h1>
              <div>
                <div>
                  <div>
                    <h1>1</h1>
                  </div>
                  <p>Klik "Apply"</p>
                  <img src="/images/partners/kormo/cara_1.png" />
                </div>
                <div>
                  <div>
                    <h1>2</h1>
                  </div>
                  <p>Kamu akan diarahkan ke Play Store untuk mengunduh aplikasi kormo</p>
                  <img src="/images/partners/kormo/cara_2.png" />
                </div>
                <div>
                  <div>
                    <h1>3</h1>
                  </div>
                  <p>Cari dan dapatkan pekerjaanmu</p>
                  <img src="/images/partners/kormo/cara_3.png" />
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};

const Arrow = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="ml-3 mr-3"
  >
    <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" fill="black" />
  </svg>
);

export default MyCourse;
