import React, { useState, useEffect, useRef } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer';
import { BannerImg } from '../../../image/InternPage';
import {
    Banner,
    InternList,
    BottomBanner
} from './styles';
import axios from 'axios';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import ReactPaginate from 'react-paginate';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const InternPage = () => {
    const isMobile = useMediaQuery({ query: "(max-device-width: 992px)" });
    const max600px = useMediaQuery({ query: "(max-device-width: 600px)" });
    const [loading, setLoading] = useState(false);
    const [internList, setInternList] = useState([]);
    const [internListPagination, setInternListPagination] = useState(null);
    const [batchTitle, setBatchTitle] = useState("");
    const [batch, setBatch] = useState(0);
    const scrollRef = useRef(null);

    const dispatch = useDispatch()
    const history = useHistory()
    // const pageQuery = useQuery().get("page")
    // const page = pageQuery ? +(pageQuery) - 1 : 0
    const [pageSelected, setPageSelected] = useState(0)

    useEffect(() => {
        setLoading(true);
        axios.get(`${window.$API_URL}api/intern?page=${pageSelected + 1}&${batch ? 'batch_id=' + batch : ''}`)
            .then(response => {
                if (response.data.success) {
                    setInternListPagination(response.data)
                    var dataQuery = response.data.data;
                    setInternList(dataQuery);
                }
            })
            .finally(() => setLoading(false))
    }, [pageSelected, batch])

    useEffect(() => {
        setPageSelected(0)
    }, [batch])

    // useEffect(() => {
    //     history.push({ search: `?page=${pageSelected + 1}` });
    // }, [pageSelected]);

    const executeScroll = () => scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start"
    });

    return (
        <div style={{ backgroundColor: "#FFFFFF" }}>
            <Header />
            <Banner>
                {!isMobile &&
                    <>
                        <img src={BannerImg} />
                        <div className="text-container">
                            <p>Meet Our Interns</p>
                            <h1>Sesuatu yang hebat telah dimulai!</h1>
                            <p>Mereka semua telah memberikan dampak positif yang hebat!</p>
                            <button className="custom-btn" onClick={executeScroll}>Baca Cerita Mereka</button>
                        </div>
                    </>
                }
            </Banner>

            <InternList ref={scrollRef}>
                <div className="header">
                    <h1>Simak Cerita Perjalanan Karier Mereka</h1>
                    <div className="container">
                        <p>Menjadi bagian dari #MyEduSolveIntern</p>
                        <div class="btn-group dropdown">
                            <button type="button" id="filter" class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {batchTitle ? batchTitle : "Semua Batch"}
                            </button>
                            <div class="dropdown-menu">
                                <p class="dropdown-item item"
                                    onClick={() => {
                                        setBatchTitle("Batch 1")
                                        setBatch(1)
                                    }}>Batch 1</p>
                                <div class="dropdown-divider" />
                                <p class="dropdown-item item"
                                    onClick={() => {
                                        setBatchTitle("Batch 2")
                                        setBatch(2)
                                    }}>Batch 2</p>
                                <div class="dropdown-divider" />
                                <p class="dropdown-item item"
                                    onClick={() => {
                                        setBatchTitle("Batch 3")
                                        setBatch(3)
                                    }}>Batch 3</p>
                                <div class="dropdown-divider" />
                                <p class="dropdown-item item"
                                    onClick={() => {
                                        setBatchTitle("Semua Batch")
                                        setBatch(0)
                                    }}>Semua Batch</p>
                            </div>
                        </div>
                    </div>
                </div>
                {loading ?
                    <LoadingSpinner text={'Mohon Tunggu..'} />
                    :
                    <>
                        {max600px ?
                            <div className="list">
                                {internList.map((intern) =>
                                    <Link to={`/intern/${intern.id}`} className="intern">
                                        <div key={intern.id}>
                                            {intern.avatar ?
                                                <img className="avatar" src={intern.avatar.url} />
                                                :
                                                <img className="avatar" />
                                            }
                                            <p className="nama">{intern.name}</p>
                                            <p className="role">{intern.position}</p>
                                        </div>
                                    </Link>
                                )}
                            </div>
                            :
                            <>
                                {isMobile ?
                                    <div className="list row">
                                        {internList.map((intern) =>
                                            <div className="col-sm-6">
                                                <Link to={`/intern/${intern.id}`} className="intern">
                                                    <div key={intern.id}>
                                                        {intern.avatar ?
                                                            <img className="avatar" src={intern.avatar.url} />
                                                            :
                                                            <div className="avatar" />
                                                        }
                                                        <p className="nama">{intern.name}</p>
                                                        <p className="role">{intern.position}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                    :
                                    <div className="list row">
                                        {internList.map((intern) =>
                                            <div className="col-sm-3">
                                                <Link to={`/intern/${intern.id}`} className="intern">
                                                    <div key={intern.id}>
                                                        {intern.avatar ?
                                                            <img className="avatar" src={intern.avatar.url} />
                                                            :
                                                            <div className="avatar" />
                                                        }
                                                        <p className="nama">{intern.name}</p>
                                                        <p className="role">{intern.position}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                }
                            </>
                        }
                        {internListPagination ?
                            <div className='d-flex justify-content-center align-items-center col-12 pagination-wrapper'>
                                {/* <p className="mr-4 navbar-desc">Menampilkan Halaman</p>
                            <div className='awal-akhir-btn mr-2' onClick={(e) => setPageSelected(0)}>Awal</div> */}
                                <ReactPaginate
                                    previousLabel={
                                        <div className="pagi-li">
                                            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.677344 5.777C0.677344 5.95703 0.751476 6.12648 0.878559 6.25356L5.623 10.998C5.75009 11.1251 5.91953 11.1992 6.09957 11.1992C6.47023 11.1992 6.77734 10.8921 6.77734 10.5214L6.77734 1.03255C6.77734 0.661893 6.47023 0.354774 6.09957 0.354774C5.91953 0.354774 5.75009 0.428906 5.623 0.55599L0.878559 5.30043C0.751476 5.42752 0.677344 5.59696 0.677344 5.777Z" fill="#FF0F51" />
                                            </svg>
                                        </div>

                                    }
                                    nextLabel={
                                        <div className="pagi-li">
                                            <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1 5.42222C6.1 5.24219 6.02587 5.07274 5.89878 4.94566L1.15434 0.201215C1.02726 0.0741319 0.857813 0 0.677778 0C0.307118 0 0 0.307118 0 0.677778V10.1667C0 10.5373 0.307118 10.8444 0.677778 10.8444C0.857813 10.8444 1.02726 10.7703 1.15434 10.6432L5.89878 5.89878C6.02587 5.7717 6.1 5.60226 6.1 5.42222Z" fill="#FF0F51" />
                                            </svg>
                                        </div>
                                    }
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={internListPagination.last_page}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={1}
                                    // onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'pageactive'}
                                    pageClassName={'pageclass'}
                                    initialPage={pageSelected}
                                    forcePage={pageSelected}
                                    onPageChange={(data) => setPageSelected(data.selected)}
                                />
                                {/* <div className='awal-akhir-btn ml-2' onClick={(e) => setPageSelected(internListPagination.last_page - 1)}>Akhir</div> */}
                            </div> : ''}
                    </>
                }
            </InternList>

            <BottomBanner>
                <div>
                    <h1>Tertarik untuk bergabung di MyEduSolve sebagai #MyEduSolveIntern?</h1>
                    <p>Yuk daftarkan dirimu!</p>
                    <button
                        className="custom-btn"
                        onClick={() => window.open('https://glints.com/id/companies/myedusolve-indonesia/8a24f654-9b52-4e02-ae24-b50303c109ea', '_blank', 'noopener,noreferrer')}>
                        Cari Lowongan
                    </button>
                </div>
            </BottomBanner>
            <Footer />
        </div>
    );
};

export default InternPage;