import React from 'react';
import 'react-notifications/lib/notifications.css';
import moment from 'moment'


const NotificationDropdownCertificate = ({data, actionTaken, date}) => {

    return (
    <div>
      <div><img src={data.certificate?.certificate_url}/></div>
      <div>
        <h2>{data.group.description_id}</h2>
        <h3>{data.certificate?.course?.name}</h3>
        <p>{`Tanggal terbit: ${moment(data.certificate?.date_completed).format('DD MMMM YYYY')}`}</p>
        <div>
          <button className="btn btn-pink" onClick={(e) => {
            e.preventDefault()
            window.open(data.certificate?.certificate_pdf_url, "_blank")
            actionTaken(data.id)
          }} disabled={data.is_action_taken}>Download Sertifikat</button>
        </div>
      </div>
    </div>
    )
}

export default NotificationDropdownCertificate;
