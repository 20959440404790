import React from 'react';
import { Link } from 'react-router-dom'

const Route = ({name}) => {
    const style1 = {
        fontFamily: "'Poppins', 'Helvetica', 'Arial', 'sans-serif'",
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#FF0F51'
    }
    return (
        <div className="route-margin">
            <div>
                <Link to="/"><p style={style1} className="beranda-link">Beranda</p></Link>
                <p style={{...style1, fontWeight: '300', color: 'black'}} className="pl-2 pr-2">{` / `}</p>
                <p style={{...style1, fontWeight: '300', color: 'black'}}>{name}</p>
            </div>
        </div>
    )
}

export default Route;