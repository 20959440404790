import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';



const Tag = ({ }) => {
    const course_detail = useSelector(state => state.course_detail)
    const [tags, setTags] = useState([])
    
    useEffect(() => {
        if (course_detail) {
            setTags(course_detail.tags)
        }
    }, [course_detail])
    
    return (
        <>
            {course_detail !== null ? (course_detail.type !== 'external' ? (
                <section className="tag">
                <h3 className="tag__title">Tags</h3>
                <div className="tag__block">
                    {tags.map(({ name, id }) => (
                        <a key={id} style={{cursor: 'default'}}>
                            <a className="tag__text" style={{cursor: 'default'}}>{name.id}</a>
                        </a>
                    ))}
                </div>
                </section>
            ) : '') : ''}
        </>
    )
}

export default Tag;