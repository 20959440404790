import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer';
import { connect } from 'react-redux';
import { removeScript } from '../Utils/Utils';
import LandingBanner from '../components/LandingComponent/LandingBanner'
import Keunggulan from '../components/LandingComponent/Keunggulan'
import KelasPopuler from '../components/LandingComponent/KelasPopuler'
import UpcomingCourse from '../components/LandingComponent/UpcomingCourse'
import UpcomingEvent from '../components/LandingComponent/UpcomingEvent'
import Instruktur from '../components/LandingComponent/Instruktur'
import FooterLanding from '../components/LandingComponent/FooterLanding'
import RedScreen from '../components/LandingComponent/RedScreen'
import LoadingSpinner from '../Layout/LoadingSpinner';

class CourseDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount = async () => {
    if(this.props.refer) {
      this.props.dispatch({
        type: 'UPDATE_LOGINPOPUP_OPEN',
        payload: 1,
      })
      this.props.dispatch({
        type: 'UPDATE_REGISTERPOPUP_OPEN',
        payload: 1,
      })
    }
    
  };

  componentWillUnmount() {
    removeScript('https://app.sandbox.midtrans.com/snap/snap.js');
  }

  render() {
    return (
      <>
        <Header/>
        <div className="main-container container d-flex flex-column">
            <LandingBanner/>
            <Keunggulan/>
            <KelasPopuler landing={true}/>
            {/* <KategoriKelas/> */}
            <UpcomingCourse/>
            <RedScreen/>
            <UpcomingEvent/>
            <Instruktur/>
          <main>
              
          </main>
        </div>
        <FooterLanding/>
        <Footer />
        {this.props.isLoading ? <LoadingSpinner text={"Mohon menunggu.."} /> : ''}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { isLoading: state.isLoading };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetail);
