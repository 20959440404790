import React, { useEffect, useState } from 'react';
import Calendar from '../Calendar';
import { connect, useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { NotificationManager } from 'react-notifications';
import { trackEventSource } from '../../Utils/EventSourcing'
import ReactHtmlParser from 'react-html-parser';
import { Table } from 'antd';
import { CollapseDown } from '../../static';
import moment from 'moment'
moment.locale()

const RadioBtn = ({id}) => {
  const dispatch = useDispatch()
  const scheduleSelectedId = useSelector(state => state.schedule_live_id_selected)
  
  if(scheduleSelectedId === id) {
    return (
      <div >
        <img src="/images/courses/radioSelected.png" style={{width: '24px', height: '24px'}} alt="radio" onClick={(e) => {
        e.stopPropagation()
        dispatch({
          type: 'SET_SELECTED_SCHEDULE',
          payload: null,
        })
      }}/>
      </div>
      

    )
  }

  return (
    <div >
      <img src="/images/courses/radio.png" style={{width: '24px', height: '24px'}} alt="radio" onClick={(e) => {
      e.stopPropagation()
      dispatch({
        type: 'SET_SELECTED_SCHEDULE',
        payload: id,
      })
    }}/>
    </div>
    
  )
}

const PilihJadwalBtn = ({id, course_detail, isZero}) => {
  const dispatch = useDispatch()
  const enrollSessionFinal = (schedule_id) => {
    const access_token = localStorage.getItem('access_token');
    dispatch({ type: 'TOGGLE_LOADING_TRUE' })
    axios
        .post(
            course_detail.course_qna_register ? `${window.$API_URL + 'api/course/updateqnaschedule'}` : `${window.$API_URL + 'api/course/qnabook'}`,
            course_detail.course_qna_register ? {
              schedule_id: schedule_id,
              course_id: course_detail.id,
              id: course_detail.course_qna_register.id
            } : {
              schedule_id: schedule_id,
              course_id: course_detail.id,
            },
            {
              headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
            },
        )
        .then((success) => {
            dispatch({ type: 'TOGGLE_LOADING_FALSE' })
            if(course_detail.course_qna_register) {
              trackEventSource({
                type : "changeschedule",
                payload : { 
                    course_id: course_detail.id, 
                    scheduletype: 'QnA', 
                    course_type: 'hosted'
                }
              })
            } else {
              trackEventSource({
                type : "registerschedule",
                payload : { 
                    course_id: course_detail.id, 
                    scheduletype: 'QnA', 
                    course_type: 'hosted'
                }
              })
            }
            if (success.data.success) {
                NotificationManager.success('Pemilihan jadwal berhasil dilakukan!', 'Berhasil!');
                setTimeout(() => {
                    window.location.reload()
                }, 1000)
            }
        })
        .catch((error) => {
            dispatch({ type: 'TOGGLE_LOADING_FALSE' })
            if (!error.response) {
              NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
              return false
            }
            if (error.response.status === 422) {
                let errorTemp = []
                Object.keys(error.response.data.errors).forEach((key) => {
                    errorTemp.push(error.response.data.errors[key][0]);
                });
                const errorList2 = errorTemp.map((char, i) => {
                    return (
                        <li key={i} style={{ listStyleType: 'none' }}>
                            {char}
                        </li>
                    );
                });
                NotificationManager.error(errorList2, 'Validasi Error!');
            }
            else if (error.response.status === 401) {
                NotificationManager.error(error.response.data.message, 'Validasi Error!');
            } else if (error.response.status === 400) {
                NotificationManager.error(error.response.data.message, 'Validasi Error!');
            } else {
                NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
            }
        });
  }
  return (
    <div className={`btn ${isZero ? 'schedule__btn-disabled' : 'btn-pink schedule__btn-size'}`} 
    onClick={() => {
      if(!isZero) {
        if(course_detail.course_qna_register){
          if(course_detail.course_qna_register.schedule_id !== id){
            enrollSessionFinal(id)
          }
        } else {
          enrollSessionFinal(id)
        }
      }
    }} style={{fontSize: '14px', paddingTop: '4px', paddingBottom: '4px'}}>{isZero ? 'Habis' : course_detail.course_qna_register ? course_detail.course_qna_register.schedule_id === id ? 'Terpilih' : 'Pilih Jadwal' : 'Pilih Jadwal'}</div>
  )
}

const SchedulePick = ({courseForPicking}) => {
  const schedule_list = useSelector(state => state.schedule_list)
  const course_detail = useSelector(state => state.course_detail)
  const [defaultExpandedRowKeys, setDefaultExpandedRowKeys] = useState([])
  const [dataSource, setDataSource] = useState([])
  
  const newObj = (el, i) => {
    if (course_detail.type === 'live') {
      if(el.session_no && el.session_no > 1) {
        return {
          ...el,
          session: el.session_no,
          radioBtn: <RadioBtn id={el.id}/>,
          st_date: <div className="d-flex align-items-center justify-content-between">{el.st_date + ' - ' + moment(el.end_date).format("dddd, D MMMM YYYY")} <CollapseDown /></div>,
          is_expendables: true,
          ed_time: moment(el.start_date).format("HH:mm [WIB]"),
          id: `${el.id}`
        }
      } else {
        return {
          ...el,
          radioBtn: <RadioBtn id={el.id}/>,
          ed_time: moment(el.start_date).format("hh:mm [WIB]")
        }
      }
      
    } else {
      const slotRemaining = courseForPicking.qna_participant_count - el.participants_qna_user_count <= 0 ? 0 : courseForPicking.qna_participant_count - el.participants_qna_user_count
      return {
        ...el,
        radioBtn:  <PilihJadwalBtn id={el.id} course_detail={courseForPicking} isZero={!(slotRemaining)}/>,
        participants_qna_user_spot: slotRemaining ? slotRemaining : 'Habis'
      }
    }
  }
  const newSchedule = (data) => {
     return data.map((el, i) => {
      return newObj(el, (i))
    })
  }
  const [columns, setColumns] = useState([
    {
      title: '',
      dataIndex: 'radioBtn',
      key: 'radioBtn',
    },
    {
      title: 'Tanggal Mulai',
      dataIndex: 'st_date',
      key: 'st_date',
    },
    {
      title: 'Waktu',
      dataIndex: 'ed_time',
      key: 'ed_time',
    },
    {
      title: 'Sesi',
      dataIndex: 'session',
      key: 'session',
    },
    {
      title: 'Harga',
      dataIndex: 'course_price',
      key: 'course_price',
      responsive: ['sm'],
    }
  ])

  useEffect(() => {
    if(course_detail){
      if(course_detail.type === 'hosted'){
        setColumns([
          {
            title: 'Tanggal',
            dataIndex: 'st_date',
            key: 'st_date',
          },
          {
            title: 'Waktu',
            dataIndex: 'ed_time',
            key: 'ed_time',
          },
          {
            title: 'Slot Tersedia',
            dataIndex: 'participants_qna_user_spot',
            key: 'participants_qna_user_spot',
          },
          {
            title: '',
            dataIndex: 'radioBtn',
            key: 'radioBtn',
          }
        ])
      } else {
        setColumns([
          {
            title: '',
            dataIndex: 'radioBtn',
            key: 'radioBtn',
          },
          {
            title: 'Tanggal',
            dataIndex: 'st_date',
            key: 'st_date',
          },
          {
            title: 'Waktu',
            dataIndex: 'ed_time',
            key: 'ed_time',
          },
          {
            title: 'Sesi',
            dataIndex: 'session',
            key: 'session',
          },
          {
            title: 'Harga',
            dataIndex: 'course_price',
            key: 'course_price',
            responsive: ['sm'],
          }
        ])
      }
    }
  }, [course_detail])

  useEffect(() => {
    schedule_list.forEach((el, i) => {
      if (course_detail.type === 'live') {
        if(el.session_no && el.session_no > 1) {
          let temp = []
          temp.push(`${el.id}`)
          setDefaultExpandedRowKeys(temp)
        }
      }
    })
    setDataSource(newSchedule(schedule_list))
  }, [schedule_list]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="schedule-pick" style={{padding: 0}}>
      <header className="schedule__header">
        <h2 className="schedule__title">Lihat Jadwal</h2> 
          <Calendar /> 
      </header>
      <div className="schedule-pick__scroll">
        {dataSource ? 
        <Table
        rowKey="id"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        expandedRowKeys ={defaultExpandedRowKeys}
        className={`table ${courseForPicking.type === 'live' ? 'table--schedule-pick' : 'table--schedule-qna'}`}
        expandable={{
            expandedRowRender: record => <p className="table-dropdown-schedule">{ReactHtmlParser(record.about_session)}</p>,
            rowExpandable: record => record.is_expendables,
            expandIconColumnIndex: -1,
            expandRowByClick: true,
            onExpand: (ex, rec) => {
              if(defaultExpandedRowKeys.find(e => e === rec.id)) {
                setDefaultExpandedRowKeys([...defaultExpandedRowKeys].filter(el => el !== rec.id))
              } 
            }
          }}
        onRow = {record =>({
          onClick:(e)=> {
            if(!defaultExpandedRowKeys.find(e => e === record.id)) {
              setDefaultExpandedRowKeys([...defaultExpandedRowKeys, record.id])
            }
          }
        })}
        locale={
          { emptyText: <empty>Tidak ada data</empty> }
        }
      // scroll={{ x: 900 }}
      />
      : ''}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return { course_detail: state.course_detail, schedule_list: state.schedule_list }
}
export default connect(
  mapStateToProps
)(SchedulePick);