import React from 'react'
import { Carousel } from 'antd';
import { useMediaQuery } from 'react-responsive'

const CarouselBanner = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 729px)' })
    return (
        <>
        <div className="carousel-banner">
            <Carousel className="carousel-banner__carousel" autoplay={true}>
                <div className="carousel-img-container">
                   <img src={`/images/landing/homepage-new/carousel-landing-1${isMobile ? '-mob' : ''}.jpg`} alt="carousel landing"/>
                </div>
                <div className="carousel-img-container">
                   <img src={`/images/landing/homepage-new/carousel-landing-2${isMobile ? '-mob' : ''}.png`} alt="carousel landing"/>
                </div>
            </Carousel>
        </div>
        </>
    )
}

export default CarouselBanner