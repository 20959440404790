import React, { useEffect, useRef, useState } from 'react'
import DatePicker, { registerLocale } from "react-datepicker";
import Select from 'react-select';
import { Link } from 'react-router-dom';
import id from "date-fns/locale/id"; // the locale you want
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import LoadingSpinner from '../../../Layout/LoadingSpinner'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
import { NotificationManager, NotificationContainer } from 'react-notifications';
registerLocale("id", id); // register it with the name you want

const styles = {
    control: base => ({
      ...base,
      fontSize: '14px',
      fontFamily: "'Poppins', 'Helvetica', 'Arial', sans-serif"
    }),
    menu: base => ({
      ...base,
      fontSize: '14px',
      fontFamily: "'Poppins', 'Helvetica', 'Arial', sans-serif"
    })
}

const minatData = ["Self Development","Tech","Creative","Business","Entrepreneurship"]
const referenceData = ["Instagram","LinkedIn","Facebook","Google","Teman","Prakerja","Lainnya",]
// const cityData = [{ value: "Aceh", label: "Aceh" }, { value: "Sumatera Utara", label: "Sumatera Utara" }, { value: "Sumater aBarat", label: "Sumatera Barat" }, { value: "Riau", label: "Riau" }, { value: "Kepulauan Riau", label: "Kepulauan Riau" }, { value: "Jambi", label: "Jambi" }, { value: "Sumatera Selatan", label: "Sumatera Selatan" }, { value: "Kepulauan Bangka Belitung", label: "Kepulauan Bangka Belitung" }, { value: "Bengkulu", label: "Bengkulu" }, { value: "Lampung", label: "Lampung" }, { value: "DKI Jakarta", label: "DKI Jakarta" }, { value: "Banten", label: "Banten" }, { value: "Jawa Barat", label: "Jawa Barat" }, { value: "Jawa Tengah", label: "Jawa Tengah" }, { value: "DI Yogyakarta", label: "DI Yogyakarta" }, { value: "Jawa Timur", label: "Jawa Timur" }, { value: "Bali", label: "Bali" }, { value: "Nusa Tenggara Barat", label: "Nusa Tenggara Barat" }, { value: "Nusa Tenggara Timur", label: "Nusa Tenggara Timur" }, { value: "Kalimantan Barat", label: "Kalimantan Barat" }, { value: "Kalimantan Tengah", label: "Kalimantan Tengah" }, { value: "Kalimantan Selatan", label: "Kalimantan Selatan" }, { value: "Kalimantan Timur", label: "Kalimantan Timur" }, { value: "Kalimantan Utara", label: "Kalimantan Utara" }, { value: "Sulawesi Utara", label: "Sulawesi Utara" }, { value: "Gorontalo", label: "Gorontalo" }, { value: "Sulawesi Tengah", label: "Sulawesi Tengah" }, { value: "Sulawesi Barat", label: "Sulawesi Barat" }, { value: "Sulawesi Selatan", label: "Sulawesi Selatan" }, { value: "Sulawesi Tenggara", label: "Sulawesi Tenggara" }, { value: "Maluku", label: "Maluku" }, { value: "Maluku Utara", label: "Maluku Utara" }, { value: "Papua Barat", label: "Papua Barat" }, { value: "Papua", label: "Papua" }]
const educationData = [{ value: "SD", label: "Sekolah Dasar" }, { value: "SMP", label: "Sekolah Menengah Pertama (SMP)" }, { value: "SMA", label: "Sekolah Menengah Akhir (SMA)" }, { value: "Diploma", label: "Diploma" }, { value: "Sarjana", label: "Sarjana" }]
const statusData = [{ value: "Pelajar-Mahasiswa", label: "Pelajar / Mahasiswa" }, { value: "FreshGraduate", label: "Fresh Graduate" }, { value: "PencariKerja", label: "Pencari Kerja" }, { value: "Professional", label: "Professional" }, { value: "Freelance", label: "Freelance" }]

const OnBoardingRegister = () => {
    const isLoadingRedux = useSelector(state => state.isLoading)
    const dispatch = useDispatch()
    const [cityData, setCityData] = useState([])
    const [industryData, setIndustryData] = useState([])

    const [startDate, setStartDate] = useState(null)
    const [minatSelected, setMinatSelected] = useState([])
    const [referenceSelected, setReferenceSelected] = useState([])
    const [citySelected, setCitySelected] = useState('')
    const [educationSelected, setEducationSelected] = useState('')
    const [statusSelected, setStatusSelected] = useState('')
    const [industrySelected, setIndustrySelected] = useState('')
    const [positionSelected, setPositionSelected] = useState('')
    const [lainnyaSelected, setLainnyaSelected] = useState('')

    let calendarRef = useRef(null)

    const errorOne = () => {
        if(step === 1) {
            if(startDate && citySelected) return false
            if(!startDate) NotificationManager.error('Harap isi tanggal lahir.')
            if(!citySelected) NotificationManager.error('Harap isi tempat tinggal.')
            return true
        }
    }

    const errorTwo = () => {
        if(step === 2) {
            if(educationSelected && statusSelected) return false
            if(!educationSelected) NotificationManager.error('Harap isi pendidikan terakhir.')
            if(!statusSelected) NotificationManager.error('Harap isi status.')
            return true
        }
    }

    const [step, setStep] = useState(1)
    const stepOne = (e) => {
        e.preventDefault()
        setStep(1)
    }
    const stepTwo = (e) => {
        e.preventDefault()
        setStep(2)
    }
    const stepThree = (e) => {
        e.preventDefault()
        setStep(3)
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        submitOnBoarding()
    }
    

    const findMinatRef = (minatFind, arraySelected) => arraySelected.indexOf(minatFind)
    const handleMinatRef = (minatFind, arraySelected, setArraySelected) => {
        let found = findMinatRef(minatFind, arraySelected)
        let _array = [...arraySelected]
        if(found < 0) _array.push(minatFind)
        else _array.splice(found, 1)
        setArraySelected(_array)
    }

    const submitOnBoarding = () => {
        const _minatSelected = []
        minatSelected.forEach(el => {
            let category_id = findMinatRef(el, minatData) + 1
            _minatSelected.push({
                category_id
            })
        })

        const _referenceSelected = []
        referenceSelected.forEach(el => {
            if(el !== 'Lainnya') _referenceSelected.push(el)
            else _referenceSelected.push(lainnyaSelected || 'Lainnya')
        })

        dispatch({ type: 'TOGGLE_LOADING_TRUE' })
        const access_token = localStorage.getItem('access_token')
        axios({
            method: 'PUT',
            url: `${window.$API_URL}api/student/updateonboarding`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
            data: {
                "location_id": citySelected.value,
                "dob": moment(startDate).format("YYYY-MM-DD"),
                "last_education": educationSelected.value,
                "professional_status": statusSelected.value,
                "job_position": positionSelected,
                "industry_id": industrySelected.value,
                "interests": _minatSelected,
                "heard_from": _referenceSelected
            }
        })
        .then(result => {
            NotificationManager.success('Terimakasih telah mengisi proses on boarding! Mengantarkan anda ke Home..', 'Berhasil Update');
            setTimeout(() => window.location.href = '/', 1000);
        })
        .catch(error => {
            let _obj = error?.response?.data?.errors || {}
            NotificationManager.error(_obj[Object.keys(_obj)[0]][0], 'Error');
        })
        .finally(_ => dispatch({ type: 'TOGGLE_LOADING_FALSE' })) 
    }

    const getCityData = () => {
        dispatch({ type: 'TOGGLE_LOADING_TRUE' })
        const access_token = localStorage.getItem('access_token')
        axios({
            method: 'GET',
            url: `${window.$API_URL}api/student/locations`,
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
        })
        .then(result => {
            let _cityData = []
            result.data.data.forEach(el => {
                _cityData.push({
                    value: el.id,
                    label: el.name
                })
            })
            setCityData(_cityData)
        })
        .finally(_ => dispatch({ type: 'TOGGLE_LOADING_FALSE' })) 
    }

    const getIndustryData = () => {
        dispatch({ type: 'TOGGLE_LOADING_TRUE' })
        const access_token = localStorage.getItem('access_token')
        axios({
            method: 'GET',
            url: `${window.$API_URL}api/student/industries`,
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
        })
        .then(result => {
            let _industryData = []
            result.data.data.forEach(el => {
                _industryData.push({
                    value: el.id,
                    label: el.name
                })
            })
            setIndustryData(_industryData)
        })
        .finally(_ => dispatch({ type: 'TOGGLE_LOADING_FALSE' })) 
    }

    useEffect(() => {
        getCityData()
        getIndustryData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
        <div className="onboarding-register">
            <div className="container">
                <div><Link to="/">
                    <img src="/images/header-small.png" alt="MyEduSolveX" />
                </Link></div>
                <div>
                    <div className="inner-container">
                        <div>
                            <p>{`Step ${step}/3`}</p>
                        </div>
                        <div>
                            <div className={step === 1 ? 'selected' : ''}><div/></div>
                            <div className={step === 2 ? 'selected' : ''}><div/></div>
                            <div className={step === 3 ? 'selected' : ''}><div/></div>
                        </div>
                        <div>
                            <h2>{step === 3 ? 'Dari Mana anda mengetahui MyEduSolve?' : 'Data Profil'}</h2>
                            {step === 1 ? 
                            <form>
                                <div class="form-group">
                                    <label for="tanggal-lahir">Tanggal Lahir<span>*</span></label>
                                    <div className="form-control form-datepicker" >
                                        <CalenderIcon/>
                                        <DatePicker yearDropdownItemNumber={50}
                                        selected={startDate} onChange={(date) => setStartDate(date)} ref={(c) => calendarRef = c}
                                        id="tanggal-lahir" dateFormatCalendar="MMMM" scrollableYearDropdown
                                        dateFormat="dd MMMM yyyy" locale="id" showYearDropdown maxDate={moment().toDate()}
                                        popperModifiers={[ { name: "offset", options: {  offset: [-28, 10] } } ]}
                                        />
                                        <div onClick={() => calendarRef?.setOpen(true)}><DropdownIcon/></div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="tempat-tinggal">Tempat Tinggal<span>*</span></label>
                                    <Select
                                        value={citySelected}
                                        onChange={setCitySelected}
                                        options={cityData} id="tempat-tinggal" placeholder="Provinsi"
                                        className="form-provinsi" styles={styles}
                                    />
                                </div>
                                <h4><span>*</span>) Kolam tersebut wajib diisi</h4>
                                <button type="submit" class="btn btn-pink" onClick={(e) => {
                                    e.preventDefault()
                                    if(!errorOne()) stepTwo(e)
                                }}>Selanjutnya</button>
                            </form> : ''}
                            {step === 2 ? 
                            <form>
                                <div class="form-group">
                                    <label for="pendidikan">Pendidikan Terakhir<span>*</span></label>
                                    <Select
                                        value={educationSelected}
                                        onChange={setEducationSelected}
                                        options={educationData} id="pendidikan" placeholder="Pilih Pendidikan Terakhir"
                                        className="form-provinsi" styles={styles}
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="status">Status<span>*</span></label>
                                    <Select
                                        value={statusSelected}
                                        onChange={setStatusSelected}
                                        options={statusData} id="status" placeholder="Pilih Status"
                                        className="form-provinsi" styles={styles}
                                    />
                                </div>
                                {statusSelected.value === "Professional" || statusSelected.value === "Freelance" ? 
                                <>
                                <div class="form-group">
                                    <label for="industri">Industri</label>
                                    <Select
                                        value={industrySelected}
                                        onChange={setIndustrySelected}
                                        options={industryData} id="industri" placeholder="Pilih Industri"
                                        className="form-provinsi" styles={styles}
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="posisi">Posisi Pekerjaan Sebagai</label>
                                    <input type="text" class="form-control" id="posisi" placeholder="Posisi Pekerjaan" value={positionSelected} onChange={(e) => setPositionSelected(e.target.value)}/>
                                </div>
                                </> : ''
                                }
                                <div class="form-group form-minat">
                                    <label>Minat</label>
                                    <div>
                                        {minatData.map(el => <div onClick={() => handleMinatRef(el, minatSelected, setMinatSelected)} className={findMinatRef(el, minatSelected) < 0 ? 'unselected' : ''}><p>{el}</p></div>)}
                                    </div>
                                </div>
                                <h4><span>*</span>) Kolam tersebut wajib diisi</h4>
                                <button type="submit" class="btn btn-pink" onClick={(e) => {
                                    e.preventDefault()
                                    if(!errorTwo()) stepThree(e)
                                }}>Selanjutnya</button>
                                <button type="submit" class="btn btn-white" onClick={stepOne}>Kembali</button>
                            </form> : ''}
                            {step === 3 ? 
                            <form>
                                <div class="form-group form-minat">
                                    <div>
                                        {referenceData.map(el => <div onClick={() => handleMinatRef(el, referenceSelected, setReferenceSelected)} className={findMinatRef(el, referenceSelected) < 0 ? 'unselected' : ''}><p>{el}</p></div>)}
                                    </div>
                                </div>
                                {findMinatRef('Lainnya', referenceSelected) >= 0 ? 
                                <div class="form-group">
                                    <label for="posisi">Lainnya</label>
                                    <input type="text" class="form-control" id="posisi" placeholder="Dari mana anda mengetahui MyEduSolve?" value={lainnyaSelected} onChange={(e) => setLainnyaSelected(e.target.value)}/>
                                </div> : ''}
                                <button type="submit" class="btn btn-pink" onClick={handleSubmit}>Submit</button>
                                <button type="submit" class="btn btn-white" onClick={stepTwo}>Kembali</button>
                            </form> : ''}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {isLoadingRedux ? <LoadingSpinner text={'Mohon Tunggu..'} /> : ''}
        <NotificationContainer/>
        </>
    )
}

const CalenderIcon = () => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 6.5C11 6.36739 11.0527 6.24021 11.1464 6.14645C11.2402 6.05268 11.3674 6 11.5 6H12.5C12.6326 6 12.7598 6.05268 12.8536 6.14645C12.9473 6.24021 13 6.36739 13 6.5V7.5C13 7.63261 12.9473 7.75979 12.8536 7.85355C12.7598 7.94732 12.6326 8 12.5 8H11.5C11.3674 8 11.2402 7.94732 11.1464 7.85355C11.0527 7.75979 11 7.63261 11 7.5V6.5Z" fill="black"/>
<path d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0ZM1 4V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H14C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14V4H1Z" fill="black"/>
</svg>
const DropdownIcon = () => <svg width="12" height="8"><svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.41 0.580002L6 5.17L10.59 0.580002L12 2L6 8L0 2L1.41 0.580002Z" fill="#20282E"/>
</svg></svg>


export default OnBoardingRegister