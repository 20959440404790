import React from 'react';
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert';
import 'react-notifications/lib/notifications.css';
import LoadingSpinner from '../../Layout/LoadingSpinner';

class CustomUrlPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoginActive: 1,
      isRegisterActive: 0,
      username: '',
      email: '',
      password: '',
      password_confirmation: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      alert: '',
      loginemail: '',
      loginpassword: '',
      loading: 0,
      api_url: window.$API_URL,
      initial_api_url: window.$API_URL,
    };
  }

  dismissModalSignInPopup = () => {
    this.props.toggleSignInPopup();
  };

  changeCustomUrl = (e) => {
    e.preventDefault();
    if (this.state.api_url === '') {
      NotificationManager.error('New Custom URL Cannot be empty.', 'Validasi Error!');
    } else {
      localStorage.setItem('api_url', this.state.api_url);
      window.location.reload();
    }
  };

  removeeCustomUrl = () => {
    localStorage.removeItem('api_url');
    window.location.reload();
  };

  render() {
    return (
      <>
        <div
          className={`sign_up_modal modal fade ${this.props.isOpen ? 'show' : ''}`}
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
          style={{ display: `${this.props.isOpen ? 'block' : 'none'}` }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.dismissModalSignInPopup}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <ul className="sign_up_tab nav">
                <li className="nav-item active fullWidthCss ">
                  <a className="nav-link pointer active">Custom API Url</a>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active">
                  <div className="login_form">
                    <form action="#">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Custom API Url"
                          defaultValue={window.$API_URL}
                          onChange={(e) => this.setState({ api_url: e.target.value })}
                        />
                      </div>
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-thm2"
                          onClick={(e) => this.changeCustomUrl(e)}
                        >
                          Change API URL
                        </button>

                        <button
                          type="button"
                          className="btn  btn-thm2"
                          onClick={(e) => this.removeeCustomUrl(e)}
                        >
                          Keep Default
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.alert}
        <NotificationContainer />
        {this.state.loading ? <LoadingSpinner /> : ''}
      </>
    );
  }
}

export default CustomUrlPopup;
