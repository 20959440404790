import React from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux'

//Functions
import { getCourseDetail } from '../../../../Utils/Utils';

const SchedulePickButton = ({isMobile, isChangeSched, SetModalOpen, course}) => {
    const dispatch = useDispatch()
    const history = useHistory()
    return (
        <>
            <div className={`btn btn-${isChangeSched ? 'white' : 'pink'} btn-pilih-jadwal`} style={isChangeSched ? {padding: '7px'} : {}}
            onClick={(e) => {
                async function fetchMyAPI() {
                    let payload = await getCourseDetail(course.id)
                    dispatch({
                        type: 'ADD_COURSEDETAIL',
                        payload
                    })
                    if(isMobile) {
                        dispatch({
                            type: 'COURSE_ROW_SCHEDULE',
                            payload: course
                        })
                        if(payload) history.push('/student/schedulepick')
                    } else {
                        SetModalOpen(true)
                    }
                }
                fetchMyAPI()
            }}>{isChangeSched ? 'Ganti Jadwal' : 'Pilih Jadwal'}</div>
        </>
    )
}

export default SchedulePickButton