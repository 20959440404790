import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {  NotificationManager } from 'react-notifications';
var fileDownload = require('js-file-download');

const sampleData = [
    {
        name: 'tugas 1',
        desc: 'ini adalah tugas 1',
        type: 'pdf'
    },
    {
        name: 'tugas 2',
        desc: 'ini adalah tugas 2',
        type: 'xls'
    },
    {
        name: 'tugas 3',
        desc: 'ini adalah tugas 3',
        type: 'xls'
    },
    {
        name: 'tugas 4',
        desc: 'ini adalah tugas 4',
        type: 'xls'
    },
    {
        name: 'tugas 5',
        desc: 'ini adalah tugas 5',
        type: 'pdf'
    },
    {
        name: 'tugas 6',
        desc: 'ini adalah tugas 6',
        type: 'ppt'
    },
    {
        name: 'tugas 7',
        desc: 'ini adalah tugas 7',
        type: 'ppt'
    }
]

const ResourceRow = ({content}) => {
    return (
        <div className="course-resource__row">
            <img src={"/images/courses/resourcePdf.png"} alt="rsc-icon" className="course-resource__icon"/>
            <div className="course-resource__content">
                <p className="course-resource__name">{content.title}</p>
            </div>
            <div className="course-resource__btn" 
                onClick={(e) => {
                    window.open(content.media_url, "_blank")
                }}> 
                <img src="/images/courses/downloadrsc.png" className="course-resource__icon-dl"/>
                <p className="course-resource__desc__bold">Download</p>
            </div>
        </div>
    )
}

const CourseResource = () => {
    const course_ebook = useSelector(state => state.course_ebook)
    return (
        <>
        <div className="course-resource">
            <h1 className="course-resource__title mb-4">E-book Kelas</h1>
            {
                course_ebook ? course_ebook[0] ? (
                    course_ebook.map(el => <ResourceRow content={el}/> )
                ) : '' : ''
            }
        </div>
        </>
    )
}

export default CourseResource