import moment from 'moment';
import React from 'react';
import { CourseExIconBlue, CourseHostedIconBlue, CourseLiveIconBlue } from '../../static';

const FullStar = () => {
  return (
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 19.5875L20.725 24.25L18.675 15.4625L25.5 9.55L16.5125 8.7875L13 0.5L9.4875 8.7875L0.5 9.55L7.325 15.4625L5.275 24.25L13 19.5875Z"
        fill="#FFC632"
      />
    </svg>
  );
};

const EmptyStar = () => {
  return (
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.5 9.55L16.5125 8.775L13 0.5L9.4875 8.7875L0.5 9.55L7.325 15.4625L5.275 24.25L13 19.5875L20.725 24.25L18.6875 15.4625L25.5 9.55ZM13 17.25L8.3 20.0875L9.55 14.7375L5.4 11.1375L10.875 10.6625L13 5.625L15.1375 10.675L20.6125 11.15L16.4625 14.75L17.7125 20.1L13 17.25Z"
        fill="#FFC632"
      />
    </svg>
  );
};

const CourseListDone = ({ course }) => {
  const stars = () => {
    let count = [];
    for (let i = 0; i < course.myrating.rating; i++) count.push('star');
    return count;
  };
  const empties = () => {
    let count = [];
    for (let i = 0; i < 5 - course.myrating.rating; i++) count.push('star');
    return count;
  };
  return (
    <>
      <div className="course-card">
        <div className="row">
          <div className="col-xl-4 col-md-4 col-sm-3 col-5">
            <div
              className=" course-card__img"
              style={{ backgroundImage: `url(${course.thumbnail ? course.thumbnail.url : ''})` }}
            >
              <div className="d-flex justify-content-end pt-3 pr-3">
                {course.kartu_prakerja ? (
                  <p className="course-card__prakerja pt-1 pb-1 pr-2 pl-2">Kartu Prakerja</p>
                ) : (
                  ''
                )}
              </div>
            </div>
            {!course.thumbnail ? <p>No Image</p> : ''}
          </div>
          <div className="col-xl-8 col-lg-8 col-md-7 col-sm-7 col-7 course-card__content">
            <div className="d-flex w-100 align-items-center">
              {course.type === 'external' ? (
                <div className="d-flex align-items-center">
                  <CourseExIconBlue />
                  <p className="course-card__type ml-2">External</p>
                </div>
              ) : (
                ''
              )}
              {course.type === 'live' ? (
                <div className="d-flex align-items-center">
                  <CourseLiveIconBlue /> <p className="course-card__type ml-2">Live</p>
                </div>
              ) : (
                ''
              )}
              {course.type === 'hosted' ? (
                <div className="d-flex align-items-center">
                  <CourseHostedIconBlue />
                  <p className="course-card__type ml-2">Self-Paced</p>
                </div>
              ) : (
                ''
              )}
              <div className="navbar-desc ml-auto-mobile-done ">
                <div className="d-flex">
                  <p className="mr-3">Ulasan: </p>
                  <div className=" d-flex">
                    {stars().map((el) => (
                      <FullStar />
                    ))}
                    {empties().map((el) => (
                      <EmptyStar />
                    ))}
                  </div>
                  <div className="course-card__selesai d-flex justify-content-center align-items-center ml-4-mobile">
                    Selesai
                  </div>
                </div>
              </div>
              <div className="navbar-mob ml-auto">
                <div className="d-flex">
                  <div className="course-card__selesai d-flex justify-content-center align-items-center ml-4-mobile">
                    Selesai
                  </div>
                </div>
              </div>
            </div>

            <h2 className="course-card__class-name">{course.name}</h2>
            <h3 className="course-card__instructor-name">{course.instructor.fullname}</h3>
            {course.type === 'hosted' ? (
              <div className="d-flex">
                <div
                  className="btn btn-pink btn-pilih-jadwal ml-auto"
                  onClick={() =>
                    (window.location.href = '/course/login/' + course.id + '/' + course.slug)
                  }
                >
                  Akses Kelas
                </div>
              </div>
            ) : (
              ''
            )}

            {course.type === 'live' && course.course_schedule_register !== null ? (
              course.course_schedule_register.schedule !== null ? (
                <div className="navbar-desc">
                  <div className="mt-3 d-flex align-items-center">
                    <p className="course-card__bold-text">Dilaksanakan Pada Tanggal:</p>
                    <p className="ml-2">{`${moment(
                      course.course_schedule_register.schedule.start_date,
                    ).format('DD MMMM YYYY')} | ${moment(
                      course.course_schedule_register.schedule.start_date,
                    ).format('HH.mm')} - ${moment(
                      course.course_schedule_register.schedule.end_date,
                    ).format('HH.mm')} WIB`}</p>
                  </div>
                </div>
              ) : (
                ''
              )
            ) : (
              ''
            )}

            {/* {course.type === "hosted" ? (
                               <p className="mt-3" style={{fontSize: '16px'}}>{`${course.material.count} Materi | Total durasi ${moment.utc().startOf('day').add({ minutes: course.material.duration }).format('HH')} jam ${moment.utc().startOf('day').add({ minutes: course.material.duration }).format('mm')} min`}</p>
                            ) : ''} */}
          </div>
          {course.type === 'live' && course.course_schedule_register !== null ? (
            course.course_schedule_register.schedule !== null ? (
              <div className="col-md-12 col-sm-12 col-lg-12 course-card__content navbar-mob mt-2">
                <div className="mt-3 d-flex align-items-center">
                  <p className="course-card__bold-text">Dilaksanakan Pada Tanggal:</p>
                  <p
                    className="ml-1 course-card__bold-text"
                    style={{ fontWeight: '300' }}
                  >{`${moment(course.course_schedule_register.schedule.start_date).format(
                    'DD MMMM YYYY',
                  )} | ${moment(course.course_schedule_register.schedule.start_date).format(
                    'HH.mm',
                  )} - ${moment(course.course_schedule_register.schedule.end_date).format(
                    'HH.mm',
                  )} WIB`}</p>
                </div>
              </div>
            ) : (
              ''
            )
          ) : (
            ''
          )}
          <div className="navbar-mob">
            <div className="col-lg-8 col-md-7 col-sm-7 col-6 course-card__content mt-4">
              <div className="ml-auto-mobile-done d-flex">
                <p className="mr-3 mb-3">Ulasan: </p>
                <div className=" d-flex mb-3">
                  {stars().map((el) => (
                    <FullStar />
                  ))}
                  {empties().map((el) => (
                    <EmptyStar />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseListDone;
