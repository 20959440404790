import React from 'react';
// import { Link} from 'react-router-dom'
// import { Redirect, Route,  } from 'react-router-dom';
// import * as router from 'react-router-dom';
class LandingMenuMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  dismissMobileMenu = () => {
    this.props.toggleMobileMenu();
  };

  render() {
    return (
      <>


        <div id="page" className={`stylehome1 h0 ${(this.props.cssClass != undefined) ? this.props.cssClass : ''}`}>
          <div className="mobile-menu">
            <div className="header stylehome1">
              <div className="main_logo_home2">
                <img className="nav_logo_img img-fluid float-left mt20 image-responsive" src="/images/images_logo-light-background.png" alt="images_logo-light-background.png" />
              </div>
              <ul className="menu_bar_home2">
                <li className="list-inline-item">

                </li>
                <li className="list-inline-item pointer" onClick={(e) => this.dismissMobileMenu()}>
                  <a ><span></span></a>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </>
    );
  }
}

export default LandingMenuMobile;
