import React from 'react'

const OurClient = () => {
    const topRow = [1,2,3,4,5,6]  
    const botomRow = [1,2,3,4,5]  

    return (
        <>
          <div className="our-client">
            <div>
              <h1>Our Clients</h1>
              <div>{topRow.map(i => <div><img src={`/images/landing/homepage-new/toprowclient/${i}.png`} alt="client top"/></div>)}</div>
              <div>{botomRow.map(i => <div><img src={`/images/landing/homepage-new/bottomrowclient/${i}.png`}alt="client bottom"/></div>)}</div>
              <p>and Many More</p>
              <a href = "mailto: hello@myedusolve.com" className="btn btn-pink">Let's Collaborate!</a>
            </div> 
          </div>
        </>
    )
}

export default OurClient