import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyCertificates, fetchMyCourses } from '../Utils/Utils';

import MyCourse from '../components/MyCourse';

const KelasSaya = () => {
  const userName = useSelector((state) => state.profile_data);
  const dispatch = useDispatch();

  const fetchCertificates = () => {
    dispatch({ type: 'TOGGLE_LOADING_TRUE' });
    fetchMyCertificates()
      .then((response) => {
        dispatch({ type: 'SET_USER_CERTIFICATES', payload: response });
      })
      .catch((error) => {
        return error;
      })
      .finally(() => dispatch({ type: 'TOGGLE_LOADING_FALSE' }));
  };

  const fetchCourse = () => {
    dispatch({ type: 'TOGGLE_LOADING_TRUE' });
    fetchMyCourses()
      .then((response) => {
        dispatch({ type: 'SET_USER_COURSE', payload: response.data });
        dispatch({ type: 'TOGGLE_LOADING_FALSE' });
      })
      .catch((error) => {
        return error;
        dispatch({ type: 'TOGGLE_LOADING_FALSE' });
      });
  };

  useEffect(() => {
    fetchCourse();
    fetchCertificates();
  }, []);

  return (
    <>
      <div className="dashboard-container">
        <h1 className="content-user__title mt-4 navbar-desc">Kelas Saya</h1>
        {userName && (
          <h1
            className="content-user__title mt-3 navbar-desc"
            style={{ fontSize: '16px' }}
          >{`Selamat datang kembali ${userName.first_name}, belajar lagi yuk!`}</h1>
        )}

        <div>
          <MyCourse />
        </div>
      </div>
    </>
  );
};

export default KelasSaya;
