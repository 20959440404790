import React from 'react';
// import { Link} from 'react-router-dom'
// import { Redirect, Route,  } from 'react-router-dom';
// import * as router from 'react-router-dom';
class LandingTopRatedSection extends React.Component {
  render() {
    return (
      <>
        <section className="home3_about2 pb10 pt30">
          <div className="container">

            <div className="row mt60">
              <div className="col-sm-6 col-lg-6 col-xl-6">
                <div className="becomea_instructor_home3 style1">
                  <div className="bi_grid">
                    <h3>Menjadi Instruktur</h3>
                    <p>
                      MyEduSolve X memberikan kesempatan kepada Anda untuk membagikan keahlian dan minat yang dimiliki dalam bentuk kelas online.
                    </p>
                    <a className="btn btn-white" href="https://www.surveymonkey.com/r/SPM7BQV" target="_new">
                      Mulai Mengajar
                      <span className="flaticon-right-arrow-1" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-6 col-xl-6">
                <div className="becomea_instructor_home3 style2">
                  <div className="bi_grid">
                    <h3>Mengenai Kartu Prakerja</h3>
                    <p>
                      Dapatkan pelatihan gratis dan bermanfaat dengan Kartu Prakerja.
                      <br className="dn-lg" /> Telusuri Sekarang!
                    </p>
                    <a className="btn btn-white" href="https://www.prakerja.go.id/" target="_new">
                      Lihat Info Prakerja
                      <span className="flaticon-right-arrow-1" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default LandingTopRatedSection;
