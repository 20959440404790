import React from 'react';
import 'react-notifications/lib/notifications.css';
import { useSelector } from 'react-redux';

const CertificatesFreeCourse = () => {
    const certId = useSelector(state => state.cert_id_download)
    const downloadCertificateTrigger = () => {
       window.open(certId, "_blank")
    }
    
    return (
            <div className="exam-finish d-flex align-items-center justify-content-center"
                style={{background: 'url("/images/courses/examFinish.png")'}}
            >
                <div className="exam-finish__modal d-flex flex-column align-items-center">
                    <h1 className="exam-finish__title">Selamat, kamu telah menyelesaikan kelas!</h1>
                    <a className='btn exam-finish__btn mt-5 w-100 btn-pink'
                    onClick={() => {
                        downloadCertificateTrigger()
                    }}>
                        Download Sertifikat
                    </a>
                </div>
            </div>
    )
}

export default CertificatesFreeCourse;