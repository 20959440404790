import React, { useEffect, useState } from 'react'
import { CloseModals } from '../../static';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import Lottie from 'react-lottie'
import * as checkAnimation from '../../static/checkAnimation.json'
import * as xAnimation from '../../static/checkFailAnimation.json'
import { pause } from 'video-react/lib/actions/player';

const VoucherModals = ({isModalOpen, closeModal, title, isSuccess, msgId, usedByOther, refreshInsted}) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [paused, setPause] = useState(true)

    const checkIcon = {
        loop: false,
        autoplay: true,
        animationData: checkAnimation.default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }
    const xIcon = {
        loop: false,
        autoplay: true,
        animationData: xAnimation.default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    useEffect(() => {
        setPause(!isModalOpen)
    }, [isModalOpen])


    return (
        <>
            <div
            className={`course_modal modal fade ${(isModalOpen) ? 'show' : ''}`}
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
            style={{ display: `${(isModalOpen) ? 'block' : 'none'}` }}
            >
                <div className="modal-dialog-course modal-dialog-centered modal-size-course" role="document">
                    <div className="modal-content-course">
                        <div className="voucher-modal-content">
                            {isSuccess ? 
                                <Lottie options={checkIcon} height={180} width={180} isStopped={paused} />
                            :
                                <Lottie options={xIcon} height={180} width={180} isStopped={paused} />
                            }
                            <p className="voucher-modal-content__title">{title}</p>
                            <div  className="voucher-modal-content__desc-blue">
                                {msgId == "not-found" ? <VoucherNotFound/> : ''}
                                {msgId == "already-used" ? <VoucherAlreadyUsed/> : ''}
                                {msgId == "already-used-email" ? <VoucherAlreadyUsedEmail msg={usedByOther}/> : ''}
                                {msgId == "success-public" ? <VoucherSuccessPublic/> : ''}
                                {msgId == "sucess-login" ? <VoucherSuccessLogin/> : ''}
                                {msgId == "course-owned" ? <VoucherAlreadyUsedByUser/> : ''}
                            </div>
                            <div className="btn btn-pink"  onClick={() =>{
                                if(msgId == 'success-login') {
                                    closeModal()
                                    if(refreshInsted) window.location.reload()
                                    else history.push('/student/course')
                                    localStorage.removeItem('voucher_code')
                                } else if(msgId == 'success-public') {
                                    closeModal()
                                    
                                    dispatch({
                                        type: 'UPDATE_LOGINPOPUP_OPEN',
                                        payload: 1,
                                    })
                                } else {
                                    closeModal()
                                    localStorage.removeItem('voucher_code')
                                    localStorage.removeItem('voucher_register')
                                    dispatch({type: 'STORE_VOUCHER_CODE', payload: null})
                                }
                                }}>Oke</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const VoucherNotFound = () => {
    return (
        <p>Voucher yang Anda masukan tidak tersedia, Silahkan <b>Masukkan kode Voucher kembali!</b></p>
)
}

const VoucherAlreadyUsed = () => {
    return (
        <p>Voucher yang Anda masukan sudah digunakan, Silahkan <b>Masukkan kode Voucher lain!</b></p>
    )
}

const VoucherAlreadyUsedEmail = ({msg}) => {
    const msgArr = msg ? msg.split(' ') : []
    const email = msgArr.pop()
    const msgToShow = msgArr.join(' ')
    return (
        <p>{msgToShow} <b>{email}</b></p>
    )
}

const VoucherAlreadyUsedByUser = () => {
    return (
        <p>Anda sudah memiliki Kelas yang sama dari voucher ini. <b>Masukkan kode Voucher lain!</b></p>
    )
}

const VoucherSuccessPublic = () => {
    return (
        <p>Silahkan <b>membuat akun atau login ke akun</b> Anda untuk menukarkan voucher </p>
    )
}

const VoucherSuccessLogin = () => {
    return (
        <p>Voucher yang anda masukkan berhasil digunakan! Silahkan cek <b>profil Anda</b> untuk melihat Kelas Anda.</p>
    )
}

export default VoucherModals