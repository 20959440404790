import React from 'react';
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert';
import 'react-notifications/lib/notifications.css';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import { connect } from 'react-redux'
import { CloseModals } from '../../static';

class VoucherPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      voucher_code: '',
      voucher_data: []
    };
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    document.addEventListener("mousedown", this.handleClickOutside, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
    document.removeEventListener("mousedown", this.handleClickOutside, false);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      if (this.props.isOpen) {
        this.dismissModalSignInPopup();
      }
    }
  }

  handleClickOutside = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
    //the click is outside
    if (this.props.isOpen) {
      this.dismissModalSignInPopup();
    }

  }


  dismissModalSignInPopup = () => {
    this.props.toggleModalVoucherPage();
  };

  enableVoucherSubmit = () => {
    this.props.toggleModalVoucherPage();
    this.props.toggleFinalSubmitPage(this.state.voucher_code);
  };

  confirmVoucherRedemption = () => {
    this.setState({
      alert: ''
    })
    this.enableVoucherSubmit();
  }

  cancelVoucherRedemption = () => {
    this.setState({
      voucher_code: '',
      voucher_data: [],
      alert: ''
    });
    this.dismissModalSignInPopup();
  }

  checkValidVoucher = () => {
    this.setState({
      loading: true,
    });
    const access_token = localStorage.getItem('access_token');
    axios
      .get(
        `${window.$API_URL}api/voucher/checkvoucher/?voucher_code=` + this.state.voucher_code,
        {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
        },
      )
      .then((success) => {
        this.setState({
          loading: false,
          voucher_data: success.data.data
        });
        if (success.data.success && !success.data.data.is_purchased) {
          const getAlert = () => (
            <SweetAlert
              success
              showCancel={true}
              cancelBtnText="Batal"
              confirmBtnText="Lanjutkan"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="warning"
              title="Kode Voucher Valid!"
              onConfirm={(e) => this.confirmVoucherRedemption(e)}
              onCancel={(e) => this.cancelVoucherRedemption(e)}
            >
              Kode voucher  yang Anda peroleh dari {(this.state.voucher_data.mpp.name !== undefined) ? this.state.voucher_data.mpp.name : 'MPP'} berhasil terkonfirmasi. Silakan memasukkan alamat email dan nama Anda untuk melanjutkan.
            </SweetAlert>
          );
          this.setState({
            alert: getAlert(),
          });

        } else if (success.data.success && success.data.data.is_purchased) {
          if (success.data.data.is_external) {
            const getAlert = () => (
              <SweetAlert
                success
                showCancel={false}
                confirmBtnText="Click Here"
                confirmBtnBsStyle="success"
                title="Voucher Berhasil Ditebus!"
                onConfirm={(e) => window.location.href = success.data.data.external_url}
              >
                Selamat! Voucher Anda telah berhasil ditebus. Untuk mengakses Pelatihan, Silakan kunjungi Situs Mitra Kami.
              </SweetAlert>
            );
            this.setState({
              alert: getAlert(),
            });
          } else {
            if (success.data.data.course_type == "live") {
              var redirectUrl = '/student/profile';
            } else {
              var redirectUrl = '/student/profile';
            }
            const getAlert = () => (
              <SweetAlert
                success
                showCancel={false}
                confirmBtnText="Ok"
                confirmBtnBsStyle="success"
                title="Voucher Berhasil Ditebus!"
                onConfirm={(e) => window.location.href = redirectUrl}
              >
                Selamat! Voucher Anda telah berhasil ditebus. Untuk mengakses Pelatihan, Silakan kunjungi Profil Saya.
              </SweetAlert>
            );
            this.setState({
              alert: getAlert(),
            });
          }
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (!error.response) {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
          return false
      }
        if (error.response.status === 422) {
          let _returnError = [];
          Object.keys(error.response.data.errors).forEach((key) => {
            _returnError.push(error.response.data.errors[key][0]);
          });
          let _showing = true;
          this.setState({ returnError: _returnError, showing: _showing });
          const errorList2 = this.state.returnError.map((char, i) => {
            return (
              <li key={i} style={{ listStyleType: 'none' }}>
                {char}
              </li>
            );
          });
          NotificationManager.error(errorList2, 'Validasi Error!');
        } else if (error.response.status === 404) {
          NotificationManager.error(error.response.data.message, 'Validasi Error!');
        } else {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        }
      });
  }

  render() {
    return (
      <>
        <div
          className={`sign_up_modal modal fade ${(this.props.isOpen || this.props.is_voucher_popup_open_video_section || this.props.voucher_popup_open) ? 'show' : ''}`}
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
          style={{ display: `${(this.props.isOpen || this.props.is_voucher_popup_open_video_section || this.props.voucher_popup_open) ? 'block' : 'none'}` }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document" ref={node => this.node = node}>
            <div className="modal-content">
              
              <div className="pointer d-flex justify-content-end pt-3 pr-3" onClick={this.dismissModalSignInPopup}>
                <CloseModals className="ml-auto" />
              </div>
              <ul className="sign_up_tab nav">
                <li className="nav-item active fullWidthCss ">
                  <h3 className="assignment__title mb-3 mt-4">Masukkan Kode Voucher</h3>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active">
                  <div className="login_form">
                    <form action="#">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Masukkan Kode Voucher"
                          value={this.state.voucher_code}
                          onChange={(e) => this.setState({ voucher_code: e.target.value })}
                        />
                      </div>
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-pink w-100"
                          onClick={(e) => this.checkValidVoucher(e)}
                        >
                          Periksa
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.alert}
        <NotificationContainer />
        {this.state.loading ? <LoadingSpinner text={this.state.loading_text} /> : ''}
      </>
    );
  }
}

const mapStateToProps = state => {
  return { voucher_popup_open: state.voucher_popup_open }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VoucherPopup);
