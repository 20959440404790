import React from 'react';

export default function KpjCodeVerification({
  handleSubmit,
  voucherKpj,
  loadingVoucherKpj,
  setVoucherKpj,
}) {
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: '8px',
          padding: '16px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <h4 style={{ fontWeight: '600', fontSize: '18px', marginBottom: '0' }}>
          Insert KPJ Verification Code
        </h4>

        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Enter your code"
            className="search__input"
            style={{ margin: '0', maxWidth: '100%', marginBottom: '16px' }}
            value={voucherKpj}
            onChange={(e) => setVoucherKpj(e.target.value)}
          />

          <div style={{ display: 'flex', gap: '16px' }}>
            <button
              type="submit"
              disabled={loadingVoucherKpj}
              className="btn btn-pink"
              style={{ width: '100%' }}
            >
              {loadingVoucherKpj ? 'Loading...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
