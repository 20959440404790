import React from 'react'
import EventList from './EventList'
import { useSelector } from 'react-redux'

const MyEvent = () => {
    const studentEvent = useSelector(state => state.student_event)
    const blankBg = () => {
        if(studentEvent !== null) {
            if(!studentEvent[0]) {
                return 'list-background-events'
            }
        } else {
            return 'list-background-course-empty'
        }
    }

    // console.log(studentEvent)
    return (
        <>
            <div className={blankBg()}>
                {studentEvent 
                ? studentEvent.map(el => <EventList event={el}/>) 
            :''}
            </div>
        </>
    )
}

export default MyEvent