import React from 'react';
import GoogleLogin from 'react-google-login';
import mixpanel from 'mixpanel-browser';
import { Tooltip } from 'antd';

const GoogleSignInButton = ({ signup, googleLogin }) => {
  const gtag_report_conversion = (url) => { var callback = function () { if (typeof (url) != 'undefined') { window.location = url; } }; window.gtag('event', 'conversion', { 'send_to': 'AW-651417204/f_uXCJ3yzoACEPSsz7YC', 'event_callback': callback }); return false; }

  const responseGoogle = (response) => {
    googleLogin(response);
    gtag_report_conversion()
    mixpanel.track('GoogleLogin')
  }
  const responseGoogleFail = (response) => {
    googleLogin(null, true);
  }
  return (
    <GoogleLogin
      clientId={window.$GOOGLE_CLIENT_ID}
      buttonText="Login"
      onSuccess={responseGoogle}
      onFailure={responseGoogleFail}
      cookiePolicy={'single_host_origin'}
      render={renderProps => (
        <Tooltip title={`${signup ? 'Daftar' : 'Masuk'} dengan Google`}>
          <button onClick={renderProps.onClick}>
            <img src="https://img.icons8.com/color/452/google-logo.png" />
          </button>
        </Tooltip>
      )}
    />
  )
}

export default GoogleSignInButton
