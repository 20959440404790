import React, { useState, useRef, useEffect } from 'react';
import { AssignmentIcon } from '../../static';
import Button from '../Button';

const ConnectionProblem = ({closeModal}) => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-around modal-hosted-course">
            <h1 className="assignment__title mb-3">Masalah Koneksi!</h1>
            <h2 className="rating__desc_2 align-self-start mt-3 mb-1">Ada gangguan pada koneksi anda.</h2>
            <h2 className="rating__desc_2 align-self-start mt-3 mb-3">Pastikan anda tersambung pada internet selama kelas. Apabila tidak, progress kelas anda tidak akan tersimpan.</h2>
            <div className='rating__btn align-self-stretch d-flex flex-column' style={{marginBottom: '12px'}}>
                <a className='btn btn-lg btn-pink align-self-stretch' style={{fontSize: '16px'}} onClick={() => window.location.reload()}>
                    OK
                </a>
            </div>
        </div>
        )
}

export default ConnectionProblem

              
// NotificationManager.error('', '', 10000);