import React from 'react';
import { useHistory } from 'react-router-dom';
import 'react-notifications/lib/notifications.css';
import { useDispatch } from 'react-redux';

const NotificationDropdownDiskusi = ({data, actionTaken, date}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const setTabSelected = (submenu) => {
    dispatch({
      type: 'SET_MY_CLASS_SUBMENU',
      payload: submenu,
    })
  }
  return (
    <div>
      <h2>Kamu memiliki kesempatan untuk berdiskusi langsung dengan instruktur.</h2>
      <p>Pilih jadwal di daftar kelas yang telah kamu ambil.</p>
      <div>
        <button className="btn btn-pink" onClick={(e) => {
          e.preventDefault()
          history.push('/student/course')
          setTabSelected('diskusi')
          actionTaken(data.id)
          
        }} >Pilih Jadwal</button>
      </div>
    </div>
  )
}

export default NotificationDropdownDiskusi;
