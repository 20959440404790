import React from 'react';
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert';
import 'react-notifications/lib/notifications.css';
import LoadingSpinner from '../../Layout/LoadingSpinner';

class VoucherLoginRedeemPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      password: '',
    };
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      if (this.props.isOpen) {
        this.props.isOpen = false;
      }
    }
  }

  redeemVoucher = () => {
    this.setState({
      loading: true,
    });
    axios
      .post(
        `${window.$API_URL}api/voucher/verifyloginandredeemvoucher/`,
        {
          password: this.state.password,
          email: this.props.email,
          code: this.props.code,
        },
        {
          headers: { 'Content-Type': 'application/json', 'x-access-token': '' },
        },
      )
      .then((success) => {
        this.setState({
          loading: false,
        });
        if (success.data.success) {
          this.setState({
            voucher_data: success.data.data
          });

          const getAlert = () => (
            <SweetAlert
              success
              showCancel={false}
              confirmBtnText="Ok"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="warning"
              title="Voucher Berhasil Ditebus!"
              onConfirm={(e) => window.location.href = '/'}
            >
              Selamat! Voucher Anda telah berhasil ditebus. Untuk mengakses Pelatihan, Silakan kunjungi Profil Saya.
            </SweetAlert>
          );
          this.setState({
            alert: getAlert(),
          });

        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (!error.response) {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
          return false
      }
        if (error.response.status === 422) {
          let _returnError = [];
          Object.keys(error.response.data.errors).forEach((key) => {
            _returnError.push(error.response.data.errors[key][0]);
          });
          let _showing = true;
          this.setState({ returnError: _returnError, showing: _showing });
          const errorList2 = this.state.returnError.map((char, i) => {
            return (
              <li key={i} style={{ listStyleType: 'none' }}>
                {char}
              </li>
            );
          });
          NotificationManager.error(errorList2, 'Validasi Error!');
        } else if (error.response.status === 404) {
          NotificationManager.error(error.response.data.message, 'Validasi Error!');
        } else if (error.response.status === 401) {
          NotificationManager.error(error.response.data.message, 'Validasi Error!');
        } else if (error.response.status === 400) {
          NotificationManager.error(error.response.data.message, 'Validasi Error!');
        } else {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        }
      });
  }

  render() {
    return (
      <>
        <div
          className={`sign_up_modal modal fade ${this.props.isOpen ? 'show' : ''}`}
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
          style={{ display: `${this.props.isOpen ? 'block' : 'none'}` }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header pointer" onClick={(e) => window.location.href = '/'}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"

                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <ul className="sign_up_tab nav">
                <li className="nav-item active fullWidthCss ">
                  <p className="nav-link pointer active">Redeem Voucher</p>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active">
                  <div className="login_form">
                    <p>Please enter password to redeem voucher.</p>
                    <form action="#">

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          value={this.props.email}
                          readOnly={true}
                        />
                      </div>

                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Masukan Password"
                          value={this.state.password}
                          onChange={(e) => this.setState({ password: e.target.value })}
                        />
                      </div>

                      <div className="form-group">
                        <button
                          type="button"
                          className="btn  btn-thm2"
                          onClick={(e) => this.redeemVoucher(e)}
                        >
                          Redeem
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.alert}
        <NotificationContainer />
        {this.state.loading ? <LoadingSpinner text={this.state.loading_text} /> : ''}
      </>
    );
  }
}

export default VoucherLoginRedeemPopup;
