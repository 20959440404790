import React from 'react';

const RedScreen = () => {
    return (
        <div className="red-screen d-flex">
            <div className="red-screen__bg navbar-desc" >
            </div>
            <div className="red-screen__content red-mob-flex justify-content-around red-bg-mobile" >
                <div className="d-flex flex-column align-items-center red-btm-margin">
                    <img src="/images/landing/red1.png" alt="red-1"/>
                    <p className="red-screen__content-text-big mb-2">20,000</p>
                    <p className="red-screen__content-text-small">Peserta Yang Terdaftar</p>
                </div>
                <div className="d-flex flex-column align-items-center red-btm-margin">
                    <img src="/images/landing/red2.png" alt="red-2" className="mt-2"/>
                    <p className="red-screen__content-text-big mt-auto mb-2">140+</p>
                    <p className="red-screen__content-text-small">Kelas Yang Berkualitas</p>
                </div>
                <div className="d-flex flex-column align-items-center">
                    <img src="/images/landing/red3.png" alt="red-3" className="mt-2"/>
                    <p className="red-screen__content-text-big mt-auto mb-2">50+</p>
                    <p className="red-screen__content-text-small ">Instruktur Yang Handal</p>
                </div>
            </div>
            
        </div>
    )
}

export default RedScreen;




