import React from 'react'

const StarIcon = () => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M15.5753 5.72254L10.6567 4.97121L8.45201 0.275207C8.28734 -0.0754596 7.71201 -0.0754596 7.54734 0.275207L5.34334 4.97121L0.424675 5.72254C0.0206746 5.78454 -0.140659 6.27587 0.142675 6.56587L3.71601 10.2285L2.87134 15.4065C2.80401 15.8179 3.24334 16.1265 3.60667 15.9245L8.00001 13.4965L12.3933 15.9252C12.7533 16.1252 13.1967 15.8225 13.1287 15.4072L12.284 10.2292L15.8573 6.56654C16.1407 6.27587 15.9787 5.78454 15.5753 5.72254Z" fill="#FFC107"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>

const StarFive = () => <div><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /></div>

const columnOne = [
  {
    gender: 'male',
    name: 'Beni Kaku',
    course: 'Desain Grafis untuk Pemula',
    desc: '“Saya adalah seorang arsitek, desain grafis sangat di perlukan terhadap profesi saya dan pengajaran yang di berikan pada kelas ini cukup di mengerti penjelasan yang di berikan juga bermanfaat buat saya dalam mendesain suatu karya hingga lebih kreatif.”'
  },
  {
    gender: 'female',
    name: 'Vit Imenuel',
    course: 'Belajar Penggunaan Microsoft Excel untuk Memaksimalkan Kerja UMKM',
    desc: '“Rumus yang di dapat sangat bermanfaat sehingga dapat membantu saya dalam mengerjakan pekerjaan saya. Terima kasih”'
  }
]

const columnTwo = [
  {
    gender: 'female',
    name: 'Siti Patimah',
    course: 'Bahasa Jepang untuk Pemula ',
    desc: '“Belajar nya menyenangkan dan cara penyampaian nya juga mudah di mengerti jdi untuk yg bener bener baru balajar juga langsung paham, rekomend deh buat yg baru mau belajar.”'
  },
  {
    gender: 'male',
    name: 'Alan Mursid',
    course: 'Digital Marketing: Menggunakan Channel dan Kampanye',
    desc: '“Untuk para pemula dlm mempromosikan produk/ konten , materi ini sangat bermanfaat guna n sebgai awal untuk lebih mengexplore kemampuan dlm advertise.”'
  }
]

const columnThree = [
  {
    gender: 'male',
    name: 'Agus Warso',
    course: 'Belajar Penggunaan Microsoft Excel untuk Memaksimalkan Kerja UMKM',
    desc: '“Penyampaianya lugas & menarik dan mudah di fahami dengan memberikan langsung contoh perhitungannya.”'
  },
  {
    gender: 'male',
    name: 'Iman Sulaiman',
    course: 'Membangun Situs Web Bisnis E-Commerce untuk Pemula ',
    desc: '“Penjelasan sangat detail dan terperinci, jadi paham kegunaan & fitur2 di wix editor, ditambah tutorial berupa video yang menjadikan pelatihan semakin maksimal, terima kasih myedusolveX.”'
  }
]

const PengalamanBelajar = () => {
    
    return (
        <>
          <div className="pengalaman-belajar">
            <div>
              <h1>Pengalaman Belajar di MyEduSolveX</h1>
              <h2>Yuk lihat apa kata mereka setelah belajar di MyEduSolveX</h2>
              
              <div>
                <div>
                  {columnOne.map(el => (
                    <div>
                        <div>
                          <div><img src={`/images/landing/homepage-new/pengalaman/${el.gender}.png`} alt="user"/></div>
                          <h2>{el.name}</h2>
                          <StarFive/>
                        </div>
                      <h2>{el.course}</h2>
                      <p>{el.desc}</p>
                    </div>
                  ))}
                </div>

                <div>
                  {columnTwo.map(el => (
                    <div>
                        <div>
                          <div><img src={`/images/landing/homepage-new/pengalaman/${el.gender}.png`} alt="user"/></div>
                          <h2>{el.name}</h2>
                          <StarFive/>
                        </div>
                      <h2>{el.course}</h2>
                      <p>{el.desc}</p>
                    </div>
                  ))}
                </div>

                <div>
                  {columnThree.map(el => (
                    <div>
                        <div>
                          <div><img src={`/images/landing/homepage-new/pengalaman/${el.gender}.png`} alt="user"/></div>
                          <h2>{el.name}</h2>
                          <StarFive/>
                        </div>
                      <h2>{el.course}</h2>
                      <p>{el.desc}</p>
                    </div>
                  ))}
                </div>

              </div>
            </div> 
          </div>
        </>
    )
}

export default PengalamanBelajar