import React from 'react';
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert';
import 'react-notifications/lib/notifications.css';
import LoadingSpinner from '../../Layout/LoadingSpinner';

class LoginRegisterModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoginActive: 1,
      isRegisterActive: 0,
      // username: '',
      email: '',
      password: '',
      password_confirmation: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      alert: '',
      loginemail: '',
      loginpassword: '',
      loading: 0,
      register_api_url: 'api/register/student/',
      is_interest_instructor: false
    };
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    document.addEventListener("mousedown", this.handleClickOutside, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
    document.removeEventListener("mousedown", this.handleClickOutside, false);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      if (this.props.isOpen) {
        this.dismissModalSignInPopup();
      }
    }
  }

  handleClickOutside = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
    //the click is outside
    if (this.props.isOpen) {
      this.dismissModalSignInPopup();
    }

  }



  dismissModalSignInPopup = () => {
    this.props.toggleSignInPopup();
  };

  loginAsInstrutor = (e) => {
    if (e.target.checked) {
      this.setState({
        is_interest_instructor: true
      })
    } else {
      this.setState({
        is_interest_instructor: false
      })
    }
  }

  loginUser = (e) => {
    if (this.state.loginemail === '' || this.state.loginpassword === '') {
      NotificationManager.error('Silakan masukkan Bidang Email dan Kata Sandi !!', 'Validasi Error!');
    } else {
      this.setState({
        loading: 1,
      });
      axios
        .post(
          `${window.$API_URL}api/auth/login`,
          {
            email: this.state.loginemail,
            password: this.state.loginpassword,
          },
          {
            headers: { 'Content-Type': 'application/json', 'x-access-token': '' },
          },
        )
        .then((success) => {
          this.setState({
            loading: 0,
          });
          if (success.data.success) {
            this.dismissModalSignInPopup();

            if (success.data.data.role_id == 2) {
              localStorage.setItem('access_token', success.data.data.access_token);
              localStorage.setItem('token_type', success.data.data.token_type);
              localStorage.setItem('expires_in', success.data.data.expires_in);
              window.location.href = '/student/profile';
            } else if (success.data.data.role_id == 3) {
              alert('Instructor Login is under Development');
              window.location.href = '/';
            } else {
              localStorage.removeItem('access_token');
              localStorage.removeItem('token_type');
              localStorage.removeItem('expires_in');
              window.location.href = '/';
            }
          }
        })
        .catch((error) => {
          this.setState({
            loading: 0,
          });
          if (!error.response) {
            NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
            return false
          }
          if (error.response.status === 422) {

            this.state.returnError = [];
            Object.keys(error.response.data.errors).forEach((key) => {
              this.state.returnError.push(error.response.data.errors[key][0]);
            });
            this.state.showing = true;
            this.setState({ returnError: this.state.returnError, showing: this.state.showing });
            const errorList2 = this.state.returnError.map((char, i) => {
              return (
                <li key={i} style={{ listStyleType: 'none' }}>
                  {char}
                </li>
              );
            });
            NotificationManager.error(errorList2, 'Validasi Error!');
          }
          if (error.response.status === 401) {
            NotificationManager.error(error.response.data.message, 'Validasi Error!');
          } else {
            NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
          }
        });
    }
  };

  registerStudent = (e) => {
    this.setState({
      loading: 1,
    });
    //  const response = await axios.get(window.$API_URL+'api/fetchusertimezone/',{
    const response = axios
      .post(
        `${window.$API_URL + this.state.register_api_url}`,
        {
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          phone_number: this.state.phone_number,
          username: this.state.email,
          password: this.state.password,
          email: this.state.email,
          password_confirmation: this.state.password_confirmation,
          is_interest_instructor: this.state.is_interest_instructor
        },
        {
          headers: { 'Content-Type': 'application/json', 'x-access-token': '' },
        },
      )
      .then((success) => {
        this.setState({
          loading: 0,
        });
        if (success.data.success) {
          this.dismissModalSignInPopup();
          this.setState({
            isLoginActive: 1,
            isRegisterActive: 0,
          });
          const getAlert = () => (
            <SweetAlert
              success
              showCancel={false}
              confirmBtnText="OK"
              confirmBtnBsStyle="success"
              title="Akun Berhasil Didaftarkan"
              onConfirm={() => this.setState({ alert: '' })}
            >
              Selamat! Akun Anda berhasil didaftarkan. Silakan check E-mail anda untuk membuat Password.
            </SweetAlert>
          );
          this.setState({
            alert: getAlert(),
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: 0,
        });
        if (!error.response) {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
          return false
      }
        if (error.response.status === 422) {
          let _returnError = [];
          Object.keys(error.response.data.errors).forEach((key) => {
            _returnError.push(error.response.data.errors[key][0]);
          });
          let _showing = true;
          this.setState({ returnError: _returnError, showing: _showing });
          const errorList2 = this.state.returnError.map((char, i) => {
            return (
              <li key={i} style={{ listStyleType: 'none' }}>
                {char}
              </li>
            );
          });
          NotificationManager.error(errorList2, 'Validasi Error!');
        } else if (error.response.status === 404) {
          NotificationManager.error(error.response.data.message, 'Validasi Error!');
        } else {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        }
      });
  };

  forgotPasswordPopup = (e) => {
    e.preventDefault();
    this.props.toggleSignInPopup();
    this.props.toggleForgerPasswordOpen();
  }

  render() {
    return (
      <div >
        <div
          className={`sign_up_modal modal fade ${this.props.isOpen ? 'show' : ''}`}
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
          style={{ display: `${this.props.isOpen ? 'block' : 'none'}` }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document" ref={node => this.node = node}>
            <div className="modal-content">
              <div className="modal-header pointer" onClick={this.dismissModalSignInPopup}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"

                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <ul className="sign_up_tab nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    className={`nav-link pointer ${this.state.isLoginActive ? 'active' : ''}`}
                    onClick={(e) => this.setState({ isLoginActive: 1, isRegisterActive: 0 })}
                  >
                    Masuk
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={`nav-link pointer ${this.state.isRegisterActive ? 'active' : ''}`}
                    onClick={(e) => this.setState({ isLoginActive: 0, isRegisterActive: 1 })}
                  >
                    Daftar
                  </a>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div className={`tab-pane fade ${this.state.isLoginActive ? 'show active' : ''}`}>
                  <div className="login_form">
                    <form action="#">
                      <img src="images/images_logo-light-background.png"></img>
                      <div className="heading">
                        <h3 className="text-center">Anda Harus Masuk.</h3>
                        <h4 className="ml-z">Anda Harus Masuk.</h4>
                        <p className="text-center">
                          Belum memiliki akun?
                          <a
                            className="text-thm pointer"
                            onClick={(e) =>
                              this.setState({ isLoginActive: 0, isRegisterActive: 1 })
                            }
                          >
                            Daftar Disini!
                          </a>
                        </p>
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Masukan Email"
                          onChange={(e) => this.setState({ loginemail: e.target.value })}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Masukan Password"
                          onChange={(e) => this.setState({ loginpassword: e.target.value })}
                        />
                      </div>
                      <div className="form-group form-check">
                        <a className="tdu text-thm float-right pointer" onClick={(e) => this.forgotPasswordPopup(e)}>
                          Lupa Password?
                        </a>
                      </div>
                      <button
                        type="button"
                        className="btn btn-log btn-block btn-thm2"
                        onClick={(e) => this.loginUser()}
                      >
                        Masuk
                      </button>

                    </form>
                  </div>
                </div>

                <div
                  className={`tab-pane fade ${this.state.isRegisterActive ? 'show active' : ''}`}
                >
                  <div className="sign_up_form">
                    <div className="heading">
                      <h3 className="text-center">Buat Akun Baru</h3>
                      <p className="text-center">
                        Sudah memiliki akun?
                        <a
                          className="text-thm pointer"
                          onClick={(e) => this.setState({ isLoginActive: 1, isRegisterActive: 0 })}
                        >
                          Masuk
                        </a>
                      </p>
                    </div>
                    <form action="#">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Masukkan Nama Depan Sesuai KTP"
                          onChange={(e) => this.setState({ first_name: e.target.value })}
                        />
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Masukkan Nama Belakang Sesuai KTP"
                          onChange={(e) => this.setState({ last_name: e.target.value })}
                        />
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Masukkan Nomor Ponsel"
                          onChange={(e) => this.setState({ phone_number: e.target.value })}
                        />
                      </div>

                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Masukkan Email"
                          onChange={(e) => this.setState({ email: e.target.value })}
                        />
                      </div>



                      <div className="form-group form-check">
                        <input type="checkbox" className="form-check-input" onClick={(e) => this.loginAsInstrutor(e)} />
                        <label className="form-check-label" htmlFor="exampleCheck2">
                          Ingin menjadi instruktur?
                        </label>
                      </div>
                      <button
                        type="button"
                        onClick={(e) => this.registerStudent()}
                        className="btn btn-log btn-block btn-thm2"
                      >
                        Daftar
                      </button>
                      <hr />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.alert}
        <NotificationContainer />
        {this.state.loading ? <LoadingSpinner /> : ''}
      </div>
    );
  }
}

export default LoginRegisterModal;
