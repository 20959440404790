import React, { useRef, useEffect, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import { Player, BigPlayButton, PosterImage, ControlBar, Shortcut } from 'video-react';
import VideoButton from './VideoButton';
import CourseResourceButton from '../CourseResource/CourseResourceBtn';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { getCourseDetailHostedLoggedIn } from '../../Utils/Utils';
import VideoProgressBar from '../ProgressBar/VideoProgressBar';
import axios from 'axios';
import { trackEventSource } from '../../Utils/EventSourcing';

class CourseVideo extends React.Component {
  _isMounted = false;
  constructor(props, context) {
    super(props, context);
    this.state = {
      ableToForward: true,
      initialForward: false,
    };
    this.newShortcuts = [
      {
        keyCode: 37, // Left arrow
        handle: () => {},
      },
      {
        keyCode: 39, // Right arrow
        handle: () => {},
      },
      {
        keyCode: 73, // I arrow
        handle: () => {},
      },
      {
        keyCode: 74, // J arrow
        handle: () => {},
      },
      {
        keyCode: 188, // > arrow
        handle: () => {},
      },
      {
        keyCode: 190, // < arrow
        handle: () => {},
      },
      {
        keyCode: 35, // End arrow
        handle: () => {},
      },
    ];
    // console.log(this.props.lesson_detail_number);
  }

  componentDidMount() {
    this._isMounted = true;
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    // this.fetchResource(this.props.resource_lecture_id)
  }

  handleStateChange(state) {
    this.setState({
      player: state,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  playerCheck(prevProps, prevState) {
    if (prevState.player) {
      if (!prevState.player.ended) {
        if (this.state.player) {
          return true;
        }
      }
    }
    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.playerCheck(prevProps, prevState)) {
      if (this.state.player.ended & this.state.player.hasStarted) {
        console.log('fetchAgain');
        const fetchAgain = async (_) => {
          this.props.dispatch({
            type: 'ADD_COURSEDETAIL',
            payload: await getCourseDetailHostedLoggedIn(this.props.lesson_detail.id),
          });
        };
        fetchAgain();
      }
    }

    //VARIABLES
    const { player } = this.state;
    const prevPlayer = prevState.player;
    const { lesson_detail, lesson_detail_number, lecture_number, dispatch } = this.props;

    const thisLecture = lesson_detail
      ? lesson_detail.lessons_front[0]
        ? lesson_detail.lessons_front[lesson_detail_number].lectures[lecture_number]
        : {}
      : {};
    const isQnaRequired = lesson_detail ? lesson_detail.is_qna_required : null;

    //SOURCING TRACKER
    if (player && prevPlayer) {
      if (player.hasStarted && !prevPlayer.hasStarted) {
        trackEventSource({
          type: 'lecturestart',
          payload: {
            course_id: lesson_detail.id,
            lectureId: thisLecture.id,
          },
        });
      }
    }

    //HIT API EVERY 30 SEC
    if (player && prevPlayer) {
      if (
        Math.floor(player.currentTime) % 30 == 0 &&
        Math.floor(prevPlayer.currentTime) % 30 != 0 &&
        Math.floor(player.currentTime) != 0
      ) {
        // console.log('hit')
        const access_token = localStorage.getItem('access_token');
        let currentTimeSent = this.state.player.currentTime;
        axios({
          method: 'POST',
          url: `${window.$API_URL}api/lecture/timetrack`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
          },
          data: {
            lecture_id: thisLecture.id,
            second: Math.floor(currentTimeSent),
            course_id: lesson_detail.id,
          },
        })
          .then((response) => {
            let newData = { ...lesson_detail };
            let newDataThisLecture =
              newData.lessons_front[lesson_detail_number].lectures[lecture_number];
            newDataThisLecture.watchseconds.push({
              watch_second: Math.floor(currentTimeSent),
            });

            if (newDataThisLecture.watchtime) {
              newDataThisLecture.watchtime.watchtime += 30;
            } else {
              newDataThisLecture.watchtime = {
                watchtime: 30,
              };
            }

            newDataThisLecture.watchseconds.sort((a, b) => {
              if (a.watch_second < b.watch_second) {
                return -1;
              }
              if (a.watch_second > b.watch_second) {
                return 1;
              }
              return 0;
            });

            dispatch({
              type: 'ADD_COURSEDETAIL',
              payload: newData,
            });

            if (!thisLecture.attended && response.data.data.course_progress) {
              trackEventSource({
                type: 'lecturefinish',
                payload: {
                  course_id: lesson_detail.id,
                  lectureId: thisLecture.id,
                },
              });
              let newData = { ...lesson_detail };
              newData.lessons_front[lesson_detail_number].lectures[lecture_number].attended =
                response.data.data.course_progress;
              dispatch({
                type: 'ADD_COURSEDETAIL',
                payload: newData,
              });
            }

            if (response.data.data.watch_percent > 75 && !response.data.data.course_progress) {
              const is_last_lecture_check = () => {
                if (
                  lesson_detail.lessons_front[lesson_detail_number].lectures[lecture_number + 1]
                ) {
                  return 0;
                } else {
                  if (lesson_detail.lessons_front[lesson_detail_number + 1]) {
                    return 0;
                  } else {
                    return 1;
                  }
                }
              };
              // axios.post(`${window.$API_URL}api/lecture/attend`,
              //   {
              //     course_id: lesson_detail.id,
              //     lecture_id: thisLecture.id,
              //     is_qna_required: isQnaRequired,
              //     is_last_lecture: is_last_lecture_check()
              //   },
              //   {
              //     headers: {
              //       'Content-Type': 'application/json',
              //       Authorization: `Bearer ${access_token}`,
              //   },
              // })
              //   .then(async (_) => {
              // console.log(_)
              // let newData = {...lesson_detail}
              // newData.lessons_front[lesson_detail_number].lectures[lecture_number].attended = _
              // dispatch({
              //   type: 'ADD_COURSEDETAIL',
              //   payload: newData
              // })
              // })
            }
          })
          .catch((error) => {
            if (!error.response) {
              this.props.openConnectionProblem();
              this.player.pause();
            }
          });
      }
    }

    //DISABLE FORWARDING
    if (lesson_detail) {
      if (
        (lesson_detail.is_qna_required && lesson_detail.is_qna_enabled) ||
        (lesson_detail.is_lecturequiz_required && lesson_detail.is_lecturequiz_enabled)
      ) {
        if (this.state.player) {
          if (prevState.player) {
            let timeSkippingTreshold = 0;
            if (thisLecture.mediafile) {
              let duration = thisLecture.mediafile.duration;
              if (duration < 1200) {
                timeSkippingTreshold = 0.5;
              } else if (duration > 1200 && duration < 1800) {
                timeSkippingTreshold = 0.4;
              } else if (duration > 1800 && duration < 2400) {
                timeSkippingTreshold = 0.3;
              } else if (duration > 2400) {
                timeSkippingTreshold = 0.2;
              }
            }

            if (!thisLecture.attended) {
              if (thisLecture.watchtime) {
                if (
                  thisLecture.watchtime.watchtime / thisLecture.mediafile.duration <
                  timeSkippingTreshold
                ) {
                  if (prevState.ableToForward) {
                    this.setState({ ableToForward: false });
                  }
                  if (player.currentTime != 0) {
                    if (player.currentTime - prevPlayer.currentTime > 1) {
                      if (this.state.initialForward) {
                        this.setState({ initialForward: false });
                      } else {
                        this.player.seek(prevPlayer.currentTime);
                      }
                    }
                  }
                } else {
                  if (!prevState.ableToForward) {
                    this.setState({ ableToForward: true });
                  }
                }
              } else {
                if (prevState.ableToForward) {
                  this.setState({ ableToForward: false });
                }
                if (player.currentTime != 0) {
                  if (player.currentTime - prevPlayer.currentTime > 1) {
                    this.player.seek(prevPlayer.currentTime);
                  }
                }
              }
            } else {
              if (!prevState.ableToForward) {
                this.setState({ ableToForward: true });
              }
            }
          }
        }
      }
    }
    if (player && prevPlayer) {
      if (player.hasStarted && !prevPlayer.hasStarted) {
        if (thisLecture.watchtime) {
          if (thisLecture.watchtime.watchtime && thisLecture.mediafile) {
            if (thisLecture.mediafile.duration - thisLecture.watchtime.watchtime > 30) {
              this.player.seek(thisLecture.watchtime.watchtime);
              this.setState({ initialForward: true });
            }
          }
        }
      }
    }

    if (prevProps.resource_lecture_id == null) {
      if (lesson_detail !== null) {
        if (lesson_detail.lessons_front[lesson_detail_number] !== undefined) {
          if (thisLecture) {
            this.props.dispatch({
              type: 'UPDATE_MATERI_OPEN',
              payload: thisLecture.id,
            });
          }
        }
      }
    } else if (
      prevProps.lesson_detail_number !== lesson_detail_number ||
      prevProps.lecture_number !== lecture_number
    ) {
      this.props.dispatch({
        type: 'UPDATE_MATERI_OPEN',
        payload: thisLecture.id,
      });
    }
  }

  render() {
    // console.log(this.props.lesson_detail)

    const { lesson_detail, lesson_detail_number, lecture_number, CourseResource } = this.props;
    const thisLecture =
      lesson_detail !== null
        ? lesson_detail.lessons_front[0]
          ? lesson_detail.lessons_front[lesson_detail_number]?.lectures[lecture_number]
          : null
        : null;
    const mediaProps =
      thisLecture !== null
        ? lesson_detail.lessons_front[0]
          ? lesson_detail.lessons_front[lesson_detail_number]?.lectures[lecture_number]?.mediafile
          : null
        : null;
    const isQnaRequired = lesson_detail ? lesson_detail.is_qna_required : null;
    return (
      <>
        <div className="course-video" onContextMenu={(e) => e.preventDefault()}>
          <Player
            playsInline
            poster="/assets/poster.png"
            src={mediaProps ? mediaProps.url : ''}
            ref={(player) => {
              this.player = player;
            }}
            autoplay
          >
            <BigPlayButton position="center" />
            <PosterImage />
            {isQnaRequired ? <Shortcut shortcuts={this.newShortcuts} /> : <Shortcut />}
          </Player>
        </div>
        {isQnaRequired ? (
          mediaProps ? (
            <VideoProgressBar
              progress={lesson_detail}
              videoDuration={mediaProps.duration}
              handleClick={(time) => this.player.seek(time)}
              ableToForward={this.state.ableToForward}
            />
          ) : (
            ''
          )
        ) : (
          ''
        )}
        {/* <VideoButton /> */}
        {CourseResource ? <CourseResourceButton url={CourseResource} /> : ''}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lesson_detail_number: state.lesson_detail_number,
    lecture_number: state.lecture_number,
    lesson_detail: state.course_detail,
    resource_lecture_id: state.materi_open_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseVideo);
