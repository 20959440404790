import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { API_URL } from '../../constant';
import './reflective_journal.scss';

const ReflectiveJournalScreen = () => {
  const history = useHistory();
  const [journalList, setJournalList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchJournalList = async () => {
      const token = localStorage.getItem('access_token');

      try {
        dispatch({ type: 'TOGGLE_LOADING_TRUE' });

        let res = await axios.get(`${API_URL}/kjflow/journallist`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        res = res?.data?.data;

        // if user havent submitted and not in journal page
        setJournalList(res);
      } catch (error) {
        return error;
      } finally {
        dispatch({ type: 'TOGGLE_LOADING_FALSE' });
      }
    };

    fetchJournalList();
  }, [dispatch]);

  return (
    <>
      <div className="dashboard-container">
        <h1 className="content-user__title mt-4 navbar-desc">Reflective Journal</h1>

        <div style={{ marginTop: '1rem' }}>
          {journalList.length === 0 && <div className="">Belum ada jurnal refleksi</div>}

          {journalList.length > 0 && (
            <ul className="journal_list">
              {journalList.map((el, i) => {
                return (
                  <li className="journal_list_item" key={i}>
                    <img
                      src={el?.quiz?.course?.thumbnail?.url}
                      alt=""
                      style={{ objectFit: 'contain', borderRadius: '8px' }}
                    />
                    <div className="">
                      <div className="">
                        <h3 className="" style={{ fontWeight: '600', fontSize: '18px' }}>
                          {el?.quiz?.course?.name}
                        </h3>
                        <h4 className="" style={{ fontWeight: '400', fontSize: '14px' }}>
                          {el?.quiz?.name}
                        </h4>
                      </div>
                      <button
                        className="btn btn-pink btn-pink-hover"
                        onClick={() => history.push(`/student/reflective-journal/${el?.id}`)}
                      >
                        {el?.student_reflective_journal_id
                          ? 'View Submissions'
                          : 'Start Journal Test'}
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default ReflectiveJournalScreen;
