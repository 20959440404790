import React from 'react';
import { useHistory } from 'react-router-dom'

const Instruktur = () => {
    const history = useHistory()
    return (
        <div className="instruktur-landing d-flex row">
            <div className="instruktur-landing__bg" >
            </div>
            <div className="col-sm-12 col-md-4 instruktur-landing__padding-mobile">
                <img src="/images/landing/instrukturlanding.png" className="instruktur-landing__img " />
            </div>
            <div className="d-flex flex-column justify-content-center col-md-8 col-sm-12 instruktur-landing__padding-mobile">
                <h1 className="instruktur-landing__text-big">Instruktur MyEduSolve X</h1>
                <p className="instruktur-landing__text-small mt-2">Banyak instruktur yang bergabung dengan <b>MyEduSolve</b> dan siap berbagi ilmu.</p>
                <div className="btn btn-pink instruktur-landing__text-btn mt-5 btn-pink-hover"
                    onClick={(e) => {
                        history.push("/instructors")
                    }}>Lihat Daftar Instruktur</div>
            </div>
        </div>
    )
}

export default Instruktur;