import React, { useState } from 'react';
import FooterBottom from './FooterBottom';
import FooterTop from './FooterTop';
import * as Survey from "survey-react";
import "survey-react/survey.css";
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux'

const Footer = ({ nps_eligible_data }) => {
    const [showNPS, setShowNPS] = useState(true)
    // const [showEditProfile, setShowEditProfile] = useState(true)
    const onComplete = (survey, options) => {
    const access_token = localStorage.getItem('access_token')

    axios({
        method: 'POST',
        url: `${window.$API_URL}api/user/nps`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
        },
        data: {
            rating: survey.data.nps_score
        }
    })
    .then(() => {
        NotificationManager.success('Terimakasih atas masukan Anda!', 'Success');
        setTimeout(() => {
            setShowNPS(false)
        }, 1000)
    })
    .catch((error) => {
        if (!error.response) {
            NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
            return false
        }
        if (error.response.status == '422') {
            this.state.returnError = [];
            Object.keys(error.response.data.errors).forEach((key) => {
            this.state.returnError.push(error.response.data.errors[key][0]);
            });
            this.state.showing = true;
            this.setState({ returnError: this.state.returnError, showing: this.state.showing });
            const errorList2 = this.state.returnError.map((char, i) => {
            return (
                <li key={i} style={{ listStyleType: 'none' }}>
                {char}
                </li>
            );
            });
            NotificationManager.error(errorList2, 'Validasi Error!');
        } else if (error.response.status == '400') {
            NotificationManager.error(error.response.data.message, 'Validasi Error!');
        } else {
            NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        }
    })
    
    //console.log("Survey results: " + JSON.stringify(survey.data));
    }
    var defaultThemeColors = Survey
    .StylesManager
    .ThemeColors["default"];
      defaultThemeColors["$main-color"] = "#FF0F51";
      defaultThemeColors["$main-hover-color"] = "#FF0F51";
      defaultThemeColors["$text-color"] = "#4a4a4a";
      defaultThemeColors["$header-color"] = "#FF0F51";
      defaultThemeColors["$inputs-background-color"] = "#FF0F51";

      defaultThemeColors["$header-background-color"] = "#4a4a4a";
      defaultThemeColors["$body-container-background-color"] = "#f8f8f8";

      Survey
      .StylesManager
    .applyTheme();
    var json = {
        "completedHtml": "<h3 >Terima kasih.</h3> <h5> Opini Anda akan membantu kami menjadikan prodik kami menjadi lebih baik!</h5>",
        "completedHtmlOnCondition": [
        {
            "expression": "{nps_score} > 8",
            "html": "<h3>Terima kasih.</h3> <h5> Kami menghargai Anda menyukai produk kami. Ide dan saran Anda akan membantu kami menjadikan produk kami menjadi lebih baik!</h5>"
        }, {
            "expression": "{nps_score} < 7",
            "html": "<h3>Terima kasih.</h3> <h5> Kami menghargai Anda sudah memberikan opini anda kepada kami. Kami melakukan yang terbaik untuk meningkatkan produk dan mencapai harapan Anda.</h5><br/>"
        }
        ],
        "pages": [
        {
            "name": "page1",
            "elements": [
            {
                "type": "rating",
                "name": "nps_score",
                "title": "Pada skala 0 hingga 10, seberapa besar kemungkinan Anda akan merekomendasikan produk kami kepada teman atau kolega?",
                "isRequired": true,
                "rateMin": 0,
                "rateMax": 10,
                "minRateDescription": "Sangat Tidak Mungkin",
                "maxRateDescription": "Sangat Mungkin"
            }
            ]
        }
        ],
        
        "showQuestionNumbers": "off"
    };
    
    var model = new Survey.Model(json);
    
    return (
        <>
        <footer>
            <FooterTop />
            <FooterBottom />
        </footer>
        {
        (nps_eligible_data !== null)
          ?
          (nps_eligible_data.is_eligible && nps_eligible_data.is_enable)
          ?
            <div className="survey-wrapper" style={{display: showNPS ? 'block' : 'none'}}>
              <Survey.Survey model={model} onComplete={onComplete}/>
              <button className="button btn-pink btn" onClick={() => setShowNPS(false)}>Cancel</button>
            </div>
            :
            ''
          : ''
        }
        {/* {
        (nps_eligible_data !== null)
          ?
          (nps_eligible_data.is_phonenumber_prompt)
            ?
            <div className="survey-wrapper" style={{display: (showEditProfile && ((nps_eligible_data.is_eligible && nps_eligible_data.is_enable && !showNPS) || (!nps_eligible_data.is_eligible && !nps_eligible_data.is_enable))) ? 'block' : 'none'}}>
                <div className="lengkapi-profile">
                    <div>
                        <div className="d-flex justify-content-end">
                            <div onClick={() => setShowEditProfile(false)} style={{cursor: 'pointer', padding: '0 8px'}}><CloseModals/></div>
                        </div>
                        <h1>Yuk Lengkapi Profilmu</h1>
                        <p>Tim MyEduSolve hanya akan menghubungimu lewat Whatsapp untuk menyampaikan pesan penting mengenai kelasmu</p>
                        <button className="btn btn-pink" onClick={() =>window.location = "/student/editprofile"}>Lengkapi Profil</button>
                    </div>
                </div>
            </div>
            :
            ''
          : ''
        } */}
        </>
    )
}
const mapStateToProps = state => {
    return {
      nps_eligible_data: state.nps_eligible_data,
    }
}
  
const mapDispatchToProps = (dispatch) => {
    return {
      dispatch,
    };
};
  
export default connect(
    mapStateToProps, mapDispatchToProps
)(Footer);