import axios from 'axios';
import moment from 'moment';
import publicIp from 'public-ip';

export const trackEventSource = (data) => {
  const access_token = localStorage.getItem('access_token');
  axios({
    method: 'POST',
    url: window.$API_URL + 'api/user/storeevents/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    data,
  });
};

export const trackEventSourceNoAuth = (data) => {
  const access_token = localStorage.getItem('access_token');
  axios({
    method: 'POST',
    url: window.$API_URL + 'api/user/storefreeevents/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    data,
  });
};

export const trackUserIp = async (data) => {
  let dateTracker = localStorage.getItem('datetracker');
  let currentDate = moment().format('YYYY-MM-DD');

  if (!dateTracker || dateTracker !== currentDate) {
    axios({
      method: 'POST',
      url: window.$API_URL + 'api/user/storefreeevents/',
      data: {
        type: 'useractive',
        payload: {
          ip: await publicIp.v4(),
          date: currentDate,
        },
      },
    }).then(() => localStorage.setItem('datetracker', currentDate));
  }
};
