import React, { useState, useRef, useEffect } from 'react';
import { AssignmentIcon } from '../../static';
import Button from '../Button';

const RatingWarning = ({closeModal}) => {
    return (
        <div className="d-flex flex-column justify-content-around modal-hosted-course" style={{flex: '1'}}>
            <h1 className="assignment__title mb-3">Beri Rating dan Ulasan Kelas</h1>
            <h2 className="rating__desc_2 align-self-start mt-3 mb-5">Sebelum memberikan rating dan ulasan pada kelas ini, kamu harus menyelesaikan semua materi terlebih dahulu.</h2>
            <div className='rating__btn align-self-stretch d-flex flex-column'>
                <a className='btn btn-lg btn-pink align-self-stretch' style={{fontSize: '16px'}}
                onMouseDown={closeModal}>
                    OK
                </a>
            </div>
        </div>
        )
}

export default RatingWarning

