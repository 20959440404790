import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Instagramdef, Instagram, Facebook, Twitter, Whatsapp } from '../../static';
import SertifikatModal from './SertifikatModal'
import { useSelector } from 'react-redux'
import {
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    TwitterShareButton,
    TwitterIcon,
    LinkedinIcon,
    InstagramShareButton
} from 'react-share';
import { trackEventSource } from '../../Utils/EventSourcing'


const SertifikatList = ({sertifikat}) => {
    const [modalOpen, SetModalOpen] = useState(false)
    const socialLinks = [
        {
            href: '/',
            title: <TwitterShareButton url={sertifikat.certificate_url}><Twitter /></TwitterShareButton>,
            id: '99',
            name: 'twitter'
        },
        {
            href: '/',
            title: <FacebookShareButton url={sertifikat.certificate_url}><Facebook /></FacebookShareButton>,
            id: '98',
            name: 'facebook'
        },
        {
            href: '/',
            title: <LinkedinShareButton url={sertifikat.certificate_url}><LinkedinIcon size={24} round={true} /></LinkedinShareButton>,
            id: '97',
            name: 'linkedin'
        },
        {
            href: '/',
            title: <WhatsappShareButton url={sertifikat.certificate_url}><Whatsapp /></WhatsappShareButton>,
            id: '96',
            name: 'whatsapp'
        },
    ]
    const title = {
        "project": "Sertifikat Kompetensi",
        "course": "Sertifikat Kelulusan",
        "coursenoscore": "Sertifikat Penyelesaian",
    }
    return (
        <>
            <div className="course-card">
                <div className="row">
                    <div className="col-md-3 col-sm-5 col-5 course-card__img__sertifikat" 
                    style={{backgroundImage: `url(${sertifikat.certificate_url})`, cursor: 'pointer'}}
                    onClick={(e) => {
                        SetModalOpen(true)
                    }}
                    >
                    {/* <iframe src="http://www.orimi.com/pdf-test.pdf" style={{width:'718px', height:'700px'}} frameborder="0"></iframe> */}
                    </div>
                    <div className="col-md-8 col-sm-6 col-6 course-card__content">
                        <div className="d-flex" onClick={(e) => {
                            SetModalOpen(true)
                        }}>
                            <p className="course-card__bold-text" style={{margin: '0'}}>{title[sertifikat.certificate_type]}</p>
                        </div>
                        
                        <h2 className="course-card__class-name" onClick={(e) => {
                            SetModalOpen(true)
                        }} style={{cursor: 'pointer'}}>{sertifikat.course.name}</h2>
                        <p className="mt-3 course-card__instructor-name">{`Tanggal terbit: ${moment(sertifikat.date_completed).format('DD MMMM YYYY')}`}</p>
                        <div className="d-flex mt-4">
                            {socialLinks.map(({ href, title, id, name }) => (
                                <a className="mr-3" href={href} key={id} target="_new" onClick={() => {
                                    trackEventSource({
                                        type : "certificateshare",
                                        payload : { 
                                            certificate_id: sertifikat.id, 
                                            platform: name
                                        }
                                    })
                                }}>
                                    {title}
                                </a>
                            ))}
                        </div>
                    </div>
                    
                </div>
            </div>
            <SertifikatModal modalOpen={modalOpen}  closeModal={(e) => SetModalOpen(false)} 
            sertifikat={sertifikat}/>
        </>
    )
}

export default SertifikatList