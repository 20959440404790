import React, { useState } from 'react';

//Components
import VoucherCard from '../VoucherCard';

//Sub-components
import CourseImage from './subcomponent/CourseImage';
import TopData from './subcomponent/TopData';

const External = ({ course }) => {
  return (
    <>
      <div className="course-card">
        <div className="row">
          <div className="col-xl-4 col-md-4 col-sm-3 col-5">
            <CourseImage course={course} />
          </div>
          <div className="col-xl-8 col-lg-8 col-md-7 col-sm-7 col-7 course-card__content">
            <TopData course={course} />
            <div className="navbar-desc">
              <div className="mt-3 d-flex align-items-center mb-3">
                <p style={{ fontWeight: '500', fontSize: '12px' }}>Dilaksanakan Pada Tanggal:</p>
                <p className="ml-2" style={{ fontSize: '12px' }}>
                  Jadwal sesuai yang tertera pada website kelas.
                </p>
                <div
                  className="btn btn-pink btn-pilih-jadwal ml-auto"
                  onClick={(e) => window.open(course.url)}
                >{`Buka Link`}</div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-sm-12 col-lg-12 course-card__content navbar-mob">
            <div className="mt-3 d-flex align-items-center mb-3 ">
              <p className="course-card__bold-text">Dilaksanakan Pada Tanggal:</p>
              <p className="ml-1 course-card__bold-text" style={{ fontWeight: '300' }}>
                Jadwal sesuai yang tertera pada website kelas.
              </p>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-12 d-flex">
              <div
                className="btn btn-pink btn-pilih-jadwal ml-auto mb-4"
                onClick={(e) => window.open(course.url)}
              >{`Buka Link`}</div>
            </div>
            {course.quiz != null ? (
              <div className="course-card__content__review d-flex align-items-center row pt-4">
                <p className="course-card__bold-text col-8" style={{ fontWeight: '300' }}>
                  Apakah Anda sudah menyelesaikan kelas? <b>Silahkan Ikuti Kuis</b>
                </p>
                <div
                  className="btn btn-pink btn-pilih-jadwal ml-auto "
                  onClick={() =>
                    (window.location.href = `/course/login/${course.id}/${course.slug}`)
                  }
                >
                  Ikuti Kuis
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="row navbar-desc">
          <VoucherCard
            code={course.voucher_downstream !== undefined ? course.voucher_downstream.code : ''}
          />
          {course.quiz != null ? (
            <div className="col-md-9 col-sm-12 course-card__content__review d-flex align-items-center">
              <p>
                Apakah Anda sudah menyelesaikan kelas? <b>Silahkan Ikuti Kuis</b>
              </p>
              <div
                className="btn btn-pink btn-pilih-jadwal ml-auto"
                onClick={() => (window.location.href = `/course/login/${course.id}/${course.slug}`)}
              >
                Ikuti Kuis
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default External;
