import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const basicAuthCheck = async () => {
  const access_token = localStorage.getItem('access_token');
  const response = await axios
    .get(window.$API_URL + 'api/auth/me', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((response) => {
      if (response.data.data.role_id === 2) {
        return 1;
      } else {
        return 0;
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        return 0;
      }
    });
  return response;
};

export const basicAuthCheckInstructor = async () => {
  const access_token = localStorage.getItem('access_token');
  const response = await axios
    .get(window.$API_URL + 'api/auth/me', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((response) => {
      if (response.data.data.role_id === 3) {
        return 1;
      } else {
        return 0;
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        return 0;
      }
    });
  return response;
};

export const getCourseDetail = async (course_slug) => {
  const access_token = localStorage.getItem('access_token');
  var response;
  await axios
    .get(`${window.$API_URL}api/course/${course_slug}/detail`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((success) => {
      if (success.data.success) {
        response = success.data.data;
      } else {
        response = null;
      }
    })
    .catch((error) => {
      response = null;
    });
  return response;
};

export const getCourseDetailHostedLoggedIn = async (course_slug) => {
  const access_token = localStorage.getItem('access_token');
  var response;
  await axios
    .get(`${window.$API_URL}api/course/${course_slug}/hosteddetail`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((success) => {
      if (success.data.success) {
        response = success.data.data;
      } else {
        response = null;
      }
    })
    .catch((error) => {
      if (!error.response) {
        NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        return false;
      }
      if (error.response.data.message === 'You are not enrolled in the Course') {
        window.location.href = '/';
      } else {
        response = null;
      }
    });
  return response;
};

export const isLoggedIn = async () => {
  var response;
  const access_token = localStorage.getItem('access_token');
  if (access_token) {
    await axios
      .get(`${window.$API_URL}api/auth/me/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((success) => {
        if (success.data.success) {
          response = {
            isLoggedIn: 1,
            profile_data: success.data.data,
          };
        } else {
          response = {
            isLoggedIn: 0,
            profile_data: null,
          };
        }
      })
      .catch((error) => {
        response = {
          isLoggedIn: 0,
          profile_data: null,
        };
      });
  } else {
    response = {
      isLoggedIn: 0,
      profile_data: null,
    };
  }
  return response;
};

export const appendScript = (scriptToAppend, client_key) => {
  const script = document.createElement('script');
  script.src = scriptToAppend;
  script.async = true;
  script.setAttribute('data-client-key', client_key);
  document.body.appendChild(script);
};

export const removeScript = (scriptToremove) => {
  let allsuspects = document.getElementsByTagName('script');
  for (let i = allsuspects.length; i >= 0; i--) {
    if (
      allsuspects[i] &&
      allsuspects[i].getAttribute('src') !== null &&
      allsuspects[i].getAttribute('src').indexOf(`${scriptToremove}`) !== -1
    ) {
      allsuspects[i].parentNode.removeChild(allsuspects[i]);
    }
  }
};

export const parseTotalTime = (data) => {
  let totalTime = 0;
  data.forEach((el) => {
    el.lectures.forEach((submateri) => {
      if (submateri.mediafile !== null) {
        totalTime += submateri.mediafile.duration;
      }
    });
  });
  if (totalTime > 3600) {
    return moment.utc(totalTime * 1000).format('H [jam] mm [menit]');
  }

  return moment.utc(totalTime * 1000).format('mm [menit]');
};

export const parseTotalTimeEbook = (data) => {
  let totalTime = 0;
  data.forEach((el) => {
    totalTime += el.ebook_pages * 240 || 0;
  });
  if (!totalTime) return 0;
  if (totalTime > 3600) {
    return moment.utc(totalTime * 1000).format('H [jam] m [menit]');
  }

  return moment.utc(totalTime * 1000).format('m [menit]');
};

export const parseTotalTimeMaterialAndEbook = (data, dataEbook) => {
  let totalTime = 0;
  data.forEach((el) => {
    el.lectures.forEach((submateri) => {
      if (submateri.mediafile !== null) {
        totalTime += submateri.mediafile.duration;
      }
    });
  });
  if (dataEbook && dataEbook.length > 0) {
    dataEbook.forEach((el) => {
      totalTime += el.ebook_pages * 240 || 0;
    });
  }
  if (totalTime > 3600) {
    return moment.utc(totalTime * 1000).format('H [jam] mm [menit]');
  }

  return moment.utc(totalTime * 1000).format('mm [menit]');
};

export const fetchMyOrders = () => {
  const access_token = localStorage.getItem('access_token');
  return axios
    .get(`${window.$API_URL}api/user/orders/`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
    })
    .then((success) => {
      if (success.data.success) {
        return success.data;
      }
    })
    .catch((error) => {
      if (!error.response) {
        NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        return false;
      }
      if (error.response.status === 422) {
        let errorTemp = [];
        Object.keys(error.response.data.errors).forEach((key) => {
          errorTemp.push(error.response.data.errors[key][0]);
        });
        const errorList2 = errorTemp.map((char, i) => {
          return (
            <li key={i} style={{ listStyleType: 'none' }}>
              {char}
            </li>
          );
        });
        NotificationManager.error(errorList2, 'Validasi Error!');
      } else if (error.response.status === 401) {
        NotificationManager.error(error.response.data.message, 'Validasi Error!');
      } else {
        NotificationManager.error('Something went wrong', 'Validasi Error!');
      }
      throw { error: 'error' };
    });
};

export const editProfile = (first_name, last_name, phone_number, email) => {
  const access_token = localStorage.getItem('access_token');
  // console.log(first_name, last_name, phone_number)
  return axios
    .post(
      `${window.$API_URL}api/user/studentprofileupdate`,
      {
        first_name,
        last_name,
        phone_number,
        gender: 'm',
        description: 'asd',
        // email
      },
      { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` } },
    )
    .then((success) => {
      return success;
    })
    .catch((error) => {
      if (!error.response) {
        NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        return false;
      }
      if (error.response.status === 422) {
        let errorTemp = [];
        Object.keys(error.response.data.errors).forEach((key) => {
          errorTemp.push(error.response.data.errors[key][0]);
        });
        const errorList2 = errorTemp.map((char, i) => {
          return (
            <li key={i} style={{ listStyleType: 'none' }}>
              {char}
            </li>
          );
        });
        NotificationManager.error(errorList2, 'Validasi Error!');
      } else if (error.response.status === 401) {
        NotificationManager.error(error.response.data.message, 'Validasi Error!');
      } else {
        NotificationManager.error('Something went wrong', 'Validasi Error!');
      }
      throw { error: 'error' };
    });
};

export const fetchMyCourses = () => {
  const access_token = localStorage.getItem('access_token');
  return axios
    .get(`${window.$API_URL}api/user/courses/`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
    })
    .then((success) => {
      if (success.data.success) {
        return success.data;
      }
    })
    .catch((error) => {
      if (!error.response) {
        NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        return false;
      }
      if (error.response.status === 422) {
        let errorTemp = [];
        Object.keys(error.response.data.errors).forEach((key) => {
          errorTemp.push(error.response.data.errors[key][0]);
        });
        const errorList2 = errorTemp.map((char, i) => {
          return (
            <li key={i} style={{ listStyleType: 'none' }}>
              {char}
            </li>
          );
        });
        NotificationManager.error(errorList2, 'Validasi Error!');
      } else if (error.response.status === 401) {
        NotificationManager.error(error.response.data.message, 'Validasi Error!');
      } else {
        NotificationManager.error('Something went wrong', 'Validasi Error!');
      }
      throw { error: 'error' };
    });
};

export const fetchSchedule = (course_id) => {
  const access_token = localStorage.getItem('access_token');
  return axios
    .get(`${window.$API_URL}api/user/${course_id}/schedule/`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
    })
    .then((success) => {
      if (success.data.success) {
        return success.data.data;
      }
    })
    .catch((error) => {
      if (!error.response) {
        NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        return false;
      }
      if (error.response.status === 422) {
        let errorTemp = [];
        Object.keys(error.response.data.errors).forEach((key) => {
          errorTemp.push(error.response.data.errors[key][0]);
        });
        const errorList2 = errorTemp.map((char, i) => {
          return (
            <li key={i} style={{ listStyleType: 'none' }}>
              {char}
            </li>
          );
        });
        NotificationManager.error(errorList2, 'Validasi Error!');
      } else if (error.response.status === 401) {
        NotificationManager.error(error.response.data.message, 'Validasi Error!');
      } else {
        NotificationManager.error('Something went wrong', 'Validasi Error!');
      }
      throw { error: 'error' };
    });
};

export const fetchMyCertificates = () => {
  const access_token = localStorage.getItem('access_token');
  return axios
    .get(`${window.$API_URL}api/user/certificates/`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
    })
    .then((success) => {
      return success.data;
    })
    .catch((error) => {
      if (!error.response) {
        NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        return false;
      }
      if (error.response.status === 422) {
        let errorTemp = [];
        Object.keys(error.response.data.errors).forEach((key) => {
          errorTemp.push(error.response.data.errors[key][0]);
        });
        const errorList2 = errorTemp.map((char, i) => {
          return (
            <li key={i} style={{ listStyleType: 'none' }}>
              {char}
            </li>
          );
        });
        NotificationManager.error(errorList2, 'Validasi Error!');
      } else if (error.response.status === 401) {
        NotificationManager.error(error.response.data.message, 'Validasi Error!');
      } else {
        NotificationManager.error('Something went wrong', 'Validasi Error!');
      }
      throw { error: 'error' };
    });
};

export const fetchMyEvent = () => {
  const access_token = localStorage.getItem('access_token');
  return axios
    .get(`${window.$API_URL}api/user/events`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
    })
    .then((success) => {
      if (success.data.success) {
        return success.data;
      }
    })
    .catch((error) => {
      if (!error.response) {
        NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        return false;
      }
      if (error.response.status === 422) {
        let errorTemp = [];
        Object.keys(error.response.data.errors).forEach((key) => {
          errorTemp.push(error.response.data.errors[key][0]);
        });
        const errorList2 = errorTemp.map((char, i) => {
          return (
            <li key={i} style={{ listStyleType: 'none' }}>
              {char}
            </li>
          );
        });
        NotificationManager.error(errorList2, 'Validasi Error!');
      } else if (error.response.status === 401) {
        NotificationManager.error(error.response.data.message, 'Validasi Error!');
      } else {
        NotificationManager.error('Something went wrong', 'Validasi Error!');
      }
      throw { error: 'error' };
    });
};

export const fetchMyVoucherDownstream = () => {
  const access_token = localStorage.getItem('access_token');
  return axios
    .get(`${window.$API_URL}api/user/downstream-vouchers`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
    })
    .then((success) => {
      if (success.data.success) {
        return success.data.data;
      }
    })
    .catch((error) => {
      // console.log(error.response)
      if (!error.response) {
        NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        return false;
      }
      if (error.response.status === 422) {
        let errorTemp = [];
        Object.keys(error.response.data.errors).forEach((key) => {
          errorTemp.push(error.response.data.errors[key][0]);
        });
        const errorList2 = errorTemp.map((char, i) => {
          return (
            <li key={i} style={{ listStyleType: 'none' }}>
              {char}
            </li>
          );
        });
        NotificationManager.error(errorList2, 'Validasi Error!');
      } else if (error.response.status === 401) {
        NotificationManager.error(error.response.data.message, 'Validasi Error!');
      } else {
        NotificationManager.error('Something went wrong', 'Validasi Error!');
      }
      throw { error: 'error' };
    });
};

export const fetchResource = (id) => {
  const access_token = localStorage.getItem('access_token');
  return axios(`${window.$API_URL}api/courseresource/lecture/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => {
      if (response.data.data[0]) {
        return response.data.data[0].media_url;
      } else {
        return null;
      }
    })
    .catch((error) => {
      NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
    });
};

/*export const isNpsEnabled = async () => {
  const access_token = localStorage.getItem('access_token');
  var response;
  await axios
    .get(`${window.$API_URL}api/user/isnpsenabled/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((success) => {
      response = success;
    })
    .catch((error) => {
      response = null;
    });
  return response;
};*/

export const isNpsEnabled = () => {
  const access_token = localStorage.getItem('access_token');
  return axios
    .get(`${window.$API_URL}api/user/isnpsenabled/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((success) => {
      return success.data.data;
    })
    .catch((error) => {
      return null;
    });
};

export const fetchCertificateData = (id) => {
  const access_token = localStorage.getItem('access_token');
  return axios
    .get(`${window.$API_URL}api/certificate/isverified/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((success) => {
      return success.data.data;
    })
    .catch((error) => {
      return null;
    });
};

export const fetchCertificateByEmail = (email) => {
  const access_token = localStorage.getItem('access_token');

  return axios
    .post(
      `${window.$API_URL}api/certificate/verifybyemail`,
      {
        email,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
      },
    )
    .then((success) => {
      return success.data.data;
    })
    .catch((error) => {
      return null;
    });
  // .finally(() => dispatch({ type: 'TOGGLE_LOADING_FALSE' }))
};

export const fetchEbook = async (id) => {
  let response = null;
  const access_token = localStorage.getItem('access_token');
  await axios
    .get(`${window.$API_URL}api/courseresource/ebook/${id}`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
    })
    .then((success) => {
      response = success.data.data;
    });

  return response;
};

export const fetchEbookCourseDetail = async (id) => {
  let response = null;
  const access_token = localStorage.getItem('access_token');
  await axios
    .get(`${window.$API_URL}api/courseresource/ebookduration/${id}`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
    })
    .then((success) => {
      response = success.data.data;
    });

  return response;
};

export const fetchCourseConsultation = async (id) => {
  let response = null;
  const access_token = localStorage.getItem('access_token');
  await axios
    .get(`${window.$API_URL}api/courseconsultation/${id}`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
    })
    .then((success) => {
      console.log(success);
      response = success.data.data;
    });

  return response;
};
