import React from 'react';
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert';
import 'react-notifications/lib/notifications.css';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import { CloseModals } from '../../static';

class VoucherFinalPurchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      loading: false,
      is_password_required: false,
      password: ''
    };
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    //  document.addEventListener("mousedown", this.handleClickOutside, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
    //  document.removeEventListener("mousedown", this.handleClickOutside, false);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      if (this.props.isOpen) {
        this.dismissModalSignInPopup();
      }
    }
  }


  dismissModalSignInPopup = () => {
    this.props.toggleModalVoucherPage();
  };

  redeemVoucher = () => {
    this.setState({
      loading: 1,
    });
    axios
      .post(
        `${window.$API_URL + 'api/voucher/redeem'}`,
        {
          code: this.props.voucher_code_referral,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          email: this.state.email,
          password: this.state.password
        },
        {
          headers: { 'Content-Type': 'application/json', 'x-access-token': '' },
        },
      )
      .then((success) => {
        this.setState({
          loading: 0,
        });
        if (success.data.success && !success.data.data.is_user_registered) {
          if (success.data.data.is_external) {
            // console.log(success.data.data.external_url)
            const getAlert = () => (
              <SweetAlert
                success
                showCancel={false}
                confirmBtnText="Click Here"
                confirmBtnBsStyle="success"
                title="Voucher Berhasil Ditebus!"
                onConfirm={(e) => window.location.href = success.data.data.external_url}
              >
                Selamat! Voucher Anda telah berhasil ditebus. Untuk mengakses Pelatihan, Silakan kunjungi Situs Mitra Kami.
              </SweetAlert>
            );
            this.setState({
              alert: getAlert(),
            });
          } else {
            const getAlert = () => (
              <SweetAlert
                success
                showCancel={false}
                confirmBtnText="OK"
                confirmBtnBsStyle="success"
                title="Email Verifikasi Terkirim"
                onConfirm={(e) => window.location.href = "/"}
              >
                Kami telah mengirimkan email verifikasi. Silakan memeriksa email Anda untuk memulai pelatihan.
              </SweetAlert>
            );
            this.setState({
              alert: getAlert(),
            });
          }
        } else if (success.data.success && success.data.data.is_user_registered && !success.data.data.is_purchased) {
          this.setState({
            is_password_required: true
          });
          NotificationManager.success("Anda telah terdaftar, silakan masukkan password untuk menebus voucher.", 'Masukkan Password');
        } else if (success.data.success && success.data.data.is_user_registered && success.data.data.is_purchased) {
          localStorage.setItem('access_token', success.data.data.access_token);
          if (success.data.data.is_external) {
            const getAlert = () => (
              <SweetAlert
                success
                showCancel={false}
                confirmBtnText="Click Here"
                confirmBtnBsStyle="success"
                title="Voucher Berhasil Ditebus!"
                onConfirm={(e) => window.location.href = success.data.data.external_url}
              >
                Selamat! Voucher Anda telah berhasil ditebus. Untuk mengakses Pelatihan, Silakan kunjungi Situs Mitra Kami.
              </SweetAlert>
            );
            this.setState({
              alert: getAlert(),
            });
          } else {
            if (success.data.data.course_type == "live") {
              var redirectUrl = '/student/profile';
            } else {
              var redirectUrl = '/student/profile';
            }
            const getAlert = () => (
              <SweetAlert
                success
                showCancel={false}
                confirmBtnText="OK"
                confirmBtnBsStyle="success"
                title="Kode Voucher Ditebus"
                onConfirm={(e) => window.location.href = redirectUrl}
              >
                Selamat Kode Voucher Anda Berhasil Ditebus!
              </SweetAlert>
            );
            this.setState({
              alert: getAlert(),
            });
          }
        }
      })
      .catch((error) => {
        this.setState({
          loading: 0,
        });
        if (!error.response) {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
          return false
      }
        if (error.response.status === 422) {
          let _returnError = [];
          Object.keys(error.response.data.errors).forEach((key) => {
            _returnError.push(error.response.data.errors[key][0]);
          });
          let _showing = true;
          this.setState({ returnError: _returnError, showing: _showing });
          const errorList2 = this.state.returnError.map((char, i) => {
            return (
              <li key={i} style={{ listStyleType: 'none' }}>
                {char}
              </li>
            );
          });
          NotificationManager.error(errorList2, 'Validasi Error!');
        } else if (error.response.status === 404 || error.response.status === 400) {
          NotificationManager.error(error.response.data.message, 'Validasi Error!');
        } else {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        }
      });
  }

  render() {
    return (
      <>
        <div
          className={`sign_up_modal modal fade ${this.props.isOpen ? 'show' : ''}`}
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
          style={{ display: `${this.props.isOpen ? 'block' : 'none'}` }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document" ref={node => this.node = node}>
            <div className="modal-content">
              <div className="pointer d-flex justify-content-end pt-3 pr-3" onClick={this.dismissModalSignInPopup}>
                <CloseModals className="ml-auto" />
              </div>
              <ul className="sign_up_tab nav">
                <li className="nav-item active fullWidthCss ">
                  <h3 className="assignment__title mb-3 mt-4">Masukkan Data</h3>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active">
                  <div className="login_form">
                    <p className="rating__desc_2 mb-4">Masukkan Data Anda Untuk Menebus Voucher!</p>
                    <form action="#">

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Masukkan Kode Voucher"
                          readOnly={true}
                          defaultValue={this.props.voucher_code_referral}
                        />
                      </div>

                      {
                        (!this.state.is_password_required)
                          ?
                          <>
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Masukkan Nama Depan Sesuai KTP"
                                defaultValue={""}
                                onChange={(e) => this.setState({ first_name: e.target.value })}
                              />
                            </div>

                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Masukkan Nama Belakang Sesuai KTP"
                                defaultValue={""}
                                onChange={(e) => this.setState({ last_name: e.target.value })}
                              />
                            </div>
                          </>
                          :
                          ''
                      }
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Masukkan Email Anda"
                          defaultValue={""}
                          onChange={(e) => this.setState({ email: e.target.value })}
                        />
                      </div>

                      {(this.state.is_password_required)
                        ?
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Masukkan Password"
                            defaultValue={""}
                            onChange={(e) => this.setState({ password: e.target.value })}
                          />
                        </div>
                        :
                        ''}


                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-pink w-100"
                          onClick={(e) => this.redeemVoucher(e)}
                        >
                          Konfirmasi
                        </button>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.alert}
        <NotificationContainer />
        {this.state.loading ? <LoadingSpinner /> : ''}
      </>
    );
  }
}

export default VoucherFinalPurchase;
