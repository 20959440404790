import React, { useEffect, useRef } from 'react'
import { CloseModals } from '../../static';
import moment from 'moment'
import { Facebook, Twitter, Whatsapp } from '../../static';
import {
    FacebookShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    LinkedinIcon,
} from 'react-share';
import { trackEventSource } from '../../Utils/EventSourcing'
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal'
var fileDownload = require('js-file-download');

const SertifikatModal = ({closeModal, modalOpen, sertifikat}) => {
    const dispatch = useDispatch();
    const wrapperRef = useRef(null)
    const socialLinks = [
        {
            href: '/',
            title: <TwitterShareButton url={sertifikat.certificate_url}><Twitter /></TwitterShareButton>,
            id: '99',
            name: 'twitter'
        },
        {
            href: '/',
            title: <FacebookShareButton url={sertifikat.certificate_url}><Facebook /></FacebookShareButton>,
            id: '98',
            name: 'facebook'
        },
        {
            href: '/',
            title: <LinkedinShareButton url={sertifikat.certificate_url}><LinkedinIcon size={24} round={true} /></LinkedinShareButton>,
            id: '97',
            name: 'linkedin'
        },
        {
            href: '/',
            title: <WhatsappShareButton url={sertifikat.certificate_url}><Whatsapp /></WhatsappShareButton>,
            id: '96',
            name: 'whatsapp'
        },
    ]

    useEffect(() => {
        const escFunction = (event) => {
          if (event.keyCode === 27) closeModal();
        }
        const handleClickOutside = (event) => {
          if (wrapperRef.current && !wrapperRef.current.contains(event.target)) closeModal();
        }
        document.addEventListener("keydown", escFunction, false);
        document.addEventListener("mousedown", handleClickOutside, false);
        return () => {
          document.removeEventListener("keydown", escFunction, false);
          document.removeEventListener("mousedown", handleClickOutside, false);
        }
    }, [modalOpen])

    return (
        <>
            <Modal
                closeTimeoutMS={100}
                isOpen={modalOpen}
                onRequestClose={closeModal}
                overlayClassName="login-popup-overlay"
                className="login-popup-content modal-size-sertifikat-overlay"
                // contentLabel="Example Modal"
                preventScroll={true}
            >
                <div className="modal-dialog modal-dialog-centered modal-size-sertifikat" role="document" ref={wrapperRef}>
                     <div className="modal-content">
                        <div className="pointer d-flex justify-content-end pt-3 pr-3" onClick={(e) => closeModal() }>
                            <CloseModals className="ml-auto" />
                        </div>
                        <div className="row course-card__padding-sertifikat" >
                            <img className="col-md-7 col-sm-12 course-card__img-modal" src={sertifikat.certificate_url} alt="sertifikat"></img>
                            <div className="col-md-5 col-sm-12 course-card__content d-flex flex-column">
                                <div className="d-flex">
                                    <p className="course-card__bold-text" >Sertifikat</p>
                                </div>
                                <h2 className="course-card__class-name">{sertifikat.course.name}</h2>
                                <p className="mt-3">{`Tanggal terbit: ${moment(sertifikat.date_completed).format('DD MMMM YYYY')}`}</p>
                                <p className="mt-5">Bagikan</p>
                                <div className="d-flex mt-4">
                                    {socialLinks.map(({ href, title, id, name }) => (
                                        <a className="mr-3" href={href} key={id} target="_new" onClick={() => {
                                            trackEventSource({
                                                type : "certificateshare",
                                                payload : { 
                                                    certificate_id: sertifikat.id, 
                                                    platform: name
                                                }
                                            })
                                        }}>
                                            {title}
                                        </a>
                                    ))}
                                </div>
                                <div className="btn btn-pink w-100 mt-4 btn-download-sertifikat"
                                onClick={(e) => {
                                    window.open(sertifikat.certificate_pdf_url, "_blank")
                                }}
                                >
                                    Download Sertifikat
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default SertifikatModal