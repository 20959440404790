import React from 'react';
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert';
import 'react-notifications/lib/notifications.css';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import { CloseModals } from '../../static';
import { connect } from 'react-redux'

class VerifyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email_address: '',
      password: '',
      password_confirmation: ''
    };

  }

  componentDidMount() {
    //document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount() {
    // document.removeEventListener("keydown", this.escFunction, false);
  }



  recoverPassword = () => {
    this.setState({
      loading: true,
    });
    const access_token = localStorage.getItem('access_token');
    axios
      .post(
        `${window.$API_URL}api/password/change/`,
        {
          password: this.state.password,
          password_confirmation: this.state.password_confirmation,
          token: this.props.reset_token

        },
        {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
        },
      )
      .then((success) => {
        if (success.data.success) {
          this.setState({
            loading: false,
          });
          localStorage.setItem('access_token', success.data.data.access_token);
          const getAlert = () => (
            <SweetAlert
              success
              showCancel={false}
              confirmBtnText="Ok"
              confirmBtnBsStyle="success"
              title="Selesai!"
              onConfirm={(e) => {
                const _voucher_code = localStorage.getItem('voucher_code')
                const _voucher_code_register = localStorage.getItem('voucher_register')
                if(_voucher_code && _voucher_code_register){
                  localStorage.removeItem('voucher_register')
                  window.location.href = '/'
                } else {
                  window.location.href = '/'
                }
              }}
            >
              Akun Anda berhasil diaktifkan. Silahkan log in kembali untuk masuk ke akun Anda.
            </SweetAlert>
          );
          this.setState({
            alert: getAlert(),
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (!error.response) {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
          return false
      }
        if (error.response.status === 422) {
          let _returnError = [];
          Object.keys(error.response.data.errors).forEach((key) => {
            _returnError.push(error.response.data.errors[key][0]);
          });
          let _showing = true;
          this.setState({ returnError: _returnError, showing: _showing });
          const errorList2 = this.state.returnError.map((char, i) => {
            return (
              <li key={i} style={{ listStyleType: 'none' }}>
                {char}
              </li>
            );
          });
          NotificationManager.error(errorList2, 'Validasi Error!');
        } else if (error.response.status === 404) {
          NotificationManager.error(error.response.data.message, 'Validasi Error!');
        } else {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        }
      });
  }

  render() {
    return (
      <>
        <div
          className={`sign_up_modal modal fade ${this.props.isOpen ? 'show' : ''}`}
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
          style={{ display: `${this.props.isOpen ? 'block' : 'none'}` }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document" ref={node => this.node = node}>
            <div className="modal-content">
              <div className="pointer d-flex justify-content-end pt-3 pr-3" onClick={(e) => window.location.href = '/'}>
                <CloseModals className="ml-auto" />
              </div>
              <ul className="sign_up_tab nav">
                <li className="nav-item nav-item2-width active ">
                  <h3 className="assignment__title mb-5 mt-4">Buat Kata Sandi</h3>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active">
                  <div className="login_form">
                    <form action="#">
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Kata Sandi"
                          defaultValue={this.state.password}
                          onChange={(e) => this.setState({ password: e.target.value })}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Konfirmasi Kata Sandi"
                          defaultValue={this.state.password_confirmation}
                          onChange={(e) => this.setState({ password_confirmation: e.target.value })}
                        />
                      </div>
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn  btn-pink w-100 mt-3"
                          onClick={(e) => this.recoverPassword(e)}
                        >
                          Submit Kata Sandi
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.alert}
        <NotificationContainer />
        {this.state.loading ? <LoadingSpinner text={this.state.loading_text} /> : ''}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(
  mapDispatchToProps
)(VerifyAccount);