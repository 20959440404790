import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './web/styles/index.scss'
import { Provider } from 'react-redux';
import App from './web/App.js';
import * as serviceWorker from './serviceWorker';
//import { initializeStore } from './initializeStore';
import store from './store/index'
import * as Sentry from "@sentry/react";

window.store = store
/// import index from "./js/index";
//const store = initializeStore();
if (localStorage.getItem('api_url')) {
  window.$API_URL = localStorage.getItem('api_url');
} else {
  window.$API_URL = process.env.REACT_APP_API_ENDPOINT;
}

window.$GOOGLE_CLIENT_ID = '1073628338835-f88h1p72j4un59flcccu3q39jplabfv8.apps.googleusercontent.com'
window.$FACEBOOK_CLIENT_ID = '676315633323343'

//REACT SENTRY
//window.$SENTRY_LARAVEL_DSN = 'https://4e93f0d8bd384a8991d051a5db44cfe9@o464830.ingest.sentry.io/5682370'

//INITIATE SENTRY
//Sentry.init({ dsn: window.$SENTRY_LARAVEL_DSN });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
