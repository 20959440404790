import React, { useRef, useState } from 'react'
import Modal from 'react-modal'
import MediaQuery from 'react-responsive'


const QuestionMark = ({onClick}) => <div onClick={onClick}><svg  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0467 7.49992L9.44668 8.11325C8.96668 8.59325 8.66668 8.99992 8.66668 9.99992H7.33334V9.66659C7.33334 8.92659 7.63334 8.25992 8.11334 7.77992L8.94001 6.93992C9.18668 6.69992 9.33334 6.36659 9.33334 5.99992C9.33334 5.25992 8.73334 4.66659 8.00001 4.66659C7.64639 4.66659 7.30725 4.80706 7.0572 5.05711C6.80715 5.30716 6.66668 5.6463 6.66668 5.99992H5.33334C5.33334 5.29267 5.6143 4.6144 6.11439 4.1143C6.61449 3.6142 7.29277 3.33325 8.00001 3.33325C8.70725 3.33325 9.38553 3.6142 9.88563 4.1143C10.3857 4.6144 10.6667 5.29267 10.6667 5.99992C10.6667 6.58659 10.4267 7.11325 10.0467 7.49992ZM8.66668 12.6666H7.33334V11.3333H8.66668V12.6666ZM8.00001 1.33325C7.12453 1.33325 6.25762 1.50569 5.44879 1.84072C4.63995 2.17575 3.90502 2.66682 3.28596 3.28587C2.03572 4.53612 1.33334 6.23181 1.33334 7.99992C1.33334 9.76803 2.03572 11.4637 3.28596 12.714C3.90502 13.333 4.63995 13.8241 5.44879 14.1591C6.25762 14.4941 7.12453 14.6666 8.00001 14.6666C9.76812 14.6666 11.4638 13.9642 12.7141 12.714C13.9643 11.4637 14.6667 9.76803 14.6667 7.99992C14.6667 4.31325 11.6667 1.33325 8.00001 1.33325Z" fill="#E5002C"/>
</svg></div>

const CloseIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="black"/>
</svg>



const Tentang = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')
  const openModal = (i) => {
    setModalTitle(contents[i].subtitle)
    setModalContent(contents[i].content)
    setModalOpen(true)
  }
  const closeModal = () => setModalOpen(false)
  const wrapperRef = useRef(null)
    
  const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      width: '90vw',
      padding: '8px',
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 1090
    }
  };

  const contents = [
    {
      index: 1,
      title: '28.000+',
      subtitle: 'Peserta Yang Terdaftar',
      content: 'Beragam pengguna dari berbagai usia dan tempat sudah mempercayakan MyEduSolveX sebagai platform belajar mereka',
    },
    {
      index: 2,
      title: '4,86/5,00',
      subtitle: 'Average Class Rating',
      content: 'MyEduSolveX mengedepankan kurikulum pembelajaran yang practical dan job-ready skill untuk penggunanya',
    },
    {
      index: 3,
      title: '88%',
      subtitle: 'Net Promoter Score',
      content: 'Persentase pelajar MyEduSolveX yang merekomendasikan pelatihan dan platform pembelajaran kami',
    }
  ]

  return (
      <>
        <div className="tentang">
          <div>
            <h1>Tentang MyEduSolveX</h1>
            <h2>Get Certified, Be Qualified</h2>

            <div>
              {contents.map((content, i) => (
                <div>
                  <div><img src={`/images/landing/homepage-new/tentang/${content.index}.png`} /></div>
                  <h1>{content.title}</h1>
            
                    <h2>{content.subtitle} <MediaQuery maxDeviceWidth={991}><QuestionMark onClick={() => openModal(i)}/></MediaQuery></h2>
                    <MediaQuery minDeviceWidth={992}><p>{content.content}</p></MediaQuery>
                </div>
              ))}
            </div>
          </div> 
        </div>
        <Modal
          closeTimeoutMS={100}
          isOpen={modalOpen}
          // onRequestClose={closeModal}
          style={customStyles}
          // contentLabel="Example Modal"
        >
          <div className="tentang__modal">
              <div onClick={closeModal}><CloseIcon/></div>
              <h1>{modalTitle}</h1>
              <p>{modalContent}</p>
          </div>
        </Modal>
      </>
  )
}

export default Tentang