import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer';

class Terms extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
            <Header/>
            <div className="terms-privacy-page container-mesx">
                <h1>Terms & Conditions</h1>
                <div>
                    <h1>Introduction</h1>
                    <p>
                        These Website (www.myedusolve.com) Standard Terms and Conditions written on this webpage shall manage participants (“you”, “your”) use of Myedusolve (“us” , “our”, “we”) website, Website Name accessible at myedusolve.com and/or myedusolvex.com.
                    </p>
                    <p>
                        These Terms will be applied fully and affect to your use of this Website. By clicking on the “SIGN UP” option, you agreed to accept all terms and conditions written in here. You must not signing up if you disagree with any of these Website Standard Terms and Conditions.
                    </p>
                    <p>
                        We may change or modify these Terms from time to time. If a modification or change is unacceptable to you, you may terminate this agreement by ceasing to use the website. Please review these Terms to ensure they are acceptable to you. Any use by you of this website constitutes your agreement to be bound by the Terms. And it is Your responsibility to check periodically if there are any amendments made.
                    </p>
                    <p>
                        All the Terms, Conditions, Obligations, Warranties, and Services are contained herein (“Agreement”)
                    </p>
                </div>
                <div>
                    <h1>Privacy</h1>
                    <p>
                        Please review our privacy policy which also regulate your use of our website and to understand our practice. You acknowledge and agree to the use of your data
                    </p>
                </div>
                <div>
                    <h1>User ID and Password</h1>
                    <p>
                        By signing up into our website, you acknowledge and agree that your User ID and Password are for your exclusive use only. Use or sharing of Your Account with another user is not permitted and is cause for immediate blocking of Your access to the Website, the Services and the Content, the Courseware, and termination of this Agreement. You agree that You are fully responsible for maintaining the confidentiality of Your Account and for all activities that occur under it.
                    </p>
                    <p>
                        You also agree to take all reasonable steps to stop such unauthorized use and to cooperate with Us in any investigation of such unauthorized uses. We shall not under any circumstances be held responsible for any claims related to the use or misuse of Your Participant Account due to the activities of any third party outside of our control or due to Your failure to maintain the confidentiality and security of Your Account.
                    </p>
                </div>
                <div>
                    <h1>Content and Courseware</h1>
                    <p>
                        As one of our offered services from our Website, we shall give you permission to access our content, courseware, practice tests, and other documents or data which may be in a form of audio, video, recorded, etc in relation to the specific certification training course you have registered for.
                    </p>
                    <p>
                        We have the right to change, revise or update the Content and Courseware offered to You.
                    </p>
                </div>
                <div>
                    <h1>Usage of the Website and Services</h1>
                    <p>
                        We grant you a personal, restricted, non-transferable license to use the Website, the Services, and the Content and Courseware offered through the Website till the time the completion of the certification training course that You have enrolled for or the termination of this Agreement according to the Terms and Conditions set forth herein, whichever is earlier. The Content and Courseware are provide for your personal use and not commercial advantages.
                    </p>
                    <p>
                        You are strictly prohibited to reproduce, transmit, distribute, sub-license, broadcast, or disseminate works of the Content and Courseware.
                    </p>
                    
                </div>
                <div>
                    <h1>Intellectual Property Rights</h1>
                    <p>
                        You acknowledge and agree that We are the sole and exclusive owner of the Website, the Services and the Content and Courseware and as such are vested with all Intellectual Property Rights and other rights in the Website, the Services, and the Content and Courseware.
                    </p>
                    <p>
                        You acknowledge and agree that this Agreement other than granting You to use the Website, the Services, and the Content and Courseware does not allow You in any manner or form any right, title or interest of a proprietary, in the Website, the Services, and the Content and Courseware.
                    </p>
                    
                </div>
                <div>
                    <h1>Limitation of Liability</h1>
                    <p>
                        The use of the Website, and all the Services we provide are at your own risk. If there is something on your doing that may create an error or interrupting your business is all yours to take and not Our responsibility.
                    </p>
                    <p>
                        You hereby specifically acknowledge that We are not liable for any defamatory, offensive, wrongful, or illegal conduct of third parties, or other users of the Website.
                    </p>
                    <p>
                        You agree that Our liability or the liability of Our affiliates, directors, officers, employees, agents, and licensors, if any, arising out of any kind of legal claim in any way connected with the Services or the Content and Courseware shall not exceed the fee you paid to Us for the particular certification training course.
                    </p>
                    <p>
                        While it is very unlikely to occur, We also are not responsible for any damage to data, software, computer or anything caused by virus that you may experience while visiting or using our website.
                    </p>
                </div>
                <div>
                    <h1>Term and Termination</h1>
                    <p>
                        This Agreement will become effective upon usage of this website.
                    </p>
                    <p>
                        We reserve the right to terminate this Agreement and block Your access to the Content and Courseware with immediate effect by sending a written notice through email to You to this effect, if such termination is made as a result of Your misrepresentation, default, misconduct, or breach of Your obligations as well as violate the provisions related to or under this Agreement .
                    </p>
                    
                </div>
                <div>
                    <h1>Indemnity</h1>
                    <p>
                        You agree to indemnify and hold Us, Our contractors, licensors, directors, officers, employees, and agents, harmless from and against any and all claims, losses, damages, liabilities, and expenses including attorneys' fees, arising out of Your unauthorized use of the Website, the Services, and the Content and Courseware or any violation or breach of this Agreement or any provisions hereof.
                    </p>
                </div>
                <div>
                    <h1>Waiver</h1>
                    <p>
                        Neither failure nor delay on the part of any party to exercise any right, remedy, power, or privilege hereunder shall operate as a waiver thereof, or of the exercise of any other right, remedy, power, or privilege. No term of this Agreement shall be deemed waived, and no breach consented to, unless such waiver or consent shall be in writing and signed by the party claimed to have waived or consented. No waiver of any rights or consent to any breaches shall constitute a waiver of any other rights or consent to any other breach.
                    </p>
                </div>
                <div>
                    <h1>Severability</h1>
                    <p>
                        In the event any provision of this Agreement is held invalid or unenforceable under the applicable laws of Indonesia, the remaining provisions shall continue in full force and effect, and the Agreement shall be deemed to be reformed by replacing such invalidated or unenforceable provision with a valid and enforceable provision that gives effect as closely as possible to the intentions of the parties as expressed by the law
                    </p>
                    <p>
                        If a provision of this Section is found to be invalid, illegal or unenforceable in any respect, the court may modify it to make such provision enforceable or in the alternative remove it without affecting the rest of the Terms. The remaining provisions of the Terms will continue to be valid and enforceable.
                    </p>
                    
                </div>
                <div>
                    <h1>Governing Law and Jurisdiction</h1>
                    <p>
                        All the participants of this website shall be governed by and construed in accordance with the Laws of the Republic of Indonesia and the courts in Indonesia shall have the exclusive jurisdiction over any matter relating to, in connection with, or arising out of, this Agreement.
                    </p>
                </div>
                <div>
                    <h1>Third Party Websites</h1>
                    <p>
                        Any links provided to third party websites, or information supplied about third parties (third party information) on our websites are supplied as a convenience. Linked sites are not under our control and we are not responsible for any third party linked sites, the contents of such sites, any use of the same, or for third party information. The inclusion of a link or third party information does not imply endorsement by Us of the linked site or third party information, any organization or person associated with the linked site or third party information, or any products or services offered or marketed through the linked site or by the third party.
                    </p>
                </div>
                <div>
                    <h1>Payments</h1>
                    <p>
                        You agree to pay the fees for Exam that you purchase, and you authorize us to charge your debit or credit card or process other means of payment for those fees. We work with third party payment processing partners to offer you the most convenient payment methods and to keep your payment information secure. Check out our Privacy Policy for more details.
                    </p>
                    <p>
                        When you make a purchase, you agree not to use an invalid or unauthorized payment method. If your payment method fails and you still get access to the course you are enrolling in, you agree to pay us the corresponding fees within thirty (30) days of notification from us. We reserve the right to disable access to any course for which we have not received adequate payments.
                    </p>
                    
                </div>
                <div>
                    <h1>Entire Agreement</h1>
                    <p>
                        This Agreement, along with the Privacy Policy, Rescheduling Policy, and any additional guidelines, rules, and/or disclaimers posted on the Website constitutes the entire agreement governing Your use of our Website.
                    </p>
                </div>
            </div>
            <Footer />
            </>
        );
    }
}

export default Terms;
