import React, { useEffect } from 'react'
import { CloseModals } from '../../static';
import { CourseLiveIconBlue, CourseHostedIconBlue } from '../../static'
import axios from 'axios';

import { getCourseDetail, appendScript, removeScript, isLoggedIn } from '../../Utils/Utils';

import SchedulePick from '../Schedule/SchedulePick'
import { useDispatch, useSelector } from 'react-redux'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { trackEventSource } from '../../Utils/EventSourcing'


const PilihJadwalModal = ({ closeModal, modalOpen, course }) => {
    const dispatch = useDispatch()
    const scheduleSelectedId = useSelector(state => state.schedule_live_id_selected)

    const enrollSessionFinal = (schedule_id, course_id) => {
        const access_token = localStorage.getItem('access_token');
        dispatch({ type: 'TOGGLE_LOADING_TRUE' })
        let data = {}
        if (course.type == 'live') {
            data = {
                schedule_id: schedule_id,
                course_id: course_id,
            }
        } else if (course.type == 'hosted') {
            if (course.course_qna_register) {
                data = {
                    schedule_id: schedule_id,
                    course_id: course_id,
                    id: course.course_qna_register.id
                }
            } else {
                data = {
                    schedule_id: schedule_id,
                    course_id: course_id,
                }
            }
        }
        axios
            .post(
                course.type == 'live' ? `${window.$API_URL + 'api/user/schedule/enroll'}` :
                    course.course_qna_register ? `${window.$API_URL + 'api/course/updateqnaschedule'}` : `${window.$API_URL + 'api/course/qnabook'}`,
                data
                ,
                {
                    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
                },
            )
            .then((success) => {
                dispatch({ type: 'TOGGLE_LOADING_FALSE' })
                if (success.data.success) {
                    trackEventSource({
                        type : "registerschedule",
                        payload : { 
                            course_id: course_id, 
                            scheduletype: 'Live', 
                            course_type: 'Live'
                        }
                    })
                    closeModal()
                    NotificationManager.success('Pemilihan jadwal berhasil dilakukan!', 'Berhasil!');
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                }
            })
            .catch((error) => {
                dispatch({ type: 'TOGGLE_LOADING_FALSE' })
                if (!error.response) {
                    NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
                    return false
                  }
                if (error.response.status === 422) {
                    let errorTemp = []
                    Object.keys(error.response.data.errors).forEach((key) => {
                        errorTemp.push(error.response.data.errors[key][0]);
                    });
                    const errorList2 = errorTemp.map((char, i) => {
                        return (
                            <li key={i} style={{ listStyleType: 'none' }}>
                                {char}
                            </li>
                        );
                    });
                    NotificationManager.error(errorList2, 'Validasi Error!');
                }
                else if (error.response.status === 401) {
                    NotificationManager.error(error.response.data.message, 'Validasi Error!');
                } else if (error.response.status === 400) {
                    NotificationManager.error(error.response.data.message, 'Validasi Error!');
                } else {
                    NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
                }
            });
    }
    // console.log(course.schedule_list)
    return (
        <>
            <div
                className={`sign_up_modal modal fade show`}
                tabIndex="-1"
                role="dialog"
                aria-hidden="true"
                style={{ display: 'block' }}
            >
                <div className="modal-dialog modal-dialog-centered" style={{ width: '952px', maxWidth: '100vw'}}>
                    <div className="modal-content">
                        <div className="pointer d-flex justify-content-end pt-3 pr-3" onClick={(e) => closeModal() }>
                            <CloseModals className="ml-auto" />
                        </div>
                        <div className="pilih-jadwal-content" >
                            <h1 className="content-user__title">{
                                course.type == 'live' ? 'Pilih Jadwal' : ''
                            }</h1>
                            <div className="d-flex">
                                <img src={course.thumbnail ? course.thumbnail.url : ''} alt="course-img" className="pilih-jadwal-content__img" />
                                <div className="d-flex flex-column ml-4">
                                    <div className="d-flex align-items-center">{course.type === "live" ? <div className="d-flex align-items-center"><CourseLiveIconBlue /> <p className="course-card__type ml-2">Live</p></div> : ""}
                                        {course.type === "hosted" ? <div className="d-flex align-items-center">
                                            <CourseLiveIconBlue /><p className="course-card__type ml-2">Live QnA</p>
                                        </div> : ""}</div>
                                    <h2 className="course-card__class-name mt-2 ml-0" >{course.name}</h2>
                                    <p className="mt-1" >{course.instructor.fullname}</p>
                                    {course.type === "live" ?
                                        <>
                                            <h2 className="course-card__class-name mt-2 mb-0" >Peserta</h2>
                                            {scheduleSelectedId && course.schedule_list ?
                                                course.schedule_list.schedules_custom.map(el => (
                                                    el.id == scheduleSelectedId ? <p className="mt-1" >{`${el.participants_count} dari 60 Peserta`}</p>
                                                        : ''))
                                                : <p className="mt-1" >Pilih Jadwal</p>}
                                        </>
                                        : ''}
                                </div>
                            </div>
                            <SchedulePick courseForPicking={course}/>

                            <div className="d-flex w-100 mt-3 justify-content-end">
                                {course.type === "live" ?
                                <div className={`btn btn-white btn-schedule ${course.type === "live" ? 'mr-3' : ''}`} onClick={(e) => {
                                    closeModal()

                                }}>
                                    Batal
                                </div> : ''}
                                {course.type === "live" ?
                                <div className="btn btn-pink btn-download-sertifikat btn-schedule" style={{ padding: '11px' }}
                                    onClick={(e) => {
                                        //SELECT SCHEDULE FUNCTION
                                        enrollSessionFinal(scheduleSelectedId, course.id)
                                    }}
                                >
                                    Pilih Jadwal
                                </div> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PilihJadwalModal