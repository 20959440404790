import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { liveSessionId } from '../../constant';

const LevelIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.1079 34.4318H35.5057C34.8702 34.4318 34.3551 33.9167 34.3551 33.2813V5.51422C34.3551 4.8788 34.8702 4.36365 35.5057 4.36365H40.1079C40.7434 4.36365 41.2585 4.8788 41.2585 5.51422V33.2813C41.2585 33.9167 40.7434 34.4318 40.1079 34.4318Z"
      fill="#20282E"
    />
    <path
      d="M30.9034 34.4318H26.3011C25.6657 34.4318 25.1506 33.9167 25.1506 33.2813V10.2699C25.1506 9.63447 25.6657 9.11932 26.3011 9.11932H30.9034C31.5388 9.11932 32.054 9.63447 32.054 10.2699V33.2813C32.054 33.9167 31.5388 34.4318 30.9034 34.4318Z"
      fill="#E7AB20"
    />
    <path
      d="M21.6989 34.4318H17.0966C16.4612 34.4318 15.946 33.9167 15.946 33.2813V14.8722C15.946 14.2368 16.4612 13.7216 17.0966 13.7216H21.6989C22.3343 13.7216 22.8495 14.2368 22.8495 14.8722V33.2813C22.8495 33.9167 22.3343 34.4318 21.6989 34.4318Z"
      fill="#20282E"
    />
    <path
      d="M12.4944 34.4318H7.89208C7.25666 34.4318 6.74152 33.9167 6.74152 33.2812V19.4744C6.74152 18.839 7.25666 18.3239 7.89208 18.3239H12.4944C13.1298 18.3239 13.6449 18.839 13.6449 19.4744V33.2812C13.6449 33.9167 13.1298 34.4318 12.4944 34.4318Z"
      fill="#20282E"
    />
    <path
      d="M22.8494 40.1847C22.8494 40.5782 22.8893 40.9632 22.9653 41.3353H5.51422C4.8791 41.3353 4.36365 40.8198 4.36365 40.1847C4.36365 39.5496 4.8791 39.0341 5.51422 39.0341H22.9653C22.8893 39.4061 22.8494 39.7912 22.8494 40.1847Z"
      fill="#20282E"
    />
    <path
      d="M43.6362 40.1847C43.6362 40.8198 43.1208 41.3353 42.4856 41.3353H34.2391C34.3151 40.9632 34.355 40.5782 34.355 40.1847C34.355 39.7912 34.3151 39.4061 34.2391 39.0341H42.4856C43.1208 39.0341 43.6362 39.5496 43.6362 40.1847Z"
      fill="#20282E"
    />
    <path
      d="M28.6023 43.6364C26.699 43.6364 25.1506 42.0879 25.1506 40.1847C25.1506 38.2814 26.699 36.733 28.6023 36.733C30.5055 36.733 32.054 38.2814 32.054 40.1847C32.054 42.0879 30.5055 43.6364 28.6023 43.6364Z"
      fill="#E7AB20"
    />
  </svg>
);

const Info = ({ tabSelected, setTabSelected }) => {
  const course_detail = useSelector((state) => state.course_detail);
  const types = {
    hosted: 'Self-Paced',
    live: 'Live',
    external: 'Eksternal',
  };
  const isDesktop = useMediaQuery({ query: '(min-device-width: 992px)' });

  return !course_detail ? (
    ''
  ) : (
    <section className="info-new">
      <h1>
        <span>{course_detail.name}</span>{' '}
        {isDesktop ? (
          <span>
            {liveSessionId.includes(course_detail?.id) ? 'Live session' : types[course_detail.type]}
          </span>
        ) : (
          ''
        )}
      </h1>
      <h2>
        {course_detail.instructor
          ? [course_detail.instructor, ...course_detail.course_instructors_details].map(
              (el, i) =>
                `${el.fullname}${
                  i ==
                  [course_detail.instructor, ...course_detail.course_instructors_details].length - 1
                    ? ''
                    : '| '
                }`,
            )
          : ''}
      </h2>
      {!isDesktop ? (
        <div className="info-new-detail__level">
          <div>
            <LevelIcon />
            <p>{course_detail.level}</p>
          </div>
          <div>
            <h2>{course_detail.student_registered_count}</h2>
            <p>Peserta</p>
          </div>
          <div>
            <h2>
              {course_detail.average_rating ? (+course_detail.average_rating).toFixed(2) : '-'}
            </h2>
            <p>Rating</p>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="info-new__nav">
        <div
          className={tabSelected == 'Detail' ? 'info-new__nav-selected' : ''}
          onClick={() => setTabSelected('Detail')}
        >
          <p>Detail</p>
        </div>
        <div
          className={tabSelected == 'Fitur' ? 'info-new__nav-selected' : ''}
          onClick={() => setTabSelected('Fitur')}
        >
          <p>Fitur</p>
        </div>
      </div>
    </section>
  );
};

export default Info;
