import React from 'react';
import { Link } from 'react-router-dom';

const FooterTop = () => {
  return (
    <div className="footerTop">
      <div className="footerTop__container container">
        <div className="row">
          <div className="col-12 col-lg footerTop__leftBlock">
            <h3 className="footerTop__title">pt dwi inti putra</h3>
            <div className="row">
              <div className="col-lg-5 col-12 footerTop__head-office">
                <p className="footerTop__text-bold">Head Office</p>
                <p className="footerTop__text-normal">Gedung Kresna</p>
                <p className="footerTop__text-normal">JI. Arjuna Utara No. 28</p>
              </div>
              <div className="col-12 col-lg-auto">
                <p className="footerTop__text-bold">Marketing Office</p>
                <p className="footerTop__text-normal">BLOCK71 Jakarta</p>
                <p className="footerTop__text-normal">Ariobimo Sentral</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg footerTop__rightBlock">
            <div className="row">
              <div className="col-12 col-lg footerTop__myedusolve">
                <h2 className="footerTop__text-title">MyEduSolveX</h2>
                {/* <Link to="/about" className="footerTop__text-link">Tentang Kami</Link> */}
                <Link to="/contactus" className="footerTop__text-link">Kontak Kami</Link>
                <a href="https://bit.ly/collaborate-myedusolvex" className="footerTop__text-link">Jadi Instruktur</a>
                <Link to="/faq" className="footerTop__text-link">FAQ</Link>
                <Link to="/certificateverify/cari-sertifikat" className="footerTop__text-link">Validasi Sertifikat</Link>
              </div>
              <div className="col-12 col-lg">
                <h2 href="/" className="footerTop__text-title">Program</h2>
                <Link to="/courses?page=1" className="footerTop__text-link">Kelas</Link>
                <Link to="/webinar" className="footerTop__text-link">Events</Link>
                <a href="https://myedusolve.com/programs/certification" className="footerTop__text-link" target="_blank">Sertifikasi</a>
                <a href="https://myedusolve.com/programs/ace" className="footerTop__text-link" target='_new'>Ambassador</a>
              </div>
            </div>
          </div>
          <div className="col-12 footerTop__contact">
            <div className="d-flex">
              <p className="footerTop__text-normal">Email:</p>
              <a href="mailto:Hello@myedusolve.com" className="footerTop__link">hello@myedusolve.com</a>
            </div>
            <div className="d-flex">
              <p className="footerTop__text-normal">WhatsApp:</p>
              <a href="tel:+6287857067313" className="footerTop__link">+62 878-5706-7313</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterTop;
