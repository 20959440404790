import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert';
import 'react-notifications/lib/notifications.css';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import { CloseModals } from '../../static';
import { connect, useDispatch } from 'react-redux'

const ForgetPassword = ({ forgot_password_popup_open_flag }) => {
    const wrapperRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const [emailAdress, setEmailAdress] = useState('')
    const [alert, setAlert] = useState('')
    const [returnError, setReturnError] = useState([])
    const [showing, setShowing] = useState(true)
    const dispatch = useDispatch();
    const toggleSignInPopup = () => {
      dispatch({
        type: 'UPDATE_LOGINPOPUP_OPEN',
        payload: 1,
      })
      dispatch({
        type: 'UPDATE_FORGOT_PASS_OPEN',
        payload: 0,
      })
    }
    const toggleForgerPasswordOpen = () => {
      dispatch({
        type: 'UPDATE_FORGOT_PASS_OPEN',
        payload: 0,
      })
    }
    useEffect(() => {
        const dismissModalSignInPopup = () => {
            toggleForgerPasswordOpen();
        }

        const escFunction = (event) => {
          if(forgot_password_popup_open_flag && event.keyCode === 27) {
            dismissModalSignInPopup();
          }
        }
  
        const handleClickOutside = (event) => {
          if (wrapperRef.current && !wrapperRef.current.contains(event.target) && forgot_password_popup_open_flag) {
            dismissModalSignInPopup();
          }
      }
  
        document.addEventListener("keydown", escFunction, false);
        // document.addEventListener("mousedown", handleClickOutside, false);
        return () => {
          document.removeEventListener("keydown", escFunction, false);
          // document.removeEventListener("mousedown", handleClickOutside, false);
        }
    }, [forgot_password_popup_open_flag, wrapperRef])

    const dismissModalSignInPopup = () => {
        toggleForgerPasswordOpen();
    }

    const recoverPassword = () => {
        setLoading(true)
        const access_token = localStorage.getItem('access_token');
        axios
          .post(
            `${window.$API_URL}api/password/reset/`,
            {
              email: emailAdress
            },
            {
              headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
            },
          )
          .then((success) => {
            if (success.data.success) {
              setLoading(false)
              const getAlert = () => (
                <SweetAlert
                  success
                  showCancel={false}
                  confirmBtnText="OK"
                  confirmBtnBsStyle="success"
                  title="Sukses!"
                  onConfirm={() => window.location.href = '/'}
                >
                  Tim kami telah mengirim email untuk proses reset password anda. Jika anda tidak dapat menemukan email dari kami, anda bisa cek di folder spam anda.
                  Jika masih belum ada, anda bisa klik Resend Email.
                </SweetAlert>
              );
              setAlert(getAlert())
            }
          })
          .catch((error) => {
            setLoading(false)
            if (!error.response) {
              NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
              return false
            }
            if (error.response.status == '422') {
              let errorTemp = []
              Object.keys(error.response.data.errors).forEach((key) => {
                errorTemp.push(error.response.data.errors[key][0]);
              });
              setShowing(true);
              setReturnError(errorTemp)
              const errorList2 = returnError.map((char, i) => {
                return (
                  <li key={i} style={{ listStyleType: 'none' }}>
                    {char}
                  </li>
                );
              });
              NotificationManager.error(errorList2, 'Validasi Error!');
            } else if (error.response.status == '404') {
              NotificationManager.error(error.response.data.message, 'Validasi Error!');
            } else {
              NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
            }
          });
      }

    return (
    <>
        <div
        className={`sign_up_modal modal fade ${forgot_password_popup_open_flag ? 'show' : ''}`}
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        style={{ display: `${forgot_password_popup_open_flag ? 'block' : 'none'}` }}
        >
        <div className="modal-dialog modal-dialog-centered modal-size" role="document" ref={wrapperRef}>
            <div className="modal-content">
            <div className="pointer d-flex justify-content-end pt-3 pr-3" onClick={dismissModalSignInPopup}>
                <CloseModals className="ml-auto"/>
            </div>

            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active">
                <div className="login_form">
                    <div className="heading d-flex align-items-center flex-column mb-1 login_form__header">
                        <img src="/images/images_logo-light-background.png" className="logo mb-5"></img>
                        <h3 className="mr-auto sign_up_form__title">Anda Lupa Kata Sandi?</h3>
                        <p>Masukkan email Anda untuk mendapatkan link pemulihan Kata Sandi.</p>
                    </div>
                    <form action="#">
                    <div className="form-group">
                        <h4 className="login-label ml-2">Email</h4>
                        <input
                        type="text"
                        className="form-control sign_up_form__form"
                        placeholder="email@email.com"
                        defaultValue={emailAdress}
                        onChange={(e) => setEmailAdress(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <button
                        type="button"
                        onClick={(e) => recoverPassword(e)}
                        className="btn btn-block btn-thm2 masuk-btn mb-3 btn-pink"
                        >
                        Kirim
                        </button>
                    </div>
                    <hr/>
                    </form>
                    <div className="login_form__bottom-text">
                      <h3>Apakah Anda belum memiliki akun? </h3>
                      <div>
                        <h3 className="mr-1">Silahkan </h3>
                        <a
                          className="text-thm pointer"
                          onClick={(e) => toggleSignInPopup(e)}
                        >
                          <h3 className=" membuat-akun">Membuat Akun</h3>
                        </a>
                      </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        {alert}
        <NotificationContainer />
        {loading ? <LoadingSpinner /> : ''}
    </>
    )
}

const mapStateToProps = state => {
  return { login_popup_open_flag: state.login_popup_open_flag, forgot_password_popup_open_flag: state.forgot_password_popup_open_flag }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};
export default connect(
  mapStateToProps
)(ForgetPassword);
