import React from 'react'

const InstructorBanner = () => {
    
    const onClickBtn = (e) => {
      e.preventDefault()
      window.open("https://bit.ly/collaborate-myedusolvex", "_blank")
    }

    return (
        <>
          <div className="instructor-banner">
            <div>
              <div>
                <h1>Bergabung Menjadi Instruktur</h1>
                <p>Kami selalu terbuka untuk Anda yang ingin</p>
                <p>berkolaborasi bersama demi membangun Indonesia.</p>
                <button onClick={onClickBtn} className="btn">Bergabung Sekarang</button>
              </div>
            </div> 
          </div>
        </>
    )
}

export default InstructorBanner