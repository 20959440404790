import axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import { getCourseDetailHostedLoggedIn } from '../../Utils/Utils';
import ExamAnswers from './ExamAnswers';

import 'react-notifications/lib/notifications.css';
class CourseExam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answerArr: { questions: [] },
      quizCount: 1,
      examQuestion: [],
      examQuestionArray: [],
      loading: false,
    };
  }

  async componentDidMount() {
    await this.props.quiz.questions.map((question) => {
      var temp_question_obj = {
        title: question.question,
        answers: [
          {
            option: 'A',
            id: question.id,
            answer: question.answers[0].id,
            text: question.answers[0].answer,
          },
          {
            option: 'B',
            id: question.id,
            answer: question.answers[1].id,
            text: question.answers[1].answer,
          },
          {
            option: 'C',
            id: question.id,
            answer: question.answers[2].id,
            text: question.answers[2].answer,
          },
          {
            option: 'D',
            id: question.id,
            answer: question.answers[3].id,
            text: question.answers[3].answer,
          },
        ],
      };
      this.state.examQuestion.push(temp_question_obj);
    });
    this.setState({
      examQuestionArray: this.state.examQuestion,
    });
  }

  setAnswer = (option) => {
    const answerArrTemp = this.state.answerArr;
    answerArrTemp.questions[this.state.quizCount - 1] = option;
    this.setState({ answerArr: answerArrTemp });
  };

  finishExam = () => {
    var quiz_length = this.props.quiz.questions.length;
    const isPretest = this.props.isPretest;
    var answer_length = 0;
    this.state.answerArr.questions.map((qt) => {
      answer_length++;
    });
    if (answer_length < quiz_length) {
      this.props.openModal('incomplete_answer');

      this.props.dispatch({
        type: 'FILL_ALL_QUIZ_QUESTION',
        payload: true,
      });
      this.props.dispatch({
        type: 'QUIZ_POPUP_TYPE',
        payload: 'incomplete_answer',
      });
    } else {
      var temp_ans_array = this.state.answerArr;
      temp_ans_array.student_id = this.props.profile_data.id;
      temp_ans_array.quiz_id = this.props.quiz.id;
      this.setState({
        answerArr: temp_ans_array,
      });
      this.setState({
        loading: 1,
      });
      const access_token = localStorage.getItem('access_token');
      axios
        .post(
          `${window.$API_URL}api/user/answer${isPretest ? '/pretest' : ''}`,
          this.state.answerArr,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${access_token}`,
            },
          },
        )
        .then((success) => {
          this.setState({
            loading: 0,
          });
          this.fetchScores(success.data.data.id);
          this.props.dispatch({
            type: 'SET_CERTIFICATE_FAILED',
            payload: success.data,
          });
        })
        .catch((error) => {
          this.setState({
            loading: 0,
          });
        });
    }
  };

  fetchScores = (attempt_id) => {
    var student_id = this.props.profile_data.id;
    var quiz_id = this.props.quiz.id;
    this.setState({
      loading: 1,
    });
    const access_token = localStorage.getItem('access_token');
    axios
      .get(`${window.$API_URL}api/user/${student_id}/${quiz_id}/${attempt_id}/score`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then(async (success) => {
        this.props.dispatch({
          type: 'ADD_COURSEDETAIL',
          payload: await getCourseDetailHostedLoggedIn(this.props.course_detail.id),
        });
        this.props.dispatch({
          type: 'UPDATE_EXAM_OR_MATERI',
          payload: !this.props.isPretest ? 'finish' : 'finish_pretest',
        });
        this.props.dispatch({
          type: 'QUIZ_SCORE_DATA',
          payload: success.data.data,
        });
        this.setState({
          loading: 0,
        });
      })
      .catch((error) => {
        // console.log(error);
        this.setState({
          loading: 0,
        });
      });
  };

  render() {
    if (this.state.examQuestionArray.length === 0) {
      return '';
    }
    return (
      <div>
        <div className="course-exam d-flex flex-column">
          <div className="d-flex ">
            <div className="exam__number d-flex justify-content-center">
              <span>{this.state.quizCount}</span>
            </div>
            <h1 className="exam__title ml-4 d-flex align-items-center">
              {this.state.examQuestionArray[this.state.quizCount - 1].title}
            </h1>
          </div>
          <div className="mt-4">
            <ExamAnswers
              answers={this.state.examQuestionArray[this.state.quizCount - 1].answers}
              answer={
                this.state.answerArr.questions[this.state.quizCount - 1] !== undefined
                  ? this.state.answerArr.questions[this.state.quizCount - 1].answer[0]
                  : 'hi'
              }
              setAnswer={this.setAnswer}
            />
          </div>

          <div className="align-self-end justify-content-end  mt-auto">
            {this.state.quizCount < 2 ? (
              ''
            ) : (
              <a
                className="btn exam__next mr-2"
                onMouseDown={(e) => this.setState({ quizCount: this.state.quizCount - 1 })}
              >
                {'<  Sebelumnya'}
              </a>
            )}
            {this.state.quizCount >= this.state.examQuestionArray.length ? (
              <a className="btn exam__kumpulkan" onMouseDown={(e) => this.finishExam()}>
                {'Kumpulkan'}
              </a>
            ) : (
              <a
                className="btn exam__next"
                onMouseDown={(e) => this.setState({ quizCount: this.state.quizCount + 1 })}
              >
                {'Selanjutnya  >'}
              </a>
            )}
          </div>
        </div>
        {this.state.loading ? <LoadingSpinner text={this.state.loading_text} /> : ''}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return { course_detail: state.course_detail, profile_data: state.profile_data };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseExam);
