import React from 'react'

const KeunggulanList = ({ img, title, desc }) => {
    return (
        <>
            <div className="col-md-3" style={{ padding: '12px' }}>
                <div className="card keunggulan__card pt-2 h-100">
                    <div className="d-flex justify-content-center align-items-center keunggulan__card-img">
                        <img src={img} alt={title}/>
                    </div>
                    <h1 className="keunggulan__card-title">{title}</h1>
                    <h1 className="keunggulan__card-desc">{desc}</h1>
                </div>
            </div>

        </>
    )
}

const Keunggulan = () => {
    const keunggulanArr = [
        {
            img: '/images/landing/keunggulan1.png',
            title: 'Praktis',
            desc: 'Belajar Di Rumah'
        },
        {
            img: '/images/landing/keunggulan2.png',
            title: 'Professional',
            desc: 'Belajar dengan spesialis di bidangnya'
        },
        {
            img: '/images/landing/keunggulan3.png',
            title: 'Sertifikat',
            desc: 'Diakhir Pembelajaran Akan Mendapatkan Sertifikat'
        },
        {
            img: '/images/landing/keunggulan4.png',
            title: 'Efektif',
            desc: 'Praktek Secara Langsung'
        }
    ]
    return (
        <div className="navbar-desc">
            <div className="keunggulan d-flex flex-column align-items-center">
                <h1 className="keunggulan__title-real">Keunggulan MyEduSolveX</h1>
                <div className="row main-row w-100">
                    {keunggulanArr.map(el => <KeunggulanList img={el.img} title={el.title} desc={el.desc} />)}
                </div>
            </div>
        </div>
    )
}

export default Keunggulan