import React from 'react'

function Banner() {
    return (
        <div className="prakerja-banner">
            <div>
                <img src="/images/header-small.png"/>
                <Xicon/>
                <img src="/images/prakerja/prakerjaicon.png"/>
            </div>
        </div>
    )
}

const Arrow = () => <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.47251 6.66656H8.91918L5.66585 9.91989C5.40585 10.1799 5.40585 10.6066 5.66585 10.8666C5.92585 11.1266 6.34585 11.1266 6.60585 10.8666L10.9992 6.47322C11.2592 6.21322 11.2592 5.79322 10.9992 5.53322L6.61251 1.13322C6.48796 1.00839 6.31886 0.938232 6.14251 0.938232C5.96617 0.938232 5.79707 1.00839 5.67251 1.13322C5.41251 1.39322 5.41251 1.81322 5.67251 2.07322L8.91918 5.33322H1.47251C1.10585 5.33322 0.805847 5.63322 0.805847 5.99989C0.805847 6.36656 1.10585 6.66656 1.47251 6.66656Z" fill="white"/>
</svg>

const Xicon = () => <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 1L1 13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1 1L13 13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


export default Banner
