import React from 'react';
import { Link } from 'react-router-dom';
class UpcomingWebinarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webinar_detail: this.props.webinar
    };
  }

  render() {
    return (

      <div className="col-lg-4">
        <Link to={"/webinar/" + this.state.webinar_detail.id}>
          <div className="item">
            <div className="blog_post_home2">
              <div className="bph2_header">
                <img
                  className="img-fluid webinarCustomCSSFix"
                  src={(this.state.webinar_detail.image === null) ? "images/blog/Blog-Images-08.png" : this.state.webinar_detail.image.url}
                  alt={this.state.webinar_detail.title}
                />
                <Link to={"/webinar/" + this.state.webinar_detail.id} className="bph2_date_meta">
                  <span className="year">
                    {this.state.webinar_detail.display_day}
                    <br /> {this.state.webinar_detail.display_month}
                  </span>
                </Link>
              </div>
              <div className="details">
                <div className="post_meta">
                  <ul>
                    <li className="list-inline-item">
                      <Link to={"/webinar/" + this.state.webinar_detail.id}>
                        <i className="flaticon-calendar" /> {this.state.webinar_detail.start_time} {(this.state.webinar_detail.end_time !== null)
                          ?
                          '-' + this.state.webinar_detail.end_time
                          :
                          ''
                        }
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to={"/webinar/" + this.state.webinar_detail.id}>
                        <i className="flaticon-placeholder" />
                        Via Zoom / Hangouts
                    </Link>
                    </li>
                  </ul>
                </div>
                <h4>{this.state.webinar_detail.title}</h4>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export default UpcomingWebinarComponent;
