import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer';
import { connect } from 'react-redux';
import { removeScript } from '../Utils/Utils';
import LandingBanner from '../components/LandingComponent/LandingBanner'
import LoadingSpinner from '../Layout/LoadingSpinner';
import EventDetailComponent from '../components/AllEventList/EventDetail'
import axios from 'axios'
import {  NotificationManager } from 'react-notifications';
import ReactPixel from 'react-facebook-pixel'

class EventDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      slug: this.props.match.params.slug,
    };
  }

  fetchWebinarDetail = async () => {
    this.props.dispatch({ type: 'TOGGLE_LOADING_TRUE' })
    await axios
      .get(`${window.$API_URL}api/event/${this.state.slug}`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((success) => {
        this.props.dispatch({
          type: 'UPDATE_EVENT_SELECTED',
          payload: success.data.data,
        })
        ReactPixel.track('ViewContent', success.data.data)
      }).catch((_) => {
        NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
      }).finally(_ => this.props.dispatch({ type: 'TOGGLE_LOADING_FALSE' }))
  }

  componentDidMount = async () => {
    this.fetchWebinarDetail()
  };

  componentWillUnmount() {
    removeScript('https://app.sandbox.midtrans.com/snap/snap.js');
  }

  render() {
    return (
      <>
        <Header />
        <LandingBanner smallBanner />
        <div className="main-container container d-flex flex-column" style={{ marginBottom: '60px' }}>
          <main>
            <EventDetailComponent />
          </main>
        </div>
        <Footer />
        {this.props.isLoading ? <LoadingSpinner text={"Mohon menunggu.."} /> : ''}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { isLoading: state.isLoading };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventDetail);
