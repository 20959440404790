import React from 'react';


class ImpersonateComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {
    localStorage.setItem('access_token', this.props.match.params.access_token);
    localStorage.setItem('token_type', 'Bearer');
    localStorage.setItem('expires_in', 1790000);
    window.location.href = '/';
  }


  render() {
    return (
      <>
        hii
      </>
    );
  }
}

export default ImpersonateComponent;
