import React, { useState, useRef, useEffect } from 'react';
import { AssignmentIcon } from '../../static';
import Button from '../Button';
import { connect, useDispatch, useStore, useSelector } from 'react-redux';

const ExamAlreadyPassedAlert = ({ closeModal }) => {
    // alert('exam already passed');
    return (
        <div className="d-flex flex-column justify-content-around modal-hosted-course" style={{ flex: '1' }}>
            <h1 className="assignment__title mb-3">Anda sudah lulus dari exam ini.</h1>
            <div className='rating__btn align-self-stretch d-flex flex-column'>
                <a className='btn btn-lg btn-pink align-self-stretch' style={{ fontSize: '16px' }}
                    onMouseDown={closeModal}>
                    OK
                </a>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    };
};

export default connect(mapDispatchToProps)(ExamAlreadyPassedAlert)

