import React from 'react'
import OrderRow from './OrderRow'
import OrderRowMob from './OrderRowMob'
import { useSelector } from 'react-redux'

const MyOrder = () => {
    const orders = useSelector(state => state.userOrders)
    // console.log(orders)
    return (
        <div style={{minHeight: '60vh'}}>  
            <div className="navbar-mob">
                {orders !== null ? 
                    orders.map(el => <OrderRowMob tableContent={el}/>)
                : ''}
            </div>
            <div className="navbar-desc">
                {orders !== null ?
                orders.map(el => <OrderRow tableContent={el}/>) : ''}
            </div>
        </div>
    )
}

export default MyOrder