import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert';
import 'react-notifications/lib/notifications.css';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import { CloseModals } from '../../static';
import LoginModal from './LoginModal'
import RegisterModal from './RegisterModal'
import { connect, useDispatch } from 'react-redux'
import Modal from 'react-modal'

const LogInRegisterModal = ({ isOpen, toggleForgerPasswordOpen, login_popup_open_flag, register_popup_open_flag }) => {
  const [isLoginActive, setIsLoginActive] = useState(1)
  const [isRegisterActive, setIsRegisterActive] = useState(0)
  const [alert, setAlert] = useState('')
  const [loading, setLoading] = useState(0)
  const [isInterestInstructor, setIsInterestInstructor] = useState(false)
  const [returnError, setReturnError] = useState([])
  const [showing, setShowing] = useState(true)
  const wrapperRef = useRef(null)
  const dispatch = useDispatch();
  const toggleSignInPopup = () => {
    dispatch({
      type: 'UPDATE_LOGINPOPUP_OPEN',
      payload: 0,
    })
  }
  useEffect(() => {
    const escFunction = (event) => {
      if (event.keyCode === 27) {
        dismissModalSignInPopup();
      }
    }

    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        dismissModalSignInPopup();
      }
    }

    document.addEventListener("keydown", escFunction, false);
    document.addEventListener("mousedown", handleClickOutside, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
      document.removeEventListener("mousedown", handleClickOutside, false);
    }
  }, [isOpen, wrapperRef])

  const dismissModalSignInPopup = () => {
    toggleSignInPopup();
  };

  const loginAsInstrutor = (e) => {
    if (e.target.checked) {
      setIsInterestInstructor(true)
    } else {
      setIsInterestInstructor(false)

    }
  }


  const forgotPasswordPopup = (e) => {
    e.preventDefault();
    toggleSignInPopup();
    dispatch({
      type: 'UPDATE_FORGOT_PASS_OPEN',
      payload: 1,
    })
  }

  useEffect(() =>  {
    if(register_popup_open_flag) {
      setIsLoginActive(0)
      setIsRegisterActive(1)
    } else {
      setIsLoginActive(1)
      setIsRegisterActive(0)
    }
  }, [register_popup_open_flag])

  return (
    <div>
      <Modal
        closeTimeoutMS={100}
        isOpen={login_popup_open_flag}
        // onRequestClose={closeModal}
        overlayClassName="login-popup-overlay"
        className="login-popup-content"
        // contentLabel="Example Modal"
        preventScroll={true}
      >
        <div className="modal-dialog modal-dialog-centered modal-size" role="document" ref={wrapperRef}>
          <div className="modal-content">

            <div className="pointer d-flex justify-content-end pt-3 pr-3" onClick={() => {
              dismissModalSignInPopup()
              localStorage.removeItem('voucher_code')
              dispatch({type: 'STORE_VOUCHER_CODE', payload: null})
            }}>
              <CloseModals className="ml-auto" />
            </div>

            <div className="tab-content">
              <LoginModal setLoading={setLoading} dismissModalSignInPopup={dismissModalSignInPopup}
                setShowing={setShowing} isLoginActive={isLoginActive} forgotPasswordPopup={forgotPasswordPopup}
                setIsLoginActive={setIsLoginActive} setIsRegisterActive={setIsRegisterActive} />

              <RegisterModal setLoading={setLoading} isRegisterActive={isRegisterActive} isInterestInstructor={isInterestInstructor}
                dismissModalSignInPopup={dismissModalSignInPopup} setAlert={setAlert} loginAsInstrutor={loginAsInstrutor} setShowing={setShowing}
                setIsLoginActive={setIsLoginActive} setIsRegisterActive={setIsRegisterActive}
              />

            </div>
          </div>
        </div>
      </Modal>
      {alert}
      <NotificationContainer />
      {loading ? <LoadingSpinner /> : ''}
    </div>
  )
}

const mapStateToProps = state => {
  return { login_popup_open_flag: state.login_popup_open_flag, forgot_password_popup_open_flag: state.forgot_password_popup_open_flag, register_popup_open_flag: state.register_popup_open_flag }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(LogInRegisterModal);

