import React from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Sticky, StickyContainer } from 'react-sticky';
import LoadingSpinner from '../Layout/LoadingSpinner';
import {
  fetchEbook,
  fetchResource,
  getCourseDetail,
  getCourseDetailHostedLoggedIn,
  isLoggedIn,
  removeScript,
} from '../Utils/Utils';
import CourseExam from '../components/CourseExam';
import CertificatesFreeCourse from '../components/CourseExam/CertificatesFreeCourse';
import ExamFinish from '../components/CourseExam/ExamFinish';
import ExamFinishPerLecture from '../components/CourseExam/ExamFinishPerLecture';
import ExamFinishPretest from '../components/CourseExam/ExamFinishPretest';
import ExamImprovement from '../components/CourseExam/ExamImprovement';
import QuizPerLecture from '../components/CourseExam/QuizPerLecture';
import CourseModal from '../components/CourseModal';
import CourseNav from '../components/CourseNav';
import QnA from '../components/CourseNav/QnA';
import CourseResource from '../components/CourseResource';
import CourseEbook from '../components/CourseResource/CourseEbook';
import CourseVideo from '../components/CourseVideo';
import Footer from '../components/Footer';
import Header from '../components/Header/Header';
import Person from '../components/Person';
import Title from '../components/Title';

import { Button } from 'antd';
import axios from 'axios';
import { DiscussionEmbed } from 'disqus-react';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import HowToDownload from '../components/HowToDownload';
import { API_URL, KPJ_CODE_ADMIN } from '../constant';
import KpjCodeVerification from './KpjCodeVerification';

class CourseDetail extends React.Component {
  constructor(props) {
    const columnsTest = [
      {
        title: 'Materi',
        dataIndex: 'content',
        key: 'time',
      },
      {
        title: '4 Pelajaran',
        dataIndex: 'durationtext',
        key: 'startDate',
        responsive: ['sm'],
      },
    ];
    super(props);
    this.state = {
      isLoggedIn: 0,
      is_menu_opened: false,
      userName: '',
      email: '',
      loading: false,
      profile_data: null,
      slug: this.props.match.params.slug,
      course_detail: null,
      columnDef: columnsTest,
      courseModalOpen: '',
      isModalOpen: false,
      materi_open_id: 1,
      class_resource: null,
      course_ebook: null,
      kpj_code: '',
      loadingVoucherKpj: false,
      loadingTugasMandiri: false,
      loadingUnjukKeterampilan: false,
    };
  }

  componentDidMount = async () => {
    this.props.dispatch({ type: 'TOGGLE_LOADING_TRUE' });
    this.setState({ loading: 1 });

    const user_check_api = await isLoggedIn();
    const course_detail = await getCourseDetailHostedLoggedIn(this.props.match.params.slug);

    const course_ebook = await fetchEbook(this.props.match.params.slug);

    this.setState({
      course_detail: course_detail,
      profile_data: user_check_api.profile_data,
      course_ebook: course_ebook,
      loading: 0,
    });

    if (this.state.profile_data === null) window.location.href = '/';

    if (this.state.course_detail === null) {
      this.setState({
        loading: 1,
      });
      this.setState({
        course_detail: await getCourseDetail(this.props.match.params.slug),
        loading: 0,
      });
      if (this.state.course_detail === null) {
        window.location.href = '/';
      }
    }
    this.props.dispatch({ type: 'TOGGLE_LOADING_FALSE' });

    const dateParse = (date = new Date()) => moment(date).utcOffset(7).format();

    if (this.state.course_detail) {
      if (this.state.course_detail.type === 'live') {
        if (this.state.course_detail.course_schedule_register) {
          if (this.state.course_detail.course_schedule_register.schedule) {
            if (
              dateParse(this.state.course_detail.course_schedule_register.schedule.end_date) >
              dateParse()
            ) {
              window.location.href = '/student/course';
            }
          } else {
            window.location.href = '/student/course';
          }
        } else {
          window.location.href = '/student/course';
        }
      }
    }

    if (this.state.course_detail && this.state.profile_data) {
      if (this.state.course_detail.ratings) {
        this.state.course_detail.is_finalized = 0;
        if (this.state.course_detail.ratings[0]) {
          this.state.course_detail.ratings.forEach((el) => {
            if (el.user_id === this.state.profile_data.id)
              this.state.course_detail.is_finalized = el.is_finalized;
          });
        }
      }
    }

    let isPreTest = false;
    if (this.state.course_detail) {
      if (this.state.course_detail.is_pretest_enabled && this.state.course_detail.pretest) {
        if (course_detail.pretest.is_prettest_given == null) {
          let alreadyStarted = false;
          course_detail.lessons_front.forEach((lesson) => {
            lesson.lectures.forEach((lecture) => {
              if (lecture.attended) {
                alreadyStarted = true;
              }
            });
          });
          if (!alreadyStarted) {
            this.setState({ courseModalOpen: 'pretest_info', isModalOpen: true });
            isPreTest = true;
          }
        }
      }
    }

    this.dispatchToStore();

    if (isPreTest) {
      this.props.dispatch({
        type: 'UPDATE_EXAM_OR_MATERI',
        payload: 'pretest',
      });
    } else {
      this.props.dispatch({
        type: 'UPDATE_MATERI_OPEN',
        payload: 0,
      });
      this.props.dispatch({
        type: 'UPDATE_EXAM_OR_MATERI',
        payload: 'materi',
      });
    }
  };

  componentWillUnmount() {
    removeScript('https://app.midtrans.com/snap/snap.js');
  }

  dispatchToStore = () => {
    this.props.dispatch({
      type: 'ADD_COURSEDETAIL',
      payload: this.state.course_detail,
    });
    this.props.dispatch({
      type: 'SET_COURSE_EBOOK',
      payload: this.state.course_ebook,
    });
    this.props.dispatch({
      type: 'ADD_BANNERDETAIL',
      payload: this.state.course_detail.name,
    });
    this.props.dispatch({
      type: 'ADD_PROFILEDATA',
      payload: this.state.profile_data,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.resource_lecture_id !== this.props.resource_lecture_id) {
      this.setState({
        loading: 1,
      });
      fetchResource(this.props.resource_lecture_id)
        .then((result) => this.setState({ class_resource: result }))
        .finally((_) =>
          this.setState({
            loading: 0,
          }),
        );
    }
  }

  render() {
    const { materiExamOpened } = this.props;

    const { course_detail } = this.state;

    const { lesson_detail_number, lecture_number } = this.props;

    let contentOpened = {};

    const thisLesson =
      course_detail?.lessons_front.length > 0
        ? course_detail?.lessons_front[lesson_detail_number]
        : {};

    const thisLecture =
      thisLesson?.lectures?.length > 0 ? thisLesson?.lectures[lecture_number] : {};

    const formatNextLine = (desc) => {
      let result = desc.split('\n');

      return (
        <div className="">
          {result.map((text, i) => {
            return (
              <p className="" key={i}>
                {text}
              </p>
            );
          })}
        </div>
      );
    };

    const verifyCoupon = async (e, isPostTest = false) => {
      e.preventDefault();
      try {
        this.setState({ loadingVoucherKpj: true });
        const token = localStorage.getItem('access_token');

        const res = await axios.post(
          `${API_URL}/kjflow/verifycoupon`,
          isPostTest
            ? {
                kpj_code: this.state.kpj_code,
                sequence: 999,
                payment_id: course_detail?.payment?.id,
                quiz_id: course_detail?.quiz?.id,
              }
            : {
                kpj_code: this.state.kpj_code,
                sequence: isPostTest ? 999 : lesson_detail_number + 2,
                payment_id: course_detail?.payment?.id,
                lesson_id: thisLesson?.id,
                lecture_id: thisLecture?.id,
              },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        NotificationManager.success('Success to verifiy coupon');

        if (this.state.kpj_code === KPJ_CODE_ADMIN) {
          window.location.reload();
        } else {
          window.open(res?.data?.data?.data?.url);
        }
      } catch (error) {
        NotificationManager.error(error?.response?.data?.message ?? 'Failed to verifiy coupon');
      } finally {
        this.setState({ loadingVoucherKpj: false });
      }
    };

    if (course_detail) {
      contentOpened = {
        certificate_free_course: <CertificatesFreeCourse />,
        exam: (
          <div>
            {course_detail?.kartu_prakerja && !course_detail?.quiz?.is_fr_done && (
              <div>
                <div className="lesson__zoom">
                  <p className="lesson__zoom__name">{course_detail?.quiz?.name ?? '-'}</p>
                </div>
                <KpjCodeVerification
                  handleSubmit={(e) => verifyCoupon(e, true)}
                  loadingVoucherKpj={this.state.loadingVoucherKpj}
                  setVoucherKpj={(e) => this.setState({ kpj_code: e })}
                  voucherKpj={this.state.kpj_code}
                />
              </div>
            )}
            {(course_detail?.quiz?.is_fr_done || !course_detail?.kartu_prakerja) && (
              <CourseExam
                quiz={this.state.course_detail.quiz}
                openModal={(modalStr) =>
                  this.setState({ courseModalOpen: modalStr, isModalOpen: true })
                }
                isPretest={false}
              />
            )}
          </div>
        ),
        pretest: (
          <div className="">
            <CourseExam
              quiz={this.state.course_detail.pretest}
              openModal={(modalStr) =>
                this.setState({ courseModalOpen: modalStr, isModalOpen: true })
              }
              isPretest={true}
            />
          </div>
        ),
        finish: (
          <ExamFinish
            course_detail={this.state.course_detail}
            result={this.props.quiz_score_data}
            certificateFailed={this.props.certificate_failed}
          />
        ),
        finish_pretest: (
          <ExamFinishPretest
            course_detail={this.state.course_detail}
            result={this.props.quiz_score_data}
          />
        ),
        finish_perlecture: <ExamFinishPerLecture />,
        exam_improvement: <ExamImprovement />,
        qna: <QnA course_detail={this.state.course_detail} />,
        download: <CourseResource />,
        quizlecture: (
          <QuizPerLecture
            fillAllAnswerWarning={() =>
              this.setState({ courseModalOpen: 'incomplete_answer', isModalOpen: true })
            }
          />
        ),
        materi: (
          <div>
            {course_detail?.kartu_prakerja && !thisLecture?.face_verified && (
              <div>
                <div className="lesson__zoom">
                  <p className="lesson__zoom__name">{thisLesson?.name ?? '-'}</p>
                </div>
                <KpjCodeVerification
                  handleSubmit={(e) => verifyCoupon(e, false)}
                  loadingVoucherKpj={this.state.loadingVoucherKpj}
                  setVoucherKpj={(e) => this.setState({ kpj_code: e })}
                  voucherKpj={this.state.kpj_code}
                />
              </div>
            )}
            {course_detail?.kartu_prakerja ? (
              <>
                {thisLecture?.face_verified && (
                  <div className="">
                    {thisLesson?.start_date && thisLesson?.end_date ? (
                      <div className="lesson__zoom">
                        <p className="lesson__zoom__name">{thisLesson?.name ?? '-'}</p>
                        <p className="lesson__zoom__description">
                          {formatNextLine(thisLesson?.description) ?? '-'}
                        </p>
                        <div className="">
                          <p className="lesson__zoom__date">
                            Start date:{' '}
                            {moment(thisLesson?.start_date).format('HH:mm, DD MMMM YYYY') ?? '-'}
                          </p>
                          <p className="lesson__zoom__date">
                            End date:{' '}
                            {moment(thisLesson?.end_date).format('HH:mm, DD MMMM YYYY') ?? '-'}
                          </p>
                        </div>
                        <Button
                          className="lesson__zoom__link"
                          onClick={() => window.open(thisLesson?.zoom_link)}
                        >
                          Zoom link
                        </Button>

                        {thisLecture?.is_tugas_mandiri === 1 ||
                        thisLesson?.is_tugas_mandiri === 1 ? (
                          <button
                            className="btn btn-pink"
                            style={{ width: 'fit-content' }}
                            onClick={() => goToTugasMandiriPage()}
                            disabled={this.state.loadingTugasMandiri}
                          >
                            {this.state.loadingTugasMandiri
                              ? 'Loading...'
                              : thisLecture?.tugas_mandiri_submitted
                              ? 'Lihat tugas yang dikumpulkan'
                              : 'Kumpulkan tugas'}
                          </button>
                        ) : (
                          ''
                        )}

                        {thisLecture?.is_unjuk_ketra === 1 || thisLesson?.is_unjuk_ketra === 1 ? (
                          <button
                            className="btn btn-pink"
                            style={{ width: 'fit-content' }}
                            onClick={() => goToUnjukKeterampilanPage()}
                            disabled={this.state.loadingUnjukKeterampilan}
                          >
                            {this.state.loadingUnjukKeterampilan
                              ? 'Loading...'
                              : thisLecture?.uk_test_submitted
                              ? 'Lihat tugas yang dikumpulkan'
                              : 'Kumpulkan tugas'}
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      this.state.course_detail.type === 'hosted' && (
                        <CourseVideo
                          CourseResource={this.state.class_resource}
                          openConnectionProblem={() =>
                            this.setState({
                              courseModalOpen: 'connection_problem',
                              isModalOpen: true,
                            })
                          }
                        />
                      )
                    )}
                  </div>
                )}
              </>
            ) : (
              <CourseVideo
                CourseResource={this.state.class_resource}
                openConnectionProblem={() =>
                  this.setState({ courseModalOpen: 'connection_problem', isModalOpen: true })
                }
              />
            )}
          </div>
        ),
        ebook: <CourseEbook />,
        howToDownload: (
          <div className="container py-10">
            <HowToDownload />
          </div>
        ),
      };
    }

    const goToTugasMandiriPage = async () => {
      const token = localStorage.getItem('access_token');

      const { history } = this.props;
      try {
        this.setState({ loadingTugasMandiri: true });

        let res = await axios.post(
          `${API_URL}/kjflow/starttugasmandiri`,
          {
            lesson_id: thisLesson?.id,
            lecture_id: thisLecture?.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (res.status === 200) {
          history.push(`/student/tugas-mandiri/${res?.data?.data?.id}`);
        }
      } catch (error) {
        NotificationManager.error(error?.response?.data?.message ?? 'Failed to verifiy coupon');
      } finally {
        this.setState({ loadingTugasMandiri: false });
      }
    };

    const goToUnjukKeterampilanPage = async () => {
      const token = localStorage.getItem('access_token');

      const { history } = this.props;
      try {
        this.setState({ loadingUnjukKeterampilan: true });

        let res = await axios.post(
          `${API_URL}/kjflow/startuktest`,
          {
            lesson_id: thisLesson?.id,
            lecture_id: thisLecture?.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (res.status === 200) {
          history.push(`/student/unjuk-keterampilan/${res?.data?.data?.id}`);
        }
      } catch (error) {
        NotificationManager.error(error?.response?.data?.message ?? 'Failed to verifiy coupon');
      } finally {
        this.setState({ loadingUnjukKeterampilan: false });
      }
    };

    return (
      <>
        <div style={{ backgroundColor: 'white' }}>
          <Header />
          <main>
            <div className="main-container-hosted-login container mob-white">
              <div className="row main-row disqus-row">
                <div className="col-12 col-lg-5 col-xl-5" style={{ padding: 0 }}>
                  <MediaQuery minDeviceWidth={991}>
                    <div className="navbar-desc">
                      <Title />
                      <CourseNav
                        openModal={(modalName) =>
                          this.setState({ courseModalOpen: modalName, isModalOpen: true })
                        }
                      />
                    </div>
                  </MediaQuery>

                  {/* for mobile */}
                  <MediaQuery maxDeviceWidth={991}>
                    <div className="navbar-mob">
                      {course_detail && contentOpened[materiExamOpened]}
                      <CourseNav
                        openModal={(modalName) => {
                          this.setState({ courseModalOpen: modalName, isModalOpen: true });
                        }}
                      />
                      {course_detail && <Person instructor={this.state.course_detail.instructor} />}
                    </div>
                  </MediaQuery>
                </div>

                {/* for desktop */}
                <MediaQuery minDeviceWidth={991}>
                  <StickyContainer
                    style={{ flex: '0 0 58.33333%', maxWidth: '58.33333%', paddingLeft: '16px' }}
                  >
                    <Sticky topOffset={300} bottomOffset={100}>
                      {({ style }) => (
                        <div
                          className="navbar-desc"
                          style={{ ...style, top: `${97.33 + style.top}px` }}
                        >
                          {course_detail && contentOpened[materiExamOpened]}
                          {course_detail && (
                            <Person instructor={this.state.course_detail.instructor} />
                          )}
                        </div>
                      )}
                    </Sticky>
                  </StickyContainer>
                </MediaQuery>

                <div className=" col-12">
                  <DiscussionEmbed
                    shortname="myedusolve-x"
                    config={{
                      url: window.location.href,
                      identifier: this.props.match.params.slug,
                      title: this.props.match.params.slug_url,
                    }}
                  />
                  <div style={{ backgroundColor: 'white', height: '160px' }}> </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
          <CourseModal
            modalOpened={this.state.courseModalOpen}
            isModalOpen={this.state.isModalOpen}
            closeModal={() => {
              this.setState({
                isModalOpen: false,
              });
              this.props.dispatch({
                type: 'QUIZ_POPUP_TYPE',
                payload: '',
              });
              this.props.dispatch({
                type: 'FILL_ALL_QUIZ_QUESTION',
                payload: false,
              });
            }}
          />
          {this.props.isLoading ? <LoadingSpinner text={this.state.loading_text} /> : ''}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    course_detail: state.course_detail,
    materi_open_id: state.materi_open_id,
    materiExamOpened: state.materi_exam_opened,
    quiz_score_data: state.quiz_score_data,
    isLoading: state.isLoading,
    lesson_detail_number: state.lesson_detail_number,
    lecture_number: state.lecture_number,
    resource_lecture_id: state.materi_open_id,
    certificate_failed: state.certificate_failed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetail);
