import React, { useState } from 'react'
import { CollapseDown, CollapseUp } from '../../static';
import { Collapse } from 'react-collapse'
import NumberFormat from 'react-number-format';
import moment from 'moment'


const OrderRowMob = ({tableContent}) => {
    const [open, setOpen] = useState(false)

    const name = (!tableContent.course && !tableContent.event) ? '' : tableContent.course_id ? tableContent.course.name : tableContent.event.title
    const price = (!tableContent.course && !tableContent.event) ? '' : tableContent.course_id ? (tableContent.course.discount_price ? tableContent.course.discount_price : tableContent.course.retail_price) : +tableContent.amount
    const hargaKelas = (!tableContent.course && !tableContent.event) ? '' : tableContent.course_id ? tableContent.course.retail_price : +tableContent.amount
    const discountPrice = (!tableContent.course && !tableContent.event) ? '' : tableContent.course_id ? (tableContent.course.discount_price != 0.00 ? tableContent.course.discount_price : 0) : 0
    const totalPrice = (!tableContent.course && !tableContent.event) ? '' : +tableContent.amount

    if(!tableContent.course && !tableContent.event) {
        return ''
    }

    return (
        <div className="navbar-mob">
            <div className={`hosted-table-order ${open ? 'hosted-table-selected-order' : ''}`}
            style={{borderRadius: '0', width: '99vw', marginLeft: '-8px', border: '0'}}>
            <div className="d-flex justify-content-between align-items-center"
                onClick={() => setOpen(!open)}
            >
                <a 
                aria-controls="class-content"
                aria-expanded={open}
                >
                    <h2 className="class-content-materi m-0" style={{fontWeight: '700'}}>{name}</h2>
                </a><div  style={{width: '12px', height: '8px'}}>
                {!open ? <CollapseDown/>: <CollapseUp style={{width: '12px', height: '8px'}}/>}</div> 
            </div>
                <Collapse isOpened={open} >
                    <div className="my-order__mob-content">
                        <div className="row d-flex align-items-center mb-2 mt-3">
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-bold">Nomor Transaksi</p></div>
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-text">{tableContent.id}</p></div>
                        </div>  
                        <div className="row d-flex align-items-center mb-2">
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-bold">Tanggal Pembelian</p></div>
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-text">{`${moment(tableContent.created_at).format('DD MMM YYYY')}`}</p></div>
                        </div>   
                        <div className="row d-flex align-items-center mb-2">
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-bold">Harga Kelas</p></div>
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-text">{'Rp  '}<NumberFormat
                                value={hargaKelas}
                                displayType={'text'}
                                thousandSeparator="."
                                isNumericStrin={true}
                                decimalSeparator={""}
                                thousandsGroupStyle="thousand"
                            /></p></div>
                        </div> 
                        <div className="row d-flex align-items-center mb-2">
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-bold">Diskon (%)</p></div>
                            <div className="col-md-6 col-sm-6 col-6">
                            {discountPrice ? 
                            <p className="my-order__mob-text">
                            <div  className="d-flex mt-2">
                                <p className="ml-auto my-order__mob-text">{'-Rp  '}<NumberFormat
                                    value={discountPrice}
                                    displayType={'text'}
                                    thousandSeparator="."
                                    isNumericStrin={true}
                                    decimalSeparator={""}
                                    thousandsGroupStyle="thousand"
                                /></p>
                            </div></p> : <p className="ml-auto my-order__mob-text">--</p>}
                            </div>
                        </div> 
                        <div  className="d-flex  mb-2">
                            <p className="my-order__mob-bold">Voucher Diskon:</p>
                            {tableContent.discount_voucher_id ? 
                            <>
                            <p className="ml-auto my-order__mob-text">{'-Rp  '}<NumberFormat
                                value={tableContent.discountvoucher.discount_type == 'amount' ? +tableContent.discountvoucher.discount_value : +tableContent.discountvoucher.discount_value/100 * +tableContent.amount_before_discount}
                                displayType={'text'}
                                thousandSeparator="."
                                isNumericStrin={true}
                                decimalSeparator={""}
                                thousandsGroupStyle="thousand"
                            /></p>
                            </> : 
                            <p className="ml-auto">--</p>
                            }
                        </div> 
                        <div className="row d-flex align-items-center mb-2">
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-bold">Metode Pembayaran</p></div>
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-text">{tableContent.method}</p></div>
                        </div> 
                        <div className="row d-flex align-items-center mb-2">
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-bold">Total Pembayaran</p></div>
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-text"><p className="ml-auto my-order__mob-text">{'Rp  '}<NumberFormat
                                value={totalPrice}
                                displayType={'text'}
                                thousandSeparator="."
                                isNumericStrin={true}
                                decimalSeparator={""}
                                thousandsGroupStyle="thousand"
                            /></p></p></div>
                        </div> 
                    </div>
                </Collapse>
                {tableContent.method == 'voucher' || tableContent.status == 'paid' || tableContent.status == 'success' ? 
                <div className="mt-2 mb-2 course-card__selesai d-flex justify-content-center align-items-center ml-4-mobile">
                    Sukses
                </div> :
                <div className="mt-2 mb-2 course-card__selesai d-flex justify-content-center align-items-center ml-4-mobile"
                style={{backgroundColor: 'rgba(255, 15, 81, 0.25)', color: '#FF0F51', borderColor: '#FF0F51'}}>
                    Gagal
                </div>}
            </div>
        </div>
    )
}

export default OrderRowMob