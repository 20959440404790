import React from 'react'
import Header from '../components/Header/Header';
import Footer from '../components/Footer';

function Route404() {
    return (
        <>
            <Header/>
                <main>
                    <div className="main-container container route404" >
                        <h1>404</h1>
                        <p>Halaman tidak tersedia.</p>
                    </div>
                </main>
            <Footer/>
        </>
    )
}

export default Route404
