import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { useDispatch } from 'react-redux'
import { NotificationManager } from 'react-notifications';
import axios from 'axios'

const RasunaSaid = () => {

  return (
    <>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M6.89553 13.3721C10.4147 13.3721 13.2676 10.5192 13.2676 7.00002C13.2676 3.48081 10.4147 0.62793 6.89553 0.62793C3.37632 0.62793 0.523438 3.48081 0.523438 7.00002C0.523438 10.5192 3.37632 13.3721 6.89553 13.3721Z" fill="#FF0F51" />
      </svg>
      <img src="/images/landing/mapphoto2.jpg" alt="myx" style={{ marginLeft: '20px', width: '222px' }} />
      <p style={{
        fontFamily: 'Montserrat', marginLeft: '20px', width: '222px',
        backgroundColor: 'white', padding: '10px'
      }}>MyEduSolve</p>
      <p style={{
        fontFamily: 'Montserrat', marginLeft: '20px', width: '222px',
        backgroundColor: 'white', fontWeight: '700', padding: '10px'
      }}>Marketing Office</p>
      <p style={{
        fontFamily: 'Montserrat', marginLeft: '20px', width: '222px',
        backgroundColor: 'white', padding: '0 10px 10px 10px'
      }}>BLOCK71 Jakarta</p>
      <p style={{
        fontFamily: 'Montserrat', marginLeft: '20px', width: '222px',
        backgroundColor: 'white', padding: '0 10px 20px 10px'
      }}>Ariobimo Sentral</p>

    </>
  )
}

const ArjunaUtara = () => {

  return (
    <>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M6.89553 13.3721C10.4147 13.3721 13.2676 10.5192 13.2676 7.00002C13.2676 3.48081 10.4147 0.62793 6.89553 0.62793C3.37632 0.62793 0.523438 3.48081 0.523438 7.00002C0.523438 10.5192 3.37632 13.3721 6.89553 13.3721Z" fill="#FF0F51" />
      </svg>
      <img src="/images/landing/mapphoto.png" alt="myx" style={{ marginLeft: '20px' }} />
      <p style={{
        fontFamily: 'Montserrat', marginLeft: '20px', width: '222px',
        backgroundColor: 'white', padding: '10px'
      }}>MyEduSolve</p>
      <p style={{
        fontFamily: 'Montserrat', marginLeft: '20px', width: '222px',
        backgroundColor: 'white', fontWeight: '700', padding: '10px'
      }}>Head Office</p>
      <p style={{
        fontFamily: 'Montserrat', marginLeft: '20px', width: '222px',
        backgroundColor: 'white', padding: '0 10px 10px 10px'
      }}>Gedung Kresna</p>
      <p style={{
        fontFamily: 'Montserrat', marginLeft: '20px', width: '222px',
        backgroundColor: 'white', padding: '0 10px 20px 10px'
      }}>Jl. Arjuna Utara No. 28</p>

    </>
  )
}

const KontakPage = () => {
  const [nama, setNama] = useState(null)
  const [email, setEmail] = useState(null)
  const [subyek, setSubyek] = useState(null)
  const [pesan, setPesan] = useState(null)
  const dispatch = useDispatch()

  const contactUs = (e) => {
    e.preventDefault()
    dispatch({ type: 'TOGGLE_LOADING_TRUE' })
    axios.post(`${window.$API_URL}api/contact/`,
      {
        name: nama,
        email,
        subject: subyek,
        message: pesan,
        ipaddress: '3423324324',
      },
      {
        headers: { 'Content-Type': 'application/json', 'x-access-token': '' },
      }).then((success) => {
        if (success.data.success) {
          NotificationManager.success('Pesan anda berhasil kami terima', 'Berhasil!')
        }
      }).catch((error) => {
        if (!error.response) {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
          return false
        }
        if (error.response.status === 422) {
          let errorTemp = []
          Object.keys(error.response.data.errors).forEach((key) => {
            errorTemp.push(error.response.data.errors[key][0]);
          });
          // setState({ returnError: state.returnError, showing: state.showing });
          const errorList2 = errorTemp.map((char, i) => {
            return (
              <li key={i} style={{ listStyleType: 'none' }}>
                {char}
              </li>
            );
          });
          NotificationManager.error(errorList2, 'Validasi Error!');
        }
        else if (error.response.status === 401) {
          NotificationManager.error(error.response.data.message, 'Validasi Error!');
        } else {
          NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
        }
      })
      .finally(_ => dispatch({ type: 'TOGGLE_LOADING_FALSE' }))
  }

  return (
    <div className="kontak-page">
      <div className="row main-row">
        <div className="col-md-12 col-lg-4 col-sm-12">
          <div className="card kontak-page__card">
            <svg width="36" height="50" viewBox="0 0 36 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 23.75C16.3424 23.75 14.7527 23.0915 13.5806 21.9194C12.4085 20.7473 11.75 19.1576 11.75 17.5C11.75 15.8424 12.4085 14.2527 13.5806 13.0806C14.7527 11.9085 16.3424 11.25 18 11.25C19.6576 11.25 21.2473 11.9085 22.4194 13.0806C23.5915 14.2527 24.25 15.8424 24.25 17.5C24.25 18.3208 24.0883 19.1335 23.7743 19.8918C23.4602 20.6501 22.9998 21.3391 22.4194 21.9194C21.8391 22.4998 21.1501 22.9602 20.3918 23.2743C19.6335 23.5883 18.8208 23.75 18 23.75ZM18 0C13.3587 0 8.90752 1.84374 5.62563 5.12563C2.34374 8.40752 0.5 12.8587 0.5 17.5C0.5 30.625 18 50 18 50C18 50 35.5 30.625 35.5 17.5C35.5 12.8587 33.6563 8.40752 30.3744 5.12563C27.0925 1.84374 22.6413 0 18 0Z" fill="#FF0F51" />
            </svg>
            <p className="kontak-page__bold-text mt-3">Head Office</p>
            <p className="kontak-page__text">Gedung Kresna</p>
            <p className="kontak-page__text"> Jl. Arjuna Utara No. 28</p>
          </div>
        </div>
        <div className="col-md-12 col-lg-4 col-sm-12 mb-3">
          <div className="card kontak-page__card" style={{ minHeight: '193px' }}>
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M25.1 0C11.45 0 0.325 11.125 0.325 24.775C0.325 29.15 1.475 33.4 3.625 37.15L0.125 50L13.25 46.55C16.875 48.525 20.95 49.575 25.1 49.575C38.75 49.575 49.875 38.45 49.875 24.8C49.875 18.175 47.3 11.95 42.625 7.275C37.95 2.575 31.725 0 25.1 0ZM25.125 4.175C30.625 4.175 35.775 6.325 39.675 10.225C43.55 14.125 45.7 19.3 45.7 24.8C45.7 36.15 36.45 45.375 25.1 45.375C21.4 45.375 17.775 44.4 14.625 42.5L13.875 42.075L6.075 44.125L8.15 36.525L7.65 35.725C5.6 32.5 4.5 28.675 4.5 24.775C4.525 13.425 13.75 4.175 25.125 4.175ZM16.325 13.325C15.925 13.325 15.25 13.475 14.675 14.1C14.125 14.725 12.5 16.25 12.5 19.275C12.5 22.325 14.725 25.25 15 25.675C15.35 26.1 19.4 32.35 25.625 35C27.1 35.675 28.25 36.05 29.15 36.325C30.625 36.8 31.975 36.725 33.05 36.575C34.25 36.4 36.7 35.075 37.225 33.625C37.75 32.175 37.75 30.95 37.6 30.675C37.425 30.425 37.025 30.275 36.4 30C35.775 29.65 32.725 28.15 32.175 27.95C31.6 27.75 31.25 27.65 30.775 28.25C30.375 28.875 29.175 30.275 28.825 30.675C28.45 31.1 28.1 31.15 27.5 30.85C26.85 30.525 24.85 29.875 22.5 27.775C20.65 26.125 19.425 24.1 19.05 23.475C18.75 22.875 19.025 22.5 19.325 22.225C19.6 21.95 20 21.5 20.25 21.125C20.575 20.775 20.675 20.5 20.875 20.1C21.075 19.675 20.975 19.325 20.825 19.025C20.675 18.75 19.425 15.65 18.9 14.425C18.4 13.225 17.9 13.375 17.5 13.35C17.15 13.35 16.75 13.325 16.325 13.325Z" fill="#FF0F51" />
            </svg>
            <p className="kontak-page__bold-text mt-3">WhatsApp</p>
            <p className="kontak-page__text mb-4">(+62) 878-5706-7313</p>
          </div>
        </div>
        <div className="col-md-12 col-lg-4 col-sm-12 mb-3">
          <div className="card kontak-page__card" style={{ minHeight: '193px' }}>
            <svg width="50" height="40" viewBox="0 0 50 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M45 10L25 22.5L5 10V5L25 17.5L45 5V10ZM45 0H5C2.225 0 0 2.225 0 5V35C0 36.3261 0.526784 37.5979 1.46447 38.5355C2.40215 39.4732 3.67392 40 5 40H45C46.3261 40 47.5979 39.4732 48.5355 38.5355C49.4732 37.5979 50 36.3261 50 35V5C50 2.225 47.75 0 45 0Z" fill="#FF0F51" />
            </svg>
            <p className="kontak-page__bold-text mt-3">Email</p>
            <p className="kontak-page__text">hello@myedusolve.com</p>
          </div>
        </div>
      </div>
      <div className="row main-row mb-5 map-butuh-container">
        <div className="col-md-12 col-lg-6 col-sm-12 navbar-desc">
          <div className="kontak-page__map">
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyDplIWI3-rwPXkhX6dkplzHwjpMBNCF9i0' }}
              defaultCenter={{
                lat: -6.227284,
                lng: 106.83353,
              }}
              defaultZoom={14}
              distanceToMouse={() => { }}
            >
              <ArjunaUtara
                lat={-6.183923}
                lng={106.790426}
              />
              <RasunaSaid
                lat={-6.227284}
                lng={106.83353}
              />
            </GoogleMapReact>
          </div>
        </div>
        <div className="col-md-12 col-lg-6 col-sm-12 kontak-page__bantuan-kami">
          <div className="card w-100" style={{ padding: '20px' }}>
            <h2 className="landing-banner-card__text-big">Butuh Bantuan Kami?</h2>
            <p className="landing-banner-card__text-text mt-3">Silakan mengisi kolom dibawah ini apabila memiliki pertanyaan atau mengalami kendala. Kami akan segera membantu Anda.</p>
            <form
              className="contact_form mt-4"
              id="contact_form"
              name="contact_form"
              action="#"
              method="post"
              noValidate="noValidate"
            >
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <input
                      id="form_name"
                      name="form_name"
                      className="form-control"
                      required="required"
                      type="text"
                      placeholder="Nama Lengkap"
                      onChange={(e) => setNama(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <input
                      id="form_email"
                      name="form_email"
                      className="form-control required email"
                      required="required"
                      type="email"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <input
                      id="form_subject"
                      name="form_subject"
                      className="form-control required"
                      required="required"
                      type="text"
                      placeholder="Subyek"
                      onChange={(e) => setSubyek(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <textarea
                      id="form_message"
                      name="form_message"
                      className="form-control required"
                      rows="5"
                      required="required"
                      placeholder="Pesan"
                      onChange={(e) => setPesan(e.target.value)}
                    />
                  </div>
                  <div className="form-group ui_kit_button mb0 d-flex">
                    <button
                      type="btn"
                      className="btn btn-pink  ml-auto kontak-page__btn"
                      onClick={(e) => contactUs(e)}
                    >
                      Kirim
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12">
          <div className="navbar-mob">
            <div className="kontak-page__map">
              <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyDplIWI3-rwPXkhX6dkplzHwjpMBNCF9i0' }}
                defaultCenter={{
                  lat: -6.227284,
                  lng: 106.83353,
                }}
                defaultZoom={14}
                distanceToMouse={() => { }}
              >
                <ArjunaUtara
                  lat={-6.183923}
                  lng={106.790426}
                />
                <RasunaSaid
                  lat={-6.227284}
                  lng={106.83353}
                />
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default KontakPage;


