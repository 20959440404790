import React from 'react'

const CourseImage = ({ course }) => {
    return (
        <>
            <div className="course-card__img" style={{ backgroundImage: `url(${course.thumbnail ? course.thumbnail.url : '/images/courses/CourseNoPicture.png'})` }}>
            <div className="d-flex justify-content-end pt-3 pr-3">
                {course.kartu_prakerja ? <p className="course-card__prakerja pt-1 pb-1 pr-2 pl-2">
                    Kartu Prakerja
                </p> : ''}
            </div>
            </div> 
        </>
    )
} 

export default CourseImage