import React, { useState, useEffect } from 'react';
import { Radio } from 'antd';
import { connect, useDispatch } from 'react-redux'

const Payment = ({ course_detail }) => {
    const [value, setValue] = useState(0);
    const dispatch = useDispatch();
    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    useEffect(() => {
        if (course_detail !== null) {

            if (course_detail.coursemppdetailsall.length > 0) {
                setValue(course_detail.coursemppdetailsall[0].id);
                dispatch({
                    type: 'ADD_MPPURL_REDIRECT',
                    payload: course_detail.coursemppdetailsall[0].url,
                })
            }
        }
    }, [course_detail]);

    function onChange(e) {
        setValue(e.mpp_id);
        dispatch({
            type: 'ADD_MPPURL_REDIRECT',
            payload: e.url,
        })
    };

    useEffect(() => {
        if (course_detail !== null) {
            if (course_detail.coursemppdetailsall.length > 0) {
                if (course_detail.coursemppdetailsall[0] !== undefined) {
                    setValue(course_detail.coursemppdetailsall[0].id);
                    dispatch({
                        type: 'ADD_MPPURL_REDIRECT',
                        payload: course_detail.coursemppdetailsall[0].url,
                    })
                }

            }
        }
    }, [course_detail]);


    return (
        (course_detail !== null)
            ?
            (course_detail.coursemppdetailsall.length > 0)
                ?
                <div className="payment-new">
                    {course_detail.coursemppdetailsall.map((mpp, index) => (
                        <div onClick={() => onChange(mpp)} className={value == mpp.mpp_id ? 'payment-new__selected' : ''}>
                        {(mpp.mpp.thumbnail !== null)
                            ?
                            <img src={mpp.mpp.thumbnail.url} alt={mpp.mpp.name} className="payment-new__image--first" />
                            :
                            <span>{mpp.mpp.name}</span>
                        }
                        </div>))
                    }
                </div>
                :
                ''
            :
            ''

    )
}

const mapStateToProps = state => {
    return { course_detail: state.course_detail, mpp_url_redirect: state.mpp_url_redirect }
}
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    };
};

export default connect(
    mapStateToProps, mapDispatchToProps
)(Payment);