import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'react-notifications/lib/notifications.css';
import axios from 'axios'
import {  NotificationManager } from 'react-notifications';

const LoadingIcon = () => <div><img src="/images/smallLoading.gif" alt="loading" /></div>

const NotificationDropdownNPS = ({data, actionTaken, date}) => {
  const [ratingSelected, setRatingSelected] = useState(0)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch() 
  const profile_data = useSelector(state => state.profile_data)

  const sendNps = () => { 
    setLoading(true)
    const access_token = localStorage.getItem('access_token');
    axios
        .post(
        `${window.$API_URL}api/user/nps`, {
        rating: ratingSelected
        },
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
        },
        )
        .then((success) => {
          NotificationManager.success('NPS Put Successfully', 'Success');
          
          return actionTaken(data.id)
        }).then(() => {
          let notificationTemp = [...profile_data.notifications]
          notificationTemp.forEach(elNotif => {
            if(elNotif.id === data.id) {
              elNotif.is_action_taken = 1
            }
          })
          dispatch({
            type: 'ADD_PROFILEDATA',
            payload: {...profile_data, notifications: notificationTemp},
          })
        })
        .catch((error) => {
            if (!error.response) {
                NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
                return false
            }
            if (error.response.status === 422) {
              let errorTemp = []
              Object.keys(error.response.data.errors).forEach((key) => {
                errorTemp.push(error.response.data.errors[key][0]);
              });
              const errorList2 = errorTemp.map((char, i) => {
                return (
                  <li key={i} style={{ listStyleType: 'none' }}>
                    {char}
                  </li>
                );
              });
              NotificationManager.error(errorList2, 'Validasi Error!');
            } else if (error.response.status === 401) {
              NotificationManager.error(error.response.data.message, 'Validasi Error!');
            } else {
              NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
            }
        })
        .finally(() => setLoading(false))
    }

  return (
    <div>
      <h2>{data.group.description_id}</h2>
      <p>Seberapa besar kemungkinan Anda akan merekomendasikan MyEduSolve kepada teman atau kolega?</p>
      <div>
        <div>
          {[1,2,3,4,5,6,7,8,9,10].map(el => (
            <div onClick={() => setRatingSelected(el)}
            className={el <= ratingSelected ? 'notification-dropdown__nps-selected' : ''}><p>{el}</p></div>
          ))}
        </div>
        <div>
          <p>Tidak Rekomendasi</p>
          <p>Sangat Rekomendasi</p>
        </div>
      </div>
      <div>
        <button disabled={data.is_action_taken || loading} className={`btn btn-pink ${!data.is_action_taken && !loading ? '' : 'btn-disabled'}`} 
        onClick={(e) => {
          e.preventDefault()
          sendNps()

        }}>
          {loading ? <LoadingIcon/> : ''}
          {loading ? 'Mengirim Ulasan..' : 'Kirim Ulasan' }
        </button>
      </div>
    </div>
  )
}

export default NotificationDropdownNPS;
