import React from 'react';
import { Link } from 'react-router-dom';
// import { Link} from 'react-router-dom'
// import { Redirect, Route,  } from 'react-router-dom';
// import * as router from 'react-router-dom';
class LandingFooterTopArea extends React.Component {
  render() {
    return (
      <>
        <section className="footer_one home4">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-md-4 col-md-3 col-lg-2">
                <div className="footer_contact_widget home4">

                  <p>PT DWI INTI PUTRA</p>
                  <p><strong>Head Office</strong></p>
                  <p>Gedung Kresna</p>
                  <p>Jl. Arjuna Utara No. 28 </p>

                  <p className="pt20">E: <a href="mailto:hello@myedusolve.com">hello@myedusolve.com</a></p>
                  <a href="https://api.whatsapp.com/send/?phone=6287857067313&text&app_absent=0" target="_new">WA: +62 878-5706-7313</a>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-md-3 col-lg-2">
                <div className="footer_contact_widget home4">
                  <p className="pt30"><strong>Marketing Office</strong></p>
                  <p>BLOCK71 Jakarta</p>
                  <p>Ariobimo Sentral</p>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-md-3 col-lg-2">
                <div className="footer_company_widget home4">
                  <h4>MyEduSolve X</h4>
                  <ul className="list-unstyled">
                    <li><a href="https://myedusolve.com/about" target="_blank">Tentang Kami</a></li>
                    <li><Link to="/contactus">Kontak Kami</Link></li>
                    <li><Link to="/instructors">Jadi Instruktur</Link></li>
                    <li><Link to="/faq">Faq</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-md-3 col-lg-2">
                <div className="footer_program_widget home4">
                  <h4>PROGRAM</h4>
                  <ul className="list-unstyled">
                    <li><Link to="/courses">Kelas</Link></li>
                    <li><Link to="/webinar">Events</Link></li>
                    <li><a href="https://myedusolve.com/programs/certification" target="_blank">Sertifikasi</a></li>
                    <li><a href="https://myedusolve.com/programs/ace" target="_blank">Ambassador</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default LandingFooterTopArea;
