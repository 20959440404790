import React from 'react';
import { Link } from 'react-router-dom';

import { Instagramdef, Instagram, Facebook, Twitter, Youtube } from '../../static';
import awsmember from '../../static/aws-member.png';
import { connect, useSelector } from 'react-redux'
import "survey-react/survey.css";
const socialLinks = [
  {
    href: 'https://twitter.com/myedusolve',
    title: <Twitter />,
    id: '99'
  },
  {
    href: 'https://www.facebook.com/myedusolve.id/?_rdc=1&_rdr',
    title: <Facebook />,
    id: '98'
  },
  {
    href: 'https://www.instagram.com/myedusolve/',
    title: <Instagram />,
    id: '97'
  },
  {
    href: 'https://www.youtube.com/channel/UC8EZqWiSgCdmQsiFEEPER6Q',
    title: <Youtube />,
    id: '96'
  },
]

const links = [
  {
    href: '/terms',
    title: 'Term and Conditions',
    id: '95',
    padding: '10px'
  },
  {
    href: '/privacy',
    title: 'Privacy Policy',
    id: '94',
    padding: '0px'
  }
]




const FooterBottom = () => {
  const page_opened = useSelector(state => state.page_opened)

  return (
    <div className={`footerBottom ${page_opened === 'course_detail' ? 'footerBottom-course-detail' : ''}`}>


      {/* gradient for instagram icon  */}
      <Instagramdef />
      <div className="footerBottom__container container">
        <div className="footerBottom__block d-flex align-items-center">

          <div className="footerBottom__links_link">
            <a target={"_new"} href={'http://aws.amazon.com/awsedstart'} className="aws-icon-footer-desktop" key={'aws-sd'}><img src={awsmember} style={{ width: '120px', height: '120px' }} alt="AWS EdStart" /></a>
            {links.map(({ href, title, id, padding }) => (
              <Link to={href} key={id} style={{ paddingLeft: padding }}>
                <a className="footerBottom__link" href={href}>
                  {title}
                </a>
              </Link>
            ))}

          </div>
          <div className="d-flex justify-content-center">
            <a target={"_new"} href={'http://aws.amazon.com/awsedstart'} key={'aws-sd'} className="aws-icon-footer-mobile"><img src={awsmember} style={{ width: '120px', height: '120px' }} alt="AWS EdStart" /></a>
          </div>

          <div className="footerBottom__links_social">
            {socialLinks.map(({ href, title, id }) => (
              <a className="footerBottom__social-link" href={href} key={id} target="_new">
                {title}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    nps_eligible_data: state.nps_eligible_data,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(FooterBottom);
