import { Collapse } from 'antd';
import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import { CollapseDown } from '../../static';

import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CloseIcon, MenuFacebook, MenuInstagram, MenuTwitter, MenuYoutobe } from '../../static';

const data = [
  {
    header: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p>MYEDUSOLVEX</p>
        <CollapseDown fill={'white'} />
      </div>
    ),
    links: [
      { title: 'All Course', link: '/courses' },
      { title: 'Our Events', link: '/webinar' },
      { title: 'Instruktur di MyEduSolveX', link: '/instructors' },
    ],
    id: '39',
  },
  {
    header: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p>MEET</p>
        <CollapseDown fill={'white'} />
      </div>
    ),
    links: [{ title: 'Internship', link: '/intern' }],
    id: '37',
  },
  {
    header: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p>LPK</p>
        <CollapseDown fill={'white'} />
      </div>
    ),
    links: [
      {
        title: 'LPK - Pemagangan Jepang',
        link: 'https://lpk-gee.myedusolvex.com/',
        isHttps: true,
        disabled: false,
      },
      {
        title: 'LPK - Teknologi Informasi & Komunikasi',
        link: 'https://lpk-mes.myedusolvex.com/',
        isHttps: true,
        disabled: false,
      },
      {
        title: 'LSP - Teknologi Informasi & Komunikasi',
        link: 'https://lsp-mes.myedusolvex.com/',
        isHttps: true,
        disabled: false,
      },
      {
        title: 'PastiMagang (Coming Soon)',
        link: 'https://pastimagang.myedusolvex.com/',
        isHttps: true,
        disabled: true,
      },
    ],
    id: '340',
  },
  {
    links: [
      {
        title: 'KONTAK',
        link: '/contactus',
      },
    ],
    id: '36',
  },
];

const MenuMob = ({ onClose }) => {
  const { Panel } = Collapse;
  const profileData = useSelector((state) => state.profile_data);
  const dispatch = useDispatch();
  const logout = () => {
    const access_token = localStorage.getItem('access_token');
    axios
      .post(
        `${window.$API_URL}api/auth/logout/`,
        {},
        {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
        },
      )
      .then((success) => {
        localStorage.removeItem('access_token');
        window.location.reload();
      })
      .catch((error) => {
        localStorage.removeItem('access_token');
        window.location.reload();
      });
  };
  const displayPopup = (register) => {
    dispatch({
      type: 'UPDATE_LOGINPOPUP_OPEN',
      payload: 1,
    });
    dispatch({
      type: 'UPDATE_REGISTERPOPUP_OPEN',
      payload: register,
    });
    onClose();
  };

  return (
    <div className="menu">
      <header className="menu__header d-flex">
        <h2 className="menu__title">menu</h2>
        <div className="margin-left mr-3">
          <Button type="text" onClick={onClose}>
            <CloseIcon />
          </Button>
        </div>
      </header>
      <nav className="menu__nav">
        <Collapse accordion className="menu__list" expandIconPosition="right">
          {data.map((el) =>
            el.header ? (
              <Panel header={el.header} key={el.id} className="menu__list-item" showArrow={false}>
                {el.links.map((el2, id) => {
                  if (el2.isHttps) {
                    return (
                      <li key={id}>
                        <a
                          className="menu-item__link"
                          href={el2.link}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            pointerEvents: el2.disabled ? 'none' : 'auto',
                            cursor: el2.disabled ? 'not-allowed' : 'pointer',
                            opacity: el2.disabled ? '0.5' : 1,
                          }}
                        >
                          {el2.title}
                        </a>
                      </li>
                    );
                  } else {
                    return (
                      <li key={id}>
                        <Link to={el2.link}>
                          <a className="menu-item__link" onClick={onClose}>
                            {el2.title}
                          </a>
                        </Link>
                      </li>
                    );
                  }
                })}
              </Panel>
            ) : (
              <Link to={el.links[0].link} className="menu__list-item">
                <a className="menu-item__link" onClick={onClose}>
                  {el.links[0].title}
                </a>
              </Link>
            ),
          )}
          {profileData !== null ? (
            <Panel
              header={
                <>
                  PROFILE
                  <CollapseDown fill={'white'} />
                </>
              }
              key="40"
              className="menu__list-item"
              showArrow={false}
            >
              {[
                { title: 'Profile Saya', link: '/student/profile/' },
                { title: 'Kelas Saya', link: '/student/course/' },
                { title: 'Event Saya', link: '/student/events/' },
                { title: 'Pesanan Saya', link: '/student/orders/' },
              ].map((el, id) => {
                return (
                  <li key={id}>
                    <Link to={el.link}>
                      <a className="menu-item__link" onClick={onClose}>
                        {el.title}
                      </a>
                    </Link>
                  </li>
                );
              })}
            </Panel>
          ) : (
            ''
          )}
        </Collapse>
        {/* <ul className="menu__list">
                    {data.map(el => (
                        <li key={el.id} className="menu__list-item">
                            <MenuItem data={el} onClose={onClose} />
                        </li>
                    ))}
                    {profileData !== null ? (
                        <li key='40' className="menu__list-item">
                            <MenuItem data = {{
                                header: 'PROFILE',
                                links: [
                                    { title: 'Profile Saya', link: '/student/profile/' },
                                    { title: 'Kelas Saya', link: '/student/course/' },
                                    { title: 'Event Saya', link: '/student/events/' },
                                    { title: 'Pesanan Saya', link: '/student/orders/' },
                                ],
                                id: '37'
                            }} onClose={onClose}/>
                        </li>
                    ) : ''}
                </ul> */}
      </nav>
      <div className="menu__sign">
        {profileData ? (
          <div onClick={() => logout()}>
            <button className="btn btn-pink">Keluar</button>
          </div>
        ) : (
          <>
            <div onClick={() => displayPopup(0)}>
              <button className="btn btn-pink">Masuk</button>
            </div>
            <div onClick={() => displayPopup(1)}>
              <button className="btn btn-white">Daftar</button>
            </div>
          </>
        )}
      </div>
      <div className="menu__socials">
        <div className="d-flex justify-content-between">
          <Link to="/terms">
            <a>Term and Conditions</a>
          </Link>
          <Link to="/privacy">
            <a>Privacy Policy</a>
          </Link>
        </div>
        <ul className="menu__socials-list d-flex justify-content-between">
          <li>
            <a target="_blank" href="https://twitter.com/myedusolve">
              <MenuTwitter />
            </a>
          </li>
          <li>
            <a target="_new" href="https://www.facebook.com/myedusolve.id/?_rdc=1&_rdr">
              <MenuFacebook />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/myedusolve/" target="_blank">
              <MenuInstagram />
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UC8EZqWiSgCdmQsiFEEPER6Q" target="_blank">
              <MenuYoutobe />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MenuMob;
