import React, { useEffect } from 'react'
import Header from '../components/Header/Header';
import Footer from '../components/Footer';
import { removeScript  } from '../Utils/Utils';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../Layout/LoadingSpinner';
import { useMediaQuery } from 'react-responsive';

import Banner from '../components/Prakerja/Banner'
import Layanan from '../components/Prakerja/Layanan'
import Partner from '../components/Prakerja/Partner'
import Langkah from '../components/Prakerja/Langkah'
import Course from '../components/Prakerja/Course'
import Testimoni from '../components/Prakerja/Testimoni'

function Prakerja() {
    const isLoading = useSelector(state => state.isLoading)
    const isMobile = useMediaQuery({ query: '(max-width: 992px)' })

    useEffect(() => {
        return () => {
            removeScript('https://app.sandbox.midtrans.com/snap/snap.js');
        }
    }, [])

    return (
        <>
            <Header/>
            <Banner/>
            <div style={{background: "#FFF"}}>
                <div className="container-mesx d-flex flex-column">
                    {isMobile ? 
                    <>
                        <Partner/>
                        <Layanan/>
                    </>
                    :
                    <>
                        <Layanan/>
                        <Partner/>
                    </>}
                    
                </div>
                <Langkah/>
                <div className="container-mesx d-flex flex-column">
                    <Course/> 
                </div>
                <Testimoni/>
            </div>
            <Footer />
            {isLoading ? <LoadingSpinner text={"Mohon menunggu.."} /> : ''}
        </>
    )
}

export default Prakerja
