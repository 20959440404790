import React from 'react'
import { useMediaQuery } from 'react-responsive'

const KelasNav = ({tabSelected, setTabSelected}) => {
    const isMobile = useMediaQuery({ query: '(max-width: 992px)' })

    return (
        <ul className="nav nav-tabs nav-kelas-mob">
            <li className="nav-item mr-3">
                <a className={`nav-link kelas-saya-link ${tabSelected === 'sedang' ? 'kelas-saya-link__active' : ''}`}
                onMouseDown={() => {
                    setTabSelected('sedang')
                }}
                >Sedang Berlangsung<div>{isMobile ? <ArrowRight/> : ''}</div></a>
            </li>
            <li className="nav-item mr-3" 
            >
                <a className={`nav-link kelas-saya-link ${tabSelected === 'selesai' ? 'kelas-saya-link__active' : ''}`}
                onMouseDown={() => {
                    setTabSelected('selesai')
                }}>Selesai<div>{isMobile ? <ArrowRight/> : ''}</div></a>
            </li>
            <li className="nav-item mr-3" 
            >
                <a className={`nav-link kelas-saya-link ${tabSelected === 'sertifikat' ? 'kelas-saya-link__active' : ''}`}
            onMouseDown={() => {
                setTabSelected('sertifikat')
            }}>Sertifikat<div>{isMobile ? <ArrowRight/> : ''}</div></a>
            </li>
            <li className="nav-item mr-3" 
            >
                <a className={`nav-link kelas-saya-link ${tabSelected === 'diskusi' ? 'kelas-saya-link__active' : ''}`}
            onMouseDown={() => {
                setTabSelected('diskusi')
            }}>Diskusi<div>{isMobile ? <ArrowRight/> : ''}</div></a>
            </li>
        </ul>
    )
}

const ArrowRight = () => <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.589844 10.58L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.58Z" fill="black"/>
</svg>


export default KelasNav