import React, { useEffect, useState } from 'react';
import Banner from '../components/Banner';
import Bar from '../components/Bar';
import CourseFeature from '../components/CourseFeature';
import CourseRating from '../components/CourseRating';
import VideoPreview from '../components/CourseVideo/VideoPreview';
import VideoPreviewBottom from '../components/CourseVideo/VideoPreviewBottom';
import Footer from '../components/Footer';
import Header from '../components/Header/Header';
import InfoNew from '../components/Info';
import Info from '../components/Info/InfoTitle';
import CourseLanding from '../components/LandingNew/CourseLanding';
import LandingBanner from '../components/LandingNew/LandingBannerNew';
import Person from '../components/Person/PersonCourseDetail';
import Social from '../components/Social';
import Tag from '../components/Tag';

import moment from 'moment';
import publicIp from 'public-ip';
import ReactPixel from 'react-facebook-pixel';
import { Helmet } from 'react-helmet';
import { connect, useDispatch, useSelector } from 'react-redux';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router';
import LoadingSpinner from '../Layout/LoadingSpinner';
import { trackEventSourceNoAuth } from '../Utils/EventSourcing';
import {
  appendScript,
  fetchEbookCourseDetail,
  getCourseDetail,
  isLoggedIn,
  removeScript,
} from '../Utils/Utils';
// NEW COURSE DETAIL APRIL 2021

const CourseDetail = () => {
  const isDesktop = useMediaQuery({ query: '(min-device-width: 992px)' });
  const dispatch = useDispatch();
  const course_detail = useSelector((state) => state.course_detail);
  const isLoadingRedux = useSelector((state) => state.isLoading);
  const columnsTest = [
    {
      title: 'Materi',
      dataIndex: 'content',
      key: 'time',
    },
    {
      title: '4 Pelajaran',
      dataIndex: 'durationtext',
      key: 'startDate',
      responsive: ['sm'],
    },
  ];
  const { slug, slug_url } = useParams();
  const [loading, setloading] = useState(false);
  const [profileData, setprofileData] = useState(null);
  const [courseEbook, setCourseEbook] = useState(null);
  const [courseDetail, setcourseDetail] = useState(null);
  const [tabSelected, setTabSelected] = useState('Detail');

  useEffect(() => {
    // Fetch course detail and whoami

    let user_check_api = {};
    let _courseDetail = {};
    const fetchCourseDetail = async () => {
      setloading(1);
      user_check_api = await isLoggedIn();
      _courseDetail = await getCourseDetail(slug);

      console.log(_courseDetail);
      setloading(0);
      // Storing
      setcourseDetail(_courseDetail);
      setprofileData(user_check_api.profile_data);
      const course_ebook = await fetchEbookCourseDetail(slug);
      setCourseEbook(course_ebook);

      dispatch({
        type: 'ADD_COURSEDETAIL',
        payload: _courseDetail,
      });
      dispatch({
        type: 'ADD_BANNERDETAIL',
        payload: _courseDetail ? _courseDetail.name : '',
      });
      dispatch({
        type: 'ADD_PROFILEDATA',
        payload: user_check_api.profile_data,
      });
      dispatch({
        type: 'SET_COURSE_EBOOK',
        payload: course_ebook.rows || [],
      });

      // Tracking
      trackEventSourceNoAuth({
        type: 'courseview',
        payload: {
          ip: await publicIp.v4(),
          course_id: slug,
          date: moment().format('YYYY-MM-DD hh:mm:ss'),
        },
      });

      ReactPixel.track('ViewContent', _courseDetail);
    };
    fetchCourseDetail();
    dispatch({
      type: 'SET_PAGE_OPENED',
      payload: 'course_detail',
    });
    return () => {
      dispatch({
        type: 'SET_PAGE_OPENED',
        payload: null,
      });
      dispatch({
        type: 'ADD_COURSEDETAIL',
        payload: null,
      });
      removeScript('https://app.midtrans.com/snap/snap.js');
    };
  }, [slug]);

  return (
    <>
      <Header />
      <main style={{ background: 'white' }} className="course-detail-new">
        <Helmet>
          <script src="https://www.googleoptimize.com/optimize.js?id=OPT-5S5MCK4"></script>
        </Helmet>

        <LandingBanner smallBanner />

        <div className="container course-detail-new-container">
          <div className="row">
            <div>
              <Banner />
              <Info tabSelected={tabSelected} setTabSelected={setTabSelected} />
              {tabSelected == 'Detail' ? (
                <>
                  <InfoNew />
                  {isDesktop ? <Person /> : ''}
                </>
              ) : (
                <CourseFeature />
              )}
            </div>
            <div>
              <Bar tabSelected={tabSelected} />
              {isDesktop ? <Tag /> : ''}
              {isDesktop || tabSelected == 'Detail' ? <Social /> : ''}
              {!isDesktop && tabSelected == 'Detail' ? <Person /> : ''}
            </div>
          </div>
        </div>

        {tabSelected == 'Detail' ? (
          <div className="row-black">
            <div className="container course-detail-new-container">
              <div className="row">
                <VideoPreview />
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {tabSelected == 'Detail' && isDesktop ? (
          <div className="row-blue">
            <div className="container course-detail-new-container">
              <div className="row">
                <VideoPreviewBottom />
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="container course-detail-new-container">
          {tabSelected == 'Detail' ? (
            <div className="row">
              <div>
                {!isDesktop ? <Tag /> : ''}
                <CourseRating />
              </div>
            </div>
          ) : (
            ''
          )}
          <div
            style={
              isDesktop
                ? { marginLeft: '-15px', marginRight: '-15px' }
                : { marginLeft: '1px', marginRight: '1px' }
            }
          >
            <CourseLanding notOnLanding />
          </div>
        </div>
      </main>
      <Footer />
      {loading || isLoadingRedux ? <LoadingSpinner text={'Mohon Tunggu..'} /> : ''}
    </>
  );
};

export default CourseDetail;
