import React, { useState, useEffect } from 'react';
import { Collapse } from 'react-collapse'
import moment from 'moment'
import { CollapseDown, CollapseUp } from '../../static';
import SubMateri from './SubMateri'
import { connect, useDispatch, useStore, useSelector } from 'react-redux';
import {  NotificationManager } from 'react-notifications';
import axios from 'axios'

const ContentDrawerProject = ({  }) => {
  const dispatch = useDispatch()
  const course_detail = useSelector(state => state.course_detail)
  const [collapseOpen, setCollapseOpen] = useState(false)

  const handleClick = () => setCollapseOpen(!collapseOpen)

  const confirmProject = () => {
    dispatch({ type: 'TOGGLE_LOADING_TRUE' })
    const access_token = localStorage.getItem('access_token')
    axios({
        method: 'POST',
        url: `${window.$API_URL}api/student/projectSubmitted`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
        },
        data: {
            "course_id": course_detail?.id,      
        }
    })
    .then(_ => window.location.reload() )
    .finally(_ => dispatch({ type: 'TOGGLE_LOADING_FALSE' })) 
}
  

  return (
    <div className={'content-drawer'}>
      <a className={`d-flex justify-content-between hosted-table align-items-center ${collapseOpen ?'hosted-table-selected' : ''}`} onClick={handleClick} aria-controls="class-subcontent-small" aria-expanded={collapseOpen}>
        <h2 className="class-subcontent-materi m-0">Proyek Tambahan</h2>
        {!collapseOpen ? <CollapseDown /> : < CollapseUp /> }
      </a>
      <Collapse isOpened={collapseOpen} >
        <div className="content-drawer-project" >
          <h2>{course_detail?.name}</h2>
          <p>{course_detail?.project_information}</p>
          <div>
            <p>Setelah kamu mengirimkan projek kamu ke instruktur yang bersangkutan, kamu harus tekan tombol dibawah ini untuk konfirmasi.</p>
            <div><button className="btn btn-pink" onClick={confirmProject} disabled={course_detail?.payment?.is_student_project_submitted}>{course_detail?.payment?.is_student_project_submitted ? 'Kamu telah mengkonfirmasi projek!' : 'Konfirmasi Pengiriman Projek'}</button></div>
            <p>Apabila instruktur memverifikasi projek kamu, kamu akan mendapatkan sertifikat keduamu di tab sertifikat pada profil mu.</p>
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default ContentDrawerProject;