import React from 'react';

export default () => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b)">
            <path fillRule="evenodd" clipRule="evenodd" d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="white" fillOpacity="0.6" />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M19.2426 11.8181L18.182 10.7574L15 13.9394L11.818 10.7574L10.7574 11.8181L13.9393 15.0001L10.7574 18.182L11.818 19.2427L15 16.0607L18.182 19.2427L19.2426 18.182L16.0607 15.0001L19.2426 11.8181Z" fill="white" />
        <defs>
            <filter id="filter0_b" x="-7" y="-7" width="44" height="44" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImage" stdDeviation="3.5" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape" />
            </filter>
        </defs>
    </svg>

)