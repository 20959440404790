import React from 'react';
import { useHistory } from 'react-router-dom';
import 'react-notifications/lib/notifications.css';

const NotificationDropdownProfile = ({data, actionTaken, date}) => {
    return (
      <div>
        <h2>{data.group.description_id}</h2>
        <p>Tambahkan nomor telepon yang dapat dihubungi.</p>
        <div>
          <button className="btn btn-pink" onClick={(e) => {
            e.preventDefault()
            window.open('/student/editprofile', "_blank")
            actionTaken(data.id)
            
          }} >Tambah Nomor Telepon</button>
        </div>
      </div>
    )
}

export default NotificationDropdownProfile;
