import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const SubMateri = ({
  content,
  lesson_detail_number_local,
  lecture_number_local,
  openModal,
  quiz,
  selectThisTab,
  isLessonSchedulePassed,
  parrentContent,
}) => {
  const lesson_detail_number = useSelector((state) => state.lesson_detail_number);
  const lecture_number = useSelector((state) => state.lecture_number);
  const dispatch = useDispatch();
  const lesson_detail = useSelector((state) => state.course_detail);
  const materiOrExam = useSelector((state) => state.materi_exam_opened);

  const title = content.name;
  const durasi = content.mediafile ? content.mediafile.duration : '';
  const id = content.id;
  const isDone = content.attended !== null;

  const selectSubMateri = () => {
    dispatch({
      type: 'UPDATE_MATERI_OPEN',
      payload: id,
    });
  };
  const selectExamOrMateri = (selected) => {
    dispatch({
      type: 'UPDATE_EXAM_OR_MATERI',
      payload: selected,
    });
  };

  const isSelected = () => {
    if (materiOrExam == 'materi') {
      if (
        lesson_detail_number == lesson_detail_number_local &&
        lecture_number_local == lecture_number
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const isSelectedQuiz = () => {
    if (materiOrExam == 'quizlecture' || materiOrExam == 'finish_perlecture') {
      if (
        lesson_detail_number == lesson_detail_number_local &&
        lecture_number_local == lecture_number
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const parseTime = (durasi) => {
    if (durasi > 3600) {
      return moment.utc(durasi * 1000).format('HH:mm:ss');
    }

    return moment.utc(durasi * 1000).format('mm:ss');
  };

  const updateVideoLink = (i, j) => {
    dispatch({
      type: 'UPDATE_LESSON_NUMBER',
      payload: i,
    });
    dispatch({
      type: 'UPDATE_LECTURE_NUMBER',
      payload: j,
    });
  };

  const prevVideoCheck = (i, j) => {
    if (lesson_detail.is_pretest_enabled && lesson_detail.pretest) {
      if (lesson_detail.pretest.is_prettest_given == null) {
        return false;
      }
    }

    // if lecture quiz required & lecture quiz enabled  is false || lecture is non required & disabled
    if (!(lesson_detail.is_lecturequiz_required && lesson_detail.is_lecturequiz_enabled)) {
      if (lesson_detail.lessons_front[i].lectures[j - 1]) {
        if (
          lesson_detail.lessons_front[i].lectures[j - 1].watchtime !== null &&
          lesson_detail.lessons_front[i].lectures[j - 1].mediafile
        ) {
          if (lesson_detail.lessons_front[i].lectures[j - 1].attended) {
            return true;
          }
        }
      } else if (!lesson_detail.lessons_front[i].lectures[j - 1]) {
        if (lesson_detail.lessons_front[i - 1]) {
          if (prevVideoCheck(i - 1, lesson_detail.lessons_front[i - 1].lectures.length))
            return true;
        } else {
          return true;
        }
      }
      return false;
    } else {
      // then in this block handle if lecture is required & enabled
      if (lesson_detail.lessons_front[i].lectures[j - 1]) {
        if (lesson_detail.lessons_front[i].lectures[j - 1].lecture_quiz_name) {
          if (lesson_detail.lessons_front[i].lectures[j - 1].is_lecture_quiz_passed) {
            return true;
          }
        } else {
          if (lesson_detail.lessons_front[i].lectures[j - 1].attended) {
            return true;
          }
        }
      } else if (!lesson_detail.lessons_front[i].lectures[j - 1]) {
        if (lesson_detail.lessons_front[i - 1]) {
          if (prevVideoCheck(i - 1, lesson_detail.lessons_front[i - 1].lectures.length))
            return true;
        } else {
          return true;
        }
      }

      if (new Date().getTime() > new Date(parrentContent?.end_date).getTime()) {
        // check if lesson schedule passed
        return true;
      }
      return false;
    }
  };

  const videoCheck = (i, j) => {
    if (lesson_detail.lessons_front[i].lectures[j].attended) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (
      lesson_detail_number == lesson_detail_number_local &&
      lecture_number_local == lecture_number
    ) {
      selectThisTab();
    }
  }, [lesson_detail_number, lecture_number]);

  const thisLesson =
    lesson_detail?.lessons_front.length > 0
      ? lesson_detail?.lessons_front[lesson_detail_number]
      : {};

  const thisLecture = thisLesson?.lectures?.length > 0 ? thisLesson?.lectures[lecture_number] : {};

  const checkEndDate = ({ endDate }) => {
    return moment(new Date()).isAfter(new Date(endDate));
  };

  const checkIsDone = isLessonSchedulePassed === true ? true : isDone === true ? true : false;

  return (
    <>
      {!lesson_detail.is_restricted ? (
        <div>
          <div
            onClick={() => {
              selectSubMateri();
              selectExamOrMateri('materi');
              if (durasi === 0) openModal('assignment');
              updateVideoLink(lesson_detail_number_local, lecture_number_local);
              window.scroll({ top: 0, left: 0, behavior: 'smooth' });
            }}
          >
            <div
              className={`d-flex hosted-table-subcontent align-items-center ${
                isSelected() ? 'hosted-table-subcontent-selected' : ''
              }`}
            >
              <img
                src={
                  checkIsDone === true
                    ? '/images/courses/courseDone.png'
                    : '/images/courses/courseUndone.png'
                }
                className={checkIsDone === true ? 'mr-4  ml-1' : 'mr-3'}
                style={checkIsDone === true ? { width: '20px', height: '20px' } : {}}
              />
              <p className={`class-subcontent-list m-0 mr-auto`}> {title}</p>
              <p className={`${'class-subcontent-list'} m-0`}> {durasi ? parseTime(durasi) : ''}</p>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div
            onClick={() => {
              if (durasi === 0) openModal('assignment');
              if (prevVideoCheck(lesson_detail_number_local, lecture_number_local)) {
                updateVideoLink(lesson_detail_number_local, lecture_number_local);
                selectSubMateri();
                selectExamOrMateri('materi');
                window.scroll({ top: 0, left: 0, behavior: 'smooth' });
              }
            }}
          >
            <div
              className={`d-flex hosted-table-subcontent align-items-center ${
                isSelected() ? 'hosted-table-subcontent-selected' : ''
              }`}
            >
              <img
                src={
                  checkIsDone === true
                    ? '/images/courses/courseDone.png'
                    : '/images/courses/courseUndone.png'
                }
                className={checkIsDone === true ? 'mr-4  ml-1' : 'mr-3'}
                style={checkIsDone === true ? { width: '20px', height: '20px' } : {}}
              />
              <p
                className={`${
                  prevVideoCheck(lesson_detail_number_local, lecture_number_local)
                    ? 'class-subcontent-list'
                    : 'class-subcontent-list-disabled'
                } m-0 mr-auto`}
              >
                {' '}
                {title}
              </p>
              <p
                className={`${
                  prevVideoCheck(lesson_detail_number_local, lecture_number_local)
                    ? 'class-subcontent-list'
                    : 'class-subcontent-list-disabled'
                } m-0`}
              >
                {' '}
                {durasi ? parseTime(durasi) : ''}
              </p>
            </div>
          </div>
        </div>
      )}
      {quiz ? (
        lesson_detail.is_restricted ? (
          <div>
            <div
              onClick={() => {
                if (prevVideoCheck(lesson_detail_number_local, lecture_number_local)) {
                  if (
                    videoCheck(lesson_detail_number_local, lecture_number_local) ||
                    thisLesson?.is_lesson_schedule_passed
                  ) {
                    selectExamOrMateri('quizlecture');
                    updateVideoLink(lesson_detail_number_local, lecture_number_local);
                    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
                  } else if (!thisLesson?.is_lesson_schedule_passed) {
                    openModal('quiz_end_date_false');
                  } else {
                    openModal('quiz_perlecture_warning');
                  }
                }
              }}
            >
              <div
                className={`d-flex hosted-table-subcontent align-items-center ${
                  isSelectedQuiz() ? 'hosted-table-subcontent-selected' : ''
                }`}
              >
                {lesson_detail.lessons_front[lesson_detail_number_local].lectures[
                  lecture_number_local
                ].is_lecture_quiz_passed ? (
                  <img
                    src="/images/courses/courseDone.png"
                    className="mr-4  ml-1"
                    style={{ width: '20px', height: '20px' }}
                  ></img>
                ) : (
                  <div className="mr-3">
                    <img src="/images/courses/courseUndone.png"></img>
                  </div>
                )}
                <p
                  className={`${
                    prevVideoCheck(lesson_detail_number_local, lecture_number_local)
                      ? 'class-subcontent-list'
                      : 'class-subcontent-list-disabled'
                  } m-0 mr-auto`}
                >{`Quiz`}</p>
                <p
                  className={`${
                    prevVideoCheck(lesson_detail_number_local, lecture_number_local)
                      ? 'class-subcontent-listQuiz'
                      : 'class-subcontent-listQuiz-disabled'
                  } m-0`}
                >
                  5 Pertanyaan
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div
              onClick={() => {
                selectExamOrMateri('quizlecture');
                updateVideoLink(lesson_detail_number_local, lecture_number_local);
                window.scroll({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <div
                className={`d-flex hosted-table-subcontent align-items-center ${
                  isSelectedQuiz() ? 'hosted-table-subcontent-selected' : ''
                }`}
              >
                {lesson_detail.lessons_front[lesson_detail_number_local].lectures[
                  lecture_number_local
                ].is_lecture_quiz_passed ? (
                  <img
                    src="/images/courses/courseDone.png"
                    className="mr-4  ml-1"
                    style={{ width: '20px', height: '20px' }}
                  ></img>
                ) : (
                  <div className="mr-3">
                    <img src="/images/courses/courseUndone.png"></img>
                  </div>
                )}
                <p className={'class-subcontent-list m-0 mr-auto'}>{`Quiz`}</p>
                <p className="class-subcontent-listQuiz m-0">5 Pertanyaan</p>
              </div>
            </div>
          </div>
        )
      ) : (
        ''
      )}
    </>
  );
};

export default SubMateri;
