import React from 'react';

//Components
import External from './External';
import Hosted from './Hosted';
import Live from './Live';

const CourseList = ({ course }) => {
  const { type } = course;

  const list = {
    hosted: <Hosted course={course} />,
    live: <Live course={course} />,
    external: <External course={course} />,
  };

  return <>{list[type]}</>;
};

export default CourseList;
