import React, { useEffect, useState } from 'react';
import { Check } from '../../static';
import { Drawer, Button, Menu, Dropdown } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'react-notifications/lib/notifications.css';
import moment from 'moment'
import axios from 'axios'

import NotificationDropdownProfile from './NotificationDropdownProfile'
import NotificationDropdownCertificate from './NotificationDropdownCertificate'
import NotificationDropdownReview from './NotificationDropdownReview'
import NotificationDropdownExam from './NotificationDropdownExam'
import NotificationDropdownNPS from './NotificationDropdownNPS'
import NotificationDropdownReviewSatisfaction from './NotificationDropdownReviewSatisfaction'
import NotificationDropdownFreeCourse from './NotificationDropdownFreeCourse'
import NotificationDropdownDiskusi from './NotificationDropdownDiskusi'

const BellIcon = ({blue}) => <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13 15H4V8.5C4 6 6 4 8.5 4C11 4 13 6 13 8.5V15ZM15 14V8.5C15 5.43 12.86 2.86 10 2.18V1.5C10 1.10218 9.84196 0.720644 9.56066 0.43934C9.27936 0.158035 8.89782 0 8.5 0C8.10218 0 7.72064 0.158035 7.43934 0.43934C7.15804 0.720644 7 1.10218 7 1.5V2.18C4.13 2.86 2 5.43 2 8.5V14L0 16V17H17V16L15 14ZM8.5 20C9.03043 20 9.53914 19.7893 9.91421 19.4142C10.2893 19.0391 10.5 18.5304 10.5 18H6.5C6.5 18.5304 6.71071 19.0391 7.08579 19.4142C7.46086 19.7893 7.96957 20 8.5 20Z" fill={blue ? "#0891D3" : "#20282E"}/>
</svg>

const BellIconNotification = ({blue}) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 17H7V10.5C7 8 9 6 11.5 6C14 6 16 8 16 10.5V17ZM18 16V10.5C18 7.43 15.86 4.86 13 4.18V3.5C13 3.10218 12.842 2.72064 12.5607 2.43934C12.2794 2.15804 11.8978 2 11.5 2C11.1022 2 10.7206 2.15804 10.4393 2.43934C10.158 2.72064 10 3.10218 10 3.5V4.18C7.13 4.86 5 7.43 5 10.5V16L3 18V19H20V18L18 16ZM11.5 22C12.0304 22 12.5391 21.7893 12.9142 21.4142C13.2893 21.0391 13.5 20.5304 13.5 20H9.5C9.5 20.5304 9.71071 21.0391 10.0858 21.4142C10.4609 21.7893 10.9696 22 11.5 22Z" fill={blue ? "#0891D3" : "#20282E"}/>
<circle cx="16" cy="8" r="3.47826" fill="#E5002C" stroke="white" stroke-width="1.04348"/>
</svg>

const NotificationIcon = ({notification, number}) => <div className='notification-icon'>
  {notification ? <BellIconNotification/> : <BellIcon/>}
  <p>{notification ? number : ''}</p>
</div>



const CloseIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="black"/>
</svg>

const NotificationPage = ({title, datas, index}) => {
  const [visible, setVisible] = useState(false)
  const [unreadNotification, setUnreadNotification] = useState(0)
  const [bellClicked, setBellClicked] = useState(false)
  const profile_data = useSelector(state => state.profile_data)
  const dispatch = useDispatch()
  
  const readNotificiation = async () => {
    const access_token = localStorage.getItem('access_token');
    axios.get(`${window.$API_URL}api/notification/markread`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
    })
  }

  useEffect(() => {
    let unreadNotificationTemp = 0
    if(profile_data) {
      profile_data.notifications.forEach(el => {
        if(!el.is_read) unreadNotificationTemp++
      })
    }
    setUnreadNotification(unreadNotificationTemp)
  }, profile_data)

  if(!profile_data) return null
  return (
      <div className="headercustomcss__link-item ml-0 header-notification">
      <Dropdown overlay={<NotificationMenu closeDropdown={() => setVisible(false)}/>} overlayClassName="notification-overlay" trigger={['click']}
      onVisibleChange={(visibility) => setVisible(visibility)} visible={visible}  overlayStyle={{ position: 'fixed', }}>
        <a className="headercustomcss__link-new" onClick={() => {
          readNotificiation()
          setBellClicked(true)
        }} >
          <NotificationIcon number={unreadNotification} notification={(!(!unreadNotification) && !bellClicked)}/> 
        </a>
      </Dropdown>

    </div>
  )
}

const NotificationMenu = ({ closeDropdown }) => {
    const dispatch = useDispatch() 
    const profile_data = useSelector(state => state.profile_data)
    const [notificationGroupedByDate, setNotificationGroupedByDate] = useState([])

    const actionTaken = (id) => {
      const access_token = localStorage.getItem('access_token');
      return axios.get(`${window.$API_URL}api/notification/action/${id}`, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
      })
      .then(() => {
        let notificationTemp = [...profile_data.notifications]
        notificationTemp.forEach(elNotif => {
          if(elNotif.id == id) {
            elNotif.is_action_taken = 1
          }
        })
        dispatch({
          type: 'ADD_PROFILEDATA',
          payload: {...profile_data, notifications: notificationTemp},
        })
        return true
      })
    }

    const notificationTypeFunc = (data, date) => {
      const notificationType = { 
        "4": <NotificationDropdownProfile data={data} actionTaken={actionTaken} />,
        "2": <NotificationDropdownCertificate data={data} actionTaken={actionTaken}/>,
        "7": <NotificationDropdownReviewSatisfaction data={data} actionTaken={actionTaken}/>,
        "5": <NotificationDropdownNPS data={data} actionTaken={actionTaken}/>,
        "3": <NotificationDropdownReview data={data} actionTaken={actionTaken}/>,
        "1" : <NotificationDropdownExam data={data} actionTaken={actionTaken}/>,
        "8" : <NotificationDropdownFreeCourse data={data} actionTaken={actionTaken}/>,
        "9" : <NotificationDropdownDiskusi data={data} actionTaken={actionTaken}/>
        
      }
      return notificationType[data.notification_group_id]
    }
    const notificationTitle = { 
      "4": ['Lengkapi Profile', 'profile'],
      "2": ['Sertifikat', 'certificate'],
      "7": ['Kepuasaan Penggunaan', 'satisfaction'],
      "5": ['Feedback', 'nps'],
      "3": ['Review Kelas', 'review'],
      "1" : ['Akses Exam', 'certificate'],
      "8" : ['Free Course', 'free'],
      "9" : ['Diskusi Bersama Instruktur', 'profile'],
    }

    useEffect(() => {
      let notificationGroupedByDateTemp = []
      profile_data.notifications.forEach(el => {
        if(el.is_action_taken) {
          return ''
        }
        let found = false
        const notificationDate = moment(el.created_at).format('DD/MM/YYYY')
        notificationGroupedByDateTemp.forEach(elTemp => {
          if(elTemp.date == notificationDate) {
            elTemp.notifications.push(el)
            found = true
          }
        })

        if(!found) notificationGroupedByDateTemp.push({date: notificationDate, notifications: [el]})
      })
      let notificationGroupedByDateTempActionTaken = []
      profile_data.notifications.forEach(el => {
        if(!el.is_action_taken) {
          return ''
        }
        let found = false
        const notificationDate = moment(el.created_at).format('DD/MM/YYYY')
        notificationGroupedByDateTempActionTaken.forEach(elTemp => {
          if(elTemp.date == notificationDate) {
            elTemp.notifications.push(el)
            found = true
          }
        })

        if(!found) notificationGroupedByDateTempActionTaken.push({date: notificationDate, notifications: [el]})
      })
      setNotificationGroupedByDate([...notificationGroupedByDateTemp, ...notificationGroupedByDateTempActionTaken])
    }, [profile_data.notifications])

    return (
      <Menu className="notification-dropdown">
        <div className="notification-dropdown__header">
          <h2>Pemberitahuan</h2>
          <div onClick={closeDropdown}><CloseIcon/></div>
        </div>
        {notificationGroupedByDate.map(elDate => (
          <>
          <Menu.Item>
            <div className="notification-dropdown__header_2">
              <h2></h2>
            </div>
          </Menu.Item>
          {elDate.notifications.map(el => (
            <Menu.Item>
              <div className={`notification-dropdown__${notificationTitle[el.notification_group_id][1]} ${el.is_action_taken ? 'notification-dropdown__child' : 'notification-dropdown__child-unread'}`}>
                <div>
                  <div>
                    {el.is_action_taken ? <BellIcon blue/> : <BellIconNotification blue/>}
                    <h2>{notificationTitle[el.notification_group_id][0]}</h2>
                    <p>{elDate.date + ', ' + moment(el.created_at).format('HH:mm [WIB]')}</p>
                  </div>
                  {notificationTypeFunc(el)}
                </div>
              </div>
            </Menu.Item>
          ))}
          </>
        ))}
      </Menu>
    )
}




export default NotificationPage;