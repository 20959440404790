import React from 'react';
import { withRouter } from 'react-router-dom'
import { Carousel } from 'antd';
import ReactGA from 'react-ga';

import Arrow from './Arrow';
import Card from '../CourseCard';

class Slider extends React.Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();
    }
    next() {
        this.carousel.next();
    }
    previous() {
        this.carousel.prev();
    }

    gaOnFocus = (el) => {
        if(this.props.location.pathname === '/') {
            ReactGA.event({
                category: 'Kelas Populer',
                action: `Click Details-Home`,
                label: el.name
            })
        } else if(this.props.location.pathname === '/courses') {
            ReactGA.event({
                category: 'Kelas Populer',
                action: `Click Details-Semua Kelas`,
                label: el.name
            }) 
        }
    }

    render() {
        const props = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1124,
                    settings: {
                        slidesToShow: this.props.courses_list ? (this.props.courses_list.length >= 3 ? 3 : this.props.courses_list.length) : 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: this.props.courses_list ? (this.props.courses_list.length >= 2 ? 2 : this.props.courses_list.length) : 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 595,
                    settings: {
                        slidesToShow: 1.5,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 391,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className="slider" style={this.props.noArrow ? {paddingRight: '0', paddingLeft: '0'} : {}}>
                {!this.props.sliderOnly ? 
                    <>
                        <h2 className="slider__title text-align">Rekomendasi Kelas</h2>
                        <p className="slider__small-text font-weight-300 text-align">Bebas pilih kelas mana yang sesuai dengan kamu</p>
                    </> : '' 
                }
                <div className="pos-relative">
                    {!this.props.noArrow ? 
                        <>
                            <div onClick={this.previous} className="slider__arrow slider__arrow--left">
                                <Arrow type='left' />
                            </div>
                        </> : '' 
                    }
                    <Carousel ref={node => (this.carousel = node)} {...props}>
                        {this.props.courses_list.map((data) => (
                            <div onFocus={() => this.gaOnFocus(data)}>
                                <Card data={data} notSlider/>
                            </div>
                        ))}
                    </Carousel>
                    {!this.props.noArrow ? 
                        <>
                            <div onClick={this.next} className="slider__arrow slider__arrow--right">
                                <Arrow type='right' />
                            </div>
                        </> : '' 
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(Slider)