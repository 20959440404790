/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import Footer from '../../components/Footer';
import Header from '../../components/Header/Header';
import MenuUser from '../../components/MenuUser';
import { API_URL } from '../../constant';

export default function UnjukKeterampilanDetail() {
  const token = localStorage.getItem('access_token');
  const { slug } = useParams();

  const [confirmModal, setConfirmModal] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [myFileList, setMyFileList] = useState([]);
  const [myUkDetail, setMyUkDetail] = useState('');
  const [loadingMyJournal, setLoadingMyUk] = useState(false);

  const [loadingSubmitAll, setLoadingSubmitAll] = useState(false);
  const [title, setTitle] = useState('');
  const [file, setFile] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const submitUnjukKeterampilan = async () => {
    setLoadingSubmit(true);
    try {
      const formData = new FormData();
      formData.append('assigment', file);
      formData.append('title', title);
      formData.append('student_uk_ketra_id', Number(slug));

      let res = await axios.post(`${API_URL}/kjflow/submitukasassignmemt`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        NotificationManager.success('File berhasil diunggah');
        setConfirmModal(false);

        await fetchMyUkFileList();

        clearState();
      }
    } catch (error) {
      NotificationManager.error(error?.response?.data?.message ?? 'File gagal diunggah');
    } finally {
      setLoadingSubmit(false);
    }
  };

  const clearState = () => {
    setFile(null);
    setTitle('');
  };

  const fetchMyUkFileList = async () => {
    try {
      setLoadingMyUk(true);

      let res = await axios.post(
        `${API_URL}/kjflow/filelistuktest`,
        { student_uk_ketra_id: slug },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      res = res?.data?.data;
      setMyFileList(res);
    } catch (error) {
      setMyFileList('');
    } finally {
      setLoadingMyUk(false);
    }
  };

  const fetchMyDetailUk = async () => {
    try {
      setLoadingMyUk(true);
      let res = await axios.post(
        `${API_URL}/kjflow/detailuktest`,
        { student_uk_ketra_id: slug },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      res = res?.data?.data;
      setMyUkDetail(res);
    } catch (error) {
      setMyUkDetail('');
    } finally {
      setLoadingMyUk(false);
    }
  };

  useEffect(() => {
    fetchMyUkFileList();
    fetchMyDetailUk();
  }, [slug, token]);

  const submitAll = async () => {
    try {
      setLoadingSubmitAll(true);
      let res = await axios.post(
        `${API_URL}/kjflow/finalizeassignmentuk`,
        { student_uk_ketra_id: Number(slug) },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      if (res.status === 200) {
        NotificationManager.success('Tugas berhasil dikirim');

        window.location.reload();
      }
    } catch (error) {
      NotificationManager.error(error?.response?.data?.message ?? 'Gagal mengirim tugas');
    } finally {
      setLoadingSubmitAll(false);
    }
  };

  return (
    <>
      <Header />
      <main>
        <div className="main-container container student-dashboard">
          <div className="row">
            <div
              className="col-sm-12 col-lg-2 col-xl-2 menu-shadow"
              style={{ padding: '0', zIndex: '2' }}
            >
              <div className="navbar-desc h-100">
                <MenuUser />
              </div>
            </div>
            <div className="col-sm-12 col-lg-10 col-xl-10 w-100 content-user-container">
              <div className="dashboard-container">
                <div className="">
                  <h1 className="content-user__title mt-4">Unjuk Keterampilan {slug}</h1>
                </div>

                {myUkDetail?.is_submitted === 0 && (
                  <>
                    <hr />
                    <div
                      className="flex flex-col gap-3 pb-4 border-b"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px',
                      }}
                    >
                      <div
                        className=""
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '4px',
                        }}
                      >
                        <label style={{ fontSize: '18px', fontWeight: 600, marginBottom: 0 }}>
                          Judul
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setTitle(e.target.value)}
                          value={title}
                          required={true}
                          placeholder="Masukkan judul disini"
                          style={{
                            padding: '10px',
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            boxShadow: 'none',
                            width: '50%',
                          }}
                        />
                      </div>
                      <div
                        className=""
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '4px',
                        }}
                      >
                        <label style={{ fontSize: '18px', fontWeight: 600, marginBottom: 0 }}>
                          Unggah tugasmu disini
                        </label>
                        <input
                          type="file"
                          accept=".pdf,.doc,.docx,.txt,image/*"
                          placeholder="pdf, doc, docx, txt, image/*"
                          onChange={handleFileUpload}
                          style={{
                            padding: '10px',
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            boxShadow: 'none',
                            width: '50%',
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className="flex justify-start"
                      style={{ display: 'flex', justifyContent: 'flex-start' }}
                    >
                      <button
                        className="btn btn-pink"
                        onClick={() => setConfirmModal(true)}
                        disabled={loadingSubmit || !file || !title}
                      >
                        {loadingSubmit ? 'Loading...' : 'Unggah'}
                      </button>
                    </div>
                  </>
                )}

                <div className="">
                  {myFileList?.length > 0 && (
                    <div className="">
                      {(myUkDetail?.score || myUkDetail?.feedback) && (
                        <div className="mb-2">
                          {myUkDetail?.score && (
                            <p className="" style={{ fontWeight: '400', fontSize: '14px' }}>
                              <b>Score:</b> {myUkDetail?.score}
                            </p>
                          )}

                          {myUkDetail?.feedback && (
                            <p className="" style={{ fontWeight: '400', fontSize: '14px' }}>
                              <b>Feedback:</b> {myUkDetail?.feedback}
                            </p>
                          )}
                        </div>
                      )}

                      <div>
                        <hr />
                        <p
                          className=""
                          style={{ fontWeight: '600', marginBottom: '8px', fontSize: '16px' }}
                        >
                          Kiriman ({myFileList?.length})
                        </p>
                        <div
                          className=""
                          style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
                        >
                          {myFileList?.map((el, i) => {
                            return (
                              <div
                                className=""
                                key={i}
                                style={{
                                  padding: '16px',
                                  borderRadius: '8px',
                                  border: '1px solid rgba(204, 204, 204)',
                                  width: '100%',
                                  display: 'flex',
                                  gap: '8px',
                                }}
                              >
                                <a href={el?.media?.url ?? ''} target="_blank" rel="noreferrer">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                    width={24}
                                    height={24}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                                    />
                                  </svg>
                                </a>
                                <h4 style={{ fontWeight: '600' }} className="">
                                  {el?.title}
                                </h4>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {myUkDetail?.is_submitted === 0 && (
                        <button
                          className="btn btn-pink mt-4"
                          onClick={() => submitAll()}
                          disabled={loadingSubmitAll}
                        >
                          {loadingSubmit ? 'Loading...' : 'Kirim Tugas'}
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* modal journal */}
        <ReactModal
          onRequestClose={() => setConfirmModal(false)}
          closeTimeoutMS={100}
          isOpen={confirmModal}
          overlayClassName="journal-popup-overlay"
          className="journal-popup-content"
          ariaHideApp={false}
        >
          <div className="">
            <p style={{ fontWeight: '600', fontSize: '18px', marginBottom: '1rem' }}>
              Apakah Anda ingin mengunggah tugas unjuk keterampilan?
            </p>

            <div
              className="flex gap-4 items-center w-full"
              style={{ display: 'flex', gap: '1rem', alignItems: 'center', width: '100%' }}
            >
              <button
                className="btn btn-outline-secondary w-100"
                style={{ color: 'black' }}
                onClick={() => setConfirmModal(false)}
              >
                Tidak
              </button>
              <button
                className="btn btn-pink w-100"
                onClick={() => submitUnjukKeterampilan()}
                disabled={loadingSubmit}
              >
                {loadingSubmit ? 'Loading...' : 'Ya'}
              </button>
            </div>
          </div>
        </ReactModal>
      </main>
      <Footer />
      <NotificationContainer />

      {loadingMyJournal && <LoadingSpinner />}
    </>
  );
}
