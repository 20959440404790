import React from 'react';
import axios from 'axios';
import LandingHeader from './LandingHeader';
import LandingHeaderLogin from './LandingHeaderLogin';

import LandingMenuMobile from './LandingMenuMobile';
import LandingMenu from './LandingMenu';
import LandingFooterTopArea from './LandingFooterTopArea';
import LandingVideoSection from './LandingVideoSection';
import LandingFooterMiddleArea from './LandingFooterMiddleArea';
import LandingTopCategoriesSection from './LandingTopCategoriesSection';
import LandingSchoolCategory from './LandingSchoolCategory';
import LandingFooter from './LandingFooter';
import LandingDivider from './LandingDivider';
import LandingBlogSection from './LandingBlogSection';
import LandingTopRatedSection from './LandingTopRatedSection';
import LandingMenuNavHolder from './LandingMenuNavHolder';
import 'react-notifications/lib/notifications.css';
import LoadingSpinner from '../Layout/LoadingSpinner';
import VoucherLoginRedeemPopup from './includes/VoucherLoginRedeemPopup';

class VerifyLoginVoucherLayout extends React.Component {
  constructor(props) {
    super(props);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let email = params.get('email');
    let code = params.get('code');
    if (email === null || code === null || email === '' || code === '') {
      alert('Invalid Request');
      window.location.href = '/';
    }
    this.state = {
      isLoggedIn: false,
      userName: '',
      role_id: '',
      is_menu_opened: false,
      email: email,
      code: code
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.isLoggedIn();
  }

  isLoggedIn = async () => {
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      //    alert('hi');
      axios
        .get(`${window.$API_URL}api/auth/me/`, {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
        })
        .then((success) => {
          this.setState({
            loading: false,
          });
          if (success.data.success) {
            this.setState({
              isLoggedIn: true,
              userName: success.data.data.first_name + " " + success.data.data.last_name,
              role_id: success.data.data.role_id,
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            isLoggedIn: false,
          });
        });
    } else {
      // alert('bye');
      this.setState({
        isLoggedIn: false,
      });
    }
  };

  toggleMobileMenu = () => {
    this.setState({
      is_menu_opened: !this.state.is_menu_opened,
    })
  }

  render() {
    return (
      <div className="app">
        <div className="wrapper  mm-page mm-slideout">
          {this.state.isLoggedIn
            ?
            <LandingHeaderLogin
              userName={this.state.userName}
              role_id={this.state.role_id}
            />
            :
            <LandingHeader />
          }
          <LandingMenuNavHolder is_menu_opened={this.state.is_menu_opened} is_loggedin={this.state.isLoggedIn} />
          <LandingMenu />
          <LandingMenuMobile
            is_menu_opened={this.state.is_menu_opened}
            toggleMobileMenu={this.toggleMobileMenu}
          />
          <VoucherLoginRedeemPopup isOpen={true} code={this.state.code} email={this.state.email} />
          <LandingVideoSection />
          <LandingTopCategoriesSection />
          <LandingSchoolCategory />
          <LandingDivider />
          <LandingBlogSection />
          <LandingTopRatedSection />
          <LandingFooterTopArea />
          <LandingFooterMiddleArea />
          <LandingFooter />
        </div>
        {this.state.alert}
        {this.state.loading ? <LoadingSpinner /> : ''}
      </div>
    );
  }
}

export default VerifyLoginVoucherLayout;
