import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//Components
import ContentDrawer from './ContentDrawer';
import ContentDrawerProject from './ContentDrawerProject';

//Assets
import { Exam, RatingStar, QnaIcon, Improvement, EbookIcon } from '../../static';
import { parseTotalTimeEbook } from '../../Utils/Utils';

const CourseNav = ({ openModal, navFunction, openedID, setOpenedID }) => {
  const dispatch = useDispatch();
  const selectedID = useSelector((state) => state.materi_open_id);
  const course_detail = useSelector((state) => state.course_detail);
  const materiCount = course_detail ? course_detail.lessons_front.length : 0;
  const materi_exam_opened = useSelector((state) => state.materi_exam_opened);
  const course_ebook = useSelector((state) => state.course_ebook);

  if (!course_detail) {
    return '';
  }

  const HowToDownloadButton = () => {
    return (
      <div className={'content-drawer'}>
        <a
          className={`d-flex align-items-center ${
            materi_exam_opened === 'howToDownload' ? 'rating-exam-selected' : 'hosted-table'
          }`}
          onClick={() => {
            dispatch({
              type: 'UPDATE_EXAM_OR_MATERI',
              payload: 'howToDownload',
            });
          }}
        >
          <h2 className="title">Cara Mengunduh Sertifikat</h2>
        </a>
      </div>
    );
  };

  const CourseEbook = () => {
    return (
      <div className={'content-drawer'}>
        <a
          className={`d-flex align-items-center hosted-table`}
          onClick={() => {
            navFunction.selectExamOrMateri('ebook');
          }}
        >
          <EbookIcon />
          <h2 className="class-subcontent-materi m-0 rating-exam">E-book Kelas</h2>
          <p className="mb-0 ml-auto">{parseTotalTimeEbook(course_ebook || []) || ''}</p>
        </a>
      </div>
    );
  };

  const CourseResource = () => {
    return (
      <div className={'content-drawer'}>
        <a
          className={`d-flex align-items-center hosted-table`}
          onClick={() => {
            window.open(course_detail.course_zip.zip_url, '_blank');
          }}
        >
          <img
            src="/images/courses/downloadrsc.png"
            style={{ width: '24px', height: '16px' }}
          ></img>
          <h2 className="class-subcontent-materi m-0 rating-exam">Resource Kelas</h2>
        </a>
      </div>
    );
  };

  const QNAPick = ({ isDisabled }) => {
    if (isDisabled) {
      return (
        <div className={'content-drawer'}>
          <a className={`d-flex align-items-center hosted-table`}>
            <QnaIcon fill="#b7b4b4" />
            <h2 className="class-subcontent-materi m-0 rating-exam" style={{ color: '#b7b4b4' }}>
              Pilih Jadwal untuk QnA
            </h2>
          </a>
        </div>
      );
    } else {
      return (
        <div className="content-drawer">
          <a
            className={`d-flex align-items-center ${
              selectedID === 'qna' ? 'rating-exam-selected' : 'hosted-table'
            }`}
            onClick={() => {
              navFunction.selectSubMateri('qna');
              navFunction.selectExamOrMateri('qna');
            }}
          >
            <QnaIcon fill="black" />
            <h2 className="class-subcontent-materi m-0 rating-exam">Pilih Jadwal untuk QnA</h2>
          </a>
        </div>
      );
    }
  };

  const CourseQuiz = ({ isDisabled }) => {
    if (isDisabled) {
      return (
        <div className={'content-drawer'}>
          <a className={`d-flex align-items-center hosted-table`}>
            <Exam fill="#b7b4b4" />
            <h2 className="class-subcontent-materi m-0 rating-exam" style={{ color: '#b7b4b4' }}>
              Exam
            </h2>
          </a>
        </div>
      );
    } else {
      return (
        <div className={'content-drawer'}>
          <a
            className={`d-flex align-items-center ${
              selectedID === 'exam' ? 'rating-exam-selected' : 'hosted-table'
            }`}
            onClick={() => {
              navFunction.checkIfQuizPassed();
            }}
          >
            <Exam />
            <h2 className="class-subcontent-materi m-0 rating-exam">Exam</h2>
          </a>
        </div>
      );
    }
  };

  const CoursePreTest = ({ isDisabled }) => {
    if (isDisabled) {
      return (
        <div className={'content-drawer'}>
          <a className={`d-flex align-items-center hosted-table`}>
            <Exam fill="#b7b4b4" />
            <h2 className="class-subcontent-materi m-0 rating-exam" style={{ color: '#b7b4b4' }}>
              Pre-Test
            </h2>
          </a>
        </div>
      );
    } else {
      return (
        <div className={'content-drawer'}>
          <a
            className={`d-flex align-items-center ${
              materi_exam_opened === 'pretest' ? 'rating-exam-selected' : 'hosted-table'
            }`}
            onClick={() => {
              navFunction.selectSubMateri('pretest');
              navFunction.selectExamOrMateri('pretest');
            }}
          >
            <Exam />
            <h2 className="class-subcontent-materi m-0 rating-exam">Pre-Test</h2>
          </a>
        </div>
      );
    }
  };

  const CourseImprovement = ({ isDisabled }) => {
    if (isDisabled) {
      return (
        <div className={'content-drawer'}>
          <a className={`d-flex align-items-center hosted-table`}>
            <Improvement fill="#b7b4b4" />
            <h2 className="class-subcontent-materi m-0 rating-exam" style={{ color: '#b7b4b4' }}>
              Improvement
            </h2>
          </a>
        </div>
      );
    } else {
      return (
        <div className={'content-drawer'}>
          <a
            className={`d-flex align-items-center ${
              materi_exam_opened === 'exam_improvement' ? 'rating-exam-selected' : 'hosted-table'
            }`}
            onClick={() => {
              navFunction.selectSubMateri('exam_improvement');
              navFunction.selectExamOrMateri('exam_improvement');
            }}
          >
            <Improvement />
            <h2 className="class-subcontent-materi m-0 rating-exam">Improvement</h2>
          </a>
        </div>
      );
    }
  };

  const CourseRating = ({ isFree }) => {
    return (
      <div className={'content-drawer'}>
        <a
          className={`d-flex align-items-center ${
            selectedID === 'rating' ? 'rating-exam-selected' : 'hosted-table'
          }`}
          onClick={() => {
            if (isFree) {
              navFunction
                .freeCertificateCheck()
                .then((id) => {
                  navFunction.selectSubMateri('rating');
                  openModal('ratingReview');
                })
                .catch((err) => openModal('free_course_warning'));
            } else {
              navFunction.selectSubMateri('rating');
              openModal('ratingReview');
            }
          }}
        >
          <RatingStar />
          <h2 className="class-subcontent-materi m-0 rating-exam">Rating dan Ulasan</h2>
        </a>
      </div>
    );
  };

  const CourseFreeCert = () => {
    return (
      <div className={'content-drawer'}>
        <a
          className={`d-flex align-items-center ${
            selectedID === 'certificate_free_course' ? 'rating-exam-selected' : 'hosted-table'
          }`}
          onClick={() => {
            navFunction
              .freeCertificateCheck()
              .then((id) => {
                dispatch({
                  type: 'UPDATE_MATERI_OPEN',
                  payload: 'certificate_free_course',
                });
                dispatch({
                  type: 'UPDATE_EXAM_OR_MATERI',
                  payload: 'certificate_free_course',
                });
                dispatch({
                  type: 'FREE_CERT_ID',
                  payload: id,
                });
              })
              .catch((err) => openModal('free_course_warning'));
          }}
        >
          <img src="/images/courses/certificates.png" />
          <h2 className="class-subcontent-materi m-0 rating-exam">Sertifikat</h2>
        </a>
      </div>
    );
  };

  return (
    <div>
      <HowToDownloadButton />
      {course_detail.course_zip ? <CourseResource /> : ''}
      {course_ebook ? course_ebook[0] ? <CourseEbook /> : '' : ''}
      {course_detail.is_pretest_enabled && course_detail.pretest ? (
        course_detail.pretest.is_prettest_given == null ? (
          <CoursePreTest />
        ) : (
          <CoursePreTest isDisabled />
        )
      ) : (
        ''
      )}

      {course_detail.type == 'hosted'
        ? course_detail.lessons_front.map((el, i) => (
            <ContentDrawer
              openModal={openModal}
              arrayContent={el}
              materiCount={materiCount}
              openedID={openedID}
              setOpenedID={setOpenedID}
              lessonNumber={i}
            ></ContentDrawer>
          ))
        : ''}
      {course_detail.type == 'hosted' &&
      course_detail.is_qna_required &&
      course_detail.is_qna_enabled ? (
        navFunction.courseQnaCheck() ? (
          <QNAPick />
        ) : (
          <QNAPick isDisabled />
        )
      ) : (
        ''
      )}
      {course_detail.quiz !== null ? (
        <>
          {navFunction.examCheck() ? <CourseQuiz /> : <CourseQuiz isDisabled />}

          {course_detail.is_pretest_enabled && course_detail.pretest ? (
            course_detail.pretest.is_prettest_given !== null &&
            course_detail.quiz.is_exam_given !== null ? (
              <CourseImprovement />
            ) : (
              <CourseImprovement isDisabled />
            )
          ) : (
            ''
          )}
          {/* <CourseRating /> */}
        </>
      ) : (
        ''
      )}
      {course_detail.retail_price == 0 ? (
        <>
          <CourseRating isFree />
          <CourseFreeCert />
        </>
      ) : (
        <CourseRating />
      )}
      {course_detail.project_information ? <ContentDrawerProject /> : ''}
    </div>
  );
};

export default CourseNav;
