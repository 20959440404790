import React from 'react';

export default ({ fill }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 4C10.7956 4 11.5587 4.31607 12.1213 4.87868C12.6839 5.44129 13 6.20435 13 7C13 7.79565 12.6839 8.55871 12.1213 9.12132C11.5587 9.68393 10.7956 10 10 10C9.20435 10 8.44129 9.68393 7.87868 9.12132C7.31607 8.55871 7 7.79565 7 7C7 6.20435 7.31607 5.44129 7.87868 4.87868C8.44129 4.31607 9.20435 4 10 4ZM10 11.5C13.315 11.5 16 12.8425 16 14.5V16H4V14.5C4 12.8425 6.685 11.5 10 11.5Z"
      fill={fill}
    />
    <path
      d="M16 6C16.3978 6 16.7794 6.15804 17.0607 6.43934C17.342 6.72064 17.5 7.10218 17.5 7.5C17.5 7.89782 17.342 8.27936 17.0607 8.56066C16.7794 8.84196 16.3978 9 16 9C15.6022 9 15.2206 8.84196 14.9393 8.56066C14.658 8.27936 14.5 7.89782 14.5 7.5C14.5 7.10218 14.658 6.72064 14.9393 6.43934C15.2206 6.15804 15.6022 6 16 6ZM16 9.75C17.6575 9.75 19 10.4212 19 11.25V12H13V11.25C13 10.4212 14.3425 9.75 16 9.75Z"
      fill={fill}
    />
  </svg>
);
