import { createStore } from 'redux'
const initialState = {
    profile_data: null,
    isLoggedIn: 0,
    course_detail: null,
    banner_detail: '',
    schedule_list: [],
    mpp_url_redirect: '',
    login_popup_open_flag: 0,
    register_popup_open_flag: 0,
    forgot_password_popup_open_flag: 0,
    voucher_popup_open: false,
    schedule_live_id_selected: null,
    my_class_submenu: '',
    materi_open_id: null,
    materi_exam_opened: 'materi',
    materi_video_link: '',
    fill_all_quiz_question: false,
    exam_passed: false,
    quiz_popup_type: '',
    quiz_score_data: null,
    voucher_popup_open: false,
    isLoading: 0,
    all_course_list: null,
    all_course_result: null,
    intern_list: null,
    instructor_list: null,
    instructor_selected: null,
    all_event_list: null,
    event_selected: null,
    userOrders: null,
    lesson_detail_number: 0,
    lecture_number: 0,
    student_course: null,
    certificates: [],
    student_event: null,
    voucher_to_be_redeem: null,
    resource_list: null,
    cert_id_download: null,
    course_detail_row_schedule: null,
    nps_eligible_data: null,
    page_opened: null,
    course_ebook: null,
    certificate_failed: null,

}

const reducer = (state = initialState, action) => {
    if (action.type === 'SET_CERTIFICATE_FAILED') {
        return Object.assign({}, state, {
            // profile_data: state.profile_data.concat(action.payload)fix
            certificate_failed: action.payload
        })
    }
    if (action.type === 'SET_COURSE_EBOOK') {
        return Object.assign({}, state, {
            // profile_data: state.profile_data.concat(action.payload)fix
            course_ebook: action.payload
        })
    }

    if (action.type === 'SET_PAGE_OPENED') {
        return Object.assign({}, state, {
            // profile_data: state.profile_data.concat(action.payload)fix
            page_opened: action.payload
        })
    }

    if (action.type === 'STORE_VOUCHER_CODE') {
        return Object.assign({}, state, {
            // profile_data: state.profile_data.concat(action.payload)fix
            voucher_to_be_redeem: action.payload
        })
    }

    if (action.type === 'UPDATE_RESOURCE') {
        return (
            { ...state, resource_list: action.payload }
        )
    }
    if (action.type === 'ADD_PROFILEDATA') {
        return Object.assign({}, state, {
            // profile_data: state.profile_data.concat(action.payload)fix
            profile_data: action.payload
        })
    }

    if (action.type === 'ADD_NPS_ELIGIBLE') {
        return Object.assign({}, state, {
            nps_eligible_data: action.payload
        })
    }

    // course detail store data
    if (action.type === 'ADD_COURSEDETAIL') {
        return Object.assign({}, state, {
            course_detail: action.payload
        })
    }

    // course detail store data
    if (action.type === 'ADD_SCHEDULE_LIST') {
        return Object.assign({}, state, {
            schedule_list: action.payload
        })
    }

    // banner text detail store data
    if (action.type === 'ADD_BANNERDETAIL') {
        return Object.assign({}, state, {
            banner_detail: action.payload
        })
    }

    // mpp url text detail store data
    if (action.type === 'ADD_MPPURL_REDIRECT') {
        return Object.assign({}, state, {
            mpp_url_redirect: action.payload
        })
    }

    // mpp url text detail store data
    if (action.type === 'UPDATE_LOGINPOPUP_OPEN') {
        return Object.assign({}, state, {
            login_popup_open_flag: action.payload
        })
    }

    if (action.type === 'UPDATE_REGISTERPOPUP_OPEN') {
        return Object.assign({}, state, {
            register_popup_open_flag: action.payload
        })
    }

    if (action.type === 'UPDATE_FORGOT_PASS_OPEN') {
        return Object.assign({}, state, {
            forgot_password_popup_open_flag: action.payload
        })
    }

    if (action.type === 'FILL_ALL_QUIZ_QUESTION') {
        return Object.assign({}, state, {
            fill_all_quiz_question: action.payload
        })
    }

    if (action.type === 'EXAM_ALREADY_PASSED') {
        return Object.assign({}, state, {
            exam_passed: action.payload
        })
    }

    if (action.type === 'QUIZ_POPUP_TYPE') {
        return Object.assign({}, state, {
            quiz_popup_type: action.payload
        })
    }

    if (action.type === 'UPDATE_MATERI_OPEN') {
        return (
            { ...state, materi_open_id: action.payload }
        )
    }

    if (action.type === 'UPDATE_EXAM_OR_MATERI') {
        return (
            { ...state, materi_exam_opened: action.payload }
        )
    }
    if (action.type === 'QUIZ_SCORE_DATA') {
        return (
            { ...state, quiz_score_data: action.payload }
        )
    }

    if (action.type === 'UPDATE_VIDEO_LINK') {
        return (
            { ...state, materi_video_link: action.payload }
        )
    }
    if (action.type === 'VOUCHERPOPUP_UPDATE') {
        return Object.assign({}, state, {
            voucher_popup_open: action.payload
        })
    }
    if (action.type === 'UPDATE_LESSON_NUMBER') {
        return Object.assign({}, state, {
            lesson_detail_number: action.payload
        })
    }
    if (action.type === 'UPDATE_LECTURE_NUMBER') {
        return Object.assign({}, state, {
            lecture_number: action.payload
        })
    }

    if (action.type === 'SET_SELECTED_SCHEDULE') {
        return Object.assign({}, state, {
            schedule_live_id_selected: action.payload
        })
    }

    if (action.type === 'SET_MY_CLASS_SUBMENU') {
        return Object.assign({}, state, {
            my_class_submenu: action.payload
        })
    }

    if (action.type === 'TOGGLE_LOADING_TRUE') {
        let temp = { ...state }
        temp.isLoading++
        return temp
    }

    if (action.type === 'TOGGLE_LOADING_FALSE') {
        let temp = { ...state }
        temp.isLoading--
        return temp
    }

    if (action.type === 'SET_USER_ORDERS') {
        return Object.assign({}, state, {
            userOrders: action.payload
        })
    }

    if (action.type === 'EDIT_PROFILE') {
        const newObj = Object.assign({}, action.payload)
        return Object.assign({}, state, {
            profile_data: newObj
        })
    }

    if (action.type === 'SET_USER_COURSE') {
        return Object.assign({}, state, {
            student_course: action.payload
        })
    }

    if (action.type === 'SET_USER_CERTIFICATES') {
        return Object.assign({}, state, {
            certificates: action.payload
        })
    }

    if (action.type === 'SET_USER_EVENTS') {
        return Object.assign({}, state, {
            student_event: action.payload
        })
    }

    if (action.type === 'TOGGLE_LOADING_TRUE') {
        return Object.assign({}, state, {
            isLoading: true
        })
    }

    if (action.type === 'TOGGLE_LOADING_FALSE') {
        return Object.assign({}, state, {
            isLoading: false
        })
    }

    if (action.type === 'UPDATE_ALL_COURSE_LIST') {
        return Object.assign({}, state, {
            all_course_list: action.payload
        })
    }

    if (action.type === 'UPDATE_COURSE_RESULT') {
        return Object.assign({}, state, {
            all_course_result: action.payload
        })
    }

    if (action.type === 'UPDATE_INTERN_LIST') {
        return Object.assign({}, state, {
            intern_list: action.payload
        })
    }

    if (action.type === 'UPDATE_INSTRUCTOR_LIST') {
        return Object.assign({}, state, {
            instructor_list: action.payload
        })
    }

    if (action.type === 'UPDATE_INSTRUCTOR_SELECTED') {
        return Object.assign({}, state, {
            instructor_selected: action.payload
        })
    }

    if (action.type === 'UPDATE_EVENT_LIST') {
        return Object.assign({}, state, {
            all_event_list: action.payload
        })
    }

    if (action.type === 'UPDATE_EVENT_SELECTED') {
        return Object.assign({}, state, {
            event_selected: action.payload
        })
    }

    if (action.type === 'FREE_CERT_ID') {
        return Object.assign({}, state, {
            cert_id_download: action.payload
        })
    }

    if (action.type === 'COURSE_ROW_SCHEDULE') {
        return Object.assign({}, state, {
            course_detail_row_schedule: action.payload
        })
    }

    return state
}
const store = createStore(reducer)

export default store
