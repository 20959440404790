import React from 'react';

export default ({fill = 'black'}) => (
    <svg width="20" height="20" viewBox="0 0 54 59" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.7107 50.9539H1.5437C1.18475 50.9539 0.893433 51.2456 0.893433 51.6059V57.6805C0.893433 58.0406 1.18463 58.3325 1.5437 58.3325H14.7108C15.0698 58.3325 15.3609 58.0407 15.3609 57.6805V51.6059C15.3608 51.2456 15.0697 50.9539 14.7107 50.9539Z" fill={fill}/>
        <path d="M51.4964 35.6899H48.9736H45.9005H43.8008H40.728H38.3293C37.9703 35.6899 37.679 35.9817 37.679 36.342V57.6804C37.679 58.0406 37.9702 58.3324 38.3293 58.3324H51.4964C51.8554 58.3324 52.1462 58.0407 52.1462 57.6804V36.342C52.1461 35.9817 51.8554 35.6899 51.4964 35.6899Z" fill={fill}/>
        <path d="M33.1035 44.325H19.9366C19.5774 44.325 19.2863 44.6169 19.2863 44.9772V57.6806C19.2863 58.0407 19.5773 58.3326 19.9366 58.3326H33.1035C33.4625 58.3326 33.7536 58.0408 33.7536 57.6806V44.9771C33.7534 44.6169 33.4625 44.325 33.1035 44.325Z" fill={fill}/>
        <path d="M36.362 17.0857C36.2956 16.8863 36.15 16.7268 35.9591 16.6391C35.7709 16.5488 35.5535 16.5433 35.3575 16.6204L33.4545 17.3676L33.0755 17.5167C32.972 17.5593 32.8793 17.6228 32.8054 17.7029C32.7523 17.7587 32.7095 17.8197 32.6781 17.8837C32.6781 17.8837 32.6754 17.8837 32.6754 17.8861C32.6275 17.9873 32.5987 18.0963 32.5958 18.2109C32.5934 18.2933 32.6038 18.3755 32.6275 18.45C32.6489 18.5166 32.6754 18.5803 32.7125 18.6388C32.7893 18.7637 32.9031 18.8649 33.0409 18.926L33.049 18.9312L33.8894 19.3118C33.6639 19.793 33.428 20.269 33.1869 20.7424C32.9588 21.189 32.7231 21.6281 32.4818 22.0614L33.8203 22.8114C34.0826 22.3406 34.337 21.862 34.5863 21.3753C34.8272 20.9017 35.0608 20.4257 35.2858 19.9471L36.0757 20.2983C36.0996 20.3089 36.126 20.3195 36.1498 20.3272C36.2452 20.3674 36.3486 20.3834 36.4467 20.3779C36.6243 20.3725 36.7992 20.3034 36.9341 20.1785C37.1623 19.9709 37.2444 19.6492 37.1436 19.3592L36.362 17.0857Z" fill={fill}/>
        <path d="M12.5821 42.0442L11.9589 40.6382C10.5802 41.2538 9.15955 41.8087 7.73663 42.2868L8.22396 43.7456C9.69262 43.2519 11.1592 42.6794 12.5821 42.0442Z" fill={fill}/>
        <path d="M6.23602 44.3654L5.81381 42.8862C3.16295 43.6482 1.44527 43.8681 1.4281 43.8702L1.61697 45.3972C1.6906 45.3882 3.45876 45.1639 6.23602 44.3654Z" fill={fill}/>
        <path d="M13.7806 39.7776L14.463 41.1551C15.8618 40.458 17.235 39.6877 18.5441 38.8654L17.7309 37.561C16.464 38.3567 15.135 39.1024 13.7806 39.7776Z" fill={fill}/>
        <path d="M28.8812 27.513L30.0914 28.4586C31.0423 27.2334 31.9415 25.9379 32.7647 24.6079L31.4618 23.7961C30.6671 25.0804 29.7988 26.3306 28.8812 27.513Z" fill={fill}/>
        <path d="M23.9911 34.86L22.9948 33.6907C21.8607 34.6633 20.655 35.593 19.4115 36.4535L20.2821 37.7202C21.569 36.8294 22.817 35.8672 23.9911 34.86Z" fill={fill}/>
        <path d="M28.774 30.074L27.6106 29.0715C26.6345 30.2122 25.5846 31.3138 24.4903 32.3456L25.5402 33.467C26.6748 32.3975 27.7626 31.2559 28.774 30.074Z" fill={fill}/>
    </svg>
    
)