import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useSelector, useDispatch } from 'react-redux';
var fileDownload = require('js-file-download');
const ExamFinish = () => {
  const quizScoreData = useSelector((state) => state.quiz_score_data);
  const courseDetail = useSelector((state) => state.course_detail);
  const lesson_detail_number = useSelector((state) => state.lesson_detail_number);
  const lecture_number = useSelector((state) => state.lecture_number);
  const dispatch = useDispatch();

  const next = () => {
    if (courseDetail.lessons_front[lesson_detail_number].lectures[lecture_number + 1]) {
      dispatch({
        type: 'UPDATE_EXAM_OR_MATERI',
        payload: 'materi',
      });
      dispatch({
        type: 'UPDATE_LECTURE_NUMBER',
        payload: lecture_number + 1,
      });
    } else {
      if (courseDetail.lessons_front[lesson_detail_number + 1]) {
        dispatch({
          type: 'UPDATE_EXAM_OR_MATERI',
          payload: 'materi',
        });
        dispatch({
          type: 'UPDATE_LECTURE_NUMBER',
          payload: 0,
        });
        dispatch({
          type: 'UPDATE_LESSON_NUMBER',
          payload: lesson_detail_number + 1,
        });
      } else {
        if (courseDetail.quiz) {
          if (courseDetail.is_qna_enabled && courseDetail.is_qna_required) {
            if (courseDetail.course_qna_attend) {
              dispatch({
                type: 'UPDATE_MATERI_OPEN',
                payload: 'exam',
              });
              dispatch({
                type: 'UPDATE_EXAM_OR_MATERI',
                payload: 'exam',
              });
            } else {
              NotificationManager.error('Harap tuntaskan Sesi QnA terlebih dahulu.');
            }
          } else {
            dispatch({
              type: 'UPDATE_MATERI_OPEN',
              payload: 'exam',
            });
            dispatch({
              type: 'UPDATE_EXAM_OR_MATERI',
              payload: 'exam',
            });
          }
        } else {
          NotificationManager.error('Tidak ada konten tersedia.');
        }
      }
    }
  };

  const nextCheck = () => {
    if (courseDetail.lessons_front[lesson_detail_number].lectures[lecture_number + 1]) {
      return true;
    } else {
      if (courseDetail.lessons_front[lesson_detail_number + 1]) {
        return true;
      } else {
        if (courseDetail.quiz) {
          if (courseDetail.is_qna_enabled && courseDetail.is_qna_required) {
            if (courseDetail.course_qna_attend) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        } else {
          return false;
        }
      }
    }
  };

  return quizScoreData !== null && courseDetail !== null ? (
    <div
      className="exam-finish d-flex align-items-center justify-content-center"
      style={{
        backgroundImage: quizScoreData.isPassed
          ? 'url("/images/courses/examFinish.png")'
          : 'url("/images/courses/examFailed.png")',
      }}
    >
      <div className="exam-finish__modal d-flex flex-column align-items-center">
        <div className="exam-finish__title-perlecture">
          <h1>
            {quizScoreData.isPassed
              ? 'Selamat, kamu telah lulus Quiz!'
              : 'Yah, kamu belum lulus Quiz :('}
          </h1>
          {!quizScoreData.isPassed ? <p>Silahkan kembali mempelajari materi di video ini.</p> : ''}
        </div>
        {quizScoreData.isPassed ? (
          <div className="exam-finish__score_out d-flex">
            <div className="exam-finish__score_in d-flex flex-column align-items-center vertical-line">
              <p className="exam-finish__score__detail">Benar</p>
              <p className="exam-finish__score__benar">{quizScoreData.score}</p>
            </div>
            <div className="exam-finish__score_in d-flex flex-column align-items-center vertical-line">
              <p className="exam-finish__score__detail">Salah</p>
              <p className="exam-finish__score__salah">{5 - quizScoreData.score}</p>
            </div>
            <div className="exam-finish__score_in d-flex flex-column align-items-center">
              <p className="exam-finish__score__detail">Nilai</p>
              <p className="exam-finish__score__nilai">{quizScoreData.score * 20}</p>
            </div>
          </div>
        ) : (
          <div className="exam-finish__score_out d-flex">
            <div className="exam-finish__score_in d-flex flex-column align-items-center vertical-line">
              <p className="exam-finish__score__detail">Benar</p>
              <p className="exam-finish__score__benar">{quizScoreData.correct}</p>
            </div>
            <div
              className="exam-finish__score_in d-flex flex-column align-items-center vertical-line"
              style={{ border: '0' }}
            >
              <p className="exam-finish__score__detail">Salah</p>
              <p className="exam-finish__score__salah">{quizScoreData.wrong}</p>
            </div>
          </div>
        )}
        {nextCheck() ? (
          <a
            className="btn exam-finish__btn mt-5 w-100 btn-pink"
            onClick={(e) => (quizScoreData.isPassed ? next() : window.location.reload())}
          >
            {quizScoreData.isPassed ? 'Akses Materi Berikutnya' : 'Ambil Quiz Ulang'}
          </a>
        ) : (
          ''
        )}
      </div>
    </div>
  ) : (
    ''
  );
};

export default ExamFinish;
