import React from 'react';
import { Link } from 'react-router-dom';
// import { Redirect, Route,  } from 'react-router-dom';
// import * as router from 'react-router-dom';
import CustomUrlPopup from './includes/CustomUrlPopup';

class LandingMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu1: 0,
      menu2: 0,
      displayPopupCount: 0,
      modalOpen: 0,
    };
    this.style = {
      'z-index': '10',
      'position': 'relative'
    };
  }

  componentDidMount() {
  }

  toggleModalSigninPage = () =>
    this.setState({
      modalOpen: !this.state.modalOpen,
    });

  menuOpenOne = (e) => {
    this.setState({
      menu1: 1,
    });
  };

  menuCloseOne = (e) => {
    this.setState({
      menu1: 0,
    });
  };

  menuOpenTwo = (e) => {
    this.setState({
      menu2: 1,
    });
  };

  menuCloseTwo = (e) => {
    this.setState({
      menu2: 0,
    });
  };

  displayCustomUrlPopup = (e) => {
    e.preventDefault();
    const displayPopCount = this.state.displayPopupCount;

    this.setState({
      displayPopupCount: displayPopCount + 1,
    });
    if (displayPopCount === 2) {
      const { hostname } = window.location;
      if (
        hostname.includes('develop.myedusolvex.com') ||
        hostname === 'localhost' ||
        hostname === 'myx-32-react-login-register.develop.myedusolvex.com'
      ) {
        this.setState({
          modalOpen: 1,
          displayPopupCount: 0,
        });
      } else {
        this.setState({
          displayPopupCount: 0,
        });
      }
    }
  };

  render() {
    return (
      <div style={this.style}>
        <header id="" className="header-nav menu_style_home_four navbar-scrolltofixed stricky main-menu">
          <div className="container">
            <nav>
              <div className="menu-toggle">
                <img
                  className="nav_logo_img img-fluid"
                  src="images/images_logo-dark-background.png"
                  alt="header-logo.png"
                />
                <button type="button" id="menu-btn">
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>
              </div>
              <a
                href="/"
                onClick={(e) => this.displayCustomUrlPopup(e)}
                className="navbar_brand float-left dn-smd"
              >
                <img
                  className="logo1 img-fluid"
                  src="images/images_logo-dark-background.png"
                  alt="header-logo222.png"
                />
                <img
                  className="logo2 img-fluid"
                  src="images/images_logo-light-background.png"
                  alt="header-logo2.png"
                />
              </a>
              <ul id="" className="ace-responsive-menu" data-menu-style="horizontal">
                <li className="last">
                  <Link to="/#" onClick={(e) => window.location.href = '/contactus'}>
                    <span className="title">Kontak</span>
                  </Link>
                </li>
                <li
                  className="list_three"
                //onMouseLeave={(e) => this.menuCloseTwo(e)}
                //onMouseEnter={(e) => this.menuOpenTwo(e)}
                >
                  <Link to="/webinar">
                    <span className="title">Events</span>
                  </Link>
                  <ul
                    className={`${this.state.menu2 ? 'sub-menu slide' : 'sub-menu'}`}
                    style={{ display: `${this.state.menu2 ? 'block' : 'none'}` }}
                  >
                    <li>
                      <Link to="/webinar">Events</Link>
                    </li>
                  </ul>
                </li>

                <li
                  className="list_two"
                  onMouseLeave={(e) => this.menuCloseOne(e)}
                  onMouseEnter={(e) => this.menuOpenOne(e)}
                >
                  <a href="#/">
                    <span className="title">Kelas</span> <span className="arrowold " />
                  </a>
                  <ul
                    className={`${this.state.menu1 ? 'sub-menu slide' : 'sub-menu'}`}
                    style={{ display: `${this.state.menu1 ? 'block' : 'none'}` }}
                  >
                    <li>
                      <Link to="/courses">Semua Kelas</Link>
                    </li>
                    <li>
                      <Link to="/instructors">Instruktur</Link>
                    </li>
                  </ul>
                </li>

                <li className="list_one">
                  <a href="/">
                    <span className="title">HOME</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <CustomUrlPopup
          isOpen={this.state.modalOpen}
          toggleSignInPopup={this.toggleModalSigninPage}
        />
      </div>
    );
  }
}

export default LandingMenu;
