import React, { useState, useEffect } from 'react'
import { useParams, Link, useHistory, useLocation } from 'react-router-dom'
import { CourseExIconBlue, CourseLiveIconBlue, CourseHostedIconBlue } from '../../static'
import { useDispatch } from 'react-redux'
import { NotificationManager } from 'react-notifications';
import axios from 'axios'
import NumberFormat from 'react-number-format';
import { appendScript, removeScript } from '../../Utils/Utils';
import CheckoutModal from './CheckoutModal'
import ReactGA from 'react-ga';
import LinkedInTag from 'react-linkedin-insight';
import mixpanel from 'mixpanel-browser';

import { trackEventSource, trackEventSourceNoAuth } from '../../Utils/EventSourcing'
import publicIp from 'public-ip'
import moment from 'moment'
import ReactPixel from 'react-facebook-pixel'

const LINKED_IN_PARTNER_ID = 2429722
const LINKED_IN_CONVERSION_ID = 2976180

const PaperIcon = () => <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.00003 7.00004H14.5L9.00003 1.50004V7.00004ZM2.00003 4.19617e-05H10L16 6.00004V18C16 18.5305 15.7893 19.0392 15.4142 19.4143C15.0392 19.7893 14.5305 20 14 20H2.00003C0.890031 20 3.05176e-05 19.1 3.05176e-05 18V2.00004C3.05176e-05 0.890042 0.890031 4.19617e-05 2.00003 4.19617e-05ZM11 16V14H2.00003V16H11ZM14 12V10H2.00003V12H14Z" fill="black" />
</svg>

const VoucherIcon = () => <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V5H0.893C1.889 5 2.813 5.681 2.973 6.664C3.02174 6.95104 3.00726 7.24525 2.93056 7.52612C2.85387 7.80698 2.71681 8.06772 2.52894 8.29015C2.34108 8.51258 2.10694 8.69133 1.84287 8.81393C1.57879 8.93654 1.29115 9.00004 1 9H0V13C0 13.2652 0.105357 13.5196 0.292893 13.7071C0.48043 13.8946 0.734784 14 1 14H19C19.2652 14 19.5196 13.8946 19.7071 13.7071C19.8946 13.5196 20 13.2652 20 13V9H19C18.7089 9.00004 18.4212 8.93654 18.1571 8.81393C17.8931 8.69133 17.6589 8.51258 17.4711 8.29015C17.2832 8.06772 17.1461 7.80698 17.0694 7.52612C16.9927 7.24525 16.9783 6.95104 17.027 6.664C17.187 5.681 18.111 5 19.107 5H20V1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0ZM7 4C7.26522 4 7.51957 4.10536 7.70711 4.29289C7.89464 4.48043 8 4.73478 8 5C8 5.26522 7.89464 5.51957 7.70711 5.70711C7.51957 5.89464 7.26522 6 7 6C6.73478 6 6.48043 5.89464 6.29289 5.70711C6.10536 5.51957 6 5.26522 6 5C6 4.73478 6.10536 4.48043 6.29289 4.29289C6.48043 4.10536 6.73478 4 7 4ZM6.2 10.4L12.2 2.4L13.8 3.6L7.8 11.6L6.2 10.4ZM13 10C12.7348 10 12.4804 9.89464 12.2929 9.70711C12.1054 9.51957 12 9.26522 12 9C12 8.73478 12.1054 8.48043 12.2929 8.29289C12.4804 8.10536 12.7348 8 13 8C13.2652 8 13.5196 8.10536 13.7071 8.29289C13.8946 8.48043 14 8.73478 14 9C14 9.26522 13.8946 9.51957 13.7071 9.70711C13.5196 9.89464 13.2652 10 13 10Z" fill="#FF0F51" />
</svg>

const Arrow = ({ link }) => (
  <Link to={link}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-4 pt-2 mr-3">
      <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" fill="black" />
    </svg>
  </Link>

)

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CheckoutComp = () => {
  const params = useParams()
  const history = useHistory()
  const query = useQuery()
  const { type, id } = params
  const dispatch = useDispatch()
  const [detail, setDetail] = useState(null)
  const [voucherDiscount, setVoucherDiscount] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [insertVoucherContent, setInsertVoucherContent] = useState('Masukkan Kode Voucher')
  const [voucher, setVoucher] = useState('')
  const [voucherId, setVoucherId] = useState(null)
  const [voucherType, setVoucherType] = useState('discount')
  const closeModal = () => setModalOpen(!modalOpen)

  useEffect(() => {
    LinkedInTag.init(LINKED_IN_PARTNER_ID)
    LinkedInTag.track(LINKED_IN_CONVERSION_ID)
  }, [])

  const countTotalPrice = () => {
    let res = 0
    let totalPrice = type === 'course' ? +detail.retail_price : +detail.price
    if ((detail.discount_price === '0.00' || !detail.discount_price) && voucherDiscount === 0) {
      res = totalPrice
    }
    else if (voucherDiscount === 0) {
      res = +detail.discount_price
    }
    else if ((detail.discount_price === '0.00' || !detail.discount_price) && voucherDiscount) {
      res = totalPrice - voucherDiscount
    }
    else {
      res = +detail.discount_price - voucherDiscount
    }
    if (res > 0) return res
    else return 0
  }

  const fetchWebinarDetail = () => {
    dispatch({ type: 'TOGGLE_LOADING_TRUE' })
    axios
      .get(`${window.$API_URL}api/event/${id}`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((success) => {
        setDetail(success.data.data)
        ReactPixel.track('InitiateCheckout', success.data.data)
      }).catch((error) => {
        NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
      }).finally(_ => dispatch({ type: 'TOGGLE_LOADING_FALSE' }))
  }

  const fetchCourseDetail = () => {
    dispatch({ type: 'TOGGLE_LOADING_TRUE' })
    axios
      .get(`${window.$API_URL}api/course/${id}/detail`, {})
      .then((success) => {
        setDetail(success.data.data)
        ReactPixel.track('InitiateCheckout', success.data.data)
        // alert('here comes the course detail')
      })
      .catch((error) => {
        NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
      }).finally(_ => dispatch({ type: 'TOGGLE_LOADING_FALSE' }))
  }

  useEffect(() => {
    if (type === 'event') fetchWebinarDetail()
    else if (type === 'course') fetchCourseDetail()

    appendScript('https://app.midtrans.com/snap/snap.js', 'Mid-client-V_QBkVCUB9-49Fvo')
    //appendScript('https://app.sandbox.midtrans.com/snap/snap.js', 'Mid-client-V_QBkVCUB9-49Fvo')

    return () => {
      removeScript('https://app.midtrans.com/snap/snap.js');
      //removeScript('https://app.sandbox.midtrans.com/snap/snap.js');
    }

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const isLoggedIn = async () => {
    var result = false;
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      dispatch({ type: 'TOGGLE_LOADING_TRUE' })
      await axios
        .get(`${window.$API_URL}api/auth/me/`, {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
        })
        .then((success) => {
          dispatch({ type: 'TOGGLE_LOADING_FALSE' })
          if (success.data.success) {
            result = true;
          } else {
            result = false;
          }
        })
        .catch((error) => {
          result = false;
          dispatch({ type: 'TOGGLE_LOADING_FALSE' })
        })
    } else {
      result = false;
    }
    return result;
  };

  const purchaseCheck = async () => {
    var loggedIn = await isLoggedIn();
    if (loggedIn) {
      purchase();
    } else {
      dispatch({
        type: 'UPDATE_LOGINPOPUP_OPEN',
        payload: 1,
      })
    }
  }

  const purchase = () => {
    window.gtag('event', 'conversion', { 'send_to': 'AW-651417204/0vZ9CIiKz4ACEPSsz7YC' });
    mixpanel.track('CheckoutBayarSekarang');
    if (voucherType === 'discount') {
      let id = detail.id
      const access_token = localStorage.getItem('access_token');
      const dataToSend = voucherId ? { method: 'midtrans', discount_voucher_id: voucherId } : { method: 'midtrans' }
      if (type === 'event') {
        // code for FB Pixel
        ReactPixel.track('AddPaymentInfo', {
          type: 'event',
          id: id,
        })
        //code or FB Pixel

        axios.post(
          `${window.$API_URL}api/event/${id}/register`,
          dataToSend
          ,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${access_token}`,
            },
          }
        ).then((r) => {
          console.log(r)
          if ('snap' in window) {
            const { snap } = window;
            console.log({
              category: 'Event',
              action: `Pay Now`,
              label: detail.title,
              value: countTotalPrice()
            })
            snap.pay(r.data.data.token, {
              onSuccess: () => {
                NotificationManager.success('Pembelian Berhasil!', '');
                history.push(`/order/detail/?order_id=${r.data.data.id}`)
              },
              onPending: () => {
                alert('Payment Process Pending..');
                NotificationManager.success('Pembayaran Tertunda', '');
                history.push(`/order/detail/?order_id=${r.data.data.id}`)
              },
              onError: function (r) {
                alert('Some Error Occured in Processing Payment');
                console.log('error');
                console.log(r);
                history.push(`/order/detail/?order_id=${r.data.data.id}`)
              },
              onClose: function () {
                console.log('customer closed the popup without finishing the payment');
              },
            });
          }
        }).catch((error) => {
          alert('Some Error Occured in purchasing the course!!');
        })
      } else if (type === 'course') {
        const courseId = detail.id;
        const access_token = localStorage.getItem('access_token');
        const dataToSend = voucherId ? { method: 'midtrans', discount_voucher_id: voucherId } : { method: 'midtrans' }
        // code for FB Pixel
        ReactPixel.track('AddPaymentInfo', {
          type: 'course',
          id: courseId,
        })
        //code or FB Pixel
        axios
          .post(
            `${window.$API_URL}api/course/${courseId}/register`,
            dataToSend,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
              },
            },
          )
          .then((r) => {
            if ('snap' in window) {
              const { snap } = window;
              snap.pay(r.data.data.token, {
                onSuccess: async () => {
                  NotificationManager.success('Pembelian Berhasil!', '');
                  trackEventSource({
                    type: "courseregister",
                    payload: {
                      course_id: courseId,
                      method: 'midtrans',
                      code: ''
                    }
                  })
                  trackEventSourceNoAuth({
                    type: "courseregisterip",
                    payload: {
                      ip: await publicIp.v4(),
                      course_id: courseId,
                      date: moment().format('YYYY-MM-DD hh:mm:ss')
                    }
                  })
                  ReactGA.event({
                    category: type === 'event' ? 'Event' : query.get('section') === 'kelas_populer' ? 'Kelas Populer' : 'Semua Kelas',
                    action: `Pay Now`,
                    label: detail.name ? detail.name : detail.title,
                    value: countTotalPrice()
                  })
                  history.push(`/order/detail/?order_id=${r.data.data.id}`)
                },
                onPending: () => {
                  alert('Payment Process Pending..');
                  NotificationManager.success('Pembayaran Tertunda', '');
                  history.push(`/order/detail/?order_id=${r.data.data.id}`)
                },
                onError: function (err) {
                  alert('Some Error Occured in Processing Payment');
                  console.log('error');
                  console.log(err);
                  history.push(`/order/detail/?order_id=${r.data.data.id}`)
                },
                onClose: function () {
                  console.log('customer closed the popup without finishing the payment');
                },
              });
            }
          }).catch((error) => {
            alert('Some Error Occured in purchasing the course!!');
          })
      }
    } else {
      const access_token = localStorage.getItem('access_token');
      axios
        .get(
          `${window.$API_URL}api/voucher/checkvoucher/?voucher_code=` + voucher,
          {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
          },
        )
        .then(async (success) => {
          trackEventSource({
            type: "courseregister",
            payload: {
              course_id: success.data.data.course.id,
              method: 'voucher',
              code: voucher,
              voucher_id: success.data.data.id,
              mpp_id: success.data.data.mpp ? success.data.data.mpp.id : ''
            }
          })
          trackEventSourceNoAuth({
            type: "courseregisterip",
            payload: {
              ip: await publicIp.v4(),
              course_id: success.data.data.course.id,
              date: moment().format('YYYY-MM-DD hh:mm:ss')
            }
          })
          ReactGA.event({
            category: query.get('section') === 'kelas_populer' ? 'Kelas Populer' : 'Semua Kelas',
            action: `Redeem-Voucher`,
            label: detail.name + ' Kode Voucher Periksa',
          })
          ReactPixel.track('AddPaymentInfo', {
            course_id: success.data.data.course.id,
            method: 'voucher',
            code: voucher,
            voucher_id: success.data.data.id,
            mpp_id: success.data.data.mpp ? success.data.data.mpp.id : ''
          })
          // console.log(success)
          history.push('/student/orders/')
        })
    }
  }

  const redeemVoucher = () => {
    const access_token = localStorage.getItem('access_token')
    dispatch({ type: 'TOGGLE_LOADING_TRUE' })
    return axios({
      method: 'POST',
      url: `${window.$API_URL}api/discountvoucher/exist`,
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
      params: {
        code: voucher,
        code_for: type,
        resource_id: detail.id
      }
    }).then(success => {
      // console.log(success.data.data)
      setVoucherId(success.data.data.id)
      setInsertVoucherContent(`Kode Voucher : ${voucher}`)
      if (success.data.data.is_discount_voucher) {
        setVoucherType('discount')
        if (success.data.data.discount_type === 'percent') setVoucherDiscount(+success.data.data.current_price * +success.data.data.discount_value / 100)
        else if (success.data.data.discount_type === 'amount') setVoucherDiscount(+success.data.data.discount_value)
        return true
      } else {
        setVoucherType('non-discount')
      }
    })
      .catch(error => {
        let msg = error.response.data.message
        // console.log(msg)
        if (msg === 'This Discount Voucher Quota is finished') {
          NotificationManager.error('Quota voucher sudah terpakai semua', 'Gagal!');
        } else if (msg === 'The given data was invalid.') {
          NotificationManager.error('Voucher tidak ditemukan', 'Gagal!');
        } else if (error.response.status === 400) {
          NotificationManager.error(msg, 'Gagal!')
        } else {
          NotificationManager.error('Ada kesalahan pada server', 'Gagal!');
        }
        setModalOpen(false)
        setInsertVoucherContent('Masukkan Kode Voucher')
        setVoucherDiscount(0)
      })
      .finally(_ => dispatch({ type: 'TOGGLE_LOADING_FALSE' }))

  }

  // console.log(detail)

  return (
    <>
      {detail !== null ?
        <div className="checkout-page">
          <div className="navbar-mob"><div className="content-user-container navbar-mob" style={{ backgroundColor: 'white', width: '100vw', marginLeft: '-16px' }}>
            <div className="d-flex">
              <Arrow link={type === 'course' ? `/course/${id}/${detail.slug}` : `/webinar/${id}`} />
              <h1 className="content-user__title mt-4 navbar-mob">{`Review Pembelian ${type === 'event' ? 'Event' : 'Kelas'}`}</h1>
            </div>
          </div>
          </div>
          <h1 className="checkout-page__main-title navbar-desc">{`Review Pembelian ${type === 'event' ? 'Event' : 'Kelas'}`}</h1>
          <div className="card checkout-page__card">
            <div className="checkout-page__top-text">
              <h2 className="checkout-page__sub-title">Rincian Pembelian</h2>
            </div>
            <div className="checkout-page__main-content row">
              <div className="col-md-3 col-sm-4 col-5 d-flex justify-content-center">
                {type === 'event' ? <img src={detail.image_id ? detail.image.url : ''} className="checkout-page__pic" alt="course cover"/> :
                  detail.thumbnail_id ? <img src={detail.thumbnail ? detail.thumbnail.url : ''} alt="course cover" className="checkout-page__pic" /> : <div style={type === 'course' ? { backgroundImage: 'url(/static/media/banner_new.fe2b666b.jpg)', backgroundSize: 'cover', backgroundPositionX: '20px' } : ''} className="checkout-page__pic"></div>}
              </div>
              <div className="col-md-5 col-sm-7 col-7">
                {type === 'course' ?
                  (
                    <>
                      {detail.type === "external" ? <div className="d-flex align-items-center"><CourseExIconBlue /><p className="course-card__type ml-2">External</p></div> : ""}
                      {detail.type === "live" ? <div className="d-flex align-items-center"><CourseLiveIconBlue /> <p className="course-card__type ml-2">Live</p></div> : ""}
                      {detail.type === "hosted" ? <div className="d-flex align-items-center"><CourseHostedIconBlue /><p className="course-card__type ml-2">Self-Paced</p></div> : ""}
                    </>
                  ) : ''}
                <h3 className="checkout-page__title">{type === 'event' ? detail.title : detail.name}</h3>
                {type === 'course' ?
                  <h3 className="mt-3 course-card__instructor-name" >{detail.instructor.fullname}</h3> : ''}
                {type === 'course' ?
                  (
                    <div className="navbar-desc">
                      {detail.type === "live" ?
                        <p className="mt-3 checkout-page__pemilihan-jadwal">Pemilihan Jadwal Dilakukan Setelah Pembayaran Selesai.</p> : ''}
                    </div>
                  )
                  : ''}
                <div className="navbar-desc">
                  <div className="d-flex mt-5">
                    <div className="d-flex">
                      <PaperIcon />
                      <p className="ml-2">Sertifikat Penyelesaian</p>
                    </div>
                    {type === 'course' ?
                      <>
                        {detail.kartu_prakerja ?
                          <div className="d-flex pl-3 ml-3 checkout-page__prakerja">
                            <PaperIcon />
                            <p className="ml-2">Kartu Prakerja</p>
                          </div>
                          : ''}
                      </>
                      : ''}
                  </div></div>
              </div>
              <div className="navbar-mob col-12">
                <div>
                  {detail.type === "live" ?
                    <p className="mt-3 checkout-page__pemilihan-jadwal">Pemilihan Jadwal Dilakukan Setelah Pembayaran Selesai.</p> : ''}
                  <div className="d-flex mt-5">
                    <div className="d-flex">
                      <PaperIcon />
                      <p className="ml-2 checkout-page__sertif">Sertifikat Penyelesaian</p>
                    </div>
                    {type === 'course' ?
                      <>
                        {detail.kartu_prakerja ?
                          <div className="d-flex pl-3 ml-3 checkout-page__prakerja">
                            <PaperIcon />
                            <p className="ml-2 checkout-page__sertif">Kartu Prakerja</p>
                          </div>
                          : ''}
                      </>
                      : ''}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-lg-4 checkout-page__voucher-content">
                <div className="checkout-page__voucher-input" onClick={(e) => {
                  setModalOpen(true)
                }}>
                  <VoucherIcon />
                  <h3 className="checkout-page__masukan-kode ml-3 mb-0" >{insertVoucherContent}</h3>
                </div>
                <h2 className="checkout-page__total-title">Total Pembayaran</h2>
                <div className="d-flex">
                  <p className="checkout-page__total-detail">Harga Kelas</p>
                  <p className="checkout-page__price ml-auto">Rp
                      <NumberFormat
                      value={type === 'course' ? +detail.retail_price : +detail.price}
                      displayType={'text'}
                      thousandSeparator="."
                      isNumericStrin={true}
                      decimalSeparator={""}
                      thousandsGroupStyle="thousand"
                      className="ml-1"
                    /></p>
                </div>
                <div className="d-flex">
                  <p className="checkout-page__total-detail">Diskon</p>
                  {detail.discount_price ? (
                    <>
                      <p className="checkout-page__price ml-auto">- Rp
                          <NumberFormat
                          value={+detail.retail_price - +detail.discount_price}
                          displayType={'text'}
                          thousandSeparator="."
                          isNumericStrin={true}
                          decimalSeparator={""}
                          thousandsGroupStyle="thousand"
                          className="ml-1"
                        />
                      </p>
                    </>
                  ) : <p className="checkout-page__price ml-auto">--</p>}
                </div>
                <div className="d-flex">
                  <p className="checkout-page__total-detail">Diskon Voucher</p>
                  {voucherDiscount ?
                    <>
                      <p className="checkout-page__price ml-auto">- Rp
                          <NumberFormat
                          value={voucherDiscount}
                          displayType={'text'}
                          thousandSeparator="."
                          isNumericStrin={true}
                          decimalSeparator={""}
                          thousandsGroupStyle="thousand"
                          className="ml-1"
                        />
                      </p>
                    </>
                    : <p className="checkout-page__price ml-auto">--</p>}
                </div>
                <div className="d-flex checkout-page__total-bottom">
                  <p className="checkout-page__total-detail-big">Total:</p>
                  <p className="checkout-page__price-bold ml-auto">Rp
                      <NumberFormat
                      value={voucherType === 'discount' ? countTotalPrice() : '0'}
                      displayType={'text'}
                      thousandSeparator="."
                      isNumericStrin={true}
                      decimalSeparator={""}
                      thousandsGroupStyle="thousand"
                      className="ml-1"
                    />
                  </p>
                </div>
                <div className="btn btn-pink w-100 checkout-page__price-btn" onClick={() => purchaseCheck()}>{voucherType === 'discount' ? 'Bayar Sekarang' : 'Gunakan Voucher'}</div>
              </div>
            </div>
          </div>
          <CheckoutModal closeModal={closeModal} modalOpen={modalOpen} redeemVoucher={redeemVoucher} voucher={voucher} setVoucher={setVoucher} name={detail.name ? detail.name : detail.title} type={type} />
        </div>
        : ''}
      <img height="1" width="1" style={{ display: "none" }} alt="" src="https://px.ads.linkedin.com/collect/?pid=2429722&conversionId=2976180&fmt=gif" />
    </>
  )
}

export default CheckoutComp
