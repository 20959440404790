import React, { useEffect, useRef, useState } from 'react'
import instructors from './instructors.json'

const instructorData = [
  {
    name: 'Dyah Heliades',
    desc: 'Instructor MESX',
    image_url: 'https://d208804zsg1urw.cloudfront.net/instructors/fC1XbKV3pU7Jqa9FQU7ot1wV7wHVR3NTTKosBCqC.jpeg',
    color: '#7CC8E4'
  },
  {
    name: 'Laras Andari',
    desc: 'Instructor MESX',
    image_url: 'https://d208804zsg1urw.cloudfront.net/instructors/pAmaWLvbblcOA3SW6f14aPTcUpcHfm7NJ3D1OKh0.png',
    color: '#D1D1D1'
  },
  {
    name: 'Aldo Stephanus',
    desc: 'Instructor MESX',
    image_url: 'https://d208804zsg1urw.cloudfront.net/instructors/IzqxxRQYbRLIDep2qCryPTZ5Yst0j7rGO0KWdi7V.jpeg',
    color: '#913430'
  },
  {
    name: 'Elven Aprilnico',
    desc: 'Instructor MESX',
    image_url: 'https://d208804zsg1urw.cloudfront.net/instructors/5DHtkGIIhZWQ4SP6cOs4vsBGwNKXaBPgfaly5UZ0.jpeg',
    color: '#5BA0B7'
  },
  {
    name: 'Filip Schouwenaars',
    desc: 'Instructor MESX',
    image_url: 'https://d208804zsg1urw.cloudfront.net/instructors/YAzLQkfHqNuJGl41n3ySVdwoPUShrZQNPBtNo4YO.png',
    color: '#275B91'
  },
  {
    name: 'Dyah Heliades',
    desc: 'Instructor MESX',
    image_url: 'https://d208804zsg1urw.cloudfront.net/instructors/fC1XbKV3pU7Jqa9FQU7ot1wV7wHVR3NTTKosBCqC.jpeg',
    color: '#B0C8E4'
  },
  {
    name: 'Laras Andari',
    desc: 'Instructor MESX',
    image_url: 'https://d208804zsg1urw.cloudfront.net/instructors/pAmaWLvbblcOA3SW6f14aPTcUpcHfm7NJ3D1OKh0.png',
    color: '#969696'
  },
  {
    name: 'Aldo Stephanus',
    desc: 'Instructor MESX',
    image_url: 'https://d208804zsg1urw.cloudfront.net/instructors/IzqxxRQYbRLIDep2qCryPTZ5Yst0j7rGO0KWdi7V.jpeg',
    color: '#B3564F'
  },
  {
    name: 'Elven Aprilnico',
    desc: 'Instructor MESX',
    image_url: 'https://d208804zsg1urw.cloudfront.net/instructors/5DHtkGIIhZWQ4SP6cOs4vsBGwNKXaBPgfaly5UZ0.jpeg',
    color: '#87B4CB'
  },
  {
    name: 'Filip Schouwenaars',
    desc: 'Instructor MESX',
    image_url: 'https://d208804zsg1urw.cloudfront.net/instructors/YAzLQkfHqNuJGl41n3ySVdwoPUShrZQNPBtNo4YO.png',
    color: '#3971C2'
  }
]

const InstructorLanding = () => {
  const scrollHalf = useRef(null)


  const instrucorsData = instructors.data
  const onClickBtn = (e) => {
    e.preventDefault()
    window.open("/instructors?page=1", "_blank")
  }

  useEffect(() => {
    const { innerWidth: width } = window;
    scrollHalf.current.scrollLeft +=  512 - width/2
    
  }, [])

  return (
        <>
          <div className="instructor-landing-new">
            <div>
              <h1>Instruktur MyEduSolveX</h1>
              <p>Yuk, kenalan dengan para pengajarmu!</p>
              <div ref={scrollHalf}>
                <div>
                  {instrucorsData.map((instructor, i) => (
                    i < 5 ? <InstructorList instructor={instructor} instructorData={instructorData} i={i}/> : ''
                  ))}
                </div>
                <div>
                  {instrucorsData.map((instructor, i) => (
                    i >= 5 ? <InstructorList instructor={instructor} instructorData={instructorData} i={i}/> : ''
                  ))}
                </div>
              </div>
              <button className="btn btn-white" onClick={onClickBtn}>Lihat Semua Instruktur</button>
            </div> 
          </div>
        </>
    )
}

const InstructorList = ({instructor, instructorData, i}) => {
  const dataHeightRef = useRef(null)
  const dataHeightRef2 = useRef(null)
  const [showData, setShowData] = useState(0)
  useEffect(() => {
    const { innerWidth: width } = window;
    if(width < 991) setShowData(dataHeightRef2.current ? -dataHeightRef2.current.clientHeight : 'unset')
    
  }, [])
  return (
    <div ref={dataHeightRef} onClick={() => window.open(`/instructors/${instructor.id}/${instructor.fullname}`, "_blank")} 
    onMouseEnter={() => setShowData(dataHeightRef2.current ? -dataHeightRef2.current.clientHeight : 'unset')}
    onMouseLeave={() => setShowData(0)}> 
      <div style={{background: `${instructorData[i].color}`}}>
        <div>
          <div><img src={instructor.photo.url} /></div>
          <div ref={dataHeightRef2} style={{top: showData+2}}>
            <h3>{instructor.fullname}</h3>
            <p>{instructor.professional_designation}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InstructorLanding