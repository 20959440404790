import React from 'react';
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert';
import 'react-notifications/lib/notifications.css';
import LoadingSpinner from '../../Layout/LoadingSpinner';

class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email_address: '',
    };
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    document.addEventListener("mousedown", this.handleClickOutside, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
    document.removeEventListener("mousedown", this.handleClickOutside, false);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      if (this.props.isOpen) {
        this.dismissModalSignInPopup();
      }
    }
  }

  handleClickOutside = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
    //the click is outside
    if (this.props.isOpen) {
      this.dismissModalSignInPopup();
    }

  }


  dismissModalSignInPopup = () => {
    this.props.toggleForgerPasswordOpen();
  };


  recoverPassword = () => {
    this.setState({
      loading: true,
    });
    const access_token = localStorage.getItem('access_token');
    axios
      .post(
        `${window.$API_URL}api/password/reset/`,
        {
          email: this.state.email_address
        },
        {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
        },
      )
      .then((success) => {
        if (success.data.success) {
          this.setState({
            loading: false,
          });
          const getAlert = () => (
            <SweetAlert
              success
              showCancel={false}
              confirmBtnText="OK"
              confirmBtnBsStyle="success"
              title="Sukses!"
              onConfirm={() => window.location.href = '/'}
            >
              Tim kami telah mengirim email untuk proses reset password anda. Jika anda tidak dapat menemukan email dari kami, anda bisa cek di folder spam anda.
              Jika masih belum ada, anda bisa klik Resend Email.
            </SweetAlert>
          );
          this.setState({
            alert: getAlert(),
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (!error.response) {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
          return false
      }
        if (error.response.status === 422) {
          let _returnError = []
          Object.keys(error.response.data.errors).forEach((key) => {
            _returnError.push(error.response.data.errors[key][0]);
          });
          let _showing = true;
          this.setState({ returnError: _returnError, showing: _showing });
          const errorList2 = this.state.returnError.map((char, i) => {
            return (
              <li key={i} style={{ listStyleType: 'none' }}>
                {char}
              </li>
            );
          });
          NotificationManager.error(errorList2, 'Validasi Error!');
        } else if (error.response.status === 404) {
          NotificationManager.error(error.response.data.message, 'Validasi Error!');
        } else {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        }
      });
  }

  render() {
    return (
      <>
        <div
          className={`sign_up_modal modal fade ${this.props.isOpen ? 'show' : ''}`}
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
          style={{ display: `${this.props.isOpen ? 'block' : 'none'}` }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document" ref={node => this.node = node}>
            <div className="modal-content">
              <div className="modal-header pointer" onClick={this.dismissModalSignInPopup}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"

                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <ul className="sign_up_tab nav">
                <li className="nav-item active fullWidthCss ">
                  <p className="nav-link pointer active">Reset untuk Lupa Password</p>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active">
                  <div className="login_form">
                    <form action="#">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Alamat Email"
                          defaultValue={this.state.email_address}
                          onChange={(e) => this.setState({ email_address: e.target.value })}
                        />
                      </div>
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn  btn-thm2"
                          onClick={(e) => this.recoverPassword(e)}
                        >
                          Reset Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.alert}
        <NotificationContainer />
        {this.state.loading ? <LoadingSpinner text={this.state.loading_text} /> : ''}
      </>
    );
  }
}

export default ForgetPassword;
