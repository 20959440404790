import React, { useEffect, useState } from 'react'
import { duration } from 'moment'
import { useDispatch, useSelector } from 'react-redux'

const VideoProgressBar = ({ progress, videoDuration, handleClick, ableToForward }) => {
  const [timeArr, setTimeArr] = useState([])
  const [gap, setGap] = useState(0)
  const [durationRounded, setDurationRounded] = useState(null)
  const [percent, setPercent] = useState(0)
  const lesson_detail_number = useSelector(state => state.lesson_detail_number)
  const lecture_number = useSelector(state => state.lecture_number)
  const parseProgress = () => {
    let temp = []
    let i = 30
    let j = 0
    while (i < videoDuration) {
      if (progress.lessons_front[lesson_detail_number].lectures[lecture_number].watchseconds[j]) {
        if (progress.lessons_front[lesson_detail_number].lectures[lecture_number].watchseconds[j].watch_second == i) {
          temp.push(1)
          j++
        } else {
          if (j != 0) {
            if (progress.lessons_front[lesson_detail_number].lectures[lecture_number].watchseconds[j].watch_second == progress.lessons_front[lesson_detail_number].lectures[lecture_number].watchseconds[j - 1].watch_second) {
              j++
              i -= 30
            } else {
              if (progress.is_finalized) temp.push(1)
              else temp.push(0)
            }
          } else {
            if (progress.is_finalized) temp.push(1)
            else temp.push(0)
          }
        }
      } else {
        if (progress.is_finalized) temp.push(1)
        else temp.push(0)
      }

      i += 30
    }
    return temp
  }

  useEffect(() => {
    let isEnough = () => {
      if (progress.is_finalized) {
        return true
      }

      if (progress.lessons_front[lesson_detail_number].lectures[lecture_number].watchseconds[0]) {
        let length = progress.lessons_front[lesson_detail_number].lectures[lecture_number].watchseconds.length
        if (progress.lessons_front[lesson_detail_number].lectures[lecture_number].watchseconds[length - 1].watch_second / videoDuration > 0.75) {
          return true
        }
      }
      return false
    }
    let temp = parseProgress()
    setTimeArr([...temp])
    let multiplier = Math.floor(videoDuration / 30)
    setDurationRounded(multiplier * 30)
    let gap = ((videoDuration - 30 * multiplier) / videoDuration) / multiplier
    let totalGap = isEnough() ? 30 / videoDuration + gap : 30 / videoDuration
    setGap(`${totalGap * 100}%`)

    let totalPercent = 0
    temp.forEach(el => {
      if (el) {
        totalPercent += isEnough() ? totalGap : 30 / videoDuration
      }
    })

    setPercent(totalPercent)

  }, [progress, lesson_detail_number, lecture_number])
  return (
    <>
      <div className="vid-progress-container">
        <div className="progress progress-vid">
          {timeArr.map((el, i) => (
            <div className={el ? 'progress-bar-vid' : 'progress-bar-empty-vid'} role="progressbar" style={{ width: gap, marginLeft: '0' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" onClick={() => handleClick(30 * (i + 1) - (Math.floor(Math.random() * 17) + 3))} />
          ))}
        </div>
      </div>
      <div className="d-flex">
        <p style={{ fontSize: '12px', marginLeft: '4px', marginBottom: 0, marginRight: '12px', lineHeight: '12px', fontWeight: '500' }}><svg width="11" height="5" viewBox="0 0 11 5" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '4px' }}>
          <rect width="11" height="5" rx="2.5" transform="matrix(1 0 0 -1 0 5)" fill="#219653" />
        </svg>
          {`Sudah ditonton: ${(percent * 100).toFixed(0)}%`}</p>
        <p style={{ fontSize: '12px', marginLeft: '4px', marginBottom: 0, lineHeight: '12px', fontWeight: '500' }}>
          <svg width="11" height="5" viewBox="0 0 11 5" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '4px' }}>
            <rect width="11" height="5" rx="2.5" transform="matrix(1 0 0 -1 0 5)" fill="#E00814" />
          </svg>
          {`Belum ditonton: ${(((1 - percent) < 0 ? 0 : (1 - percent)) * 100).toFixed(0)}%`}</p>
      </div>

      <div className="info-kelas-self-vprogress d-flex flex-column">
        <p>Hi, kalian juga bisa diskusi dengan teman-teman lain yang mengambil kelas ini lho!</p>
        <h3 onClick={() => window.scroll({ top: 1200, left: 0, behavior: 'smooth' })}>Scroll ke bawah ya!</h3>

      </div>
      <div className="info-kelas-self-vprogress d-flex flex-column">
        <h2>Info!</h2>
        <p>Tonton 100% dari durasi video untuk mengakses materi selanjutnya</p>
      </div>

    </>
  )
}

export default VideoProgressBar
