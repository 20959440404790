import React, { Component } from 'react';
import { Redirect, Route, Link, BrowserRouter as Router } from 'react-router-dom'
import { basicAuthCheck } from '../Utils/Utils'
class ProtectedRoute extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: true,
      isLoggedIn: false
    };


  }

  componentDidMount() {
    // Your axios call here
    basicAuthCheck().then((res) => {
      if (res === 1) {
        this.setState(() => ({ isLoading: false, isLoggedIn: true }));
      } else {
        this.setState(() => ({ isLoading: false, isLoggedIn: false }));
      }
    })
  }

  render() {
    return this.state.isLoading ? null :
      this.state.isLoggedIn ?
        <Route path={this.props.path} component={this.props.component} exact={this.props.exact} /> :
        <Redirect to={{ pathname: '/', state: { from: this.props.location } }} />
  }

}


export default ProtectedRoute;
