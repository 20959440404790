import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Loading from '../Loading';

const MyUserProfile = () => {
  const userProfile = useSelector((state) => state.profile_data);

  if (!userProfile) return <Loading />;

  return (
    <>
      <div className="user-profile__container d-flex justify-content-center">
        <div className="d-flex-desktop-profile">
          <img
            src={
              userProfile.photo
                ? userProfile.photo.url
                  ? userProfile.photo.url
                  : `https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png`
                : `https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png`
            }
            alt="user-profile-pic"
            className="user-profile__picture mr-4"
          ></img>
          <div className="d-flex-desktop-profile flex-column">
            <h1 className="user-profile__name">{userProfile.fullname}</h1>
            <h1 className="user-profile__email">{userProfile.email}</h1>
            <Link to={`/student/editprofile`}>
              <div className="btn btn-white user-profile__btn">Edit Profil</div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyUserProfile;
