import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Link } from 'react-router-dom';
import { Instagram, Facebook, Twitter, Youtube, Whatsapp, List, File, LinkedIn } from '../../static';

const Person = ({ instructor }) => {
  const socialLinks = [
    {
      href: instructor ? (instructor.twitter_url ? instructor.twitter_url : null) : null,
      title: <Twitter size={24} />,
      id: '69'
    },
    {
      href: instructor ? (instructor.facebook_url ? instructor.facebook_url : null) : null,
      title: <Facebook size={24} />,
      id: '68'
    },
    {
      href: instructor ? (instructor.instagram_url ? instructor.instagram_url : null) : null,
      title: <Instagram size={24} />,
      id: '65'
    },
    {
      href: instructor ? (instructor.linkedin_url ? instructor.linkedin_url : null) : null,
      title: <LinkedIn size={24} />,
      id: '66'
    }
  ]
  return (
    (instructor !== null)
      ?
      <section className="person card">
        <h2 className="person__main-title mb-3">Instruktur</h2>
        <div className="person__wrap d-flex ">
          <div className="person__image" >
            <a href={`/instructors/${instructor.id}/${instructor.fullname}`}>
              <img src={
                (instructor.photo !== null) ?
                  instructor.photo.url : ''
              } alt={instructor.fullname} />
            </a>
          </div>
          <div className="person__info pt-2 d-flex flex-column">
            <a className="person__title mb-3" href={`/instructors/${instructor.id}/${instructor.fullname}`}>{instructor.fullname}</a>
            <p className="person__desc">{ReactHtmlParser(instructor.description)}</p>
            <div className="d-flex mt-3">
              {socialLinks.map(({ href, title, id }) => (
                href !== null ?
                  <Link href={href}>
                    <a className="social__link mr-4" onClick={(e) => {
                      e.preventDefault()
                      window.open(href, "_blank")
                    }}>{title}</a>
                  </Link> : ''
              ))}
            </div>
          </div>
        </div>
      </section>
      :
      ''

  )
}

export default Person;
