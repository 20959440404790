import moment from 'moment';
import React, { useState } from 'react';

//Components
import PilihJadwalModal from '../PilihJadwalModal';

//Sub-components
import CourseImage from './subcomponent/CourseImage';
import ScheduleDate from './subcomponent/ScheduleDate';
import SchedulePickButton from './subcomponent/SchedulePickButton';
import TopData from './subcomponent/TopData';
import { WALink, ZoomLink } from './subcomponent/ZoomWAButton';

const Live = ({ course }) => {
  const [modalOpen, SetModalOpen] = useState(false);
  const dateParse = (date = new Date()) => moment(date).utcOffset(7).format();
  const IkutiQuizBtn = () => {
    // if(course.course_schedule_register.is_attend) {
    if (course.course_schedule_register.schedule) {
      if (dateParse(course.course_schedule_register.schedule.end_date) < dateParse()) {
        if (course.quiz) {
          return (
            <div
              className="btn btn-pink btn-pilih-jadwal ml-auto"
              onClick={() => (window.location.href = `/course/login/${course.id}/${course.slug}`)}
            >
              Ambil Exam
            </div>
          );
        }
      } else {
        if (course.course_zip) {
          return (
            <div
              className="btn btn-white ml-auto btn-download-course-list"
              onClick={() => window.open(course.course_zip.zip_url, '_blank')}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.125 8.36671C15.5583 5.49171 13.0333 3.33337 10 3.33337C7.59167 3.33337 5.5 4.70004 4.45833 6.70004C1.95 6.96671 0 9.09171 0 11.6667C0 14.425 2.24167 16.6667 5 16.6667H15.8333C18.1333 16.6667 20 14.8 20 12.5C20 10.3 18.2917 8.51671 16.125 8.36671ZM14.1667 10.8334L10 15L5.83333 10.8334H8.33333V7.50004H11.6667V10.8334H14.1667Z"
                  fill="#FF0F51"
                />
              </svg>
              Download Resource
            </div>
          );
        }
      }
    }
    return '';
  };

  const ScheduleUnselected = () => {
    return (
      <div className="mt-3 d-flex align-items-center w-100">
        <p className="course-card__bold-text mr-auto">Pilih Jadwal Terlebih Dahulu.</p>
        <div className="navbar-desc ml-auto ">
          <SchedulePickButton course={course} SetModalOpen={SetModalOpen} />
        </div>
        <div className="navbar-mob ml-auto ">
          <SchedulePickButton isMobile course={course} SetModalOpen={SetModalOpen} />
        </div>
      </div>
    );
  };

  const ScheduleSelected = () => {
    if (!course.course_schedule_register.schedule) {
      return null;
    } else {
      const { zoom_link, whatsapp_link, session_no } = course.course_schedule_register.schedule;
      return (
        <div className="d-flex flex-column w-100">
          <div className="d-flex">
            <p className="course-card__bold-text mr-2">Dilaksanakan Pada Tanggal:</p>
            {session_no && session_no > 1 ? (
              <div className="mr-auto">
                <p className="course-card__bold-text" style={{ fontWeight: '300' }}>
                  {`${moment(course.course_schedule_register.schedule.start_date).format(
                    'DD MMMM YYYY HH:mm [WIB]',
                  )} & ${moment(course.course_schedule_register.schedule.end_date).format(
                    'DD MMMM YYYY HH:mm [WIB]',
                  )}`}
                </p>
              </div>
            ) : (
              <div className="mr-auto">
                <ScheduleDate course={course} />
              </div>
            )}
          </div>
          <div className="d-flex ml-auto">
            {whatsapp_link && <WALink link={whatsapp_link} />}
            {zoom_link && <ZoomLink link={zoom_link} />}
          </div>
        </div>
      );
    }
  };
  // console.log(course)
  return (
    <>
      <div className="course-card">
        <div className="row">
          <div className="col-xl-4 col-md-4 col-sm-3 col-5">
            <CourseImage course={course} />
          </div>
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-9 col-7 course-card__content">
            <TopData course={course} />

            {/* Desktop View */}

            {course.course_schedule_register ? (
              <div className="d-flex justify-content-end">
                <IkutiQuizBtn />
              </div>
            ) : (
              <div className="navbar-desc">
                <ScheduleUnselected />
              </div>
            )}
          </div>

          {/* Mobile View */}
          <div className="course-card__content navbar-mob col-12">
            {!course.course_schedule_register && <ScheduleUnselected />}
          </div>

          <div className="course-card__content col-12">
            {course.course_schedule_register ? (
              <div className="course-card__content__bottom d-flex">
                <ScheduleSelected />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      {modalOpen ? (
        <PilihJadwalModal
          modalOpen={modalOpen}
          closeModal={(e) => SetModalOpen(false)}
          course={course}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default Live;
