import styled from 'styled-components';
import {
    FooterBanner,
    MobileBanner,
    MobileFooter,
    Dropdown
} from '../../../image/InternPage';

export const Banner = styled.div`
    background: #D1D1D1;
    position: relative;
    height: 60vw;
    width: auto;
    max-height: 800px;
    font-family: Poppins !important;
    img {
        position: absolute;
        left: 60%;
        width: 32%;
        max-width: 432px;
    }
    p {
        font-size: 24px;
        color: #20282E;
        width: 60%;
    }
    h1 {
        font-size: 48px;
        font-weight: 700;
        color: #20282E;
        font-family: Poppins !important;
        width: 60%;
        max-width: 650px;
    }
    .text-container {
        width: 84%;
        margin: auto;
        max-width: 1308px;
        padding-top: 15vw;
    }
    .custom-btn {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        background: #D70C24;
        color: #FFFFFF;
        margin-top: 24px;
        padding: 8px 48px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        border: 0;
        cursor: pointer;
        outline: none;
    }
    .custom-btn:hover {
        filter: brightness(90%);
    }

    @media (max-width: 992px) {
        background-image: url(${MobileBanner});
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    @media (min-width: 1400px) {
        .text-container {
            padding-top: 220px;
        }
    }
`;

export const InternList = styled.div`
    background: #FFFFFF;
    padding: 32px;
    .header {
        background: #FFFFFF;
        text-align: center;
        width: 80%;
        margin: 0 auto 32px auto;
        h1 {
            font-family: Poppins !important;
            font-size: 24px;
            font-weight: 700;
            color: #20282E;
        }
        p {
            font-family: Poppins !important;
            font-size: 16px;
            color: #20282E;
            margin: 0;
        }
        .container {
            display: flex;
            align-items: center;
            justify-content: center;
            #filter {
                background: #FFFFFF;
                margin-left: 12px;
                padding: 4px 20px;
                color: #20282E;
                font-size: 16px;
                width: 180px;
                border: 1px solid #FFCAD0;
                box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
                border-radius: 100px;
                font-family: Poppins !important;
            }
            .item {
                background-color: unset;
                cursor: pointer;
                color: #20282E;
                font-size: 16px;
                padding: 0 0 0 24px;
            }
            .dropdown-menu {
                width: 180px;
                margin-top: 8px;
                padding: 0;
                border: 1px solid #FFCAD0;
                box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
                border-radius: 20px;
            }
            .dropdown-divider {
                margin: 0;
            }
            .dropdown-toggle::after {
                background-image: url(${Dropdown});
                background-repeat: no-repeat;
                width: 13px;
                height: 8px;
                border: 0;
                margin: 0 0 1px 7px;
            }
        }
        @media (max-width: 349.5px) {
            margin-bottom: 48px;
            .container {
                padding: 0;
            }
        }
    }
    .list {
        width: 85%;
        margin: auto;
        text-align: center;
        .intern {
            width: 100%;
            max-width: 280px;
            margin: 0 auto 32px auto;
            cursor: pointer;
            text-align: left;
            font-family: Poppins !important;

            .avatar {
                width: 100%;
                height: 100%;
                background: #C4C4C4;
                border-radius: 20px;
            }
            .nama {
                margin-top: 8px;
                font-weight: 600;
                font-size: 18px;
                color: #20282E;
            }
            .role {
                font-size: 16px;
                color: #5B7080;
            }
        }
    }

    @media (max-width: 992px) {
        padding: 32px 0;
        .header {
            width: 100%;
            h1 {
                font-size: 16px;
            }
            p {
                font-size: 12px;
            }
            .container {
                display: block;
                #filter {
                    width: 160px;
                    font-size: 12px;
                    margin-bottom: 16px;
                }
                .dropdown-menu {
                    width: 160px;
                }
                .item {
                    font-size: 12px;
                }
                .dropdown-toggle::after {
                    margin: 0 0 0 7px;
                }
            }
        }
        .list {
            width: 90%;
            max-width: 720px;
            height: auto;
            margin: auto;
            .intern {
                text-align: left;
                .nama {
                    font-size: 16px;
                }
                .role {
                    font-size: 12px;
                }
            }
        }
    }
    @media (max-width: 600px) {
        .list {
            width: 90%;
            max-width: 360px;
            height: auto;
            margin: auto;
            .intern {
                text-align: left;
                .nama {
                    font-size: 16px;
                }
                .role {
                    font-size: 12px;
                }
            }
        }
    }
    @media (min-width: 1400px) {
        .header {
            max-width: 1400px;
        }
        .list {
            max-width: 1400px;
        }
    }
`;

export const BottomBanner = styled.div`
    background-image: url(${FooterBanner});
    background-repeat: no-repeat;
    div {
        font-family: Poppins !important;
        width: 620px;
        margin: auto;
        padding: 48px;
        text-align: center;
        h1 {
            font-family: Poppins !important;
            font-size: 24px;
            font-weight: 600;
            color: #FFFFFF;
        }
        p {
            font-size: 18px;
            color: #FFFFFF;
        }
        .custom-btn {
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            background: #D70C24;
            color: #FFFFFF;
            margin-top: 24px;
            padding: 8px 48px;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            border: 0;
            cursor: pointer;
            outline: none;
        }
        .custom-btn:hover {
            filter: brightness(90%);
        }
    }

    @media (max-width: 992px) {
        background-image: url(${MobileFooter});
        background-size: cover;
        div {
            width: 90%;
            padding: 48px 0;
            h1 {
                font-size: 16px;
                max-width: 360px;
                margin: 0 auto 16px auto;
            }
            p {
                font-size: 12px;
            }
            .custom-btn {
                font-size: 12px;
                width: 100%;
                max-width: 360px;
            }
        }
    }

    @media (min-width: 1400px) {
        background-size: 100% auto;
    }
`;