import React from 'react';
class LandingVideoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  toggleMethod = (e) => {
    e.preventDefault();
    this.props.toggleVideoSectionPopup();
  }
  render() {
    return (
      <>
        <section id="maximage1" className="maximage-home home-four p0">
          <div className="container-fluid p0">
            <div id="max-image-custom" className="mc-cycle">
              {/* <div className="first-item">
                          <img src="images/home/header-home.jpg" alt="header-home.jpg" />
                      </div>
                      <div className="second-item">
                          <img src="images/home/4.jpg" alt="4.jpg" />
                      </div>
                      <div className="fourth-item">
                          <img src="images/home/5.jpg" alt="5.jpg" />
                      </div> */}

              <div className="mc-image mc-image-partone" title="header-home.jpg" data-href="" />
            </div>
            <div className="maxslider-content">
              <div className="lbox-caption">
                <div className="lbox-details">
                  <div className="maxtext">
                    <h1>Belajar Bareng dengan MyEduSolve X</h1>
                    <p>untuk Masa Depan Kamu! </p>
                    <div className="search_box_home4">
                      <div className="ht_search_widget">
                        <div className="header_search_widget">
                          <div className="col-lg-6 offset-lg-3">
                            <div className="courses_all_btn text-center">
                              <button className="btn btn-voucher-redeem" onClick={(e) => this.toggleMethod(e)}>
                                Redeem Voucher Prakerja
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="top_courses_iconbox">
          <div className="container">
            <div className="row row_home4">
              <div className="col-sm-6 col-lg-3">
                <div className="home_icon_box home4">
                  <div className="icon">
                    <span className="flaticon-pencil" />
                  </div>
                  <p>
                    Praktis  <br />
                    Belajar dari rumah
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="home_icon_box home4">
                  <div className="icon">
                    <span className="flaticon-student-1" />
                  </div>
                  <p>
                    Professional <br />
                  Belajar dengan spesialis di bidangnya
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="home_icon_box home4">
                  <div className="icon">
                    <span className="flaticon-medal" />
                  </div>
                  <p>
                    Sertifikat <br />
                    Dapat sertifikat
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="home_icon_box home4">
                  <div className="icon">
                    <span className="flaticon-elearning" />
                  </div>
                  <p>
                    Efektif <br />
                    Langsung praktek
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LandingVideoSection;
