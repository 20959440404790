import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useSelector } from 'react-redux';
var fileDownload = require('js-file-download');
const ExamFinishPretest = ({ result }) => {
    const [loading, setLoading] = useState(false)
    const course_detail = useSelector(state => state.course_detail)
    
    return (
        // (result !== null)
        //     ?
            <div className="exam-finish d-flex align-items-center justify-content-center"
                style={{ backgroundImage:'url("/images/courses/examFinish.png")'}}
            >
                <div className="exam-finish__modal d-flex flex-column align-items-center">
                    <h1 className="exam-finish__title mb-1" style={{fontWeight: '700'}}>Selamat!</h1>
                    <p style={{fontSize: '14px', marginBottom: '4px', fontWeight: '500', textAlign: 'center', marginBottom: '32px'}}>Kamu telah menyeleasaikan course ini, dibawah ini adalah perbandingan skor sebelum dengan skor sesudah kamu menyelesaikan course ini.</p>
                    <div className="exam-finish__score_out d-flex">
                        <div className="exam-finish__score_in d-flex flex-column align-items-center vertical-line">
                            <p className="exam-finish__score__detail">
                                Sebelum
                        </p>
                            <p className="exam-finish__score__benar">
                                {course_detail.pretest.is_prettest_given.score} 
                            </p>
                        </div>
                        <div className="exam-finish__score_in d-flex flex-column align-items-center">
                            <p className="exam-finish__score__detail">
                                Sesudah
                        </p>
                            <p className="exam-finish__score__nilai">
                                {course_detail.quiz.is_exam_given.score}
                            </p>
                        </div>
                    </div>
                </div>
                <NotificationContainer />
                {loading ? <LoadingSpinner /> : ''}
            </div>
            // :
            // ''
    )
}

export default ExamFinishPretest;