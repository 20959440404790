import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import NumberFormat from 'react-number-format';
import moment from 'moment'
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import { Carousel } from 'antd';
import ReactGA from 'react-ga';

const EventCard = ({ data }) => {
    return (
        <a href={`/webinar/${data.id}`}  className="w-100" onFocus={() => {
            ReactGA.event({
                category: 'Events',
                action: `Click-Home`,
                label: data.title,
              })
        }}>
            <div className="event-card card pointer">
                <div className="event-card__bg d-flex"
                    style={{
                        background: `white url("${(data.image !== null) ? data.image.url : '../../static/container.png'}") no-repeat`,
                        backgroundSize: '100% 274px'
                    }}>
                    <div className="d-flex mt-auto w-100">
                        <div className="event-card__schedule d-flex ml-3 flex-column  align-items-center">
                            <p className="event-card__schedule-date mt-2">{moment(data.start_date).format('DD')}</p>
                            <p className="event-card__schedule-month">{moment(data.start_date).format('MMM')}</p>
                        </div>

                        {data.price !== "0.00" ?
                            <div className="d-flex mt-auto ml-auto mr-4">
                                <p className="event-card__price mr-2">Rp</p><NumberFormat
                                    value={+data.price}
                                    displayType={'text'}
                                    thousandSeparator="."
                                    isNumericStrin={true}
                                    decimalSeparator={""}
                                    thousandsGroupStyle="thousand"
                                    className="event-card__price"
                                /></div> :
                            <p className="event-card__price mt-auto ml-auto mr-4">Gratis</p>
                        }
                    </div>
                </div>
                <div className="event-card__content">
                    <p className="event-card__content-main text-color-black">{data.title}</p>
                    <div className="d-flex mt-4 align-content-center mb-1">
                        <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 0C11.31 0 14 2.66 14 5.95C14 10.41 8 17 8 17C8 17 2 10.41 2 5.95C2 2.66 4.69 0 8 0ZM8 4C7.46957 4 6.96086 4.21071 6.58579 4.58579C6.21071 4.96086 6 5.46957 6 6C6 6.53043 6.21071 7.03914 6.58579 7.41421C6.96086 7.78929 7.46957 8 8 8C8.53043 8 9.03914 7.78929 9.41421 7.41421C9.78929 7.03914 10 6.53043 10 6C10 5.46957 9.78929 4.96086 9.41421 4.58579C9.03914 4.21071 8.53043 4 8 4ZM16 17C16 19.21 12.42 21 8 21C3.58 21 0 19.21 0 17C0 15.71 1.22 14.56 3.11 13.83L3.75 14.74C2.67 15.19 2 15.81 2 16.5C2 17.88 4.69 19 8 19C11.31 19 14 17.88 14 16.5C14 15.81 13.33 15.19 12.25 14.74L12.89 13.83C14.78 14.56 16 15.71 16 17Z" fill="black" />
                        </svg>
                        <p className="event-card__content-main text-color-black mt-0 ml-3 mr-2">Event:</p>
                        <p className="event-card__content-main text-color-black mt-0" style={{ fontWeight: '300', textTransform: 'capitalize' }}>{data.location}</p>
                    </div>
                </div>
            </div>
        </a>
    )
}

class Slider extends React.Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();
    }
    next() {
        this.carousel.next();
    }
    previous() {
        this.carousel.prev();
    }

    render() {
        const props = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1124,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 845,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 595,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className="slider" style={this.props.noArrow ? {paddingRight: '0', paddingLeft: '0'} : {}}>
                <div className="pos-relative">
                    <Carousel ref={node => (this.carousel = node)} {...props}>
                        {this.props.courses_list.map((data) => (
                            <EventCard data={data} key={data.id} />
                        ))}
                    </Carousel>
                </div>
            </div>
        );
    }
}

const UpcomingEvent = () => {
    const dispatch = useDispatch()
    const [upcomingEvent, setUpcomingEvenet] = useState(null)
    const [returnError, setReturnError] = useState([])

    useEffect(() => {
        const fetchUpcomingEvents = async () => {
            dispatch({ type: 'TOGGLE_LOADING_TRUE' })
            axios.get(`${window.$API_URL}api/event/upcoming/ `, {},
                {
                    headers: { 'Content-Type': 'application/json', 'x-access-token': '' },
                },
            ).then((success) => {
                dispatch({ type: 'TOGGLE_LOADING_FALSE' })
                // console.log(success.data.data)
                if (success.data.success) {
                    setUpcomingEvenet(success.data.data)
                }
            })
                .catch((error) => {
                    dispatch({ type: 'TOGGLE_LOADING_FALSE' })
                    if (!error.response) {
                        NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
                        return false
                    }
                    if (error.response.status === 422) {
                        setReturnError([]);
                        let errorTemp = []
                        Object.keys(error.response.data.errors).forEach((key) => {
                            errorTemp.push(error.response.data.errors[key][0]);
                        });
                        setReturnError(errorTemp)
                        const errorList2 = returnError.map((char, i) => {
                            return (
                                <li key={i} style={{ listStyleType: 'none' }}>
                                    {char}
                                </li>
                            );
                        });
                        NotificationManager.error(errorList2, 'Validasi Error!');
                    } else {
                        NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
                    }
                });
        }
        fetchUpcomingEvents()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const isEmpty = () => {
        if(upcomingEvent !== null){
            if(upcomingEvent[0]){
                return true
            }
        }

        return false
    }

    return (
        <div className="navbar-desc">
            {isEmpty() ? (
                upcomingEvent[0] ? (
                    <div className="kelas-populer " style={{ marginTop: '80px' }}>
                        <h1 className="kelas-populer__title">Event Yang Akan Datang</h1>
                        <h1 className="kelas-populer__desc">Berikut adalah beberapa opsi untuk kamu yang lebih suka kegiatan seru!</h1>
                        <div className="navbar-desc">
                            <div className="row main-row">
                                {upcomingEvent !== null ?
                                    upcomingEvent.map(el => (
                                        <div className="col-md-3 col-sm-12 d-flex justify-content-center mb-4">
                                            <EventCard data={el} key={el.id} />
                                        </div>
                                    ))
                                    : ''}
                            </div>
                        </div>
                        <div className="navbar-mob">
                            {upcomingEvent !== null ?
                                <Slider courses_list={upcomingEvent} sliderOnly noArrow/>
                            : ''}
                        </div>
                    </div>
                ) : ''
            ) : ''}
        </div>
    )
}

export default UpcomingEvent