import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import Avatar from 'react-avatar';

const ReviewRow = ({ rating, last }) => {
  const [totalRatingStars, setTotalRatingStars] = useState([
    <EmptyStar />,
    <EmptyStar />,
    <EmptyStar />,
    <EmptyStar />,
    <EmptyStar />,
  ]);

  const Cons = ({ con }) => <p>{con}</p>;

  const timeDiff = (createdAt) => {
    const weekDiff = Math.abs(moment(createdAt).diff(moment(), 'weeks'));
    const dayDiff = Math.abs(moment(createdAt).diff(moment(), 'days'));
    const hourDiff = Math.abs(moment(createdAt).diff(moment(), 'hours'));
    const minuteDiff = Math.abs(moment(createdAt).diff(moment(), 'minutes'));

    if (weekDiff) return `${weekDiff} Minggu Lalu`;
    if (dayDiff) return `${dayDiff} Hari Lalu`;
    if (hourDiff) return `${hourDiff} Jam Lalu`;
    if (minuteDiff) return `${minuteDiff} Menit Lalu`;
  };

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < rating.rating; i++) {
      temp.push(<FullStar />);
    }
    setTotalRatingStars(temp);
  }, [rating]);

  return (
    <>
      <div className="course-rating-detail-new__review-row">
        <div>
          {/* <div><img src={`/images/courses/rating_profile.png`} /></div> */}
          <div>
            <Avatar name={rating.user.fullname} size="46" textSizeRatio={3} round />
          </div>

          <div>
            <h2 className="course-rating-detail-new__username">{rating.user.fullname}</h2>
            <div className="course-rating-detail-new__stars-icon">
              {totalRatingStars.map((el) => el)}
            </div>
          </div>
          <div>
            <h3>{timeDiff(rating.created_at)}</h3>
          </div>
        </div>
        <p>{rating.review_content}</p>
        <div>
          {rating.is_like_materi ||
          rating.is_like_mentor ||
          rating.is_like_video ||
          rating.is_like_resource ? (
            <p>Hal Yang Disukai</p>
          ) : (
            ''
          )}
          <div>
            {rating.is_like_materi ? <Cons con="Materi" /> : ''}
            {rating.is_like_mentor ? <Cons con="Penyampaian Materi" /> : ''}
            {rating.is_like_video ? <Cons con="Video" /> : ''}
            {rating.is_like_resource ? <Cons con="Resource" /> : ''}
          </div>
        </div>
      </div>
    </>
  );
};

const FullStar = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.49996 12.2329L12.8775 14.875L11.7158 9.89541L15.5833 6.54499L10.4904 6.11291L8.49996 1.41666L6.50954 6.11291L1.41663 6.54499L5.28413 9.89541L4.12246 14.875L8.49996 12.2329Z"
      fill="#FFC632"
    />
  </svg>
);

const EmptyStar = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5833 6.54499L10.4904 6.10582L8.49996 1.41666L6.50954 6.11291L1.41663 6.54499L5.28413 9.89541L4.12246 14.875L8.49996 12.2329L12.8775 14.875L11.7229 9.89541L15.5833 6.54499ZM8.49996 10.9083L5.83663 12.5162L6.54496 9.48457L4.19329 7.44457L7.29579 7.17541L8.49996 4.32082L9.71121 7.18249L12.8137 7.45166L10.462 9.49166L11.1704 12.5233L8.49996 10.9083Z"
      fill="#FFC632"
    />
  </svg>
);

const HalfStar = () => (
  <svg width="17" height="17" xmlns="http://www.w3.org/2000/svg">
    <path
      id="svg_1"
      fill="#FFC632"
      d="m15.5833,6.54499l-5.0929,-0.43917l-1.99044,-4.68916l-1.99042,4.69625l-5.09291,0.43208l3.8675,3.35042l-1.16167,4.97959l4.3775,-2.6421l4.37754,2.6421l-1.1546,-4.97959l3.8604,-3.35042zm-7.08334,4.36331l-2.66333,1.6079l0.70833,-3.03163l-2.35167,-2.04l3.1025,-0.26916l1.20417,-2.85459l1.21125,2.86167l3.10249,0.26917l-2.3517,2.04l0.7084,3.03164l-2.67044,-1.615z"
    />
    <path
      id="svg_9"
      d="m1.64207,6.47814l4.91531,-0.46721l1.96721,-4.48087l-0.05464,10.65574l-4.37159,2.62295l1.14754,-4.97268l-3.60383,-3.35793z"
      fill-opacity="null"
      stroke-opacity="null"
      stroke-width="null"
      stroke="null"
      fill="#FFC632"
    />
  </svg>
);

const CourseRating = () => {
  const isDesktop = useMediaQuery({ query: '(min-device-width: 992px)' });
  const dispatch = useDispatch();
  const course_detail = useSelector((state) => state.course_detail);
  const [totalRating, setTotalRating] = useState(0);
  const [totalRatingStars, setTotalRatingStars] = useState([
    <EmptyStar />,
    <EmptyStar />,
    <EmptyStar />,
    <EmptyStar />,
    <EmptyStar />,
  ]);
  const [totalRatingCount, setTotalRatingCount] = useState(0);
  const [singularRatingCount, setSingularRatingCount] = useState({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 });
  const [ratings, setRatings] = useState([]);
  const [pageSelected, setPageSelected] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const formatter = (num) => {
    if (Math.abs(num) > 999999) {
      return Math.abs(num) > 999999
        ? Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + 'jt'
        : Math.sign(num) * Math.abs(num);
    } else if (Math.abs(num) > 999) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
        : Math.sign(num) * Math.abs(num);
    }
    return num;
  };

  const ReviewLegend = ({ rating, total }) => (
    <div className="d-flex w-100 course-rating-detail-new__legend">
      {isDesktop ? <FullStar /> : ''}
      <p className="course-rating-detail-new__legend__small">{rating}</p>
      <div className="course-rating-detail-new__legend__bar">
        <div
          className="course-rating-detail-new__legend__bar__active"
          style={{ width: `${(total * 100) / (totalRatingCount ? totalRatingCount : 1)}%` }}
        />
      </div>
      <p className="course-rating-detail-new__legend__small-right">{formatter(total)}</p>
    </div>
  );

  const fetchReviews = () => {
    dispatch({ type: 'TOGGLE_LOADING_TRUE' });
    axios
      .get(`${window.$API_URL}api/course/${course_detail.id}/rating?page=${pageSelected + 1}`, {})
      .then((success) => {
        if (success.data.success) {
          if (success.data.data.rating_summary) {
            setTotalRating((+success.data.data.rating_summary.average_rating).toFixed(1));
            let decimalNumber = `${(+success.data.data.rating_summary.average_rating).toFixed(
              1,
            )}`[2];
            let ratingNumber = Math.floor(+success.data.data.rating_summary.average_rating) + 1;
            let halfNumber = 0;
            if (decimalNumber <= 9) {
              ratingNumber = ratingNumber - 1;
              if (decimalNumber > 2) {
                halfNumber++;
              }
            }
            let temp = [];
            for (let i = 0; i < ratingNumber; i++) {
              temp.push(<FullStar />);
            }
            if (halfNumber) temp.push(<HalfStar />);
            for (let i = 0; i < 5 - ratingNumber - halfNumber; i++) {
              temp.push(<EmptyStar />);
            }
            setTotalRatingStars(temp);

            setTotalRatingCount(success.data.data.ratings.total);

            let temp2 = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
            success.data.data.count_ratings.forEach((el) => {
              temp2[el.rating] = el.total_rating;
            });
            setSingularRatingCount(temp2);
            setRatings(success.data.data.ratings.data);
            setTotalPage(success.data.data.ratings.last_page);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => dispatch({ type: 'TOGGLE_LOADING_FALSE' }));
  };

  useEffect(() => {
    if (course_detail) fetchReviews();
  }, [course_detail, pageSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!ratings[0])
    return (
      <section className="course-rating-detail-new">
        <h2 className="course-rating-detail-new__main-title mb-3">Rating dan Ulasan</h2>
        <p>Belum ada Rating.</p>
      </section>
    );

  return (
    <section className="course-rating-detail-new">
      <h2 className="course-rating-detail-new__main-title mb-3">Rating dan Ulasan</h2>
      <div className="course-rating-detail-new__wrap d-flex">
        {/* overview rating */}
        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
          <div className="course-rating-detail-new__summary ">
            <div className="d-flex align-items-end mb-1">
              <p className="course-rating-detail-new__summary__big">
                {totalRating ? totalRating : '-'}
              </p>
              <p className="course-rating-detail-new__summary__small">/5</p>
            </div>
            <div className="course-rating-detail-new__summary__stars mb-1">
              {totalRatingStars.map((el) => el)}
            </div>
            <p className="course-rating-detail-new__summary__count">{`${totalRatingCount} Ulasan`}</p>
          </div>
          <div className="d-flex flex-column justify-content-between course-rating-detail-new__legend-wrap">
            {isDesktop ? <p>Rating</p> : ''}
            <ReviewLegend rating="5" total={singularRatingCount['5']} />
            <ReviewLegend rating="4" total={singularRatingCount['4']} />
            <ReviewLegend rating="3" total={singularRatingCount['3']} />
            <ReviewLegend rating="2" total={singularRatingCount['2']} />
            <ReviewLegend rating="1" total={singularRatingCount['1']} />
          </div>
        </div>

        {/* rating card detail */}
        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 ">
          {ratings[0]
            ? ratings.map((el, i) =>
                isDesktop || i < 2 ? <ReviewRow rating={el} last={i === ratings.length - 1} /> : '',
              )
            : 'Belum ada rating.'}
        </div>
      </div>

      <div className="d-flex justify-content-end align-items-center col-12 mt-3">
        <ReactPaginate
          previousLabel={
            <div className="pagi-li">
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.677344 5.777C0.677344 5.95703 0.751476 6.12648 0.878559 6.25356L5.623 10.998C5.75009 11.1251 5.91953 11.1992 6.09957 11.1992C6.47023 11.1992 6.77734 10.8921 6.77734 10.5214L6.77734 1.03255C6.77734 0.661893 6.47023 0.354774 6.09957 0.354774C5.91953 0.354774 5.75009 0.428906 5.623 0.55599L0.878559 5.30043C0.751476 5.42752 0.677344 5.59696 0.677344 5.777Z"
                  fill="#FF0F51"
                />
              </svg>
            </div>
          }
          nextLabel={
            <div className="pagi-li">
              <svg
                width="7"
                height="11"
                viewBox="0 0 7 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.1 5.42222C6.1 5.24219 6.02587 5.07274 5.89878 4.94566L1.15434 0.201215C1.02726 0.0741319 0.857813 0 0.677778 0C0.307118 0 0 0.307118 0 0.677778V10.1667C0 10.5373 0.307118 10.8444 0.677778 10.8444C0.857813 10.8444 1.02726 10.7703 1.15434 10.6432L5.89878 5.89878C6.02587 5.7717 6.1 5.60226 6.1 5.42222Z"
                  fill="#FF0F51"
                />
              </svg>
            </div>
          }
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={totalPage}
          marginPagesDisplayed={1}
          pageRangeDisplayed={1}
          // onPageChange={this.handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'pageactive'}
          pageClassName={'pageclass'}
          initialPage={pageSelected}
          forcePage={pageSelected}
          onPageChange={(data) => setPageSelected(data.selected)}
        />
      </div>
    </section>
  );
};

export default CourseRating;
