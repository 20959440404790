import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import VoucherPopup from './includes/VoucherPopup';
import VoucherFinalPurchase from './includes/VoucherFinalPurchase';

class LandingHeaderLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: 0,
      userName: this.props.userName,
      voucherModalOpen: false,
      voucherFinalSubmitModalOpen: false,
      voucher_code: '',
      voucher_code_referral: ''
    };
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let voucherpop_open = params.get('voucherpop_open');
    console.log(voucherpop_open);
    if (voucherpop_open) {
      this.toggleModalVoucherPage();
    }
  }

  logoutCall = (e) => {
    e.preventDefault();
    this.setState({
      loading: 1,
    });
    const access_token = localStorage.getItem('access_token');
    axios
      .post(`${window.$API_URL}api/auth/logout/`, {}, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
      })
      .then((success) => {
        this.setState({
          loading: 0,
        });
        localStorage.removeItem('access_token');
        NotificationManager.success('Sampai jumpa kembali', 'Berhasil Logout!');
        setTimeout(() => window.location.href = '/', 3000);
      })
      .catch((error) => {
        localStorage.removeItem('access_token');
        NotificationManager.success('Sampai jumpa kembali', 'Berhasil Logout!');
        setTimeout(() => window.location.href = '/', 3000);
        this.setState({
          loading: 0,
        });
      });
  }

  toggleModalSigninPage = () =>
    this.setState({
      modalOpen: !this.state.modalOpen,
    });

  loginModalToggle = (e) => {
    e.preventDefault();
    this.setState({
      modalOpen: 1,
    });
  };


  toggleModalVoucherPage = () =>
    this.setState({
      voucherModalOpen: !this.state.voucherModalOpen,
    });

  toggleModalVoucherPurchasePage = () =>
    this.setState({
      voucherFinalSubmitModalOpen: !this.state.voucherFinalSubmitModalOpen,
    });

  toggleFinalSubmitPage = (voucher_code) =>
    this.setState({
      voucherFinalSubmitModalOpen: !this.state.voucherFinalSubmitModalOpen,
      voucher_code: voucher_code,
      voucher_code_referral: voucher_code
    });

  render() {
    return (
      <>
        <div className="header_top home4">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-xl-5">
                <ul className="home4_header_top_contact">
                  <li className="list-inline-item d-none d-md-inline-block">
                    <a href="https://api.whatsapp.com/send/?phone=6287857067313&text&app_absent=0" target="_new">+62 878-5706-7313</a>
                  </li>
                  <li className="list-inline-item d-none d-md-inline-block">
                    <Link to="/">hello@myedusolve.com</Link>
                  </li>
                  <li className="list-inline-item">
                    <a href="#/" onClick={(e) => this.toggleModalVoucherPage()}>Punya Kode Voucher?</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-7 col-xl-7">
                <ul className="sign_up_btn home4 dn-smd text-right">
                  <li className="list-inline-item">
                    <Link to={
                      (this.props.role_id == undefined
                        ||
                        this.props.role_id === 2)
                        ?
                        "/student/profile"
                        :
                        "/instructor/profile"
                    } className="btn btn-md">
                      <i className="flaticon-megaphone" />
                      <span className="dn-md">{this.state.userName}</span>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <a
                      onClick={(e) => this.logoutCall(e)}
                      className="btn btn-md classPointer sign_up_btn"
                      data-toggle="modal"
                    >
                      <i className="flaticon-user" />
                      <span className="dn-md"> Logout</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <VoucherPopup
          isOpen={this.state.voucherModalOpen}
          toggleModalVoucherPage={this.toggleModalVoucherPage}
          toggleFinalSubmitPage={this.toggleFinalSubmitPage}
        />

        <VoucherFinalPurchase
          isOpen={this.state.voucherFinalSubmitModalOpen}
          toggleModalVoucherPage={this.toggleModalVoucherPurchasePage}
          voucher_code_referral={this.state.voucher_code_referral}
        />
        <NotificationContainer />
      </>
    );
  }
}

export default LandingHeaderLogin;
