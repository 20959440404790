import axios from 'axios';
import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactGA from 'react-ga';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import MediaQuery from 'react-responsive';
import VoucherFinalPurchase from '../../containers/includes/VoucherFinalPurchase';
import VoucherPopup from '../../containers/includes/VoucherPopup';
import VoucherModals from '../LandingComponent/VoucherModals';
import Search from '../Search/SearchNew';

import moment from 'moment';
import publicIp from 'public-ip';
import { trackEventSource, trackEventSourceNoAuth } from '../../Utils/EventSourcing';

const ExlamationMark = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="1"
      y="2"
      width="15"
      height="14"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.49998 2.33136C4.81998 2.33136 1.83331 5.31803 1.83331 8.99803C1.83331 12.678 4.81998 15.6647 8.49998 15.6647C12.18 15.6647 15.1666 12.678 15.1666 8.99803C15.1666 5.31803 12.18 2.33136 8.49998 2.33136ZM8.49998 9.66469C8.13331 9.66469 7.83331 9.36469 7.83331 8.99803V6.33136C7.83331 5.96469 8.13331 5.66469 8.49998 5.66469C8.86665 5.66469 9.16665 5.96469 9.16665 6.33136V8.99803C9.16665 9.36469 8.86665 9.66469 8.49998 9.66469ZM7.83331 10.998V12.3314H9.16665V10.998H7.83331Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect x="0.5" y="0.998047" width="16" height="16" fill="#2E5AAC" />
    </g>
  </svg>
);

// * small banner used in course detail under navbar

const LandingBannerNew = ({ smallBanner, disableBackground, userNameProps, secondaryStyle }) => {
  const dispatch = useDispatch();
  const [alert, setAlert] = useState('');
  const [voucherModalOpen, setVoucherModalOpen] = useState(false);
  const [voucherFinalSubmitModalOpen, setVoucherFinalSubmitModalOpen] = useState(false);
  const [voucher_code, setVoucher_code] = useState('');
  const [voucher_code_referral, setVoucher_code_referral] = useState('');
  const [voucherModalNewOpen, setVoucherModalNewOpen] = useState(false);
  const [voucherModalNewIsSuccess, setVoucherModalNewIsSuccess] = useState(false);
  const [voucherModalNewMsgId, setVoucherModalNewMsgId] = useState(null);
  const [voucherModalNewMsgOtherEmail, setVoucherModalNewMsgOtherEmail] = useState(null);
  const [voucherModalNewTitle, setVoucherModalNewTitle] = useState(null);
  const [formClicked, setFormClicked] = useState(false);

  useEffect(() => {
    const _voucher_code = localStorage.getItem('voucher_code');
    const _voucher_code_register = localStorage.getItem('voucher_register');
    if (_voucher_code && !_voucher_code_register) {
      checkValidVoucher(_voucher_code);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleModalVoucherPurchasePage = () =>
    setVoucherFinalSubmitModalOpen(!voucherFinalSubmitModalOpen);

  const toggleModalVoucherPage = () => setVoucherModalOpen(!voucherModalOpen);

  const toggleFinalSubmitPage = (voucher_code) => {
    setVoucherFinalSubmitModalOpen(!voucherFinalSubmitModalOpen);
    setVoucher_code(voucher_code);
    setVoucher_code_referral(voucher_code);
  };

  const checkValidVoucher = (voucher) => {
    dispatch({ type: 'TOGGLE_LOADING_TRUE' });
    const access_token = localStorage.getItem('access_token');

    axios
      .get(
        `${window.$API_URL}api/voucher/checkvoucher/?voucher_code=${voucher}`,
        {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
        },
      )
      .then(async (success) => {
        dispatch({ type: 'TOGGLE_LOADING_FALSE' });

        if (success.data.success && !success.data.data.is_purchased) {
          //public
          // localStorage.setItem('voucher_code', voucher);
          dispatch({ type: 'STORE_VOUCHER_CODE', payload: voucher });
          setVoucherModalNewOpen(true);
          setVoucherModalNewIsSuccess(true);
          setVoucherModalNewMsgId('success-public');
          setVoucherModalNewTitle('Voucher Tersedia!');
        } else if (success.data.success && success.data.data.is_purchased) {
          trackEventSource({
            type: 'courseregister',
            payload: {
              course_id: success.data.data.course.id,
              method: 'voucher',
              code: voucher,
              voucher_id: success.data.data.id,
              mpp_id: success.data.data.mpp ? success.data.data.mpp.id : '',
            },
          });
          trackEventSourceNoAuth({
            type: 'courseregisterip',
            payload: {
              ip: await publicIp.v4(),
              course_id: success.data.data.course.id,
              date: moment().format('YYYY-MM-DD hh:mm:ss'),
            },
          });
          ReactGA.event({
            category: 'Kelas Populer',
            action: 'Redeem-Voucher',
            label: `Kode Voucher in Homepage`,
          });
          if (success.data.data.is_external) {
            const getAlert = () => (
              <SweetAlert
                success
                showCancel={false}
                confirmBtnText="Click Here"
                confirmBtnBsStyle="success"
                title="Voucher Berhasil Ditebus!"
                onConfirm={(e) => (window.location.href = success.data.data.external_url)}
              >
                Selamat! Voucher Anda telah berhasil ditebus. Untuk mengakses Pelatihan, Silakan
                kunjungi Situs Mitra Kami.
              </SweetAlert>
            );
            setAlert(getAlert());
          } else {
            setVoucherModalNewOpen(true);
            setVoucherModalNewIsSuccess(true);
            setVoucherModalNewMsgId('success-login');
            setVoucherModalNewTitle('Voucher Berhasil digunakan!');
          }
        }
      })
      .catch((error) => {
        dispatch({ type: 'TOGGLE_LOADING_FALSE' });
        if (error.response) {
          if (error.response.status == '404') {
            if (error.response.data.message == 'Kode voucher ini sudah digunakan sebelumnya') {
              setVoucherModalNewOpen(true);
              setVoucherModalNewIsSuccess(false);
              setVoucherModalNewMsgId('already-used');
              setVoucherModalNewTitle('Voucher Sudah digunakan!');
            } else if (error.response.data.message == 'Voucher Tidak Ditemukan') {
              setVoucherModalNewOpen(true);
              setVoucherModalNewIsSuccess(false);
              setVoucherModalNewMsgId('not-found');
              setVoucherModalNewTitle('Voucher tidak tersedia!');
            } else if (error.response.data.message) {
              setVoucherModalNewOpen(true);
              setVoucherModalNewIsSuccess(false);
              setVoucherModalNewMsgId('already-used-email');
              setVoucherModalNewTitle('Voucher Sudah digunakan!');
              setVoucherModalNewMsgOtherEmail(error.response.data.message);
            }
          } else if (error.response.status == '400') {
            if (error.response.data.message == 'You are Already enrolled in this course') {
              setVoucherModalNewOpen(true);
              setVoucherModalNewIsSuccess(false);
              setVoucherModalNewMsgId('course-owned');
              setVoucherModalNewTitle('Anda sudah memiliki Kelas ini.');
            } else if (error.response.data.message) {
              setVoucherModalNewOpen(true);
              setVoucherModalNewIsSuccess(false);
              setVoucherModalNewMsgId('already-used-email');
              setVoucherModalNewTitle('Voucher Sudah digunakan!');
              setVoucherModalNewMsgOtherEmail(error.response.data.message);
            }
          } else {
            NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
          }
        } else {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        }
      });
  };

  return (
    <>
      {!smallBanner ? (
        <div className="voucher-prakerja-new">
          <div className={secondaryStyle ? 'secondary-style' : ''}>
            {/* desktop */}
            <MediaQuery minDeviceWidth={992}>
              <img src="/images/landing/homepage-new/voucher-bg.png" alt="Kratu Prakreja Program" />
            </MediaQuery>

            {/* mobile */}
            <MediaQuery maxDeviceWidth={991}>
              <div className="voucher-prakerja-new__images-mob">
                <div>
                  <img
                    src="/images/landing/homepage-new/prakerja-icon.png"
                    alt="Kratu Prakreja Program"
                  />
                </div>
                <div>
                  <img
                    src="/images/landing/homepage-new/voucher-bg-mob.png"
                    alt="Kratu Prakreja Program"
                  />
                </div>
              </div>
            </MediaQuery>

            <div>
              <MediaQuery minDeviceWidth={992}>
                <div>
                  <img
                    src="/images/landing/homepage-new/prakerja-icon.png"
                    alt="Kratu Prakreja Program"
                  />
                </div>
              </MediaQuery>
              <h1>Apakah Anda Memiliki Voucher Prakerja?</h1>
              <MediaQuery minDeviceWidth={992}>
                <p>Bantuan:</p>
                <p>hello@myedusolve.com | +62 878-5706-7313</p>
              </MediaQuery>
            </div>

            <div>
              <div className="form-prakerja">
                <input
                  className="search__input"
                  placeholder="Masukkan kode voucher"
                  value={voucher_code}
                  onChange={(e) => setVoucher_code(e.target.value)}
                  onClick={(e) => {
                    if (!formClicked) {
                      ReactGA.event({
                        category: 'Kelas Populer',
                        action: 'Click-Voucher',
                        label: `Kode Voucher in Homepage`,
                      });
                      setFormClicked(true);
                    }
                  }}
                />
                <button
                  className="btn btn-pink btn-pink-hover"
                  onClick={(e) => checkValidVoucher(voucher_code)}
                >
                  Redeem
                </button>
              </div>
              <h3>
                <ExlamationMark />
                Voucher hanya perlu di redeem satu kali saja.
              </h3>
            </div>

            {/* mobile */}
            <div>
              <MediaQuery maxDeviceWidth={991}>
                <p>Bantuan:</p>
                <p>hello@myedusolve.com | +62 878-5706-7313</p>
              </MediaQuery>
            </div>
          </div>
        </div>
      ) : (
        <Search
          checkValidVoucher={checkValidVoucher}
          handleVoucherChange={(e) => setVoucher_code(e.target.value)}
          voucherCode={voucher_code}
        />
      )}

      <VoucherModals
        isModalOpen={voucherModalNewOpen}
        closeModal={() => setVoucherModalNewOpen(false)}
        title={voucherModalNewTitle}
        isSuccess={voucherModalNewIsSuccess}
        msgId={voucherModalNewMsgId}
        usedByOther={voucherModalNewMsgOtherEmail}
        refreshInsted={secondaryStyle ? true : false}
      />
      <VoucherPopup
        isOpen={voucherModalOpen}
        toggleModalVoucherPage={toggleModalVoucherPage}
        toggleFinalSubmitPage={toggleFinalSubmitPage}
      />

      <VoucherFinalPurchase
        isOpen={voucherFinalSubmitModalOpen}
        toggleModalVoucherPage={toggleModalVoucherPurchasePage}
        voucher_code_referral={voucher_code_referral}
      />
      <NotificationContainer />
      {alert}
    </>
  );
};

export default LandingBannerNew;
