import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom'
import NumberFormat from 'react-number-format';

const FullStar = () => <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.49996 12.2329L12.8775 14.875L11.7158 9.89541L15.5833 6.54499L10.4904 6.11291L8.49996 1.41666L6.50954 6.11291L1.41663 6.54499L5.28413 9.89541L4.12246 14.875L8.49996 12.2329Z" fill="#FFC632"/>
</svg>

const EmptyStar = () => <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5833 6.54499L10.4904 6.10582L8.49996 1.41666L6.50954 6.11291L1.41663 6.54499L5.28413 9.89541L4.12246 14.875L8.49996 12.2329L12.8775 14.875L11.7229 9.89541L15.5833 6.54499ZM8.49996 10.9083L5.83663 12.5162L6.54496 9.48457L4.19329 7.44457L7.29579 7.17541L8.49996 4.32082L9.71121 7.18249L12.8137 7.45166L10.462 9.49166L11.1704 12.5233L8.49996 10.9083Z" fill="#FFC632"/>
</svg>

const HalfStar = () => <svg width="17" height="17" xmlns="http://www.w3.org/2000/svg">
<path id="svg_1" fill="#FFC632" d="m15.5833,6.54499l-5.0929,-0.43917l-1.99044,-4.68916l-1.99042,4.69625l-5.09291,0.43208l3.8675,3.35042l-1.16167,4.97959l4.3775,-2.6421l4.37754,2.6421l-1.1546,-4.97959l3.8604,-3.35042zm-7.08334,4.36331l-2.66333,1.6079l0.70833,-3.03163l-2.35167,-2.04l3.1025,-0.26916l1.20417,-2.85459l1.21125,2.86167l3.10249,0.26917l-2.3517,2.04l0.7084,3.03164l-2.67044,-1.615z"/>
<path id="svg_9" d="m1.64207,6.47814l4.91531,-0.46721l1.96721,-4.48087l-0.05464,10.65574l-4.37159,2.62295l1.14754,-4.97268l-3.60383,-3.35793z" fill-opacity="null" stroke-opacity="null" stroke-width="null" stroke="null" fill="#FFC632"/>
</svg>



const Card = ({ data, from, section }) => {
    const history = useHistory()
    const [totalRatingStars, setTotalRatingStars] = useState([])
    const [ratingtoShow, setRatingtoShow] = useState([])
    const type = {
        live: 'Live',
        hosted: 'Self-Paced',
        external: 'External'
    }
    const urlParams = from ? section ? `?from=${from}&section=${section}` : `?from=${from}` : ''

    useEffect(() => {
        if(data.average_rating !== null) {
            let decimalNumber = `${(+data.average_rating).toFixed(1)}`[2]
            let ratingNumber = Math.floor(+data.average_rating) + 1
            let halfNumber = 0
            if(decimalNumber <= 9) {
                ratingNumber = ratingNumber - 1
                if(decimalNumber > 2) {
                    halfNumber++
                }
            }

            let temp = []
            for(let i = 0; i < ratingNumber; i++) {
                temp.push(<FullStar />)
            }
            if(halfNumber) temp.push(<HalfStar />)
            for(let i = 0; i < 5 - ratingNumber - halfNumber; i++) {
                temp.push(<EmptyStar />)
            }
            // console.log(data, decimalNumber, ratingNumber, halfNumber)
            setTotalRatingStars(temp)
            setRatingtoShow((+data.average_rating).toFixed(1))
        }
    }, [data])
    return (
        <div className="w-100 d-flex justify-content-center">
        <a href={`/course/${data.id}/${data.slug}${urlParams}`} className="w-100">
            <div className="sliderCard card" style={{cursor: 'pointer', maxHeight: '394px'}}>
                <div className="sliderCard__bg"
                    style={{
                        backgroundImage: `url("${(data.thumbnail_id !== null) ? data.thumbnail.url : '../../static/container.png'}")`,
                    }}>
                    <div className="sliderCard__labelTop d-flex justify-content-end">
                        <p className="sliderCard__labelTop-text text-align-end">{data.course_language}</p>
                    </div>
                    <div className="sliderCard__labelBottom d-flex justify-content-end align-items-end">
                        <p className="sliderCard__labelBottom-text text-align-end">{type[data.type]}</p>
                    </div>
                </div>
                <div className="sliderCard__content">
                    <p className="sliderCard__content-main sliderCard__content__coursename text-color-black">{data.name}</p>
                    <div className="d-flex justify-content-between align-items-end">
                        {data.brandIcon ? <>
                            <p className="sliderCard__instructor-text text-color-black" >{(data.instructor !== null && data.instructor) ? data.instructor.fullname : ''}</p>
                            <img src={data.brandIcon} alt="brand" />
                        </> :
                            <p className="sliderCard__instructor-text sliderCard__text text-color-black">{(data.instructor !== null && data.instructor) ? data.instructor.fullname : ''}</p>
                        }
                    </div>
                    <p style={{height: '24px', fontSize: '12px'}} className="d-flex align-items-center">{data.average_rating == null ? 'Belum ada Rating' : totalRatingStars.map(el => el)} <p className="ml-1">{`${data.average_rating == null ? '' : `(${ratingtoShow})`}`}</p></p>
                    <div className="sliderCard__content-bottom d-flex justify-content-between align-items-center">
                        {
                            (data.discount_price === 0.00)
                                ?
                                data.retail_price == 0.00 ? (
                                    <p className="sliderCard__price font-weight-500 text-color-black">Gratis</p>
                                ) :
                                <div className="d-flex align-items-end">
                                <p className="mr-1 sliderCard__price text-color-black">Rp</p><NumberFormat
                                    value={data.retail_price}
                                    displayType={'text'}
                                    thousandSeparator="."
                                    isNumericStrin={true}
                                    decimalSeparator={""}
                                    thousandsGroupStyle="thousand"
                                    className="sliderCard__price font-weight-500 text-color-black"
                                /></div>
                                :
                                <>
                                    <div className="d-flex align-items-end">
                                        <p className="mr-1 sliderCard__price text-color-black">Rp</p><NumberFormat
                                            value={data.discount_price}
                                            displayType={'text'}
                                            thousandSeparator="."
                                            isNumericStrin={true}
                                            decimalSeparator={""}
                                            thousandsGroupStyle="thousand"
                                            className="sliderCard__price font-weight-500 text-color-black"
                                        /></div>
                                    <div className="d-flex align-items-end">
                                        <p className="text-line tx-tiny tx-color-3">Rp</p><NumberFormat
                                            value={data.retail_price}
                                            displayType={'text'}
                                            thousandSeparator="."
                                            isNumericStrin={true}
                                            decimalSeparator={""}
                                            thousandsGroupStyle="thousand"
                                            className="tx-tiny tx-color-3 text-line"
                                        /></div>
                                </>
                        }
                    </div>
                </div>
            </div>
        </a>
        </div>
    )
}

export default Card;