import React from 'react';
import { Link } from 'react-router-dom';
import LoginRegisterModal from './includes/LoginRegisterModal';
import VoucherPopup from './includes/VoucherPopup';
import VoucherFinalPurchase from './includes/VoucherFinalPurchase';
import ForgetPassword from './includes/ForgetPassword';
import ResetPassword from './includes/ResetPassword';
import VerifyAccount from './includes/VerifyAccount';
class LandingHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: 0,
      voucherModalOpen: false,
      voucherFinalSubmitModalOpen: false,
      voucher_code: '',
      voucher_code_referral: '',
      forgotPasswordModalOpen: false,
      is_voucher_popup_open_video_section: this.props.is_voucher_popup_open_video_section
    };
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let voucherpop_open = params.get('voucherpop_open');
    if (voucherpop_open) {
      this.toggleModalVoucherPage();
    }
  }

  loginModalToggle = (e) => {
    e.preventDefault();
    this.setState({
      modalOpen: 1,
    });
  };

  toggleModalSigninPage = () =>
    this.setState({
      modalOpen: !this.state.modalOpen,
    });

  toggleModalForgetPasswordPage = () =>
    this.setState({
      forgotPasswordModalOpen: !this.state.forgotPasswordModalOpen,
    });

  toggleModalVoucherPage = () =>
    this.setState({
      voucherModalOpen: !this.state.voucherModalOpen,
    });

  toggleModalVoucherPurchasePage = () =>
    this.setState({
      voucherFinalSubmitModalOpen: !this.state.voucherFinalSubmitModalOpen,
    });

  toggleFinalSubmitPage = (voucher_code) =>
    this.setState({
      voucherFinalSubmitModalOpen: !this.state.voucherFinalSubmitModalOpen,
      voucher_code: voucher_code,
      voucher_code_referral: voucher_code
    });

  render() {
    return (
      <>
        <div className="header_top home4">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-xl-5">
                <ul className="home4_header_top_contact">
                  <li className="list-inline-item  d-none d-md-inline-block">
                    <p href="https://api.whatsapp.com/send/?phone=6287857067313&text&app_absent=0" target="_new">+62 878-5706-7313</p>
                  </li>
                  <li className="list-inline-item d-none d-md-inline-block">
                    <Link to="/">hello@myedusolve.com</Link>
                  </li>
                  <li className="list-inline-item">
                    <a href="#/" onClick={(e) => this.toggleModalVoucherPage()}>Punya Kode Voucher?</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-7 col-xl-7">
                <ul className="sign_up_btn home4 dn-smd text-right">
                  <li className="list-inline-item">
                    <a
                      onClick={(e) => {
                        e.preventDefault()
                        this.loginModalToggle(e)
                      }}
                      href="/"
                      className="btn btn-md classPointer sign_up_btn"
                      data-toggle="modal"
                    >
                      <i className="flaticon-user" />
                      <span className="dn-md"> Masuk/Daftar</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <VerifyAccount isOpen={this.props.is_verify_menu_open}
          reset_token={this.props.reset_token} />

        <ResetPassword isOpen={this.props.is_reset_menu_open}
          reset_token={this.props.reset_token} />

        <ForgetPassword isOpen={this.state.forgotPasswordModalOpen}
          toggleForgerPasswordOpen={this.toggleModalForgetPasswordPage} />

        <LoginRegisterModal
          isOpen={this.state.modalOpen}
          toggleSignInPopup={this.toggleModalSigninPage}
          toggleForgerPasswordOpen={this.toggleModalForgetPasswordPage}
        />

        <VoucherPopup
          isOpen={this.state.voucherModalOpen}
          toggleModalVoucherPage={this.toggleModalVoucherPage}
          toggleFinalSubmitPage={this.toggleFinalSubmitPage}
          is_voucher_popup_open_video_section={this.state.is_voucher_popup_open_video_section}
        />

        <VoucherFinalPurchase
          isOpen={this.state.voucherFinalSubmitModalOpen}
          toggleModalVoucherPage={this.toggleModalVoucherPurchasePage}
          voucher_code_referral={this.state.voucher_code_referral}
        />
      </>
    );
  }
}

export default LandingHeader;
