import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel'
import { trackEventSource, trackEventSourceNoAuth } from '../../Utils/EventSourcing'
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

const VideoPreviewBottom = () => {
  const page_opened = useSelector(state => state.page_opened)
  const [offset, setOffset] = useState(0)
  const handleScroll = () => setOffset(window.pageYOffset)
  const isDesktop = useMediaQuery({ query: '(min-device-width: 992px)' })
  useEffect(() => {
    if (page_opened == 'course_detail') window.addEventListener('scroll', handleScroll, { passive: true });
    else window.removeEventListener('scroll', handleScroll);
  }, [page_opened])
  return (
    <div className={`contanctuswa-btn ${(page_opened == 'course_detail' && offset > 600) || (page_opened == 'course_detail' && !isDesktop) ? 'contanctuswa-btn-course-detail' : ''}`}>
      <div className="contanctuswa-btn__btn" onClick={() => {
        window.open("https://api.whatsapp.com/send/?phone=6287857067313&text&app_absent=0", "_blank")
        ReactPixel.track('ContactUsWA', { from: window.location.pathname })
        ReactGA.event({
          category: 'Contact Us',
          action: `WhatsApp-Btn`,
          from: window.location.pathname
        })
        const access_token = localStorage.getItem('access_token')
        if (access_token) {
          trackEventSource({
            type: "clickcontactusWA",
            payload: {
              from: window.location.pathname
            }
          })
        } else {
          trackEventSourceNoAuth({
            type: "clickcontactusWA",
            payload: {
              from: window.location.pathname
            }
          })
        }
      }}>
        <div>
          <img src="/images/courses/wahd.png" className="mr-3 contanctuswa-btn__img" />
        </div>
        <div className="d-flex flex-column">
          <p className="contanctuswa-btn__text1">Punya Pertanyaan?</p>
          <p className="contanctuswa-btn__text2">Hubungi Kami</p>
        </div>
      </div>
    </div>
  )
}

export default VideoPreviewBottom;
