import React from 'react'

const CourseResourceButton = ({url}) => {
    return (
        <>
            <div className="course-resource-btn">
                <h1 className="course-resource-btn__title">Resource Kelas</h1>
                <div className="course-resource-btn__card card">
                    <img src="/images/courses/downloadrsc.png" className="course-resource-btn__icon-dl" alt="resource download icon"/>
                    <p className="course-resource-btn__title ml-2"
                    onClick={() => {
                        window.open(url, "_blank")
                    }}>Download</p>
                </div>
            </div>
        </>
    )
}


export default CourseResourceButton