import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import Button from '../Button';

//Search new April 2021

const Search = ({
  handleVoucherChange,
  voucherCode,
  checkValidVoucher,
}) => {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ query: '(min-device-width: 992px)' });
  const [redeemOpen, setRedeemOpen] = useState(false);

  return (
    <div className="search-new">
      <div>
        {/* redeem open only for mobile */}
        {!redeemOpen ? (
          <div className="search__desc">
            {isDesktop ? (
              <div>
                <img
                  src="/images/landing/homepage-new/prakerja-icon.png"
                  alt="Kratu Prakreja Program"
                />
              </div>
            ) : (
              ''
            )}
            <p>
              Anda memiliki voucher Kartu Prakerja?
              {!isDesktop ? <span onClick={() => setRedeemOpen(true)}>Redeem</span> : ''}
            </p>
            {isDesktop ? (
              <div className="form-prakerja-small">
                <input
                  value={voucherCode}
                  placeholder="Masukkan kode voucher"
                  onChange={(e) => handleVoucherChange(e)}
                />
                <button
                  className="btn btn-pink"
                  onClick={(e) => checkValidVoucher(voucherCode)}
                >
                  Redeem
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          <div className="search__desc">
            <div className="form-prakerja-small__mobile">
              <input
                placeholder="Masukkan kode voucher"
                value={voucherCode}
                onChange={(e) => handleVoucherChange(e)}
              />
              <button
                className="btn btn-pink"
                onClick={(e) => checkValidVoucher(voucherCode)}
              >
                Redeem
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};
export default connect(mapDispatchToProps)(Search);
