/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//Components
import { Collapse } from 'react-collapse';
import { NotificationManager } from 'react-notifications';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import CourseNavContent from './CourseNav';

//Functions
import { parseTotalTime, parseTotalTimeMaterialAndEbook } from '../../Utils/Utils';

//Assets
import { useMediaQuery } from 'react-responsive';
import { CollapseDown, CollapseUp } from '../../static';

const CourseNav = ({ openModal }) => {
  const dispatch = useDispatch();

  // openedID => opened id on lessons_front data
  const [openedID, setOpenedID] = useState(0);
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const course_detail = useSelector((state) => state.course_detail);
  const course_ebook = useSelector((state) => state.course_ebook);
  let materiCount = 0;

  if (course_detail) materiCount = course_detail.lessons_front.length;

  const navFunction = {
    selectSubMateri(id) {
      dispatch({
        type: 'UPDATE_MATERI_OPEN',
        payload: id,
      });
    },
    selectExamOrMateri(selected) {
      dispatch({
        type: 'UPDATE_EXAM_OR_MATERI',
        payload: selected,
      });
    },
    checkIfQuizPassed: () => {
      setLoading(true);
      const access_token = localStorage.getItem('access_token');
      axios
        .get(`${window.$API_URL}api/quiz/${course_detail?.quiz?.id}/isquizpassed`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((success) => {
          setLoading(false);
          if (success.data.data != null) {
            openModal('exam_passed');
            dispatch({
              type: 'QUIZ_POPUP_TYPE',
              payload: 'exam_passed',
            });
            dispatch({
              type: 'EXAM_ALREADY_PASSED',
              payload: true,
            });
          } else {
            navFunction.selectSubMateri('exam');
            navFunction.selectExamOrMateri('exam');
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
            setOpenedID(0);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            if (error.response.status === '422') {
              let errorTemp = [];
              Object.keys(error.response.data.errors).forEach((key) => {
                errorTemp.push(error.response.data.errors[key][0]);
              });
              const errorList2 = errorTemp.map((char, i) => {
                return (
                  <li key={i} style={{ listStyleType: 'none' }}>
                    {char}
                  </li>
                );
              });
              NotificationManager.error(errorList2, 'Validasi Error!');
            } else if (error.response.status === '404') {
              NotificationManager.error('Some Error Occured', 'Validasi Error!');
            } else {
              NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
            }
          } else {
            NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
          }
        });
    },
    freeCertificateCheck: () => {
      // if (course_detail.retail_price===0) {
      dispatch({ type: 'TOGGLE_LOADING_TRUE' });
      const access_token = localStorage.getItem('access_token');
      return axios
        .post(
          `${window.$API_URL}api/course/generatecertificatefreecourse`,
          {
            course_id: course_detail.id,
            lecture_id: course_detail.lessons_front[0].lectures[0].id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${access_token}`,
            },
          },
        )
        .then((success) => {
          console.log(success);
          return success.data.data.certificate_pdf_url;
        })
        .catch((error) => {
          throw error;
        })
        .finally((_) => dispatch({ type: 'TOGGLE_LOADING_FALSE' }));

      // }
    },
    courseQnaCheck: () => {
      let isFinish = true;
      course_detail.lessons_front.forEach((lesson) => {
        lesson.lectures.forEach((lecture) => {
          if (lecture.attended === null) {
            isFinish = false;
          }
        });
      });
      return isFinish;
    },
    examCheck: () => {
      let allowExam = true;
      if (course_detail.is_qna_required && course_detail.is_qna_enabled) {
        if (!course_detail.course_qna_attend) {
          allowExam = false;
        }
      } else {
        let countScheduleNotPassed = 0;
        course_detail.lessons_front.forEach((lesson) => {
          lesson.lectures.forEach((lecture) => {
            if (course_detail.is_lecturequiz_required && course_detail.is_lecturequiz_enabled) {
              if (lecture.attended === null) {
                allowExam = false;
              } else {
                if (lecture.lecture_quiz_questions) {
                  if (lecture.lecture_quiz_questions[0]) {
                    if (lecture.is_lecture_quiz_passed === null) {
                      allowExam = false;
                    }
                  }
                }
              }
            } else {
              if (lecture.attended === null) {
                allowExam = false;
              }
            }
          });

          if (!lesson?.is_lesson_schedule_passed && lesson?.start_date && lesson?.end_date) {
            countScheduleNotPassed += 1;
          }
        });

        allowExam = countScheduleNotPassed > 0 ? false : true;
      }
      return allowExam;
    },
  };

  const NavTitle = () => {
    return course_detail ? (
      <div className="course-nav-title">
        <h2 className="class-content">Konten Kelas</h2>
        {materiCount ? (
          <h4 className="class-content-detail">{`${materiCount} Materi${
            course_ebook && course_ebook.length > 0 ? ' + ' + course_ebook.length + ' Ebook' : ''
          } | Total durasi ${
            course_ebook && course_ebook.length > 0
              ? parseTotalTimeMaterialAndEbook(course_detail.lessons_front, course_ebook)
              : parseTotalTime(course_detail.lessons_front)
          }`}</h4>
        ) : (
          ''
        )}
      </div>
    ) : (
      ''
    );
  };

  return (
    <div>
      {!isMobile ? (
        <div className="navbar-desc mb-5">
          <NavTitle />
          <CourseNavContent
            openModal={openModal}
            navFunction={navFunction}
            openedID={openedID}
            setOpenedID={setOpenedID}
          />
        </div>
      ) : (
        ''
      )}

      {isMobile ? (
        <div className="navbar-mob mt-4 mb-4">
          <div className={'content-drawer'}>
            <a
              className={`d-flex justify-content-between hosted-table-mob align-items-center mb-3`}
              onClick={() => setOpen(!open)}
              aria-controls="class-subcontent"
              aria-expanded={open}
            >
              <NavTitle />
              {!open ? <CollapseDown /> : <CollapseUp />}
            </a>
            <Collapse isOpened={open}>
              <div id="class-subcontent">
                <CourseNavContent
                  openModal={openModal}
                  navFunction={navFunction}
                  openedID={openedID}
                  setOpenedID={setOpenedID}
                />
              </div>
            </Collapse>
          </div>
        </div>
      ) : (
        ''
      )}
      {loading ? <LoadingSpinner text={'Loading..'} /> : ''}
    </div>
  );
};

export default CourseNav;
