import React from 'react'
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import VoucherPopup from '../../containers/includes/VoucherPopup';
import VoucherFinalPurchase from '../../containers/includes/VoucherFinalPurchase';
import VoucherModals from './VoucherModals'
import Search from '../Search/Search'
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { trackEventSource, trackEventSourceNoAuth } from '../../Utils/EventSourcing'
import publicIp from 'public-ip'
import moment from 'moment'

//const LandingBanner = () => {
class LandingBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: 0,
      userName: this.props.userName,
      voucherModalOpen: false,
      voucherFinalSubmitModalOpen: false,
      voucher_code: '',
      voucher_code_referral: '',
      voucherModalNewOpen: false,
      voucherModalNewIsSuccess: false,
      voucherModalNewMsgId: null,
      voucherModalNewMsgOtherEmail: null,
      voucherModalNewTitle: null,
      formClicked: false
    };
  }

  componentDidMount = () => {
    const _voucher_code = localStorage.getItem('voucher_code')
    const _voucher_code_register = localStorage.getItem('voucher_register')
    if (_voucher_code && !_voucher_code_register) {
      this.checkValidVoucher(_voucher_code)
    }
  }

  cancelVoucherRedemption = () => {
    this.setState({
      voucher_code: '',
      voucher_data: [],
      alert: ''
    });
  }

  toggleModalVoucherPurchasePage = () =>
    this.setState({
      voucherFinalSubmitModalOpen: !this.state.voucherFinalSubmitModalOpen,
    });

  toggleModalVoucherPage = () =>
    this.setState({
      voucherModalOpen: !this.state.voucherModalOpen,
    });

  toggleFinalSubmitPage = (voucher_code) =>
    this.setState({
      voucherFinalSubmitModalOpen: !this.state.voucherFinalSubmitModalOpen,
      voucher_code: voucher_code,
      voucher_code_referral: voucher_code
    });

  confirmVoucherRedemption = () => {
    this.setState({
      alert: ''
    })
    this.toggleFinalSubmitPage(this.state.voucher_code);
  }

  checkValidVoucher = (voucher) => {
    this.props.dispatch({ type: 'TOGGLE_LOADING_TRUE' })

    const access_token = localStorage.getItem('access_token');

    axios
      .get(
        `${window.$API_URL}api/voucher/checkvoucher/?voucher_code=` + voucher,
        {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
        },
      )
      .then(async (success) => {
        this.props.dispatch({ type: 'TOGGLE_LOADING_FALSE' })

        if (success.data.success && !success.data.data.is_purchased) { //public
          // localStorage.setItem('voucher_code', voucher);
          this.props.dispatch({ type: 'STORE_VOUCHER_CODE', payload: voucher })
          this.setState({
            voucherModalNewOpen: true,
            voucherModalNewIsSuccess: true,
            voucherModalNewMsgId: 'success-public',
            voucherModalNewTitle: 'Voucher Tersedia!',
          })

        } else if (success.data.success && success.data.data.is_purchased) {
          trackEventSource({
            type: "courseregister",
            payload: {
              course_id: success.data.data.course.id,
              method: 'voucher',
              code: voucher,
              voucher_id: success.data.data.id,
              mpp_id: success.data.data.mpp ? success.data.data.mpp.id : ''
            }
          })
          trackEventSourceNoAuth({
            type: "courseregisterip",
            payload: {
              ip: await publicIp.v4(),
              course_id: success.data.data.course.id,
              date: moment().format('YYYY-MM-DD hh:mm:ss')
            }
          })
          ReactGA.event({
            category: 'Kelas Populer',
            action: 'Redeem-Voucher',
            label: `Kode Voucher in Homepage`
          })
          if (success.data.data.is_external) {
            const getAlert = () => (
              <SweetAlert
                success
                showCancel={false}
                confirmBtnText="Click Here"
                confirmBtnBsStyle="success"
                title="Voucher Berhasil Ditebus!"
                onConfirm={(e) => window.location.href = success.data.data.external_url}
              >
                Selamat! Voucher Anda telah berhasil ditebus. Untuk mengakses Pelatihan, Silakan kunjungi Situs Mitra Kami.
              </SweetAlert>
            );
            this.setState({
              alert: getAlert(),
            });
          } else {
            if (success.data.data.course_type == "live") {
              var redirectUrl = '/student/profile';
            } else {
              var redirectUrl = '/student/profile';
            }
            this.setState({
              voucherModalNewOpen: true,
              voucherModalNewIsSuccess: true,
              voucherModalNewMsgId: 'sucess-login',
              voucherModalNewTitle: 'Voucher Berhasil digunakan!',
            })
          }
        }
      })
      .catch((error) => {
        this.props.dispatch({ type: 'TOGGLE_LOADING_FALSE' })
        if (error.response) {
          if (error.response.status == '422') {
            this.state.returnError = [];
            Object.keys(error.response.data.errors).forEach((key) => {
              this.state.returnError.push(error.response.data.errors[key][0]);
            });
            this.state.showing = true;
            this.setState({ returnError: this.state.returnError, showing: this.state.showing });
            const errorList2 = this.state.returnError.map((char, i) => {
              return (
                <li key={i} style={{ listStyleType: 'none' }}>
                  {char}
                </li>
              );
            });
            NotificationManager.error(errorList2, 'Validasi Error!');
          } else if (error.response.status == '404') {
            if (error.response.data.message == "Kode voucher ini sudah digunakan sebelumnya") {
              this.setState({
                voucherModalNewOpen: true,
                voucherModalNewIsSuccess: false,
                voucherModalNewMsgId: 'already-used',
                voucherModalNewTitle: 'Voucher Sudah digunakan!',
              })
            } else if (error.response.data.message == "Voucher Tidak Ditemukan") {
              this.setState({
                voucherModalNewOpen: true,
                voucherModalNewIsSuccess: false,
                voucherModalNewMsgId: 'not-found',
                voucherModalNewTitle: 'Voucher tidak tersedia!',
              })
            } else if (error.response.data.message) {
              this.setState({
                voucherModalNewOpen: true,
                voucherModalNewIsSuccess: false,
                voucherModalNewMsgId: 'already-used-email',
                voucherModalNewTitle: 'Voucher Sudah digunakan!',
                voucherModalNewMsgOtherEmail: error.response.data.message
              })
            }
          } else if (error.response.status == '400') {
            if (error.response.data.message == "You are Already enrolled in this course") {
              this.setState({
                voucherModalNewOpen: true,
                voucherModalNewIsSuccess: false,
                voucherModalNewMsgId: 'course-owned',
                voucherModalNewTitle: 'Anda sudah memiliki Kelas ini.',
              })
            } else if (error.response.data.message) {
              this.setState({
                voucherModalNewOpen: true,
                voucherModalNewIsSuccess: false,
                voucherModalNewMsgId: 'already-used-email',
                voucherModalNewTitle: 'Voucher Sudah digunakan!',
                voucherModalNewMsgOtherEmail: error.response.data.message
              })
            }
          } else {
            NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
          }
        } else {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        }
      });
  }

  render() {
    if (this.props.disableBackground) {
      return (
        <>
          <div className="d-flex h-100 justify-content-center pt-3">
            <div className="card landing-banner-card">
              <h1 className="landing-banner-card__text-big">Apakah Anda Memiliki Voucher Pra-Kerja?</h1>
              <div className="d-flex mt-3">
                <input
                  className="search__input mr-3"
                  placeholder="Masukkan kode voucher"
                  style={{ margin: 0 }}
                  value={this.state.voucher_code}
                  onChange={(e) => this.setState({ voucher_code: e.target.value })}
                  onClick={(e) => {
                    if (!this.state.formClicked) {
                      ReactGA.event({
                        category: 'Kelas Populer',
                        action: 'Click-Voucher',
                        label: `Kode Voucher in Homepage`
                      })
                      this.setState({ formClicked: true })
                    }
                  }}
                />
                <button className="btn btn-pink btn-pink-hover" style={{ fontWeight: '500', fontFamily: 'Montserrat' }} onClick={(e) => this.checkValidVoucher(this.state.voucher_code)}>Redeem</button>
              </div>
            </div>
          </div>
          <VoucherModals isModalOpen={this.state.voucherModalNewOpen} closeModal={() => this.setState({ voucherModalNewOpen: false })} title={this.state.voucherModalNewTitle} isSuccess={this.state.voucherModalNewIsSuccess} msgId={this.state.voucherModalNewMsgId} usedByOther={this.state.voucherModalNewMsgOtherEmail} refreshInsted />
          <VoucherPopup
            isOpen={this.state.voucherModalOpen}
            toggleModalVoucherPage={this.toggleModalVoucherPage}
            toggleFinalSubmitPage={this.toggleFinalSubmitPage}
          />

          <VoucherFinalPurchase
            isOpen={this.state.voucherFinalSubmitModalOpen}
            toggleModalVoucherPage={this.toggleModalVoucherPurchasePage}
            voucher_code_referral={this.state.voucher_code_referral}
          />
          <NotificationContainer />
          {this.state.alert}
        </>
      )
    }
    return (
      <>
        {!this.props.smallBanner ? (
          <>
            <div className="navbar-desc">
              <div className="landing-banner row main-row">
                <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div>
                    <h1 className="landing-banner__text-big">BELAJAR BARENG MYEDUSOLVE X</h1>
                    <h3 className="landing-banner__text-small mr-auto mt-2">untuk Masa Depan Kamu!</h3>
                  </div>
                </div>
                <div className="col-md-6 d-flex flex-column align-items-end justify-content-center" style={{ paddingRight: '70px' }}>
                  <div className="card landing-banner-card">
                    <h1 className="landing-banner-card__text-big">Apakah Anda Memiliki Voucher Pra-Kerja?</h1>
                    <div className="d-flex mt-3">
                      <input
                        className="search__input mr-3"
                        placeholder="Masukkan kode voucher"
                        style={{ margin: 0 }}
                        value={this.state.voucher_code}
                        onChange={(e) => this.setState({ voucher_code: e.target.value })}
                        onClick={(e) => {
                          if (!this.state.formClicked) {
                            ReactGA.event({
                              category: 'Kelas Populer',
                              action: 'Click-Voucher',
                              label: `Kode Voucher in Homepage`
                            })
                            this.setState({ formClicked: true })
                          }
                        }}
                      />
                      <button className="btn btn-pink btn-pink-hover" style={{ fontWeight: '500', fontFamily: 'Montserrat' }} onClick={(e) => this.checkValidVoucher(this.state.voucher_code)}>Redeem</button>
                    </div>
                    <p className="landing-banner-card__text-info mt-3">Bantuan:</p>
                    <p className="landing-banner-card__text-info">hello@myedusolve.com | +62 878-5706-7313</p>
                  </div>
                </div>
              </div>


            </div>
            <div className="navbar-mob  mt-4">
              <div className="landing-banner row main-row">
                <div>
                  <h1 className="landing-banner__text-big">BELAJAR BARENG MYEDUSOLVE X</h1>
                  <h3 className="landing-banner__text-small mr-auto mt-2">untuk Masa Depan Kamu!</h3>
                </div>
              </div>
              <div className="card landing-banner-card mt-4">
                <h1 className="landing-banner-card__text-big">Apakah Anda Memiliki Voucher Pra-Kerja?</h1>
                <div className="d-flex mt-3">
                  <input
                    className="search__input mr-3"
                    placeholder="Masukkan kode voucher"
                    style={{ margin: 0 }}
                    value={this.state.voucher_code}
                    onChange={(e) => this.setState({ voucher_code: e.target.value })}
                    onClick={(e) => {
                      if (!this.state.formClicked) {
                        ReactGA.event({
                          category: 'Kelas Populer',
                          action: 'Click-Voucher',
                          label: `Kode Voucher in Homepage`
                        })
                        this.setState({ formClicked: true })
                      }
                    }}
                  />
                  <button className="btn btn-pink btn-pink-hover" style={{ fontWeight: '500', fontFamily: 'Montserrat' }} onClick={(e) => this.checkValidVoucher(this.state.voucher_code)}>Redeem</button>
                </div>
                <p className="landing-banner-card__text-info mt-3">Bantuan:</p>
                <p className="landing-banner-card__text-info">hello@myedusolve.com | +62 878-5706-7313</p>
              </div>
            </div>

          </>
        ) : (
          <Search checkValidVoucher={this.checkValidVoucher} handleVoucherChange={(e) => this.setState({ voucher_code: e.target.value })}
            voucherCode={this.state.voucher_code} />
        )}
        <VoucherModals isModalOpen={this.state.voucherModalNewOpen} closeModal={() => this.setState({ voucherModalNewOpen: false })} title={this.state.voucherModalNewTitle} isSuccess={this.state.voucherModalNewIsSuccess} msgId={this.state.voucherModalNewMsgId} usedByOther={this.state.voucherModalNewMsgOtherEmail} />
        <VoucherPopup
          isOpen={this.state.voucherModalOpen}
          toggleModalVoucherPage={this.toggleModalVoucherPage}
          toggleFinalSubmitPage={this.toggleFinalSubmitPage}
        />

        <VoucherFinalPurchase
          isOpen={this.state.voucherFinalSubmitModalOpen}
          toggleModalVoucherPage={this.toggleModalVoucherPurchasePage}
          voucher_code_referral={this.state.voucher_code_referral}
        />
        <NotificationContainer />
        {this.state.alert}
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapDispatchToProps)(LandingBanner);
