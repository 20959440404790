import React, { useState, useRef, useEffect } from 'react';
import { AssignmentIcon } from '../../static';
import Button from '../Button';

const Assignment = () => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-around">
            <h1 className="assignment__title mb-3">Assignment 1</h1>
            <AssignmentIcon/>
            <h2 className="assignment__description mt-3">Buatlah sebuah kreasi desain grafis menggunakan prinsip yang telah diajarkan di dalam kelas!</h2>
            <p className="assignment__download mt-5">Silahkan <b>Download Worksheet</b> untuk petunjuk selanjutnya, dan <b>Upload Assignment</b> setelah selesai!</p>
            <div className="d-flex assignment__button justify-content-between mt-4 mb-4">
                <a className={'btn btn-white mb-2 btn-assignment btn-assignment-white'}>
                    Download Worksheet
                </a>
                <a className={'btn btn-pink mb-2 btn-assignment btn-assignment-pink'}>
                    Upload Assignment
                </a>
            </div>
            
            
        </div>
        )
}

export default Assignment