import React, { useEffect, useState } from 'react';
import MenuUser from '../components/MenuUser';
import MyUserProfile from '../components/MyUserProfile';
import Lencana from '../components/MyUserProfile/Lencana';

const AkunSaya = () => {
  return (
    <>
      <div className="dashboard-container-user-profile">
        <MyUserProfile />

        {/* <Lencana/> */}
      </div>
      <div className="menu-shadow mt-10px-mob" style={{ padding: '0', zIndex: '2' }}>
        <div className="navbar-mob">
          <MenuUser />
        </div>
      </div>
    </>
  );
};

export default AkunSaya;
