import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Card from '../CourseCard';
import ReactPaginate from 'react-paginate';
import { useHistory, useLocation } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import { DebounceInput } from 'react-debounce-input';
import MediaQuery from 'react-responsive';

const FilterCountIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 13H14.6667V11H4V13ZM4 6V8H20V6H4ZM4 18H9.33333V16H4V18Z" fill="#FF0F51" />
  </svg>
);

const GlassIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.5 0C8.22391 0 9.87721 0.684819 11.0962 1.90381C12.3152 3.12279 13 4.77609 13 6.5C13 8.11 12.41 9.59 11.44 10.73L11.71 11H12.5L17.5 16L16 17.5L11 12.5V11.71L10.73 11.44C9.59 12.41 8.11 13 6.5 13C4.77609 13 3.12279 12.3152 1.90381 11.0962C0.684819 9.87721 0 8.22391 0 6.5C0 4.77609 0.684819 3.12279 1.90381 1.90381C3.12279 0.684819 4.77609 0 6.5 0ZM6.5 2C4 2 2 4 2 6.5C2 9 4 11 6.5 11C9 11 11 9 11 6.5C11 4 9 2 6.5 2Z"
      fill="black"
    />
  </svg>
);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CourseCount = () => {
  const all_course_result = useSelector((state) => state.all_course_result);

  return all_course_result ? (
    all_course_result.result_from ? (
      <p>{`Menampilkan Kelas ke ${all_course_result.result_from} - ${all_course_result.result_to} dari ${all_course_result.total_data} Kelas`}</p>
    ) : (
      <p>Tidak ada kelas ditemukan</p>
    )
  ) : (
    <p>Tidak ada kelas ditemukan</p>
  );
};

const FilterTop = ({ filterProps }) => {
  const [nameFilter, setNameFilter] = useState('');
  const sortFilterArr = [
    { name: 'Terbaru ', field: 'newest', type: 'asc', number: 5 },
    { name: 'Harga: Rendah ke Tinggi', field: 'retail_price', type: 'asc', number: 3 },
    { name: 'Harga: Tinggi ke Rendah', field: 'retail_price', type: 'desc', number: 4 },
    { name: 'Rating: Tinggi ke Rendah', field: 'rating', type: 'desc', number: 6 },
    { name: 'Rating: Rendah ke Tinggi', field: 'rating', type: 'asc', number: 7 },
  ];

  const handleSort = (id) => {
    var filter_object = { ...filterProps.filterArray };
    filter_object.sort = id;
    filterProps.setFilterArray(filter_object);
    filterProps.setPageSelected(0);
  };

  const handleNameFilter = (text) => {
    setNameFilter(text);
    var filter_object = { ...filterProps.filterArray };
    if (text) filter_object.name = text;
    else delete filter_object.name;

    filterProps.setFilterArray(filter_object);
    filterProps.setPageSelected(0);
  };

  return (
    <>
      <div className="filter-top-new">
        <div>
          <p>{`Filter: (${filterProps.filterCount})`}</p>
          <FilterCountIcon />
        </div>
        <div>
          <div class="input-group">
            <div class="input-group-prepend">
              <label class="input-group-text" for="inputGroupSelect01">
                Urutkan:{' '}
              </label>
            </div>
            <select
              class="custom-select"
              id="inputGroupSelect01"
              onChange={(e) => handleSort(e.target.value)}
            >
              {sortFilterArr.map((el) => (
                <option value={el.number}>{el.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div
          onClick={() => {
            filterProps.eraseFilter();
            setNameFilter('');
          }}
        >
          <h2>Hapus Filter</h2>
        </div>
        <MediaQuery maxDeviceWidth={991}>
          <Filter filterProps={filterProps} />
        </MediaQuery>
        <div className="filter-top-new__input">
          <div class="input-group input-group-sm">
            <DebounceInput
              minLength={2}
              debounceTimeout={600}
              className="form-control"
              placeholder="Cari kelas yang dibutuhkan"
              onChange={(e) => handleNameFilter(e.target.value)}
              value={nameFilter}
            />
            {/* <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" placeholder="Cari kelas yang dibutuhkan"/> */}
          </div>
          <GlassIcon />
        </div>
      </div>
    </>
  );
};

const Filter = ({ filterProps }) => {
  const filterTypeArr = [
    { tag: 'hosted', name: 'Self-Paced' },
    { tag: 'live', name: 'Live' },
    { tag: 'external', name: 'External' },
  ];
  const handleType = (tag) => {
    if (filterProps.filterType === tag) filterProps.setFilterType('all');
    else filterProps.setFilterType(tag);
    filterProps.setPageSelected(0);
  };

  const filterPricing = ['Kartu Prakerja', 'Gratis'];
  const handlePricing = (id) => {
    var filter_object = { ...filterProps.filterArray };
    if (filterProps.filterArray.pricing[0] === id) filter_object.pricing = [];
    else filter_object.pricing = [id];
    filterProps.setFilterArray(filter_object);
    filterProps.setPageSelected(0);
  };

  const filterKategori = [
    { id: 1, category_name: 'Self Development' },
    { id: 2, category_name: 'Tech' },
    { id: 3, category_name: 'Creative' },
    { id: 4, category_name: 'Business' },
    { id: 5, category_name: 'Entrepreneurship' },
  ];
  const handleCategory = (id) => {
    if (filterProps.categoryType === id) filterProps.setCategoryType(null);
    else filterProps.setCategoryType(id);
    filterProps.setPageSelected(0);
  };

  return (
    <div className="filter-new">
      <div>
        <h2>Tipe Kelas</h2>
        <ul>
          {filterTypeArr.map((el, i) => (
            <li>
              <input
                id={`type${i}`}
                type="checkbox"
                checked={filterProps.filterType === el.tag}
                onClick={() => handleType(el.tag)}
              />
              <label
                for={`type${i}`}
                className={filterProps.filterType === el.tag ? 'filter-label-selected' : ''}
              >
                {el.name}
              </label>
            </li>
          ))}
        </ul>
        <h2>Fitur Kelas</h2>
        <ul>
          {filterPricing.map((el, i) => (
            <li>
              <input
                id={`pricing${i}`}
                type="checkbox"
                checked={filterProps.filterArray.pricing[0] === i + 1}
                onClick={() => handlePricing(i + 1)}
              />
              <label
                for={`pricing${i}`}
                className={
                  filterProps.filterArray.pricing[0] === i + 1 ? 'filter-label-selected' : ''
                }
              >
                {el}
              </label>
            </li>
          ))}
        </ul>
        <h2>Kategori Kelas</h2>
        <ul>
          {filterKategori.map((el, i) => (
            <li>
              <input
                id={`category${i}`}
                type="checkbox"
                checked={filterProps.categoryType === el.id}
                onClick={() => handleCategory(el.id)}
              />
              <label
                for={`category${i}`}
                className={filterProps.categoryType === el.id ? 'filter-label-selected' : ''}
              >
                {el.category_name}
              </label>
            </li>
          ))}
        </ul>
        <h2>Segera Mendaftar!</h2>
        <img
          className="pointer mb-2"
          src="/images/KratuPrakreja.jpg"
          alt="Kratu Prakreja Program"
          onClick={(e) => window.open('https://www.prakerja.go.id/', '_blank')}
        />
      </div>
    </div>
  );
};

const AllCourseList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const scrollRef = useRef(null);
  const courseList = useSelector((state) => state.all_course_list);
  const [courseLoading, setCourseLoading] = useState(false);
  const [initialScroll, setInitialScroll] = useState(false);

  // QUERIES
  const pageQuery = +useQuery().get('page');
  const categoryQuery = useQuery().get('category');

  // STATE
  const [pageSelected, setPageSelected] = useState(pageQuery ? pageQuery - 1 : 0);
  const [categoryType, setCategoryType] = useState(categoryQuery ? categoryQuery : null);
  const [filterArray, setFilterArray] = useState({ filter: 'or', tags: [], sort: 5, pricing: [] });
  const [filterType, setFilterType] = useState('all');
  const [filterCount, setFilterCount] = useState(0);

  // PROPS TO PASS
  const eraseFilter = () => {
    setFilterArray({ filter: 'or', tags: [], sort: 5, pricing: [] });
    setFilterType('all');
    setPageSelected(0);
    setCategoryType(null);
  };
  const filterProps = {
    categoryType,
    setCategoryType,
    setPageSelected,
    setFilterArray,
    filterArray,
    filterType,
    setFilterType,
    eraseFilter,
    filterCount,
  };

  // APIS
  const filterCourseList = () => {
    setCourseLoading(true);
    const access_token = localStorage.getItem('access_token');
    axios
      .post(
        `${window.$API_URL}api/course/filter?page=${pageSelected + 1}&type=${filterType}${
          categoryType ? '&category_id=' + categoryType : ''
        }`,
        // `${window.$API_URL}api/course/filter?page=${pageSelected + 1}&type=${filterType}${categoryType ? '&category_id='+categoryType : ''}`, {},
        filterArray,
        {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
        },
      )
      .then((success) => {
        setCourseLoading(false);
        dispatch({
          type: 'UPDATE_ALL_COURSE_LIST',
          payload: success.data.data,
        });
        dispatch({
          type: 'UPDATE_COURSE_RESULT',
          payload: {
            result_from: success.data.from,
            result_to: success.data.to,
            total_page: success.data.last_page,
            next_page_url: success.data.next_page_url,
            prev_page_url: success.data.prev_page_url,
            current_page: success.data.current_page,
            total_data: success.data.total,
          },
        });
      })
      .catch((error) => {
        setCourseLoading(false);
      });
  };

  // LIFECYCLES
  useEffect(() => {
    let filterCountTemp = 0;
    if (filterArray.pricing[0]) filterCountTemp++;
    if (filterArray.name) filterCountTemp++;
    if (filterType !== 'all') filterCountTemp++;
    if (categoryType) filterCountTemp++;
    setFilterCount(filterCountTemp);
    filterCourseList();
  }, [filterArray, pageSelected, categoryType, filterType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    history.push({
      search: `?page=${pageSelected + 1}&type=${filterType}${
        categoryType ? '&category=' + categoryType : ''
      }`,
    });
    if (initialScroll) scrollRef.current.scrollIntoView();
    else {
      window.scrollTo(0, 0);
      setInitialScroll(true);
    }
  }, [pageSelected, categoryType, filterArray, filterType]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="all-course-new">
        <div>
          <h1 ref={scrollRef}>Semua Kelas</h1>
          <CourseCount />
          <FilterTop filterProps={filterProps} />
          <div className="all-course-new__wrapper">
            <MediaQuery minDeviceWidth={992}>
              <Filter filterProps={filterProps} />
            </MediaQuery>
            <div
              className={
                !courseLoading && courseList && !(courseList[0] || courseList['0'])
                  ? 'all-course-not-found all-course-new__wrapper__inside'
                  : 'all-course-new__wrapper__inside'
              }
            >
              {!courseLoading
                ? courseList
                  ? courseList[0] !== undefined
                    ? courseList.map((el) => (
                        <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-12 mh-100 all-course__class-list-wrapper">
                          <Card data={el} notSlider={true} />
                        </div>
                      ))
                    : Object.keys(courseList).map((keyName, i) => (
                        <div
                          className="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-12 mh-100 all-course__class-list-wrapper"
                          key={i}
                        >
                          <Card data={courseList[keyName]} notSlider={true} />
                        </div>
                      ))
                  : ''
                : [0, 1, 2, 3, 4, 5].map((el) => (
                    <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-12 mh-100 all-course__class-list-wrapper">
                      <ContentLoader
                        speed={2}
                        width={'100%'}
                        height={'100%'}
                        viewBox="0 0 400 471"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        {...props}
                      >
                        <rect x="0" y="0" rx="2" ry="2" width="100%" height="100%" />
                      </ContentLoader>
                    </div>
                  ))}
            </div>
          </div>
          <CoursePagination pageSelected={pageSelected} setPageSelected={setPageSelected} />
        </div>
      </div>
    </>
  );
};

const CoursePagination = ({ pageSelected, setPageSelected }) => {
  const all_course_result = useSelector((state) => state.all_course_result);
  return all_course_result !== null ? (
    all_course_result.total_page ? (
      <div className="d-flex justify-content-center align-items-center col-12 pagination-wrapper">
        <p className="mr-4 navbar-desc">Menampilkan Halaman</p>
        <div className="awal-akhir-btn mr-2" onClick={(e) => setPageSelected(0)}>
          Awal
        </div>
        <ReactPaginate
          previousLabel={
            <div className="pagi-li">
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.677344 5.777C0.677344 5.95703 0.751476 6.12648 0.878559 6.25356L5.623 10.998C5.75009 11.1251 5.91953 11.1992 6.09957 11.1992C6.47023 11.1992 6.77734 10.8921 6.77734 10.5214L6.77734 1.03255C6.77734 0.661893 6.47023 0.354774 6.09957 0.354774C5.91953 0.354774 5.75009 0.428906 5.623 0.55599L0.878559 5.30043C0.751476 5.42752 0.677344 5.59696 0.677344 5.777Z"
                  fill="#FF0F51"
                />
              </svg>
            </div>
          }
          nextLabel={
            <div className="pagi-li">
              <svg
                width="7"
                height="11"
                viewBox="0 0 7 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.1 5.42222C6.1 5.24219 6.02587 5.07274 5.89878 4.94566L1.15434 0.201215C1.02726 0.0741319 0.857813 0 0.677778 0C0.307118 0 0 0.307118 0 0.677778V10.1667C0 10.5373 0.307118 10.8444 0.677778 10.8444C0.857813 10.8444 1.02726 10.7703 1.15434 10.6432L5.89878 5.89878C6.02587 5.7717 6.1 5.60226 6.1 5.42222Z"
                  fill="#FF0F51"
                />
              </svg>
            </div>
          }
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={all_course_result.total_page}
          marginPagesDisplayed={1}
          pageRangeDisplayed={1}
          // onPageChange={this.handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'pageactive'}
          pageClassName={'pageclass'}
          initialPage={pageSelected}
          forcePage={pageSelected}
          onPageChange={(data) => setPageSelected(data.selected)}
        />
        <div
          className="awal-akhir-btn ml-2"
          onClick={(e) => setPageSelected(all_course_result.total_page - 1)}
        >
          Akhir
        </div>
      </div>
    ) : (
      ''
    )
  ) : (
    ''
  );
};

export default AllCourseList;
