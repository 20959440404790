import styled from 'styled-components';
import { FooterBanner, MobileFooter } from '../../../image/InternPage';

export const TopNav = styled.div`
    display: flex;
    font-size: 14px;
    color: #20282E;
    width: 85%;
    margin: 32px auto;
    font-family: Poppins !important;
    .polygon {
        margin: 0 12px;
    }
    .link {
        color: #004F8A;
    }
    @media (max-width: 992px) {
        font-size: 12px;
        width: 90%;
        max-width: 400px;
        margin: 32px auto 0 auto;
        .back {
            margin-right: 16px;
        }
    }
    @media (min-width: 1400px) {
        max-width: 1400px;
    }
`;

export const ImageSection = styled.div`
    display: flex;
    padding: 32px 0;
    width: 85%;
    margin: auto;
    position: relative;
    .clip {
        position: absolute;
        top: 0;
        left: 0;
    }
    .avatar {
        border-radius: 20px;
        background: #C4C4C4;
        margin-right: 32px;
        width: 244px;
        height: 244px;
    }
    .text {
        font-family: Poppins !important;
        width: 62.5%;
        p {
            font-size: 20px;
            color: #20282E;
        }
        .nama {
            font-size: 30px;
            font-weight: 700;
        }
        .school-onjob {
            background: #FCFCFC;
            border: 1px solid #DDDDDD;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 12px 24px;
            margin-top: 16px;
            display: flex;
            .school {
                width: 45%;
            }
            .line {
                content: "";
                background-color: #DDDDDD;
                width: 2px;
                margin: 0 24px;
                height: 100px;
                display: block;
            }
            p {
                font-size: 16px;
                color: #20282E;
            }
            .title {
                margin-top: 4px;
                font-weight: 600;
            }
        }
    }
    @media (max-width: 992px) {
        display: block;
        text-align: center;
        width: 90%;
        max-width: 400px;
        margin: 0 auto;
        .avatar {
            margin: 0 auto;
            width: 100%;
            max-width: 400px;
            height: 100%;
        }
        .text {
            width: 100%;
            text-align: left;
            padding-top: 16px;
            p {
                font-size: 12px;
            }
            .nama {
                font-size: 16px;
            }
            .school-onjob {
                display: block;
                padding: 16px 16px 4px 16px;
                .container {
                    margin-bottom: 12px;
                    padding: 0;
                    display: flex;
                    justify-content: space-between;
                    p {
                        font-size: 12px;
                    }
                }
            }
        }
    }
    @media (min-width: 1400px) {
        max-width: 1400px;
        .text {
            width: 65%;
        }
    }
`;

export const QuestionSection = styled.div`
    width: 85%;
    margin: auto;
    .question {
        font-family: Poppins !important;
        color: #20282E;
        margin-bottom: 32px;
        width: 90%;
        min-width: 800px;
        max-width: 1200px;
        .title {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 8px;
        }
        .content {
            font-size: 16px;
            text-align: left;
        }
    }
    .container {
        width: 50%;
        margin: 0 auto 32px auto;
        text-align: center;
    }
    @media (max-width: 992px) {
        width: 90%;
        max-width: 400px;
        .question {
            min-width: 0;
            width: 100%;
            .title {
                font-size: 16px;
            }
            .content {
                font-size: 12px;
            }
        }
        .container {
            width: 100%;
            max-width: 400px;
        }
        .share {
            margin: 0 auto 32px 0;
            text-align: center;
            display: flex;
            p {
                color: #20282E;
                font-size: 16px;
                font-weight: 700;
                margin: 4px 6px 0 0;
            }
            .icon {
                width: 10%;
                margin: 6px 0;   
                img {
                    cursor: pointer;
                }
            }
        }
    }
    @media (min-width: 1400px) {
        max-width: 1400px;
    }
`;

export const ShareStories = styled.div`
    z-index: 100;
    position: absolute;
    height: 1200px;
    width: 92.5%;
    top: 188px;
    left: 50%;
    transform: translate(-50%, 0);
    .share {
        width: 70px;
        margin: 0 0 0 auto;
        font-family: Poppins !important;
        position: sticky;
        top: 80px;
        text-align: center;
        p {
            color: #20282E;
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 6px;
        }
        .icon {
            width: 100%;
            margin: 6px 0;   
            img {
                cursor: pointer;
            }
        }
    }
    @media (min-width: 1200px) {
        width: 85%;
        max-width: 1300px;
    }
`;

export const InterestingStories = styled.div`
    background: #F5F5F5;
    text-align: center;
    padding: 32px 0;
    font-family: Poppins !important;
    .top {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 32px;
        color: #20282E;
    }
    .stories {
        margin: auto;
        .intern {
            width: 100%;
            margin: 0 auto 32px auto;
            cursor: pointer;

            .avatar {
                width: 100%;
                height: 240px;
                background: #C4C4C4;
                border-radius: 20px;
            }
            .nama {
                text-align: left;
                margin-top: 8px;
                font-weight: 600;
                font-size: 18px;
                color: #20282E;
            }
            .role {
                text-align: left;
                font-size: 16px;
                color: #5B7080;
            }
        }
        .carousel-button-group {
            bottom: 240px !important;
        }
        .react-multiple-carousel__arrow {
            position: absolute !important;
        }
        .react-multiple-carousel__arrow--right {
            right: 0px !important;
        }
        .react-multi-carousel-dot button {
            border: 0;
            background-color: #FFF;
        }
        .react-multi-carousel-dot--active button {
            background-color: #20282E;
        }
        .react-multi-carousel-dot button:hover:active {
            background-color: #20282E;
        }

        @media (min-width: 993px) {
            width: 550px;
        }
        @media (min-width: 1201px) {
            width: 825px;
        }
        @media (min-width: 1601px) {
            width: 1100px;
        }
    }
    @media (max-width: 992px) {
        .top {
            font-size: 16px;
        }
        .stories {
            width: 100%;
            max-width: 480px;
            margin: auto;
            a {
                width: 60%;
            }
            .intern {
                .avatar {
                    height: 100%;
                }
                .nama {
                    font-size: 16px;
                }
                .role {
                    font-size: 12px;
                }
            }
        }
    }
`;

export const BottomBanner = styled.div`
    background-image: url(${FooterBanner});
    background-repeat: no-repeat;
    div {
        font-family: Poppins !important;
        width: 620px;
        margin: auto;
        padding: 48px;
        text-align: center;
        h1 {
            font-family: Poppins !important;
            font-size: 24px;
            font-weight: 600;
            color: #FFFFFF;
        }
        p {
            font-size: 18px;
            color: #FFFFFF;
        }
        .custom-btn {
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            background: #D70C24;
            color: #FFFFFF;
            margin-top: 24px;
            padding: 8px 48px;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            border: 0;
            cursor: pointer;
            outline: none;
        }
        .custom-btn:hover {
            filter: brightness(90%);
        }
    }

    @media (max-width: 992px) {
        background-image: url(${MobileFooter});
        background-size: cover;
        div {
            width: 90%;
            padding: 48px 0;
            h1 {
                font-size: 16px;
            }
            p {
                font-size: 12px;
            }
            .custom-btn {
                font-size: 12px;
                width: 100%;
                max-width: 360px;
            }
        }
    }

    @media (min-width: 1400px) {
        background-size: 100% auto;
    }
`;