import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header/Header';
import './privacy.scss';
class Privacy extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <Header />
        <div className="terms-privacy-page container-mesx terms-and-privacy">
          <h1>Kebijakan Privasi</h1>

          <div className="">
            <h1 className="">KEBIJAKAN PRIVASI MYEDUSOLVE</h1>

            <p className="">
              Terima kasih telah memilih MyEduSolve sebagai pilihan Anda untuk solusi pembelajaran
              online. Kami di MyEduSolve ("MyEduSolve", "MyEduSolveX", "kami") sangat menghargai
              privasi Anda dan ingin Anda mengerti bahwa komitmen kami adalah untuk melindungi
              privasi Anda. Kami juga sangat serius dalam melindungi data dan privasi Anda.
              Kebijakan Privasi ini ("Kebijakan") menjelaskan bagaimana MyEduSolve mengumpulkan,
              menggunakan, membagikan, dan menjaga keamanan informasi pribadi yang Anda berikan
              ketika Anda mengunjungi Situs Web kami dan ketika Anda menggunakan Layanan kami,
              kecuali jika kami menyatakan lain dengan menghubungkan ke situs web lain yang memiliki
              kebijakan berbeda atau sebaliknya.
            </p>

            <p>
              Kebijakan Privasi ini juga menjelaskan pilihan Anda terkait penggunaan, akses, dan
              koreksi informasi pribadi Anda. Penggunaan informasi yang kami kumpulkan melalui
              Layanan kami dibatasi hanya untuk tujuan menyediakan layanan yang Anda daftarkan
              bersama MyEduSolve. Istilah yang digunakan dalam Kebijakan ini namun tidak
              didefinisikan akan memiliki makna yang sama seperti yang didefinisikan dalam Ketentuan
              Layanan kami di ("Ketentuan").
            </p>

            <p>
              Mohon baca Kebijakan ini dengan seksama, karena ini mengatur cara Anda menggunakan
              MyEduSolve atau produk afiliasi kami. Dengan menggunakan Layanan, Anda menyetujui
              syarat dan ketentuan yang terdapat dalam Kebijakan ini. Anda tidak boleh menggunakan
              Layanan jika Anda tidak setuju dengan Kebijakan ini atau perjanjian lain yang mengatur
              penggunaan Anda terhadap Layanan.
            </p>
          </div>

          <div className="">
            <h1 className="">PEMBERITAHUAN KEPADA PENGGUNA</h1>
            <p>
              Dalam situasi tertentu, seorang administrator mungkin membuat akun di MyEduSolve atas
              nama seorang pengguna, dengan menyertakan informasi pribadi mereka. Dalam konteks ini,
              administrator tersebut, yang bisa jadi adalah pemberi kerja Anda atau salah satu mitra
              pelatihan resmi MyEduSolve, berperan sebagai "Pelanggan" kami. Kami mengumpulkan
              informasi ini berdasarkan permintaan pelanggan kami dan tidak memiliki kontak langsung
              dengan pengguna akhir yang data pribadinya kami olah.
            </p>

            <p>
              Penggunaan platform MyEduSolve oleh pengguna mungkin diatur sesuai dengan kebijakan
              yang diterapkan oleh Pelanggan kami, jika ada.
            </p>

            <p>
              Pelanggan kami harus memiliki hak yang sah untuk bertindak mewakili pengguna dan
              memberikan izin untuk mengumpulkan serta menggunakan informasi pribadi mereka,
              sebagaimana dijelaskan dalam Kebijakan Privasi ini.
            </p>

            <p>
              Bagi Anda yang merupakan pengguna akhir platform kami, disarankan untuk menghubungi
              administrator Anda untuk segala pertanyaan terkait privasi. MyEduSolve tidak
              bertanggung jawab atas kebijakan privasi atau keamanan yang diterapkan oleh pelanggan
              kami, yang mungkin berbeda dari yang tercantum dalam kebijakan privasi kami.
            </p>

            <p>
              Kami di MyEduSolve tidak mengirimkan iklan personalisasi atau materi pemasaran kepada
              pengguna akhir kami.
            </p>
          </div>

          <div className="">
            <h1>TRANSFER INTERNASIONAL</h1>
            <p>
              MyEduSolve mengumpulkan data pribadi yang mungkin tidak hanya diproses di wilayah
              Anda, tapi juga di berbagai negara lain tempat MyEduSolve atau mitra dan subkontraktor
              kami beroperasi. Dalam rangka kegiatan global kami, informasi tersebut dapat diakses
              dari manapun di dunia, termasuk negara-negara dimana kami memiliki kegiatan bisnis.
              Meskipun data Anda dipindahkan ke negara lain, Kebijakan Privasi ini tetap berlaku.
            </p>

            <p>
              Selanjutnya, data yang kami pegang mungkin diolah oleh staf kami yang berada di
              berbagai negara di mana kami memiliki operasi. Staf kami mungkin terlibat dalam
              berbagai kegiatan, termasuk pengembangan produk serta dukungan pelanggan dan teknis.
              Harap diingat, perlindungan data dan hukum di negara-negara tersebut mungkin tidak
              sama rinci atau ketatnya dengan di negara Anda. Dengan mengirimkan data Anda dan
              menggunakan Layanan kami, Anda memberikan persetujuan terhadap pemindahan,
              penyimpanan, dan pengolahan data Anda di luar negara asal Anda.
            </p>
          </div>

          <div className="">
            <h1>DATA YANG KAMI PEROLEH</h1>
            <p>
              Kami mengumpulkan beberapa data dari Anda secara langsung, seperti informasi yang Anda
              masukkan sendiri, data tentang partisipasi Anda dalam kursus, dan data dari platform
              pihak ketiga yang Anda hubungkan dengan MyEduSolve. Kami juga mengumpulkan beberapa
              data secara otomatis, seperti informasi tentang perangkat Anda dan bagian-bagian dari
              Layanan kami yang Anda interaksikan atau habiskan waktu untuk menggunakannya.
            </p>

            <p>
              Kami dapat mengumpulkan informasi pribadi berikut dari Anda dalam situasi-situasi
              berikut:
            </p>

            <p>
              Ketika Anda mengunjungi situs web atau aplikasi seluler kami dan dengan sukarela
              memberikan informasi berikut melalui salah satu formulir kontak kami, melalui sesi
              obrolan atau telepon, atau sebagai bagian dari pembelian salah satu kursus kami atau
              mendaftar di situs kami:
            </p>

            <ul>
              <li>Informasi Kontak, seperti nama, alamat email, alamat surat, nomor telepon, IP</li>
              <li>
                Informasi tentang bisnis Anda, seperti nama perusahaan, ukuran perusahaan, jenis
                bisnis
              </li>
              <li>Informasi Penagihan, seperti nomor kartu kredit dan alamat penagihan;</li>
            </ul>

            <p>
              Catatan : Semua transaksi pembayaran diproses melalui penyedia gateway pembayaran yang
              aman. Kami tidak menyimpan informasi kartu (selain 4 digit terakhir kartu Anda) di
              server kami.
            </p>

            <p>
              Ketika Anda menggunakan Sistem Manajemen Pembelajaran kami, aplikasi seluler kami,
              sistem dukungan kami, atau merespons survei kami:
            </p>

            <p>
              Informasi Kontak, seperti nama, alamat email, alamat surat, alamat IP, lokasi
              geografis, atau nomor telepon;
            </p>

            <p>Pengidentifikasi Unik, seperti nama pengguna, atau kata sandi;</p>

            <p>Nama dan alamat email ketika Anda memberikan umpan balik dari Layanan</p>

            <p>Ketika Anda menggunakan situs web komunitas atau blog kami</p>

            <p>Silakan lihat bagian kami tentang Forum Publik</p>

            <p>
              Pengguna dapat, bagaimanapun, mengunjungi Situs kami secara anonim. Kami hanya akan
              mengumpulkan informasi identifikasi pribadi dari Pengguna jika mereka secara sukarela
              mengirimkan informasi tersebut kepada kami. Pengguna selalu dapat menolak untuk
              menyediakan informasi identifikasi pribadi, kecuali itu dapat mencegah mereka dari
              terlibat dalam beberapa aktivitas terkait Situs.
            </p>

            <p>
              Kami juga menangkap beberapa data yang tidak dapat diidentifikasi dalam log kami. Data
              dalam log termasuk
            </p>

            <ul>
              <li>Jenis perangkat</li>
              <li>Jenis peramban</li>
              <li>preferensi bahasa,</li>
              <li>zona waktu,</li>
              <li>ukuran layar,</li>
              <li>halaman referensi / keluar.</li>
            </ul>
          </div>

          <div>
            <h1>USE (PENGGUNAAN)</h1>
            <p>
              KAMI DAPAT MENGGUNAKAN INFORMASI PRIBADI YANG KAMI KUMPULKAN DARI SITUS WEB ATAU
              KETIKA ANDA MENGGUNAKAN LAYANAN KAMI UNTUK TUJUAN-TUJUAN BERIKUT:
            </p>
            <ol>
              <li>
                Menyediakan dan mengelola Layanan, termasuk menyesuaikan konten dan memudahkan
                komunikasi dengan pengguna lain.
              </li>
              <li>
                Memproses permintaan dan pesanan Anda terkait kursus, produk, layanan spesifik,
                informasi, atau fitur tertentu.
              </li>
              <li>
                Berkomunikasi dengan Anda mengenai akun Anda dengan cara:Menanggapi pertanyaan dan
                kekhawatiran Anda.Mengirim pesan administratif dan informasi, termasuk pesan dari
                instruktur dan asisten pengajaran, notifikasi tentang perubahan Layanan, serta
                pembaruan pada perjanjian kami.Mengirimkan informasi dan pesan dalam aplikasi
                mengenai kemajuan Anda dalam kursus, program penghargaan, layanan baru, fitur baru,
                promosi, buletin, dan kursus lain yang tersedia (yang dapat Anda opt-out kapan
                saja).Mengirim pemberitahuan push ke perangkat nirkabel Anda untuk memberikan
                pembaruan dan pesan relevan lainnya (yang dapat Anda atur melalui halaman 'opsi'
                atau 'pengaturan' pada aplikasi seluler).
              </li>
              <li>Mengelola preferensi akun Anda.</li>
              <li>
                Memfasilitasi fungsi teknis Layanan, termasuk pemecahan masalah, menyelesaikan
                masalah, mengamankan Layanan, serta mencegah kecurangan dan penyalahgunaan.
              </li>
              <li>Mengumpulkan umpan balik dari pengguna.</li>
              <li>
                Memasarkan dan mengelola survei serta promosi yang diadakan atau disponsori oleh
                MyEduSolve.
              </li>
              <li>
                Belajar lebih banyak tentang Anda dengan menggabungkan data Anda dengan data
                tambahan melalui penyedia data pihak ketiga atau analisis data dengan bantuan
                penyedia layanan analitik.
              </li>
              <li>Mengidentifikasi pengguna unik di berbagai perangkat.</li>
              <li>Menyesuaikan iklan di berbagai perangkat.</li>
              <li>Meningkatkan Layanan kami dan mengembangkan produk, layanan, dan fitur baru.</li>
              <li>
                Menganalisis tren dan lalu lintas, melacak pembelian, dan memantau data penggunaan.
              </li>
              <li>Mengiklankan Layanan kami di situs web dan aplikasi pihak ketiga.</li>
              <li>Sebagaimana yang diwajibkan atau diizinkan oleh hukum.</li>
              <li>
                Atau sebagaimana yang kami tentukan perlu, atas kebijakan kami sendiri, untuk
                memastikan keselamatan atau integritas pengguna kami, karyawan, pihak ketiga,
                masyarakat, atau Layanan kami.
              </li>
            </ol>
          </div>

          <div className="">
            <h1>SHARE (BERBAGI)</h1>
            <p>
              Jika langganan Anda diperoleh melalui pemberi kerja Anda atau mitra pelatihan yang
              telah mendapatkan otorisasi dari MyEduSolve, kami akan berbagi informasi Anda,
              termasuk data pribadi, dengan mereka.
            </p>

            <p>
              Kami mungkin mengirimkan informasi pribadi Anda ke perusahaan lain dalam grup
              MyEduSolve dan kepada subkontraktor yang membantu kami dalam menyediakan Layanan.
              Transfer informasi ke pihak ketiga ini diatur oleh perjanjian kami dengan pemroses
              data. Kami juga berhak membuka data pribadi Anda sesuai dengan hukum yang berlaku,
              sebagai tanggapan atas permintaan resmi dari otoritas publik, termasuk untuk tujuan
              keamanan nasional atau penegakan hukum, serta ketika kami merasa perlu untuk
              melindungi hak-hak kami, atau untuk mematuhi prosedur hukum, perintah pengadilan, atau
              proses hukum lainnya yang berlaku terhadap kami.
            </p>

            <p>
              Jika terjadi perubahan besar pada MyEduSolve, seperti merger, diakuisisi oleh
              perusahaan lain, atau penjualan sebagian atau seluruh aset kami, akun dan data pribadi
              pengguna kemungkinan akan termasuk dalam aset yang dipindahkan. Anda akan diberitahu
              melalui email dan pengumuman yang jelas di situs web kami tentang perubahan
              kepemilikan atau pengendalian seperti itu. Selain itu, kami dapat membagikan data
              pribadi Anda kepada pihak ketiga lainnya dengan persetujuan Anda yang diberikan
              terlebih dahulu.
            </p>
          </div>

          <div className="">
            <h1>DATA DARI PIHAK KETIGA</h1>
            <p>
              Kami juga memperoleh data dari berbagai sumber pihak ketiga. Data ini kami lindungi
              sesuai dengan kebijakan privasi yang kami terapkan, ditambah dengan pembatasan
              tambahan dari sumber data tersebut. Sumber pihak ketiga ini dapat berubah seiring
              waktu, namun umumnya meliputi:
            </p>
            <ol>
              <li>
                Broker data, dari mana kami membeli data demografis untuk melengkapi informasi yang
                telah kami kumpulkan.
              </li>
              <li>
                Jaringan sosial, ketika Anda memberi izin kepada kami untuk mengakses data Anda di
                satu atau lebih jaringan tersebut.
              </li>
              <li>Peserta yang telah atau sedang mengikuti program rujukan kami.</li>
              <li>
                Penyedia layanan yang membantu kami menentukan lokasi Anda berdasarkan alamat IP,
                untuk menyesuaikan produk kami sesuai lokasi Anda.
              </li>
              <li>
                Mitra yang bekerja sama dengan kami dalam menyediakan layanan bersama atau kegiatan
                pemasaran bersama.
              </li>
              <li>
                Sumber yang dapat diakses oleh publik, seperti basis data pemerintah yang terbuka
                atau data lainnya yang tersedia secara publik.
              </li>
            </ol>
            <p>
              Selain itu, kami juga mungkin menerima Informasi Pribadi tentang Anda dari sumber lain
              seperti surat, telepon, atau faks. Informasi ini kemudian kami gabungkan dengan data
              yang telah kami kumpulkan dari Anda melalui situs kami, guna meningkatkan produk dan
              layanan yang kami tawarkan.
            </p>
          </div>

          <div className="">
            <h1>KOREKSI, PENGHAPUSAN, ATAU EKSPOR INFORMASI PRIBADI</h1>
            <p>
              MyEduSolve mengakui hak Anda untuk mengakses, mengedit, dan menghapus informasi
              pribadi Anda setiap saat. Berdasarkan permintaan, kami akan menyediakan akses yang
              memadai kepada informasi pribadi yang telah kami kumpulkan tentang Anda. Anda
              berkesempatan untuk memperbaiki, memperbarui, mengubah, atau menghapus informasi
              tersebut dengan cara berikut: Jika Anda mendaftar di MyEduSolve secara mandiri, Anda
              dapat mengakses, memperbarui, atau mengunduh Informasi Pribadi Anda yang ada di akun
              MyEduSolve Anda kapan saja dengan masuk ke akun Anda dan mengunjungi profil Anda, atau
              dengan menghubungi kami.
            </p>
            <p>
              Jika Anda menggunakan akun yang diberikan oleh perusahaan Anda (Mitra Pelatihan yang
              Diotorisasi MyEduSolve atau Pemberi Kerja Anda)Mohon rujuk ke bagian Pemberitahuan
              kepada Pengguna Akhir.
            </p>
            <p>
              Pengecualian dalam Penghapusan Informasi: Apabila Anda mengakses informasi pribadi
              Anda dan menemukan bahwa ada kesalahan, kami berupaya menyediakan cara untuk Anda
              memperbarui atau menghapusnya dengan cepat - kecuali kami perlu menyimpan informasi
              tersebut untuk kepentingan bisnis yang sah atau alasan hukum. Kami berusaha menjaga
              layanan kami agar terlindungi dari penghapusan yang tidak sengaja atau disengaja.
              Karena itu, setelah Anda menghapus informasi dari layanan kami, mungkin ada penundaan
              sebelum penghapusan penuh terjadi dari server aktif kami atau sistem cadangan.
            </p>
            <p>
              Pengecualian dalam Portabilitas Data: Terdapat batas dalam jumlah informasi yang dapat
              kami berikan. Misalnya, kami dapat membatasi akses individu terhadap informasi pribadi
              jika biaya atau usaha untuk memberikan akses tidak sebanding dengan risiko privasi
              individu, atau jika hal itu akan melanggar hak orang lain.
            </p>
            <p>
              Pengecualian dalam Koreksi Data: Bahkan setelah Anda memperbarui Informasi Pribadi
              Anda, kami mungkin masih menyimpan versi lama dari informasi tersebut dalam basis data
              kami, log akses, dan catatan lainnya untuk mematuhi keperluan audit data.
            </p>
          </div>

          <div className="">
            <h1>KEBIJAKAN KAMI TENTANG ANAK-ANAK</h1>

            <p>
              Kami di MyEduSolve sangat memperhatikan privasi anak-anak dan mendorong orang tua
              serta wali untuk terlibat aktif dalam kegiatan online anak-anak mereka. Layanan kami
              tidak ditujukan untuk anak-anak di bawah usia 13 tahun. Apabila kami mengetahui bahwa
              kami telah tanpa sengaja mengumpulkan data pribadi dari anak di bawah usia tersebut,
              kami akan mengambil tindakan yang diperlukan untuk menghapus data tersebut secepat
              mungkin.
            </p>

            <p>
              Kami mengajak orang tua dan wali hukum untuk aktif memantau dan mengawasi penggunaan
              internet anak-anak mereka. Penting bagi mereka untuk membantu menegakkan kebijakan
              privasi kami dengan menginstruksikan anak-anak untuk tidak memberikan informasi
              pribadi melalui Layanan atau Situs Web kami tanpa persetujuan mereka.
            </p>

            <p>
              Orang tua yang yakin bahwa MyEduSolve mungkin telah mengumpulkan data pribadi dari
              anak di bawah usia yang ditentukan dapat menghubungi kami untuk meminta penghapusan
              data tersebut.
            </p>
          </div>

          <div className="">
            <h1>COOKIE DAN TEKNOLOGI PENELUSURAN LAINNYA</h1>
            <p>
              Cookie adalah file teks kecil yang disimpan oleh browser Anda ketika Anda menjelajah
              internet. Cookie ini berperan dalam mengumpulkan, menyimpan, dan membagikan data
              tentang kegiatan Anda di berbagai situs web, termasuk MyEduSolve. Selain itu, cookie
              membantu kami mengingat preferensi Anda, seperti pilihan bahasa, sehingga pengalaman
              menjelajah di MyEduSolve menjadi lebih nyaman.
            </p>

            <p>
              Kami menggunakan dua jenis cookie: cookie sesi dan cookie persisten. Cookie sesi
              kedaluwarsa dalam waktu singkat atau ketika Anda menutup browser, sementara cookie
              persisten tetap ada di browser Anda untuk periode tertentu. Cookie sesi membantu
              mengidentifikasi Anda selama satu sesi penjelajahan, misalnya saat Anda login ke
              MyEduSolve. Cookie persisten berguna ketika kami perlu mengenali Anda untuk jangka
              waktu yang lebih lama, seperti ketika Anda meminta kami untuk tetap menjaga Anda tetap
              masuk (login).
            </p>

            <p>
              Anda dapat mengatur browser Anda untuk memberi tahu Anda setiap kali cookie dikirim,
              atau Anda dapat memilih untuk menonaktifkan semua cookie. Cara ini dapat diatur
              melalui menu pengaturan pada browser Anda. Ingat, setiap browser memiliki cara yang
              berbeda, jadi periksalah menu Bantuan browser Anda untuk informasi lebih lanjut.
            </p>

            <p>
              Jika Anda memilih untuk menonaktifkan cookie, perlu diingat bahwa beberapa fitur yang
              membuat situs lebih efisien mungkin tidak berfungsi dengan baik.
            </p>
          </div>

          <div className="">
            <h1>IKLAN</h1>
            <p>
              Kami bekerja sama dengan pihak ketiga untuk mengelola iklan kami di situs lain. Mitra
              kami ini menggunakan teknologi seperti cookie untuk mengumpulkan informasi tentang
              aktivitas Anda di Situs Web kami dan situs lain, guna menyajikan iklan yang
              disesuaikan dengan minat dan kebiasaan penjelajahan Anda. Jika Anda tidak ingin
              informasi ini digunakan untuk menyajikan iklan berdasarkan minat, Anda dapat memilih
              untuk tidak terlibat melalui tautan yang kami sediakan. Namun, ini tidak akan
              menghentikan Anda dari menerima iklan secara umum. Anda akan tetap menerima iklan
              generik.
            </p>
          </div>

          <div className="">
            <h1>ANALITIK & WEB-BEACONS</h1>
            <p>
              MyEduSolve menggunakan perangkat lunak analitik dari pihak ketiga. Semua data metrik
              yang kami kumpulkan dari penggunaan Anda terhadap Layanan akan dikirimkan ke penyedia
              layanan analitik kami. Data ini kemudian digunakan untuk mengevaluasi bagaimana
              pengguna interaksi dengan platform kami, dan untuk menyusun laporan statistik tentang
              aktivitas pengguna. Kami juga menggunakan alat analitik ini untuk melacak atau
              mengumpulkan Informasi Identitas Pribadi (PII) Anda. Informasi ini akan digunakan
              untuk memperbaiki, meningkatkan, atau menambah fungsionalitas Layanan, serta untuk
              mempersonalisasi pengalaman Anda. Informasi dan analisis yang teragregasi ini mungkin
              dibuat publik oleh MyEduSolve jika dianggap perlu.
            </p>

            <p>
              Kami dan mitra periklanan pihak ketiga kami menggunakan teknologi seperti web beacons
              untuk menganalisis tren, mengelola situs web, melacak gerakan pengguna di situs, dan
              mengumpulkan informasi demografis tentang keseluruhan basis pengguna kami. Kami
              mungkin menerima laporan berdasarkan penggunaan teknologi ini oleh
              perusahaan-perusahaan ini baik secara individu maupun secara agregat.
            </p>
          </div>

          <div className="">
            <h1>DO NOT TRACK.</h1>
            <p>
              Saat ini, beberapa browser seperti Internet Explorer, Firefox, dan Safari, menyediakan
              fitur "jangan lacak" atau "DNT" melalui header DNT. Header ini mengirim sinyal ke
              situs web yang dikunjungi pengguna tentang preferensi DNT browser tersebut. Saat ini,
              MyEduSolve belum berkomitmen untuk merespons sinyal DNT dari browser, terutama karena
              belum ada standar umum untuk DNT yang diakui oleh industri, perusahaan teknologi, atau
              regulator, termasuk tidak adanya standar yang konsisten untuk menginterpretasikan niat
              pengguna. MyEduSolve mengambil serius privasi dan pilihan pengguna, dan akan terus
              memantau perkembangan terkait teknologi DNT dan standar yang mungkin diadopsi di masa
              depan.
            </p>
          </div>

          <div className="">
            <h1>TAUTAN KE SITUS PIHAK KETIGA</h1>
            <p>
              Situs Web kami mengandung tautan ke situs web lain yang bukan milik atau dikontrol
              oleh MyEduSolve. Kami ingin Anda menyadari bahwa kami tidak bertanggung jawab atas
              praktik privasi dari situs-situs atau pihak ketiga ini. Kami mendorong Anda untuk
              berhati-hati ketika meninggalkan Situs Web kami dan membaca kebijakan privasi setiap
              situs yang mengumpulkan informasi pribadi Anda.
            </p>
          </div>

          <div className="">
            <h1>FORUM UMUM</h1>
            <p>
              Situs Web kami menawarkan blog atau forum komunitas yang dapat diakses publik. Anda
              harus menyadari bahwa informasi yang Anda sampaikan di area tersebut dapat dibaca,
              dikumpulkan, dan digunakan oleh orang lain yang mengaksesnya. Untuk meminta
              penghapusan informasi pribadi Anda dari blog atau forum komunitas kami, silakan
              hubungi kami. Dalam beberapa kasus, kami mungkin tidak dapat menghapus informasi
              pribadi Anda, dan jika demikian, kami akan memberitahukan Anda mengenai ketidakmampuan
              kami untuk melakukannya beserta alasan-alasannya.
            </p>

            <p>
              MyEduSolve mungkin menyediakan papan buletin, blog, atau utas komunitas di situs web.
              Informasi pribadi yang Anda putuskan untuk bagikan di forum tersebut dapat dibaca,
              dikumpulkan, atau digunakan oleh orang lain yang mengunjungi forum tersebut, dan
              mungkin digunakan untuk mengirim pesan yang tidak diinginkan. MyEduSolve tidak
              bertanggung jawab atas informasi pribadi yang Anda pilih untuk bagikan di forum ini.
            </p>

            <p>
              Ingatlah bahwa ketika Anda berbagi informasi secara publik, informasi tersebut
              terindeks oleh mesin pencari.
            </p>
          </div>

          <div className="">
            <h1>KOMUNIKASI DARI SITUS WEB</h1>
            <p>
              Kami mungkin menggunakan alamat email Anda untuk mengirimkan buletin dan/atau materi
              pemasaran. Jika Anda tidak ingin menerima komunikasi tersebut lagi, Anda dapat
              berhenti berlangganan dengan mengikuti instruksi yang diberikan dalam email yang
              diterima atau dengan menghubungi kami.
            </p>

            <p>
              Kami juga mungkin mengirim pengumuman terkait Layanan pada situasi tertentu yang kami
              anggap perlu. Misalnya, jika Layanan kami sementara dihentikan untuk pemeliharaan,
              kami mungkin mengirim email. Umumnya, Anda tidak dapat memilih keluar dari komunikasi
              yang bukan bersifat promosi. Jika Anda tidak ingin menerimanya, Anda memiliki opsi
              untuk menghapus Akun Anda.
            </p>
          </div>

          <div className="">
            <h1>KEAMANAN INFORMASI</h1>
            <p>
              Situs Web dan Layanan kami telah dilengkapi dengan langkah-langkah keamanan standar
              industri untuk melindungi informasi dari kehilangan, penyalahgunaan, dan perubahan
              yang tidak diizinkan. Saat Anda memberikan informasi sensitif kepada kami, seperti
              data kartu kredit atau kredensial login, kami mengamankan informasi tersebut dengan
              enkripsi Secure Socket Layer (SSL).
            </p>

            <p>
              Meskipun kami berupaya maksimal untuk menjaga keamanan data pribadi Anda, kami tidak
              dapat menjamin keamanan data yang dikirimkan ke Situs Web kami atau melalui Layanan
              kami, sehingga transmisi informasi tersebut tetap berisiko pada Anda. Setelah kami
              menerima data pribadi Anda, kami menerapkan prosedur dan fitur keamanan yang ketat
              untuk mencegah akses tidak sah.
            </p>

            <p>
              Kami mengimplementasikan praktik pengumpulan, penyimpanan, dan pemrosesan data yang
              tepat, serta langkah-langkah keamanan untuk melindungi dari akses yang tidak sah,
              perubahan, pengungkapan, atau penghancuran informasi pribadi Anda, nama pengguna, kata
              sandi, informasi transaksi, dan data yang tersimpan di Situs kami.
            </p>

            <p>
              Kami secara rutin memeriksa Situs Web kami untuk mencari lubang keamanan dan
              kerentanan guna memastikan kunjungan Anda seaman mungkin. Kami melakukan pemindaian
              Malware secara berkala. Informasi pribadi Anda disimpan di belakang jaringan yang aman
              dan hanya dapat diakses oleh sejumlah orang yang memiliki hak akses khusus ke sistem
              kami dan diharuskan menjaga kerahasiaan informasi tersebut. Selain itu, semua
              informasi sensitif/kredit yang Anda berikan dienkripsi menggunakan teknologi SSL.
            </p>

            <p>
              Semua transaksi pembayaran diproses melalui penyedia gateway pembayaran yang aman dan
              tidak disimpan atau diproses di server kami.
            </p>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Privacy;
