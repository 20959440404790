import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import NumberFormat from 'react-number-format';
import moment from 'moment'
import Countdown from "react-countdown";
import { NotificationManager } from 'react-notifications';
import { Facebook, Twitter, Whatsapp } from '../../static';
import {
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
} from 'react-share';
import axios from 'axios'
import ReactGA from 'react-ga';
import { fetchMyEvent } from '../../Utils/Utils';
import ReactHtmlParser from 'react-html-parser';

const socialLinks = [
  {
    href: '/',
    title: <TwitterShareButton url={window.location.href}><Twitter /></TwitterShareButton>,
    id: '69',
    name: 'Twitter'
  },
  {
    href: '/',
    title: <FacebookShareButton url={window.location.href}><Facebook /></FacebookShareButton>,
    id: '68',
    name: 'Facebook'
  },
  {
    href: '/',
    title: <LinkedinShareButton url={window.location.href}><LinkedinIcon size={24} round={true} /></LinkedinShareButton>,
    id: '67',
    name: 'LinkedIn'
  },
  {
    href: '/',
    title: <WhatsappShareButton url={window.location.href}><Whatsapp /></WhatsappShareButton>,
    id: '65',
    name: 'WhatsApp'
  }
]

const LocationSvg = () => <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8 0C11.31 0 14 2.66 14 5.95C14 10.41 8 17 8 17C8 17 2 10.41 2 5.95C2 2.66 4.69 0 8 0ZM8 4C7.46957 4 6.96086 4.21071 6.58579 4.58579C6.21071 4.96086 6 5.46957 6 6C6 6.53043 6.21071 7.03914 6.58579 7.41421C6.96086 7.78929 7.46957 8 8 8C8.53043 8 9.03914 7.78929 9.41421 7.41421C9.78929 7.03914 10 6.53043 10 6C10 5.46957 9.78929 4.96086 9.41421 4.58579C9.03914 4.21071 8.53043 4 8 4ZM16 17C16 19.21 12.42 21 8 21C3.58 21 0 19.21 0 17C0 15.71 1.22 14.56 3.11 13.83L3.75 14.74C2.67 15.19 2 15.81 2 16.5C2 17.88 4.69 19 8 19C11.31 19 14 17.88 14 16.5C14 15.81 13.33 15.19 12.25 14.74L12.89 13.83C14.78 14.56 16 15.71 16 17Z" fill="black" />
</svg>

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  // let daysStr =days.toString()
  // let hoursStr =hours.toString()
  // let minutesStr =minutes.toString()
  // let secondsStr =seconds.toString()
  if (completed) {
    return (
      <div className="row w-50-mobile ml-2">
        <h1 className="event-detail__title mt-3 w-100" style={{ textAlign: 'center' }}>Event is not available.</h1>
      </div>
    )
  } else {
    return (
      <div className="row event-countdown__clock">
        <div className="col-2 event-countdown__clock-el ">
          <h1 className="event-countdown__clock-number" style={{ marginBottom: '0' }}>{days > 9 ? days : `0${days}`}</h1>
          <p className="event-countdown__clock-text">Hari</p>
        </div>
        <div className="col-1 event-countdown__clock-el">
          <p className="event-countdown__clock-text">:</p>
        </div>
        <div className="col-2 event-countdown__clock-el ">
          <h1 className="event-countdown__clock-number" style={{ marginBottom: '0' }}>{hours > 9 ? hours : `0${hours}`}</h1>
          <p className="event-countdown__clock-text">Jam</p>
        </div>
        <div className="col-1 event-countdown__clock-el">
          <p className="event-countdown__clock-text">:</p>
        </div>
        <div className="col-2 event-countdown__clock-el ">
          <h1 className="event-countdown__clock-number" style={{ marginBottom: '0' }}>{minutes > 9 ? minutes : `0${minutes}`}</h1>
          <p className="event-countdown__clock-text">Menit</p>
        </div>
        <div className="col-1 event-countdown__clock-el">
          <p className="event-countdown__clock-text">:</p>
        </div>
        <div className="col-2 event-countdown__clock-el ">
          <h1 className="event-countdown__clock-number" style={{ marginBottom: '0' }}>{seconds > 9 ? seconds : `0${seconds}`}</h1>
          <p className="event-countdown__clock-text">Detik</p>
        </div>
      </div>
    );
  }
}


const EventDetailComponent = () => {
  const eventSelected = useSelector(state => state.event_selected)
  const dispatch = useDispatch()
  const [userEnrolledStatus, setUserEnrolledStatus] = useState(false)
  const history = useHistory()

  useEffect(() => {
    let checking = async () => {
      let _isLoggedIn = await isLoggedIn()
      if (_isLoggedIn && eventSelected != null) {
        eventCheck()
      }
    }
    checking()
  }, [eventSelected]) // eslint-disable-line react-hooks/exhaustive-deps

  const eventCheck = () => {
    dispatch({ type: 'TOGGLE_LOADING_TRUE' });
    fetchMyEvent()
      .then(response => {
        let studentEnrolled = false
        response.data.forEach(el => {
          console.log(eventSelected.id, el.id)
          if (el.id == eventSelected.id) {
            studentEnrolled = true
          }
        })
        if (studentEnrolled) return true
        else return false
      })
      .then(res => setUserEnrolledStatus(res))
      .catch(error => {
        console.log('error')
      })
      .finally(() => dispatch({ type: 'TOGGLE_LOADING_FALSE' }))
  }

  const registerEvent = (url) => {
    const access_token = localStorage.getItem('access_token');
    dispatch({ type: 'TOGGLE_LOADING_TRUE' })
    axios.post(
      `${window.$API_URL}api/event/${eventSelected.id}/register`,
      {
        method: 'midtrans',
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
      }
    ).then((r) => {
      dispatch({ type: 'TOGGLE_LOADING_FALSE' })
      if (!r.data.data.token) {
        history.push(url)
      } else {
        history.push('/checkoutsuccess/event/' + eventSelected.id)
      }
    }).catch((error) => {
      dispatch({ type: 'TOGGLE_LOADING_FALSE' })
      NotificationManager.error('Some Error Occured in API!', '');
      if (error.response.status == 400) {
        history.push('/student/events')
      }
    })
  }

  const isLoggedIn = async () => {
    var result = false;
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      dispatch({ type: 'TOGGLE_LOADING_TRUE' })
      await axios
        .get(`${window.$API_URL}api/auth/me/`, {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
        })
        .then((success) => {
          dispatch({ type: 'TOGGLE_LOADING_FALSE' })
          if (success.data.success) {
            result = true;
          } else {
            result = false;
          }
        })
        .catch((error) => {
          result = false;
          dispatch({ type: 'TOGGLE_LOADING_FALSE' })

        })

    } else {
      result = false;
    }
    return result;
  };

  const signupEvent = async (url) => {
    var loggedIn = await isLoggedIn();
    if (loggedIn) {
      registerEvent(url);
    } else {
      dispatch({
        type: 'UPDATE_LOGINPOPUP_OPEN',
        payload: 1,
      })
    }
  }

  const parseDate = (date) => new Date(date.replace(' ', 'T'))
  // console.log(eventSelected)
  return (
    <>
      <div className="row">
        {eventSelected !== null ? (
          <div className="col-lg-9 col-md-12 col-sm-12 p-0-mobile">
            <div className="col-12 p-0-mobile">
              <div className="card event-detail__main">
                <div className="event-detail__main-flex msg-wrapper">
                  {eventSelected.image !== null ? (
                    <img src={eventSelected.image.url}
                      className="event-detail__img" />
                  ) : ''}
                  <div>
                    <h1 className="event-detail__title">{eventSelected.title}</h1>
                    <h2 className="event-detail__mediumtext mt-4">Deskripsi</h2>
                    <p className="event-detail__text mt-3">{eventSelected.description.split("\n").map((i, key) => {
                      return <p key={'data-' + key}>{i}</p>;
                    })}</p>
                    {eventSelected.event_learning ?
                      <>
                        <h2 className="event-detail__mediumtext mt-4 mb-0">Apa yang akan dipelajari?</h2>
                        <p className="info__detail">{ReactHtmlParser(eventSelected.event_learning)}</p>
                      </> 
                    : ''}
                    {eventSelected.event_benefits ?
                      <>
                        <h2 className="event-detail__mediumtext mt-4 mb-0">Apa yang akan didapatkan?</h2>
                        <p className="info__detail">{ReactHtmlParser(eventSelected.event_benefits)}</p>
                      </> 
                    : ''}
                    </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-4-mobile p-0-mobile w-50-mobile">
              <div className="event-countdown">
                <p className="event-countdown__text">Sisa waktu menuju event</p>
                <Countdown date={parseDate(eventSelected.start_date)} renderer={renderer} />
              </div>
            </div>
            {eventSelected.instructors[0] ?
              <div className="col-12  p-0-mobile">
                <div className="card event-detail__card mt-4-mobile" style={{ padding: '20px' }}>
                  <h2 className="event-detail__mediumtext mb-3">Instruktur</h2>
                  <div
                    className="row" style={{ paddingRight: '20px', paddingLeft: '20px' }}>
                    {eventSelected.instructors.map(el => (
                      <div className="col-md-4 col-sm-12 mb-3 event-detail__instructor-container" style={{cursor: 'pointer'}} onClick={() => history.push(`/instructors/${el.id}/${el.fullname}`)}>
                        <div className="event-detail__instructor">
                          <img src={`${el.photo_id && el.photo ? el.photo.url : "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"}`}
                            alt="instructor-img" className="mr-3"
                            style={{ width: '45px', height: '45px', borderRadius: '32px' }} />
                          <h2 className="event-detail__instructor-name">{el.fullname}</h2>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            : ''}
          </div>
        ) : ''}

        <div className="col-lg-3 col-md-12 col-sm-12 pl-3-mobile p-0-mobile">
          {eventSelected !== null ? (
            <div className="card event-detail__card" style={{ padding: '10px 20px 20px 20px' }}>
              <p className="event-detail__text-bold">Harga:</p>
              {eventSelected.price !== "0.00"
                ?
                <div className="price">
                  <h1 className="price__title d-flex">
                    <p className="harga-format mr-2">Rp</p>
                    <NumberFormat
                      value={+eventSelected.price}
                      displayType={'text'}
                      thousandSeparator="."
                      isNumericStrin={true}
                      decimalSeparator={""}
                      thousandsGroupStyle="thousand"
                      className="harga-format"
                    />
                  </h1>
                </div>
                :
                <p className="harga-format mr-2">Gratis</p>
              }
              {(eventSelected.start_date !== null) ? (
                <>
                  <p className="event-detail__text-bold mt-3">Tanggal:</p>
                  <p className="event-detail__text mt-1">{moment(eventSelected.start_date).format("DD MMMM YYYY")}</p>
                  <p className="event-detail__text-bold mt-2">Jam:</p>
                  <p className="event-detail__text mt-1">{`${moment(eventSelected.start_date).format("HH.mm")} - ${eventSelected.end_date ? moment(eventSelected.end_date).format("HH.mm") + ' WIB' : 'Selesai'}`}</p>
                </>
              ) : ''}
              <div className="d-flex mt-3 align-items-center">
                <LocationSvg />
                <p className="event-detail__text text-color-black mt-0 ml-3 mr-2">Event:</p>
                <p className="mt-0 text-color-black" style={{ fontWeight: '300', textTransform: 'capitalize' }}>{eventSelected.location}</p>
              </div>
              <p className="event-detail__text-bold mt-1">Bagikan</p>
              <div className="list-reset d-flex social__list mt-2">
                {socialLinks.map(({ href, title, id, name }) => (
                  <li className="social__list-item" key={id}
                    onClick={() => {
                      ReactGA.event({
                        category: 'Events',
                        action: `Click-Bagikan`,
                        label: `${eventSelected.title}-${name}`
                      })
                    }}>
                    <Link href={href}>
                      <a className="social__link">{title}</a>
                    </Link>
                  </li>
                ))}
              </div>
              {userEnrolledStatus ? (
                <div className="btn btn-pink w-100 mt-4"
                  onClick={(e) => {
                    if (eventSelected.signup_url) {
                      window.open(eventSelected.signup_url, "_blank")
                    }
                  }}>
                  Link Event
                </div>
              ) : (
                  <div className="btn btn-pink w-100 mt-4"
                    onClick={(e) => {
                      signupEvent('/student/events')
                      ReactGA.event({
                        category: 'Events',
                        action: `Buy Now`,
                        label: eventSelected.title,
                        value: eventSelected.price
                      })
                    }}>
                    {`${eventSelected.price == 0.00 ? 'Bergabung' : 'Beli'}`}
                  </div>
                )}


            </div>
          ) : ''}
        </div>

      </div>


    </>
  )
}

export default EventDetailComponent

// active: true
// audience: null
// created_at: "2020-05-28T16:20:53.000000Z"
// deleted_at: null
// description: "Tetap produktif dari rumah juga bisa dong! Design abstrak memang unik dan menarik karena memiliki nilai seni tersendiri. Dasar-dasar seni abstrak akan dikupas tuntas pada sesi ini."
// display_date: "01"
// display_month: "Aug"
// end_date: null
// id: 8
// image: {id: 84, original_name: "ART DESIGN ABSTRACT EXCLUSIVE.jpg", url: "https://storage.googleapis.com/production-assets/e…nts/gNDxB7sOg3RAOheFTpSXWR5G2y4E2pctGsgiQoeS.jpeg", extension: "jpeg", type: "image", …}
// image_id: 84
// instructors: []
// is_kartu_prakreja: false
// location: "online"
// price: "0.00"
// remaintime: 718393000
// signup_url: "https://www.loket.com/event/abstract-design"
// start_date: "2020-08-01 18:00:00"
// students: []
// summary: null
// title: "ART DESIGN: ABSTRACT EXCLUSIVE"
// updated_at: "2020-05-28T16:23:03.000000Z
