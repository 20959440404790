import React, { useState } from 'react'
import { CollapseDown, CollapseUp } from '../../static';
import { Collapse } from 'react-collapse'
import { useStore } from 'react-redux';
import NumberFormat from 'react-number-format';
import moment from 'moment'
import { CloseModals,CourseExIconBlue, CourseLiveIconBlue, CourseHostedIconBlue } from '../../static';

const OrderRow = ({tableContent}) => {
    const [open, setOpen] = useState(false)
    const closeModal = () => setOpen(false)
    const openModal = () => setOpen(true)
    const isCourse = tableContent.course != null
    const courseType = {
        external: <><CourseExIconBlue /><p className="order-modal__type ml-2">External</p></>,
        live: <><CourseLiveIconBlue /> <p className="order-modal__type ml-2">Live</p></>,
        hosted: <><CourseHostedIconBlue /><p className="order-modal__type ml-2">Self-Paced</p></>
    }

    const name = (!tableContent.course && !tableContent.event) ? '' : tableContent.course_id ? tableContent.course.name : tableContent.event.title
    const price = (!tableContent.course && !tableContent.event) ? '' : tableContent.course_id ? (tableContent.course.discount_price ? tableContent.course.discount_price : tableContent.course.retail_price) : +tableContent.amount
    const hargaKelas = (!tableContent.course && !tableContent.event) ? '' : tableContent.course_id ? tableContent.course.retail_price : +tableContent.amount
    const discountPrice = (!tableContent.course && !tableContent.event) ? '' : tableContent.course_id ? (tableContent.course.discount_price != 0.00 ? tableContent.course.retail_price - tableContent.course.discount_price : 0) : 0
    const totalPrice = (!tableContent.course && !tableContent.event) ? '' : +tableContent.amount

    if(!tableContent.course && !tableContent.event) {
        return ''
    }

    return (
        <>
            <div className="order-row card">
                <div className="order-row__col ">
                    <img src="/images/courses/orderIcon.png"/>
                </div>
                <div className="order-row__col " style={{maxWidth: '40%'}}>
                    <h2 className='order-row__name'>{name}</h2>
                    <h2 className='order-row__date'>{`Tanggal Pembelian: ${moment(tableContent.created_at).format('DD MMM YYYY')}`}</h2>
                </div>
                <div className="ml-auto">
                    <h2 className='order-row__small'>Metode Pembayaran: <b style={{textTransform: 'capitalize'}}>{tableContent.method}</b></h2>
                    <h2 className='order-row__small'>Total: <b>{'Rp  '}<NumberFormat
                        value={totalPrice}
                        displayType={'text'}
                        thousandSeparator="."
                        isNumericStrin={true}
                        decimalSeparator={""}
                        thousandsGroupStyle="thousand"
                    /></b></h2>
                    <div className="d-flex">
                        {tableContent.status == 'success' || tableContent.status == 'paid' || tableContent.method == 'voucher' ? <div className="pr-2 pl-2 course-card__selesai d-flex justify-content-center align-items-center" style={{width: 'unset'}}>Berhasil</div> : <div className="pr-2 pl-2 course-card__selesai d-flex justify-content-center align-items-center" style={{width: 'unset'}}
                        style={{backgroundColor: 'rgba(255, 15, 81, 0.25)', color: '#FF0F51', borderColor: '#FF0F51'}}>
                            Gagal
                        </div>}
                    </div>
                </div>
                <div className="d-flex ml-5 justify-content-center align-items-center">
                    <div className="btn btn-pink" onClick={openModal}>Detail</div>
                </div>
            </div>
            <div
                className={`sign_up_modal modal fade show`}
                tabIndex="-1"
                role="dialog"
                aria-hidden="true"
                style={{ display: open ? 'block' : 'none' }}
            >
                <div className="modal-dialog modal-dialog-centered" style={{ width: '440px', maxWidth: '100vw'}}>
                    <div className="modal-content order-modal">
                        <div className="pointer d-flex justify-content-between">
                            <p className="order-modal__title">Detail Pembayaran</p>
                            <div  className="ml-auto" onClick={closeModal}>
                                <CloseModals  />
                            </div>
                        </div>
                        <div className="d-flex mb-3">
                            <div className="order-modal__img" style={{backgroundImage:`url(${ isCourse ? tableContent.course.thumbnail ? tableContent.course.thumbnail.url : '/images/courses/CourseNoPicture.png' : tableContent.event.image ? tableContent.event.image.url : '/images/courses/CourseNoPicture.png'})` }} />
                            <div className="d-flex flex-column">
                                {isCourse && <div className="d-flex align-items-center">{courseType[tableContent.course.type]}</div>}
                                <p className="order-modal__name">{isCourse ? tableContent.course.name : tableContent.event.title}</p>
                                <p className="order-modal__instructor">{isCourse && tableContent.course.instructor.fullname}</p>
                                {!isCourse ? 
                                    tableContent.booking_days[0] ? 
                                        tableContent.booking_days.map(el => (
                                            <>
                                                <p className="order-detail-page__detail-name" style={{fontWeight: '500'}}>{el.day.title}</p>
                                                <p className="order-detail-page__detail-name" style={{marginTop: '0', fontSize: '12px'}}>{`${moment(el.day.date).format('D MMMM YYYY')}, ${moment(el.day.date).format('HH:mm')} - ${moment(el.day.end_date).format('HH:mm')} WIB`}</p>
                                            </>
                                        )) : ''
                                    : ''
                                }
                            </div>
                        </div>
                        <div className="row d-flex mb-2 mt-3">
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-bold">Nomor Transaksi</p></div>
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-text">{tableContent.id}</p></div>
                        </div>
                        <div className="row d-flex align-items-center mb-2">
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-bold">Tanggal Pembelian</p></div>
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-text">{`${moment(tableContent.created_at).format('DD MMM YYYY')}`}</p></div>
                        </div> 
                        <div className="row d-flex align-items-center mb-2">
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-bold">Harga Kelas</p></div>
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-text">{'Rp  '}<NumberFormat
                                value={hargaKelas}
                                displayType={'text'}
                                thousandSeparator="."
                                isNumericStrin={true}
                                decimalSeparator={""}
                                thousandsGroupStyle="thousand"
                            /></p></div>
                        </div> 
                        <div className="row d-flex align-items-center mb-2">
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-bold">Diskon (%)</p></div>
                            <div className="col-md-6 col-sm-6 col-6">
                            {discountPrice ? 
                            <p className="my-order__mob-text">
                            <div  className="d-flex">
                                <p className="ml-auto my-order__mob-text">{'-Rp  '}<NumberFormat
                                    value={discountPrice}
                                    displayType={'text'}
                                    thousandSeparator="."
                                    isNumericStrin={true}
                                    decimalSeparator={""}
                                    thousandsGroupStyle="thousand"
                                /></p>
                            </div></p> : <p className="ml-auto my-order__mob-text">--</p>}
                            </div>
                        </div> 
                        <div  className="d-flex  mb-2">
                            <p className="my-order__mob-bold">Voucher Diskon:</p>
                            {tableContent.discount_voucher_id ? 
                            <>
                            <p className="ml-auto my-order__mob-text">{'-Rp  '}<NumberFormat
                                value={tableContent.discountvoucher.discount_type == 'amount' ? +tableContent.discountvoucher.discount_value : +tableContent.discountvoucher.discount_value/100 * +tableContent.amount_before_discount}
                                displayType={'text'}
                                thousandSeparator="."
                                isNumericStrin={true}
                                decimalSeparator={""}
                                thousandsGroupStyle="thousand"
                            /></p>
                            </> : 
                            <p className="ml-auto">--</p>
                            }
                        </div> 
                        <div className="row d-flex align-items-center mb-2">
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-bold">Metode Pembayaran</p></div>
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-text">{tableContent.method}</p></div>
                        </div> 
                        <div className="row d-flex align-items-center order-modal__total">
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-bold">Total Pembayaran</p></div>
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-text" style={{fontWeight: '700'}}><p className="ml-auto my-order__mob-bold" style={{fontWeight: '700'}}>{'Rp  '}<NumberFormat
                                value={totalPrice}
                                displayType={'text'}
                                thousandSeparator="."
                                isNumericStrin={true}
                                decimalSeparator={""}
                                thousandsGroupStyle="thousand"
                            /></p></p></div>
                        </div> 
                        <div className="row d-flex align-items-center mb-2">
                            <div className="col-md-6 col-sm-6 col-6"><p className="my-order__mob-bold">Status</p></div>
                            <div className="col-md-6 col-sm-6 col-6"><div className="d-flex justify-content-end">
                                {tableContent.status == 'success' || tableContent.status == 'paid' || tableContent.method == 'voucher' ? <div className="pr-2 pl-2 course-card__selesai d-flex justify-content-center align-items-center" style={{width: 'unset'}}>Berhasil</div> : <div className="pr-2 pl-2 course-card__selesai d-flex justify-content-center align-items-center" style={{width: 'unset'}}
                                style={{backgroundColor: 'rgba(255, 15, 81, 0.25)', color: '#FF0F51', borderColor: '#FF0F51'}}>
                                    Gagal
                                </div>}
                            </div></div>
                        </div> 
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderRow