import React from 'react'
import {CourseLiveIconBlue, CourseHostedIconBlue } from '../../static'
import moment from 'moment'
import { Link } from 'react-router-dom'

const QnA = ({course_detail}) => {
    // console.log(course_detail)
    return (
        <>
        <div className="hosted-jadwal-qna">
            <div className="d-flex">
                <img src={course_detail.thumbnail ? course_detail.thumbnail.url : ''} alt="course-img" className="pilih-jadwal-content__img" />
                <div className="d-flex flex-column ml-4">
                    <div className="d-flex align-items-center">{course_detail.type === "live" ? <div className="d-flex align-items-center"><CourseLiveIconBlue /> <p className="course-card__type ml-2">Live</p></div> : ""}
                    {course_detail.type === "hosted" ? <div className="d-flex align-items-center">
                        <CourseLiveIconBlue /><p className="course-card__type ml-2">Live QnA</p>
                    </div> : ""}</div>
                    <h2 className="course-card__class-name mt-2 ml-0" >{course_detail.name}</h2>
                    <p className="mt-1" >{course_detail.instructor.fullname}</p>
                </div>
            </div>
            {course_detail.course_qna_register ? 
                course_detail.course_qna_attend ?
                <div className="info-kelas-self d-flex flex-column">
                    <h2>Anda telah menyelesaikan Live QnA!</h2>
                    <p className="mt-3">Silahkan kerjakan Exam dan beri Rating untuk menyelesaikan Kelas.</p>
                    
                </div>
                :
                <div className="d-flex align-items-center hosted-jadwal-qna__jadwal">
                    <p className="hosted-jadwal-qna__jadwal__bold">Dilaksanakan Pada Tanggal:</p>
                    <p className="ml-2 hosted-jadwal-qna__jadwal__text">{`${moment(course_detail.course_qna_register.schedule.start_date).format('DD MMMM YYYY')} | ${moment(course_detail.course_qna_register.schedule.start_date).format('HH.mm')} - ${moment(course_detail.course_qna_register.schedule.end_date).format('HH.mm')} WIB`}</p>
                    <div className="ml-auto">
                        <Link to="/student/course">
                            <div className="btn btn-white ml-auto">Ganti Jadwal</div>
                        </Link>
                    </div>
                </div>
                : <div className="info-kelas-self d-flex flex-column">
                <h2>Info Kelas Self-Pace!</h2>
                    <p className="mt-3">Untuk memilih jadwal, lakukan pada halaman Kelas Saya, dan klik tombol 'Pilih Jadwal QnA'.</p>
                    <div className="d-flex">
                        <div className="ml-auto">
                            <Link to="/student/course">
                                <div className="btn btn-pink ml-auto mt-4">Ke Kelas Saya</div>
                            </Link>
                        </div>
                    </div>
                </div>}
                <div className="d-flex justify-content-end mt-4">
                {course_detail.course_qna_register ? 
                    course_detail.course_qna_attend ? '' :
                    course_detail.course_qna_register.schedule.whatsapp_link ?  
                    <div className="hosted-jadwal-qna__wa" onClick={(e) => window.open(course_detail.course_qna_register.schedule.whatsapp_link, "_blank")}>
                        <div><img src="/images/courses/wa.png" /></div>
                        <p>Grup WhatsApp</p>
                    </div>
                    : ''
                : ''}
                {course_detail.course_qna_register ? 
                    course_detail.course_qna_attend ? '' :
                    course_detail.course_qna_register.schedule.zoom_link ?  
                    <div className="hosted-jadwal-qna__zoom" onClick={(e) => window.open(course_detail.course_qna_register.schedule.zoom_link, "_blank")}>
                        <div><img src="/images/courses/zoom.png" /></div>
                        <p>Link Zoom</p>
                    </div>
                    : ''
                : ''}
            </div>
        </div>
        </>
    )
}

export default QnA