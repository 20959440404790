

import React, { useEffect } from 'react'
import {CourseLiveIconBlue } from '../../static'
import axios from 'axios';
import { useHistory } from "react-router";
import SchedulePick from '../Schedule/SchedulePick'
import { useDispatch, useSelector } from 'react-redux'
import { NotificationManager } from 'react-notifications';
import { trackEventSource } from '../../Utils/EventSourcing'


const PilihJadwalModal = () => {
    const dispatch = useDispatch()
    const scheduleSelectedId = useSelector(state => state.schedule_live_id_selected)
    const course = useSelector(state => state.course_detail)
    const courseForPicking = useSelector(state => state.course_detail_row_schedule)
    const history = useHistory()

    useEffect(() => {
        if(!course){
            history.push('/student/course')
        }
    }, [scheduleSelectedId, course]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const enrollSessionFinal = (schedule_id, course_id) => {
        const access_token = localStorage.getItem('access_token');
        dispatch({type: 'TOGGLE_LOADING_TRUE'})
        axios
            .post(
                `${window.$API_URL + 'api/user/schedule/enroll'}`,
                {
                    schedule_id: schedule_id,
                    course_id: course_id,
                },
                {
                    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
                },
            )
            .then((success) => {
                dispatch({type: 'TOGGLE_LOADING_FALSE'})
                if (success.data.success) {
                    trackEventSource({
                        type : "registerschedule",
                        payload : { 
                            course_id: course_id, 
                            scheduletype: 'Live', 
                            course_type: 'Live'
                        }
                    })
                    NotificationManager.success('Pemilihan jadwal berhasil dilakukan!', 'Berhasil!');
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                }
            })
            .catch((error) => {
                console.log(error.response)
                dispatch({type: 'TOGGLE_LOADING_FALSE'})
                if (!error.response) {
                    NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
                    return false
                  }
                if (error.response.status === 422) {
                    let errorTemp = []
                    Object.keys(error.response.data.errors).forEach((key) => {
                      errorTemp.push(error.response.data.errors[key][0]);
                    });
                    const errorList2 = errorTemp.map((char, i) => {
                      return (
                        <li key={i} style={{ listStyleType: 'none' }}>
                          {char}
                        </li>
                      );
                    });
                    NotificationManager.error(errorList2, 'Validasi Error!');
                  }
                  else if (error.response.status === 401) {
                    NotificationManager.error(error.response.data.message, 'Validasi Error!');
                  } else {
                    NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
                  }
            });
    }
    // console.log(course.schedule_list)
    return (
        <>
            {course !== null ? 
            <div className="navbar-mob">
                <div  className="dashboard-container">
                    <div className="d-flex">
                        <img src={course.thumbnail ? course.thumbnail.url : ''} alt="course-img" style={{width: '138px', height: '99px'}}/>
                        <div className="d-flex flex-column ml-4">
                            <div className="d-flex align-items-center">
                                {course.type === "live" ? 
                                <div className="d-flex align-items-center">
                                    <CourseLiveIconBlue /> 
                                    <p className="course-card__type ml-2">Live</p>
                                </div> : 
                                <div className="d-flex align-items-center">
                                    <CourseLiveIconBlue />
                                    <p className="course-card__type ml-2">Live QnA</p>
                                </div>
                                }
                            </div>
                            <h2 className="course-card__class-name">{course.name}</h2>
                            <p className="mt-1 course-card__instructor-name">{course.instructor.fullname}</p>
                        </div>
                    </div> 
                    <SchedulePick courseForPicking={courseForPicking}/>
                    {course.type === "live" ?
                        <div className="d-flex w-100 mt-5 justify-content-end">
                            <div className="btn btn-white btn-schedule mr-3" onClick={(e) =>{
                                history.push('/student/course')
                                }}>
                                Batal
                            </div>
                            <div className="btn btn-pink btn-download-sertifikat btn-schedule"style={{padding: '11px'}} 
                            onClick={(e) =>{
                                //SELECT SCHEDULE FUNCTION
                                enrollSessionFinal(scheduleSelectedId, course.id)
                            }} 
                            >
                                Pilih Jadwal
                            </div>
                        </div>
                    : ''}
                </div>
            </div>
            : ''}
        </>
    )
}

export default PilihJadwalModal