import React from 'react';
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import LoadingSpinnerInside from '../Layout/LoadingSpinnerInside';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom'

class LandingTopCategoriesSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loading_text: "Loading...",
      featured_courses: []
    };
  }

  componentDidMount() {
    this.fetchFeaturedCourses();
  }

  fetchFeaturedCourses = async () => {
    this.setState({
      loading: true,
    });
    axios
      .get(
        `${window.$API_URL}api/course/featured_home/`,
        {
        },
        {
          headers: { 'Content-Type': 'application/json', 'x-access-token': '' },
        },
      )
      .then((success) => {
        this.setState({
          loading: false,
        });
        if (success.data.success) {
          this.setState({
            featured_courses: success.data.data
          });

        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (!error.response) {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
          return false
      }
        if (error.response.status === 422) {
          let _returnError = [];
          Object.keys(error.response.data.errors).forEach((key) => {
            _returnError.push(error.response.data.errors[key][0]);
          });
          let _showing = true;
          this.setState({ returnError: _returnError, showing: _showing });
          const errorList2 = this.state.returnError.map((char, i) => {
            return (
              <li key={i} style={{ listStyleType: 'none' }}>
                {char}
              </li>
            );
          });
          NotificationManager.error(errorList2, 'Validasi Error!');
        } else if (error.response.status === 404) {
          NotificationManager.error(error.response.data.message, 'Validasi Error!');
        } else {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        }
      });
  }

  render() {
    return (
      <>
        {
          (this.state.featured_courses.length > 0) ? <section id="top-courses" className="top_cours">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 offset-lg-3">
                  <div className="main-title text-center">
                    <h3 className="mt0">Kelas Unggulan</h3>
                    <p>Temukan Kelas Untuk Masa Depanmu</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="emply-text-sec">
                    <div className="row" id="">
                      {
                        this.state.featured_courses.map((course, index) => (
                          <div className="col-md-6 col-lg-4 col-xl-3 business design"
                          // onClick={(e) => (course.coursemppdetails != null) ? window.open(course.coursemppdetails.url, '_blank') : e.preventDefault()}
                          >
                            <div className="top_courses">
                              <div
                                className="thumb col-md-12 col-lg-12 col-xl-12 p-l-0 p-r-0"
                              >
                                <img
                                  className="img-whp"
                                  src={(course.thumbnail != null) ? course.thumbnail.url : "images/courses/Course-Images-05.jpg"}
                                  alt="t1.jpg"

                                />
                                <div
                                  className="overlay"
                                >
                                  {
                                    (course.kartu_prakerja) ? <div className="tag">Kartu Prakerja</div> : ''
                                  }

                                  <div className="icon">
                                  </div>
                                  <Link className="tc_preview_course" to={'course/' + course.id + '/' + course.slug}>
                                    {
                                      /*
                                      ((course.coursemppdetails != null && course.coursemppdetails.mpp != null && course.coursemppdetails.mpp.thumbnail != null))
                                        ?
                                        <img
                                          className="img-whp"
                                          src={course.coursemppdetails.mpp.thumbnail.url}
                                          alt="MPP Logo"
                                        />
                                        :
                                        ''
                                        */
                                    }
                                  </Link>
                                </div>
                              </div>
                              <div className="details">
                                <Link className="tc_preview_course" to={'course/' + course.id + '/' + course.slug}>
                                  <div className="tc_content">
                                    {
                                      (course.instructor != null)
                                        ?
                                        <p>{course.instructor.first_name + " " + course.instructor.last_name}</p>
                                        :
                                        ''
                                    }
                                    <h5>{course.name}</h5>
                                  </div>
                                  <div className="tc_footer">
                                    {
                                      (course.discount_price == '0.00')
                                        ?
                                        <div className="tc_price float-right">Rp <NumberFormat value={course.retail_price} displayType={'text'} thousandSeparator="." isNumericStrin={true} decimalSeparator={""} thousandsGroupStyle="thousand" />
                                        </div>
                                        :
                                        <div className="tc_price float-right fn-414">
                                          <span className="original_price"><s>Rp <NumberFormat value={course.retail_price} displayType={'text'} thousandSeparator="." isNumericStrin={true} decimalSeparator={""} thousandsGroupStyle="thousand" /></s></span> Rp <NumberFormat value={course.discount_price} displayType={'text'} thousandSeparator="." isNumericStrin={true} decimalSeparator={""} thousandsGroupStyle="thousand" />
                                        </div>
                                    }

                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.loading ? <LoadingSpinnerInside text={this.state.loading_text} /> : ''}
          </section> : ''
        }


        <NotificationContainer />


      </>
    );
  }
}

export default LandingTopCategoriesSection;
