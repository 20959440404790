import React, { useEffect } from 'react';
import { NotificationContainer } from 'react-notifications';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../Layout/LoadingSpinner';
import EditProfilMob from '../components/EditProfile/EditProfMob';
import Footer from '../components/Footer';
import Header from '../components/Header/Header';
import MenuUser from '../components/MenuUser';
import PilihJadwal from '../components/MyCourse/PilihJadwalMob';

import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { isLoggedIn } from '../Utils/Utils';

import AkunSaya from './AkunSaya';
import EditProfil from './EditProfil';
import EventSaya from './EventSaya';
import KelasSaya from './KelasSaya';
import PesananSaya from './PesananSaya';
import Referral from './Referral';
import ReflectiveJournalScreen from './reflective-journal/ReflectiveJournalScreen';
import TugasMandiriScreen from './tugas-mandiri/TugasMandiri';
import UnjukKeterampilanScreen from './unjuk-keterampilan/UnjukKeterampilan';

const Arrow = () => (
  <Link to="/student/profile">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mt-4 pt-2 mr-3"
    >
      <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" fill="black" />
    </svg>
  </Link>
);

const StudentDashboard = ({ course_detail }) => {
  const { profile_menu } = useParams();
  const isLoading = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();

  async function dispatchMethod() {
    const user_check_api = await isLoggedIn();
    dispatch({
      type: 'ADD_PROFILEDATA',
      payload: user_check_api.profile_data,
    });
  }

  const generateTitle = (title) => {
    switch (title) {
      case 'course': {
        return 'Kelas Saya';
      }
      case 'events': {
        return 'Event Saya';
      }
      case 'orders': {
        return 'Pesanan Saya';
      }
      case 'editprofile': {
        return 'Edit Profil';
      }
      case 'schedulepick': {
        return 'Pilih Jadwal';
      }
      case 'ubahdatadiri': {
        return 'Ubah Data Diri';
      }
      case 'referral': {
        return 'Referral';
      }
      case 'reflective-journal': {
        return 'Reflective Journal';
      }
      case 'tugas-mandiri': {
        return 'Tugas Mandiri';
      }
      case 'unjuk-keterampilan': {
        return 'Unjuk Keterampilan';
      }
      default: {
        return '';
      }
    }
  };

  const generateComp = (title) => {
    switch (title) {
      case 'course': {
        return <KelasSaya />;
      }
      case 'events': {
        return <EventSaya />;
      }
      case 'orders': {
        return <PesananSaya />;
      }
      case 'editprofile': {
        return <EditProfil />;
      }
      case 'schedulepick': {
        return <PilihJadwal />;
      }
      case 'ubahdatadiri': {
        return <EditProfilMob />;
      }
      case 'referral': {
        return <Referral />;
      }
      case 'reflective-journal': {
        return <ReflectiveJournalScreen />;
      }
      case 'tugas-mandiri': {
        return <TugasMandiriScreen />;
      }
      case 'unjuk-keterampilan': {
        return <UnjukKeterampilanScreen />;
      }
      default: {
        return '';
      }
    }
  };

  useEffect(() => {
    dispatchMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <main>
        <div className="main-container container student-dashboard">
          <div className="row">
            <div
              className="col-sm-12 col-lg-2 col-xl-2 menu-shadow"
              style={{ padding: '0', zIndex: '2' }}
            >
              <div className="navbar-desc h-100">
                <MenuUser />
              </div>
              {profile_menu !== 'profile' ? (
                <div
                  className="col-sm-12 col-lg-10 col-xl-10 w-100 content-user-container navbar-mob"
                  style={{ backgroundColor: 'white' }}
                >
                  <div className="d-flex">
                    <Arrow />
                    <h1 className="content-user__title mt-4 navbar-mob">
                      {generateTitle(profile_menu)}
                    </h1>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            {profile_menu !== 'profile' ? (
              <div className="col-sm-12 col-lg-10 col-xl-10 w-100 content-user-container">
                {generateComp(profile_menu)}
              </div>
            ) : (
              ''
            )}
            {profile_menu === 'profile' ? (
              <div
                className="col-sm-12 col-lg-10 col-xl-10 w-100 content-user-container-profile"
                style={{ backgroundColor: 'white' }}
              >
                <AkunSaya />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </main>
      <Footer />
      <NotificationContainer />
      {isLoading ? <LoadingSpinner text={'Mohon Menunggu..'} /> : ''}
    </>
  );
};

const mapStateToProps = (state) => {
  return { course_detail: state.course_detail };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentDashboard);
