import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga';
import { Facebook, Twitter, Whatsapp } from '../../static';
import {
    FacebookShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
} from 'react-share';



function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

const Social = () => {
    const course_detail = useSelector(state => state.course_detail)
    const query = useQuery()
    const socialLinks = [
        {
            href: '/',
            title: <TwitterShareButton url={`https://www.myedusolvex.com/course/${course_detail?.id}/${course_detail?.slug}`}><Twitter /></TwitterShareButton>,
            id: '69',
            name: 'Twitter'
        },
        {
            href: '/',
            title: <FacebookShareButton url={`https://www.myedusolvex.com/course/${course_detail?.id}/${course_detail?.slug}`}><Facebook /></FacebookShareButton>,
            id: '68',
            name: 'Facebook'
        },
        {
            href: '/',
            title: <LinkedinShareButton url={`https://www.myedusolvex.com/course/${course_detail?.id}/${course_detail?.slug}`}><LinkedinIcon size={24} round={true} /></LinkedinShareButton>,
            id: '67',
            name: 'Linkedin'
        },
        /*{
            href: '/',
            title: <Youtube />,
            id: '66',
            name: ''
        },*/
        {
            href: '/',
            title: <WhatsappShareButton url={`https://www.myedusolvex.com/course/${course_detail?.id}/${course_detail?.slug}`}><Whatsapp /></WhatsappShareButton>,
            id: '65',
            name: 'Whatsapp'
        }
    ]
    return (
        <section className="social-new">
            <div className="social-new__bagikan">
                <h3 className="social-new__title">Bagikan</h3>
                <ul className="list-reset d-flex social-new__list">
                    {socialLinks.map(({ href, title, id, name }) => (
                        <li className="social__list-item" key={id} 
                        onClick={() => {
                            ReactGA.event({
                                category: query.get('section') === 'kelas_populer' ? 'Kelas Populer' : 'Semua Kelas',
                                action: `Click-Bagikan`,
                                label: `${course_detail.name}-${name}`
                            })
                        }}>
                            <Link href={href}>
                                <a className="social__link" href="/" onClick={(e) => e.preventDefault()}>{title}</a>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}

export default Social;