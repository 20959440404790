import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

const ExamAnswers = ({ answers, setAnswer, answer, answerSelected, setAnswerSelected }) => {
    return (
        <div className="exam-answers">
            {
                answers.map(elAnswer => (
                    <div className={`mb-3 d-flex ${elAnswer.id === answerSelected ? 'exam-answers__answer__selected' : 'exam-answers__answer'}`}
                        onMouseDown={() => {
                            setAnswerSelected(elAnswer.id)
                            // setAnswer(elAnswer.answer)
                            // setAnswerId(elAnswer.id)
                        }}
                    >
                        <div className={`mr-3  ${elAnswer.id === answerSelected ? 'exam-answers__option__selected' : 'exam-answers__option'}`}>
                            {elAnswer.option}
                        </div>
                        <div className="mr-auto ">
                            {elAnswer.answer}
                        </div>
                        <div style={{ minWidth: '30px' }} className='ml-1'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                                style={{ display: `${elAnswer.option === answer ? 'block' : 'none'}` }}>
                                <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z" fill="#FF0F51" />
                            </svg>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

const QuizPerLecture = ({ fillAllAnswerWarning }) => {
    const courseDetail = useSelector(state => state.course_detail)
    const lesson_number = useSelector(state => state.lesson_detail_number)
    const lecture_number = useSelector(state => state.lecture_number)
    const [lessonDetail, setLessonDetail] = useState(null)

    const [questionQuery, setQuestionQuery] = useState(0)

    const [answerSelected, setAnswerSelected] = useState(null)

    const dispatch = useDispatch()

    useEffect(() => {
      if(courseDetail.lessons_front[lesson_number].lectures[lecture_number].is_lecture_quiz_passed) {
        dispatch({
          type: 'UPDATE_EXAM_OR_MATERI',
          payload: 'finish_perlecture',
        })
        dispatch({
          type: 'QUIZ_SCORE_DATA',
          payload: {isPassed: true, score: courseDetail.lessons_front[lesson_number].lectures[lecture_number].is_lecture_quiz_passed.correct_count},
        })
      }
    }, [lesson_number, lecture_number]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let options = ['A', 'B', 'C', 'D']
        let temp = {...courseDetail.lessons_front[lesson_number].lectures[lecture_number]}
        temp.lecture_quiz_questions.forEach(el => {
            let i = 0
            el.lecture_question_answers.forEach(answerEl => {
                answerEl.option = options[i]
                i++
            })
        })
        setLessonDetail(temp)
        let _answerSelected = {
            lecture_id: courseDetail.lessons_front[lesson_number].lectures[lecture_number].id,
            answers: []
        }
        courseDetail.lessons_front[lesson_number].lectures[lecture_number].lecture_quiz_questions.forEach(el => {
            _answerSelected.answers.push({
                questionId: el.id,
                answerId: null
            })
        })
        setAnswerSelected(_answerSelected)
    }, [lesson_number, lecture_number]) // eslint-disable-line react-hooks/exhaustive-deps

    const submitQuiz = () => {
      let allQuestionAnswered = true
      answerSelected.answers.forEach(el => {
        if (!el.answerId) {
          allQuestionAnswered = false
        }
      })

      if (allQuestionAnswered) {
        const access_token = localStorage.getItem('access_token');
        dispatch({type: 'TOGGLE_LOADING_TRUE'})
        axios({
          method: 'POST',
          url: `${window.$API_URL}api/quiz/qnaquizsubmit`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
          },
          data: {
            answerArr: answerSelected.answers,
            lecture_id: answerSelected.lecture_id
          }
        }).then(result => {
          dispatch({
            type: 'UPDATE_EXAM_OR_MATERI',
            payload: 'finish_perlecture',
          })
          if(result.data.data.is_passed) {
            let newData = {...courseDetail}
            newData.lessons_front[lesson_number].lectures[lecture_number].is_lecture_quiz_passed = result.data.data
            dispatch({
              type: 'ADD_COURSEDETAIL',
              payload: newData
            })
            dispatch({
              type: 'QUIZ_SCORE_DATA',
              payload: {isPassed: true, score: result.data.data.correct_count},
            })
          } else {
            dispatch({
              type: 'QUIZ_SCORE_DATA',
              payload: {isPassed: false, correct: result.data.data.correct, wrong: result.data.data.wrong},
            })
          }
        }).catch((error) => {
        }).finally(_ => dispatch({type: 'TOGGLE_LOADING_FALSE'}))
      } else {
        fillAllAnswerWarning()
      }
    }

    return (
        (lessonDetail)
          ?
          <div>
            <div className="course-exam d-flex flex-column">
              <div className="d-flex ">
                <div className="exam__number d-flex justify-content-center">
                  <span>{questionQuery + 1}</span>
                </div>
                <h1 className="exam__title ml-4 d-flex align-items-center">{lessonDetail.lecture_quiz_questions[questionQuery].question}</h1>
              </div>
              <div className="mt-4">
                <ExamAnswers
                  answers={lessonDetail.lecture_quiz_questions[questionQuery].lecture_question_answers}
                  answerSelected={answerSelected.answers[questionQuery].answerId}
                  setAnswerSelected={(id) => {
                    let temp = {...answerSelected}
                    temp.answers[questionQuery].answerId = id
                    setAnswerSelected(temp)
                  }}  />
              </div>
  
              <div className="align-self-end justify-content-end  mt-auto">
                {questionQuery < 1 ? ''
                  :
                  <a className='btn exam__next mr-2' href="/"
                    onClick = {(e) => {
                      e.preventDefault()
                      setQuestionQuery(questionQuery - 1)
                    }}
                  >
                    {'<  Sebelumnya'}
                  </a>
                }
                {questionQuery == lessonDetail.lecture_quiz_questions.length - 1 ?
                  <a className='btn exam__kumpulkan' href="/"
                    onClick = {(e) => {
                      e.preventDefault()
                      submitQuiz()
                    }}
                  >
                    {'Kumpulkan'}
                  </a>
                  :
                  <a className='btn exam__next' href="/"
                    onClick = {(e) => {
                      e.preventDefault()
                      setQuestionQuery(questionQuery + 1)
                    }}
                  >
                    {'Selanjutnya  >'}
                  </a>
                }
              </div>
            </div>
            {/* {this.state.loading ? <LoadingSpinner text={this.state.loading_text} /> : ''} */}
          </div>
          :
          ''
      )
}

export default QuizPerLecture