import React from 'react'

const ZoomLink = ({ link }) => {
    return (
        <>
            <div className="hosted-jadwal-qna__zoom" onClick={(e) => window.open(link, "_blank")}>
                <div><img src="/images/courses/zoom.png" className="hosted-jadwal-qna__zoom-img" alt="zoom icon" /></div>
                <p className="navbar-desc">Link Zoom</p>
            </div>
        </>
    )
} 

const WALink = ({ link }) => {
    return (
        <>
           <div className="hosted-jadwal-qna__wa" onClick={(e) => window.open(link, "_blank")}>
                <div><img src="/images/courses/wa.png" className="hosted-jadwal-qna__wa-img" alt="wa icon"/></div>
                <p className="navbar-desc">Grup WhatsApp</p>
            </div>
        </>
    )
} 



export { ZoomLink, WALink }