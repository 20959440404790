import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssignmentIcon } from '../../static';
import Button from '../Button';

const InfoIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.25 11.907C23.25 18.0633 18.2187 23.064 12 23.064C5.78128 23.064 0.75 18.0633 0.75 11.907C0.75 5.75064 5.78128 0.75 12 0.75C18.2187 0.75 23.25 5.75064 23.25 11.907Z" fill="none" stroke="black" stroke-width="1.5"/>
<path d="M11.038 8.65883H12.958V7.03883H11.038V8.65883ZM11.086 15.7988H12.91V9.36683H11.086V15.7988Z" fill="black"/>
</svg>

const PretestInfo = ({closeModal}) => {
    const course_detail = useSelector(state => state.course_detail)
    const dispatch = useDispatch()
    return (
        course_detail?
        <div className="d-flex flex-column justify-content-around modal-hosted-course" style={{flex: '1'}}>
            <h1 className="assignment__title mb-3" style={{fontWeight: '700'}}>Halo!</h1>
            <h2 className="rating__desc_3 align-self-center" style={{textAlign: 'center'}}>Selamat datang di kelas</h2>
            <h2 className="rating__desc_3 align-self-center" style={{textAlign: 'center', fontWeight: '700'}}>{`${course_detail.name}!`}</h2>
            <h2 className="rating__desc_1 align-self-start mt-4 mb-4" style={{textAlign: 'left'}}><p style={{fontSize: '14px', marginBottom: '4px', fontWeight: '700'}}>Sebelum melakukan pembelajaran, silahkan Anda mengerjakan Pre-Test terlebih dahulu.</p>Hasil dari Pre-Test ini akan dijadikan perbandingan dengan nilai <span style={{fontWeight: '700'}}>"Exam"</span> yang Anda dapatkan.  Hal tersebut bertujuan untuk mengukur seberapa besar kemampuan Anda dalam menyerap materi pembelajaran. Pre-Test ini terdiri atas (banyak soal dan jenis soalnya). <span style={{fontWeight: '700'}}>Terima kasih dan selamat belajar!</span></h2>
            <div className='rating__btn align-self-stretch d-flex flex-column'>
                <a className='btn btn-lg btn-pink align-self-stretch' style={{fontSize: '16px'}}
                onMouseDown={() => {
                    closeModal()
                    // dispatch({
                    //     type: 'UPDATE_EXAM_OR_MATERI',
                    //     payload: 'pretest',
                    // })
                }}>
                    Mulai Sekarang
                </a>
            </div>
        </div> :''
        )
}

export default PretestInfo

