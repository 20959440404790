import React from 'react'

const VoucherCard = ({code}) => {

    return (
        <div className="col-3 course-card__img p-0 mt-2" style={{backgroundImage: `url("/images/courses/myCourseExVoucherBg.png")`, width: '100%', height: '85px', backgroundSize: 'cover'}}>
            <div className="row m-0" style={{height: '85px'}}>
                <p className="col-5 mt-3" style={{color: 'white'}}>Kode Vouher:</p>
                <div className="col-7">
                    <h1 className="mt-4 mb-0" style={{fontSize: '18px', fontWeight: '700'}}>{code}</h1>
                    <p style={{fontSize: '9px', letterSpacing: '0.6px'}}>Telah digunakan</p>
                </div>  
            </div>
           
        </div>
    )
}

export default VoucherCard