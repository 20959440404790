import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import Footer from '../../components/Footer';
import Header from '../../components/Header/Header';
import MenuUser from '../../components/MenuUser';
import { API_URL } from '../../constant';

export default function ReflectiveJournalDetail() {
  const token = localStorage.getItem('access_token');
  const { slug } = useParams();

  const [answers, setAnswers] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [myJournal, setMyJournal] = useState('');
  const [loadingMyJournal, setLoadingMyJournal] = useState(true);

  const questions = [
    {
      q: 'Sebutkan 3 (tiga) pelajaran yang paling berkesan untuk Anda selama mengikuti pelatihan!',
    },
    {
      q: 'Jelaskan hal yang ingin Anda terapkan setelah mengikuti pelatihan ini!',
    },
    {
      q: 'Berikan pendapatmu mengenai pengalaman belajar di kelas pelatihan ini dengan kami!',
    },
    {
      q: 'Berikan pendapatmu mengenai hal yang dapat diperbaiki dari pelatihan ini!',
    },
  ];

  const handleInputAnswer = ({ q, answer }) => {
    let number = `q${q}`;

    setAnswers((prev) => {
      return { ...prev, [number]: answer };
    });
  };

  const submitJournal = async () => {
    setLoadingSubmit(true);
    try {
      let body = {
        student_quiz_attempt_id: Number(slug),
        ...answers,
      };

      let res = await axios.post(`${API_URL}/kjflow/submitjournal`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        NotificationManager.success('Jurnal berhasil dikirim');
        setConfirmModal(false);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      NotificationManager.error(error?.response?.data?.message ?? 'Jurnal gagal dikirim');
    } finally {
      setLoadingSubmit(false);
    }
  };

  const checkJournal = () => {
    return answers?.q1 && answers?.q2 && answers?.q3 && answers?.q4 ? false : true;
  };

  useEffect(() => {
    const fetchMyJournal = async () => {
      try {
        let res = await axios.get(`${API_URL}/kjflow/journalcheckdetail/${slug}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        res = res?.data?.data;

        setMyJournal(res);
      } catch (error) {
        setMyJournal('');
      } finally {
        setLoadingMyJournal(false);
      }
    };

    fetchMyJournal();
  }, [slug, token]);

  return (
    <>
      <Header />
      <main>
        <div className="main-container container student-dashboard">
          <div className="row">
            <div
              className="col-sm-12 col-lg-2 col-xl-2 menu-shadow"
              style={{ padding: '0', zIndex: '2' }}
            >
              <div className="navbar-desc h-100">
                <MenuUser />
              </div>
            </div>
            <div className="col-sm-12 col-lg-10 col-xl-10 w-100 content-user-container">
              <div className="dashboard-container">
                <div className="">
                  <h1 className="content-user__title mt-4">
                    {myJournal?.student_quiz_attempt?.quiz?.course?.name ?? `Journal ${slug}`}
                  </h1>
                  {myJournal?.student_quiz_attempt?.quiz?.name && (
                    <span>({myJournal?.student_quiz_attempt?.quiz?.name})</span>
                  )}
                </div>

                <hr />
                <div
                  className="flex flex-col gap-3 pb-4 border-b"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                  }}
                >
                  {questions?.map((el, i) => {
                    return (
                      <div className="">
                        <p style={{ marginBottom: '8px', fontWeight: '500', fontSize: '16px' }}>
                          {i + 1}. {el.q}
                        </p>
                        <textarea
                          rows="4"
                          style={{
                            width: '100%',
                            borderRadius: '0.5rem',
                            padding: '4px',
                            fontSize: '14px',
                          }}
                          onChange={(e) => handleInputAnswer({ q: i + 1, answer: e.target.value })}
                          disabled={myJournal}
                          placeholder={
                            loadingMyJournal
                              ? 'Loading...'
                              : myJournal
                              ? myJournal[`q${i + 1}`]
                              : 'Silahkan isi jawaban Anda disini...'
                          }
                        ></textarea>
                      </div>
                    );
                  })}
                </div>

                {!myJournal && (
                  <div
                    className="flex justify-end"
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <button
                      className="btn btn-pink"
                      onClick={() => setConfirmModal(true)}
                      disabled={loadingSubmit || checkJournal()}
                    >
                      {loadingSubmit ? 'Loading...' : 'Kirim'}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* modal journal */}
        <ReactModal
          onRequestClose={() => setConfirmModal(false)}
          closeTimeoutMS={100}
          isOpen={confirmModal}
          overlayClassName="journal-popup-overlay"
          className="journal-popup-content"
          ariaHideApp={false}
        >
          <div className="">
            <p style={{ fontWeight: '600', fontSize: '18px', marginBottom: '1rem' }}>
              Apakah Anda ingin menyelesaikan journal?
            </p>

            <div
              className="flex gap-4 items-center w-full"
              style={{ display: 'flex', gap: '1rem', alignItems: 'center', width: '100%' }}
            >
              <button
                className="btn btn-outline-secondary w-100"
                style={{ color: 'black' }}
                onClick={() => setConfirmModal(false)}
              >
                Tidak
              </button>
              <button
                className="btn btn-pink w-100"
                onClick={() => submitJournal()}
                disabled={loadingSubmit}
              >
                {loadingSubmit ? 'Loading...' : 'Ya'}
              </button>
            </div>
          </div>
        </ReactModal>
      </main>
      <Footer />
      <NotificationContainer />

      {loadingMyJournal && <LoadingSpinner />}
    </>
  );
}
