import React, { useEffect, useState } from 'react'
import MyEvent from '../components/MyEvent'
import {useSelector, useDispatch} from 'react-redux'
import { fetchMyEvent } from '../Utils/Utils';


const EventSaya = () => {
    const studentEvent = useSelector(state => state.student_event)
    const dispatch = useDispatch()

    const fetchEvent = () => {
        dispatch({type: 'TOGGLE_LOADING_TRUE'});
        fetchMyEvent()
        .then(response => {
            dispatch({type: 'SET_USER_EVENTS', payload: response.data});
        })
        .catch(error => {
            console.log('error');
        })
        .finally(() => dispatch({type: 'TOGGLE_LOADING_FALSE'}))
    }

    useEffect(() => {
        fetchEvent()
    }, [])

    return (
        <>
            <div  className="dashboard-container">
                <h1 className="content-user__title  mt-4 mb-4 navbar-desc">Event Saya</h1>
                <MyEvent/>
            </div>
        </>
    )
}

export default EventSaya