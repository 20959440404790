import React, { useEffect, useState } from 'react'
import LandingPage from './LandingPage'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert';
import { NotificationManager } from 'react-notifications';

const MarkAttendance = () => {
    const { schedule_id, course_id, is_qna_session } = useParams()
    const [alert, setAlert] = useState()
    const dispatch = useDispatch()
    const history = useHistory()

    const isLoggedIn = async () => {
        var result = false;
        const access_token = localStorage.getItem('access_token');
        if (access_token) {
            dispatch({type: 'TOGGLE_LOADING_TRUE'})
            await axios
            .get(`${window.$API_URL}api/auth/me/`, {
              headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
            })
            .then((success) => {
              dispatch({type: 'TOGGLE_LOADING_FALSE'})
              if (success.data.success) {
                result = true;
              } else {
                result = false;
              }
            })
            .catch((error) => {
              result = false;
              dispatch({type: 'TOGGLE_LOADING_FALSE'})
            })
            
        } else {
          result = false;
        }
        return result;
    }

    const MarkAttendance = () => {
        const access_token = localStorage.getItem('access_token');
        dispatch({type: 'TOGGLE_LOADING_TRUE'})
        axios.post(
        `${window.$API_URL}api/attendance/markattendance`,
            {
                schedule_id,
                course_id,
                is_qna_session
            },
            {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
            },
        ).then(_ => {
            dispatch({type: 'TOGGLE_LOADING_FALSE'})
            const getAlert = () => (
                <SweetAlert 
                  success 
                  title="Berhasil!" 
                  onConfirm={() => history.push('/student/course')}
                >
                  Anda sudah berhasil melakukan absen pada QnA! Selesaikan Exam dan Rating untuk menyelesaikan kelas.
                </SweetAlert>
            )
            setAlert(getAlert())
        }).catch(error => {
            // console.log(error.response)
            dispatch({type: 'TOGGLE_LOADING_FALSE'})
            NotificationManager.error('Proses gagal dilakukan', 'Error!');
        })
    }

    // console.log(schedule_id, course_id, is_qna_session)

    useEffect(() => {
        const checkLoggedIn = async () => {
            let loggedIn = await isLoggedIn();
            if (loggedIn) {
                MarkAttendance()
            } else {
                dispatch({
                    type: 'UPDATE_LOGINPOPUP_OPEN',
                    payload: 1,
                })
            }
        }
        checkLoggedIn()
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <LandingPage />
            {alert}
        </>
    )
}

export default MarkAttendance