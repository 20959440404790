import React from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

const LocationIcon = () => {
    return (
        <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0C11.31 0 14 2.66 14 5.95C14 10.41 8 17 8 17C8 17 2 10.41 2 5.95C2 2.66 4.69 0 8 0ZM8 4C7.46957 4 6.96086 4.21071 6.58579 4.58579C6.21071 4.96086 6 5.46957 6 6C6 6.53043 6.21071 7.03914 6.58579 7.41421C6.96086 7.78929 7.46957 8 8 8C8.53043 8 9.03914 7.78929 9.41421 7.41421C9.78929 7.03914 10 6.53043 10 6C10 5.46957 9.78929 4.96086 9.41421 4.58579C9.03914 4.21071 8.53043 4 8 4ZM16 17C16 19.21 12.42 21 8 21C3.58 21 0 19.21 0 17C0 15.71 1.22 14.56 3.11 13.83L3.75 14.74C2.67 15.19 2 15.81 2 16.5C2 17.88 4.69 19 8 19C11.31 19 14 17.88 14 16.5C14 15.81 13.33 15.19 12.25 14.74L12.89 13.83C14.78 14.56 16 15.71 16 17Z" fill="black"/>
        </svg>
    )
}

const EventList = ({ event }) => {
    const history = useHistory()
    return (
        <>
        <div className="d-flex my-event__container mb-4">
            <div className="d-flex">
                {event.image ? (
                    event.image.url ? (<img src={event.image.url} alt="event-image" className="my-event__image"></img>) :''
                ) : ''}
                
                <div className="d-flex flex-column ml-4 my-event__container__sub">
                    {event.is_kartu_prakreja ? (
                        <p className="my-event__prakerja">Kartu Prakerja</p>
                    ) : ''}
                    <p className="course-card__class-name mt-0">{event.title}</p>
                    <div className="d-flex mt-3 align-items-center">
                        <LocationIcon/>
                        <p className="ml-3 mr-1 my-event__bold-text">Event:</p>
                        <p style={{textTransform: 'capitalize', fontWeight: '300'}} className="my-event__bold-text">{event.location}</p>

                    </div>
                    <div className="navbar-desc mt-auto ">
                        <div className="d-flex mt-auto align-items-center">
                                
                            {event.payment.booking_days[0] ? 
                                <div className=" w-100">
                                    {event.payment.booking_days.map(el => (
                                        <div className="d-flex mt-4">
                                            <div>
                                                <p className="order-detail-page__detail-name" style={{marginTop: '0', fontWeight: '500'}}>{el.day.title}</p>
                                                <p className="order-detail-page__detail-name" style={{marginTop: '0', fontSize: '12px'}}>{`${moment(el.day.date).format('D MMMM YYYY')}, ${moment(el.day.date).format('HH:mm')} - ${moment(el.day.end_date).format('HH:mm')} WIB`}</p>
                                            </div>
                                            <div className="ml-auto d-flex">
                                                <div className="hosted-jadwal-qna__zoom" onClick={(e) => window.open(el.day.zoom_link, "_blank")}>
                                                    <div><img src="/images/courses/zoom.png" className="hosted-jadwal-qna__zoom-img"/></div>
                                                    <p className="navbar-desc">Link Zoom</p>
                                                </div>
                                                <div className="hosted-jadwal-qna__wa ml-2" onClick={(e) => window.open(el.day.whatsapp_link, "_blank")}>
                                                    <div><img src="/images/courses/wa.png" className="hosted-jadwal-qna__wa-img"/></div>
                                                    <p className="navbar-desc">Grup WhatsApp</p>
                                                </div>
                                            </div>
                                        </div>
                                    )) }
                                </div> : 
                                <>
                                    <p className="mr-1 my-event__bold-text">Tanggal:</p>
                                    <p style={{fontWeight: '300'}} className="my-event__bold-text mr-1 pl-3 pr-3 my-event__border-right">{event.start_date ? moment(event.start_date).format('DD MMM YYYY') : ''}</p>
                                    <p className="ml-3 mr-2 my-event__bold-text">Jam:</p>
                                    <p style={{fontWeight: '300'}} className="my-event__bold-text">{`${event.start_date ? moment(event.start_date).format('hh.mm') : ''} - ${event.end_date ? moment(event.end_date).format('hh.mm') + ' WIB' : 'Selesai   '}`}</p>
                                </>
                            }
                            {event.payment.booking_days[0] ? '' :
                            <div className="btn btn-pink my-event__detil-btn"
                            onClick={() => history.push(`/webinar/${event.id}`)}>Detail Event</div>}
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="navbar-mob">
                <div className="d-flex mt-3 align-items-center">
                    {event.payment.booking_days[0] ? 
                        <div className=" w-100">
                            {event.payment.booking_days.map(el => (
                                <div className="d-flex mt-4">
                                    <div>
                                        <p className="order-detail-page__detail-name" style={{marginTop: '0', fontWeight: '500'}}>{el.day.title}</p>
                                        <p className="order-detail-page__detail-name" style={{marginTop: '0', fontSize: '12px'}}>{`${moment(el.day.date).format('D MMMM YYYY')}, ${moment(el.day.date).format('HH:mm')} - ${moment(el.day.end_date).format('HH:mm')} WIB`}</p>
                                    </div>
                                    <div className="ml-auto d-flex">
                                        <div className="hosted-jadwal-qna__zoom" onClick={(e) => window.open(el.day.zoom_link, "_blank")}>
                                            <div><img src="/images/courses/zoom.png" className="hosted-jadwal-qna__zoom-img"/></div>
                                            <p className="navbar-desc">Link Zoom</p>
                                        </div>
                                        <div className="hosted-jadwal-qna__wa ml-2" onClick={(e) => window.open(el.day.whatsapp_link, "_blank")}>
                                            <div><img src="/images/courses/wa.png" className="hosted-jadwal-qna__wa-img"/></div>
                                            <p className="navbar-desc">Grup WhatsApp</p>
                                        </div>
                                    </div>
                                </div>
                            )) }
                        </div> : 
                        <>
                            <p className="mr-1 my-event__bold-text">Tanggal:</p>
                            <p style={{fontWeight: '300'}} className="my-event__bold-text mr-1 pl-3 pr-3 my-event__border-right">{event.start_date ? moment(event.start_date).format('DD MMM YYYY') : ''}</p>
                            <p className="ml-3 mr-2 my-event__bold-text">Jam:</p>
                            <p style={{fontWeight: '300'}} className="my-event__bold-text">{`${event.start_date ? moment(event.start_date).format('hh.mm') : ''} - ${event.end_date ? moment(event.end_date).format('hh.mm') + ' WIB' : 'Selesai   '}`}</p>
                        </>
                    }
                    
                </div>
                {event.payment.booking_days[0] ? '' :
                <div className="btn btn-pink my-event__detil-btn"
                onClick={() => history.push(`/webinar/${event.id}`)}>Detail Event</div>}
            </div>
        </div>
        </>
    )
}

export default EventList