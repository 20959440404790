import React from 'react';
// import { Link} from 'react-router-dom'
// import { Redirect, Route,  } from 'react-router-dom';
// import * as router from 'react-router-dom';
class LandingFooter extends React.Component {
  render() {
    return (
      <>
        <section className="footer_bottom_area home4 pt30 pb30">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <div className="copyright-widget text-center">
                  <p>Copyright MyEduSolveX © 2020. All Rights Reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default LandingFooter;
