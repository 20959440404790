import React from 'react';
import { connect } from 'react-redux';
import { isLoggedIn, removeScript } from '../Utils/Utils';
import Footer from '../components/Footer';
import Header from '../components/Header/Header';
import CarouselBanner from '../components/LandingNew/CarouselBanner';
import CourseLanding from '../components/LandingNew/CourseLanding';
import Ekosistem from '../components/LandingNew/Ekosistem';
import FeaturedBy from '../components/LandingNew/FeaturedBy';
import InstructorBanner from '../components/LandingNew/InstructorBanner';
import InstructorLanding from '../components/LandingNew/InstructorLanding';
import LandingBannerNew from '../components/LandingNew/LandingBannerNew';
import OurClient from '../components/LandingNew/OurClient';
import PengalamanBelajar from '../components/LandingNew/PengalamanBelajar';
import SertifikatLanding from '../components/LandingNew/SertifikatLanding';
import Tentang from '../components/LandingNew/Tentang';

import { Helmet } from 'react-helmet';
import LoadingSpinner from '../Layout/LoadingSpinner';

class LandingPageNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    if (this.props.refer) {
      this.props.dispatch({
        type: 'UPDATE_LOGINPOPUP_OPEN',
        payload: 1,
      });
      this.props.dispatch({
        type: 'UPDATE_REGISTERPOPUP_OPEN',
        payload: 1,
      });
    }
    this.props.dispatch({ type: 'TOGGLE_LOADING_TRUE' });
    const user_check_api = await isLoggedIn();
    if (user_check_api.profile_data !== null) {
      if (!user_check_api.profile_data?.is_onboarding_done) {
        window.location.href = '/complete-on-boarding';
      }
    }
    this.props.dispatch({ type: 'TOGGLE_LOADING_FALSE' });
    window.gtag('event', 'conversion', { send_to: 'AW-651417204/1HVKCPu3rIACEPSsz7YC' });
  };

  componentWillUnmount() {
    removeScript('https://app.sandbox.midtrans.com/snap/snap.js');
  }

  render() {
    return (
      <>
        <Header />
        <CarouselBanner />

        <Helmet>
          <meta charSet="utf-8" />
          <title>MyEduSolveX | Kelas Online Literasi Digital Terbaik</title>
          <meta name="title" content="MyEduSolveX | Kelas Online Literasi Digital Terbaik" />
          <meta
            name="description"
            content="Yuk, tingkatkan daya saingmu dengan mengikuti kelas online bareng MyEduSolveX. Akses dimana saja, kapan saja!"
          />
          <meta property="og:title" content="MyEduSolveX | Kelas Online Literasi Digital Terbaik" />
          <meta
            property="twitter:title"
            content="MyEduSolveX | Kelas Online Literasi Digital Terbaik"
          />
          <meta
            property="og:description"
            content="Yuk, tingkatkan daya saingmu dengan mengikuti kelas online bareng MyEduSolveX. Akses dimana saja, kapan saja!"
          />
          <meta
            property="twitter:description"
            content="Yuk, tingkatkan daya saingmu dengan mengikuti kelas online bareng MyEduSolveX. Akses dimana saja, kapan saja!"
          />
          <meta
            name="google-site-verification"
            content="L9n7ma8RGq0-0kUevozDWC8mg2D7kwK9SsnvndWdQEw"
          />
          <script src="https://www.googleoptimize.com/optimize.js?id=OPT-5S5MCK4"></script>
        </Helmet>

        <div className="container container-homepage d-flex flex-column">
          <LandingBannerNew />

          <SertifikatLanding />
        </div>

        <div className="bg-container-white">
          <div className="container container-homepage d-flex flex-column">
            <FeaturedBy />
            <CourseLanding />
            <Tentang />
            <InstructorLanding />
          </div>
          <Ekosistem />
          <div className="container container-homepage d-flex flex-column">
            <PengalamanBelajar />
            <InstructorBanner />
          </div>
        </div>

        <OurClient />
        <Footer />
        {this.props.isLoading ? <LoadingSpinner text={'Mohon menunggu..'} /> : ''}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { isLoading: state.isLoading, profile_data: state.profile_data };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageNew);
