import React from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import HowToDownload from '../HowToDownload';

const CourseFeature = () => {
  const course_detail = useSelector((state) => state.course_detail);
  const levelObj = {
    "Beginner": 'Kelas ini dirancang bagi peserta di tingkat pemula',
    "Pemula": 'Kelas ini dirancang bagi peserta di tingkat pemula',
    "Intermediate": 'Kategori kelas level menengah',
    "Advanced": 'Kelas yang diperuntukkan untuk tingkat ahli/lanjutan',
    "Semua Tingkat": 'Kelas ini dapat diikuti oleh peserta dari tingkat manapun'
}

  if (!course_detail) return '';
  return (
    <div className="course-feature">
      <div>
        <div>
          <div>
            <div>
              <img src="/images/courses/new_detail/fitur_language.png" />
            </div>
          </div>
          <p>{course_detail.course_language}</p>
        </div>
        <div>
          <div>
            <div>
              <img src="/images/courses/new_detail/fitur_method.png" />
            </div>
          </div>
          <p>{course_detail.method_of_study}</p>
        </div>
      </div>

      <div className='py-mob6-des10'>
        <HowToDownload />
      </div>
      
      <div>
        <div>
          <div>
            <img src="/images/courses/new_detail/fitur_sertifikat.jpg" />
          </div>
          <h2>Sertifikat</h2>
          <p>{`Peserta akan mendapatkan sertifikat setelah menyelesaikan kelas ${
            course_detail.retail_price != 0.0 ? 'dan memenuhi nilai yang mencukupi.' : ''
          }`}</p>
        </div>
        <div>
          <div>
            <div>
              <img src="/images/courses/new_detail/fitur_level.png" />
            </div>
            <p>Direkomendasikan Untuk</p>
            <h2>{course_detail.level}</h2>
          </div>
          <h2>Tingkat Keahlian</h2>
          <p>{levelObj[course_detail.level]}</p>
        </div>
        {/* <div>
                    <div>
                        <div><img src="/images/courses/new_detail/fitur_tugas.png"/></div>
                        <p>Peserta akan  Mendapatkan Penilaian</p>
                    </div>
                    <h2>Tugas</h2>
                    <p>Semua peserta akan mendapatkan tugas di kelas ini, dan mendapatkan penilaian.</p>
                </div> */}
      </div>
    </div>
  );
};

export default CourseFeature;
