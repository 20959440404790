import React, { useState, useRef, useEffect } from 'react';
import { AssignmentIcon } from '../../static';
import Button from '../Button';

const RatingWarning = ({closeModal}) => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-around modal-hosted-course">
            <h1 className="assignment__title mb-3">Exam</h1>
            <h2 className="rating__desc_2 align-self-start mt-3 mb-5">Sebelum mengikuti Exam pada kelas ini, kamu harus menyelesaikan semua materi kemudian memberikan rating dan review terlebih dahulu.</h2>
            <div className='rating__btn align-self-stretch d-flex flex-column'>
                <a className='btn btn-lg btn-pink align-self-stretch' style={{fontSize: '16px'}} onMouseDown={closeModal}>
                    OK
                </a>
            </div>
        </div>
        )
}

export default RatingWarning

