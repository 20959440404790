import React from 'react';
import { connect } from 'react-redux'

const Title = ({ title }) => {
    return (
        <div>
            <h1 className="course-title">{title}</h1>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        title: state.banner_detail,
    }
}
export default connect(
    mapStateToProps,
)(Title);