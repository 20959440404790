import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect, useDispatch, useSelector } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'
import GoogleSignInButton from './GoogleSignInButton'
import FacebookSignInButton from './FacebookSignInButton'
import { useParams } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const LoadingIcon = ({loading}) => <div style={{display: loading ? 'block' : 'none'}}><img src="/images/smallLoading.gif"/></div>

const LoginModal = ({ loginAsInstrutor, setAlert, setShowing, setIsLoginActive, setIsRegisterActive, isRegisterActive, isInterestInstructor, dismissModalSignInPopup }) => {
  const [registerApiUrl, setRegisterApiUrl] = useState('api/register/student/')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [firstName, setFirstName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  const [firstNameError, setFirstNameError] = useState('')
  const [phoneNumberError, setPhoneNumberError] = useState('')
  const [emailError, setEmailError] = useState('')

  const [loading, setLoading] = useState(0)
  const voucherCode = useSelector(state => state.voucher_to_be_redeem)
  const dispatch = useDispatch()
  const { referral_id } = useParams()
  const gtag_report_conversion = (url) => { var callback = function () { if (typeof (url) != 'undefined') { window.location = url; } }; window.gtag('event', 'conversion', { 'send_to': 'AW-651417204/czijCPLbzoACEPSsz7YC', 'event_callback': callback }); return false; }

  const googleLogin = (response, isError) => {
    if (!isError) {
      setLoading(1)
      axios({
        method: 'POST',
        url: `${window.$API_URL}api/auth/login/google`,
        // headers: { 'Content-Type': 'application/json', 'x-access-token': '' },
        data: {
          email: response.profileObj.email,
          first_name: response.profileObj.givenName,
          last_name: response.profileObj.familyName ? response.profileObj.familyName : response.profileObj.givenName,
          id_token: response.tokenId
        }
      })
        .then((success) => {
          setLoading(0)
          if (success.data.success) {
            dismissModalSignInPopup();
            localStorage.setItem('access_token', success.data.data.access_token);
            localStorage.setItem('token_type', success.data.data.token_type);
            localStorage.setItem('expires_in', success.data.data.expires_in);
            if (voucherCode) {
              localStorage.setItem('voucher_code', voucherCode)
              localStorage.removeItem('voucher_register')
              dispatch({ type: 'STORE_VOUCHER_CODE', payload: null })
            }
            setTimeout(() => window.location.reload(), 500);
              //window.location.reload();
          }
        })
        .catch((error) => {
          setLoading(0)
          if (error.response) {
            if (error.response.status === 422) {
              let errorTemp = []
              Object.keys(error.response.data.errors).forEach((key) => {
                errorTemp.push(error.response.data.errors[key][0]);
              });
              setShowing(true);
              // setState({ returnError: state.returnError, showing: state.showing });
              const errorList2 = errorTemp.map((char, i) => {
                return (
                  <li key={i} style={{ listStyleType: 'none' }}>
                    {char}
                  </li>
                );
              });
              NotificationManager.error(errorList2, 'Validasi Error!');
            }
            if (error.response.status === 401) {
              NotificationManager.error(error.response.data.message, 'Validasi Error!');
            } else {
              NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
            }
          } else {
            NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
          }
        })
    } else {
      //NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
    }
  }

  const facebookLogin = (response, isError) => {
    if (!isError) {
      setLoading(1)
      axios({
        method: 'POST',
        url: `${window.$API_URL}api/auth/login/facebook`,
        // headers: { 'Content-Type': 'application/json', 'x-access-token': '' },
        data: {
          email: response.email,
          first_name: response.name,
          last_name: "",
          access_token: response.accessToken
        }
      })
        .then((success) => {
          setLoading(0)
          if (success.data.success) {
            dismissModalSignInPopup();
            localStorage.setItem('access_token', success.data.data.access_token);
            localStorage.setItem('token_type', success.data.data.token_type);
            localStorage.setItem('expires_in', success.data.data.expires_in);
            if (voucherCode) {
              localStorage.setItem('voucher_code', voucherCode)
              localStorage.removeItem('voucher_register')
              dispatch({ type: 'STORE_VOUCHER_CODE', payload: null })
            }
            setTimeout(() => window.location.reload(), 500);
              //window.location.reload();
          }
        })
        .catch((error) => {
          setLoading(0)
          if (error.response) {
            if (error.response.status === 422) {
              let errorTemp = []
              Object.keys(error.response.data.errors).forEach((key) => {
                errorTemp.push(error.response.data.errors[key][0]);
              });
              setShowing(true);
              // setState({ returnError: state.returnError, showing: state.showing });
              const errorList2 = errorTemp.map((char, i) => {
                return (
                  <li key={i} style={{ listStyleType: 'none' }}>
                    {char}
                  </li>
                );
              });
              NotificationManager.error(errorList2, 'Validasi Error!');
            }
            if (error.response.status === 401) {
              NotificationManager.error(error.response.data.message, 'Validasi Error!');
            } else {
              NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
            }
          } else {
            NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
          }
        })
    } else {
      //NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
    }
  }

  const registerStudent = (e) => {
    if(!firstName || !phoneNumber || !email) {
      if(!firstName) setFirstNameError('Harap isi nama Anda.')
      if(!phoneNumber) setPhoneNumberError('Harap isi nomor telepon.')
      if(!email) setEmailError('Harap isi email.')
      return ''
    }
    setFirstNameError('')
    setPhoneNumberError('')
    setEmailError('')
    setLoading(1)
    const dataToSend = {
      first_name: firstName,
      phone_number: phoneNumber,
      username: email,
      password: password,
      email: email,
      password_confirmation: passwordConfirmation,
      is_interest_instructor: isInterestInstructor
    }

    if (referral_id) dataToSend['referral_user_id'] = referral_id

    //  const response = await axios.get(window.$API_URL+'api/fetchusertimezone/',{
    const response = axios
      .post(
        `${window.$API_URL + registerApiUrl}`, dataToSend,
        {
          headers: { 'Content-Type': 'application/json', 'x-access-token': '' },
        },
      )
      .then((success) => {
        setLoading(0)

        if (success.data.success) {
          gtag_report_conversion()
          const dataPixel = success?.data?.data
          ReactPixel.track('register', {
            fn: dataPixel.first_name,
            em: dataPixel.email,
            ph: dataPixel.phone_number,
            register_time: dataPixel.created_at
          })
          mixpanel.track('Register')
          dismissModalSignInPopup();
          setIsLoginActive(1)
          setIsRegisterActive(0)
          if (voucherCode) {
            localStorage.setItem('voucher_code', voucherCode)
            localStorage.setItem('voucher_register', 1)
            dispatch({ type: 'STORE_VOUCHER_CODE', payload: null })
          }
          const getAlert = () => (
            <SweetAlert
              success
              showCancel={false}
              confirmBtnText="OK"
              confirmBtnBsStyle="success"
              title="Akun Berhasil Didaftarkan"
              onConfirm={() => setAlert('')}
            >
              Akun Berhasil Didaftarkan - Silahkan cek email untuk menyelesaikan proses verifikasi.
            </SweetAlert>
          );
          setAlert(getAlert())
        }
      })
      .catch((error) => {
        setLoading(0)
        if (!error.response) {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
          return false
        }
        if (error.response.status == '422') {
          let errorTemp = [];
          Object.keys(error.response.data.errors).forEach((key) => {
            if (key == 'phone_number') {
              if (error.response.data.errors[key][0] == 'The phone number field is not a valid number.') setPhoneNumberError('Format nomor telepon salah.')
              else if (error.response.data.errors[key][0] == 'Phone number wajib diisi.') setPhoneNumberError('Nomor telpon wajib diisi.')
            } else if (key == 'email') {
              if (error.response.data.errors[key][0] == 'Email sudah ada sebelumnya.') setEmailError('Email sudah digunakan sebelumnya.')
              else if (error.response.data.errors[key][0] == 'Email harus berupa alamat surel yang valid.') setEmailError('Format Email salah.')
              else setEmailError(error.response.data.errors[key][0])
            } else if (key == 'first_name') {
              if (error.response.data.errors[key][0] == 'First name wajib diisi.') setFirstNameError('Nama wajib diisi')
            } else if (key == 'username') {

            }
            else {
              errorTemp.push(error.response.data.errors[key][0]);
            }
          });
        } else {
          NotificationManager.error('Beberapa Kesalahan Terjadi di API Pendaftaran!', 'Validasi Error!');
        }
      });
  }

  return (
    <div
      className={`overflow-scroll tab-pane fade ${isRegisterActive ? 'show active' : ''} `}
    >
      <div className="login_form">
        <div className="heading d-flex align-items-center flex-column mb-1 login_form__header">
          <img src="/images/logo_login.png" className="logo"></img>
          <h3>Buat Akun</h3>
          <p>Isi formulir dibawah ini.</p>
        </div>
        <form action="#">
          <div className="form-group">
            <h4 className="login-label">Nama Lengkap</h4>
            <input
              required
              type="text"
              className={`form-control sign_up_form__form ${firstNameError ? 'sign_up_form__form-error' : ''}`}
              placeholder="Nama Lengkap Sesuai KTP"
              onChange={(e) => setFirstName(e.target.value)}
            />
            <p>{firstNameError}</p>
          </div>

          <div className="form-group">
            <h4 className="login-label">Nomor Telepon</h4>
            <input
              required
              type="text"
              className={`form-control sign_up_form__form ${phoneNumberError ? 'sign_up_form__form-error' : ''}`}
              placeholder="081234567890"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <p>{phoneNumberError}</p>
          </div>

          <div className="form-group">
            <h4 className="login-label">Email</h4>
            <input
              required
              type="email"
              className={`form-control sign_up_form__form ${emailError ? 'sign_up_form__form-error' : ''}`}
              placeholder="email@email.com"
              onChange={(e) => setEmail(e.target.value)}
            />
            <p>{emailError}</p>
          </div>

          {/* <div className="form-group form-check">
            <input type="checkbox" className="form-check-input" onClick={(e) => loginAsInstrutor(e)} />
            <label className="form-check-label" htmlFor="exampleCheck2">
              Ingin menjadi instruktur?
              </label>
          </div> */}
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault()
              registerStudent()
            }}
            className="btn btn-block btn-thm2 masuk-btn mb-1 btn-pink"
            disabled={loading}
            type="submit"
          >
            <LoadingIcon loading={loading}/>
            <p>Daftar</p>
            </button>
        </form>
        <div className="login_form__googlefbbtn">
          <p>Daftar menggunakan:</p>
          <div>
            <GoogleSignInButton  signup googleLogin={googleLogin}/>
            <FacebookSignInButton signup facebookLogin={facebookLogin}/>
          </div>
        </div>
        {/*<h4 className="silahkan mr-auto mt-0">Daftar menggunakan: </h4>
        <div className="d-flex justify-content-center mt-3 logos-border pb-4 mb-4">
          <img src="/images/home/google_signin.png" className="signin-logos mr-4"></img>
          <img src="/images/home/fb_signin.png" className="signin-logos mr-4"></img>
          <img src="/images/home/twitter_signin.png" className="signin-logos"></img>
  </div>*/ }
        <div className="login_form__bottom-text">
          <h3>Apakah Anda sudah memiliki akun? </h3>
          <div>
            <h3 className="mr-1">Silahkan </h3>
            <a
              className="text-thm pointer"
              onClick={(e) => {
                setIsLoginActive(1)
                setIsRegisterActive(0)
              }}
            >
              <h3 className=" membuat-akun">Masuk</h3>
            </a>
          </div>
        </div>

      </div>
    </div>
  )
}

export default LoginModal
