import React, { useRef, useState } from 'react'
import Carousel from "react-multi-carousel";
import { useMediaQuery } from 'react-responsive';

const partnerData = [
    // {img: '', class: 26, url:'/'},
{img: '/images/prakerja/pijaricon.png', class: 17, url:'https://pijarmahir.id/institution-partner/17549?page=1&limit=10&sort=0'},
{img: '/images/prakerja/pintariaicon.png', class: 2, url:'https://pintaria.com/semua-kursus/cari/hasil?keyword=myedusolve'},
{img: '/images/prakerja/blicon.png', class: 5, url:'https://m.bukalapak.com/kartu-prakerja/pencarian?source=https%3A%2F%2Fwww.google.com%2F&keyword=myedusolve'},
{img: '/images/prakerja/mbaicon.png', class: 2, url:'https://maubelajarapa.com/educator/myedusolve/classes/'}]

function Partner() {
    const isMobile = useMediaQuery({ query: '(max-width: 992px)' })
    let carousel = useRef(null)
    const [dataToShow, setDataToShow] = useState(0) 
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 1052 },
          items: 3.5,
        },
        largeDesktop: {
            breakpoint: { max: 1052, min: 848 },
            items: 2.5,
        },
        mobileLarge: {
            breakpoint: { max: 1052, min: 848 },
            items: 2.5,
        },
        mobile: {
            breakpoint: { max: 848, min: 0 },
            items: 1.3,
        }
    }
    
    return (
        <div className="prakerja-partner">
            <div>
                <h2 className="prakerja-title">Partner kartu prakerja</h2>
                <div>
                    <div onClick={() => carousel.previous()}><ArrowLeft/></div>
                    <div onClick={() => carousel.next()}><ArrowRight/></div>
                </div>
            </div>
            <div>
                <Carousel 
                    responsive={responsive}
                    arrows={false}
                    ref={(el) => (carousel = el)}
                    afterChange={(previousSlide, { currentSlide, onMove }) => {
                        let currentData = dataToShow
                        if(previousSlide - currentSlide < 0 ) {
                            if(currentData - 1 < 0) setDataToShow(partnerData.length - 1)
                            else setDataToShow(currentData - 1)
                        } else {
                            if(!partnerData[currentData + 1]) setDataToShow(0)
                            else setDataToShow(currentData + 1)
                        }
                    }}
                    >
                        {partnerData.map(el => (
                            <div className="prakerja-partner__partners">
                                <div>
                                    <div>
                                        {el.img ? <div><img src={el.img}/></div> : <div><h2>BEST SELLER</h2></div>}
                                        <div><ArrowPartner/></div>
                                    </div>
                                    <div>
                                        {/* <h2>Tersedia</h2> */}
                                        {/* <p>{`${el.class} Kelas`}</p> */}
                                    </div>
                                    <div>
                                        <button className="btn btn-pink" onClick={(e) => {
                                            e.preventDefault()
                                            window.open(el.url, "_blank")
                                        }}><p>Lihat Partner</p><Arrow/></button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                
            </div>
        </div>
    )
}

const ArrowRight = () => <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.72125 0.5L0.488754 2.7325L7.74042 10L0.488754 17.2675L2.72125 19.5L12.2213 10L2.72125 0.5Z" fill="#C4CCCB"/>
</svg>

const ArrowLeft = () => <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.2787 0.5L12.5112 2.7325L5.25958 10L12.5112 17.2675L10.2787 19.5L0.778746 10L10.2787 0.5Z" fill="#C4CCCB"/>
</svg>

const ArrowLeftMob = () => <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.42428 0.919922L7.79986 2.34872L3.33166 6.99992L7.79986 11.6511L6.42428 13.0799L0.570744 6.99992L6.42428 0.919922Z" fill="#C4CCCB"/>
</svg>

const ArrowRightMob = () => <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.57571 0.919922L0.200134 2.34872L4.66833 6.99992L0.200134 11.6511L1.57571 13.0799L7.42925 6.99992L1.57571 0.919922Z" fill="#C4CCCB"/>
</svg>

const ArrowPartner = () => <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 16.7675L6.79892 9.5L0 2.2325L2.09312 0L11 9.5L2.09312 19L0 16.7675Z" fill="#007EA7"/>
</svg>

const Arrow = () => <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.47251 6.66656H8.91918L5.66585 9.91989C5.40585 10.1799 5.40585 10.6066 5.66585 10.8666C5.92585 11.1266 6.34585 11.1266 6.60585 10.8666L10.9992 6.47322C11.2592 6.21322 11.2592 5.79322 10.9992 5.53322L6.61251 1.13322C6.48796 1.00839 6.31886 0.938232 6.14251 0.938232C5.96617 0.938232 5.79707 1.00839 5.67251 1.13322C5.41251 1.39322 5.41251 1.81322 5.67251 2.07322L8.91918 5.33322H1.47251C1.10585 5.33322 0.805847 5.63322 0.805847 5.99989C0.805847 6.36656 1.10585 6.66656 1.47251 6.66656Z" fill="white"/>
</svg>


export default Partner
