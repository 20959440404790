import axios from 'axios';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
  MenuAkun,
  MenuEvent,
  MenuKelas,
  MenuPesanan,
  MenuRefferal,
  ReflectiveJournal,
  TugasMandiri,
  UnjukKeterampilan,
} from '../../static';

const menu = [
  {
    name: 'Kelas Saya',
    url: 'course',
    desc: 'Kelas Berlangsung, Sertifikat, Info Prakerja',
    logo: <MenuKelas fill="black" />,
    logoSelected: <MenuKelas fill="#FF0F51" />,
  },
  {
    name: 'Reflective Journal',
    url: 'reflective-journal',
    desc: 'Bantu kami untuk mengembangkan pelatihan ini lebih baik lagi',
    logo: <ReflectiveJournal fill="black" />,
    logoSelected: <ReflectiveJournal fill="#FF0F51" />,
  },
  {
    name: 'Tugas Mandiri',
    url: 'tugas-mandiri',
    desc: '',
    logo: <TugasMandiri fill="black" />,
    logoSelected: <TugasMandiri fill="#FF0F51" />,
  },
  {
    name: 'Unjuk Keterampilan',
    url: 'unjuk-keterampilan',
    desc: '',
    logo: <UnjukKeterampilan fill="black" />,
    logoSelected: <UnjukKeterampilan fill="#FF0F51" />,
  },
  {
    name: 'Event Saya',
    url: 'events',
    desc: 'Daftar Event ',
    logo: <MenuEvent fill="black" />,
    logoSelected: <MenuEvent fill="#FF0F51" />,
  },
  {
    name: 'Pesanan Saya',
    url: 'orders',
    desc: 'Bukti Pesanan dan Transaksi',
    logo: <MenuPesanan fill="black" />,
    logoSelected: <MenuPesanan fill="#FF0F51" />,
  },
  {
    name: 'Akun',
    url: 'profile',
    desc: 'Pengaturan Akun, Password',
    logo: <MenuAkun fill="black" />,
    logoSelected: <MenuAkun fill="#FF0F51" />,
  },
  {
    name: 'Referral',
    url: 'referral',
    desc: 'Progress program referral mu!',
    logo: <MenuRefferal fill="black" />,
    logoSelected: <MenuRefferal fill="#FF0F51" />,
  },
];

const MenuUser = () => {
  const { profile_menu } = useParams();
  const logout = () => {
    const access_token = localStorage.getItem('access_token');
    axios
      .post(
        `${window.$API_URL}api/auth/logout/`,
        {},
        {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
        },
      )
      .then((success) => {
        window.location.href = '/';
        localStorage.removeItem('access_token');
        NotificationManager.success('Sampai jumpa kembali', 'Berhasil Logout!');
      })
      .catch((error) => {
        window.location.href = '/';
        localStorage.removeItem('access_token');
        NotificationManager.success('Sampai jumpa kembali', 'Berhasil Logout!');
      });
  };
  return (
    <>
      <div className="menu-user h-100">
        <h1 className="menu-user__title">Menu</h1>
        <ul>
          {menu.map((el) => (
            <li>
              <Link to={`/student/${el.url}`}>
                {profile_menu === el.url ? (
                  <div className="d-flex pb-3 mt-2 align-items-center menu-user__container">
                    <div className="ml-2">{el.logoSelected}</div>
                    <div>
                      <p className="menu-user__link_selected ml-4">{el.name}</p>
                      <p className="menu-user__small-text ml-4 navbar-mob">{el.desc}</p>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex pb-3 mt-2 align-items-center menu-user__container">
                    <div className="ml-2">{el.logo}</div>
                    <div>
                      <p className="menu-user__link ml-4">{el.name}</p>
                      <p className="menu-user__small-text ml-4 navbar-mob">{el.desc}</p>
                    </div>
                  </div>
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="menu-user navbar-mob mt-4 mb-2">
        <ul>
          <li>
            <button onClick={() => logout()}>
              <div className="d-flex pb-3 mt-2 align-items-center menu-user__container">
                <img src="/images/keluar.png" className="ml-2" alt="" />
                <div>
                  <p className="menu-user__link ml-4" style={{ color: '#E00814', padding: '0' }}>
                    Keluar
                  </p>
                </div>
              </div>
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MenuUser;
