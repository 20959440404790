import React, { useState, useRef, useEffect } from 'react';
import { CourseRating, CourseRatingFull } from '../../static';

const RatingStars = ({ setRatingValue, rating_value }) => {
    const [rating, setRating] = useState(0)
    const [clickedRating, setClickedRating] = useState(0)

    const ratingFun = () => {
        if (clickedRating == 0 || rating > clickedRating) {
            return rating
        }

        return clickedRating;
    }

    useEffect(() => {
        setRatingValue(clickedRating);
    }, [clickedRating])

    useEffect(() => {
        if(rating_value){
            setRatingValue(rating_value)
            setClickedRating(rating_value)
        }
    }, [])

    return (
        <div className='d-flex rating__stars'>
            <div className="course-rating"
                onMouseEnter={() => setRating(1)}
                onMouseLeave={() => setRating(0)}
                onMouseDown={() => setClickedRating(1)}
            >
                <div style={{ display: ratingFun() < 1 ? 'block' : 'none' }}><CourseRating /></div>
                <div style={{ display: ratingFun() > 0 ? 'block' : 'none' }}><CourseRatingFull /></div>
            </div>
            <div className="course-rating"
                onMouseEnter={() => setRating(2)}
                onMouseLeave={() => setRating(0)}
                onMouseDown={() => setClickedRating(2)}
            >
                <div style={{ display: ratingFun() < 2 ? 'block' : 'none' }}><CourseRating /></div>
                <div style={{ display: ratingFun() > 1 ? 'block' : 'none' }}><CourseRatingFull /></div>
            </div>
            <div className="course-rating"
                onMouseEnter={() => setRating(3)}
                onMouseLeave={() => setRating(0)}
                onMouseDown={() => setClickedRating(3)}
            >
                <div style={{ display: ratingFun() < 3 ? 'block' : 'none' }}><CourseRating /></div>
                <div style={{ display: ratingFun() > 2 ? 'block' : 'none' }}><CourseRatingFull /></div>
            </div>
            <div className="course-rating"
                onMouseEnter={() => setRating(4)}
                onMouseLeave={() => setRating(0)}
                onMouseDown={() => setClickedRating(4)}
            >
                <div style={{ display: ratingFun() < 4 ? 'block' : 'none' }}><CourseRating /></div>
                <div style={{ display: ratingFun() > 3 ? 'block' : 'none' }}><CourseRatingFull /></div>
            </div>
            <div className="course-rating"
                onMouseEnter={() => setRating(5)}
                onMouseLeave={() => setRating(0)}
                onMouseDown={() => setClickedRating(5)}
            >
                <div style={{ display: ratingFun() < 5 ? 'block' : 'none' }}><CourseRating /></div>
                <div style={{ display: ratingFun() > 4 ? 'block' : 'none' }}><CourseRatingFull /></div>
            </div>
        </div>
    )
}

export default RatingStars