import React, { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ReactPixel from 'react-facebook-pixel'

import { CourseExIconBlue, CourseLiveIconBlue, CourseHostedIconBlue } from '../../static'
import axios from 'axios'
import moment from 'moment'
import mixpanel from 'mixpanel-browser';

const IconI = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2 mb-1">
  <path d="M9 7H11V5H9V7ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM9 15H11V9H9V15Z" fill="black" />
</svg>

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const OrderDetail = () => {
  const history = useHistory()
  const query = useQuery()
  const dispatch = useDispatch()
  const [orderDetail, setOrderDetail] = useState(null)
  const [type, setType] = useState(null)
  const [discountPrice, setDiscountPrice] = useState(null)
  const [hargaKelas, setHargaKelas] = useState(null)

  const fetchOrderDetail = () => {
    dispatch({ type: 'TOGGLE_LOADING_TRUE' });
    const access_token = localStorage.getItem('access_token');
    axios.get(`${window.$API_URL}api/user/order/${query.get('order_id')}`,
      { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` } }
    ).then((success) => {
      let _orderDetail = success.data.data
      setOrderDetail(_orderDetail)

      if (_orderDetail.course_id) setType('course')
      else setType('event')

      const _discountPrice = _orderDetail.course_id ? (_orderDetail.course.discount_price !== "0.00" ? _orderDetail.course.retail_price - _orderDetail.course.discount_price : 0) : 0
      setDiscountPrice(_discountPrice)
      window.gtag('event', 'conversion', { 'send_to': 'AW-651417204/MkNECPWaz4ACEPSsz7YC', 'value': _discountPrice, 'currency': 'IDR', 'transaction_id': _orderDetail.id });
      mixpanel.track('PurchaseOrderStatus')
      const _hargaKelas = _orderDetail.course_id ? _orderDetail.course.retail_price : +_orderDetail.amount
      setHargaKelas(_hargaKelas)
    })
      .catch(error => {
        console.log(error)
      })
      .finally(() => dispatch({ type: 'TOGGLE_LOADING_FALSE' }))
  }

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      fetchOrderDetail()
    } else {
      history.push('/')
    } 
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (orderDetail) {
      if (orderDetail.status === 'paid' || orderDetail.status === 'success') {
        ReactPixel.track('Purchase', {
          type: type === 'event' ? 'Event' : 'Kelas',
          value: +(orderDetail.amount),
          currency: 'IDR'
        })
      }
    }
  }, [orderDetail]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {orderDetail ?
        <div className="order-detail-page">
          <div className="row pb-4">
            {orderDetail.status === 'paid' || orderDetail.status === 'success' ?
              <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                <h1 className="order-detail-page__title">Pembelian Anda Berhasil!</h1>
                <p className="order-detail-page__text">Pesanan Anda telah berhasil dimasukkan ke dalam daftar transaksi.</p>
                <p className="order-detail-page__text">Detail pesanan dapat Anda cek pada email Anda (atau ada pada folder spam).</p>
                <p className="order-detail-page__text mt-4 navbar-desc"><IconI />Anda juga dapat melihat detail pesanan pada bagian Pesanan Saya di Profil Pengguna.</p>
                <div className="order-detail-page__btns">
                  {type === 'event' ?
                    orderDetail.booking_days[0] ?
                      <div className="btn btn-pink order-detail-page__lihat-pesanan navbar-desc"
                        onClick={() => history.push('/student/events')}>Lihat Akses di Event Saya</div>
                      : <div className="btn btn-white order-detail-page__lihat-pesanan navbar-desc" onClick={() => history.push('/student/orders')}>Lihat Pesanan Saya</div>
                    : <div className="btn btn-white order-detail-page__lihat-pesanan navbar-desc" onClick={() => history.push('/student/orders')}>Lihat Pesanan Saya</div>
                  }
                  <div className="btn btn-pink navbar-mob" onClick={() => history.push('/student/orders')} style={{ width: '160px' }}>Lihat Pesanan Saya</div>
                  <div className="btn btn-white order-detail-page__lihat-kembali"
                    onClick={() => history.push('/')}>Kembali Ke Halaman Utama</div>
                </div>
                {type === 'course' ?
                  orderDetail.course.type === 'live' ?
                    <div className="order-detail-page__jadwal">
                      <h2 className="order-detail-page__jadwal-title">Jadwal Kelas Anda</h2>
                      <p className="order-detail-page__text">Jika Anda ingin melakukan pemilihan jadwal, dapat dilakukan di bagian Kelas Saya.</p>
                      <div className="btn btn-pink order-detail-page__jadwal-btn" onClick={() => history.push('/student/course')}>Atur Jadwal</div>
                    </div> : ''
                  : ''}
              </div>
              :
              <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                <h1 className="order-detail-page__title">Pembelian Anda belum Berhasil.</h1>
                <p className="order-detail-page__text">Proses transaksi telah dibatalkan.</p>
                <p className="order-detail-page__text mt-4 navbar-desc"><IconI />Anda juga dapat melihat detail pesanan pada bagian Pesanan Saya di Profil Pengguna.</p>
                <div className="order-detail-page__btns">
                  <div className="btn btn-white order-detail-page__lihat-pesanan navbar-desc"
                    onClick={() => history.push('/student/orders')}>Lihat Pesanan Saya</div>
                  <div className="btn btn-pink navbar-mob" onClick={() => history.push('/student/orders')} style={{ width: '160px' }}>Lihat Pesanan Saya</div>
                  <div className="btn btn-white order-detail-page__lihat-kembali"
                    onClick={() => history.push('/')}>Kembali Ke Halaman Utama</div>
                </div>
              </div>
            }
            <div className="col-12 col-sm-12 col-md-5 col-lg-5">
              <div className="order-detail-page__detail">
                <div className="card order-detail-page__detail-card mb-3">
                  <h2 className="order-detail-page__detail-title">Rincian Pembelian</h2>
                  <div className="row">
                    <div className="col-7">
                      {type === 'course' ?
                        <>
                          {orderDetail.course.type === "external" ? <div className="d-flex align-items-center "><CourseExIconBlue /><p className="course-card__type ml-2">External</p></div> : ""}
                          {orderDetail.course.type === "live" ? <div className="d-flex align-items-center "><CourseLiveIconBlue /> <p className="course-card__type ml-2">Live</p></div> : ""}
                          {orderDetail.course.type === "hosted" ? <div className="d-flex align-items-center "><CourseHostedIconBlue /><p className="course-card__type ml-2">Self-Paced</p></div> : ""}
                          <p className="order-detail-page__detail-name" style={{ fontWeight: '700' }}>{orderDetail.course.name}</p>
                          <p className="order-detail-page__detail-name">{orderDetail.course.instructor.fullname}</p>

                        </>
                        : type === 'event' ? <>
                          <p className="order-detail-page__detail-name" style={{ fontWeight: '700' }}>{orderDetail.event.title}</p>
                          {orderDetail.booking_days[0] ?
                            orderDetail.booking_days.map(el => (
                              <>
                                <p className="order-detail-page__detail-name" style={{ fontWeight: '500' }}>{el.day.title}</p>
                                <p className="order-detail-page__detail-name" style={{ marginTop: '0', fontSize: '12px' }}>{`${moment(el.day.date).format('D MMMM YYYY')}, ${moment(el.day.date).format('HH:mm')} - ${moment(el.day.end_date).format('HH:mm')} WIB`}</p>
                              </>
                            )) : ''}
                        </> : ''}
                    </div>
                    <div className="col-5 d-flex justify-content-end">
                      {type === 'course' ?
                        orderDetail.course.thumbnail ?
                          <div style={{ backgroundImage: `url("${orderDetail.course.thumbnail.url}")` }} className="order-detail-page__detail-pic" /> :
                          <>
                            <div style={{ backgroundImage: 'url(/static/media/banner_new.fe2b666b.jpg)', backgroundSize: 'cover', backgroundPositionX: '20px' }} className="order-detail-page__detail-pic"></div>
                          </>
                        : type === 'event' ? <img src={orderDetail.event.image_id ? orderDetail.event.image ? orderDetail.event.image.url : '' : ''} className="order-detail-page__detail-pic" alt="order"/> : ''}
                    </div>
                  </div>
                </div>
                <div className="card order-detail-page__detail-card mb-3">
                  <h2 className="order-detail-page__number-title">Total Pembayaran</h2>
                  <div className="d-flex mb-2">
                    <p className="order-detail-page__number-detail">Harga Kelas:</p>
                    {hargaKelas ? <p className="order-detail-page__number-price">
                      {'Rp  '}<NumberFormat
                        value={hargaKelas}
                        displayType={'text'}
                        thousandSeparator="."
                        isNumericStrin={true}
                        decimalSeparator={""}
                        thousandsGroupStyle="thousand"
                      /></p> : <p className="order-detail-page__number-price">--</p>}
                  </div>
                  <div className="d-flex mb-2">
                    <p className="order-detail-page__number-detail">Diskon:</p>
                    {discountPrice ? <p className="order-detail-page__number-price">
                      {'-Rp  '}<NumberFormat
                        value={discountPrice}
                        displayType={'text'}
                        thousandSeparator="."
                        isNumericStrin={true}
                        decimalSeparator={""}
                        thousandsGroupStyle="thousand"
                      /></p> : <p className="order-detail-page__number-price">--</p>}

                  </div>
                  <div className="d-flex mb-2">
                    <p className="order-detail-page__number-detail">Diskon Voucher:</p>
                    {orderDetail.discount_voucher_id ? <p className="order-detail-page__number-price">
                      {'-Rp  '}<NumberFormat
                        value={orderDetail.discountvoucher.discount_type === 'amount' ? +orderDetail.discountvoucher.discount_value : +orderDetail.discountvoucher.discount_value / 100 * +orderDetail.amount_before_discount}
                        displayType={'text'}
                        thousandSeparator="."
                        isNumericStrin={true}
                        decimalSeparator={""}
                        thousandsGroupStyle="thousand"
                      /></p> : <p className="order-detail-page__number-price">--</p>}
                  </div>
                  <div className="d-flex order-detail-page__number-total pt-2 mb-2">
                    <p className="order-detail-page__number-total-detail">Total:</p>
                    {orderDetail.amount ? <p className="order-detail-page__number-total-price">
                      {'Rp  '}<NumberFormat
                        value={orderDetail.method === 'voucher' ? '0' : +orderDetail.amount}
                        displayType={'text'}
                        thousandSeparator="."
                        isNumericStrin={true}
                        decimalSeparator={""}
                        thousandsGroupStyle="thousand"
                      /></p> : <p className="order-detail-page__number-total-price">--</p>}
                  </div>
                  <div className="d-flex">
                    <p className="order-detail-page__number-detail">Status</p>
                    {orderDetail.status === 'success' || orderDetail.status === 'paid' ? <div className="course-card__selesai d-flex justify-content-center align-items-center ml-auto">Berhasil</div> : <div className="course-card__selesai d-flex justify-content-center align-items-center ml-auto"
                      style={{ backgroundColor: 'rgba(255, 15, 81, 0.25)', color: '#FF0F51', borderColor: '#FF0F51' }}>
                      Gagal
                                    </div>}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        : ''}
    </>
  )
}

export default OrderDetail
