import React from 'react';


const Loading = (props) => (
  <div className="myLoader">
    <span>
      <i className="fa fa-spinner fa-spin fa-4x" /> 
    </span>
    <p>
      {(props.text) ? props.text : 'Mohon Menunggu'}
    </p>
  </div>
);

export default Loading;
