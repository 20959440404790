import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Loading from '../Loading'
import EditModal from './EditModal'
import { editProfile } from '../../Utils/Utils';

const EditProfileMob = () => {
    const userProfile = useSelector(state => state.profile_data)
    const [modalopen, setModalOpen] = useState(false)
    const [modalContent, setModalContent] = useState('')

    const dispatch = useDispatch()
    const triggerEditProfile = () => {
        dispatch({type: 'TOGGLE_LOADING_TRUE'});
        editProfile(userProfile.first_name, userProfile.last_name, userProfile.phone_number, userProfile.email)
        .then(_ => {
            setModalContent('doneEdit')
        })
        .catch(error => {
            console.log('error')
        })
        .finally(_ => dispatch({type: 'TOGGLE_LOADING_FALSE'}))

    }

    if (!userProfile) return <Loading/>

    return (
        <>
        <div className="navbar-mob">
            <div  className="dashboard-container">
                <div className="edit_profile_form">
                    <form action="#">
                        <div className="form-group">
                            <h4 className="edit-profile__form-label ml-2">Email</h4>
                            <input

                            readOnly

                            type="email"
                            className="form-control edit-profile__form"
                            placeholder="email@email.com"
                            value={userProfile.email}
                            onChange={(e) =>  dispatch({type: 'EDIT_PROFILE', payload: {...userProfile, email: e.target.value}})}
                            />
                        </div>

                        <div className="form-group"
                            style={{marginBottom: '20px'}}
                        >
                            <h4 className="edit-profile__form-label ml-2">Nama Depan*</h4>
                            <input
                            type="text"
                            className="form-control edit-profile__form"
                            placeholder="Nama Depan Sesuai KTP"
                            style={{marginBottom: 0}}
                            value={userProfile.first_name}
                            onChange={(e) =>  dispatch({type: 'EDIT_PROFILE', payload: {...userProfile, first_name: e.target.value}})}
                            />
                        </div>
                        <div className="form-group"
                            style={{marginBottom: 0}}
                        >
                            <h4 className="edit-profile__form-label ml-2">Nama Belakang*</h4>
                            <input
                            type="text"
                            className="form-control edit-profile__form"
                            placeholder="Nama Depan Sesuai KTP"
                            style={{marginBottom: 0}}
                            value={userProfile.last_name}
                            onChange={(e) =>  dispatch({type: 'EDIT_PROFILE', payload: {...userProfile, last_name: e.target.value}})}
                            />
                        </div>
                        <p className="edit-profile__pass-desc ml-2" style={{marginBottom: '20px', color: "#828282"}} >*Nama akan tertera di sertifikat</p>

                        <div className="form-group">
                            <h4 className="edit-profile__form-label ml-2">Nomor Telepon</h4>
                            <input
                            type="text"
                            className="form-control edit-profile__form"
                            placeholder="081234567890"
                            style={{marginBottom: '30px'}}
                            value={userProfile.phone_number}
                            onChange={(e) =>  dispatch({type: 'EDIT_PROFILE', payload: {...userProfile, phone_number: e.target.value}})}
                            />
                        </div>
                        
                        <button
                            type="button"
                            onClick={(e) => {
                                setModalContent('editWarning')
                                setModalOpen(true)
                            }}
                            className="btn btn-block masuk-btn mb-3 edit-profile__btn-pink"
                            >
                            Simpan
                        </button>
                    </form>
                </div>
            </div>
            <EditModal modalOpen={modalopen} setModalContent={setModalContent} modalContent={modalContent} 
            closeModal={(e) => setModalOpen(false)} triggerEditProfile={triggerEditProfile}/>
        </div>
        </>
    )
}

export default EditProfileMob