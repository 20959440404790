import React from 'react'
import Carousel from "react-multi-carousel";

const testimoniData = [
    {
      gender: 'male',
      name: 'Beni Kaku',
      course: 'Desain Grafis untuk Pemula',
      desc: '“Saya adalah seorang arsitek, desain grafis sangat di perlukan terhadap profesi saya dan pengajaran yang di berikan pada kelas ini cukup di mengerti penjelasan yang di berikan juga bermanfaat buat saya dalam mendesain suatu karya hingga lebih kreatif.”'
    },
    {
      gender: 'female',
      name: 'Vit Imenuel',
      course: 'Belajar Penggunaan Microsoft Excel untuk Memaksimalkan Kerja UMKM',
      desc: '“Rumus yang di dapat sangat bermanfaat sehingga dapat membantu saya dalam mengerjakan pekerjaan saya. Terima kasih”'
    },
    {
      gender: 'female',
      name: 'Siti Patimah',
      course: 'Bahasa Jepang untuk Pemula ',
      desc: '“Belajar nya menyenangkan dan cara penyampaian nya juga mudah di mengerti jdi untuk yg bener bener baru balajar juga langsung paham, rekomend deh buat yg baru mau belajar.”'
    },
    {
      gender: 'male',
      name: 'Alan Mursid',
      course: 'Digital Marketing: Menggunakan Channel dan Kampanye',
      desc: '“Untuk para pemula dlm mempromosikan produk/ konten , materi ini sangat bermanfaat guna n sebgai awal untuk lebih mengexplore kemampuan dlm advertise.”'
    },{
      gender: 'male',
      name: 'Agus Warso',
      course: 'Belajar Penggunaan Microsoft Excel untuk Memaksimalkan Kerja UMKM',
      desc: '“Penyampaianya lugas & menarik dan mudah di fahami dengan memberikan langsung contoh perhitungannya.”'
    },
    {
      gender: 'male',
      name: 'Iman Sulaiman',
      course: 'Membangun Situs Web Bisnis E-Commerce untuk Pemula ',
      desc: '“Penjelasan sangat detail dan terperinci, jadi paham kegunaan & fitur2 di wix editor, ditambah tutorial berupa video yang menjadikan pelatihan semakin maksimal, terima kasih myedusolveX.”'
    }
  ]

function Testimoni() {
    const responsive = {
        desktop: {
        breakpoint: { max: 3000, min: 992 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
        breakpoint: { max: 991, min: 0 },
        items: 1.2,
        slidesToSlide: 1 // optional, default to 1.
        },
        mobileSmall: {
        breakpoint: { max: 448, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
        }
    };
    return (
        <div className="prakerja-testimoni">
            <h2 className="prakerja-title">Testimoni Peserta Prakerja</h2>
            <p>Testimoni Prakerja Yang #SiapDariSekarang</p>
            <div>
                <Carousel
                    transitionDuration={500}
                    responsive={responsive}
                    containerClass="carousel-container"
                    itemClass="carousel-item-padding-40-px"
                    deviceType="desktop"
                    arrows={false}
                    showDots={true}
                    // infinite={true}
                    >
                    {testimoniData.map(el => (
                        <div className="prakerja-testimoni__testimonies">
                            <div>
                                <div><img src={`/images/landing/homepage-new/pengalaman/${el.gender}.png`} /></div>
                                <div>
                                    <h2>{el.name}</h2>
                                    <h3>{el.course}</h3>
                                </div>
                            </div>
                        <p>{el.desc}</p>
                        </div>
                    ))}
                </Carousel>
                
            </div>
        </div>
    )
}



export default Testimoni
