import React from 'react';
import axios from 'axios';
import moment from 'moment'

export const parseCourseDuration = (duration) => {
    const durationArr = duration.split(':')
    if (durationArr[0] !== '00') {
        return `${durationArr[0]} Jam ${durationArr[1]} menit`
    } else if (durationArr[1] !== '00') {
        return `${durationArr[0]} menit`
    } else {
        return '--'
    }
}

export const parseCourseDurationAndEbook = (duration, ebook) => {
    const ebookDuration = ebook.reduce((prev, current) => {
        if(current.ebook_pages) return prev + current.ebook_pages*240
        else return prev
    }, 0)
    const durationArr = duration.split(':')
    const ebookHour = (+durationArr[0])*60*60
    const ebookMin = (+durationArr[1])*60
    const totalTime = ebookHour+ebookMin+ebookDuration
    if ((totalTime) >= 3600) {
        return (moment.utc((totalTime) * 1000).format('H [jam] mm [menit]'))
    } else  {
        return (moment.utc((totalTime) * 1000).format('m [menit]'))
    }
}