import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import Modal from 'react-modal';
import 'react-notifications/lib/notifications.css';
import { connect, useDispatch, useSelector } from 'react-redux';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useHistory, withRouter } from 'react-router-dom';
import { appendScript, removeScript } from '../../Utils/Utils';
import { CloseModals } from '../../static';
import Button from '../Button';
import Payment from './PaymentNew';
import Price from './PriceNew';
import Ticket from './Ticket';

const BarModal = ({ isModalOpen, closeModals, modalMsg }) => {
  const wrapperRef = useRef(null);
  const history = useHistory();
  useEffect(() => {
    const escFunction = (event) => {
      if (event.keyCode === 27) {
        closeModals();
      }
    };
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        closeModals();
      }
    };

    document.addEventListener('keydown', escFunction, false);
    document.addEventListener('mousedown', handleClickOutside, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, [isModalOpen, wrapperRef]);
  return (
    <>
      <Modal
        closeTimeoutMS={100}
        isOpen={isModalOpen}
        overlayClassName="login-popup-overlay"
        className="login-popup-content"
        onRequestClose={closeModals}
      >
        <div
          className="free-modal__dialog modal-dialog-centered free-modal__size"
          role="document"
          ref={wrapperRef}
        >
          <div className="free-modal__content-course">
            <div>
              <div onClick={() => closeModals()}>
                <CloseModals />
              </div>
            </div>
            <h2>Akses Kelas Gratis</h2>
            <p>{modalMsg}</p>
            <div className="d-flex">
              <div className="btn btn-white beli-kelas btn-big" onClick={() => closeModals()}>
                Kembali
              </div>
              <div
                className="btn btn-pink beli-kelas btn-big"
                onClick={() => history.push('/student/course')}
              >
                Lanjutkan Kelas
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const FullStar = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.49996 12.2329L12.8775 14.875L11.7158 9.89541L15.5833 6.54499L10.4904 6.11291L8.49996 1.41666L6.50954 6.11291L1.41663 6.54499L5.28413 9.89541L4.12246 14.875L8.49996 12.2329Z"
      fill="#FFC632"
    />
  </svg>
);

const EmptyStar = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5833 6.54499L10.4904 6.10582L8.49996 1.41666L6.50954 6.11291L1.41663 6.54499L5.28413 9.89541L4.12246 14.875L8.49996 12.2329L12.8775 14.875L11.7229 9.89541L15.5833 6.54499ZM8.49996 10.9083L5.83663 12.5162L6.54496 9.48457L4.19329 7.44457L7.29579 7.17541L8.49996 4.32082L9.71121 7.18249L12.8137 7.45166L10.462 9.49166L11.1704 12.5233L8.49996 10.9083Z"
      fill="#FFC632"
    />
  </svg>
);

const HalfStar = () => (
  <svg width="17" height="17" xmlns="http://www.w3.org/2000/svg">
    <path
      id="svg_1"
      fill="#FFC632"
      d="m15.5833,6.54499l-5.0929,-0.43917l-1.99044,-4.68916l-1.99042,4.69625l-5.09291,0.43208l3.8675,3.35042l-1.16167,4.97959l4.3775,-2.6421l4.37754,2.6421l-1.1546,-4.97959l3.8604,-3.35042zm-7.08334,4.36331l-2.66333,1.6079l0.70833,-3.03163l-2.35167,-2.04l3.1025,-0.26916l1.20417,-2.85459l1.21125,2.86167l3.10249,0.26917l-2.3517,2.04l0.7084,3.03164l-2.67044,-1.615z"
    />
    <path
      id="svg_9"
      d="m1.64207,6.47814l4.91531,-0.46721l1.96721,-4.48087l-0.05464,10.65574l-4.37159,2.62295l1.14754,-4.97268l-3.60383,-3.35793z"
      fill-opacity="null"
      stroke-opacity="null"
      stroke-width="null"
      stroke="null"
      fill="#FFC632"
    />
  </svg>
);

const LevelIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3828 15.7812H16.2734C15.9822 15.7812 15.7461 15.5451 15.7461 15.2539V2.52734C15.7461 2.23611 15.9822 2 16.2734 2H18.3828C18.674 2 18.9102 2.23611 18.9102 2.52734V15.2539C18.9102 15.5451 18.674 15.7812 18.3828 15.7812Z"
      fill="#20282E"
    />
    <path
      d="M14.1641 15.7812H12.0547C11.7635 15.7812 11.5273 15.5451 11.5273 15.2539V4.70703C11.5273 4.4158 11.7635 4.17969 12.0547 4.17969H14.1641C14.4553 4.17969 14.6914 4.4158 14.6914 4.70703V15.2539C14.6914 15.5451 14.4553 15.7812 14.1641 15.7812Z"
      fill="#20282E"
    />
    <path
      d="M9.94531 15.7812H7.83594C7.5447 15.7812 7.30859 15.5451 7.30859 15.2539V6.81641C7.30859 6.52517 7.5447 6.28906 7.83594 6.28906H9.94531C10.2365 6.28906 10.4727 6.52517 10.4727 6.81641V15.2539C10.4727 15.5451 10.2365 15.7812 9.94531 15.7812Z"
      fill="#20282E"
    />
    <path
      d="M5.72656 15.7812H3.61719C3.32595 15.7812 3.08984 15.5451 3.08984 15.2539V8.92578C3.08984 8.63455 3.32595 8.39844 3.61719 8.39844H5.72656C6.0178 8.39844 6.25391 8.63455 6.25391 8.92578V15.2539C6.25391 15.5451 6.0178 15.7812 5.72656 15.7812Z"
      fill="#20282E"
    />
    <path
      d="M10.4727 18.418C10.4727 18.5983 10.4909 18.7748 10.5257 18.9453H2.52734C2.23625 18.9453 2 18.7091 2 18.418C2 18.1269 2.23625 17.8906 2.52734 17.8906H10.5257C10.4909 18.0611 10.4727 18.2376 10.4727 18.418Z"
      fill="#20282E"
    />
    <path
      d="M19.9999 18.418C19.9999 18.7091 19.7637 18.9453 19.4726 18.9453H15.6929C15.7277 18.7748 15.746 18.5983 15.746 18.418C15.746 18.2376 15.7277 18.0611 15.6929 17.8906H19.4726C19.7637 17.8906 19.9999 18.1269 19.9999 18.418Z"
      fill="#20282E"
    />
    <path
      d="M13.1094 20C12.237 20 11.5273 19.2903 11.5273 18.418C11.5273 17.5456 12.237 16.8359 13.1094 16.8359C13.9817 16.8359 14.6914 17.5456 14.6914 18.418C14.6914 19.2903 13.9817 20 13.1094 20Z"
      fill="#20282E"
    />
  </svg>
);

const SertifikatIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9167 19.25L13.75 18.3333L15.5833 19.25V12.8333H11.9167V19.25ZM15.5833 8.25V6.41667L13.75 7.33333L11.9167 6.41667V8.25L10.0833 9.16667L11.9167 10.0833V11.9167L13.75 11L15.5833 11.9167V10.0833L17.4167 9.16667L15.5833 8.25ZM18.3333 2.75H3.66668C3.18045 2.75 2.71413 2.94315 2.37031 3.28697C2.0265 3.63079 1.83334 4.0971 1.83334 4.58333V13.75C1.83334 14.2362 2.0265 14.7025 2.37031 15.0464C2.71413 15.3902 3.18045 15.5833 3.66668 15.5833H10.0833V13.75H3.66668V4.58333H18.3333V13.75H17.4167V15.5833H18.3333C18.8196 15.5833 19.2859 15.3902 19.6297 15.0464C19.9735 14.7025 20.1667 14.2362 20.1667 13.75V4.58333C20.1667 4.0971 19.9735 3.63079 19.6297 3.28697C19.2859 2.94315 18.8196 2.75 18.3333 2.75ZM10.0833 7.33333H4.58334V5.5H10.0833V7.33333ZM8.25001 10.0833H4.58334V8.25H8.25001V10.0833ZM10.0833 12.8333H4.58334V11H10.0833V12.8333Z"
      fill="#20282E"
    />
  </svg>
);

const DurasiIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.75 7.33333V14.6667H4.58333V7.33333H13.75ZM14.6667 5.5H3.66667C3.42355 5.5 3.19039 5.59658 3.01849 5.76849C2.84658 5.94039 2.75 6.17355 2.75 6.41667V15.5833C2.75 15.8264 2.84658 16.0596 3.01849 16.2315C3.19039 16.4034 3.42355 16.5 3.66667 16.5H14.6667C14.9098 16.5 15.1429 16.4034 15.3148 16.2315C15.4868 16.0596 15.5833 15.8264 15.5833 15.5833V12.375L19.25 16.0417V5.95833L15.5833 9.625V6.41667C15.5833 6.17355 15.4868 5.94039 15.3148 5.76849C15.1429 5.59658 14.9098 5.5 14.6667 5.5Z"
      fill="black"
    />
  </svg>
);

const PesertaIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.7908 10.6242L12.375 18.04L9.01084 14.6667L7.71834 15.9592L12.375 20.625L21.0833 11.9167L19.7908 10.6242ZM5.89417 10.0833L7.79167 5.04167L9.68917 10.0833H5.89417ZM11.4125 14.6667H13.3283L8.64417 2.75H6.93917L2.255 14.6667H4.17084L5.1975 11.9167H10.3675L11.4125 14.6667Z"
      fill="#20282E"
    />
  </svg>
);

const SelfPacedIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 2.75L0.916656 8.25L4.58332 10.2483V15.7483L11 19.25L17.4167 15.7483V10.2483L19.25 9.24917V15.5833H21.0833V8.25L11 2.75ZM17.2517 8.25L11 11.66L4.74832 8.25L11 4.84L17.2517 8.25ZM15.5833 14.6667L11 17.16L6.41666 14.6667V11.2475L11 13.75L15.5833 11.2475V14.6667Z"
      fill="#20282E"
    />
  </svg>
);

const LanguageIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.7975 13.8142L9.46916 11.5133L9.49666 11.4858C11.0917 9.7075 12.2283 7.66333 12.8975 5.5H15.5833V3.66667H9.16666V1.83333H7.33332V3.66667H0.916656V5.5H11.1558C10.5417 7.26 9.56999 8.9375 8.24999 10.4042C7.39749 9.46 6.69166 8.42417 6.13249 7.33333H4.29916C4.96832 8.8275 5.88499 10.2392 7.03082 11.5133L2.36499 16.115L3.66666 17.4167L8.24999 12.8333L11.1008 15.6842L11.7975 13.8142ZM16.9583 9.16667H15.125L11 20.1667H12.8333L13.86 17.4167H18.2142L19.25 20.1667H21.0833L16.9583 9.16667ZM14.5567 15.5833L16.0417 11.6142L17.5267 15.5833H14.5567Z"
      fill="#20282E"
    />
  </svg>
);

const BarNew = ({ tabSelected, videoPreview }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const course_detail = useSelector((state) => state.course_detail);
  const mpp_url_redirect = useSelector((state) => state.mpp_url_redirect);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');
  const [query, setQuery] = useState(queryString.parse(window.location.search));
  const [alert, setAlert] = useState(null);
  const [fbixelSent, setFbixelSent] = useState(false);
  const [totalRatingStars, setTotalRatingStars] = useState([]);
  const [ratingtoShow, setRatingtoShow] = useState([]);
  const isDesktop = useMediaQuery({ query: '(min-device-width: 992px)' });
  const [offset, setOffset] = useState(0);
  const handleScroll = () => setOffset(window.pageYOffset);

  const gtag_report_conversion = (url) => {
    var callback = function () {
      if (typeof url != 'undefined') {
        window.location = url;
      }
    };
    window.gtag('event', 'conversion', {
      send_to: 'AW-651417204/IoZcCMOGz4ACEPSsz7YC',
      event_callback: callback,
    });
    return false;
  };
  const gtag_report_conversion2 = (url) => {
    var callback = function () {
      if (typeof url != 'undefined') {
        window.location = url;
      }
    };
    window.gtag('event', 'conversion', {
      send_to: 'AW-651417204/VhqCCOn_t4ACEPSsz7YC',
      event_callback: callback,
    });
    return false;
  };
  const isLoggedIn = async () => {
    var result = false;
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      dispatch({ type: 'TOGGLE_LOADING_TRUE' });
      await axios
        .get(`${window.$API_URL}api/auth/me/`, {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
        })
        .then((success) => {
          dispatch({ type: 'TOGGLE_LOADING_FALSE' });
          if (success.data.success) {
            result = true;
          } else {
            result = false;
          }
        })
        .catch((error) => {
          result = false;
          dispatch({ type: 'TOGGLE_LOADING_FALSE' });
        });
    } else {
      result = false;
    }
    return result;
  };

  const signupEvent = async (url) => {
    var loggedIn = await isLoggedIn();
    if (loggedIn) {
      gtag_report_conversion();
      mixpanel.track('InitiatePurchase');
      registerCourse(url);
    } else {
      dispatch({
        type: 'UPDATE_LOGINPOPUP_OPEN',
        payload: 1,
      });
    }
  };

  const registerCourse = async (url) => {
    const courseId = course_detail.id;
    const access_token = localStorage.getItem('access_token');
    dispatch({ type: 'TOGGLE_LOADING_TRUE' });
    axios
      .post(
        `${window.$API_URL}api/course/${courseId}/register`,
        {
          method: 'midtrans',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
          },
        },
      )
      .then((r) => {
        dispatch({ type: 'TOGGLE_LOADING_FALSE' });
        if (!r.data.data.token) {
          history.push(url);
        } else {
          history.push('/checkoutsuccess/course/' + course_detail.id + window.location.search);
        }
      })
      .catch((error) => {
        dispatch({ type: 'TOGGLE_LOADING_FALSE' });
        if (error.response.status == 400) {
          if (course_detail.retail_price == 0) {
            if (
              error.response.data.message ==
              'Please finish ongoing free course to start a new one!!'
            ) {
              setIsModalOpen(true);
              setModalMsg(
                'Anda harus menyelesaikan kelas anda terlebih dahulu, sebelum mendapatkan akses ke kelas berikutnya!',
              );
            } else if (error.response.data.message == 'You are already enrolled in this class') {
              window.location.href = `/course/login/${course_detail.id}/${course_detail.slug}`;
            }
          } else {
            history.push('/student/course');
          }
        }
      });
  };

  useEffect(() => {
    appendScript('https://app.midtrans.com/snap/snap.js', 'Mid-client-V_QBkVCUB9-49Fvo');
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      removeScript('https://app.midtrans.com/snap/snap.js');
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    if (course_detail && course_detail.average_rating !== null) {
      let decimalNumber = `${(+course_detail.average_rating).toFixed(1)}`[2];
      let ratingNumber = Math.floor(+course_detail.average_rating) + 1;
      let halfNumber = 0;
      if (decimalNumber <= 9) {
        ratingNumber = ratingNumber - 1;
        if (decimalNumber > 2) {
          halfNumber++;
        }
      }

      let temp = [];
      for (let i = 0; i < ratingNumber; i++) {
        temp.push(<FullStar />);
      }
      if (halfNumber) temp.push(<HalfStar />);
      for (let i = 0; i < 5 - ratingNumber - halfNumber; i++) {
        temp.push(<EmptyStar />);
      }
      // console.log(course_detail, decimalNumber, ratingNumber, halfNumber)
      setTotalRatingStars(temp);
      setRatingtoShow((+course_detail.average_rating).toFixed(1));
    }
  }, [course_detail]);

  if (!course_detail) return null;

  if (videoPreview)
    return (
      <div className="video-preview-bar">
        <h2>Lakukan pembelian kelas dan dapatkan akses ke semua video!</h2>
        <div>
          {course_detail.retail_price != 0 ? (
            course_detail.type !== 'external' ? (
              <>
                <div
                  className={'btn btn-big btn-pink'}
                  onClick={() => signupEvent('/student/course')}
                >
                  Beli Di MyEduSolve
                </div>
              </>
            ) : (
              <a
                className={'btn btn-big btn-pink beli-kelas'}
                style={{ color: 'white' }}
                onClick={() => signupEvent('/student/course')}
              >
                Beli Kelas
              </a>
            )
          ) : (
            <div
              className="btn btn-pink beli-kelas btn-big"
              onClick={() => signupEvent('/student/course')}
            >
              Akses Kelas
            </div>
          )}{' '}
        </div>
      </div>
    );

  return (
    <>
      {isDesktop ? (
        <section className="bar-new">
          <h2>{course_detail.name}</h2>
          <div>
            <h3>{course_detail.instructor ? course_detail.instructor.fullname : ''}</h3>
            {course_detail.course_instructors_details[0]
              ? course_detail.course_instructors_details.map((el) => <h3>{el.fullname}</h3>)
              : ''}
          </div>
          <Price />
          <p>
            {course_detail.average_rating == null
              ? 'Belum ada Rating'
              : totalRatingStars.map((el) => el)}{' '}
            <p className="ml-1">{`${
              !course_detail.ratings_count ? '' : `(${course_detail.ratings_count})`
            }`}</p>
          </p>
          <div>
            <div>
              <div>
                <LevelIcon />
              </div>
              <p> {course_detail.level}</p>
            </div>
            <div>
              <div>
                <SertifikatIcon />
              </div>
              <p> Sertifikat</p>
            </div>
            <div>
              <div>
                <DurasiIcon />
              </div>
              <p> {`Durasi ${course_detail.duration} jam`}</p>
            </div>
            {course_detail.retail_price != 0.0 ? (
              <div>
                <div>
                  <PesertaIcon />
                </div>
                <p> Peserta akan diberikan penilaian</p>
              </div>
            ) : (
              ''
            )}
            <div>
              <div>
                <SelfPacedIcon />
              </div>
              <p> {course_detail.method_of_study}</p>
            </div>
            <div>
              <div>
                <LanguageIcon />
              </div>
              <p> {course_detail.course_language}</p>
            </div>
          </div>
          {course_detail.retail_price != 0 ? (
            course_detail.type !== 'external' ? (
              <>
                <div
                  className={'btn btn-big btn-pink'}
                  onClick={() => signupEvent('/student/course')}
                >
                  {course_detail?.is_purchased ? 'Akses Kelas' : 'Beli Di MyEduSolve'}
                </div>
              </>
            ) : (
              <a
                className={'btn btn-big btn-pink beli-kelas'}
                style={{ color: 'white' }}
                onClick={() => signupEvent('/student/course')}
              >
                Beli Kelas
              </a>
            )
          ) : (
            <div
              className="btn btn-pink beli-kelas btn-big mb-0"
              onClick={() => signupEvent('/student/course')}
            >
              Akses Kelas
            </div>
          )}
        </section>
      ) : (
        ''
      )}
      {course_detail.coursemppdetailsall[0] ? (
        tabSelected == 'Detail' ? (
          <section className="bar-new-bottom">
            <h2>{`Tersedia juga penyedia lain untuk kelas ${course_detail.name}`}</h2>
            <p>Pilih salah satu penyedia kelas untuk mendapatkan voucher:</p>
            <Payment />
            <div
              className="bar-new-bottom__button"
              onClick={(e) => {
                window.open(mpp_url_redirect, '_new');
                // this.getVoucher(mpp_url_redirect)
                gtag_report_conversion2();
                mixpanel.track('InitiatePurchasethirdParty');
                if (!fbixelSent) {
                  ReactPixel.track('GetVoucher', { url: mpp_url_redirect });
                  setFbixelSent(true);
                }
              }}
            >
              {course_detail.coursemppdetailsall.length > 0 ? (
                <Button className={'btn btn-big btn-white'}>Dapatkan Voucher</Button>
              ) : (
                ''
              )}
            </div>
          </section>
        ) : (
          ''
        )
      ) : (
        ''
      )}
      <section
        className="bar-new-mobile"
        style={isDesktop && offset < 600 ? { bottom: -110 } : { bottom: 0 }}
      >
        <div>
          {isDesktop ? (
            <div className="bar-new-mobile__title">
              <h3>{course_detail.name}</h3>
              <h4>{course_detail.instructor ? course_detail.instructor.fullname : ''}</h4>
            </div>
          ) : (
            ''
          )}
          <div className="bar-new-mobile__price">
            <Price mobile />
          </div>
          <div className="bar-new-mobile__btn">
            {course_detail.retail_price != 0 ? (
              course_detail.type !== 'external' ? (
                <>
                  <div
                    className={'btn btn-big btn-pink'}
                    onClick={() => signupEvent('/student/course')}
                  >
                    {course_detail?.is_purchased ? 'Akses Kelas' : 'Beli Di MyEduSolve'}
                  </div>
                </>
              ) : (
                <a
                  className={'btn btn-big btn-pink beli-kelas mb-0'}
                  style={{ color: 'white' }}
                  onClick={() => signupEvent('/student/course')}
                >
                  Beli Kelas
                </a>
              )
            ) : (
              <div
                className="btn btn-pink beli-kelas btn-big"
                onClick={() => signupEvent('/student/course')}
              >
                Akses Kelas
              </div>
            )}
          </div>
        </div>
      </section>
      {alert}
      <BarModal
        isModalOpen={isModalOpen}
        closeModals={() => setIsModalOpen(false)}
        modalMsg={modalMsg}
      />
    </>
  );
};
export default BarNew;
