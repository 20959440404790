import React from 'react';
import { Link } from 'react-router-dom';
class LandingFooterMiddleArea extends React.Component {
  render() {
    return (
      <>
        <section className="footer_middle_area home4 p0">
          <div className="container">
            <div className="row">
              <div className="col-sm-4 col-md-3 col-lg-3 col-xl-3 pb15 pt15">
                <div className="logo-widget home3">
                  <img className="img-fluid" src="images/images_logo-light-background.png" alt="header-logo3.png" />
                </div>
              </div>
              <div className="col-sm-8 col-md-5 col-lg-6 col-xl-6 pb25 pt25 brdr_left_right home3">
                <div className="footer_menu_widget home3">
                  <ul>
                    <li className="list-inline-item"><a href="/">HOME</a></li>
                    <li className="list-inline-item"><Link to="/terms">Terms and Conditions</Link></li>
                    <li className="list-inline-item"><Link to="/privacy">Privacy Policy</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3 pb15 pt15">
                <div className="footer_social_widget mt15">
                  <ul>
                    <li className="list-inline-item"><a href="https://web.facebook.com/myedusolve.id/"><i className="fa fa-facebook"></i></a></li>
                    <li className="list-inline-item"><a href="https://twitter.com/myedusolve"><i className="fa fa-twitter"></i></a></li>
                    <li className="list-inline-item"><a href="https://www.instagram.com/myedusolve/"><i className="fa fa-instagram"></i></a></li>
                    <li className="list-inline-item"><a href="https://www.youtube.com/channel/UC8EZqWiSgCdmQsiFEEPER6Q"><i className="fa fa-youtube"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default LandingFooterMiddleArea;
