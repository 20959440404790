import React, { useState, useRef, useEffect } from 'react';
import { CourseRating, CourseRatingFull, RatingStar } from '../../static';
import RatingStars from './RatingStars'
import axios from 'axios';
import { connect, useDispatch, useStore, useSelector } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import { trackEventSource } from '../../Utils/EventSourcing'

const RatingReview = ({ course_detail, closeModal }) => {
  const [title, setUlasan] = useState('')
  const [content, setContent] = useState('')
  const [rating, setRating] = useState('')
  const [returnError, setReturnError] = useState([])
  const [loading, setLoading] = useState(false)
  const [showing, setShowing] = useState(true)
  const setRatingValue = (rating) => {
    setRating(rating);
  }
  const [isReviewBlockOpen, setReviewBlockOpen] = useState(0);
  const [courseProsSelected, setCourseProsSelected] = useState([])

  const coursePros = ['Materi', 'Penyampaian Instruktur', 'Video', 'Resource Kelas']

  const sendReview = () => {
    setLoading(true)
    const access_token = localStorage.getItem('access_token');
    let is_like_materi = 0
    let is_like_mentor = 0
    let is_like_video = 0
    let is_like_resource = 0
    courseProsSelected.forEach(el => {
      if(el == 'Materi') is_like_materi = 1
      if(el == 'Penyampaian Instruktur') is_like_mentor = 1
      if(el == 'Video') is_like_video = 1
      if(el == 'Resource Kelas') is_like_resource = 1
    })

    axios
      .post(
        `${window.$API_URL}api/course/${course_detail.id}/rating`,
        {
          rating_value: rating,
          review_title: title,
          review_content: content,
          is_like_materi,
          is_like_mentor,
          is_like_resource,
          is_like_video
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
          },
        },
      ).then((success) => {
        setLoading(false)
        NotificationManager.success('Terima kasih! Ulasan untuk kelas ini berhasil dikirim.', 'Ulasan Berhasil Diberikan');
        trackEventSource({
          type : "coursecomplete",
          payload : { 
              course_id: course_detail.id, 
              rating: rating, 
              title: title, 
              content: content
          }
        })
        setTimeout(() => window.location.reload(), 3000);
      }).catch((error) => {
        setLoading(false)
        if (error.response) {
          if (error.response.status == '422') {
            let errorTemp = []
            Object.keys(error.response.data.errors).forEach((key) => {
              errorTemp.push(error.response.data.errors[key][0]);
            });
            const errorList2 = errorTemp.map((char, i) => {
              return (
                <li key={i} style={{ listStyleType: 'none' }}>
                  {char}
                </li>
              );
            });
            NotificationManager.error(errorList2, 'Validasi Error!');
          } else if (error.response.status == '404') {
            NotificationManager.error('Some Error Occured', 'Validasi Error!');
          } else {
            NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
          }
        } else {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        }

      })
  }
  const fetchReviews = () => {
    setLoading(true)
    const access_token = localStorage.getItem('access_token');
    axios
      .get(
        `${window.$API_URL}api/course/${course_detail.id}/getrating?quiz_id=${course_detail.quiz.id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
          },
        },
      ).then((success) => {
        setLoading(false)
        if (success.data.data != null) {
          setUlasan(success.data.data.review_title)
          setContent(success.data.data.review_content)
          setRating(success.data.data.rating)
          let temp = []
          if(success.data.data.is_like_materi) temp.push('Materi') 
          if(success.data.data.is_like_mentor) temp.push('Penyampaian Instruktur') 
          if(success.data.data.is_like_video) temp.push('Video') 
          if(success.data.data.is_like_resource) temp.push('Resource Kelas') 
          setCourseProsSelected(temp)
        }
        setReviewBlockOpen(1);
      }).catch((error) => {
        setLoading(false)
        if (error.response) {
          if (error.response.status == '422') {
            let errorTemp = []
            Object.keys(error.response.data.errors).forEach((key) => {
              errorTemp.push(error.response.data.errors[key][0]);
            });
            setShowing(true);
            setReturnError(errorTemp)
            const errorList2 = errorTemp.map((char, i) => {
              return (
                <li key={i} style={{ listStyleType: 'none' }}>
                  {char}
                </li>
              );
            });
            NotificationManager.error(errorList2, 'Validasi Error!');
          } else if (error.response.status == '404') {
            NotificationManager.error(error.response.data.message, 'Validasi Error!');
          } else if (error.response.status == '400') {
            setReviewBlockOpen(2);
          } else {
            NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
          }
        } else {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        }
        //setTimeout(() => window.location.reload(), 3000);
      })
  }

  const CoursePros = ({pros}) => {
    let selected = false
    courseProsSelected.forEach(el => {
      if(el === pros) selected = true
    })
    return (
      <div className={`rating__pros rating__pros-${selected ? 'selected' : 'unselected'}`}
      onClick={() => {
        let temp = [...courseProsSelected]
        if(!selected) temp.push(pros)
        else {
          temp = temp.filter(el => el !== pros)
        }
        setCourseProsSelected(temp)
      }}>
        {pros}
      </div>
    )
  }

  useEffect(() => {
    if (course_detail !== null) {
      if(course_detail.quiz !== null){
        fetchReviews();
      } else {
        setReviewBlockOpen(1);
      }
    }

  }, [course_detail])
  return (
    (isReviewBlockOpen)
      ?
      <div className="d-flex flex-column align-items-center justify-content-around rating-modal">
        {(isReviewBlockOpen === 1) ?
          <>
            <h1>Beri Rating dan Ulasan Kelas</h1>
            {course_detail.retail_price !== 0 ? <p className="rating__desc_1">Beri rating dan ulasan kelas terlebih dahulu untuk dapat mengikuti exam dan mendapatkan <b>sertifikat pembelajaran.</b></p> : ''}
            <RatingStars setRatingValue={setRatingValue} rating_value={rating} />
            <h2>Pilih beberapa hal yang Anda sukai dari kelas ini:</h2>
            <div className="rating__pros-wrapper">
                {coursePros.map((el, i) => (
                  <CoursePros pros={el} key={i} />
                ))}
            </div>
            <h2>Ulasan:</h2>
            {/* <div className="rating-modal__input">
              <p>Judul Ulasan*</p>
              <input
                type="text"
                className="form-control"
                placeholder="Judul Ulasan (min. 5 karakter)"
                onChange={(e) => setUlasan(e.target.value)}
                value={title}
              />
            </div> */}
            <div className="rating-modal__input">
              {/* <p>Ulasan*</p> */}
              <textarea
                className="form-control"
                placeholder="Tulis ulasan kamu di sini ya (min. 10 karakter)"
                onChange={(e) => setContent(e.target.value)}
                value={content}
              ></textarea>
            </div>
            {/* <small>*Wajib diisi</small> */}
            <div className='rating-modal__btn align-self-stretch d-flex flex-column'>
              <a onClick={(e) => sendReview()} className='btn btn-lg btn-pink align-self-stretch' style={{ fontSize: '16px' }}>
                Beri Review
              </a>
            </div>
          </> :
          <>
            <h1 className="assignment__title mb-3">Rating Belum Bisa Diberikan</h1>
            <p className="rating__desc_1">Anda belum bisa memberikan rating. Harap beri rating setelah anda menyelesaikan exam.</p>
            <div className='rating__btn align-self-stretch d-flex flex-column mt-3'> <a onClick={(e) => window.location.reload()} className='btn btn-lg btn-pink align-self-stretch' style={{ fontSize: '16px' }}>
              OK
              </a>
            </div>
          </>
        }

        <NotificationContainer />
      </div>
      :
      <p className="rating__desc_1">Harap tunggu...</p>
  )
}

const mapStateToProps = (state) => {
  return { course_detail: state.course_detail };
};


export default connect(mapStateToProps)(RatingReview)