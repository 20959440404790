import React from 'react'
import moment from 'moment'

const ScheduleDate = ({course}) => {
    const startDate = course.type === 'hosted' ? course.course_qna_register.schedule.start_date : course.course_schedule_register.schedule.start_date
    const endDate = course.type === 'hosted' ? course.course_qna_register.schedule.end_date : course.course_schedule_register.schedule.end_date
    return (
        <p className="course-card__bold-text" style={{ fontWeight: '300' }}>
            {`${moment(startDate).format('DD MMMM YYYY')} | ${moment(startDate).utcOffset(7).format('HH.mm')} - ${moment(endDate).utcOffset(7).format('HH.mm')} WIB`}
        </p>
    )
}

export default ScheduleDate