import React from 'react';
import { useHistory } from 'react-router-dom'

const Instruktur = () => {
    return (
        <>
            <div className="navbar-desc">
                <div className="footer-landing">
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="mr-5">
                            <h1 className="instruktur-landing__text-big">MyEduSolve X</h1>
                            <p className="footer-landing__text mt-2">Bergabunglah Dengan Kami</p>
                        </div>
                        <div className="footer-landing__card card mr-5 ml-auto justify-content-between">
                            <h1 className="footer-landing__title">Menjadi Instruktur</h1>
                            <p>MyEduSolve X memberikan kesempatan kepada Anda untuk membagikan keahlian dan minat yang dimiliki dalam bentuk kelas online.</p>
                            <p className="ml-auto footer-landing__link"
                            onClick={() =>window.open("https://bit.ly/collaborate-myedusolvex", "_blank") }
                            >Daftar Menjadi Instruktur<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-3">
                                <path d="M0.580002 10.59L5.17 6L0.580002 1.41L2 0L8 6L2 12L0.580002 10.59Z" fill="#FF0F51" />
                            </svg></p>


                        </div>
                        <div className="footer-landing__card card justify-content-between">
                            <h1 className="footer-landing__title">Mengenai Kartu Prakerja</h1>
                            <p>Dapatkan pelatihan gratis dan bermanfaat dengan Kartu Prakerja.</p>
                            <p>Telusuri Sekarang!</p>
                            <p className="ml-auto footer-landing__link"
                            onClick={() => window.open("https://www.prakerja.go.id/", "_blank")}
                            >Lihat Selengkapnya <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-3">
                                <path d="M0.580002 10.59L5.17 6L0.580002 1.41L2 0L8 6L2 12L0.580002 10.59Z" fill="#FF0F51" />
                            </svg></p>

                        </div>
                    </div>
                </div>
            </div>
            <div className="navbar-mob">
                <div className="footer-landing">
                    <div className="d-flex flex-column">
                        <div style={{marginBottom: '37px', marginTop: '37px'}}>
                            <h1 className="instruktur-landing__text-big"  style={{margin: '0'}}>MyEduSolve X</h1>
                            <p className="footer-landing__text mt-2">Bergabunglah Dengan Kami</p>
                        </div>
                        <div className="footer-landing__card card justify-content-between mt-3">
                            <h1 className="footer-landing__title">Mengenai Kartu Prakerja</h1>
                            <p className="footer-landing__text">Dapatkan pelatihan gratis dan bermanfaat dengan Kartu Prakerja.</p>
                            <p className="footer-landing__text">Telusuri Sekarang!</p>
                            <p className="ml-auto footer-landing__link"
                            onClick={() => window.open("https://www.prakerja.go.id/", "_blank")}
                            >Lihat Selengkapnya <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-3">
                                <path d="M0.580002 10.59L5.17 6L0.580002 1.41L2 0L8 6L2 12L0.580002 10.59Z" fill="#FF0F51" />
                            </svg></p>

                        </div>
                    </div>
                </div>
            </div>
        </>
        
    )
}

export default Instruktur;