import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../Loading'
import { Link } from 'react-router-dom';
import { Facebook, Twitter, Whatsapp } from '../../static';
import Avatar from 'react-avatar'
import {  NotificationManager } from 'react-notifications';
import axios from 'axios'
import {
    FacebookShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
} from 'react-share';


const MyUserProfile = () => {
    const userProfile = useSelector(state => state.profile_data)
    const [socialLinks, setSocialLinks] = useState([])
    const [referList, setReferList] = useState([])
    const [expand, setExpand] = useState(false)
    const dispatch = useDispatch()

    const fetchReferrals = () => {
        const access_token = localStorage.getItem('access_token')
        dispatch({ type: 'TOGGLE_LOADING_TRUE' })
        axios({
            method: 'GET',
            url: `${window.$API_URL}api/user/referrals`,
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
        })
      .then(response => {
        setReferList(response.data.data)
      })
      .catch(_ => NotificationManager.error("There was some problem in API. Please try again", 'Validation Error'))
      .finally(_ => dispatch({ type: 'TOGGLE_LOADING_FALSE' }))
    }

    useEffect(() => {
        if(userProfile) {
            const msg = `Yuk, upgrade skill bareng MyEduSolve. Tentunya sukses bersama-sama terasa lebih menyenangkan dibandingkan sukses sendirian. Klik link untuk satu langkah awal sukses bersama!`
            const msg2 = `Tentunya sukses bersama-sama terasa lebih menyenangkan dibandingkan sukses sendirian. Klik link untuk satu langkah awal sukses bersama!`
            const title = `Yuk, upgrade skill bareng MyEduSolve!`
            setSocialLinks([
                {
                    href: '/',
                    title: <FacebookShareButton url={userProfile.referral_url} quote={msg}><Facebook size={36}/></FacebookShareButton>,
                    id: '68',
                    name: 'Facebook'
                },
                {
                    href: '/',
                    title: <LinkedinShareButton title={title} summary={msg2} url={userProfile.referral_url}><LinkedinIcon size={36} round={true} /></LinkedinShareButton>,
                    id: '67',
                    name: 'Linkedin'
                },
                {
                    href: '/',
                    title: <TwitterShareButton title={msg} url={userProfile.referral_url} hashtags={['MyEduSolveX']}><Twitter size={36}/></TwitterShareButton>,
                    id: '69',
                    name: 'Twitter'
                },
                {
                    href: '/',
                    title: <WhatsappShareButton  title={title} url={userProfile.referral_url}><Whatsapp size={36}/></WhatsappShareButton>,
                    id: '65',
                    name: 'Whatsapp'
                }])
        }
        
    }, [userProfile])

    useEffect(() => {
        fetchReferrals()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (!userProfile) return <Loading/>

    console.log(userProfile)
    return (
        <div style={{minHeight: '60vh'}} className="my-referral">  
            <div className="navbar-mob my-referral__desc">
                <div className="row mb-4">
                    <div className="col-md-6 col-sm-6 col-xs-6 col-12">
                        <p>Ajak teman-teman Anda untuk bergabung dan belajar bersama dan dapatkan hadiah menarik dari Myedusolve untuk Anda dan teman-teman Anda.</p>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-6 my-referral__desc__img col-12">
                        <img src="/images/rafiki.png" alt="rafiki" />
                    </div>
                </div>
            </div>
            <div className="my-referral__status my-referral__box">
                <div>
                    <h2>Referral Status</h2>
                    <p>Undang lebih banyak temanmu!</p>
                    <div className="d-flex">
                        <img src="/images/courses/personIcon.png" alt="person icon"/>
                        {!referList[0] ?
                        <div className="d-flex align-items-center">
                            <h4>Kamu belum memiliki referral!</h4>
                        </div>
                        :
                        <div>
                            <h3>{referList.length}</h3>
                            <h4>Your referral:</h4>
                            <div className="my-referral__status__list">
                                <div className="d-flex"> 
                                    {/* {referList.map(friend => <li>{friend.fullname}</li>)} */}
                                    {referList.map((friend, i) => i < 4 ? friend.photo ? <img src={friend.photo.url} title={friend.fullname} /> : <Avatar name={friend.fullname} size="40" textSizeRatio={1.75} round style={{cursor: 'pointer'}}/> : '')}
                                    {expand ? '' : referList.length > 4 ? <p onClick={() => setExpand(true)}>{`+ ${referList.length - 4}`}</p> : ''}
                                    {expand ? referList.map((friend, i) => i >= 4 ? friend.photo ? <img src={friend.photo.url} title={friend.fullname} alt={friend.fullname}/> : <Avatar name={friend.fullname} size="40" textSizeRatio={1.75} round style={{cursor: 'pointer'}}/> : '') : ''}
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    
                </div>
            </div>
            <div className="my-referral__undang my-referral__box">
                <div>
                    <h2>Undang Teman Anda</h2>
                    <p>Undang teman Anda dengan cara membagikan link referral Anda kepada mereka.</p>
                    <div>
                        <form class="form-inline">
                            <div class="form-group">
                                <input type="text" class="form-control" disabled value={userProfile.referral_url}/>
                            </div>
                            <button type="submit" class="btn btn-primary" onClick={(e) => {
                                e.preventDefault()
                                navigator.clipboard.writeText(`Yuk, upgrade skill bareng MyEduSolve. Tentunya sukses bersama-sama terasa lebih menyenangkan dibandingkan sukses sendirian. Klik link: ${userProfile.referral_url} untuk satu langkah awal sukses bersama!`)
                                NotificationManager.success('Pesan dan URL berhasil disalin!');
                            }}>Salin Pesan</button>
                            <button type="submit" class="btn btn-primary" onClick={(e) => {
                                e.preventDefault()
                                navigator.clipboard.writeText(userProfile.referral_url)
                                NotificationManager.success('URL berhasil disalin!');
                            }}>Salin URL</button>
                        </form>
                        <ul className="list-reset d-flex social__list ml-4 p-0">
                            {socialLinks.map(({ href, title, id, name }) => (
                                <li className="social__list-item" key={id} 
                                onClick={() => {
                                    
                                }}>
                                    <Link href={href}>
                                        <a className="social__link" href="/" onClick={e => e.preventDefault()}>{title}</a>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default MyUserProfile