import React from 'react';

const LoadingSpinnerInside = (props) => (
  <div className="myLoaderInside">
    <span>
      <i className="fa fa-spinner fa-spin fa-4x" /> {(props.text) ? props.text : 'Mohon Menunggu'}
    </span>
  </div>
);

export default LoadingSpinnerInside;
