import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import LoginRegisterModal from './includes/LoginRegisterModal';

class LandingMenuNavHolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_menu_opened: this.props.is_menu_opened,
      is_parent_open: true,
      is_child_one_open: false,
      is_child_two_open: false,
      is_child_three_open: false,
      modalOpen: false,
    };
  }

  logoutCall = (e) => {
    e.preventDefault();
    this.setState({
      loading: 1,
    });
    const access_token = localStorage.getItem('access_token');
    axios
      .post(`${window.$API_URL}api/auth/logout/`, {}, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
      })
      .then((success) => {
        this.setState({
          loading: 0,
        });
        localStorage.removeItem('access_token');
        NotificationManager.success('Sampai jumpa kembali', 'Berhasil Logout!');
        setTimeout(() => window.location.href = '/', 3000);
      })
      .catch((error) => {
        localStorage.removeItem('access_token');
        NotificationManager.success('Sampai jumpa kembali', 'Berhasil Logout!');
        setTimeout(() => window.location.href = '/', 3000);
        this.setState({
          loading: 0,
        });
      });
  }

  toggleModalSigninPage = () =>
    this.setState({
      modalOpen: !this.state.modalOpen,
    });

  componentDidMount() {

  }
  render() {
    return (
      <>
        <nav id="menu" className={`stylehome1 mm-menu mm-menu_offcanvas ${(this.props.is_menu_opened) ? 'mm-menu_opened' : ''}`}>
          <div className="mm-panels">
            <div id="mm-1" className={`mm-panel mm-panel_has-navbar ${(this.state.is_parent_open) ? 'mm-panel_opened' : 'mm-panel_opened-parent mm-hidden'}`}>
              <div className="mm-navbar"><a className="mm-navbar__title" href="/" onClick={e => e.preventDefault()}>Menu</a></div>
              <ul className="mm-listview">
                <li className="mm-listitem mm-listitem_selected">
                  <a href="/" className="mm-listitem__text">HOME</a>
                </li>

                <li className="mm-listitem" onClick={(e) => this.setState({ is_parent_open: 0, is_child_one_open: 1 })}>
                  <a className="mm-btn mm-btn_next mm-listitem__btn mm-listitem__text" aria-owns="mm-2"  href="/" onClick={e => e.preventDefault()}
                    aria-haspopup="true">Kelas<span className="mm-sronly">Open submenu</span></a>
                </li>

                <li className="mm-listitem"
                  onClick={(e) => e.preventDefault()//this.setState({ is_parent_open: 0, is_child_two_open: 1 })
                  }
                >
                  <Link className="mm-btn  mm-listitem__btn mm-listitem__text" aria-owns="mm-3"
                    aria-haspopup="true" to="/webinar">Events</Link>
                </li>



                <li className="mm-listitem pointer"><a onClick={(e) => {
                    e.preventDefault()
                    window.location.href = '/contactus'
                  }} className="mm-listitem__text"  href="/">Kontak</a></li>


                {
                  (this.props.is_loggedin)
                    ?
                    <> <li className="mm-listitem">
                      <Link to="/student/profile" className="mm-listitem__text"> Profile</Link>
                    </li>
                      <li className="mm-listitem">
                        <a href="/" onClick={(e) => {
                            e.preventDefault()
                            this.logoutCall(e)
                          }} className="mm-listitem__text"><span className="flaticon-user"></span> Logout</a>
                      </li></>
                    :
                    <li className="mm-listitem">
                      <a href="/" onClick={(e) => {
                          e.preventDefault()
                          this.setState({ modalOpen: true })
                        }} className="mm-listitem__text"><span className="flaticon-user"></span> Masuk/Daftar</a>
                    </li>
                }



              </ul>
            </div>

            <div id="mm-2" className={`mm-panel mm-panel_has-navbar ${(this.state.is_child_one_open) ? 'mm-panel_opened' : 'mm-hidden'}`} aria-hidden="true">
              <div className="mm-navbar"><a href="/" onClick={(e) => {
                e.preventDefault()
                this.setState({ is_parent_open: 1, is_child_one_open: 0 })
              }} className="mm-btn mm-btn_prev mm-navbar__btn" aria-owns="mm-1"
                aria-haspopup="true"><span className="mm-sronly">Close submenu</span></a><a className="mm-navbar__title"
                >Kelas</a></div>
              <ul className="mm-listview">
                <li className="mm-listitem">
                  <Link to="/courses" className="mm-listitem__text">Semua Kelas</Link>
                </li>
                <li className="mm-listitem">
                  <Link to="/instructors" className="mm-listitem__text">Instruktur</Link>
                </li>
              </ul>
            </div>
            {
              /*
        <div id="mm-3" className={`mm-panel mm-panel_has-navbar ${(this.state.is_child_two_open) ? 'mm-panel_opened' : 'mm-hidden'}`} aria-hidden="true">
          <div onClick={(e) => this.setState({ is_parent_open: 1, is_child_two_open: 0 })} className="mm-navbar">

            <a className="mm-btn mm-btn_prev mm-navbar__btn" aria-owns="mm-1"
              aria-haspopup="true">
              <span className="mm-sronly">Close submenu</span>
            </a>
            <a className="mm-navbar__title">
              Webinars
              </a>
          </div>
          <ul className="mm-listview">
            <li className="mm-listitem">
              <Link to="/webinar" className="mm-listitem__text">Events</Link>
            </li>
          </ul>
        </div>

              */
            }

          </div>
        </nav>
        <LoginRegisterModal
          isOpen={this.state.modalOpen}
          toggleSignInPopup={this.toggleModalSigninPage}
        />
      </>
    );
  }
}

export default LandingMenuNavHolder;
