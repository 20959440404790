import React, { useState, useRef, useEffect } from 'react';
import { AssignmentIcon } from '../../static';
import Button from '../Button';
import { connect, useDispatch, useStore, useSelector } from 'react-redux';

const QuizPerlectureAlert = ({
  closeModal,
  text = 'Tonton 100% dari durasi video materi terkait untuk mengakses materi selanjutnya',
}) => {
  return (
    <div
      className="d-flex flex-column justify-content-around modal-hosted-course"
      style={{ flex: '1' }}
    >
      <h1 className="assignment__title">Akses Materi</h1>
      <div className="info-kelas-self-vprogress-warning d-flex flex-column">
        <p>{text}</p>
      </div>
      <div className="rating__btn align-self-stretch d-flex flex-column">
        <a
          className="btn btn-lg btn-pink align-self-stretch"
          style={{ fontSize: '16px' }}
          onMouseDown={closeModal}
        >
          OK
        </a>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapDispatchToProps)(QuizPerlectureAlert);
