import React from 'react';
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import LoadingSpinnerInside from '../Layout/LoadingSpinnerInside';
import UpcomingWebinarComponent from './includes/UpcomingWebinarComponent';

class LandingBlogSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loading_text: "Fetching Events..",
      upcoming_events: []
    };
  }

  componentDidMount() {
    this.fetchUpcomingWebinars();
  }

  fetchUpcomingWebinars = () => {
    this.setState({
      loading: true,
    });
    axios
      .get(
        `${window.$API_URL}api/event/upcoming/`,
        {
        },
        {
          headers: { 'Content-Type': 'application/json', 'x-access-token': '' },
        },
      )
      .then((success) => {
        this.setState({
          loading: false,
        });
        if (success.data.success) {
          this.setState({
            upcoming_events: success.data.data
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (!error.response) {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
          return false
      }
        if (error.response.status === 422) {
          let _returnError = [];
          Object.keys(error.response.data.errors).forEach((key) => {
            _returnError.push(error.response.data.errors[key][0]);
          });
          let _showing = true;
          this.setState({ returnError: _returnError, showing: _showing });
          const errorList2 = this.state.returnError.map((char, i) => {
            return (
              <li key={i} style={{ listStyleType: 'none' }}>
                {char}
              </li>
            );
          });
          NotificationManager.error(errorList2, 'Validasi Error!');
        } else if (error.response.status === 404) {
          NotificationManager.error(error.response.data.message, 'Validasi Error!');
        } else {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        }
      });
  }

  render() {
    return (
      <>
        {(this.state.upcoming_events.length > 0)
          ?
          <section className="our-blog">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 offset-lg-3">
                  <div className="main-title text-center">
                    <h3 className="mt0">Upcoming Live Classes/Webinar</h3>
                    <p>Berikut adalah beberapa opsi untuk kamu yang lebih suka blended learning!</p>
                  </div>
                </div>
              </div>
              <div className="row">
                {
                  this.state.upcoming_events.map((webinar, index) => (
                    <UpcomingWebinarComponent key={"webinar-upcoming-" + index} webinar={webinar} />
                  ))
                }

              </div>
            </div>
            {this.state.loading ? <LoadingSpinnerInside text={this.state.loading_text} /> : ''}
          </section>
          :
          ''
        }

        <NotificationContainer />
      </>
    );
  }
}

export default LandingBlogSection;
