import React, { useEffect, useState, Component, useRef } from 'react';
import courses from '../LandingNew/courses.json';
import categoriesJson from '../LandingNew/categories.json';
import { Link, useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ReactGA from 'react-ga';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { liveSessionId } from '../../constant';

const FullStar = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.49996 12.2329L12.8775 14.875L11.7158 9.89541L15.5833 6.54499L10.4904 6.11291L8.49996 1.41666L6.50954 6.11291L1.41663 6.54499L5.28413 9.89541L4.12246 14.875L8.49996 12.2329Z"
      fill="#FFC632"
    />
  </svg>
);

const EmptyStar = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5833 6.54499L10.4904 6.10582L8.49996 1.41666L6.50954 6.11291L1.41663 6.54499L5.28413 9.89541L4.12246 14.875L8.49996 12.2329L12.8775 14.875L11.7229 9.89541L15.5833 6.54499ZM8.49996 10.9083L5.83663 12.5162L6.54496 9.48457L4.19329 7.44457L7.29579 7.17541L8.49996 4.32082L9.71121 7.18249L12.8137 7.45166L10.462 9.49166L11.1704 12.5233L8.49996 10.9083Z"
      fill="#FFC632"
    />
  </svg>
);

const HalfStar = () => (
  <svg width="17" height="17" xmlns="http://www.w3.org/2000/svg">
    <path
      id="svg_1"
      fill="#FFC632"
      d="m15.5833,6.54499l-5.0929,-0.43917l-1.99044,-4.68916l-1.99042,4.69625l-5.09291,0.43208l3.8675,3.35042l-1.16167,4.97959l4.3775,-2.6421l4.37754,2.6421l-1.1546,-4.97959l3.8604,-3.35042zm-7.08334,4.36331l-2.66333,1.6079l0.70833,-3.03163l-2.35167,-2.04l3.1025,-0.26916l1.20417,-2.85459l1.21125,2.86167l3.10249,0.26917l-2.3517,2.04l0.7084,3.03164l-2.67044,-1.615z"
    />
    <path
      id="svg_9"
      d="m1.64207,6.47814l4.91531,-0.46721l1.96721,-4.48087l-0.05464,10.65574l-4.37159,2.62295l1.14754,-4.97268l-3.60383,-3.35793z"
      fill-opacity="null"
      stroke-opacity="null"
      stroke-width="null"
      stroke="null"
      fill="#FFC632"
    />
  </svg>
);

const Card = ({ data, notSlider }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [totalRatingStars, setTotalRatingStars] = useState([]);
  const [ratingtoShow, setRatingtoShow] = useState([]);
  const type = {
    live: 'Live',
    hosted: 'Self-Paced',
    external: 'External',
  };
  const urlParams = `?from=landing&section=kelas_populer`;

  useEffect(() => {
    if (data.average_rating !== null) {
      let decimalNumber = `${(+data.average_rating).toFixed(1)}`[2];
      let ratingNumber = Math.floor(+data.average_rating) + 1;
      let halfNumber = 0;
      if (decimalNumber <= 9) {
        ratingNumber = ratingNumber - 1;
        if (decimalNumber > 2) {
          halfNumber++;
        }
      }

      let temp = [];
      for (let i = 0; i < ratingNumber; i++) {
        temp.push(<FullStar />);
      }
      if (halfNumber) temp.push(<HalfStar />);
      for (let i = 0; i < 5 - ratingNumber - halfNumber; i++) {
        temp.push(<EmptyStar />);
      }
      // console.log(data, decimalNumber, ratingNumber, halfNumber)
      setTotalRatingStars(temp);
      setRatingtoShow((+data.average_rating).toFixed(1));
    }
  }, [data]);
  const registerCourse = async (url) => {
    const courseId = data.id;
    const access_token = localStorage.getItem('access_token');
    dispatch({ type: 'TOGGLE_LOADING_TRUE' });
    axios
      .post(
        `${window.$API_URL}api/course/${courseId}/register`,
        {
          method: 'midtrans',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
          },
        },
      )
      .then((r) => {
        dispatch({ type: 'TOGGLE_LOADING_FALSE' });
        if (!r.data.data.token) {
          history.push(url);
        } else {
          history.push('/checkoutsuccess/course/' + data.id);
        }
      })
      .catch((error) => {
        dispatch({ type: 'TOGGLE_LOADING_FALSE' });
        if (error.response.status == 400) {
          if (data.retail_price == 0) {
            if (
              error.response.data.message ==
              'Please finish ongoing free course to start a new one!!'
            ) {
              this.setState({
                isModalOpen: true,
                modalMsg:
                  'Anda harus menyelesaikan kelas anda terlebih dahulu, sebelum mendapatkan akses ke kelas berikutnya!',
              });
            } else if (error.response.data.message == 'You are already enrolled in this class') {
              this.setState({
                isModalOpen: true,
                modalMsg: 'Anda sudah memiliki kelas, akses melalui Kelas Saya!',
              });
            }
          } else {
            history.push('/student/course');
          }
        }
      });
  };
  const isLoggedIn = async () => {
    var result = false;
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      dispatch({ type: 'TOGGLE_LOADING_TRUE' });
      await axios
        .get(`${window.$API_URL}api/auth/me/`, {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
        })
        .then((success) => {
          dispatch({ type: 'TOGGLE_LOADING_FALSE' });
          if (success.data.success) {
            result = true;
          } else {
            result = false;
          }
        })
        .catch((error) => {
          result = false;
          dispatch({ type: 'TOGGLE_LOADING_FALSE' });
        });
    } else {
      result = false;
    }
    return result;
  };
  const signupEvent = async (url) => {
    var loggedIn = await isLoggedIn();
    if (loggedIn) {
      registerCourse(url);
    } else {
      dispatch({
        type: 'UPDATE_LOGINPOPUP_OPEN',
        payload: 1,
      });
    }
  };
  return (
    <div className={`landing-card-new ${notSlider ? 'non-slider-card' : ''}`}>
      <div className="card">
        <Link to={`/course/${data.id}/${data.slug}${urlParams}`}>
          <div
            style={{
              backgroundImage: `url("${
                data.thumbnail_id !== null ? data.thumbnail.url : '../../static/container.png'
              }")`,
            }}
          >
            <div>
              <p>{liveSessionId.includes(data?.id) ? 'Live session' : type[data.type]}</p>
            </div>
            {data.kartu_prakerja ? (
              <div>
                <img
                  src="/images/landing/homepage-new/prakerja-icon.png"
                  alt="Kratu Prakreja Program"
                />
              </div>
            ) : (
              ''
            )}
          </div>
          <div>
            <h2>{data.name}</h2>
            <h3>{data.instructor !== null && data.instructor ? data.instructor.fullname : ''}</h3>
            <p>
              {data.average_rating == null ? 'Belum ada Rating' : totalRatingStars.map((el) => el)}{' '}
              <p className="ml-1">{`${
                data.average_rating == null
                  ? ''
                  : `(${notSlider ? ratingtoShow : data.ratings_count})`
              }`}</p>
            </p>
            <div>
              {data.discount_price === 0.0 ? (
                data.retail_price == 0.0 ? (
                  <div>
                    <p className="retail-price-new">Gratis</p>
                  </div>
                ) : (
                  <div className="retail-price-new">
                    <p>Rp</p>
                    <NumberFormat
                      value={data.retail_price}
                      displayType={'text'}
                      thousandSeparator="."
                      isNumericStrin={true}
                      decimalSeparator={''}
                      thousandsGroupStyle="thousand"
                    />
                  </div>
                )
              ) : (
                <>
                  <div className="retail-price-new">
                    <p>Rp</p>
                    <NumberFormat
                      value={data.discount_price}
                      displayType={'text'}
                      thousandSeparator="."
                      isNumericStrin={true}
                      decimalSeparator={''}
                      thousandsGroupStyle="thousand"
                    />
                  </div>
                  <div>
                    <p className="text-line retail-price-new-not ml-2">Rp</p>
                    <NumberFormat
                      value={data.retail_price}
                      displayType={'text'}
                      thousandSeparator="."
                      isNumericStrin={true}
                      decimalSeparator={''}
                      thousandsGroupStyle="thousand"
                      className="text-line retail-price-new-not"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </Link>
        <MediaQuery maxDeviceWidth={991}>
          <div>
            {data.type !== 'external' ? (
              <>
                <a
                  className={'btn btn-big btn-white btn-buy-edu mb-2'}
                  onClick={() => signupEvent('/student/course')}
                >
                  Beli Di MyEduSolve
                </a>
              </>
            ) : (
              <a
                className={'btn btn-big btn-pink mb-2 beli-kelas'}
                style={{ color: 'white', fontFamily: 'Montserrat' }}
                onClick={() => signupEvent('/student/course')}
              >
                Beli Kelas
              </a>
            )}
          </div>
        </MediaQuery>
      </div>
    </div>
  );
};

export default Card;
