import React, { useState } from 'react'
import { useSelector } from 'react-redux'

const ResourceRow = ({content}) => {
    // const downloadResource = (id, name) => {
    //     const access_token = localStorage.getItem('access_token');
    //     setStatus('Downloading...')
    //     fetch(
    //         `${window.$API_URL}api/courseresource/lecture/${id}`,
    //         {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 Authorization: `Bearer ${access_token}`,
    //             },
    //         },
    //     )
    //         .then((response) => response.blob())
    //         .then((response) => {
    //             var filename = name + ".pdf";
    //             fileDownload(response, filename);
    //         }).catch((error) => {
    //             NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
    //         }).finally(_ => setStatus('Download File'))
    // }

    const typeFile = (type) => {
        if(type === 'pdf') return "/images/courses/resourcePdf.png"
        if(type === 'xls') return "/images/courses/resourceXls.png"
        if(type === 'ppt') return "/images/courses/resourcePpt.png"
        return "/images/courses/resourcePdf.png"
    }
    return (
        <div className="course-resource__row">
            <img src={typeFile(content.type)} alt="rsc-icon" className="course-resource__icon"/>
            <div className="course-resource__content">
                <p className="course-resource__name">{content.title}</p>
                <p className="course-resource__desc">{content.desc}</p>
            </div>
            <div className="course-resource__btn" 
                onClick={(e) => {
                    window.open(content.media_url, "_blank")
                }}> 
                <img src="/images/courses/downloadrsc.png" className="course-resource__icon-dl" alt="download icon"/>
                <p className="course-resource__desc__bold">Download File</p>
            </div>
        </div>
    )
}

const CourseResource = () => {
    const resources = useSelector(state => state.resource_list)
    return (
        <>
        <div className="course-resource">
            <h1 className="course-resource__title mb-4">Resource Kelas</h1>
            {
                resources ? (
                    resources.map(el => <ResourceRow content={el}/> )
                ) : ''
            }
        </div>
        </>
    )
}

export default CourseResource