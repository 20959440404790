import React from 'react';

const ProgressBar = ({ progress }) => {
  function parsePercent(percent) {
    percent = percent.slice(0, -1);
    percent = Number(percent);
    return percent;
  }
  return (
    <div className="progress-container">
      <div className="d-flex">
        <p className="mb-2 progress__text">Progress Kelas</p>
        <p className="mb-2 progress__text ml-auto">{`${parsePercent(progress)}%`}</p>
      </div>
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: progress }}
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
