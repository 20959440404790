import React, {useEffect, useState} from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Instagram, Facebook, Twitter, Youtube, Whatsapp, List, File, LinkedIn } from '../../static';

const Person = () => {
  const course_detail = useSelector(state => state.course_detail)

  return (
    <>
      <PersonDetail data={course_detail?.instructor}/>
      {course_detail?.course_instructors_details ? course_detail?.course_instructors_details[0] ? 
        course_detail.course_instructors_details.map(el => <PersonDetail data={el}/>) 
      : '' : ''}
    </>
  )

}

const PersonDetail = ({data}) => {
    const [text, setText] = useState('')
    const [instructor, setInstructor] = useState(data)
    const [hiddenText, setHiddenText] = useState(false);
    const [showText, setShowText] = useState(false);
    const [readMore, setReadMore] = useState(false);
    const handleReadMore = () => {
      setReadMore(true);
  }

  const handleReadLess = () => {
      setReadMore(false);
  }
    const socialLinks = [
    {
      href: instructor ? (instructor.twitter_url ? instructor.twitter_url : null) : null,
      title: <Twitter size={24} />,
      id: '69'
    },
    {
      href: instructor ? (instructor.facebook_url ? instructor.facebook_url : null) : null,
      title: <Facebook size={24} />,
      id: '68'
    },
    {
      href: instructor ? (instructor.instagram_url ? instructor.instagram_url : null) : null,
      title: <Instagram size={24} />,
      id: '65'
    },
    {
      href: instructor ? (instructor.linkedin_url ? instructor.linkedin_url : null) : null,
      title: <LinkedIn size={24} />,
      id: '66'
    }
  ]

  useEffect(() => {
    if(data) {
      setInstructor(data)
      setText(data.description)
    }
  }, [data])

  useEffect(() => {
    if (text?.length > 300) {
      setHiddenText(text?.substr(0, text?.length));
      setShowText(text?.substr(0, 300));
    }
  }, [text])

  return (
    (instructor)
      ?
      <section className="person-new">
        <div>
          <div className="person-new__image" >
             <a href={`/instructors/${instructor.id}/${instructor.fullname}`}>
               <img src={
                 (instructor.photo !== null) ?
                   instructor.photo.url : ''
               } alt={instructor.fullname} />
             </a>
           </div>
           <div>
              <a href={`/instructors/${instructor.id}/${instructor.fullname}`}><h2>{instructor.fullname}</h2></a>
              {instructor.professional_designation ? <h3>{instructor.professional_designation}</h3> : <SocialLinks socialLinks={socialLinks}/>}
           </div>
        </div>
        {instructor.professional_designation ? <SocialLinks socialLinks={socialLinks}/> : <div/>}
        {instructor.description ? text?.length <= 300 ?
                <p className="font-weight-300 info__text">{ReactHtmlParser(text)}</p>
                :
                <p>{!readMore ? ReactHtmlParser(showText) : ''}
                    {!readMore ?
                        <>
                            <span>.. </span>
                            <span className="info-new__button tx-pink" onClick={handleReadMore}>Baca Selengkapnya</span>
                        </>
                        :
                        <>
                            <span>{ReactHtmlParser(hiddenText)}</span>
                            <span className="info-new__button tx-pink" onClick={handleReadLess}>Tutup</span>
                        </>
                    }
                </p> : ''}
      </section>
      :
      ''

  )
}

const SocialLinks = ({socialLinks}) => {
  return (
  <div>
    {socialLinks.map(({ href, title, id }) => (
      href !== null ?
        <Link href={href}>
          <a className="social__link mr-2" onClick={(e) => {
            e.preventDefault()
            window.open(href, "_blank")
          }}>{title}</a>
        </Link> : ''
    ))}
  </div>
  )
}

export default Person;
