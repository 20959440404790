import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
var fileDownload = require('js-file-download');
const ExamFinish = ({ result, course_detail, certificateFailed }) => {
    const [correct, setCorrect] = useState(0);
    const [wrong, setWrong] = useState(0);
    const [score, setScore] = useState(0);
    const [loading, setLoading] = useState(false)
    const [showing, setShowing] = useState(true)
    const [returnError, setReturnError] = useState([])
    const downloadCertificate = () => {
        setLoading(true)
        const access_token = localStorage.getItem('access_token');
        axios
            .post(
                `${window.$API_URL}api/course/generatecertificate`,
                {
                    course_id: result.quiz.course_id,
                    quiz_id: result.quiz.id,
                    score: result.score
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${access_token}`,
                    },
                },
            )
            .then((success) => {
                setLoading(false)
                downloadCertificateTrigger(success.data.data.id);
            }).catch((error) => {
                // console.log(error.response)
                setLoading(false)
                if (error.response) {
                    if (error.response.status == '422') {
                        let errorTemp = []
                        Object.keys(error.response.data.errors).forEach((key) => {
                            errorTemp.push(error.response.data.errors[key][0]);
                        });
                        setShowing(true);
                        setReturnError(errorTemp)
                        const errorList2 = errorTemp.map((char, i) => {
                            return (
                                <li key={i} style={{ listStyleType: 'none' }}>
                                    {char}
                                </li>
                            );
                        });
                        NotificationManager.error(errorList2, 'Validasi Error!');
                    } else if (error.response.status == '400') {
                        NotificationManager.error(error.response.data.message, 'Validasi Error!');
                    } else {
                        NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
                    }
                } else {
                    NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
                }
            })
        //('download certificate alert');
    }

    const downloadCertificateTrigger = (certificate_id) => {
        const access_token = localStorage.getItem('access_token');
        fetch(
            `${window.$API_URL}api/certificate/${certificate_id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${access_token}`,
                },
            },
        )
            .then((response) => response.blob())
            .then((response) => {
                if (course_detail !== null) {
                    var filename = course_detail.name + ".pdf";
                } else {
                    var filename = "Sertifikat_Anda.pdf";
                }
                setLoading(false)
                fileDownload(response, filename);
            }).catch((error) => {
                setLoading(false)
                // console.log(error);
            })
    }
    useEffect(() => {
        if (result !== null) {
            var correctCount = 0;
            var wrongCount = 0;
            result.answers.map(ans => {
                (ans.is_correct) ? correctCount++ : wrongCount++;
            })
            setWrong(wrongCount);
            setCorrect(correctCount);
            setScore(result.score);
        }
    }, [result])
    console.log(certificateFailed)
    return (
        (result !== null)
            ?
            <div className="exam-finish d-flex align-items-center justify-content-center"
                style={{ backgroundImage: +result.score >= 60 ? 'url("/images/courses/examFinish.png")' : 'url("/images/courses/examFailed.png")' }}
            >
                <div className="exam-finish__modal d-flex flex-column align-items-center">
                    <h1 className="exam-finish__title">{+result.score >= 60 ? 'Selamat, kamu telah lulus Exam!' : 'Yah, kamu belum lulus Exam :('}</h1>
                    <div className="exam-finish__score_out d-flex">
                        <div className="exam-finish__score_in d-flex flex-column align-items-center vertical-line">
                            <p className="exam-finish__score__detail">
                                Benar
                        </p>
                            <p className="exam-finish__score__benar">
                                {correct}
                            </p>
                        </div>
                        <div className="exam-finish__score_in d-flex flex-column align-items-center vertical-line">
                            <p className="exam-finish__score__detail">
                                Salah
                        </p>
                            <p className="exam-finish__score__salah">
                                {wrong}
                            </p>
                        </div>
                        <div className="exam-finish__score_in d-flex flex-column align-items-center">
                            <p className="exam-finish__score__detail">
                                Nilai
                        </p>
                            <p className="exam-finish__score__nilai">
                                {score}
                            </p>
                        </div>
                    </div>
                    <a className='btn exam-finish__btn mt-5 w-100 btn-pink' onClick={(e) =>
                        (result.score < 60) ? window.location.reload() : downloadCertificate()
                    }>
                        {+result.score >= 60 ? 'Download Sertifikat' : 'Ambil Ujian Ulang'}

                    </a>
                    {result.score < 60 && certificateFailed?.data?.certificate ?
                    <a className='btn exam-finish__btn mt-2 w-100 btn-pink' onClick={(e) => window.open(certificateFailed?.data?.certificate?.certificate_pdf_url)}>
                        Download Sertifikat Penyelesaian
                    </a> : ''
                    }
                </div>
                <NotificationContainer />
                {loading ? <LoadingSpinner /> : ''}
            </div>
            :
            ''
    )
}

export default ExamFinish;