import React, { useEffect, useState } from 'react'
import MyOrder from '../components/MyOrder'
import {useDispatch, useSelector} from 'react-redux'
import {fetchMyOrders } from '../Utils/Utils';


const PesananSaya = () => {
    const orders = useSelector(state => state.userOrders)
    const dispatch = useDispatch()

    const fetchOrder = () => {
        dispatch({type: 'TOGGLE_LOADING_TRUE'});
        fetchMyOrders()
        .then(response => {
            dispatch({type: 'SET_USER_ORDERS', payload: response.data});
        })
        .catch(error => {
            console.log('error');
        })
        .finally(() => dispatch({type: 'TOGGLE_LOADING_FALSE'}))
    }

    useEffect(() => {
        fetchOrder()
    }, [])
    
    const blankBg = () => {
        if(orders !== null) {
            if(!orders[0]) {
                return 'dashboard-container list-background-orders'
            }
        } else {
            return 'dashboard-container list-background-course-empty'
        }
    }

    return (
        <>
            <div  className={blankBg()}>
                <h1 className="content-user__title  mt-4 mb-4 navbar-desc">Daftar Pesanan</h1>
                <MyOrder/>
            </div>
        </>
    )
}

export default PesananSaya