import React, { useEffect, useState } from 'react'
import MyEvent from '../components/MyEvent'
import ReferralComp from '../components/MyReferral'


const EditProfil = () => {
    return (
        <>
            <div  className="dashboard-container">
                <div className="row mt-5 mb-4 navbar-desc ">
                    <div className="col-md-6">
                        <h1 className="content-user__title mb-3">Referral</h1>
                        <p>Ajak teman-teman Anda untuk bergabung dan belajar bersama dan dapatkan hadiah menarik dari Myedusolve untuk Anda dan teman-teman Anda.</p>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end">
                        <img src="/images/rafiki.png" />
                    </div>
                </div>
                <ReferralComp/>
            </div>
        </>
    )
}

export default EditProfil