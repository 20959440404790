import React, { useState, useRef, useEffect } from 'react';
import { AssignmentIcon } from '../../static';
import Button from '../Button';

const CourseFinish = () => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-around">
            <h1 className="assignment__title mb-3">Exam</h1>
            <h2 className="rating__desc_2 mt-3 mb-3" style={{fontSize: '16px', textAlign: 'center'}}>Yeayy, kamu telah menyelesaikan kelas <b>Desain Grafis untuk Pemula</b>.</h2>
            <p className="assignment__download mb-4">Kerjakan ujian akhir untuk mendapatkan <b>sertifikat</b> dari MyEduSolveX. Nilai minimal yang harus kalian dapatkan adalah <b>60</b>. </p>
            <div className='rating__btn align-self-stretch d-flex flex-column'>
                <a className='btn btn-lg btn-pink align-self-stretch' style={{fontSize: '16px'}}>
                    Mulai Sekarang
                </a>
            </div>
        </div>
        )
}

export default CourseFinish