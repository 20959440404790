import React from 'react'

const FeaturedBy = () => {
    const featuredContent = ['katadata', 'techinasia', 'tempo', 'e27', 'kompas']
    
    return (
        <>
          <div className="featured-by">
            <div>
              <h1>As Featured By</h1>
              <div>{featuredContent.map(name => <div><img src={`/images/landing/homepage-new/featuredlogo/${name}.png`}/></div>)}</div>
            </div> 
          </div>
        </>
    )
}

export default FeaturedBy