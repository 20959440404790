import React from 'react';
// import { Link} from 'react-router-dom'
// import { Redirect, Route,  } from 'react-router-dom';
// import * as router from 'react-router-dom';
class LandingDivider extends React.Component {
  render() {
    return (
      <>
        <section
          className="divider_home2 parallax bg-img2"
          data-stellar-background-ratio="0.3"
          style={{
            backgroundImage: `url("images/home/home-section-04.jpg")`,
            backgroundSize: `cover`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <div className="main-title text-center">
                  <h3 className="color-white mt0">MyEduSolve X</h3>
                  <p className="color-white">Bergabunglah Dengan Kami</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-lg-4 text-center">
                <div className="funfact_one">
                  <div className="icon">
                    <span className="flaticon-student-3" />
                  </div>
                  <div className="details">
                    <ul>
                      <li className="list-inline-item">
                        <div className="timer">20,000</div>
                      </li>
                    </ul>
                    <h5>Peserta Yang Terdaftar</h5>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 text-center">
                <div className="funfact_one">
                  <div className="icon">
                    <span className="flaticon-cap" />
                  </div>
                  <div className="details">
                    <div className="timerAlt">140+</div>
                    <h5>Kelas Yang Berkualitas</h5>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 text-center">
                <div className="funfact_one">
                  <div className="icon">
                    <span className="flaticon-jigsaw" />
                  </div>
                  <div className="details">
                    <div className="timerAlt">50+</div>
                    <h5>Instruktur Yang handal</h5>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
      </>
    );
  }
}

export default LandingDivider;
