import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { CollapseDown, CollapseUp } from '../../static';
import SubMateri from './SubMateri';

const ContentDrawer = ({ arrayContent, openModal, openedID, setOpenedID, lessonNumber }) => {
  const dispatch = useDispatch();
  const selectedID = useSelector((state) => state.materi_open_id);
  const lesson_detail_number = useSelector((state) => state.lesson_detail_number);
  const [lessonResource, setLessonResource] = useState(null);
  const [resourceLoading, setResourceLoading] = useState(null);
  const lesson_detail = useSelector((state) => state.course_detail);

  const fetchLessonResource = () => {
    setResourceLoading(true);
    const access_token = localStorage.getItem('access_token');
    axios
      .get(`${window.$API_URL}api/courseresource/lesson/${arrayContent.id}`, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
      })
      .then((success) => {
        setLessonResource(success.data.data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == '422') {
            let _returnError = [];
            Object.keys(error.response.data.errors).forEach((key) => {
              _returnError.push(error.response.data.errors[key][0]);
            });
            let _showing = true;
            this.setState({ returnError: _returnError, showing: _showing });
            const errorList2 = _returnError.map((char, i) => {
              return (
                <li key={i} style={{ listStyleType: 'none' }}>
                  {char}
                </li>
              );
            });
            NotificationManager.error(errorList2, 'Validasi Error!');
          } else {
            NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
          }
        } else {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        }
      })
      .finally((_) => setResourceLoading(false));
  };

  const selectSubMateri = (id) => {
    dispatch({
      type: 'UPDATE_MATERI_OPEN',
      payload: id,
    });
  };

  const selectExamOrMateri = (selected) => {
    dispatch({
      type: 'UPDATE_EXAM_OR_MATERI',
      payload: selected,
    });
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (arrayContent.id !== openedID) {
      setOpenedID(arrayContent.id);
    } else setOpenedID(0);
  };

  const isOpened = () => {
    if (arrayContent.id === openedID) {
      return true;
    }
    return false;
  };

  const isQuizRequired = (subMateri) => {
    if (lesson_detail) {
      if (lesson_detail.is_lecturequiz_required && lesson_detail.is_lecturequiz_enabled) {
        if (subMateri.lecture_quiz_questions) {
          return subMateri.lecture_quiz_questions[0];
        }
      }
    }
    return false;
  };

  const ResourcePerLesson = () => {
    return (
      <div>
        {lessonResource !== null ? (
          lessonResource[0] ? (
            <div
              className={`d-flex hosted-table-subcontent align-items-center  ${
                lesson_detail_number === lessonNumber && selectedID === 'download'
                  ? 'hosted-table-subcontent-selected'
                  : ''
              }`}
              onClick={() => {
                selectSubMateri('download');
                selectExamOrMateri('download');
                dispatch({
                  type: 'UPDATE_RESOURCE',
                  payload: lessonResource,
                });
              }}
            >
              <img
                src="/images/courses/downloadrsc.png"
                className="mr-4  ml-1"
                style={{ width: '24px', height: '16px' }}
                alt="download"
              ></img>
              <p className="class-subcontent-list-dl mr-auto">{`Download resource ${arrayContent.name}`}</p>
              <p className="class-subcontent-list m-0">{`${lessonResource.length} File`}</p>
            </div>
          ) : (
            ''
          )
        ) : (
          ''
        )}
        {resourceLoading ? (
          <div
            className={`d-flex hosted-table-subcontent align-items-center  ${
              lesson_detail_number === lessonNumber && selectedID === 'download'
                ? 'hosted-table-subcontent-selected'
                : ''
            }`}
          >
            <img
              src="/images/courses/downloadrsc.png"
              className="mr-4  ml-1"
              style={{ width: '24px', height: '16px' }}
              alt="download"
            ></img>
            <p className="class-subcontent-list-dl mr-auto">Loading...</p>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };

  useEffect(() => {
    if (isOpened()) fetchLessonResource();
  }, [arrayContent, openedID]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!arrayContent?.lectures[0]) return '';

  return (
    <div className={'content-drawer'}>
      <a
        className={`d-flex justify-content-between hosted-table align-items-center ${
          isOpened() ? 'hosted-table-selected' : ''
        }`}
        href="/"
        onClick={handleClick}
        aria-controls="class-subcontent-small"
        aria-expanded={isOpened()}
      >
        <h2 className="class-subcontent-materi m-0">{arrayContent.name}</h2>
        {!isOpened() ? <CollapseDown /> : <CollapseUp />}
      </a>
      <Collapse isOpened={arrayContent.id === openedID}>
        <div id="class-subcontent-small">
          {arrayContent.lectures.map((subMateri, i) => (
            <div>
              <SubMateri
                content={subMateri}
                openModal={openModal}
                selectThisTab={() => {
                  setOpenedID(arrayContent.id);
                }}
                quiz={isQuizRequired(subMateri)}
                lesson_detail_number_local={lessonNumber}
                lecture_number_local={i}
                isLessonSchedulePassed={arrayContent?.is_lesson_schedule_passed ?? false}
                parrentContent={arrayContent}
              />
            </div>
          ))}
          <ResourcePerLesson />
        </div>
      </Collapse>
    </div>
  );
};

export default ContentDrawer;
