import React from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

const VideoPreviewBottom = () => {
    const course_detail = useSelector(state => state.course_detail)
    if(course_detail?.is_purchased) return ''
    return (
        <div className="video-preview-bottom">
            <h2>Bergabunglah dengan 28.000+ pelajar di seluruh Indonesia</h2>
        </div>
    )
}

export default VideoPreviewBottom;