import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'react-notifications/lib/notifications.css';
import axios from 'axios'
import { NotificationManager } from 'react-notifications';


const VeryDisappointed = () => <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.9999 35.9998C27.941 35.9998 35.9998 27.941 35.9998 17.9999C35.9998 8.05884 27.941 0 17.9999 0C8.05884 0 0 8.05884 0 17.9999C0 27.941 8.05884 35.9998 17.9999 35.9998Z" fill="#FAC600"/>
<path d="M35.9998 18.0002C35.9998 27.9416 27.9414 35.9999 18.0001 35.9999C12.0999 35.9999 6.86238 33.1619 3.5791 28.7759C6.69065 31.342 10.6792 32.8832 15.0262 32.8832C24.9676 32.8832 33.0259 24.8249 33.0259 14.8835C33.0259 10.8441 31.6953 7.1157 29.4504 4.11108C33.4507 7.41138 35.9998 12.4074 35.9998 18.0002Z" fill="#E0AE10"/>
<path d="M9.9271 18.1646C9.24234 18.1646 8.6875 17.6096 8.6875 16.925V14.442C8.6875 13.7574 9.24234 13.2024 9.9271 13.2024C10.6118 13.2024 11.1667 13.7574 11.1667 14.442V16.925C11.1667 17.6096 10.6118 18.1646 9.9271 18.1646Z" fill="#444444"/>
<path d="M26.0726 18.1646C25.3879 18.1646 24.833 17.6096 24.833 16.925V14.442C24.833 13.7574 25.3879 13.2024 26.0726 13.2024C26.7574 13.2024 27.3122 13.7574 27.3122 14.442V16.925C27.3122 17.6096 26.7574 18.1646 26.0726 18.1646Z" fill="#444444"/>
<path d="M11.4524 27.0374C11.2154 27.0374 10.9761 26.9696 10.7645 26.828C10.1954 26.4475 10.0426 25.6776 10.4232 25.1086C12.1104 22.5856 14.9272 21.0501 17.9585 21.0012C21.0249 20.9515 23.9079 22.4221 25.6722 24.934C26.0657 25.4943 25.9305 26.2674 25.3702 26.6609C24.8101 27.0544 24.0369 26.9191 23.6434 26.3589C22.3521 24.5202 20.2487 23.4445 17.9985 23.4801C15.7806 23.5158 13.7192 24.6398 12.4842 26.4865C12.2447 26.8442 11.8522 27.0374 11.4524 27.0374Z" fill="#444444"/>
</svg>

const Disappointed = () => <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.9999 35.9998C27.941 35.9998 35.9998 27.941 35.9998 17.9999C35.9998 8.05884 27.941 0 17.9999 0C8.05884 0 0 8.05884 0 17.9999C0 27.941 8.05884 35.9998 17.9999 35.9998Z" fill="#FAC600"/>
<path d="M10 24.5C10 24.5 12.5 23 18 23C23.5 23 26 24.5 26 24.5" stroke="#444444" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M35.9993 18.0002C35.9993 27.9416 27.941 35.9999 17.9996 35.9999C12.0994 35.9999 6.86189 33.1619 3.57861 28.7759C6.69016 31.342 10.6787 32.8832 15.0257 32.8832C24.9671 32.8832 33.0254 24.8249 33.0254 14.8835C33.0254 10.8441 31.6948 7.1157 29.45 4.11108C33.4502 7.41138 35.9993 12.4074 35.9993 18.0002Z" fill="#E0AE10"/>
<path d="M9.92661 18.1646C9.24185 18.1646 8.68701 17.6096 8.68701 16.925V14.442C8.68701 13.7574 9.24185 13.2024 9.92661 13.2024C10.6114 13.2024 11.1662 13.7574 11.1662 14.442V16.925C11.1662 17.6096 10.6114 18.1646 9.92661 18.1646Z" fill="#444444"/>
<path d="M26.0731 18.1646C25.3883 18.1646 24.8335 17.6096 24.8335 16.925V14.442C24.8335 13.7574 25.3883 13.2024 26.0731 13.2024C26.7578 13.2024 27.3127 13.7574 27.3127 14.442V16.925C27.3127 17.6096 26.7578 18.1646 26.0731 18.1646Z" fill="#444444"/>
</svg>

const Neutral = () => <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05888 27.9411 0 18 0C8.05888 0 0 8.05888 0 18C0 27.9411 8.05888 36 18 36Z" fill="#FAC600"/>
<path d="M9.89829 18.1648C9.21354 18.1648 8.65869 17.6098 8.65869 16.9252V14.4422C8.65869 13.7576 9.21354 13.2026 9.89829 13.2026C10.583 13.2026 11.1379 13.7576 11.1379 14.4422V16.9252C11.1379 17.6098 10.583 18.1648 9.89829 18.1648Z" fill="#444444"/>
<path d="M26.0448 18.1648C25.36 18.1648 24.8052 17.6098 24.8052 16.9252V14.4422C24.8052 13.7576 25.36 13.2026 26.0448 13.2026C26.7295 13.2026 27.2844 13.7576 27.2844 14.4422V16.9252C27.2844 17.6098 26.7294 18.1648 26.0448 18.1648Z" fill="#444444"/>
<path d="M26.045 24.5827H9.89878C9.21403 24.5827 8.65918 24.0277 8.65918 23.3431C8.65918 22.6585 9.21403 22.1035 9.89878 22.1035H26.045C26.7298 22.1035 27.2846 22.6585 27.2846 23.3431C27.2846 24.0277 26.7296 24.5827 26.045 24.5827Z" fill="#444444"/>
<path d="M36 17.9997C36 27.9412 27.9416 35.9996 18.0001 35.9996C12.1 35.9996 6.86239 33.1616 3.5791 28.7755C6.69067 31.3415 10.679 32.8829 15.0261 32.8829C24.9675 32.8829 33.0259 24.8245 33.0259 14.8831C33.0259 10.8436 31.6952 7.11522 29.4504 4.1106C33.4508 7.41074 36 12.407 36 17.9997Z" fill="#E0AE10"/>
</svg>

const Happy = () => <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.9999 35.9998C27.941 35.9998 35.9998 27.941 35.9998 17.9999C35.9998 8.05884 27.941 0 17.9999 0C8.05884 0 0 8.05884 0 17.9999C0 27.941 8.05884 35.9998 17.9999 35.9998Z" fill="#FAC600"/>
<path d="M36.0001 18.0002C36.0001 27.9416 27.9418 35.9999 18.0004 35.9999C12.1002 35.9999 6.8627 33.1619 3.57959 28.7759C6.69114 31.342 10.6795 32.8832 15.0265 32.8832C24.9679 32.8832 33.0263 24.8249 33.0263 14.8835C33.0263 10.8441 31.6956 7.1157 29.4508 4.11108C33.4512 7.41138 36.0001 12.4074 36.0001 18.0002Z" fill="#E0AE10"/>
<path d="M9.92661 18.1646C9.24185 18.1646 8.68701 17.6096 8.68701 16.925V14.442C8.68701 13.7574 9.24185 13.2024 9.92661 13.2024C10.6114 13.2024 11.1662 13.7574 11.1662 14.442V16.925C11.1662 17.6096 10.6112 18.1646 9.92661 18.1646Z" fill="#444444"/>
<path d="M26.0731 18.1646C25.3883 18.1646 24.8335 17.6096 24.8335 16.925V14.442C24.8335 13.7574 25.3883 13.2024 26.0731 13.2024C26.7578 13.2024 27.3127 13.7574 27.3127 14.442V16.925C27.3127 17.6096 26.7578 18.1646 26.0731 18.1646Z" fill="#444444"/>
<path d="M17.9471 28.0572C14.9373 28.0572 12.119 26.5944 10.383 24.1233C9.98952 23.563 10.1247 22.79 10.685 22.3964C11.2453 22.0029 12.0187 22.1383 12.4119 22.6982C13.7032 24.537 15.8113 25.608 18.0566 25.5771C20.2745 25.5414 22.3361 24.4175 23.571 22.5706C23.9518 22.0014 24.7219 21.8489 25.2904 22.2293C25.8595 22.61 26.0124 23.3797 25.6317 23.9487C23.9445 26.4717 21.1275 28.0072 18.0965 28.0559C18.0469 28.0569 17.9968 28.0572 17.9471 28.0572Z" fill="#444444"/>
</svg>

const VeryHappy = () => <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.9999 35.9998C27.941 35.9998 35.9998 27.941 35.9998 17.9999C35.9998 8.05884 27.941 0 17.9999 0C8.05884 0 0 8.05884 0 17.9999C0 27.941 8.05884 35.9998 17.9999 35.9998Z" fill="#FAC600"/>
<path d="M36.0001 18.0002C36.0001 27.9416 27.9418 35.9999 18.0004 35.9999C12.1002 35.9999 6.8627 33.1619 3.57959 28.7759C6.69114 31.342 10.6795 32.8832 15.0265 32.8832C24.9679 32.8832 33.0263 24.8249 33.0263 14.8835C33.0263 10.8441 31.6956 7.1157 29.4508 4.11108C33.4512 7.41138 36.0001 12.4074 36.0001 18.0002Z" fill="#E0AE10"/>
<path d="M9.92661 18.1646C9.24185 18.1646 8.68701 17.6096 8.68701 16.925V14.442C8.68701 13.7574 9.24185 13.2024 9.92661 13.2024C10.6114 13.2024 11.1662 13.7574 11.1662 14.442V16.925C11.1662 17.6096 10.6112 18.1646 9.92661 18.1646Z" fill="#444444"/>
<path d="M26.0731 18.1646C25.3883 18.1646 24.8335 17.6096 24.8335 16.925V14.442C24.8335 13.7574 25.3883 13.2024 26.0731 13.2024C26.7578 13.2024 27.3127 13.7574 27.3127 14.442V16.925C27.3127 17.6096 26.7578 18.1646 26.0731 18.1646Z" fill="#444444"/>
<rect x="12" y="24" width="12" height="2" fill="white"/>
<path d="M24.9351 25H11.0649C10.4766 25 10 24.5523 10 24C10 23.4477 10.4766 23 11.0649 23H24.9351C25.5234 23 26 23.4477 26 24C26 24.5523 25.5232 25 24.9351 25Z" fill="#444444"/>
<path d="M17.9466 30C14.8761 30 12.0009 28.3038 10.2299 25.4387C9.82841 24.789 9.96634 23.8927 10.5379 23.4364C11.1096 22.9801 11.8985 23.1371 12.2996 23.7864C13.617 25.9183 15.7678 27.1601 18.0584 27.1243C20.3211 27.0829 22.4242 25.7798 23.6841 23.6384C24.0726 22.9784 24.8582 22.8015 25.4382 23.2427C26.0188 23.684 26.1748 24.5765 25.7864 25.2363C24.0652 28.1616 21.1913 29.9419 18.099 29.9985C18.0485 29.9996 17.9974 30 17.9466 30Z" fill="#444444"/>
</svg>

const LoadingIcon = () => <div><img src="/images/smallLoading.gif" alt="loading"/></div>

const NotificationDropdownReviewSatisfaction = ({data, actionTaken, date}) => {
  const dispatch = useDispatch() 
  const profile_data = useSelector(state => state.profile_data)
  const [scoreSelected, setScoreSelected] = useState(0)
  const [loading, setLoading] = useState(false)

  const sendSurvey = () => { 
    setLoading(true)
    const access_token = localStorage.getItem('access_token');
    axios
        .post(
        `${window.$API_URL}api/user/happiness`, {
        happiness_level: scoreSelected,
        notification_id: data.id
        },
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
        },
        )
        .then((success) => {
          NotificationManager.success('Terimakasih atas ulasan Anda!', 'Success');
          return actionTaken(data.id)
        }).then(() => {
          let notificationTemp = [...profile_data.notifications]
          notificationTemp.forEach(elNotif => {
            if(+elNotif.id === +data.id) {
              elNotif.is_action_taken = 1
            }
          })
          dispatch({
            type: 'ADD_PROFILEDATA',
            payload: {...profile_data, notifications: notificationTemp},
          })
        })
        .catch((error) => {
            if (!error.response) {
                NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
                return false
            }
            if (error.response.status === 422) {
              let errorTemp = []
              Object.keys(error.response.data.errors).forEach((key) => {
                errorTemp.push(error.response.data.errors[key][0]);
              });
              const errorList2 = errorTemp.map((char, i) => {
                return (
                  <li key={i} style={{ listStyleType: 'none' }}>
                    {char}
                  </li>
                );
              });
              NotificationManager.error(errorList2, 'Validasi Error!');
            } else if (error.response.status === 401) {
              NotificationManager.error(error.response.data.message, 'Validasi Error!');
            } else {
              NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
            }
        })
        .finally(() => setLoading(false))
    }

  return (
    <div>
      <h2>{data.group.description_id}?</h2>
      <p></p>
      <div>
        <div onClick={() => setScoreSelected(1)} className={scoreSelected === 1 ? 'satisfaction-selected' : ''}>
          <div><VeryDisappointed/></div>
          <p>Sangat Kecewa</p>
        </div>
        <div onClick={() => setScoreSelected(2)} className={scoreSelected === 2 ? 'satisfaction-selected' : ''}>
          <div><Disappointed/></div>
          <p>Kecewa</p>
        </div>
        <div onClick={() => setScoreSelected(3)} className={scoreSelected === 3 ? 'satisfaction-selected' : ''}>
          <div><Neutral/></div>
          <p>Netral</p>
        </div>
        <div onClick={() => setScoreSelected(4)} className={scoreSelected === 4 ? 'satisfaction-selected' : ''}>
          <div><Happy/></div>
          <p>Baik</p>
        </div>
        <div onClick={() => setScoreSelected(5)} className={scoreSelected === 5 ? 'satisfaction-selected' : ''}>
          <div><VeryHappy/></div>
          <p>Sangat Baik</p>
        </div>
      </div>
      <div>
        <button disabled={data.is_action_taken || loading} className={`btn btn-pink ${!data.is_action_taken && !loading ? '' : 'btn-disabled'}`} onClick={(e) => {
          e.preventDefault()
          if(scoreSelected) sendSurvey()

        }}>
          {loading ? <LoadingIcon/> : ''}
          {loading ? 'Mengirim Ulasan..' : 'Submit' }
        </button>
      </div>
    </div>
  )
}

export default NotificationDropdownReviewSatisfaction;
