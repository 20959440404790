import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import Card from '../Slider/Card'
import Slider from '../Slider'

const UpcomingCourse = () => {
    const dispatch = useDispatch()
    const [featuredCourse, setFeaturedCourse] = useState(null)
    const [returnError, setReturnError] = useState([])

    useEffect(() => {
        const fetchUpcomingLiveCourse = async () => {
            dispatch({ type: 'TOGGLE_LOADING_TRUE' })
            axios.get(`${window.$API_URL}api/course/upcominglivecourse`, {},
                {
                    headers: { 'Content-Type': 'application/json', 'x-access-token': '' },
                },
            ).then((success) => {
                dispatch({ type: 'TOGGLE_LOADING_FALSE' })
                if (success.data.success) {
                    setFeaturedCourse(success.data.data)
                }
            })
                .catch((error) => {
                    dispatch({ type: 'TOGGLE_LOADING_FALSE' })
                    if (!error.response) {
                        NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
                        return false
                    }
                    if (error.response.status === 422) {
                        setReturnError([]);
                        let errorTemp = []
                        Object.keys(error.response.data.errors).forEach((key) => {
                            errorTemp.push(error.response.data.errors[key][0]);
                        });
                        setReturnError(errorTemp)
                        const errorList2 = returnError.map((char, i) => {
                            return (
                                <li key={i} style={{ listStyleType: 'none' }}>
                                    {char}
                                </li>
                            );
                        });
                        NotificationManager.error(errorList2, 'Validasi Error!');
                    } else {
                        NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
                    }
                });
        }
        fetchUpcomingLiveCourse()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    const isEmpty = () => {
        if(featuredCourse !== null){
            if(featuredCourse[0]){
                return true
            }
        }

        return false
    }
    return (
        <>
            {isEmpty() ?
            <div className="kelas-populer ">
                <h1 className="kelas-populer__title">Live Course Yang Akan Datang</h1>
                <h1 className="kelas-populer__desc">Berikut adalah beberapa opsi untuk kamu yang lebih suka blended learning!</h1>
                <div className="navbar-desc">
                    <div className="row main-row">
                        {
                            featuredCourse.map(el => (
                                <div className="col-md-3 col-xs-12 col-sm-6 mb-4 d-flex justify-content-center">
                                    <Card data={el} key={el.id} />
                                </div>
                            ))
                        }     
                    </div>
                </div>
                <div className="navbar-mob">
                    {
                        <Slider courses_list={featuredCourse} sliderOnly noArrow/>
                    }
                </div>
            </div> : ''}
        </>
    )
}

export default UpcomingCourse