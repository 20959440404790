import React, { useState } from 'react'
import { CloseModals } from '../../static';
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const VoucherInput = ({ closeModal, redeemVoucher, setModal, voucher, setVoucher, name, type }) => {
    const query = useQuery()
    const [inputClicked, setInputClicked] = useState(false)
    return (
        <>
        <div className="d-flex justify-content-end pt-3 pr-3 mt-2" >
            <h2 className="discount-voucher-modal__title mr-auto ">Redeem Voucher</h2>
            <div className="mt-1 mr-3 pointer" onClick={(e) => closeModal() }><CloseModals/></div>
        </div>
        <div className="discount-voucher-modal__input-container">
            <input
            className="discount-voucher-modal__input mr-3"
            placeholder="Masukkan Kode Voucher"
            style={{ margin: 0 }}
            onChange={(e) => setVoucher(e.target.value)}
            onClick={(e) => {
                if(!inputClicked) {
                    setInputClicked(true)
                    ReactGA.event({
                        category: type === 'event' ? 'Event' : query.get('section') === 'kelas_populer' ? 'Kelas Populer' : 'Semua Kelas',
                        action: `Click-Voucher`,
                        label: name + ' Kode Voucher',
                    })
                }
            }}
            />
            <button className="btn btn-pink btn-pink-hover discount-voucher-modal__btn" onClick={(e) => redeemVoucher().then(_ => setModal('done')).catch(_ => console.log(''))}>Periksa</button>
        </div>
        </>
    )
}

const VoucherDone = ({ closeModal, setModal, voucher }) => {
    return (
        <>
            <div className="d-flex flex-column align-items-center mt-3">
                <img src="/images/doneEdit.png"  className="discount-voucher-modal__berhasil-img" alt="done edit"/>
                <h2 className="discount-voucher-modal__berhasil">Voucher Berhasil digunakan!</h2>
                <button className="btn btn-pink btn-pink-hover discount-voucher-modal__oke-btn" onClick={() => {
                    closeModal()
                    setModal('default')
                }}>Oke</button>
            </div>
        </>
    )
}

const VoucherModal = ({ closeModal, modalOpen, redeemVoucher, voucher, setVoucher, name, type }) => {
    const [modal, setModal] = useState('default')

    return (
        <>
        <div
                className={`discount-voucher-modal modal fade ${modalOpen ? 'show' : ''}`}
                tabIndex="-1"
                role="dialog"
                aria-hidden="true"
                style={{ display: `${modalOpen ? 'block' : 'none'}` }}
            >
                <div className="discount-voucher-modal__dialog modal-dialog-centered discount-voucher-modal__size" style={{maxWidth: '100vw'}}>
                    <div className="modal-content" style={{borderRadius: '10px'}}>
                        {modal === 'default' ? 
                            <VoucherInput closeModal={closeModal} redeemVoucher={redeemVoucher} setModal={setModal} voucher={voucher} setVoucher={setVoucher} name={name} type={type}/>
                        : ''}
                        {modal === 'done' ? 
                            <VoucherDone closeModal={closeModal} setModal={setModal} voucher={voucher}/>
                        : ''}
                    </div>
                </div>
        </div>
        </>
    )
}

export default VoucherModal