import React from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { Tooltip } from 'antd';
 
 
const FacebookSignInButton = ({ signup, facebookLogin }) => {
  const responseFacebook = (response) => {
    facebookLogin(response);
  }
  const responseFacebookFail = (response) => {
    facebookLogin(null, true);
  }
  return (
    <FacebookLogin
    appId={window.$FACEBOOK_CLIENT_ID}
    // autoLoad={true}
    // onClick={componentClicked}
    fields="name,email,picture"
    callback={responseFacebook}
    onFailure={responseFacebookFail}
    render={renderProps => (
        <Tooltip title={`${signup ? 'Daftar' : 'Masuk'} dengan Facebook`}>
          <button onClick={renderProps.onClick}>
              <img src="https://cdn.iconscout.com/icon/free/png-512/facebook-logo-2019-1597680-1350125.png" />
          </button>
        </Tooltip>
      )}
    />
  )
}

export default FacebookSignInButton