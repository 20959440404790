import React, { useEffect, useState } from 'react'
import MyEvent from '../components/MyEvent'
import EditProfileComp from '../components/EditProfile'


const EditProfil = () => {
    return (
        <>
            <div  className="dashboard-container">
                <h1 className="content-user__title  mt-4 mb-4 navbar-desc">Edit Profil</h1>
                <EditProfileComp/>
            </div>
        </>
    )
}

export default EditProfil