import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer';
import { connect } from 'react-redux';
import { removeScript  } from '../Utils/Utils';
import Route from '../components/LandingComponent/Route'
import LoadingSpinner from '../Layout/LoadingSpinner';
import Kontak from '../components/LandingComponent/Kontak'
import LandingBannerNew from '../components/LandingNew/LandingBannerNew'

class KontakPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount = async () => {
  };

  componentWillUnmount() {
    removeScript('https://app.sandbox.midtrans.com/snap/snap.js');
  }

  render() {
    return (
      <>
        <Header/>
        <div className="main-container container d-flex flex-column" style={{marginBottom: '20px'}}>
            <Route name="Kontak"/>
        </div>
        <LandingBannerNew/>
        <div className="main-container container d-flex flex-column" style={{marginBottom: '60px', marginTop: '-20px'}}>
          <main>
              <Kontak/>
          </main>
        </div>
        <Footer />
        {this.props.isLoading ? <LoadingSpinner text={"Mohon menunggu.."} /> : ''}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { isLoading: state.isLoading };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KontakPage);
