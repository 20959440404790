import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import NumberFormat from 'react-number-format';
import moment from 'moment'
import axios from 'axios'
import { NotificationManager } from 'react-notifications';
import ReactPaginate from 'react-paginate'
import ReactGA from 'react-ga';

const GlassIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
            className="ml-auto mr-3">
            <path d="M6.5 0C8.22391 0 9.87721 0.684819 11.0962 1.90381C12.3152 3.12279 13 4.77609 13 6.5C13 8.11 12.41 9.59 11.44 10.73L11.71 11H12.5L17.5 16L16 17.5L11 12.5V11.71L10.73 11.44C9.59 12.41 8.11 13 6.5 13C4.77609 13 3.12279 12.3152 1.90381 11.0962C0.684819 9.87721 0 8.22391 0 6.5C0 4.77609 0.684819 3.12279 1.90381 1.90381C3.12279 0.684819 4.77609 0 6.5 0ZM6.5 2C4 2 2 4 2 6.5C2 9 4 11 6.5 11C9 11 11 9 11 6.5C11 4 9 2 6.5 2Z" fill="black" />
        </svg>
    )
}

const EventCard = ({ data }) => {
    const history = useHistory()
    return (
        <a href={`/webinar/${data.id}`}  className="w-100" style={{maxWidth: '274px'}} onFocus={() => {
            ReactGA.event({
                category: 'Events',
                action: `Click-Event`,
                label: data.title,
              })
        }}>
            <div className="event-card card pointer" 
            >
                <div className="event-card__bg d-flex"
                    style={{
                        background: `white url("${(data.image !== null) ? data.image.url : '../../static/container.png'}") no-repeat`,
                        backgroundSize: '274px 274px'
                    }}>
                    <div className="d-flex mt-auto w-100">
                        <div className="event-card__schedule d-flex ml-3 flex-column  align-items-center">
                            <p className="event-card__schedule-date mt-2">{moment(data.start_date).format('DD')}</p>
                            <p className="event-card__schedule-month">{moment(data.start_date).format('MMM')}</p>
                        </div>

                        {data.price !== "0.00" ?
                            <div className="d-flex mt-auto ml-auto mr-4">
                                <p className="event-card__price mr-2">Rp</p><NumberFormat
                                    value={+data.price}
                                    displayType={'text'}
                                    thousandSeparator="."
                                    isNumericStrin={true}
                                    decimalSeparator={""}
                                    thousandsGroupStyle="thousand"
                                    className="event-card__price"
                                /></div> :
                            <p className="event-card__price mt-auto ml-auto mr-4">Gratis</p>
                        }
                    </div>
                </div>
                <div className="event-card__content">
                    <p className="event-card__content-main text-color-black">{data.title}</p>
                    <div className="d-flex mt-4 align-content-center mb-1">
                        <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 0C11.31 0 14 2.66 14 5.95C14 10.41 8 17 8 17C8 17 2 10.41 2 5.95C2 2.66 4.69 0 8 0ZM8 4C7.46957 4 6.96086 4.21071 6.58579 4.58579C6.21071 4.96086 6 5.46957 6 6C6 6.53043 6.21071 7.03914 6.58579 7.41421C6.96086 7.78929 7.46957 8 8 8C8.53043 8 9.03914 7.78929 9.41421 7.41421C9.78929 7.03914 10 6.53043 10 6C10 5.46957 9.78929 4.96086 9.41421 4.58579C9.03914 4.21071 8.53043 4 8 4ZM16 17C16 19.21 12.42 21 8 21C3.58 21 0 19.21 0 17C0 15.71 1.22 14.56 3.11 13.83L3.75 14.74C2.67 15.19 2 15.81 2 16.5C2 17.88 4.69 19 8 19C11.31 19 14 17.88 14 16.5C14 15.81 13.33 15.19 12.25 14.74L12.89 13.83C14.78 14.56 16 15.71 16 17Z" fill="black" />
                        </svg>
                        <p className="event-card__content-main text-color-black mt-0 ml-3 mr-2">Event:</p>
                        <p className="event-card__content-main text-color-black mt-0" style={{ fontWeight: '300', textTransform: 'capitalize' }}>{data.location}</p>
                    </div>
                </div>
            </div>
        </a>
    )
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const EventListing = () => {
    const eventList = useSelector(state => state.all_event_list)
    const dispatch = useDispatch()
    const history = useHistory()
    const pageQuery = useQuery().get("page")
    const page = pageQuery ? +(pageQuery) - 1 : 0
    const [pageSelected, setPageSelected] = useState(page)
    const [nameSearch, setNameSearch] = useState('')

    const handleSetNameSeatch = (name) => {
        setNameSearch(name)
        setPageSelected(0)
    }

    const fetchWebinarList = async () => {
        dispatch({ type: 'TOGGLE_LOADING_TRUE' })
        axios
            .get(`${window.$API_URL}api/event/listing?page=${pageSelected + 1}&search_term=${nameSearch}`, {
                headers: { 'Content-Type': 'application/json' },
            })
            .then((success) => {
                console.log(success)
                dispatch({
                    type: 'UPDATE_EVENT_LIST',
                    payload: {
                        data: success.data.data,
                        result_from: success.data.from,
                        result_to: success.data.to,
                        total_page: success.data.last_page,
                        next_page_url: success.data.next_page_url,
                        prev_page_url: success.data.prev_page_url,
                        current_page: success.data.current_page
                    },
                })
            })
            .catch((error) => { 
                if (error.response) {
                    if (error.response.status == '422') {
                        this.state.returnError = [];
                        Object.keys(error.response.data.errors).forEach((key) => {
                            this.state.returnError.push(error.response.data.errors[key][0]);
                        });
                        this.state.showing = true;
                        this.setState({ returnError: this.state.returnError, showing: this.state.showing });
                        const errorList2 = this.state.returnError.map((char, i) => {
                            return (
                                <li key={i} style={{ listStyleType: 'none' }}>
                                    {char}
                                </li>
                            );
                        });
                        NotificationManager.error(errorList2, 'Validasi Error!');
                    } else {
                        NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
                    }
                } else {
                    NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
                }
            })
            .finally(_ => dispatch({ type: 'TOGGLE_LOADING_FALSE' }))
    };

    useEffect(() => {
        fetchWebinarList()
    }, [pageSelected, nameSearch])

    useEffect(() => {
        history.push({ search: `?page=${pageSelected + 1}` })
    }, [pageSelected])

    return (
        <>
            <div className="all-instructor">
                <div>
                <h1 className="kelas-populer__title" style={{ marginBottom: '10px' }}>Semua Event</h1>
                {eventList !== null ? (
                    <>
                    <div className="navbar-desc">
                        <div className="d-flex mt-3">
                            {eventList.result_from ? <p>{`Menampilkan ${eventList.result_from} - ${eventList.result_to} Event`}</p> : <p>{`Tidak ada Event yang ditemukan`}</p> }
                            <div className="d-flex flex-row align-items-center ml-auto all-course__search" style={{ marginBottom: '20px' }}>
                                <input className="search__input mr-3" placeholder="Cari event" style={{ margin: 0, border: 0 }}
                                    onChange={(e) => {
                                        handleSetNameSeatch(e.target.value)
                                    }}
                                />
                                <GlassIcon />
                            </div>
                        </div>
                    </div>
                    <div className="navbar-mob mb-5">
                        {eventList.result_from ? <p>{`Menampilkan ${eventList.result_from} - ${eventList.result_to} Event`}</p> : <p>{`Tidak ada Event yang ditemukan`}</p> }
                        <div className="d-flex flex-row align-items-center mt-5 all-course__search" style={{ marginBottom: '20px' }}>
                            <input className="search__input mr-3" placeholder="Cari event" style={{ margin: 0, border: 0 }}
                                onChange={(e) => {
                                    handleSetNameSeatch(e.target.value)
                                }}
                            />
                            <GlassIcon />
                        </div>
                    </div>
                    </>
                ) : ''}
                <div className="row">
                    {eventList !== null ?
                        eventList.data.map(el => (
                            <div className="col-xl-3 col-lg-4 col-xs-12 col-sm-6 col-md-4 mb-5 mt-2 d-flex justify-content-center" style={{ padding: '0 5px' }}>
                                <EventCard data={el} key={el.id} />
                            </div>
                        ))
                        : ''}
                    {eventList !== null ? (
                        <div className='d-flex justify-content-center align-items-center col-12 pagination-wrapper'>
                        <p className="mr-4 navbar-desc">Menampilkan Halaman</p>
                        <div className='awal-akhir-btn mr-2' onClick={(e) => setPageSelected(0)}>Awal</div>
                        <ReactPaginate
                        previousLabel={
                            <div className="pagi-li">
                                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.677344 5.777C0.677344 5.95703 0.751476 6.12648 0.878559 6.25356L5.623 10.998C5.75009 11.1251 5.91953 11.1992 6.09957 11.1992C6.47023 11.1992 6.77734 10.8921 6.77734 10.5214L6.77734 1.03255C6.77734 0.661893 6.47023 0.354774 6.09957 0.354774C5.91953 0.354774 5.75009 0.428906 5.623 0.55599L0.878559 5.30043C0.751476 5.42752 0.677344 5.59696 0.677344 5.777Z" fill="#FF0F51"/>
                                </svg>    
                            </div>
                        
                        }
                        nextLabel={
                            <div className="pagi-li">
                                <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1 5.42222C6.1 5.24219 6.02587 5.07274 5.89878 4.94566L1.15434 0.201215C1.02726 0.0741319 0.857813 0 0.677778 0C0.307118 0 0 0.307118 0 0.677778V10.1667C0 10.5373 0.307118 10.8444 0.677778 10.8444C0.857813 10.8444 1.02726 10.7703 1.15434 10.6432L5.89878 5.89878C6.02587 5.7717 6.1 5.60226 6.1 5.42222Z" fill="#FF0F51"/>
                                </svg>
                            </div>
                        }
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={eventList.total_page}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        // onPageChange={this.handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'pageactive'}
                        pageClassName={'pageclass'}
                        initialPage={pageSelected}
                        forcePage={pageSelected}
                        onPageChange={(data) => 
                            setPageSelected(data.selected)
                        }
                        />
                        <div className='awal-akhir-btn ml-2' onClick={(e) => setPageSelected(eventList.total_page - 1)}>Akhir</div>
                    </div>
                    ) : ''}
                </div>
                </div>
            </div>
        </>
    )
}

export default EventListing