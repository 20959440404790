import React from 'react';

//Assets
import { CourseExIconBlue, CourseHostedIconBlue, CourseLiveIconBlue } from '../../../../static';

const TopData = ({ course }) => {
  const type = {
    external: (
      <>
        <CourseExIconBlue />
        <p className="course-card__type ml-2">External</p>
      </>
    ),
    live: (
      <>
        <CourseLiveIconBlue /> <p className="course-card__type ml-2">Live</p>
      </>
    ),
    hosted: (
      <>
        <CourseHostedIconBlue />
        <p className="course-card__type ml-2">Self-Paced</p>
        {course?.progress_indicator == '100.00%' &&
        course?.is_qna_required &&
        course?.is_qna_enabled ? (
          course?.course_qna_attend ? (
            ''
          ) : (
            <div className="ml-auto course-card__qna__status">
              <p>Selesaikan live QnA</p>
            </div>
          )
        ) : (
          ''
        )}
      </>
    ),
  };
  return (
    <>
      <div className="d-flex align-items-center">{type[course?.type]}</div>
      <h2 className="course-card__course-name">{course?.name}</h2>
      <h3 className="course-card__instructor-name">{course?.instructor?.fullname}</h3>
    </>
  );
};

export default TopData;
