import axios from 'axios';
import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { editProfile } from '../../Utils/Utils';
import Loading from '../Loading';
import EditModal from './EditModal';

const EditProfile = () => {
  const userProfile = useSelector((state) => state.profile_data);
  const [modalopen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [srcCropVersion, setSrcCropVersion] = useState(null);
  const [imageRef, setImageRef] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [returnError, setReturnError] = useState([]);
  const [imageBlob, setImageBlob] = useState('');
  const [crop, setCrop] = useState({
    unit: '%',
    width: 100,
    aspect: 1 / 1,
    x: 0,
    y: 0,
  });

  const dispatch = useDispatch();
  const triggerEditProfile = () => {
    dispatch({ type: 'TOGGLE_LOADING_TRUE' });
    editProfile(
      userProfile.first_name,
      userProfile.last_name,
      userProfile.phone_number,
      userProfile.email,
    )
      .then((_) => {
        setModalContent('doneEdit');
      })
      .catch((error) => {
        console.log('error');
      })
      .finally((_) => dispatch({ type: 'TOGGLE_LOADING_FALSE' }));
  };

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(imageRef, crop, 'newFile.jpeg');
      setCroppedImageUrl(croppedImageUrl);
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      let fileUrl = null;
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        blob.name = 'profile-image-' + fileName;
        window.URL.revokeObjectURL(fileUrl);
        fileUrl = window.URL.createObjectURL(blob);
        resolve(fileUrl);
        setImageBlob(blob);
      }, 'image/jpeg');
    });
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setSrcCropVersion(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoaded = (image) => {
    setImageRef(image);
  };

  const onCropChange = (crop, percentCrop) => {
    setCrop(crop);
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const cropAndUploadPicture = () => {
    const access_token = localStorage.getItem('access_token');
    const data = new FormData();
    data.append('image', imageBlob);
    dispatch({ type: 'TOGGLE_LOADING_TRUE' });

    axios
      .post(window.$API_URL + 'api/user/addprofileimage', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((success) => {
        dispatch({ type: 'TOGGLE_LOADING_FALSE' });
        if (success.data.success) {
          NotificationManager.success('Profile Image Updated Successfully', 'Success');
          setTimeout(() => (window.location.href = '/student/editprofile'), 1000);
        }
      })
      .catch((error) => {
        dispatch({ type: 'TOGGLE_LOADING_FALSE' });
        if (!error.response) {
          NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
          return false;
        }
        if (error.response.status === 422) {
          setReturnError([]);
          let errorTemp = [];
          Object.keys(error.response.data.errors).forEach((key) => {
            errorTemp.push(error.response.data.errors[key][0]);
          });
          setReturnError(errorTemp);
          // setState({ returnError: state.returnError, showing: state.showing });
          const errorList2 = returnError.map((char, i) => {
            return (
              <li key={i} style={{ listStyleType: 'none' }}>
                {char}
              </li>
            );
          });
          NotificationManager.error(errorList2, 'Validasi Error!');
        }
        if (error.response.status === 401) {
          NotificationManager.error(error.response.data.message, 'Validasi Error!');
        } else {
          NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
        }
      });
  };

  if (!userProfile) return <Loading />;

  return (
    <>
      <div className="navbar-desc">
        <div className="d-flex">
          <div className="d-flex flex-column align-items-center edit-profile__container">
            <img
              src={
                userProfile.photo
                  ? userProfile.photo.url
                  : `https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png`
              }
              alt="user-profile-pic"
              className="edit-profile__picture mb-4"
            ></img>
            <label
              htmlFor="image1"
              className="btn btn-pink w-100 edit-profile__btn-pink mb-4 pointer navbar-desc"
              style={{ width: '90px' }}
            >
              Ganti Foto Profil
            </label>
            <svg
              width="250"
              height="1"
              viewBox="0 0 250 1"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line y1="0.5" x2="250" y2="0.5" stroke="#DDDDDD" />
            </svg>
            <p className="edit-profile__pass-desc mt-4">
              Demi keamanan akun, Anda dapat melakukan perubahan kata sandi secara berkala.
            </p>
            {/* <div className="btn btn-white edit-profile__btn-white mt-3 w-100">Ubah Kata Sandi</div> */}
            <div className="btn btn-white edit-profile__btn-white-disabled mt-3 w-100">
              Ubah Kata Sandi
            </div>
          </div>

          <div className="edit_profile_form">
            <input
              type="file"
              name="image1"
              id="image1"
              accept=".gif, .jpg, .png"
              onChange={onSelectFile}
              style={{ display: 'none' }}
            />
            <div className="heading d-flex align-items-center flex-column mb-4">
              <h3 className="mr-auto sign_up_form__title" style={{ fontSize: '18px' }}>
                Ubah Data Diri
              </h3>
            </div>
            <form action="#">
              <div className="form-group">
                <h4 className="edit-profile__form-label ml-2">Email</h4>
                <input
                  readOnly
                  type="email"
                  className="form-control edit-profile__form"
                  placeholder="email@email.com"
                  value={userProfile.email}
                  onChange={(e) =>
                    dispatch({
                      type: 'EDIT_PROFILE',
                      payload: { ...userProfile, email: e.target.value },
                    })
                  }
                />
              </div>

              <div className="form-group" style={{ marginBottom: '20px' }}>
                <h4 className="edit-profile__form-label ml-2">Nama Depan*</h4>
                <input
                  type="text"
                  className="form-control edit-profile__form"
                  placeholder="Nama Depan Sesuai KTP"
                  style={{ marginBottom: 0 }}
                  value={userProfile.first_name}
                  onChange={(e) =>
                    dispatch({
                      type: 'EDIT_PROFILE',
                      payload: { ...userProfile, first_name: e.target.value },
                    })
                  }
                />
              </div>
              <div className="form-group" style={{ marginBottom: 0 }}>
                <h4 className="edit-profile__form-label ml-2">Nama Belakang*</h4>
                <input
                  type="text"
                  className="form-control edit-profile__form"
                  placeholder="Nama Depan Sesuai KTP"
                  style={{ marginBottom: 0 }}
                  value={userProfile.last_name}
                  onChange={(e) =>
                    dispatch({
                      type: 'EDIT_PROFILE',
                      payload: { ...userProfile, last_name: e.target.value },
                    })
                  }
                />
              </div>
              <p
                className="edit-profile__pass-desc ml-2"
                style={{ marginBottom: '20px', color: '#828282' }}
              >
                *Nama akan tertera di sertifikat
              </p>

              <div className="form-group">
                <h4 className="edit-profile__form-label ml-2">Nomor Telepon</h4>
                <input
                  type="text"
                  className="form-control edit-profile__form"
                  placeholder="081234567890"
                  style={{ marginBottom: '30px' }}
                  value={userProfile.phone_number}
                  onChange={(e) =>
                    dispatch({
                      type: 'EDIT_PROFILE',
                      payload: { ...userProfile, phone_number: e.target.value },
                    })
                  }
                />
              </div>

              <button
                type="button"
                onClick={(e) => {
                  setModalContent('editWarning');
                  setModalOpen(true);
                }}
                className="btn btn-block masuk-btn mb-3 edit-profile__btn-pink"
              >
                Simpan
              </button>
            </form>
          </div>
        </div>
        <div
          className={`modal login_signup_modal bcmtechar ${srcCropVersion != null ? 'show' : ''}`}
          style={{ display: `${srcCropVersion != null ? 'block' : 'none'}` }}
          id="loginmodal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="" role="document">
            <div
              className="modal-content"
              style={{ width: '80%', marginLeft: 'auto', marginTop: '30px', marginRight: 'auto' }}
            >
              <div className="modal-body fixhight">
                <div className="row">
                  <div className="col-md-6">
                    {srcCropVersion && (
                      <ReactCrop
                        src={srcCropVersion}
                        crop={crop}
                        ruleOfThirds
                        onImageLoaded={onImageLoaded}
                        onComplete={onCropComplete}
                        onChange={onCropChange}
                      />
                    )}
                    <button
                      className="btn btn-primary  btn btn-success grnsuccess small"
                      onClick={(e) => {
                        setSrcCropVersion(null);
                        setCroppedImageUrl(null);
                      }}
                      style={{ backgroundColor: '#FF0F51' }}
                    >
                      Exit
                    </button>
                  </div>

                  <div className="col-md-6">
                    {croppedImageUrl && (
                      <div>
                        <img alt="Crop" style={{ maxWidth: '45%' }} src={croppedImageUrl} />
                        <button
                          className="btn btn-primary  btn btn-success grnsuccess small"
                          onClick={cropAndUploadPicture}
                          style={{ backgroundColor: '#FF0F51' }}
                        >
                          Crop & Upload
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <EditModal
          modalOpen={modalopen}
          setModalContent={setModalContent}
          modalContent={modalContent}
          closeModal={(e) => setModalOpen(false)}
          triggerEditProfile={triggerEditProfile}
        />
      </div>
      <div className="navbar-mob">
        <div className="d-flex flex-column align-items-center">
          {userProfile !== null ? (
            <img
              src={
                userProfile.photo
                  ? userProfile.photo.url
                  : `https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png`
              }
              alt="user-profile-pic"
              className="edit-profile__picture mb-4"
            ></img>
          ) : (
            ''
          )}
          <div className="btn btn-white edit-profile__btn-white-disabled mt-3 w-50">
            Ganti Foto Profil
          </div>
          <small>Hanya untuk desktop untuk sementara.</small>
        </div>
        <Link
          to="/student/ubahdatadiri"
          style={{
            marginTop: '40px',
            backgroundColor: 'white',
            width: '100vw',
            marginLeft: '-8px',
            padding: '20px',
          }}
        >
          Ubah Data Diri
        </Link>
      </div>
    </>
  );
};

export default EditProfile;
