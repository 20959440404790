import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer';
import { connect } from 'react-redux';
import { removeScript  } from '../Utils/Utils';
import Route from '../components/LandingComponent/Route'
import LoadingSpinner from '../Layout/LoadingSpinner';
import InstructorListingComponent from '../components/AllInstructorList'
import LandingBannerNew from '../components/LandingNew/LandingBannerNew'

class InstructorListing extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }


  componentDidMount = async () => {
  };

  componentWillUnmount() {
    removeScript('https://app.sandbox.midtrans.com/snap/snap.js');
  }

  render() {
    return (
      <>
        <Header/>
        <div className="container container-homepage d-flex flex-column">
          <Route name="Instruktur"/>
          <LandingBannerNew/>
          <InstructorListingComponent />
        </div>
        <Footer />
        {this.props.isLoading ? <LoadingSpinner text={"Mohon menunggu.."} /> : ''}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { isLoading: state.isLoading };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InstructorListing);
