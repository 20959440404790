
import React from 'react';
export default () => (
    <svg className="def">
        <defs>
            <linearGradient id="paint0_linear" x1="14" y1="10.5" x2="10" y2="13.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#D63E8F" />
                <stop offset="0.492735" stopColor="#FE6C44" />
                <stop offset="1" stopColor="#FFC451" />
            </linearGradient>
            <linearGradient id="paint1_linear" x1="16.5" y1="6.99997" x2="7" y2="16" gradientUnits="userSpaceOnUse">
                <stop stopColor="#D23C97" />
                <stop offset="0.5" stopColor="#FE6543" />
                <stop offset="1" stopColor="#FFC050" />
            </linearGradient>
            <linearGradient id="paint2_linear" x1="20.5" y1="3.5" x2="1.5" y2="18" gradientUnits="userSpaceOnUse">
                <stop stopColor="#D03B9B" />
                <stop offset="0.46875" stopColor="#FE5440" />
                <stop offset="1" stopColor="#FFCC52" />
            </linearGradient>
        </defs>
    </svg>
)