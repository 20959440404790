import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import axios from 'axios'
import {  NotificationManager } from 'react-notifications';
import ReactPaginate from 'react-paginate'
import { DebounceInput } from 'react-debounce-input';
import ContentLoader from "react-content-loader"
import  { useMediaQuery } from 'react-responsive'

const GlassIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
            className="ml-auto mr-3">
            <path d="M6.5 0C8.22391 0 9.87721 0.684819 11.0962 1.90381C12.3152 3.12279 13 4.77609 13 6.5C13 8.11 12.41 9.59 11.44 10.73L11.71 11H12.5L17.5 16L16 17.5L11 12.5V11.71L10.73 11.44C9.59 12.41 8.11 13 6.5 13C4.77609 13 3.12279 12.3152 1.90381 11.0962C0.684819 9.87721 0 8.22391 0 6.5C0 4.77609 0.684819 3.12279 1.90381 1.90381C3.12279 0.684819 4.77609 0 6.5 0ZM6.5 2C4 2 2 4 2 6.5C2 9 4 11 6.5 11C9 11 11 9 11 6.5C11 4 9 2 6.5 2Z" fill="black" />
        </svg>
    )
}

const Instructor = ({ instructor }) => {
    return (
        <>
            <div className="col-md-12 col-lg-3 col-sm-12" style={{ padding: 0 }}>
                <a className="instructor-list pointer"
                    href={`/instructors/${instructor.id}/${instructor.fullname}`}>
                    {instructor.photo !== null ? (
                        <img src={instructor.photo.url} alt="instructor-img" className="instructor-list__img navbar-mob" />
                    ) : <div style={{ width: '74px' }}></div>}
                    <p className="all-instructor__text">{instructor.fullname}</p>
                    {instructor.photo !== null ? (
                        <img src={instructor.photo.url} alt="instructor-img" className="instructor-list__img navbar-desc" />
                    ) : ''}
                </a>
            </div>
        </>
    )
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const InstructorListing = () => {
    const instructorList = useSelector(state => state.instructor_list)
    const isMobile = useMediaQuery({ query: '(max-width: 992px)' })
    const dispatch = useDispatch()
    const [nameSearch, setNameSearch] = useState('')
    // console.log(instructorList)
    const history = useHistory()
    const pageQuery = useQuery().get("page")
    const page = pageQuery ? +(pageQuery) - 1 : 0
    const [pageSelected, setPageSelected] = useState(page)
    const [loading, setLoading] = useState(false)

    const handleNameSearch = (name) => {
        setNameSearch(name)
        setPageSelected(0)
    }

    const fetchInstructors = () => {
        const access_token = localStorage.getItem('access_token');
        setLoading(true)
        axios
            .get(
                `${window.$API_URL}api/instructor/?page=${pageSelected + 1}&search_term=${nameSearch}`,
                {
                    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
                },
            )
            .then((success) => {
                dispatch({
                    type: 'UPDATE_INSTRUCTOR_LIST',
                    payload: {
                        data: success.data.data,
                        result_from: success.data.from,
                        result_to: success.data.to,
                        total_page: success.data.last_page,
                        next_page_url: success.data.next_page_url,
                        prev_page_url: success.data.prev_page_url,
                        current_page: success.data.current_page
                    },
                })
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 422) {
                        let _returnError = [];
                        Object.keys(error.response.data.errors).forEach((key) => {
                            _returnError.push(error.response.data.errors[key][0]);
                        });
                        let _showing = true;
                        this.setState({ returnError: _returnError, showing: _showing });
                        const errorList2 = this.state.returnError.map((char, i) => {
                            return (
                                <li key={i} style={{ listStyleType: 'none' }}>
                                    {char}
                                </li>
                            );
                        });
                        NotificationManager.error(errorList2, 'Validasi Error!');
                    } else {
                        NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
                    }
                } else {
                    NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
                }
            }).finally(_ => setLoading(false))
    }

    useEffect(() => {
        fetchInstructors()
    }, [pageSelected, nameSearch]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        history.push({ search: `?page=${pageSelected + 1}` })
    }, [pageSelected]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="all-instructor">
                <div>
                    <h1 className="all-instructor__title">Semua Instruktur</h1>

                    {instructorList !== null ? (<>
                        <div className="all-instructor__search-instructor-margin all-course__search" style={{ marginBottom: '20px' }}>
                            <DebounceInput
                                minLength={2}
                                debounceTimeout={600}
                                className="form-control search__input mr-3"
                                placeholder="Cari instruktur" style={{ margin: 0, border: 0 }}
                                value={nameSearch}
                                onChange={(e) => {
                                    handleNameSearch(e.target.value)
                                }}
                            />
                            <GlassIcon />
                        </div>
                        <div className="row" style={{ padding: '0 15px' }}>
                            {!loading && instructorList ? (
                                instructorList.data.map(el => <Instructor instructor={el} />)
                            ) :  
                                <div className="row">
                                    {[0,1,2,3,4,5,6,7,8].map(() => <div className="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-12 mh-100 all-course__class-list-wrapper">
                                        <ContentLoader 
                                            speed={2}
                                            width={'100%'}
                                            height={'100%'}
                                            viewBox={isMobile ? "0 0 280 80" : "0 0 280 280"}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#ecebeb"
                                        >
                                            <rect x="0" y="0" rx="2" ry="2" width="100%" height='100%' />
                                        </ContentLoader>
                                    </div>)}
                                </div>
                            }
                        </div>
                        {instructorList !== null ? (
                            <div className='d-flex justify-content-center align-items-center col-12 pagination-wrapper'>
                                <p className="mr-4 navbar-desc">Menampilkan Halaman</p>
                                <div className='awal-akhir-btn mr-2' onClick={(e) => setPageSelected(0)}>Awal</div>
                                <ReactPaginate
                                previousLabel={
                                    <div className="pagi-li">
                                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.677344 5.777C0.677344 5.95703 0.751476 6.12648 0.878559 6.25356L5.623 10.998C5.75009 11.1251 5.91953 11.1992 6.09957 11.1992C6.47023 11.1992 6.77734 10.8921 6.77734 10.5214L6.77734 1.03255C6.77734 0.661893 6.47023 0.354774 6.09957 0.354774C5.91953 0.354774 5.75009 0.428906 5.623 0.55599L0.878559 5.30043C0.751476 5.42752 0.677344 5.59696 0.677344 5.777Z" fill="#FF0F51"/>
                                        </svg>    
                                    </div>
                                
                                }
                                nextLabel={
                                    <div className="pagi-li">
                                        <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1 5.42222C6.1 5.24219 6.02587 5.07274 5.89878 4.94566L1.15434 0.201215C1.02726 0.0741319 0.857813 0 0.677778 0C0.307118 0 0 0.307118 0 0.677778V10.1667C0 10.5373 0.307118 10.8444 0.677778 10.8444C0.857813 10.8444 1.02726 10.7703 1.15434 10.6432L5.89878 5.89878C6.02587 5.7717 6.1 5.60226 6.1 5.42222Z" fill="#FF0F51"/>
                                        </svg>
                                    </div>
                                }
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={instructorList.total_page}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                // onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'pageactive'}
                                pageClassName={'pageclass'}
                                initialPage={pageSelected}
                                forcePage={pageSelected}
                                onPageChange={(data) => 
                                    setPageSelected(data.selected)
                                }
                                />
                                <div className='awal-akhir-btn ml-2' onClick={(e) => setPageSelected(instructorList.total_page - 1)}>Akhir</div>
                            </div>
                        ) : ''}
                    </>) : ''}
                </div>
            </div>
        </>
    )
}

export default InstructorListing