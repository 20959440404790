import React from 'react';
import { connect, useSelector } from 'react-redux'
import NumberFormat from 'react-number-format';
import MediaQuery, { useMediaQuery } from 'react-responsive'

const Price = ({mobile}) => {
    const course_detail = useSelector(state => state.course_detail)
    const isDesktop = useMediaQuery({ query: '(min-device-width: 992px)' })
    return (
        (course_detail !== null)
            ?
            course_detail.retail_price != 0.00 ?
                isDesktop &&  !mobile ? <div className="price-new">
                    <h1 className="price-new__title">
                        <p className = "mr-2">Rp</p>
                        <NumberFormat
                            value={(course_detail.discount_price === 0.00) ? course_detail.retail_price : course_detail.discount_price}
                            displayType={'text'}
                            thousandSeparator="."
                            isNumericStrin={true}
                            decimalSeparator={""}
                            thousandsGroupStyle="thousand"
                            className = "harga-format"
                        />
                    </h1>
                    {
                        (course_detail.discount_price !== 0.00)
                            ?
                            <div className="price-new__wrap">
                                <p className="price-new__diskon">{`diskon ${Math.round((course_detail.retail_price - course_detail.discount_price) *100.0 / course_detail.retail_price)}%`}</p>
                                <p className="price-new__old">Rp
                                <NumberFormat
                                        value={course_detail.retail_price}
                                        displayType={'text'}
                                        thousandSeparator="."
                                        isNumericStrin={true}
                                        decimalSeparator={""}
                                        thousandsGroupStyle="thousand"
                                        className = "harga-format-disc"
                                /></p>
                            </div> 
                            :
                            ''
                    }

                </div> :
                <div className="price-new-mobile">
                    <div>
                        <h1 className="price-new-mobile__title">
                            <p>Rp</p>
                            <NumberFormat
                                value={(course_detail.discount_price === 0.00) ? course_detail.retail_price : course_detail.discount_price}
                                displayType={'text'}
                                thousandSeparator="."
                                isNumericStrin={true}
                                decimalSeparator={""}
                                thousandsGroupStyle="thousand"
                                className = "harga-format"
                            />
                        </h1>
                        {
                            (course_detail.discount_price !== 0.00)
                                ?
                                <div className="price-new-mobile__wrap">
                                    <p className="price-new-mobile__diskon">{`diskon ${Math.round((course_detail.retail_price - course_detail.discount_price) *100.0 / course_detail.retail_price)}%`}</p>
                                </div> 
                            :
                        ''
                    }
                    </div>
                    {course_detail.discount_price !== 0.00 ?
                        <p className="price-new-mobile__old">Rp
                        <NumberFormat
                                value={course_detail.retail_price}
                                displayType={'text'}
                                thousandSeparator="."
                                isNumericStrin={true}
                                decimalSeparator={""}
                                thousandsGroupStyle="thousand"
                                className = "harga-format-disc"
                        /></p>
                    : ''
                    }
                </div>
                : isDesktop && !mobile  ? <h1 className="price-new__gratis d-flex">Gratis</h1> : <h1 className="price-new-mobile__gratis d-flex">Gratis</h1>
            :
            ''
    )
}

export default Price;