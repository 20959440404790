import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useDispatch } from 'react-redux';
var fileDownload = require('js-file-download');
const ExamFinishPretest = ({ result, course_detail }) => {
    const [correct, setCorrect] = useState(0);
    const [wrong, setWrong] = useState(0);
    const [score, setScore] = useState(0);
    const [loading, setLoading] = useState(false)
    const [showing, setShowing] = useState(true)
    const [returnError, setReturnError] = useState([])
    const dispatch = useDispatch()
   
    useEffect(() => {
        if (result !== null) {
            var correctCount = 0;
            var wrongCount = 0;
            result.answers.map(ans => {
                (ans.is_correct) ? correctCount++ : wrongCount++;
            })
            setWrong(wrongCount);
            setCorrect(correctCount);
            setScore(result.score);
        }
    }, [result])
    return (
        (result !== null)
            ?
            <div className="exam-finish d-flex align-items-center justify-content-center"
                style={{ backgroundImage:'url("/images/courses/examFinish.png")'}}
            >
                <div className="exam-finish__modal d-flex flex-column align-items-center">
                    <h1 className="exam-finish__title">{'Selamat, kamu telah lulus menyelesaikan Pre-test!'}</h1>
                    <div className="exam-finish__score_out d-flex">
                        <div className="exam-finish__score_in d-flex flex-column align-items-center vertical-line">
                            <p className="exam-finish__score__detail">
                                Benar
                        </p>
                            <p className="exam-finish__score__benar">
                                {correct}
                            </p>
                        </div>
                        <div className="exam-finish__score_in d-flex flex-column align-items-center vertical-line">
                            <p className="exam-finish__score__detail">
                                Salah
                        </p>
                            <p className="exam-finish__score__salah">
                                {wrong}
                            </p>
                        </div>
                        <div className="exam-finish__score_in d-flex flex-column align-items-center">
                            <p className="exam-finish__score__detail">
                                Nilai
                        </p>
                            <p className="exam-finish__score__nilai">
                                {score}
                            </p>
                        </div>
                    </div>
                    <a className='btn exam-finish__btn mt-5 w-100 btn-pink' onClick={(e) =>
                        {
                            dispatch({
                                type: 'UPDATE_MATERI_OPEN',
                                payload: 0,
                            });
                            dispatch({
                                type: 'UPDATE_EXAM_OR_MATERI',
                                payload: 'materi',
                            })
                        }
                    }>
                        Mulai Materi

                    </a>
                </div>
                <NotificationContainer />
                {loading ? <LoadingSpinner /> : ''}
            </div>
            :
            ''
    )
}

export default ExamFinishPretest;