import axios from 'axios';
import React, { useEffect } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useQuery } from '../Utils/Utils';
import Footer from '../components/Footer';
import Header from '../components/Header/Header';
import { API_URL } from '../constant';

export default function KpjRedeemVerify() {
  const kpj_code = useQuery().get('kpj_code');
  const payment_id = useQuery().get('payment_id');
  const sequence = +useQuery().get('sequence');

  const token = localStorage.getItem('access_token');

  useEffect(() => {
    const verifyCode = async () => {
      try {
        const res = await axios.post(
          `${API_URL}/kjflow/redemption-verify`,
          { kpj_code, sequence, payment_id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        NotificationManager.success('Code verified');
        window.location.href = '/student/course';
      } catch (error) {
        NotificationManager.error(error?.response?.data?.message, 'Validasi Error!');
      }
    };

    verifyCode();
  }, [kpj_code, payment_id, sequence]);

  return (
    <div>
      <Header />
      <main>
        <div style={{ padding: '16px' }}>Verifying redeem code</div>
      </main>
      <Footer />
      <NotificationContainer />
    </div>
  );
}
