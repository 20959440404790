import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { connect, useDispatch, useSelector } from 'react-redux'
import GoogleSignInButton from './GoogleSignInButton'
import FacebookSignInButton from './FacebookSignInButton'
import mixpanel from 'mixpanel-browser';

const LoadingIcon = ({ loading }) => <div style={{ display: loading ? 'block' : 'none' }}><img src="/images/smallLoading.gif" /></div>

const LoginModal = ({ dismissModalSignInPopup, setShowing, isLoginActive, forgotPasswordPopup, setIsLoginActive, setIsRegisterActive }) => {
  const history = useHistory();
  const [loginEmail, setLoginEmail] = useState('')
  const [loginPassword, setLoginPassword] = useState('')
  const [error, setError] = useState('')
  const [returnError, setReturnError] = useState([])
  const [loading, setLoading] = useState(0)
  const voucherCode = useSelector(state => state.voucher_to_be_redeem)
  const dispatch = useDispatch()


  const googleLogin = (response, isError) => {
    if (!isError) {
      setLoading(1)
      axios({
        method: 'POST',
        url: `${window.$API_URL}api/auth/login/google`,
        // headers: { 'Content-Type': 'application/json', 'x-access-token': '' },
        data: {
          email: response.profileObj.email,
          first_name: response.profileObj.givenName,
          last_name: response.profileObj.familyName ? response.profileObj.familyName : response.profileObj.givenName,
          id_token: response.tokenId
        }
      })
        .then((success) => {
          setLoading(0)
          if (success.data.success) {
            dismissModalSignInPopup();
            localStorage.setItem('access_token', success.data.data.access_token);
            localStorage.setItem('token_type', success.data.data.token_type);
            localStorage.setItem('expires_in', success.data.data.expires_in);
            if (voucherCode) {
              localStorage.setItem('voucher_code', voucherCode)
              localStorage.removeItem('voucher_register')
              dispatch({ type: 'STORE_VOUCHER_CODE', payload: null })
            }
            setTimeout(() => window.location.reload(), 500);
            //window.location.reload();
          }
        })
        .catch((error) => {
          setLoading(0)
          if (error.response) {
            if (error.response.status === 422) {
              setReturnError([])
              let errorTemp = []
              Object.keys(error.response.data.errors).forEach((key) => {
                errorTemp.push(error.response.data.errors[key][0]);
              });
              setShowing(true);
              setReturnError(errorTemp)
              // setState({ returnError: state.returnError, showing: state.showing });
              const errorList2 = returnError.map((char, i) => {
                return (
                  <li key={i} style={{ listStyleType: 'none' }}>
                    {char}
                  </li>
                );
              });
              NotificationManager.error(errorList2, 'Validasi Error!');
            }
            if (error.response.status === 401) {
              NotificationManager.error(error.response.data.message, 'Validasi Error!');
            } else {
              NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
            }
          } else {
            NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
          }
        })
    } else {
      //NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
    }

  }
  const gtag_report_conversion = (url) => { var callback = function () { if (typeof (url) != 'undefined') { window.location = url; } }; window.gtag('event', 'conversion', { 'send_to': 'AW-651417204/qL3lCLrlt4ACEPSsz7YC', 'event_callback': callback }); return false; }

  const facebookLogin = (response, isError) => {
    console.log(response);
    if (!isError) {
      setLoading(1)
      axios({
        method: 'POST',
        url: `${window.$API_URL}api/auth/login/facebook`,
        // headers: { 'Content-Type': 'application/json', 'x-access-token': '' },
        data: {
          email: response.email,
          first_name: response.name,
          last_name: "",
          access_token: response.accessToken
        }
      })
        .then((success) => {
          setLoading(0)
          if (success.data.success) {
            dismissModalSignInPopup();
            localStorage.setItem('access_token', success.data.data.access_token);
            localStorage.setItem('token_type', success.data.data.token_type);
            localStorage.setItem('expires_in', success.data.data.expires_in);
            if (voucherCode) {
              localStorage.setItem('voucher_code', voucherCode)
              localStorage.removeItem('voucher_register')
              dispatch({ type: 'STORE_VOUCHER_CODE', payload: null })
            }
            //setTimeout(() => window.location.reload(), 500);
            //window.location.reload();
          }
        })
        .catch((error) => {
          setLoading(0)
          if (error.response) {
            if (error.response.status === 422) {
              let errorTemp = []
              Object.keys(error.response.data.errors).forEach((key) => {
                errorTemp.push(error.response.data.errors[key][0]);
              });
              setShowing(true);
              // setState({ returnError: state.returnError, showing: state.showing });
              const errorList2 = errorTemp.map((char, i) => {
                return (
                  <li key={i} style={{ listStyleType: 'none' }}>
                    {char}
                  </li>
                );
              });
              NotificationManager.error(errorList2, 'Validasi Error!');
            }
            if (error.response.status === 401) {
              NotificationManager.error(error.response.data.message, 'Validasi Error!');
            } else {
              NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
            }
          } else {
            NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
          }
        })
    } else {
      //NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
    }
  }

  const loginUser = (e) => {
    if (loginEmail === '' || loginPassword === '') {
      setError('Masukkan Email dan Password!');
    } else {
      setLoading(1)
      setError('')

      axios
        .post(
          `${window.$API_URL}api/auth/login`,
          {
            email: loginEmail,
            password: loginPassword,
          },
          {
            headers: { 'Content-Type': 'application/json', 'x-access-token': '' },
          },
        )
        .then((success) => {
          setLoading(0)
          if (success.data.success) {
            dismissModalSignInPopup();
            gtag_report_conversion();
            mixpanel.track('LoginUser')
            if (success.data.data.role_id == 2) {
              localStorage.setItem('access_token', success.data.data.access_token);
              localStorage.setItem('token_type', success.data.data.token_type);
              localStorage.setItem('expires_in', success.data.data.expires_in);
              if (voucherCode) {
                localStorage.setItem('voucher_code', voucherCode)
                localStorage.removeItem('voucher_register')
                dispatch({ type: 'STORE_VOUCHER_CODE', payload: null })
              }
              setTimeout(() => window.location.reload(), 500);
              //window.location.href = '/student/profile';
            } else if (success.data.data.role_id == 3) {
              alert('Instructor Login is under Development');
              window.location.href = '/';
            } else {
              localStorage.removeItem('access_token');
              localStorage.removeItem('token_type');
              localStorage.removeItem('expires_in');
              setTimeout(() => window.location.href = '/', 500);
              //window.location.href = '/';
            }
          }
        })
        .catch((error) => {
          setLoading(0)
          if (!error.response) {
            NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
            return false
          }
          if (error.response.status === 422) {
            setReturnError([])
            let errorTemp = []
            Object.keys(error.response.data.errors).forEach((key) => {
              errorTemp.push(error.response.data.errors[key][0]);
            });
            setShowing(true);
            setReturnError(errorTemp)
            // setState({ returnError: state.returnError, showing: state.showing });
            const errorList2 = returnError.map((char, i) => {
              return (
                <li key={i} style={{ listStyleType: 'none' }}>
                  {char}
                </li>
              );
            });
            NotificationManager.error(errorList2, 'Validasi Error!');
          }
          if (error.response.status === 401) {
            let message = error.response.data.message
            if (error.response.data.message == 'Email tidak ada') message = 'Email tidak ditemukkan!'
            if (error.response.data.message == 'password tidak sesuai') message = 'Password tidak sesuai!'
            setError(message)
          } else {
            NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
          }
        });
    }
  }

  return (
    <div className={`tab-pane fade ${isLoginActive ? 'show active' : ''}`}>
      <div className="login_form">
        <div className="heading d-flex align-items-center flex-column mb-1 login_form__header">
          <img src="/images/logo_login.png" className="logo"></img>
          <h3>Masuk</h3>
          <p>Silahkan masukkan akun MyEduSolve Anda</p>
        </div>
        <form action="#">
          <div className="form-group">
            <h4 className="login-label ml-2">Email</h4>
            <input
              type="email"
              className="form-control login_form__form"
              placeholder="email@email.com"
              onChange={(e) => setLoginEmail(e.target.value)}
              required
            />
          </div>
          <h4 className="login-label ml-2">Password</h4>
          <div className="form-group">
            <input
              type="password"
              className="form-control login_form__form"
              placeholder="Kata Sandi"
              onChange={(e) => setLoginPassword(e.target.value)}
              required
            />
            <p>{error}</p>
          </div>
          <div className="form-group form-check">
            <a className="tdu text-thm float-right pointer forgot-pass mb-3" onClick={(e) => forgotPasswordPopup(e)}>
              Lupa Kata Sandi?
            </a>
          </div>
          <button
            type="button"
            className="btn btn-block btn-thm2 masuk-btn mb-1 btn-pink"
            onClick={(e) => {
              e.preventDefault()
              loginUser()
            }}
            type="submit"
            disabled={loading}
          >
            <LoadingIcon loading={loading} />
            <p>Masuk</p>
          </button>
        </form>
        <div className="login_form__googlefbbtn">
          <p>Masuk menggunakan:</p>
          <div>
            <GoogleSignInButton googleLogin={googleLogin} />
            <FacebookSignInButton facebookLogin={facebookLogin} />
          </div>
        </div>

        <div className="login_form__bottom-text">
          <h3>Apakah Anda belum memiliki akun? </h3>
          <div>
            <h3 className="mr-1">Silahkan </h3>
            <a
              className="text-thm pointer"
              onClick={(e) => {
                setIsLoginActive(0)
                setIsRegisterActive(1)
              }}
            >
              <h3 className=" membuat-akun">Membuat Akun</h3>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return { login_popup_open_flag: state.login_popup_open_flag }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(
  mapStateToProps, mapDispatchToProps
)(LoginModal);
