import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'react-notifications/lib/notifications.css';
import Modal from 'react-modal';
import axios from 'axios'
import { trackEventSource } from '../../Utils/EventSourcing'
import {  NotificationManager } from 'react-notifications';


const Star = ({gold}) => <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M31.1509 11.4453L21.3135 9.94266L16.9042 0.550658C16.5749 -0.150675 15.4242 -0.150675 15.0949 0.550658L10.6869 9.94266L0.849532 11.4453C0.0415323 11.5693 -0.281134 12.552 0.285532 13.132L7.4322 20.4573L5.74287 30.8133C5.6082 31.636 6.48687 32.2533 7.21353 31.8493L16.0002 26.9933L24.7869 31.8507C25.5069 32.2507 26.3935 31.6453 26.2575 30.8147L24.5682 20.4587L31.7149 13.1333C32.2815 12.552 31.9575 11.5693 31.1509 11.4453Z" fill={gold ? "#FFC632" : "#CED3D7"}/>
</svg>

const CloseIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="black"/>
</svg>

const LoadingIcon = () => <div><img src="/images/smallLoading.gif"/></div>

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    transform             : 'translate(-50%, -50%)',
    padding: '8px',
    borderRadius: '4px'

  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.55)',
    zIndex: 1090
  }
};

const NotificationDropdownReview = ({data, date}) => {
    const [ratingSelected, setRatingSelected] = useState(4)
    const [ratingHover, setRatingHover] = useState(4)
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    const ratingArr = [0, 1, 2, 3, 4]
    const disukaiArr = ['Penyampaian Materi', 'Resource', 'Video', 'Materi']
    const [disukaiArrSelected, setDisukaiArrSelected] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const closeModal = () => setIsModalOpen(false)
    const dispatch = useDispatch() 
    const profile_data = useSelector(state => state.profile_data)

    const disukaiCheck = (pros) => {
      let found = false
      disukaiArrSelected.map(el => {
        if(el == pros) found = true
      })
      return found
    }
  
    const disukaiSelect = (pros) => {
      let arrTemp = [...disukaiArrSelected]
      if(disukaiCheck(pros)) {
        arrTemp = arrTemp.filter(e => e !== pros)
      } else {
        arrTemp.push(pros)
      }
      setDisukaiArrSelected(arrTemp)
    }

    const sendReview = () => {
      setLoading(true)
      const access_token = localStorage.getItem('access_token');
      let is_like_materi = 0
      let is_like_mentor = 0
      let is_like_video = 0
      let is_like_resource = 0
      disukaiArrSelected.forEach(el => {
        if(el == 'Materi') is_like_materi = 1
        if(el == 'Penyampaian Materi') is_like_mentor = 1
        if(el == 'Video') is_like_video = 1
        if(el == 'Resource') is_like_resource = 1
      })
  
      axios
        .post(
          `${window.$API_URL}api/course/${data.course.id}/rating`,
          {
            rating_value: ratingSelected + 1,
            review_title: title,
            review_content: content,
            is_like_materi,
            is_like_mentor,
            is_like_resource,
            is_like_video
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${access_token}`,
            },
          },
        ).then((success) => {
          setLoading(false)
          NotificationManager.success('Terima kasih! Ulasan untuk kelas ini berhasil dikirim.', 'Ulasan Berhasil Diberikan');
          trackEventSource({
            type : "coursecomplete",
            payload : { 
                course_id: data.course.id, 
                rating:  ratingSelected + 1,
                title: title, 
                content: content
            }
          })
          return axios.get(`${window.$API_URL}api/notification/action/${data.id}`, {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
          })
        }).then(() => {
          let notificationTemp = [...profile_data.notifications]
          notificationTemp.forEach(elNotif => {
            if(elNotif.id == data.id) {
              elNotif.is_action_taken = 1
            }
          })
          dispatch({
            type: 'ADD_PROFILEDATA',
            payload: {...profile_data, notifications: notificationTemp},
          })
          setIsModalOpen(false)
        })
        .catch((error) => {
          setLoading(false)
          if (error.response) {
            if (error.response.status == '422') {
              let errorTemp = []
              Object.keys(error.response.data.errors).forEach((key) => {
                errorTemp.push(error.response.data.errors[key][0]);
              });
              const errorList2 = errorTemp.map((char, i) => {
                return (
                  <li key={i} style={{ listStyleType: 'none' }}>
                    {char}
                  </li>
                );
              });
              NotificationManager.error(errorList2, 'Validasi Error!');
            } else if (error.response.status == '404') {
              NotificationManager.error('Some Error Occured', 'Validasi Error!');
            } else {
              NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
            }
          } else {
            NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
          }
  
        })
    }
  
    return (
      <>
        <div>
          <h2>{data.group.description_id}</h2>
          <p>{data.course.name}</p>
          <div>
            {ratingArr.map(el => (
              <div onClick={() => setRatingSelected(el)}
              onMouseEnter={() => setRatingHover(el)}
              onMouseLeave={() => setRatingHover(ratingSelected)}><Star gold={el <= ratingHover}/></div>
            ))}
          </div>
          <p>Hal yang disukai:</p>
          <div>
            {disukaiArr.map(el => 
              <p className={disukaiCheck(el) ? 'pros-selected' : ''} onClick={() => disukaiSelect(el)}>{el}</p>
            )}
          </div>
          <div>
            <button className="btn btn-pink" onClick={(e) => {
              e.preventDefault()
              setIsModalOpen(true)
            }} disabled={data.is_action_taken}>{data.is_action_taken ? 'Ulasan Terkirim' : 'Tambahkan Ulasan'}</button>
          </div>
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >

          <div className="notification-dropdown__review__modals">
            <div onClick={closeModal}><CloseIcon/></div>
            <h2>Tambah Ulasan</h2>
            <form>
              <input type="text" class="form-control" placeholder="Judul Ulasan (min. 5 char)" value={title} onChange={e => setTitle(e.target.value)}/>
              <textarea placeholder="Tulis ulasan kamu disini ya (min. 10 char)" class="form-control" value={content} onChange={e => setContent(e.target.value)}/>
              <div>
                <button className="btn btn-pink" type="submit" onClick={(e) => {
                  e.preventDefault()
                  sendReview()
                }}
                disabled={title.length < 5 || content.length < 10 || loading}>
                  {loading ? <LoadingIcon/> : ''}
                  {loading ? 'Mengirim Ulasan..' : 'Kirim Ulasan' }
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </>
    )
}

export default NotificationDropdownReview;
