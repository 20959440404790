import React, { useState, useRef, useEffect } from 'react';
import { CloseModals } from '../../static';
import Assignment from './Assignment';
import RatingReview from './RatingReview';
import RatingWarning from './RatingWarning';
import IncompleteQuizAlert from './IncompleteQuizAlert';
import ExamAlreadyPassedAlert from './ExamAlreadyPassedAlert';
import ExamWarning from './ExamWarning';
import CourseFinish from './CourseFinish';
import QuizPerlectureAlert from './QuizPerlectureAlert';
import ConnectionProblem from './ConnectionProblem';
import PretestInfo from './PretestInfo';
import { connect, useDispatch, useStore, useSelector } from 'react-redux';
import Modal from 'react-modal';

const CourseModal = ({
  modalOpened,
  isModalOpen,
  closeModal,
  fill_all_quiz_question,
  quiz_popup_type,
  exam_passed,
}) => {
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  useEffect(() => {
    const dismissModalSignInPopup = () => {
      closeModal();
    };
    const escFunction = (event) => {
      if (isModalOpen && event.keyCode === 27) {
        dismissModalSignInPopup();
      }
    };

    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [isModalOpen, fill_all_quiz_question]);

  useEffect(() => {
    const escFunction = (event) => {
      if (event.keyCode === 27) {
        closeModal();
      }
    };

    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener('keydown', escFunction, false);
    document.addEventListener('mousedown', handleClickOutside, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, [isModalOpen, fill_all_quiz_question, wrapperRef]);

  return (
    <>
      <Modal
        closeTimeoutMS={100}
        isOpen={isModalOpen || fill_all_quiz_question}
        // onRequestClose={closeModal}
        overlayClassName="login-popup-overlay"
        className="login-popup-content"
        // contentLabel="Example Modal"
        preventScroll={true}
      >
        <div
          className="modal-dialog-course modal-dialog-centered modal-size-course"
          role="document"
          ref={wrapperRef}
        >
          <div className="modal-content-course">
            <div
              className="pointer d-flex justify-content-end pt-3 pr-3"
              onClick={() => closeModal()}
            >
              <CloseModals className="ml-auto" />
            </div>

            <div className="tab-content-course" id="myTabContent" style={{ display: 'flex' }}>
              <div className="tab-pane fade show active d-flex" style={{ flex: '1' }}>
                {modalOpened === 'assignment' ? <Assignment closeModal={closeModal} /> : ''}
                {modalOpened === 'ratingReview' ? <RatingReview closeModal={closeModal} /> : ''}
                {modalOpened === 'ratingWarning' ? <RatingWarning closeModal={closeModal} /> : ''}
                {modalOpened === 'examWarning' ? <ExamWarning closeModal={closeModal} /> : ''}
                {modalOpened === 'courseFinish' ? <CourseFinish closeModal={closeModal} /> : ''}
                {modalOpened === 'incomplete_answer' ? (
                  <IncompleteQuizAlert closeModal={closeModal} />
                ) : (
                  ''
                )}
                {modalOpened === 'exam_passed' ? (
                  <ExamAlreadyPassedAlert closeModal={closeModal} />
                ) : (
                  ''
                )}
                {modalOpened === 'quiz_perlecture_warning' ? (
                  <QuizPerlectureAlert closeModal={closeModal} />
                ) : (
                  ''
                )}
                {modalOpened === 'quiz_end_date_false' ? (
                  <QuizPerlectureAlert closeModal={closeModal} text="Pembelajaran belum berakhir" />
                ) : (
                  ''
                )}
                {modalOpened === 'connection_problem' ? (
                  <ConnectionProblem closeModal={closeModal} />
                ) : (
                  ''
                )}
                {modalOpened === 'pretest_info' ? <PretestInfo closeModal={closeModal} /> : ''}

                {modalOpened === 'free_course_warning' ? (
                  <div className="d-flex flex-column justify-content-around" style={{ flex: '1' }}>
                    <h1 className="assignment__title mb-3">
                      Harap selesaikan kelas terlebih dahulu.
                    </h1>
                    <div className="rating__btn align-self-stretch d-flex flex-column">
                      <a
                        className="btn btn-lg btn-pink align-self-stretch"
                        style={{ fontSize: '16px' }}
                        onMouseDown={closeModal}
                      >
                        OK
                      </a>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {/* {
                  (fill_all_quiz_question && quiz_popup_type === 'incomplete_answer') ? <IncompleteQuizAlert closeModal={closeModal} /> : ''

                } */}
                {/* {
                  (exam_passed && quiz_popup_type == 'exam_passed') ? <ExamAlreadyPassedAlert closeModal={closeModal} /> : ''

                } */}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {alert}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    fill_all_quiz_question: state.fill_all_quiz_question,
    quiz_popup_type: state.quiz_popup_type,
    exam_passed: state.exam_passed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseModal);
