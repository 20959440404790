import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import Modal from 'react-modal'

//Components

//Sub-components
import TopData from './subcomponent/TopData'
import CourseImage from './subcomponent/CourseImage'
import { ZoomLink, WALink } from './subcomponent/ZoomWAButton'
import { CloseModals } from '../../../static';
import { CourseLiveIconBlue, CourseHostedIconBlue } from '../../../static'
import axios from 'axios';

import { useDispatch } from 'react-redux'

const CourseListConsultation = ({ course }) => {
    const [modalOpen, SetModalOpen] = useState(false)

    return (
        <>
            <div className="course-card">
                <div className="row">
                    <div className="col-xl-4 col-md-4 col-sm-3 col-5 course-card__mobile-container-img">
                        <CourseImage course={course} />
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-9 col-7 course-card__content course-card__mobile-container-content">
                        <TopData course={course} />
                        {course?.course_consultation_register ? 
                        <div className="course-card__content__consultation-booked">
                            <div>
                                <p>Dilaksanakan Pada Tanggal</p>
                                <h2>{`${moment(course?.course_consultation_register?.consultation?.start_date).format("DD MMMM YYYY")} | ${moment(course?.course_consultation_register?.consultation?.start_date).format("HH.mm")} - ${moment(course?.course_consultation_register?.consultation?.end_date).format("HH.mm")} WIB`}</h2>
                            </div>
                            <div>
                                {course?.course_consultation_register?.consultation?.whatsapp_link && <WALink link={course?.course_consultation_register?.consultation?.whatsapp_link} /> }
                                {course?.course_consultation_register?.consultation?.zoom_link && <ZoomLink link={course?.course_consultation_register?.consultation?.zoom_link} /> }
                            </div>
                        </div> 
                        :
                        <div className="course-card__content__consultation-unbooked">
                            <p>Jika ada yang ingin didiskusikan, pilih jadwal untuk melakukan diskusi dengan instruktur.</p>
                            <button className="btn btn-pink" onClick={() => SetModalOpen(true)}>Pilih Jadwal</button>
                        </div>}
                    </div>
                </div>
            </div>
            {<PilihJadwalModal modalOpen={modalOpen} closeModal={(e) => SetModalOpen(false)} course={course} />} 
        </>
    )
}


const PilihJadwalModal = ({ closeModal, modalOpen, course }) => {
    const dispatch = useDispatch()
    const [scheduleList, setScheduleList] = useState({})
    const [scheduleListData, setScheduleListData] = useState([])
    const [pageSelected, setPageSelected] = useState(0)
    const wrapperRef = useRef(null)

    const getSchedule = () => {
        dispatch({ type: 'TOGGLE_LOADING_TRUE' })
        const access_token = localStorage.getItem('access_token')
        axios({
            method: 'GET',
            url: `${window.$API_URL}api/courseconsultation/${course?.id}?page=${pageSelected + 1}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
            }
        })
        .then(result => {
            setScheduleListData(result.data.data)
            setScheduleList(result.data)
        })
        .finally(_ => dispatch({ type: 'TOGGLE_LOADING_FALSE' })) 
    }

    const bookSchedule = (consultationId) => {
        dispatch({ type: 'TOGGLE_LOADING_TRUE' })
        const access_token = localStorage.getItem('access_token')
        axios({
            method: 'POST',
            url: `${window.$API_URL}api/courseconsultation/bookconsultation`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
            data: {
                "course_id": course?.id,      
                "consultation_id": consultationId
            }
        })
        .then(_ => window.location.reload() )
        .finally(_ => dispatch({ type: 'TOGGLE_LOADING_FALSE' })) 
    }

    useEffect(() => {
        const escFunction = (event) => {
          if (event.keyCode === 27) {
            closeModal()
          }
        }
        const handleClickOutside = (event) => {
          if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            closeModal()
          }
        }
    
        document.addEventListener("keydown", escFunction, false);
        document.addEventListener("mousedown", handleClickOutside, false);
        return () => {
          document.removeEventListener("keydown", escFunction, false);
          document.removeEventListener("mousedown", handleClickOutside, false);
        }
      }, [modalOpen, wrapperRef])

    useEffect(() => {
        if(modalOpen) getSchedule()
    }, [pageSelected, modalOpen]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <Modal
                closeTimeoutMS={100}
                isOpen={modalOpen}
                // onRequestClose={closeModal}
                overlayClassName="login-popup-overlay"
                className="login-popup-content"
                // contentLabel="Example Modal"
            >
                <div className="modal-dialog modal-dialog-centered" role="document" ref={wrapperRef} style={{ width: '952px', maxWidth: '100vw'}}>
                    <div className="modal-content">
                        <div className="pointer d-flex justify-content-end pt-3 pr-3" onClick={(e) => closeModal() }>
                            <CloseModals className="ml-auto" />
                        </div>
                        <div className="pilih-jadwal-content" >
                            <h1 className="content-user__title">{
                                course.type == 'live' ? 'Pilih Jadwal' : ''
                            }</h1>
                            <div className="d-flex">
                                <img src={course.thumbnail ? course.thumbnail.url : ''} alt="course-img" className="pilih-jadwal-content__img" />
                                <div className="d-flex flex-column ml-4">
                                    <div className="d-flex align-items-center">{course.type === "live" ? <div className="d-flex align-items-center"><CourseLiveIconBlue /> <p className="course-card__type ml-2">Live</p></div> : ""}
                                        <div className="d-flex align-items-center">
                                            <CourseHostedIconBlue /><p className="course-card__type ml-2">Self-Paced</p>
                                        </div></div>
                                    <h2 className="course-card__class-name mt-2 ml-0" >{course.name}</h2>
                                    <p className="mt-1" >{course.instructor.fullname}</p>
                                </div>
                            </div>
                            <div className="pilih-jadwal-content__consultation">
                                <div>
                                    <h2>Tanggal</h2>
                                    <h2>Waktu</h2>
                                </div>
                                {scheduleListData.map(el => (
                                    <div>
                                        <p>{moment(el.start_date).format("dddd, DD MMMM YYYY")}</p>
                                        <p>{`${moment(el.start_date).format("HH.mm")} - ${moment(el.end_date).format("HH.mm")}`}</p>
                                        <div><button className="btn btn-pink" onClick={() => bookSchedule(el.id)}>Pilih Jadwal</button></div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <Pagination pageSelected={pageSelected} setPageSelected={setPageSelected} data={scheduleList}/>
                    </div>
                </div>
            </Modal>
        </>
    )
}

const Pagination = ({pageSelected, setPageSelected, data}) => {
    return  (
        data !== null ? (
            data.last_page ?
              <div className='d-flex pagination-wrapper pilih-jadwal-content__consultation__pagination'>
                <p className="mr-4 navbar-desc">Menampilkan Halaman</p>
                <div className='awal-akhir-btn mr-2' onClick={(e) => setPageSelected(0)}>Awal</div>
                <ReactPaginate
                previousLabel={
                    <div className="pagi-li">
                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.677344 5.777C0.677344 5.95703 0.751476 6.12648 0.878559 6.25356L5.623 10.998C5.75009 11.1251 5.91953 11.1992 6.09957 11.1992C6.47023 11.1992 6.77734 10.8921 6.77734 10.5214L6.77734 1.03255C6.77734 0.661893 6.47023 0.354774 6.09957 0.354774C5.91953 0.354774 5.75009 0.428906 5.623 0.55599L0.878559 5.30043C0.751476 5.42752 0.677344 5.59696 0.677344 5.777Z" fill="#FF0F51"/>
                        </svg>    
                    </div>
                
                }
                nextLabel={
                    <div className="pagi-li">
                        <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1 5.42222C6.1 5.24219 6.02587 5.07274 5.89878 4.94566L1.15434 0.201215C1.02726 0.0741319 0.857813 0 0.677778 0C0.307118 0 0 0.307118 0 0.677778V10.1667C0 10.5373 0.307118 10.8444 0.677778 10.8444C0.857813 10.8444 1.02726 10.7703 1.15434 10.6432L5.89878 5.89878C6.02587 5.7717 6.1 5.60226 6.1 5.42222Z" fill="#FF0F51"/>
                        </svg>
                    </div>
                }
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={data.last_page}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1}
                // onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'pageactive'}
                pageClassName={'pageclass'}
                initialPage={pageSelected}
                forcePage={pageSelected}
                onPageChange={(data) => 
                    setPageSelected(data.selected)
                }
                />
                <div className='awal-akhir-btn ml-2' onClick={(e) => setPageSelected(data.last_page - 1)}>Akhir</div>
              </div>
            : '') 
        : ''
    )
}

export default CourseListConsultation