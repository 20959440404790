import React, { useEffect, useState, Component, useRef } from 'react'
import courses from './courses.json'
import categoriesJson from './categories.json'
import { Link, useHistory } from 'react-router-dom'
import NumberFormat from 'react-number-format';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactGA from 'react-ga';
import MediaQuery, { useMediaQuery } from 'react-responsive'
import { useDispatch } from 'react-redux';
import axios from 'axios'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Card from '../CourseCard'
import ContentLoader from "react-content-loader"

const CourseLandingNew = (props) => {
  const dispatch = useDispatch()
  let carouselRef = useRef(null)
  const isDesktop = useMediaQuery({ query: '(min-device-width: 992px)' })
  const [coursesData, setCourseData] = useState(courses.data)
  const [coursesLandingLoading, setCourseLandingLoading] = useState(false)
  const [categorieSelected, setCategorieSelected] = useState({
    "id": 0,
    "category_name": "POPULAR",
    "created_at": null,
    "updated_at": null,
    "slug": "popular"
  }
  )
  const categories = [
    {
      "id": 0,
      "category_name": "POPULAR",
      "created_at": null,
      "updated_at": null,
      "slug": "popular"
    },
    ...categoriesJson.data
  ]
  const { innerWidth: width } = window;
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 4,
      slidesToSlide: 1 // optional, default to 1.
    },
    // tablet: {
    //   breakpoint: { max: 1024, min: 464 },
    //   items: 2,
    //   slidesToSlide: 2 // optional, default to 1.
    // },
    mobile: {
      breakpoint: { max: 991, min: 0 },
      items: width * 1.2 / 320,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const fetchCourseByCategory = (categoryId) => {
    setCourseLandingLoading(true)
    axios.get(`${window.$API_URL}api/common/getcategorycourses/?category_id=${categoryId}`, {},
      {
        headers: { 'Content-Type': 'application/json', 'x-access-token': '' },
      },
    ).then((success) => {
    setCourseLandingLoading(false)
    if (success.data.success) {
        setCourseData(success.data.data)
      }
    }).catch((error) => {
    setCourseLandingLoading(false)
    NotificationManager.error('Some Error Occured in API!');
    });
  }

  useEffect(() => {
    carouselRef.goToSlide(0)
    if (categorieSelected.id == 0) {
      setCourseData(courses.data)
    } else {
      fetchCourseByCategory(categorieSelected.id)
    }
  }, [categorieSelected])

  return (
      <>
      
        <div className={`course-landing-new ${props.notOnLanding ? 'course-landing-new-not-landing' : ''}`}>
          <div>
            <h1>Kelas Rekomendasi MyEduSolveX</h1>
            <p>Pelajari keterampilan baru untuk mendapatkan pekerjaan impian Anda</p>
            <MediaQuery maxDeviceWidth={991}>
              <div>
                <a href="/courses" className="btn btn-white ml-auto">Lihat Semua</a>
              </div>
            </MediaQuery>
          <div>
            {categories.map(categorie => (
              <div className={categorieSelected.slug == categorie.slug ? 'h2-selected' : ''} onClick={() => setCategorieSelected(categorie)}><h2>{categorie.category_name}</h2></div>
            ))}
            <MediaQuery minDeviceWidth={992}>
              <a href="/courses" className="btn btn-white ml-auto">Lihat Semua</a>
            </MediaQuery>
          </div>
          <div>

            <Carousel
              draggable={false}
              responsive={responsive}
              transitionDuration={500}
              containerClass="carousel-container"
              itemClass="carousel-item-padding-40-px"
              deviceType="desktop"
              customButtonGroup={isDesktop ? <ButtonGroup /> : null}
              renderButtonGroupOutside={isDesktop ? true : false}
              arrows={false}
              ref={el => (carouselRef = el)}
            // infinite={true}
            >

              {coursesLandingLoading ? null : coursesData.map(course => <Card data={course} />)}
              {coursesLandingLoading ? [0,1,2,3].map(el => (
                <div className="landing-card-new">
                <ContentLoader 
                    speed={2}
                    width="100%"
                    height="100%"
                    viewBox="0 0 400 471"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...props}
                >
                    <rect x="0" y="0" rx="2" ry="2" width="100%" height="100%" />
                </ContentLoader></div>
              )) : null }

            </Carousel>
          </div>
        </div>
      </div>
    </>
  )
}



const CustomRightArrow = ({ onClick, disable }) => {
  return <div onClick={() => onClick()} className="course-landing-arrow course-landing-arrow--right" style={disable ? { display: 'none' } : {}}>
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dd)">
        <circle cx="34" cy="32" r="30" fill="white" />
      </g>
      <path d="M28.585 39.2675L35.8525 32L28.585 24.7325L30.8333 22.5L40.3333 32L30.8333 41.5L28.585 39.2675Z" fill="black" />
    </svg>
  </div>;
}

const CustomLeftArrow = ({ onClick, disable }) => {
  // onMove means if dragging or swiping in progress.
  return <div onClick={() => onClick()} className="course-landing-arrow course-landing-arrow--left" style={disable ? { display: 'none' } : {}}>
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dd)">
        <circle cx="34" cy="32" r="30" transform="rotate(-180 34 32)" fill="white" />
      </g>
      <path d="M39.415 24.7325L32.1475 32L39.415 39.2675L37.1667 41.5L27.6667 32L37.1667 22.5L39.415 24.7325Z" fill="#20282E" />
    </svg>
  </div>;
}

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const { carouselState: { currentSlide, slidesToShow } } = rest;
  return (
    <div className="carousel-button-group">
      <CustomLeftArrow disable={currentSlide === 0} onClick={() => previous()} />
      <CustomRightArrow disable={currentSlide >= slidesToShow} onClick={() => next()} />
    </div>
  );
};

export default CourseLandingNew;
