import React, { useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import courses from '../LandingNew/courses.json'
import Card from '../CourseCard'
import Carousel from "react-multi-carousel";

function Course() {
    const isMobile = useMediaQuery({ query: '(max-width: 992px)' })
    const isDesktop = useMediaQuery({ query: '(min-device-width: 992px)' })
    const { innerWidth: width } = window;
    let carouselRef = useRef(null)
    const responsive = {
        desktop: {
        breakpoint: { max: 3000, min: 992 },
        items: 3.72,
        slidesToSlide: 2.72 // optional, default to 1.
        },
        mobile: {
        breakpoint: { max: 991, min: 0 },
        items: 2.5,
        slidesToSlide: 1 // optional, default to 1.
        },
        mobileSmall: {
        breakpoint: { max: 448, min: 0 },
        items: 1.2,
        slidesToSlide: 1 // optional, default to 1.
        }
    };
    return (
        <div className="prakerja-course">
            <div className="prakerja-course__top">
                <div>
                    <img src={`/images/prakerja/coursedetail${isMobile ? '-mob' : ''}.png`}/>
                </div>  
                <div>
                    <h2>Belajar Penggunaan Microsoft Excel Untuk Keuangan</h2>    
                    <h3>Manajemen Keuangan Anti Boncos</h3>
                    <p>Banyak bisnis yang rugi karena manajemen keuangan berantakan yang disebabkan oleh pencatatan secara manual, tercampurnya keuangan pribadi dengan bisnis, dan kurangnya analisa keuangan secara berkala.</p>
                    <p>Dengan belajar Microsoft Excel, kamu bisa menghemat waktu, uang, dan energi untuk menjalankan bisnismu dengan efektif dan efisien. </p>
                    <p>Yuk, daftar pelatihan ini untuk membangun keterampilan Excel secara bertahap, dengan studi kasus nyata, yang diajarkan oleh Microsoft Certified Trainer.</p>
                    <div>
                        <div>
                            <CameraIcon/>
                            <p>Durasi 4-5 Jam</p>
                        </div>
                        <div>
                            <TogaIcon/>
                           <p>Materi Praktis yang bisa langsung diaplikasikan</p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h2>Rp 499.000</h2>
                            <div>
                                <div><p>Diskon 50%</p></div>
                                <p>Rp 1.100.000</p>
                            </div>
                        </div>
                        <div>
                            <button className="btn btn-pink" onClick={(e) => {
                                e.preventDefault()
                                window.open("/course/7/belajar-penggunaan-microsoft-excel-untuk-keuangan?from=kpj", "_blank")
                            }}>Belajar Sekarang</button>
                        </div>
                    </div>
                </div>  
            </div>
            <div className="prakerja-course__bottom">
                <h2 className="prakerja-title">Kelas Prakerja Best Seller</h2>
                <div>
                    <Carousel
                        draggable={false}
                        responsive={responsive}
                        transitionDuration={500}
                        containerClass="carousel-container"
                        itemClass="carousel-item-padding-40-px"
                        deviceType="desktop"
                        customButtonGroup={isDesktop ? <ButtonGroup /> : null}
                        renderButtonGroupOutside={isDesktop ? true : false}
                        arrows={false}
                        ref={el => (carouselRef = el)}
                        // infinite={true}
                        >
                            {courses.data.map(course => <Card data={course} />)}
                        </Carousel>
                </div>
            </div>
        </div>
    )
}

const CameraIcon = () => <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.75 2.33333V9.66667H2.58333V2.33333H11.75ZM12.6667 0.5H1.66667C1.42355 0.5 1.19039 0.596577 1.01849 0.768486C0.846577 0.940394 0.75 1.17355 0.75 1.41667V10.5833C0.75 10.8264 0.846577 11.0596 1.01849 11.2315C1.19039 11.4034 1.42355 11.5 1.66667 11.5H12.6667C12.9098 11.5 13.1429 11.4034 13.3148 11.2315C13.4868 11.0596 13.5833 10.8264 13.5833 10.5833V7.375L17.25 11.0417V0.958333L13.5833 4.625V1.41667C13.5833 1.17355 13.4868 0.940394 13.3148 0.768486C13.1429 0.596577 12.9098 0.5 12.6667 0.5Z" fill="#5B7080"/>
</svg>

const TogaIcon = () => <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 0.75L0.916672 6.25L4.58334 8.24833V13.7483L11 17.25L17.4167 13.7483V8.24833L19.25 7.24917V13.5833H21.0833V6.25L11 0.75ZM17.2517 6.25L11 9.66L4.74834 6.25L11 2.84L17.2517 6.25ZM15.5833 12.6667L11 15.16L6.41667 12.6667V9.2475L11 11.75L15.5833 9.2475V12.6667Z" fill="#5B7080"/>
</svg>
const CustomRightArrow = ({ onClick, disable }) => {
    return <div onClick={() => onClick()} className="course-landing-arrow course-landing-arrow--right" style={disable ? { display: 'none' } : {}}>
      <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_dd)">
          <circle cx="34" cy="32" r="30" fill="white" />
        </g>
        <path d="M28.585 39.2675L35.8525 32L28.585 24.7325L30.8333 22.5L40.3333 32L30.8333 41.5L28.585 39.2675Z" fill="black" />
      </svg>
    </div>;
  }
  
  const CustomLeftArrow = ({ onClick, disable }) => {
    // onMove means if dragging or swiping in progress.
    return <div onClick={() => onClick()} className="course-landing-arrow course-landing-arrow--left" style={disable ? { display: 'none' } : {}}>
      <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_dd)">
          <circle cx="34" cy="32" r="30" transform="rotate(-180 34 32)" fill="white" />
        </g>
        <path d="M39.415 24.7325L32.1475 32L39.415 39.2675L37.1667 41.5L27.6667 32L37.1667 22.5L39.415 24.7325Z" fill="#20282E" />
      </svg>
    </div>;
  }
  
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide, slidesToShow } } = rest;
    return (
      <div className="carousel-button-group">
        <CustomLeftArrow disable={currentSlide === 0} onClick={() => previous()} />
        <CustomRightArrow disable={currentSlide >= slidesToShow} onClick={() => next()} />
      </div>
    );
  };

export default Course
