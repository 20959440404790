import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { API_URL } from '../../constant';

const TugasMandiriScreen = () => {
  const history = useHistory();
  const [tugasMandiriList, setTugasMandiriList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTugasMandiri = async () => {
      const token = localStorage.getItem('access_token');

      try {
        dispatch({ type: 'TOGGLE_LOADING_TRUE' });

        let res = await axios.get(`${API_URL}/kjflow/mytugasmandiri`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        res = res?.data?.data;

        setTugasMandiriList(res);
      } catch (error) {
        return error;
      } finally {
        dispatch({ type: 'TOGGLE_LOADING_FALSE' });
      }
    };

    fetchTugasMandiri();
  }, [dispatch]);

  return (
    <>
      <div className="dashboard-container">
        <h1 className="content-user__title mt-4 navbar-desc">Tugas Mandiri</h1>

        <div style={{ marginTop: '1rem' }}>
          {tugasMandiriList.length === 0 && <div className="">Belum ada tugas mandiri</div>}

          {tugasMandiriList.length > 0 && (
            <ul className="journal_list">
              {tugasMandiriList.map((el, i) => {
                return (
                  <li className="journal_list_item" key={i}>
                    <div className="">
                      <img src={el?.lesson?.course?.thumbnail?.url} alt="" />
                    </div>
                    <div className="">
                      <div
                        className=""
                        style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
                      >
                        <h3
                          className=""
                          style={{ fontWeight: '600', fontSize: '18px', marginBottom: '0' }}
                        >
                          {el?.lesson?.name ?? 'Tugas Mandiri'}
                        </h3>

                        <div className="">
                          {el?.score && (
                            <p className="" style={{ fontWeight: '400', fontSize: '14px' }}>
                              <b>Score:</b> {el?.score}
                            </p>
                          )}

                          {el?.feedback && (
                            <p className="" style={{ fontWeight: '400', fontSize: '14px' }}>
                              <b>Feedback:</b> {el?.feedback}
                            </p>
                          )}
                        </div>
                      </div>
                      <button
                        className="btn btn-pink btn-pink-hover mt-2"
                        onClick={() => history.push(`/student/tugas-mandiri/${el?.id}`)}
                      >
                        {el?.is_submitted === 1
                          ? 'Lihat tugas yang dikirim'
                          : 'Kerjakan Tugas Mandiri'}
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default TugasMandiriScreen;
