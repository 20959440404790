import React from 'react';
import { connect, useSelector } from 'react-redux'
import MediaQuery, { useMediaQuery } from 'react-responsive'

const Banner = ({}) => {
    const banner_detail = useSelector(state => state.banner_detail)
    const thumbnail = useSelector(state => state.course_detail ? `${(state.course_detail.thumbnail !== null) ? state.course_detail.thumbnail.url : ''}` : '')
    const course_detail = useSelector(state => state.course_detail)
    const isDesktop = useMediaQuery({ query: '(min-device-width: 992px)' })
    const categories = [{
        "id": 1,
        "category_name": "Self Development",
        "created_at": null,
        "updated_at": null,
        "slug": "courses-self-development"
    },
    {
        "id": 2,
        "category_name": "Tech",
        "created_at": null,
        "updated_at": null,
        "slug": "courses-tech"
    },
    {
        "id": 3,
        "category_name": "Creative",
        "created_at": null,
        "updated_at": null,
        "slug": "courses-creative"
    },
    {
        "id": 4,
        "category_name": "Business",
        "created_at": null,
        "updated_at": null,
        "slug": "courses-business"
    },
    {
        "id": 5,
        "category_name": "Entrepreneurship",
        "created_at": null,
        "updated_at": null,
        "slug": "courses-entrepreneurship"
    }]

    const types = {
        hosted: 'Self-Paced',
        live: 'Live',
        external: 'Eksternal'
    }
    return (
        // <section className="banner" style={{ backgroundImage: `url("${(course_banner_url && course_banner_url !== 'Select Banner Type-Url') ? course_banner_url : '/images/background/landingBg_old.png'}")` }}>
        !course_detail ? '' :
        <div className="banner-new">
            {isDesktop ? 
            <div>
                <p>{categories[course_detail.category_id - 1].category_name}</p>
            </div> : ''}
            <img src={thumbnail} />
            {!isDesktop ? 
            <div>
                <p>{categories[course_detail.category_id - 1].category_name}</p>
                <p>{types[course_detail.type]}</p>
            </div> : ''}
        </div>
    )
}
export default (Banner);
