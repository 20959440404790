import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { NotificationManager } from 'react-notifications';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Instagram, Facebook, Twitter, Youtube, Whatsapp, List, File, LinkedIn } from '../../static';
import { LinkedinIcon } from 'react-share';
import { Link } from 'react-router-dom';
import Slider from '../Slider'
import Card from '../CourseCard'





const InstructorListing = () => {
  const { instructor_id } = useParams()
  const dispatch = useDispatch()
  const [instructor_selected, setInstructor_selected] = useState(null)

  const socialLinks = [
    {
      href: instructor_selected ? (instructor_selected.twitter_url ? instructor_selected.twitter_url : null) : null,
      title: <Twitter size={32} />,
      id: '69'
    },
    {
      href: instructor_selected ? (instructor_selected.facebook_url ? instructor_selected.facebook_url : null) : null,
      title: <Facebook size={32} />,
      id: '68'
    },
    {
      href: instructor_selected ? (instructor_selected.instagram_url ? instructor_selected.instagram_url : null) : null,
      title: <Instagram size={32} />,
      id: '65'
    },
    {
      href: instructor_selected ? (instructor_selected.linkedin_url ? instructor_selected.linkedin_url : null) : null,
      title: <LinkedIn size={32} />,
      id: '66'
    }
  ]

  const fetchInstructorsDetail = () => {
    dispatch({ type: 'TOGGLE_LOADING_TRUE' })
    const access_token = localStorage.getItem('access_token');
    console.log('shooting api')
    axios
      .get(
        `${window.$API_URL}api/instructor/${instructor_id}`,
        {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
        },
      )
      .then((success) => {
        setInstructor_selected(success.data.data)
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == '422') {
              this.state.returnError = [];
              Object.keys(error.response.data.errors).forEach((key) => {
                  this.state.returnError.push(error.response.data.errors[key][0]);
              });
              this.state.showing = true;
              this.setState({ returnError: this.state.returnError, showing: this.state.showing });
              const errorList2 = this.state.returnError.map((char, i) => {
                  return (
                      <li key={i} style={{ listStyleType: 'none' }}>
                          {char}
                      </li>
                    );
                });
                NotificationManager.error(errorList2, 'Validasi Error!');
            } else {
                NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
            }
        } else {
            NotificationManager.error('Some Error Occured in API!', 'Validasi Error!');
        }
      }).finally(_ => dispatch({ type: 'TOGGLE_LOADING_FALSE' }))
    }

  useEffect(() => {
    fetchInstructorsDetail()
  }, [])
  console.log(instructor_selected)

  return (
    <>
      {instructor_selected ? (
        <div className="instructor-detail">
          <div>
          <h1 className="all-instructor__title" style={{ marginBottom: '20px'}}>Detail Instruktur</h1>
          <div className="row">
            <div className="col-md-3 col-sm-12 instructor-detail__img-margin">
              <div className="instructor-detail__img d-flex align-items-end">
                {instructor_selected !== null ? (
                  instructor_selected.photo !== null ? (
                    <div><img src={instructor_selected.photo.url} alt="instructor-photo"
                      className="instructor-detail__img-photo" /></div>
                  ) : ''
                ) : ''}
              </div>
            </div>
            {instructor_selected !== null ? (
              <div className="col-md-9 col-sm-12">
                <h1 className="all-instructor__name">{instructor_selected.fullname}</h1>
                <div className="instructor-detail__text">{ReactHtmlParser(instructor_selected.description)}</div>
                <h1 className="all-instructor__name mt-5 mb-0">Sosial Media</h1>
                {!instructor_selected.twitter_url && !instructor_selected.facebook_url && !instructor_selected.instagram_url && !instructor_selected.linkedin_url ?
                  <p className="instructor-detail__text mb-3">Belum ada sosial media yang terhubung.</p>
                  : <p className="instructor-detail__text mb-3">{`Terhubung dengan ${instructor_selected.fullname}`}</p>
                }
                {socialLinks.map(({ href, title, id }) => (
                  href !== null ?
                    <Link href={href}>
                      <a className="social__link mr-5" onClick={(e) => {
                        e.preventDefault()
                        window.open(href, "_blank")
                      }}>{title}</a>
                    </Link> : ''

                ))}
              </div>
            ) : ''}

          </div>
          {instructor_selected !== null ? (
            <>
              <div>
                <h1 className="all-instructor__title" style={{ marginBottom: '10px', marginTop: '40px' }}>{`Kelas ${instructor_selected.fullname}`}</h1>
                <p className="instructor-detail__text mb-4">{`Daftar kelas bersama ${instructor_selected.fullname}`}</p>
              </div>
              <div className="row instructor-detail__row-margin">
                {instructor_selected.instructorcourses.map(el => (
                  <div className="col-md-4 col-lg-3 col-sm-6 col-12 mb-5 justify-content-center">
                    <Card data={{
                      ...el, instructor: {
                        fullname: instructor_selected.fullname
                      }
                    }} notSlider/>
                  </div>
                ))}
              </div>
            </>
          ) : ''}
        </div>
      </div>
      ) : ''}
    </>
  )
}

export default InstructorListing
