import React from 'react';
import 'react-notifications/lib/notifications.css';

const NotificationDropdownExam = ({data, actionTaken, date}) => {
    return (
      <div>
        <div><img src={data.course?.thumbnail?.url} alt={data.course.name}/></div>
        <div>
          <h3>{data.course.name}</h3>
          <p>{data.course?.instructor?.fullname}</p>
          <div>
            <button className="btn btn-pink" disabled={data.is_action_taken} onClick={() => actionTaken(data.id).then(() => window.open(`/course/login/${data.course.id}/${data.course.name}`, "_blank"))}>Akses Exam</button>
          </div>
        </div>
      </div>
    )
}

export default NotificationDropdownExam;
