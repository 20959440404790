import React from 'react'

const langkahData = [
    { img: '/images/prakerja/langkah1.png', desc: 'Buat Akun di MyEduSolveX' },
    { img: '/images/prakerja/langkah2.png', desc: 'Tukar Voucher yang sudah kamu dapatkan dari mitra Prakerja kita.' },
    { img: '/images/prakerja/langkah3.png', desc: 'Ikuti kelas sesuai kebutuhan hingga tuntas.' },
    { img: '/images/prakerja/langkah4.png', desc: 'Berikan review dan rating pada mitra kami di web mereka.' },
    { img: '/images/prakerja/langkah5.png', desc: 'Dapatkan sertifikat.' },
    { img: '/images/prakerja/langkah6.png', desc: 'Cek dashboard Prakerja apakah status “Aktif” berubah menjadi “Selesai”.' }
]

function Langkah() {
    return (
        <div className="prakerja-langkah">
            <div className="container-mesx">
                <h2 className="prakerja-title">Langkah-Langkah Mengikuti Kelas Prakerja</h2>
                <div>
                    {langkahData.map((el, i) => (
                        <div className="prakerja-langkah__imgs">
                            <div>
                                <div><p>{i + 1}</p></div>
                                <div>
                                    <p>{el.desc}</p>
                                </div>
                            </div>
                            <div>
                                <img src={el.img} alt={`desc ${i + 1}`} />
                            </div>
                        </div>
                    ))}
                </div>
                <div>
                    <div onClick={(e) => {
                        e.preventDefault()
                        window.open("https://api.whatsapp.com/send/?phone=6287857067313&text&app_absent=0", "_blank")
                    }}><p>Saya Butuh Bantuan</p><Arrow /></div>
                </div>
            </div>
        </div>
    )
}

const Arrow = () => <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.47251 6.66656H8.91918L5.66585 9.91989C5.40585 10.1799 5.40585 10.6066 5.66585 10.8666C5.92585 11.1266 6.34585 11.1266 6.60585 10.8666L10.9992 6.47322C11.2592 6.21322 11.2592 5.79322 10.9992 5.53322L6.61251 1.13322C6.48796 1.00839 6.31886 0.938232 6.14251 0.938232C5.96617 0.938232 5.79707 1.00839 5.67251 1.13322C5.41251 1.39322 5.41251 1.81322 5.67251 2.07322L8.91918 5.33322H1.47251C1.10585 5.33322 0.805847 5.63322 0.805847 5.99989C0.805847 6.36656 1.10585 6.66656 1.47251 6.66656Z" fill="#007EA7" />
</svg>

export default Langkah
