import React from 'react';
import { useSelector } from 'react-redux';


const LoadingSpinner = (props) => {
  const isLoading = useSelector(state => state.isLoading)
  return (
  <div className={`myLoader ${isLoading ? 'myLoader_show' : ''}`}>
    <span>
      <i className="fa fa-spinner fa-spin fa-4x" />
    </span>
    <p style={{fontWeight: '700', marginTop: '12px'}}>
      {(props.text) ? props.text : 'Mohon Menunggu'}
    </p>
  </div>)
};

export default LoadingSpinner;
