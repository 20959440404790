import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
    TopNav,
    ImageSection,
    QuestionSection,
    ShareStories,
    InterestingStories,
    BottomBanner
} from './styles';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import {
    Polygon,
    School,
    OnJob,
    Twitter,
    Facebook,
    LinkedIn,
    Back,
    Paperclip
} from '../../../image/InternPage';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from 'react-share';
import { useMediaQuery } from 'react-responsive';
import LoadingSpinner from '../../Layout/LoadingSpinner';

const DetailInternPage = ({ match }) => {
    const isMobile = useMediaQuery({ query: "(max-device-width: 992px)" });
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1601 },
            items: 4,
        },
        largeDesktop: {
            breakpoint: { max: 1600, min: 1201 },
            items: 3,
        },
        mediumDesktop: {
            breakpoint: { max: 1200, min: 993 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 991, min: 0 },
            items: 1,
        }
    }

    const socialLinks = [
        {
            href: '/',
            title: <TwitterShareButton url={`https://www.myedusolvex.com/intern/${match.params.id}`}><img src={Twitter} /></TwitterShareButton>,
            name: 'Twitter'
        },
        {
            href: '/',
            title: <FacebookShareButton url={`https://www.myedusolvex.com/intern/${match.params.id}`}><img src={Facebook} /></FacebookShareButton>,
            name: 'Facebook'
        },
        {
            href: '/',
            title: <LinkedinShareButton url={`https://www.myedusolvex.com/intern/${match.params.id}`}><img src={LinkedIn} /></LinkedinShareButton>,
            name: 'Linkedin'
        }
    ]

    useEffect(() => {
        setLoading(true);
        let id = match.params.id;
        axios.get(`${window.$API_URL}api/intern/${id}/detail/`)
            .then(response => {
                if (response.data.success) {
                    setData(response.data.data)
                }
            })
            .finally(() => setLoading(false))
    }, []);

    const getLineSeparator = (text) => {
        if (!text) return '';
        let arrText = (JSON.stringify(text)).split('\\r\\n');
        return arrText.map((el, i) => {
            let str = el;
            if (i === 0) str = str.substring(1, str.length);
            if (i === arrText.length - 1) str = str.substring(0, str.length - 1);
            return (
                <p className="content">{str || " "}</p>
            );
        })
    }
    return (
        <div style={{ backgroundColor: "#FFFFFF" }}>
            <Header />
            {loading ?
                <LoadingSpinner text={'Mohon Tunggu..'} />
                :
                <>
                    {isMobile ?
                        <TopNav>
                            <img src={Back} className="back" />
                            <Link to="/intern"><p>Kembali</p></Link>
                        </TopNav    >
                        :
                        <TopNav>
                            <Link to="/"><p className="link">Home</p></Link>
                            <img src={Polygon} className="polygon" />
                            <Link to="/intern"><p className="link">Intern Page</p></Link>
                            <img src={Polygon} className="polygon" />
                            <p>Meet {data.name}</p>
                        </TopNav>
                    }
                    {!isMobile &&
                        <ShareStories>
                            <div className="share">
                                <p>Bagikan</p>
                                {socialLinks.map(({ href, title, name }) => (
                                    <div className="icon" key={name}>
                                        <Link href={href}>
                                            <a className="social__link" href="/" onClick={(e) => e.preventDefault()}>{title}</a>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </ShareStories>
                    }
                    <ImageSection>
                        {!isMobile && <img src={Paperclip} className="clip" />}
                        {data.avatar ?
                            <img className="avatar" src={data.avatar.url} />
                            :
                            <div className="avatar" />
                        }
                        <div className="text">
                            <p>Meet</p>
                            <p className="nama">{data.name}</p>
                            {isMobile ?
                                <div className="school-onjob">
                                    <div className="container">
                                        <div>
                                            <p className="title">School</p>
                                            <p>{data.school}</p>
                                        </div>
                                        <img src={School} />
                                    </div>
                                    <div className="container">
                                        <div>
                                            <p className="title">On Job</p>
                                            <p>{data.position}</p>
                                        </div>
                                        <img src={OnJob} />
                                    </div>
                                </div>
                                :
                                <div className="school-onjob">
                                    <div className="school">
                                        <img src={School} />
                                        <p className="title">School</p>
                                        <p>{data.school}</p>
                                    </div>
                                    <div className="line" />
                                    <div>
                                        <img src={OnJob} />
                                        <p className="title">On Job</p>
                                        <p>{data.position}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </ImageSection>
                    <QuestionSection>
                        {data.question_1 &&
                            <div className="question">
                                <p className="title">Tell us a fun fact about yourself!</p>
                                {getLineSeparator(data.question_1)}
                            </div>
                        }
                        {data.question_2 &&
                            <div className="question">
                                <p className="title">What is your favorite quote or philosophy and why?</p>
                                {getLineSeparator(data.question_2)}
                            </div>
                        }
                        {data.question_3 &&
                            <div className="question">
                                <p className="title">How would you define a good/great education and why?</p>
                                {getLineSeparator(data.question_3)}
                            </div>
                        }
                        {data.question_4 &&
                            <div className="question">
                                <p className="title">What is your favorite part of interning at MyEduSolve?</p>
                                {getLineSeparator(data.question_4)}
                            </div>
                        }

                        {data.media &&
                            <div className="container">
                                <img src={data.media} />
                            </div>
                        }

                        {data.question_5 &&
                            <div className="question">
                                <p className="title">What does a typical day at MyEduSolve look like for you?</p>
                                {getLineSeparator(data.question_5)}
                            </div>
                        }
                        {data.question_6 &&
                            <div className="question">
                                <p className="title">What are you most proud of from your internship at MyEduSolve?</p>
                                {getLineSeparator(data.question_6)}
                            </div>
                        }
                        {data.question_7 &&
                            <div className="question">
                                <p className="title">What's one piece of advice you'd give to a future MyEduSolve intern?</p>
                                {getLineSeparator(data.question_7)}
                            </div>
                        }
                        {data.question_8 &&
                            <div className="question">
                                <p className="title">
                                    Mention 3 interesting things that you experienced during
                                    your internship at MyEduSolve!
                                </p>
                                {getLineSeparator(data.question_8)}
                            </div>
                        }
                        {data.question_9 &&
                            <div className="question">
                                <p className="title">
                                    Why did you decide to apply for MyEduSolve’s internship program?
                                </p>
                                {getLineSeparator(data.question_9)}
                            </div>
                        }
                        {data.question_10 &&
                            <div className="question">
                                <p className="title">
                                    What’s your message for MyEduSolve?
                                </p>
                                {getLineSeparator(data.question_10)}
                            </div>
                        }

                        {data.cover &&
                            <div className="container">
                                <img src={data.cover} />
                            </div>
                        }

                        {isMobile &&
                            <div className="share">
                                <p>Bagikan artikel ini</p>
                                {socialLinks.map(({ href, title, name }) => (
                                    <div className="icon" key={name}>
                                        <Link href={href}>
                                            <a className="social__link" href="/" onClick={(e) => e.preventDefault()}>{title}</a>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        }
                    </QuestionSection>
                    <InterestingStories>
                        <p className="top">Temukan cerita menarik lainnya</p>
                        <div className="container">
                            <div className="stories">
                                {data.interestingStories ?
                                    <Carousel
                                        responsive={responsive}
                                        customButtonGroup={isMobile ? null : <ButtonGroup />}
                                        renderButtonGroupOutside={isMobile ? false : true}
                                        arrows={isMobile}
                                        infinite={false}
                                        showDots={true}
                                    >
                                        {data.interestingStories.map((intern) =>
                                            <a onClick={() => { window.location.href = `/intern/${intern.id}` }}>
                                                <div key={intern.id} className="intern">
                                                    {intern.avatar ?
                                                        <img className="avatar" src={intern.avatar.url} />
                                                        :
                                                        <div className="avatar" />
                                                    }
                                                    <p className="nama">{intern.name}</p>
                                                    <p className="role">{intern.position}</p>
                                                </div>
                                            </a>)}
                                    </Carousel>
                                    : <></>
                                }
                            </div>
                        </div>
                    </InterestingStories>
                </>
            }
            <BottomBanner>
                <div>
                    <h1>Tertarik untuk bergabung di MyEduSolve sebagai #MyEduSolveIntern?</h1>
                    <p>Yuk daftarkan dirimu!</p>
                    <button
                        className="custom-btn"
                        onClick={() => window.open('https://glints.com/id/companies/myedusolve-indonesia/8a24f654-9b52-4e02-ae24-b50303c109ea', '_blank', 'noopener,noreferrer')}>
                        Cari Lowongan
                    </button>
                </div>
            </BottomBanner>
            <Footer />
        </div>
    );
};

const CustomRightArrow = ({ onClick, disable }) => {
    return <div onClick={() => onClick()} className="course-landing-arrow course-landing-arrow--right">
        <svg width="52" height="50" viewBox="0 0 52 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
                <g filter="url(#filter0_d)">
                    <path d="M26.1065 47.1429C39.227 47.1429 49.8632 36.9094 49.8632 24.2857C49.8632 11.6621 39.227 1.42859 26.1065 1.42859C12.9861 1.42859 2.34985 11.6621 2.34985 24.2857C2.34985 36.9094 12.9861 47.1429 26.1065 47.1429Z" fill="white" />
                    <path d="M49.685 24.2857C49.685 36.8039 39.1356 46.9643 26.1065 46.9643C13.0775 46.9643 2.52795 36.8039 2.52795 24.2857C2.52795 11.7676 13.0775 1.60718 26.1065 1.60718C39.1356 1.60718 49.685 11.7676 49.685 24.2857Z" stroke="#DDDDDD" stroke-width="0.5" />
                </g>
                <path d="M22 16L30 24L22 32" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <filter id="filter0_d" x="-0.722046" y="-0.642822" width="53.6571" height="51.8571" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
                <clipPath id="clip0">
                    <rect width="52" height="50" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </div>;
}

const CustomLeftArrow = ({ onClick, disable }) => {
    // onMove means if dragging or swiping in progress.
    return <div onClick={() => onClick()} className="course-landing-arrow course-landing-arrow--left">
        <svg width="53" height="50" viewBox="0 0 53 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
                <g filter="url(#filter0_d)">
                    <path d="M26.3916 47.1429C39.7644 47.1429 50.6051 36.9094 50.6051 24.2857C50.6051 11.6621 39.7644 1.42859 26.3916 1.42859C13.0189 1.42859 2.1781 11.6621 2.1781 24.2857C2.1781 36.9094 13.0189 47.1429 26.3916 47.1429Z" fill="white" />
                    <path d="M50.4236 24.2858C50.4236 36.8039 39.6713 46.9643 26.3916 46.9643C13.1119 46.9643 2.35962 36.8039 2.35962 24.2858C2.35962 11.7676 13.1119 1.60718 26.3916 1.60718C39.6713 1.60718 50.4236 11.7676 50.4236 24.2858Z" stroke="#DDDDDD" stroke-width="0.5" />
                </g>
                <path d="M30 16L22 24L30 32" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <filter id="filter0_d" x="-0.890381" y="-0.642822" width="54.564" height="51.8571" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
                <clipPath id="clip0">
                    <rect width="53" height="50" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </div>;
}

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide, slidesToShow } } = rest;
    return (
        <div className="carousel-button-group">
            <CustomLeftArrow disable={currentSlide === 0} onClick={() => previous()} />
            <CustomRightArrow disable={currentSlide >= slidesToShow} onClick={() => next()} />
        </div>
    );
};

export default DetailInternPage;