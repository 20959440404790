import React from 'react'
import { CloseModals } from '../../static';
import { useHistory } from "react-router-dom"

const EditWarning = ({closeModal, setModalContent, triggerEditProfile}) => {
    return (
        <div className="d-flex flex-column align-items-center pr-5 pl-5">
            <h3 className="mb-5" style={{fontSize: '18px', textAlign: 'center', fontWeight: '500'}}>Simpan Perubahan Data Diri</h3>
            <p style={{fontSize: '16px', textAlign: 'center'}}>Anda telah melakukan perubahan data diri, apakah Anda ingin menyimpan perubahannya?</p>
            <div className="d-flex w-100 justify-content-between mt-5 mb-4">
                <div className="btn btn-white edit-profile__btn-white w-40"style={{width: '200px', height: '40px'}}
                onClick={closeModal}>
                    Batal
                </div>
                <div className="btn btn-pink edit-profile__btn-pink w-40" style={{padding: '11px', width: '200px', height: '40px'}}
                onClick={(e) => {
                    triggerEditProfile()
                }}>
                    Simpan
                </div>
            </div>
        </div>
    )
}

const PasswordWarning = () => {
    return (
        <div className="d-flex flex-column align-items-center pr-5 pl-5">
            <h3 className="mb-5" style={{fontSize: '18px', textAlign: 'center', fontWeight: '500'}}>Ubah Password</h3>
            <p style={{fontSize: '16px', textAlign: 'center'}}>Kami telah mengirimkan link untuk melakukan reset kata sandi Anda, silahkan cek email.</p>
            <div className="d-flex w-100 justify-content-between mt-5 mb-4">
                <div className="btn btn-pink edit-profile__btn-pink w-100" style={{padding: '11px'}}>
                    Oke
                </div>
            </div>
        </div>
    )
}

const DoneEdit = ({closeModal}) => {
    const history = useHistory();
    return (
        <div className="d-flex flex-column align-items-center pr-5 pl-5">
            <img src="/images/doneEdit.png" alt="done edit"></img>
            <h3 className="mb-5" style={{fontSize: '18px', textAlign: 'center', fontWeight: '500'}}>Perubahan Tersimpan</h3>
            <div className="d-flex w-100 justify-content-between mb-4">
                <div className="btn btn-pink edit-profile__btn-pink w-100" style={{padding: '11px'}} onClick={(e) => {
                    closeModal()
                    history.push("/student/profile")
                    window.location.reload(false)
                }}>
                    Oke
                </div>
            </div>
        </div>
    )
}

const EditModal = ({closeModal, modalOpen, modalContent, setModalContent, triggerEditProfile}) => {
    return (
        <>
            <div
                className={`course_modal modal fade ${modalOpen ? 'show' : ''}`}
                tabIndex="-1"
                role="dialog"
                aria-hidden="true"
                style={{ display: `${modalOpen ? 'block' : 'none'}` }}
            >
                <div className="modal-dialog-course modal-dialog-centered modal-size-edit" style={{maxWidth: '100vw'}}>
                     <div className="modal-content">
                        <div className="pointer d-flex justify-content-end pt-3 pr-3" onClick={(e) => closeModal()}>
                            <CloseModals className="ml-auto" />
                        </div>
                        {modalContent === 'doneEdit' ? 
                            <DoneEdit closeModal={closeModal}/>
                        : '' }
                        {modalContent === 'editWarning' ? 
                            <EditWarning closeModal={closeModal} setModalContent={setModalContent} triggerEditProfile={triggerEditProfile}/>
                        : '' }
                        {modalContent === 'passwordWarning' ? 
                            <PasswordWarning closeModal={closeModal}/>
                        : '' }
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditModal