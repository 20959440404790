import React, { useState } from 'react';
import Button from '../Button';
import { connect, useDispatch } from 'react-redux'

const Search = ({handleVoucherChange, voucherCode, checkValidVoucher}) => {
    const dispatch = useDispatch();

    return (
        <div className="search">
            <div className="container">
                <div className="search__desc justify-content-center align-items-center">
                    <p className="font-weight-500 check-voucher">Jika Anda memiliki voucher Pra-Kerja silahkan periksa.</p>
                    <input className="search__input" placeholder="Masukkan kode voucher"
                    onChange={(e) => handleVoucherChange(e)} />
                    <button className="btn btn-pink ml-3" onClick={(e) => checkValidVoucher(voucherCode) } >Redeem</button>
                </div>
                <div className="search__mob justify-content-center align-items-center">
                    <h2 className="search__mob-text font-weight-500">Redeem Voucher?</h2>
                    <h2 className="search__mob-text font-weight-500 tx-pink">Klik Disini</h2>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    };
};
export default connect(
    mapDispatchToProps
)(Search);
