import React, { useEffect, useState } from 'react';

import Header from '../components/Header/Header';
import Footer from '../components/Footer';
import { fetchCertificateData, fetchCertificateByEmail } from '../Utils/Utils'
import LoadingSpinner from './../Layout/LoadingSpinner';
import { NotificationManager } from 'react-notifications';
import moment from 'moment'

const CertificateVerify = (props) => {
  const [loading, setLoading] = useState(false)
  const [certificate_data, setCertificateData] = useState('')
  const [email, setEmail] = useState('')
  const [searchResult, setSearchResult] = useState(null)
  useEffect(() => {
    setLoading(true);
    fetchCertificateData(props.match.params.id)
    .then((data) => {
      setCertificateData(data)
    })
    .finally(() => setLoading(false))
    setLoading(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return (<>
    <Header />
    <main>
      {/* <LandingBanner smallBanner /> */}
      {/* <Banner /> */}
      <div className="main-container container cert-verification-wrap">
        <h1>Validasi Sertifikat</h1>
        <div style={{ minHeight: '400px'}} className="row">
          <div className="col-md-6 d-flex align-items-center justify-content-end">
            <img src="/images/resource/cert-ver.png" style={{width: '360px', height: '328px'}} className="cert-verification-img" alt="certificate validation"/>
          </div>
          <div className="col-md-6 d-flex flex-column justify-content-center cert-verification">
            {
              (certificate_data !== '')
                ?
                (certificate_data === null)
                  ?
                  <div>
                    {props.match.params.id !== 'cari-sertifikat' ? 
                    <h2><svg width="24" height="24" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="red"/>
                    </svg>Sertifikat Tidak Ditemukan!</h2>: ''}
                    {props.match.params.id !== 'cari-sertifikat' ? 
                    <p className="mt-1">Validasi lewat Email:</p>: <h3 className="mt-1">Validasi lewat Email:</h3>}
                    <div className="mt-3">
                    <form class="form-inline">
                      <div class="form-group mb-2">
                        <input
                          type="text"
                          className="form-control login_form__form"
                          placeholder="E-mail"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <button type="submit" class="btn btn-primary mb-2" onClick={(e) => {
                        e.preventDefault()
                        setLoading(true)
                        fetchCertificateByEmail(email)
                        .then((data) => {
                          if(data) setSearchResult(data)
                          else NotificationManager.error('Some Error Occured in Login API!', 'Validasi Error!');
                        })
                        .finally(() => setLoading(false))

                      }}>Kirim</button>
                    </form>
                    </div>
                </div>
                  :
                  <div>
                    <h2><svg width="32" height="36" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2.89453C6.5 2.89453 2 7.39192 2 12.8887C2 18.3855 6.5 22.8829 12 22.8829C17.5 22.8829 22 18.3855 22 12.8887C22 7.39192 17.5 2.89453 12 2.89453ZM10 17.8858L5 12.8887L6.41 11.4795L10 15.0575L17.59 7.47187L19 8.89104L10 17.8858Z" fill="#15959A"/>
                    </svg>Sertifikat Valid!
                    </h2>
                    <p>Sertifikat ini terdaftar atas nama <u>{certificate_data.student.fullname}</u>.</p>
                  </div>
                : ''
            }
          </div>
        </div>
        {searchResult ? 
        <div className="cert-search-result">
          <h2>Sertifikat dari Email</h2>
            {searchResult[0] ? 
            <div>
              <table>
                <tr>
                  <th>Course</th>
                  <th>Tanggal</th>
                  <th>Link</th>
                </tr>
                {searchResult.map(el => (
                  <tr>
                    <td>{el.course ? el.course.name : 'Data tidak tersedia'}</td>
                    <td>{moment(el.created_at).format('DD MMMM YYYY')}</td>
                    <td><button className="btn btn-primary" onClick={() => window.open(el.certificate_pdf_url, "_blank")}>PDF</button><button className="btn btn-primary" onClick={() => window.open(el.certificate_url, "_blank")}>JPG</button></td>
                  </tr>
                ))}
              </table>
            </div>
        : <p>Sertifikat tidak ditemukan</p>}
        </div>
      : ''}
      </div>
    </main>
    {loading ? <LoadingSpinner text={'Loading..'} /> : ''}
    <Footer />
  </>)
}

export default CertificateVerify;
