import React, { useState } from 'react'
import MediaQuery from 'react-responsive'

const SelfPacedIcon = () => <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 15.8334H3V14.6667C3 13 6.33333 12.0834 8 12.0834C9.66667 12.0834 13 13 13 14.6667V15.8334ZM8 5.83337C8.66304 5.83337 9.29893 6.09677 9.76777 6.56561C10.2366 7.03445 10.5 7.67033 10.5 8.33337C10.5 8.99642 10.2366 9.6323 9.76777 10.1011C9.29893 10.57 8.66304 10.8334 8 10.8334C7.33696 10.8334 6.70107 10.57 6.23223 10.1011C5.76339 9.6323 5.5 8.99642 5.5 8.33337C5.5 7.67033 5.76339 7.03445 6.23223 6.56561C6.70107 6.09677 7.33696 5.83337 8 5.83337ZM8 2.50004C8.22101 2.50004 8.43297 2.58784 8.58926 2.74412C8.74554 2.9004 8.83333 3.11236 8.83333 3.33337C8.83333 3.55439 8.74554 3.76635 8.58926 3.92263C8.43297 4.07891 8.22101 4.16671 8 4.16671C7.77899 4.16671 7.56702 4.07891 7.41074 3.92263C7.25446 3.76635 7.16667 3.55439 7.16667 3.33337C7.16667 3.11236 7.25446 2.9004 7.41074 2.74412C7.56702 2.58784 7.77899 2.50004 8 2.50004ZM13.8333 2.50004H10.35C10 1.53337 9.08333 0.833374 8 0.833374C6.91667 0.833374 6 1.53337 5.65 2.50004H2.16667C1.72464 2.50004 1.30072 2.67564 0.988155 2.9882C0.675595 3.30076 0.5 3.72468 0.5 4.16671V15.8334C0.5 16.2754 0.675595 16.6993 0.988155 17.0119C1.30072 17.3244 1.72464 17.5 2.16667 17.5H13.8333C14.2754 17.5 14.6993 17.3244 15.0118 17.0119C15.3244 16.6993 15.5 16.2754 15.5 15.8334V4.16671C15.5 3.72468 15.3244 3.30076 15.0118 2.9882C14.6993 2.67564 14.2754 2.50004 13.8333 2.50004Z" fill="black"/>
</svg>

const SertifikatIcon = () => <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.83335 15.5L11.5 14.6667L13.1667 15.5V9.66667H9.83335V15.5ZM13.1667 5.5V3.83333L11.5 4.66667L9.83335 3.83333V5.5L8.16669 6.33333L9.83335 7.16667V8.83333L11.5 8L13.1667 8.83333V7.16667L14.8334 6.33333L13.1667 5.5ZM15.6667 0.5H2.33335C1.89133 0.5 1.4674 0.675595 1.15484 0.988155C0.842282 1.30072 0.666687 1.72464 0.666687 2.16667V10.5C0.666687 10.942 0.842282 11.3659 1.15484 11.6785C1.4674 11.9911 1.89133 12.1667 2.33335 12.1667H8.16669V10.5H2.33335V2.16667H15.6667V10.5H14.8334V12.1667H15.6667C16.1087 12.1667 16.5326 11.9911 16.8452 11.6785C17.1578 11.3659 17.3334 10.942 17.3334 10.5V2.16667C17.3334 1.72464 17.1578 1.30072 16.8452 0.988155C16.5326 0.675595 16.1087 0.5 15.6667 0.5ZM8.16669 4.66667H3.16669V3H8.16669V4.66667ZM6.50002 7.16667H3.16669V5.5H6.50002V7.16667ZM8.16669 9.66667H3.16669V8H8.16669V9.66667Z" fill="black"/>
</svg>

const DiskusiIcon = () => <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.3334 7.49998C17.3334 7.49998 17.3334 10.8333 17.3334 10.8333V12.5H12.3334V10.8333C12.3334 10.8333 12.3334 9.42498 11.375 8.16665C11.2334 7.97498 11.0584 7.79165 10.875 7.61665C11.3084 7.54998 11.7834 7.49998 12.3334 7.49998ZM0.666687 10.8333C0.666687 10.8333 0.666687 7.49998 5.66669 7.49998C10.6667 7.49998 10.6667 10.8333 10.6667 10.8333V12.5H0.666687V10.8333ZM6.50002 14.1666V15.8333H11.5V14.1666L14 16.6666L11.5 19.1666V17.5H6.50002V19.1666L4.00002 16.6666L6.50002 14.1666ZM5.66669 0.833313C4.28335 0.833313 3.16669 1.94998 3.16669 3.33331C3.16669 4.71665 4.28335 5.83331 5.66669 5.83331C7.05002 5.83331 8.16669 4.71665 8.16669 3.33331C8.16669 1.94998 7.05002 0.833313 5.66669 0.833313ZM12.3334 0.833313C10.95 0.833313 9.83335 1.94998 9.83335 3.33331C9.83335 4.71665 10.95 5.83331 12.3334 5.83331C13.7167 5.83331 14.8334 4.71665 14.8334 3.33331C14.8334 1.94998 13.7167 0.833313 12.3334 0.833313Z" fill="black"/>
</svg>


const TabSertifikat = () => (
    <div className="sertifikat-landing__content">
        <MediaQuery minDeviceWidth={992}>
            <p>Dapatkan e-sertifikat kelulusan untuk semua pelatihan Anda di MyEduSolveX</p>
        </MediaQuery>

        <div className="e-sertifikat-landing">
            <MediaQuery minDeviceWidth={992}>
                <div><img src="/images/landing/homepage-new/sertifikat-img-2.png" /></div>
            </MediaQuery>
                <div><img src="/images/landing/homepage-new/sertifikat-img-1.png" /></div>
            <MediaQuery minDeviceWidth={992}>
                <div><img src="/images/landing/homepage-new/sertifikat-img-3.png" /></div>
            </MediaQuery>
        </div>
    </div>
)

const TabSelfPaced = () => (
    <div className="sertifikat-landing__content">
        <MediaQuery minDeviceWidth={992}>
            <p>Akses kelas kapan saja sesuai dengan jadwal Anda</p>
        </MediaQuery>
        <div className="self-paced-landing">
            <div><img src="/images/landing/homepage-new/selfpaced-img-1.png" /></div>
            <MediaQuery minDeviceWidth={992}>
                <div><img src="/images/landing/homepage-new/selfpaced-img-2.png" /></div>
            </MediaQuery>
        </div>
    </div>
)

const TabDiskusi = () => (
    <div className="sertifikat-landing__content">
        <MediaQuery minDeviceWidth={992}>
            <p>Diskusi interaktif dengan sesama pelajar, dan moderator yang siap membantu Anda!</p>
        </MediaQuery>
        <div className="diskusi-landing">
            <div><img src="/images/landing/homepage-new/diskusi-img-1.png" /></div>
            <MediaQuery minDeviceWidth={992}>
                <div><img src="/images/landing/homepage-new/diskusi-img-2.png" /></div>
            </MediaQuery>
        </div>
    </div>
)

const navMenu = [
    { text: 'Self-paced', icon: <SelfPacedIcon/> },
    { text: 'E-Sertifikat', icon: <SertifikatIcon/> },
    { text: 'Diskusi', icon: <DiskusiIcon/> }
]

const contentMenu = [
    { text: 'Self-paced', content: <TabSelfPaced/> },
    { text: 'E-Sertifikat', content: <TabSertifikat/> },
    { text: 'Diskusi', content: <TabDiskusi/> }
]

const SertifikatLanding = () => {
    const [navSelected, setNavSelected] = useState('Self-paced')

    return (
        <>
            <div className="sertifikat-landing">
                <div>
                    <div className="sertifikat-landing__nav">
                        {navMenu.map(nav => <div onClick={() => setNavSelected(nav.text)} className={navSelected == nav.text ? 'sertifikat-landing__nav-selected' : ''}><div>{nav.icon} <p>{nav.text}</p></div></div>)}
                    </div>
                    {contentMenu.map(content => <div style={navSelected !== content.text ? { display: 'none' } : {}}>{content.content}</div>)}
                </div>
                
            </div>
        </>
    )
}



export default SertifikatLanding