import React from 'react'

const layananData = [{ color: '#717CFF', desc: 'Materi praktis yang bisa langsung diaplikasikan.' },
{ color: '#FF5959', desc: 'Pengalaman belajar yang mudah.' },
{ color: '#70CE88', desc: 'Materi video yang sangat relevan.' },
{ color: '#5C96FF', desc: 'User support siap siaga.' },
{ color: '#FA706C', desc: 'Career consultation dengan DIAN.' },
{ color: '#70CE88', desc: '4.86 average class rating, 87% class recommendation score (NPS)' }]

function Layanan() {
    return (
        <div className="prakerja-layanan">
            <h2 className="prakerja-title">Layanan Kartu Prakerja</h2>
            <p>Apa yang kamu dapatkan di Program Prakerja MyEduSolveX</p>
            <div>
                {layananData.map(el => (
                    <div>
                        <div><Icon fill={el.color}/></div>
                        <div><p>{el.desc}</p></div>
                    </div>
                ))}
                
            </div>
        </div>
    )
}

const Icon = ({fill}) => <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="16" fill={fill}/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.8012 19.576C10.8012 20.4723 11.5278 21.1988 12.424 21.1988H19.9971C20.8934 21.1988 21.6199 20.4723 21.6199 19.576V12.0029C21.6199 11.1067 20.8934 10.3801 19.9971 10.3801H12.424C11.5278 10.3801 10.8012 11.1067 10.8012 12.0029V19.576ZM20.5381 19.576C20.5381 19.8748 20.2959 20.117 19.9971 20.117H12.424C12.1253 20.117 11.8831 19.8748 11.8831 19.576V12.0029C11.8831 11.7042 12.1253 11.462 12.424 11.462H15.1287V15.7955L16.4242 15.7955L17.2925 14.6341L18.1607 15.7955H19.4562L19.4562 11.462H19.9971C20.2959 11.462 20.5381 11.7042 20.5381 12.0029V19.576ZM16.2106 11.462H18.3743V14.3483L17.2925 12.9011L16.2106 14.3483V11.462Z" fill="white"/>
<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="10" y="10" width="12" height="12">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.8012 19.576C10.8012 20.4723 11.5278 21.1988 12.424 21.1988H19.9971C20.8934 21.1988 21.6199 20.4723 21.6199 19.576V12.0029C21.6199 11.1067 20.8934 10.3801 19.9971 10.3801H12.424C11.5278 10.3801 10.8012 11.1067 10.8012 12.0029V19.576ZM20.5381 19.576C20.5381 19.8748 20.2959 20.117 19.9971 20.117H12.424C12.1253 20.117 11.8831 19.8748 11.8831 19.576V12.0029C11.8831 11.7042 12.1253 11.462 12.424 11.462H15.1287V15.7955L16.4242 15.7955L17.2925 14.6341L18.1607 15.7955H19.4562L19.4562 11.462H19.9971C20.2959 11.462 20.5381 11.7042 20.5381 12.0029V19.576ZM16.2106 11.462H18.3743V14.3483L17.2925 12.9011L16.2106 14.3483V11.462Z" fill="white"/>
</mask>
<g mask="url(#mask0)">
</g>
</svg>


export default Layanan
