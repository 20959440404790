import React from 'react';
import { Link } from 'react-router-dom'
// import { Redirect, Route,  } from 'react-router-dom';
// import * as router from 'react-router-dom';
class LandingVideoSection extends React.Component {
  render() {
    return (
      <>
        <section id="our-courses" className="our-courses bgc-f9">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <div className="main-title text-center">
                  <h3 className="mt0">Kategori Kelas</h3>
                  <p>Temukan kelas sesuai dengan bidang yang Anda minati!</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-lg-3">
                <div
                  className="img_hvr_box"
                  style={{ backgroundImage: `url("images/courses/Category-Images-08.jpg")` }}
                >
                  <div className="overlay">
                    <div className="details">
                      <h5>Administrasi</h5>
                      <p>Over 800 Courses</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div
                  className="img_hvr_box"
                  style={{ backgroundImage: `url("images/courses/Category-Images-03.jpg")` }}
                >
                  <div className="overlay">
                    <div className="details">
                      <h5>Desain</h5>
                      <p>Over 1,400 Courses</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div
                  className="img_hvr_box"
                  style={{ backgroundImage: `url("images/courses/Category-Images-04.jpg")` }}
                >
                  <div className="overlay">
                    <div className="details">
                      <h5>Digital Marketing</h5>
                      <p>Over 350 Courses</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div
                  className="img_hvr_box"
                  style={{ backgroundImage: `url("images/courses/Category-Images-09.jpg")` }}
                >
                  <div className="overlay">
                    <div className="details">
                      <h5>F&B</h5>
                      <p>Over 640 Courses</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div
                  className="img_hvr_box"
                  style={{ backgroundImage: `url("images/courses/Category-Images-11.jpg")` }}
                >
                  <div className="overlay">
                    <div className="details">
                      <h5>Future-Ready Skills</h5>
                      <p>Over 740 Courses</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div
                  className="img_hvr_box"
                  style={{ backgroundImage: `url("images/courses/Category-Images-06.jpg")` }}
                >
                  <div className="overlay">
                    <div className="details">
                      <h5>Pengembangan Diri</h5>
                      <p>Over 120 Courses</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div
                  className="img_hvr_box"
                  style={{ backgroundImage: `url("images/courses/Category-Images-07.jpg")` }}
                >
                  <div className="overlay">
                    <div className="details">
                      <h5>Retail</h5>
                      <p>Over 200 Courses</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div
                  className="img_hvr_box"
                  style={{ backgroundImage: `url("images/courses/Category-Images-01.jpg")` }}
                >
                  <div className="overlay">
                    <div className="details">
                      <h5>UMKM</h5>
                      <p>Over 900 Courses</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-3">
                <div className="courses_all_btn text-center">
                  <Link className="btn btn-voucher-redeem" to="/courses">
                    Lihat Semua Kategori
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default LandingVideoSection;
