import React from 'react';

const HowToDownload = () => {
  const howToDownload = [
    <p>Sertifikat akan terunduh otomatis setelah menyelesaikan kuis</p>,
    <p>
      Apabila ingin mengunduh sertifikat di lain waktu, silakan login atau masuk di{' '}
      <a className="link" href="https://myedusolvex.com/" target="_blank" rel="noopener noreferrer">
        www.myedusolvex.com
      </a>{' '}
      dengan memasukkan username dan password
    </p>,
    <p>
      Kemudian klik profil di sisi kanan atas kemudian pilih “<b>Kelas Saya</b>”
    </p>,
    <p>
      Klik “<b>Sertifikat</b>” untuk melihat sertifikat yang dimiliki
    </p>,
    <p>
      Pilih sertifikat yang ingin didownload lalu klik “<b>Download Sertifikat</b>”
    </p>,
  ];

  return (
    <div className="how-to-download">
      <h2 className="font-bold text-base">Berikut tahapan mengunduh sertifikat pelatihan:</h2>
      {howToDownload.map((how, i) => (
        <div className="flex-gap-6" key={i}>
          <div className="number">
            <p>{i + 1}</p>
          </div>
          {how}
        </div>
      ))}
    </div>
  );
};

export default HowToDownload;
