import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { trackUserIp } from './Utils/EventSourcing';

// NEW LANDINGS
import CourseListing from './courses/CourseListing';
import EventDetail from './events_new/EventDetail';
import EventListing from './events_new/EventListing';
import InstructorsDetail from './instructor_new/InstructorDetail';
import Instructors from './instructor_new/InstructorListing';
import FaqNew from './landing_new/FaqNew';
import KontakPage from './landing_new/KontakPage';
import LandingPageNew from './landing_new/LandingPageNew';
import MarkAttendance from './landing_new/MarkAttendance';

import CheckoutPage from './checkout_new/Checkout';
import OrderDetail from './checkout_new/OrderDetail';
import WAButton from './components/WAButton';

import OnBoardingRegister from './components/LogInRegisterModal/OnBoardingRegister';

import CertificateVerify from './CertificateVerify/';
import VerifyLoginVoucherLayout from './containers/VerifyLoginVoucherLayout';
import VerifyVoucherLayout from './containers/VerifyVoucherLayout';
import CourseDetail from './courses/CourseDetailNew';

import CourseHostedLogin from './courses/CourseHostedLogin';

// Intern Page
import DetailInternPage from './InternPage/Detail';
import InternPage from './InternPage/Main';

//Student Routes
import StudentDashboard from './studentprofile_new/StudentDashboard'; //NEW

//Teacher Routes

//Route Protections on Reload
import ProtectedRoute from './Route/ProtectedRoute';

//Other Routes
import createBrowserHistory from 'history/createBrowserHistory';
import mixpanel from 'mixpanel-browser';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import NotFound from '../web/Route/Route404';
import ImpersonateComponent from './containers/ImpersonateComponent';
import Prakerja from './info/Prakerja';
import Privacy from './info/Privacy';
import Terms from './info/Terms';
import KpjRedeemVerify from './kpj/KpjRedeemVerify';
import ReflectiveJournalDetail from './studentprofile_new/reflective-journal/ReflectiveJournalDetail';
import TugasMandiriDetail from './studentprofile_new/tugas-mandiri/TugasMandiriDetail';
import UnjukKeterampilanDetail from './studentprofile_new/unjuk-keterampilan/UnjukKeterampilanDetail';

const history = createBrowserHistory();
history.listen((_) => {
  window.scrollTo(0, 0);
});

const trackingId = 'UA-163767507-1';

ReactGA.initialize(trackingId, {
  debug: false,
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    ReactGA.set({ page: window.location.pathname }); // Update the user's current page
    ReactGA.pageview(window.location.pathname); // Record a pageview for the given page
    ReactPixel.init('915068772310431');
    ReactPixel.pageView(); // For tracking page view

    // MixPanel
    mixpanel.init('939d679fde8f95f53f390d41a06f24ec');
    mixpanel.track('Landing');
    history.listen(() => {
      ReactGA.set({ page: window.location.pathname }); // Update the user's current page
      ReactGA.pageview(window.location.pathname); // Record a pageview for the given page
    });

    trackUserIp();
  }
  render() {
    return (
      <>
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={LandingPageNew} />
            <Route exact path="/refer/:referral_id" component={LandingPageNew}>
              <LandingPageNew refer={true} />
            </Route>

            <Route exact path="/voucher/verify" component={VerifyVoucherLayout} />
            <Route exact path="/voucher/verifylogin" component={VerifyLoginVoucherLayout} />
            <Route exact path="/courses" component={CourseListing} />
            <Route exact path="/course/login/:slug/:slug_url" component={CourseHostedLogin} />
            <Route exact path="/course/:slug/:slug_url" component={CourseDetail} />
            <Route exact path="/certificateverify/:id/" component={CertificateVerify} />

            <Route exact path="/coursedetail/:slug/:slug_url" component={CourseDetail} />
            <Route exact path="/contactus" component={KontakPage} />
            <Route exact path="/faq" component={FaqNew} />
            <Route exact path="/webinar" component={EventListing} />
            <Route exact path="/webinar/:slug" component={EventDetail} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/prakerja" component={Prakerja} />
            <Route exact path="/instructors" component={Instructors} />
            <Route
              exact
              path="/instructors/:instructor_id/:instructor_url"
              component={InstructorsDetail}
            />
            <Route exact path="/reset" component={LandingPageNew} reset={true} />
            <Route exact path="/verify" component={LandingPageNew} reset={true} />
            <Route exact path="/impersonate/:access_token" component={ImpersonateComponent} />

            <Route exact path="/intern" component={InternPage} />
            <Route exact path="/intern/:id" component={DetailInternPage} />

            <Route exact path="/checkoutsuccess/:type/:id" component={CheckoutPage} />

            <Route exact path="/order/detail" component={OrderDetail} />

            <Route exact path="/complete-on-boarding" component={OnBoardingRegister} />

            <Route
              exact
              path="/markattendance/:course_id/:schedule_id/:is_qna_session"
              component={MarkAttendance}
            />
            <ProtectedRoute
              path="/student/reflective-journal/:slug"
              component={ReflectiveJournalDetail}
              exact={true}
            />
            <ProtectedRoute
              path="/student/tugas-mandiri/:slug"
              component={TugasMandiriDetail}
              exact={true}
            />
            <ProtectedRoute
              path="/student/unjuk-keterampilan/:slug"
              component={UnjukKeterampilanDetail}
              exact={true}
            />
            <ProtectedRoute
              path="/student/:profile_menu"
              component={StudentDashboard}
              exact={true}
            />
            <ProtectedRoute path="/kpj/redeem-verify" component={KpjRedeemVerify} exact={true} />
            <Route component={NotFound} />
          </Switch>
          <WAButton />
        </Router>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { profile_data: state.profile_data };
};

export default connect(mapStateToProps)(App);
