import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Menu, Dropdown } from 'antd';
import NotificationPage from '../NotificationPage';

const HeaderUser = ({ profile_data, onClose }) => {
  const dispatch = useDispatch();
  const displayPopup = (isregister) => {
    dispatch({
      type: 'UPDATE_LOGINPOPUP_OPEN',
      payload: 1,
    });
    dispatch({
      type: 'UPDATE_REGISTERPOPUP_OPEN',
      payload: isregister,
    });

    onClose();
  };

  const setTabSelected = (submenu) => {
    dispatch({
      type: 'SET_MY_CLASS_SUBMENU',
      payload: submenu,
    });
  };

  const menu = (
    <Menu style={{ top: '-5px' }}>
      <Menu.Item key="0">
        <Link to="/student/profile/" style={{ fontSize: '18px' }}>
          Profile Saya
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <Link to="/student/course" style={{ fontSize: '18px' }}>
          Kelas Saya
        </Link>
      </Menu.Item>
      <Menu.ItemGroup key="5" style={{ marginTop: 0 }}>
        <Menu.Item>
          <Link
            to="/student/course"
            onClick={(e) => setTabSelected('sedang')}
            style={{ paddingTop: 0, fontWeight: '700' }}
          >
            Sedang Berlangsung
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link
            to="/student/course"
            onClick={(e) => setTabSelected('selesai')}
            style={{ paddingTop: 0, fontWeight: '700' }}
          >
            Selesai
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link
            to="/student/course"
            onClick={(e) => setTabSelected('sertifikat')}
            style={{ paddingTop: 0, fontWeight: '700' }}
          >
            Sertifikat
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link
            to="/student/course"
            onClick={(e) => setTabSelected('diskusi')}
            style={{ paddingTop: 0, fontWeight: '700' }}
          >
            Diskusi
          </Link>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.Item key="2">
        <Link to="/student/events" style={{ fontSize: '18px' }}>
          Event Saya
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">
        <Link to="/student/orders" style={{ fontSize: '18px' }}>
          Pesanan Saya
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="4">
        <Link to="/" style={{ color: '#E00814', fontSize: '18px' }} onClick={(e) => logout()}>
          Keluar
        </Link>
      </Menu.Item>
    </Menu>
  );

  const logout = () => {
    const access_token = localStorage.getItem('access_token');
    axios
      .post(
        `${window.$API_URL}api/auth/logout/`,
        {},
        {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
        },
      )
      .then((success) => {
        localStorage.removeItem('access_token');
        NotificationManager.success('Sampai jumpa kembali', 'Berhasil Logout!');
        setTimeout(() => (window.location.href = '/'), 500);
      })
      .catch((error) => {
        localStorage.removeItem('access_token');
        NotificationManager.success('Sampai jumpa kembali', 'Berhasil Logout!');
        setTimeout(() => (window.location.href = '/'), 500);
      });
  };

  return (
    <div className="headercustomcss__user">
      {profile_data !== null ? (
        <>
          <NotificationPage />
          <div className="headercustomcss__link-item ml-0">
            <Dropdown overlay={menu} overlayStyle={{ width: '228px', position: 'fixed' }}>
              <a
                className="headercustomcss__link-new"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = '/student/course';
                }}
                href="/"
              >
                {profile_data.fullname}
                <div className="headercustomcss__link-arrow"></div>
              </a>
            </Dropdown>
          </div>
        </>
      ) : (
        <>
          <a onClick={(e) => displayPopup(1)} className="header-profile-hover">
            <p>Daftar</p>
          </a>
          <span className="headercustomcss__user-separator"> / </span>
          <a onClick={(e) => displayPopup(0)} className="header-profile-hover">
            <p>Masuk</p>
          </a>
        </>
      )}
      {/* <NotificationContainer /> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    login_popup_open_flag: state.login_popup_open_flag,
    profile_data: state.profile_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUser);
